import { ReactElement } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { getDeviceLanguageDateFormat } from '../../../../utility/dateUtilities/getDeviceLanguageDateFormat';

const Badge = styled(Label)`
    &&& {
        margin: 0.5rem 0 1rem 0;
    }
`;

const StyledCloseIcon = styled(Icon)`
    cursor: pointer;
    padding: 0 0.3rem;

    :hover {
        color: #000;
    }
`;

interface DateBadgeProps {
    fromDate: Date | undefined;
    toDate: Date | undefined;
    onReset(): void;
}

const DateBadge = ({ fromDate, toDate, onReset }: DateBadgeProps): ReactElement => {
    return (
        <Badge circular>
            {`${fromDate ? getDeviceLanguageDateFormat(fromDate) : ''}${
                toDate ? ' - ' + getDeviceLanguageDateFormat(toDate) : ''
            }`}
            <StyledCloseIcon name="close" onClick={onReset} />
        </Badge>
    );
};

export default DateBadge;
