import { AxiosError, AxiosResponse } from 'axios';
import type { InfiniteQueryResult, MutationResultPair } from 'react-query';
import { useInfiniteQuery, useQueryCache, useMutation } from 'react-query';
import { CreateTicketComment, TicketCommentWithSeen, TicketSeenEntity } from '../../../api/apiTypes/ticketingApiTypes';
import { createTicketInternalNote, getTicketInternalNotes, getTicketReadCount } from '../../../api/ticketingApi';
import userHasSeen from './utils/userHasSeen';

const TicketInternalNotesCacheKey = 'TicketInternalNotesCacheKey';

export const useGetInfiniteTicketInternalNotes = (
    ticketId: string,
): InfiniteQueryResult<TicketCommentWithSeen[], Error | AxiosError<string>> => {
    const numberOfTicketInternalNotes = 20;
    return useInfiniteQuery<TicketCommentWithSeen[], Error | AxiosError<string>>(
        [TicketInternalNotesCacheKey, ticketId],
        async (_, ticketId, page): Promise<TicketCommentWithSeen[]> => {
            const response = await getTicketInternalNotes(ticketId, page ?? 0, numberOfTicketInternalNotes);
            const responseCount = await getTicketReadCount(ticketId, TicketSeenEntity.InternalNotes);
            const { allCount, readCount } = responseCount.data;

            return userHasSeen(response.data, allCount - readCount);
        },
        {
            getFetchMore: (lastPage, allPages) => {
                if (lastPage.length !== numberOfTicketInternalNotes) {
                    return false;
                }
                return allPages.length;
            },
            staleTime: 1000 * 20,
            refetchInterval: 1000 * 20,
        },
    );
};

export const useCreateTicketInternalNote = (): MutationResultPair<
    AxiosResponse<CreateTicketComment>,
    string | AxiosError<string> | Error,
    [string, CreateTicketComment],
    never
> => {
    const cache = useQueryCache();

    return useMutation(
        ([ticketNumber, note]) => {
            return createTicketInternalNote(ticketNumber, note);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(TicketInternalNotesCacheKey);
            },
        },
    );
};
