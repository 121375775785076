import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ShoppingCartItem } from '../../modules/Food/components';
import { orderedFoodLine, DeliveryMethod } from '../../modules/Food/types';
import { getItemPrice } from '../../modules/Food/utils';

const Container = styled.div`
    margin: 1rem 0;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: var(--background-color);
`;

const EmptyStatePhrase = styled.p`
    && {
        margin: 1rem;
        font-weight: bold;
    }
`;

interface SelectedItemsSectionProps {
    deliveryMethod: DeliveryMethod;
    foodLines: orderedFoodLine[];
    changeFoodLine?: (foodLineId: number, quantity: number) => void;
    removeFoodLine?: (id: number) => void;
    deliveryFeeGross: number;
    setTableFeeGross: number;
    deliveryDateTime: Date;
    readOnly?: boolean;
    showDeadlineMessage?: boolean;
}
const SelectedItemsSection: React.FC<React.PropsWithChildren<SelectedItemsSectionProps>> = ({
    deliveryMethod,
    deliveryDateTime,
    foodLines,
    changeFoodLine,
    removeFoodLine,
    deliveryFeeGross,
    setTableFeeGross,
    readOnly = false,
    showDeadlineMessage = true,
}: SelectedItemsSectionProps) => {
    const deliveryAddon = deliveryMethod !== 'hente';
    const tableSettingAddon = deliveryMethod === 'oppdekning';
    const { t } = useTranslation('common');
    return (
        <Container>
            {foodLines.length > 0 ? (
                foodLines.map((foodLine) => {
                    const handleQuantityChanged =
                        changeFoodLine &&
                        ((newQuantity: number) => {
                            changeFoodLine(foodLine.foodLineId, newQuantity);
                        });

                    const handleRemoveItem =
                        removeFoodLine &&
                        (() => {
                            removeFoodLine(foodLine.foodLineId);
                        });
                    const canBeChanged = !readOnly && !foodLine.isPassedDeadline;
                    return (
                        <ShoppingCartItem
                            key={foodLine.foodLineId}
                            name={foodLine.name}
                            quantity={foodLine.quantity}
                            minQuantity={foodLine.minOrderQuantity}
                            handleQuantityChanged={canBeChanged ? handleQuantityChanged : undefined}
                            priceString={getItemPrice(foodLine, deliveryMethod).toFixed(2)}
                            priceUnit={foodLine.priceUnit}
                            handleRemoveItem={canBeChanged ? handleRemoveItem : undefined}
                            itemDeadline={foodLine.orderDeadline}
                            itemIsUnavailable={foodLine.unavailable}
                            deliveryDateTime={deliveryDateTime}
                            showDeadlineMessage={showDeadlineMessage}
                        />
                    );
                })
            ) : (
                <EmptyStatePhrase>{t('noSelectedItems')}</EmptyStatePhrase>
            )}
            {}
            {deliveryAddon ? (
                <ShoppingCartItem
                    name={t('deliverySurcharge')}
                    priceString={deliveryFeeGross.toFixed(2)}
                    priceUnit={t('perOrderInclMVA')}
                />
            ) : null}
            {tableSettingAddon ? (
                <ShoppingCartItem
                    name={t('discoveryFree')}
                    priceString={setTableFeeGross.toFixed(2)}
                    priceUnit={t('perParticipantInclMVA')}
                />
            ) : null}
        </Container>
    );
};

export default SelectedItemsSection;
