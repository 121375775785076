import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import LinesEllipsis from 'react-lines-ellipsis';
import { Card } from 'semantic-ui-react';
import styled, { css, keyframes } from 'styled-components';
import { ArticleModel } from '../../../../../api/apiTypes/cmsApiTypes';
import { MobileViewBreakpoint } from '../../../../../common/ScreenSizeBreakPoints';
import { SmartImage } from '../../../../../components';
import { SmartImageProps } from '../../../../../components/SmartImage';
import { ModalStateType } from '../../components/ArticlesList';
import ArticleCardContextMenu from './ArticleCardContextMenu';

const fadeIn = keyframes`
0% {
    opacity: 0%;
}
100% {
    opacity: 100%;
}
`;

type StyledCardProps = {
    show?: boolean;
    footer: Element | undefined;
};
const StyledCard = styled(Card)`
    position: relative;
    z-index: 0;
    cursor: pointer;
    height: ${({ footer }: StyledCardProps) => (!!footer ? '30.071rem' : '25rem')};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    animation: ${({ show }: StyledCardProps) =>
        show
            ? 'none;'
            : css`
                  ${fadeIn} 0.1s linear forwards;
              `};

    && {
        width: 31.65rem;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    }

    &&:hover {
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
    }

    &&:active {
        border: 1px solid var(--primary-color-dark);
        box-shadow: none;
    }

    &&:focus {
        box-shadow: 0px 0px 0px 2px var(--primary-color);
    }

    @media (max-width: ${MobileViewBreakpoint ?? 768}px) {
        && {
            width: 100%;
        }
    }
`;

const ImageContainer = styled.div`
    position: relative;
    display: inline-block;
    text-align: center;
    min-height: 213px;
    background-color: var(--background-color);
`;

type ImageContainerProps = { coverImage: boolean };

type StyledSmartImageProps = SmartImageProps & ImageContainerProps;
const StyledImage = styled(({ ...props }: StyledSmartImageProps) => <SmartImage {...props} />)<StyledSmartImageProps>`
    width: 100%;
    height: 15.214rem;
    object-fit: cover;
    ${({ coverImage }: ImageContainerProps) =>
        !coverImage &&
        'width: 150px; height: 127px; position: absolute; top:50%; left: 50%; transform: translate(-50%, -62%);'}
`;

const ContextMenuContainer = styled.div`
    position: absolute;
    top: 1.7rem;
    height: 2.28rem;
    width: 2.28rem;
    right: 1.7rem;
    z-index: 1;
`;

const StyledLinesEllipsisHeader = styled.div`
    font-size: 1.3rem;
    font-weight: bold;
    min-height: 4rem;
    margin-left: 1.7rem;
    overflow: hidden;
    border-top: none;
    padding: 0;
    flex-grow: 1;
    padding-top: 0.5rem;
    margin-right: 1.7rem;
    @media (max-width: ${MobileViewBreakpoint}px) {
        font-size: 1.1rem;
    }
`;

const StyledLinesEllipsisArticleContent = styled.div`
    overflow: hidden;
    border-top: none;
    min-height: 1.5rem;
    padding: 0;
    font-size: 1.14rem;
    line-height: 1.71rem;
    flex-grow: 1;
    margin-left: 1.7rem;
    margin-right: 1.7rem;
    @media (max-width: ${MobileViewBreakpoint}px) {
        font-size: 1rem;
    }
`;

const StyledFooter = styled(Card.Content)`
    &&& {
        border: none;
        padding: 1rem;
        height: min-content;
        flex-grow: 0;
    }
`;

const Badge = styled.div`
    position: absolute;
    bottom: 1rem;
    left: 2rem;
`;

const Message = styled.p`
    font-size: 0.78rem;
    font-style: italic;
    margin: 0;
    margin-top: 0.3rem;
    margin-left: 1.7rem;
    @media (max-width: ${MobileViewBreakpoint}px) {
        font-size: 0.6rem;
    }
`;

interface IArticleCardProps {
    header?: string | null;
    content?: string | null;
    footer?: JSX.Element;
    image: ComponentProps<typeof SmartImage>['sources'];
    badge?: JSX.Element | null;
    isLoading?: boolean;
    articleId: string;
    coverImage?: boolean;
    canEdit?: boolean;
    status: ArticleModel['status'];
    handleOpenModal(arg: ModalStateType): void;
}

const ArticleCard: React.FC<React.PropsWithChildren<IArticleCardProps>> = ({
    header,
    content,
    footer,
    image,
    badge,
    isLoading,
    articleId,
    coverImage = false,
    canEdit = false,
    status,
    handleOpenModal,
}: IArticleCardProps): JSX.Element => {
    const { t } = useTranslation('Dashboard', { keyPrefix: 'articles' });
    const showNotPublishedChangesMessage = status === 'PublishedButChanged' && canEdit;
    return (
        <StyledCard show={isLoading} footer={footer}>
            {canEdit ? (
                <ContextMenuContainer>
                    <ArticleCardContextMenu articleId={articleId} status={status} handleOpenModal={handleOpenModal} />
                </ContextMenuContainer>
            ) : null}
            <ImageContainer>
                <StyledImage coverImage={coverImage} sources={image} />
                <Badge>{badge}</Badge>
            </ImageContainer>
            {header ? (
                <StyledLinesEllipsisHeader>
                    <LinesEllipsis text={header || ''} maxLine="3" ellipsis="" trimRight basedOn="letters" />
                </StyledLinesEllipsisHeader>
            ) : null}
            {content && (
                <StyledLinesEllipsisArticleContent>
                    <LinesEllipsis text={content} maxLine="2" ellipsis="" trimRight basedOn="letters" />
                </StyledLinesEllipsisArticleContent>
            )}
            {showNotPublishedChangesMessage ? <Message>{t('articleCardUnpublishedChangesMessage')}</Message> : null}
            {footer && <StyledFooter>{footer}</StyledFooter>}
        </StyledCard>
    );
};

export default ArticleCard;
