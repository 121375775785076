import { FC } from 'react';
import { FlexBox } from '../../../components/styleds';
import { useGetOrder } from '../../ShopAtWork/apiQueries/useShopOrders';
import { ShopOverviewView } from '../../ShopAtWork/ShopAtWork';
import CustomerSection from './components/CustomerSection';
import DeliverySection from './components/DeliverySection';
import DetailsSection from './components/DetailsSection';
import HeaderStrip from './components/HeaderStrip';
import PaymentSection from './components/PaymentSection';
import ProductsInfoSection from './components/ProductsInfoSection';
import SellerSection from './components/SellerSection';
import SummarySection from './components/SummarySection';
import { FoodOrderDetailsProps } from './types';

const FoodOrderDetails: FC<FoodOrderDetailsProps> = ({ view, orderId }) => {
    const { data: order, isLoading: isLoadingOrder } = useGetOrder(orderId);

    return (
        <FlexBox
            position={'absolute'}
            left={'0px'}
            top={'0px'}
            height={'100%'}
            align={'center'}
            background={'#fbfbfb'}
            dir={'column'}
        >
            <HeaderStrip isLoadingOrder={isLoadingOrder} order={order} view={view} />
            <FlexBox overflow={'auto'} justify={'center'}>
                <FlexBox dir={'column'} position={'relative'} maxW={'90rem'}>
                    <FlexBox gap={'1.143rem'} pd={'1.714rem'} wrap={['nowrap', 'wrap']}>
                        {/*LEFT COLUMN*/}
                        <FlexBox dir={'column'} gap={'0.714rem'} width={['51.429rem', '100%']}>
                            <ProductsInfoSection isLoadingOrder={isLoadingOrder} orderLines={order?.orderLines} />
                            <SummarySection isLoadingOrder={isLoadingOrder} order={order} />
                        </FlexBox>

                        {/*RIGHT COLUMN*/}
                        <FlexBox dir={'column'} gap={'0.714rem'} width={['28.571rem', '100%']}>
                            <DetailsSection isLoadingOrder={isLoadingOrder} order={order} view={view} />
                            <DeliverySection isLoadingOrder={isLoadingOrder} order={order} />
                            {view === ShopOverviewView.MyPurchases && (
                                <SellerSection isLoadingOrder={isLoadingOrder} vendor={order?.vendor} />
                            )}
                            {view === ShopOverviewView.CompanyPurchases && (
                                <CustomerSection
                                    isLoadingOrder={isLoadingOrder}
                                    customer={order?.employee}
                                    comment={order?.comment}
                                />
                            )}
                            <PaymentSection isLoadingOrder={isLoadingOrder} order={order} />
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    );
};
export default FoodOrderDetails;
