import { ReactElement, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { Category } from '../../../../../../../api/apiTypes/ticketingApiTypes';
import {
    FullPageLoader,
    GhostPrimaryButton,
    InputField,
    Modal,
    ModalType,
    PrimaryButton,
} from '../../../../../../../components';
import { useCreateTicketCategory } from '../../../../../apiQueries/useTicketingTypeCategory';
import { Paragraph } from '../../../../../common/styles';

const Container = styled.div`
    width: 100%;
    margin-bottom: 1.714rem;
`;

const ButtonWrapper = styled.p`
    margin: 0 0.7rem 0 0;
`;

const FooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ModalContentWrapper = styled.div`
    padding: 2rem;
`;

export const DuplicateForm = styled.form`
    display: grid;
    row-gap: 1.71rem;
    grid-auto-columns: auto;
`;

const LoaderContainer = styled.div`
    min-height: min(80vh, 15rem);
    display: flex;
    align-items: center;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-left: 1rem;
    }
`;

let existingCategoriesForTicketType: string[] = [];

type Form = { name: string };

const schema = (t: TFunction<'Ticketing', 'settings'>) =>
    yup.object().shape({
        name: yup
            .string()
            .required(t('errorMessageNameIsRequired'))
            .min(2, t('errorMessageNameMustHaveMinValue'))
            .max(100, t('errorMessageNameMustHaveMaxValue'))
            .test(`unique-category`, t('errorMessageNameIsDublicateValue'), function (value) {
                return value !== undefined &&
                    Array.isArray(existingCategoriesForTicketType) &&
                    existingCategoriesForTicketType.length
                    ? !existingCategoriesForTicketType.includes(value.toString().toLowerCase())
                    : true;
            }),
    });

interface CategoryDuplicateProps {
    category: Category;
    existingTicketTypeCategoryNames: string[];
    typeId: string;
}

const CategoryDuplicate = ({
    category,
    existingTicketTypeCategoryNames,
    typeId,
}: CategoryDuplicateProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const { t: tCommon } = useTranslation('common');
    existingCategoriesForTicketType = existingTicketTypeCategoryNames.map((x) => x.toLowerCase());
    const formId = 'duplicateCategoryForm' + category.id;
    const [createCategory, { isLoading, isSuccess }] = useCreateTicketCategory();
    const [openDuplicateModal, setOpenDuplicateModal] = useState(false);

    useEffect(() => {
        if (isSuccess) setOpenDuplicateModal(false);
    }, [isSuccess]);

    const {
        register,
        handleSubmit,
        reset: formReset,
        watch,
        formState: { errors },
    } = useForm<Form>({
        mode: 'onTouched',
        resolver: yupResolver(schema(t)),
        defaultValues: {
            name: '',
        },
    });

    const [categoryDuplicateName] = watch(['name']);

    const onSubmit = (formField: Form) => {
        createCategory([
            typeId,
            {
                ...category,
                name: formField.name,
            },
        ]);
    };

    const handleAbortClick = () => {
        setOpenDuplicateModal(false);
    };

    const DuplicateModalFooter = (
        <FooterWrapper>
            <GhostPrimaryButton
                type="button"
                onClick={() => {
                    formReset();
                    setOpenDuplicateModal(false);
                }}
            >
                {tCommon('cancelButton')}
            </GhostPrimaryButton>
            <StyledPrimaryButton
                type="submit"
                form={formId}
                disabled={isLoading || Object.keys(errors).length > 0 ? true : false || !categoryDuplicateName}
            >
                {tCommon('addButton')}
            </StyledPrimaryButton>
        </FooterWrapper>
    );
    return (
        <Container>
            <Paragraph>{t('dublicateWarningMessage')}</Paragraph>
            <ButtonWrapper>
                <PrimaryButton onClick={() => setOpenDuplicateModal(true)}>{t('dublicateButton')}</PrimaryButton>
            </ButtonWrapper>
            <Modal
                type={ModalType.Small}
                key={`duplicateModal${category.id}`}
                title={`${t('dublicateButton')} ${category.name}`}
                onClose={handleAbortClick}
                hideCloseButton
                open={openDuplicateModal}
                bottomContent={DuplicateModalFooter}
            >
                {isLoading ? (
                    <LoaderContainer>
                        <FullPageLoader text="" />
                    </LoaderContainer>
                ) : (
                    <ModalContentWrapper key={`duplicateCategoryModalWrapper${category.id}`}>
                        <Paragraph>{t('nameOfNewCategory')}</Paragraph>
                        <DuplicateForm id={formId} onSubmit={handleSubmit(onSubmit)}>
                            <InputField
                                label={tCommon('name')}
                                {...register('name')}
                                placeholder={t('nameOfCategoryPlaceholder')}
                                required
                                error={errors.name?.message}
                            />
                        </DuplicateForm>
                    </ModalContentWrapper>
                )}
            </Modal>
        </Container>
    );
};

export default CategoryDuplicate;
