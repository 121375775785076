import { useEffect, useState } from 'react';
import { TicketCommentWithSeen, TicketSeenEntity } from './../../../../../../../app/api/apiTypes/ticketingApiTypes';
import { useSetReadCount } from './../../../../../../../app/modules/Ticketing/apiQueries/useTicketComments';

export const useCommentHighlighting = (
    comments: TicketCommentWithSeen[],
    isLoadingComments: boolean,
    ticketNumber: string,
    ticketSeenEntity: TicketSeenEntity,
): void => {
    const [setReadCount] = useSetReadCount();
    const [initialLoading, setInitialLoading] = useState(true);

    useEffect(() => {
        if (initialLoading && !isLoadingComments && comments) {
            setReadCount([ticketNumber, ticketSeenEntity]);
            setInitialLoading(false);
        }
    }, [comments, initialLoading, isLoadingComments, setReadCount, ticketNumber, ticketSeenEntity]);
};
