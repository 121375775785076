import { Key, KeyboardEvent, ReactElement } from 'react';
import styled from 'styled-components';
import { SvgIcon } from '../../components';
import { ColumnType } from './Table';

type StyledTableHeaderProps = { collapse?: boolean };
const StyledTableHeader = styled.thead`
    border: 1px solid #d4d6d7;
    display: ${({ collapse }: StyledTableHeaderProps) => (collapse ? 'none' : 'contents')};
`;

const Row = styled.tr`
    height: 1rem;
`;

type HeaderProps = { disabled: boolean; path?: ColumnType };
const Header = styled.th`
    white-space: nowrap;
    font-size: 1.143rem;
    line-height: 1.714rem;
    text-align: left;
    padding: 0.429rem;
    padding-bottom: 0.714rem;

    ${({ path }: HeaderProps) => path !== undefined && { cursor: 'pointer' }};
    ${({ disabled }: HeaderProps) => disabled && { color: '#a8adaf', pointerEvents: 'none' }};

    &:first-child {
        padding-left: 1rem;
    }

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

const HeaderContent = styled.div`
    display: flex;
    align-items: center;
    user-select: none;
    font-weight: normal;
    color: var(--text-medium-emphasis-color);
`;

type FilterIconProps = { filter: unknown };
const FilterIcon = styled.span`
    display: flex;
    align-items: center;
    padding-right: 0.429rem;

    svg {
        height: 0.324rem;
        width: 0.571rem;

        transform: ${({ filter }: FilterIconProps) => (filter === 'asc' ? 'rotate(180deg)' : 'rotate(0)')};
        path {
            fill: var(--primary-color);
        }
    }
`;

interface TableColumn {
    label: string | JSX.Element;
    path?: ColumnType;
    width?: number | string;
    key: Key;
}

interface TableHeaderProps {
    columns: TableColumn[];
    sortColumn: { path: ColumnType; order: unknown };
    onSort(sortColumn: { path: ColumnType; order: unknown }): void;
    disabled: boolean;
    collapse?: boolean;
    className?: string;
}

const TableHeader = ({
    columns,
    sortColumn,
    onSort,
    disabled,
    collapse,
    className,
}: TableHeaderProps): ReactElement => {
    const raiseSort = (path: ColumnType) => {
        const sortedColumn = { ...sortColumn };
        if (sortColumn.path === path) sortedColumn.order = sortColumn.order === 'asc' ? 'desc' : 'asc';
        else {
            sortedColumn.path = path;
            sortedColumn.order = 'asc';
        }
        onSort(sortedColumn);
    };

    const renderSortIcon = (column: { path?: ColumnType }): JSX.Element | null => {
        if (column.path !== sortColumn.path) return null;
        return (
            <FilterIcon filter={sortColumn.order}>
                <SvgIcon name="FilledCaretDownIcon" />
            </FilterIcon>
        );
    };

    const onKeyPressHandler = (e: KeyboardEvent<HTMLTableCellElement>, path?: ColumnType) => {
        const raiseSortCondition = e.key === 'Enter' && path !== undefined;
        if (raiseSortCondition) raiseSort(path);
    };

    const handleHeaderClick = (path?: ColumnType) => {
        path !== undefined && raiseSort(path);
    };

    return (
        <StyledTableHeader collapse={collapse} className={className}>
            <Row>
                {columns.map((column) => (
                    <Header
                        disabled={disabled}
                        key={column.key}
                        onClick={() => handleHeaderClick(column.path)}
                        style={{ width: column.width || 100 }}
                        tabIndex={0}
                        onKeyPress={(e: KeyboardEvent<HTMLTableCellElement>) => onKeyPressHandler(e, column.path)}
                        path={column.path}
                    >
                        <HeaderContent>
                            {column.path !== undefined && renderSortIcon(column)}
                            {column.label}
                        </HeaderContent>
                    </Header>
                ))}
            </Row>
        </StyledTableHeader>
    );
};

export default TableHeader;
