import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexBox, StressedText, Typography } from '../../../../../components/styleds';
import OrderCard from '../components/OrderCard';
import { SellerSectionProps } from './types';

const SellerSection: FC<SellerSectionProps> = ({ isLoadingOrder, vendor }) => {
    const { t } = useTranslation('Food', { keyPrefix: 'foodForMeeting' });

    return (
        <OrderCard title={t('foodOrderSellerSectionTitle')} isSkeleton={isLoadingOrder}>
            <FlexBox gap={'0.571rem'} dir={'column'}>
                <Typography
                    color={'#475156'}
                    fontWeight={500}
                    lineHeight={'1.714rem'}
                    fontSize={'1.286rem'}
                    letterSpacing={'0.2px'}
                    lineClamp={'1'}
                    width={'auto'}
                >
                    {vendor?.name}
                </Typography>
                {!!vendor?.organizationNumber && (
                    <Typography
                        color={'#475156'}
                        fontWeight={500}
                        lineHeight={'1.714rem'}
                        fontSize={'1.286rem'}
                        letterSpacing={'0.2px'}
                        lineClamp={'1'}
                        width={'auto'}
                    >
                        <StressedText fontWeight={900}>{t('foodOrderSellerSectionOrgNr')}&nbsp;</StressedText>
                        {vendor.organizationNumber}
                    </Typography>
                )}
                {!!vendor && (
                    <Typography
                        color={'#475156'}
                        fontWeight={500}
                        lineHeight={'1.714rem'}
                        fontSize={'1.286rem'}
                        letterSpacing={'0.2px'}
                        lineClamp={'1'}
                        width={'auto'}
                    >
                        <StressedText fontWeight={900}>{t('foodOrderSellerSectionAddress')}&nbsp;</StressedText>
                        {`${vendor.street}, ${vendor.zipCode}, ${vendor.city}`}
                    </Typography>
                )}
            </FlexBox>
        </OrderCard>
    );
};

export default SellerSection;
