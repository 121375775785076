import { ReactElement } from 'react';
import { ChangeHandler, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UpdateTicketingTypeCategory } from '../../../../../../../api/apiTypes/ticketingApiTypes';
import { InputField, PrimaryButton, SvgIcon } from '../../../../../../../components';
import { Header } from '../../../../../common/styles';

export const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
`;

const CategoryInputField = styled(InputField)`
    margin-right: 0.571rem;
`;

const SaveButton = styled(PrimaryButton)`
    &&& {
        height: 2.69rem;
        margin-top: 1.428rem;
    }
`;

const SaveButtonText = styled.span`
    padding-right: 0.667rem;
`;

const Description = styled.p`
    font-size: 1.142rem;
`;

type UpdateTicketingTypeCategoryFields = Pick<UpdateTicketingTypeCategory, 'name' | 'externalLinkUrl'>;

interface FormField {
    name: keyof UpdateTicketingTypeCategoryFields;
    label: string;
    placeholder: string;
    required: boolean;
}

interface CategoryEditFormProps {
    defaultValues: UpdateTicketingTypeCategoryFields;
    context?: any;
    formSchema: Resolver<UpdateTicketingTypeCategoryFields, any> | undefined;
    formId: string;
    headerLabel: string;
    formFieldConfig: FormField;
    isLoading: boolean;
    changesSaved: (isSaved: boolean) => void;
    onSubmit: (data: UpdateTicketingTypeCategoryFields) => void;
    isSuccess: boolean;
    description?: string;
}

const CategoryEditForm = ({
    defaultValues,
    context,
    formSchema,
    formId,
    headerLabel,
    formFieldConfig,
    isLoading,
    changesSaved,
    onSubmit,
    isSuccess,
    description,
}: CategoryEditFormProps): ReactElement => {
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<UpdateTicketingTypeCategoryFields>({
        mode: 'onTouched',
        resolver: formSchema,
        defaultValues,
        context,
    });
    const { name, label, placeholder, required } = formFieldConfig;
    const isEqualToDefault = watch(name) === defaultValues[name];
    const { onChange, ...rest } = register(name);

    const customOnChange: ChangeHandler = async (event) => {
        const target = event.target;

        if (typeof target.value === 'string' && target.value) {
            target.value = target.value.trim();
        }

        onChange(event);
    };

    return (
        <>
            <Header>{headerLabel}</Header>
            {description && <Description>{description}</Description>}
            <Form id={formId} onSubmit={handleSubmit(onSubmit)} onChange={() => changesSaved(isEqualToDefault)}>
                <CategoryInputField
                    label={label}
                    {...rest}
                    onChange={customOnChange}
                    placeholder={placeholder}
                    required={required}
                    error={errors[name]?.message}
                />
                <SaveButton
                    type="submit"
                    form={formId}
                    disabled={isEqualToDefault || Object.keys(errors).length > 0 || isLoading}
                >
                    <>
                        <SaveButtonText>
                            {isLoading ? tCommon('storing') : isSuccess ? t('saved') : tCommon('saveButton')}
                        </SaveButtonText>
                        <SvgIcon name="CheckIcon" />
                    </>
                </SaveButton>
            </Form>
        </>
    );
};

export default CategoryEditForm;
