import { ReactElement } from 'react';
import styled from 'styled-components';
import SvgIcon, { SvgIconName } from '../icons/SvgIcon';

const theme = {
    information: {
        backgroundColor: '#E1F6FF',
        iconName: 'InfoCircleOutline' as SvgIconName,
        iconColor: '#001EFF',
        iconColorHighContrast: '#BEE4FF',
    },
    error: {
        backgroundColor: '#FFD4D4',
        iconName: 'ForbiddenIcon' as SvgIconName,
        iconColor: 'var(--error-color-dark)',
        iconColorHighContrast: '#FF5E5E',
    },
    warning: {
        backgroundColor: '#FFF6C9',
        iconName: 'InfoCircle' as SvgIconName,
        iconColor: '#D2B100',
        iconColorHighContrast: '#FFD600',
    },
    success: {
        backgroundColor: '#D2F8B6',
        iconName: 'SuccessIcon' as SvgIconName,
        iconColor: 'var(--success-color-dark)',
        iconColorHighContrast: '#73CF2A',
    },
    highContrastBackgroundColor: '#263238',
    fontColor: '#475156',
    highContrastFontColor: '#FFFFFF',
};

type messageType = 'information' | 'error' | 'warning' | 'success';

type ContainerProps = { type: messageType; dark: boolean };
const Container = styled.div`
    background-color: ${({ type, dark }: ContainerProps) =>
        dark ? theme.highContrastBackgroundColor : theme[type].backgroundColor};
    color: ${({ dark }: ContainerProps) => (dark ? theme.highContrastFontColor : theme.fontColor)};
    border-radius: 5px;
    padding: 0.786rem;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
`;

type IconWrapper = { type: messageType; dark: boolean };
const IconWrapper = styled.div`
    display: flex;
    flex: 2.571rem 0 0;
    justify-content: center;

    svg {
        width: 1.429rem;
        height: 1.429rem;

        path {
            fill: ${({ type, dark }: IconWrapper) =>
                dark ? theme[type].iconColorHighContrast : theme[type].iconColor};
        }
    }
`;

type CloseIconWrapperProps = { type: messageType; dark: boolean };
const CloseIconWrapper = styled.div`
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;

    svg {
        width: 0.964rem;
        height: 0.964rem;
        margin: 0.5rem 1.429rem;

        path {
            fill: ${({ dark }: CloseIconWrapperProps) => (dark ? theme.highContrastFontColor : theme.fontColor)};
        }
    }
`;

const Message = styled.div`
    margin: 0;
    padding-left: 0.5rem;
    flex: 1;
`;

interface InformationMessageProps {
    type: messageType;
    dark?: boolean;
    onClose?(): void;
    children?: React.ReactNode;
    className?: string;
}

const InformationMessage = ({
    type,
    dark = false,
    onClose,
    children,
    className,
}: InformationMessageProps): ReactElement => {
    return (
        <Container type={type} dark={dark} className={className} id="informationMessage">
            <IconWrapper type={type} dark={dark}>
                <SvgIcon name={theme[type].iconName} />
            </IconWrapper>
            <Message>{children}</Message>
            {onClose && (
                <CloseIconWrapper type={type} dark={dark} role="button" onClick={onClose}>
                    <SvgIcon name="CloseIcon" />
                </CloseIconWrapper>
            )}
        </Container>
    );
};

export default InformationMessage;
