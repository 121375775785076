import { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TicketType } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { AppLink } from '../../../../../../components';
import useResizeWindow from '../../../../../../utility/hooks/useResizeWindow';
import { useUpdateTicketType } from '../../../../apiQueries/useTicketingType';
import useTicketingNavigation from '../../../../useTicketingNavigation';
import TitleBar from '../../TitleBar';

const StyledAppLink = styled(AppLink)`
    cursor: pointer;
    outline: none;
    display: flex;
    margin-top: 1.14rem;

    :focus,
    :focus-visible {
        border: 1px solid var(--primary-color);
    }
    :focus:not(:focus-visible) {
        border: none;
        border-bottom: 1px solid var(--border-color);
    }
`;

const Card = styled.div`
    width: 100%;
    border-radius: 0.4rem;
    padding: 1.71rem;
    background: var(--surface-color-light);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    color: var(--text-high-emphasis-color);
`;

const StyledDivider = styled.hr`
    border: none;
    border-top: 1px solid #d4d6d7;
    margin: 1.142rem 0;
`;

const StyledTitleBar = styled(TitleBar)`
    padding: 0;
    margin-bottom: 0.571rem;
`;

const Text = styled.p`
    font-size: 1.14rem;
`;

const BoldText = styled.p`
    font-weight: 900;
    font-size: 1.142rem;
    line-height: 1.714rem;
`;

const Subtitle = styled.h5`
    font-style: normal;
    font-weight: 400;
    font-size: 0.857rem;
    line-height: 1.428rem;
    text-transform: uppercase;
`;

const CategoriesList = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
`;

const CategoriesItem = styled.li`
    margin-right: 1.857rem;
    margin-bottom: 0.571rem;
    word-wrap: break-word;
    :last-child {
        margin-right: 0;
    }
    max-width: 100%;
`;

interface TypeCardProps {
    type: TicketType;
}
const TypeCard = ({
    type: { id, name, isPublished, internalDescription, categories, caseWorkers },
}: TypeCardProps): ReactElement => {
    const { getTypeTabLink } = useTicketingNavigation();
    const { t } = useTranslation('Ticketing');
    const containerRef = useRef<HTMLDivElement>(null);
    const containerWidth = useResizeWindow(containerRef);
    const tabletBreakPoint = 463;
    const mobileBreakPoint = 315;
    const isTabletView = containerWidth < tabletBreakPoint;
    const isMobileView = containerWidth < mobileBreakPoint;

    const countOfDisplayedCategories = isMobileView ? 3 : isTabletView ? 4 : 5;

    const [updateType] = useUpdateTicketType();

    const handleOnClickPublish = (isPublished: boolean) => {
        updateType([id, { isPublished }]);
    };

    return (
        <StyledAppLink key={id} to={getTypeTabLink(id, 'general')}>
            <Card ref={containerRef}>
                <StyledTitleBar name={name} isPublished={isPublished} handleOnClickPublish={handleOnClickPublish} />
                <Text>{internalDescription}</Text>
                <StyledDivider />
                <BoldText>
                    {caseWorkers.length} {caseWorkers.length !== 1 ? 'saksbehandlere' : 'saksbehandler'}
                </BoldText>
                <Subtitle>{t('settings.newTicketCategoryHeader')}</Subtitle>
                <CategoriesList>
                    {categories.slice(0, countOfDisplayedCategories).map((category) => {
                        return (
                            <CategoriesItem key={category.id}>
                                <BoldText>{category.name} </BoldText>
                            </CategoriesItem>
                        );
                    })}
                </CategoriesList>
                {categories.length > countOfDisplayedCategories && (
                    <BoldText>
                        +{categories.length - countOfDisplayedCategories} {t('settings.more')}
                    </BoldText>
                )}
            </Card>
        </StyledAppLink>
    );
};
export default TypeCard;
