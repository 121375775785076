import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalType } from '../../../../../../../components';
import { Button, FlexBox, Typography } from '../../../../../../../components/styleds';
import SuccessIconSvg from '../../../../../../Booking/layouts/components/sessionsOverview/components/informationBlock/SuccessIconSvg';
import { OrderCanceledPopupProps } from './types';

const OrderCanceledPopup: FC<OrderCanceledPopupProps> = ({ isOpened, setIsOpened, backToOrderHandler }) => {
    const { t } = useTranslation('Food', { keyPrefix: 'foodForMeeting' });
    const hideModalCallback = useCallback(() => {
        setIsOpened(false);
    }, [setIsOpened]);

    const bottomContent = useMemo(() => {
        return (
            <FlexBox gap={'1.143rem'} justify={'flex-end'}>
                <Button
                    width={'auto'}
                    borderWidth={'0.143rem'}
                    borderColor={'var(--primary-color)'}
                    pd={'0.571rem 1.143rem'}
                    br={'0.357rem'}
                    justify={'center'}
                    align={'center'}
                    background={'#fff'}
                    onClick={backToOrderHandler}
                >
                    <Typography fontSize={'1.143rem'} fontWeight={700} color={'var(--primary-color)'}>
                        {t('foodOrderDetailHeaderEditOrderSuccessConfirmationSubmitBtnText')}
                    </Typography>
                </Button>
            </FlexBox>
        );
    }, [backToOrderHandler, t]);

    return (
        <Modal
            type={ModalType.Medium}
            open={isOpened}
            title={t('foodOrderDetailHeaderEditOrderSuccessConfirmationTitle')}
            onClose={hideModalCallback}
            bottomContent={bottomContent}
        >
            <FlexBox pd={'1.714rem'} gap={'1.714rem'} dir={'column'} justify={'center'} align={'center'}>
                <SuccessIconSvg />
                <Typography
                    color={'#006729'}
                    fontSize={'1.429rem'}
                    fontWeight={900}
                    lineHeight={'2.286rem'}
                    textAlign={'center'}
                >
                    {t('foodOrderDetailHeaderEditOrderSuccessConfirmationDescription')}
                </Typography>
            </FlexBox>
        </Modal>
    );
};

export default OrderCanceledPopup;
