import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import GhostPrimaryButton from '../../buttons/GhostPrimaryButton';
import PrimaryButton from '../../buttons/PrimaryButton';
import SearchSelect, { FilterView } from '../../select/searchSelect/SearchSelect';
import { GroupedOptionType, OptionType } from '../../select/searchSelect/types';
import Drawer from '../Drawer';

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-bottom: 1.142rem;
    }
`;

const BottomContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 1.142rem;
    border-top: 1px solid #e9ebeb;
`;

interface FilteringDrawerProps {
    isVisible: boolean;
    onClose(): void;
    onReset(): void;
    filterValue: OptionType[];
    filterOptions: OptionType[] | GroupedOptionType[];
    isSearchable: boolean;
    filterName: string;
    filterTitle: string;
    onFilterChange(options: OptionType[]): void;
    itemsCount: number;
}

const FilteringDrawer = ({
    isVisible,
    onClose,
    onReset,
    filterValue,
    filterOptions,
    isSearchable,
    filterName,
    filterTitle,
    onFilterChange,
    itemsCount,
}: FilteringDrawerProps): ReactElement => {
    const handleConfirm = () => {
        onClose();
    };
    const { t } = useTranslation('common');

    return (
        <Drawer onClose={onClose} open={isVisible}>
            <>
                <SearchSelect
                    onChange={onFilterChange}
                    value={filterValue}
                    name={filterName}
                    title={filterTitle}
                    onResetOptions={onReset}
                    options={filterOptions}
                    isSearchable={isSearchable}
                    expanded
                    onClose={onClose}
                    view={FilterView.Mobile}
                />
                <BottomContentContainer>
                    <StyledPrimaryButton onClick={handleConfirm}>
                        {t('viewTransactions', { itemsCount })}
                    </StyledPrimaryButton>
                    <GhostPrimaryButton onClick={onReset}>{t('resetFilters')}</GhostPrimaryButton>
                </BottomContentContainer>
            </>
        </Drawer>
    );
};

export default FilteringDrawer;
