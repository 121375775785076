export enum Checked {
    FULL,
    PARTIAL,
    NONE,
}
export type assetsControlNodeInput = Omit<AccessNode, 'children'> & {
    checked: Checked;
    children?: assetsControlNodeInput[];
};
export type AccessControlFormData = {
    groupId: string;
    isActive?: boolean;
    name: string;
    canUserUpdateGroup?: boolean;
};

export type AccessEntitiesType = {
    access: assetsControlNodeInput[];
    domains: assetsControlNodeInput[];
    groups: assetsControlNodeInput[];
} | null;

export interface AccessNode {
    id: string;
    portalId?: number;
    buildingGuid?: string;
    name: string;
    description?: string;
    externalId?: string;
    domains?: AccessNode[];
    groups?: AccessNode[];
    canUserUpdateGroup?: boolean;
    isActive?: boolean;
}

export enum MobileKeyStatus {
    Disabled = 0,
    CanReassign = 10,
    CanCreate = 20,
}
export interface AccessSystemNode extends AccessNode {
    mobileKeyStatus: MobileKeyStatus;
}

export interface MobileKeyActivationAvailability {
    id: string;
    name: boolean;
    mobileKeyStatus: MobileKeyStatus;
}

export const createAccessEntities = (nodes: assetsControlNodeInput[]): NonNullable<AccessEntitiesType> => {
    const accessEntities: AccessEntitiesType = {
        access: [],
        domains: [],
        groups: [],
    };
    for (const node of nodes) {
        if (node.checked !== Checked.FULL) {
            const newAccessEntities = createAccessEntities(node.children ?? []);
            accessEntities.access = [...accessEntities.access, ...newAccessEntities.access];
            accessEntities.domains = [...accessEntities.domains, ...newAccessEntities.domains];
            accessEntities.groups = [...accessEntities.groups, ...newAccessEntities.groups];
            continue;
        }
        if (node.domains) {
            accessEntities.access.push(node);
        }
        if (node.groups) {
            accessEntities.domains.push(node);
            accessEntities.groups.push(node);
        } else {
            accessEntities.groups.push(node);
        }
    }
    return accessEntities;
};
export const updatePartialsTree = (node: assetsControlNodeInput): assetsControlNodeInput => {
    if (!node.children?.length) {
        return node;
    }
    const children = node.children.map((child) => updatePartialsTree(child));
    const full = children.every((child) => child.checked === Checked.FULL);
    const partial = children.some((child) => child.checked === Checked.FULL || child.checked === Checked.PARTIAL);
    return {
        ...node,
        checked: full ? Checked.FULL : partial ? Checked.PARTIAL : Checked.NONE,
        children,
    };
};

export const updatedTree = (
    orgAccessTree: assetsControlNodeInput[],
    node: assetsControlNodeInput,
    isChecked: boolean,
): assetsControlNodeInput[] => {
    return JSON.parse(
        JSON.stringify(orgAccessTree, (_, val) => {
            if (typeof val === 'object' && !Array.isArray(val) && (val?.id === node.id || val === node.id)) {
                const checked = isChecked ? Checked.FULL : Checked.NONE;
                return {
                    ...val,
                    checked,
                    children: node.children ? setCheckedForAllNodes(node.children, checked) : undefined,
                };
            }
            return val;
        }),
    );
};

export const createInputTree = (
    node: AccessNode,
    accessEntities: {
        access: assetsControlNodeInput[];
        domains: assetsControlNodeInput[];
        groups: assetsControlNodeInput[];
    } | null,
): assetsControlNodeInput => {
    if (node.domains?.length) {
        return {
            ...node,
            checked: accessEntities?.access.some((access) => access.id === node.id) ? Checked.FULL : Checked.NONE,
            children: node.domains.map((node) => createInputTree(node, accessEntities)),
        };
    }
    if (node.groups?.length) {
        return {
            ...node,
            checked: accessEntities?.domains.some((access) => access.id === node.id) ? Checked.FULL : Checked.NONE,
            children: node.groups.map((node) => createInputTree(node, accessEntities)),
        };
    }
    return {
        ...node,
        checked: accessEntities?.groups.some((access) => access.id === node.id) ? Checked.FULL : Checked.NONE,
    };
};

export const setCheckedForAllNodes = (nodes: assetsControlNodeInput[], checked: Checked): assetsControlNodeInput[] => {
    return nodes.map((node) => {
        const nodeObject = {
            ...node,
            checked,
        };
        return node.children ? { ...nodeObject, children: setCheckedForAllNodes(node.children, checked) } : nodeObject;
    });
};
export const createInitialPartialsInTree = (node: assetsControlNodeInput): assetsControlNodeInput => {
    if (node.checked === Checked.FULL) {
        return {
            ...node,
            children: node.children && setCheckedForAllNodes(node.children, Checked.FULL),
        };
    }
    const children = node.children?.map((node) =>
        createInitialPartialsInTree({ ...node, checked: node.isActive ? Checked.FULL : Checked.NONE }),
    );

    const getChecked = (children: assetsControlNodeInput[]) => {
        if (children?.every((node) => node.checked === Checked.FULL)) return Checked.FULL;
        if (children?.every((node) => node.checked === Checked.NONE)) return Checked.NONE;
        return Checked.PARTIAL;
    };

    return {
        ...node,
        checked: children ? getChecked(children) : Checked.NONE,
        children,
    };
};

export const prepareDataForRequest = (accessItem: assetsControlNodeInput): AccessControlFormData => {
    return {
        ...accessItem,
        groupId: accessItem.id,
        isActive: accessItem.checked === Checked.FULL,
    };
};

export const getAllGroupsItem = (
    orgAccessArray: assetsControlNodeInput[],
    groupsArray: assetsControlNodeInput[],
): assetsControlNodeInput[] => {
    orgAccessArray.forEach((assetControlNode: assetsControlNodeInput) => {
        assetControlNode.children
            ? getAllGroupsItem(assetControlNode.children, groupsArray)
            : groupsArray.push(assetControlNode);
    });
    return groupsArray;
};
