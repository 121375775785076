import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GhostPrimaryButton, Modal, ModalType, PrimaryButton } from '../../../../../../../components';

const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    padding: 1.7rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Paragraph = styled.p`
    font-size: 1.143rem;
    line-height: 1.714rem;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-left: 1rem;
    }
`;

interface NewAssigneeModalProps {
    open: boolean;
    onClickNext(): void;
    onClose(): void;
}

const RemoveYourselfModal = ({ open, onClickNext, onClose }: NewAssigneeModalProps): ReactElement => {
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    return (
        <Modal
            title={t('removeYourselfTitle')}
            onClose={() => null}
            open={open}
            type={ModalType.Small}
            hideCloseButton={true}
            bottomContent={
                <ButtonContainer>
                    <GhostPrimaryButton onClick={onClose}>{tCommon('cancelButton')}</GhostPrimaryButton>
                    <StyledPrimaryButton onClick={onClickNext}>{tCommon('next')}</StyledPrimaryButton>
                </ButtonContainer>
            }
        >
            <ContentContainer>
                <Paragraph>{t('removeYourselfDescription')}</Paragraph>
            </ContentContainer>
        </Modal>
    );
};

export default RemoveYourselfModal;
