import { AxiosResponse } from 'axios';
import { PortalConfig } from './apiTypes/configApiTypes';
import http from './infrastructure/httpService';
import { getBaseUrlApim } from './utils';

const portalApiUrl = process.env.REACT_APP_APIM_PORTAL_URL;

export const getPortalConfig = async (portalName: string): Promise<AxiosResponse<PortalConfig>> => {
    const portalConfigUrl = `${getBaseUrlApim()}/${portalApiUrl}/portals/${portalName}`;
    return await http.get<PortalConfig>(portalConfigUrl, { withCredentials: false });
};
