import { PropsWithChildren, ReactElement } from 'react';
import { Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { TicketStatus } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { OptionType } from '../../../../../../components/general/dropdown/DropDown';
import { getStatusTicketTranslation } from '../../../../utils';

const theme = {
    New: {
        backgroundColor: '#E1F6FF',
        color: '#001EFF',
    },
    Open: {
        backgroundColor: '#FFD4D4',
        color: 'var(--error-color-dark)',
    },
    Pending: {
        backgroundColor: '#FFF6C9',
        color: '#7A6700',
    },
    Solved: {
        backgroundColor: '#D2F8B6',
        color: 'var(--success-color-dark)',
    },
    Closed: {
        backgroundColor: '#E9EBEB',
        color: '#475156',
    },
};

const Container = styled.div`
    display: flex;
    align-items: center;
`;

type BadgeProps = { status: TicketStatus };
const Badge = styled(Label)`
    &&& {
        background-color: ${({ status }: BadgeProps) => theme[status]?.backgroundColor ?? 'grey'};
        color: ${({ status }: BadgeProps) => theme[status]?.color ?? 'white'};
    }
`;

interface TicketStatusBadgeProps {
    status: TicketStatus;
}

const TicketStatusBadge = ({ status, children }: PropsWithChildren<TicketStatusBadgeProps>): ReactElement => {
    const mappedStatus = getStatusTicketTranslation(status);
    return (
        <Container>
            <Badge circular status={status}>
                {status === children ? mappedStatus : children}
            </Badge>
        </Container>
    );
};

export const renderStatusOptions = (statusOptions: TicketStatus[] | undefined): OptionType[] | [] => {
    if (statusOptions) {
        return statusOptions.map((statusOption: TicketStatus) => {
            return {
                id: statusOption,
                value: <TicketStatusBadge status={statusOption}>{statusOption}</TicketStatusBadge>,
            };
        });
    } else return [];
};

export const placeholderOption = (placeholder: string): { id: string; value: ReactElement } => {
    return {
        id: 'placeholder',
        value: <TicketStatusBadge status={TicketStatus.New}>{placeholder}</TicketStatusBadge>,
    };
};

export default TicketStatusBadge;
