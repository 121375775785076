import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { IHelpArticle } from '../../../api/apiTypes/cmsApiTypes';
import { getDeviceLanguageDateFormat } from '../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import { MobileViewBreakpoint } from '../../ScreenSizeBreakPoints';
import { getUpdatedSinceString } from '../utils/getUpdatedSinceString';

const ArticleContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    margin: 1rem;
    overflow-y: auto;
    margin-bottom: 4rem;
`;

const FooterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    border-top: 0.06rem solid rgba(38, 50, 56, 0.12);
    margin: 0 -1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: var(--background-color);
`;

const BackButton = styled(Button)`
    &&& {
        background-color: transparent;
        color: var(--primary-color);
        font-size: 16px;
        font-weight: bold;
        text-align: left;
        margin-bottom: 0.5rem;
        padding: 0;
        width: 7rem;
    }
`;

const CloseButton = styled(Button)`
    &&& {
        background-color: transparent;
        color: var(--primary-color);
        font-size: 16px;
        font-weight: bold;
        width: 25%;
        margin: 1rem;

        @media (max-width: ${MobileViewBreakpoint}px) {
            width: 100%;
        }
    }
`;

const Title = styled.h1`
    color: var(--primary-color);
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 0;
`;

const PublishedDateContainer = styled.div`
    display: inline-block;
`;

const PublishedDate = styled.span`
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--text-high-emphasis-color);
`;

const PublishedDateText = styled.span``;

const PublishedDurationText = styled.span`
    color: #677074;
`;

const Ingress = styled.div`
    font-weight: bold;
    padding: 1rem 0;
`;

const Body = styled.pre`
    white-space: pre-wrap;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
`;

interface IArticleProps {
    article: IHelpArticle;
    onArticleBack(): void;
    onArticleClose(): void;
    dateFormat?: string;
}

const Article: React.FC<React.PropsWithChildren<IArticleProps>> = ({
    article,
    onArticleBack,
    onArticleClose,
    dateFormat = 'd.MM.yyyy',
}: IArticleProps): JSX.Element => {
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Dashboard', { keyPrefix: 'articles' });
    return (
        <>
            <ArticleContainer>
                <BackButton onClick={onArticleBack}>
                    <Icon name="arrow left" />
                    {tCommon('buttonBack')}
                </BackButton>

                <Title>{article.title}</Title>
                <PublishedDateContainer>
                    <PublishedDateText>{t('autorArticleInfoPublished')}: </PublishedDateText>
                    <PublishedDate>
                        {getDeviceLanguageDateFormat(new Date(article?.published_at), dateFormat)}
                    </PublishedDate>
                    <PublishedDurationText>{getUpdatedSinceString(article.updated_at)}</PublishedDurationText>
                </PublishedDateContainer>

                <Ingress>{article.ingress}</Ingress>
                <Body>{article.body}</Body>
            </ArticleContainer>
            <FooterContainer>
                <CloseButton onClick={onArticleClose}>{tCommon('closeButton')}</CloseButton>
            </FooterContainer>
        </>
    );
};

export default Article;
