import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { sidebarOperations } from '../../common/Sidebar/duck';
import { SidebarMenuItem } from '../../components';
import { useNavigation } from '../../utility';
import { useIsMobile } from '../../utility/hooks/useIsMobile';

const wardrobeLink = '/wardrobe/';

const WardrobeDynamicMenuItem = (): ReactElement => {
    const { t } = useTranslation('wardrobe');

    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const { pathname } = useLocation();
    const navigation = useNavigation();

    const onNavigate = (url: string, shouldCloseSideBar = true) => {
        if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
        navigation.push(url);
    };

    const goToWardrobe = () => onNavigate(wardrobeLink, false);

    return (
        <SidebarMenuItem
            icon="KitchenIcon"
            isActive={pathname?.includes(wardrobeLink)}
            onClick={goToWardrobe}
            text={t('wardrobeMenuItem')}
        />
    );
};

export default WardrobeDynamicMenuItem;
