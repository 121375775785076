import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SidebarMenuItem } from '../../components';

const TicketingExternalDynamicMenuItem = (): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });

    const onExternalTicketingClick = () => {
        window.open(`https://svg-sp.newsec.no`, '_blank');
    };

    return <SidebarMenuItem icon="MessageIconWithContent" text={t('tickets')} onClick={onExternalTicketingClick} />;
};

export default TicketingExternalDynamicMenuItem;
