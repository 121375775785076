import styled from 'styled-components';
import { TertiaryButton } from '../../../../components';

const IconButton = styled(TertiaryButton)`
    && {
        padding: 0;
        color: var(--primary-color);
        :hover {
            background: none;
        }
        :active {
            background: none;
        }
        :focus-visible,
        :focus {
            background: none;
            outline: 1px solid var(--primary-color);
        }

        :focus:not(:focus-visible) {
            outline: none;
        }

        & path {
            fill: var(--primary-color);
        }
    }
`;

export default IconButton;
