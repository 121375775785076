import { ReactElement, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OrderLineTitle, OrderStatusOption } from '../../../../api/apiTypes/shopApiTypes';
import Perm from '../../../../common/perms';
import { MobileViewBreakpoint } from '../../../../common/ScreenSizeBreakPoints';
import { FullPageLoader, GhostPrimaryButton, InformationMessage, SvgIcon } from '../../../../components';
import { isHiddenFromProduction, useUserHasPerm } from '../../../../utility';
import useResizeWindow from '../../../../utility/hooks/useResizeWindow';
import { useGetOrder, useGetOrderExcelFile } from '../../apiQueries/useShopOrders';
import { ShopOverviewView } from '../../ShopAtWork';
import useShopAtWorkNavigation from '../../useShopAtWorkNavigation';
import { BreadCrumb } from './components';
import PurchaseDetails from './components/PurchaseDetails';

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

type HeaderProps = { isMobileView: boolean };
const Header = styled.header`
    display: flex;
    flex-direction: ${({ isMobileView }: HeaderProps) => (isMobileView ? 'column' : 'row')};
    justify-content: space-between;
    padding: 1.857rem 0;
    gap: ${({ isMobileView }: HeaderProps) => (isMobileView ? '0.857rem' : '0')};
`;

const HeaderControls = styled.div`
    display: flex;
    gap: 0.571rem;
`;

const StyledBreadCrumb = styled(BreadCrumb)`
    padding: 0;
`;

const StyledGhostPrimaryButton = styled(GhostPrimaryButton)`
    svg {
        margin-left: 0.714rem;

        path {
            fill: var(--primary-color);
        }
    }
`;

interface PurchaseProps {
    view: ShopOverviewView;
    orderId: string;
}

function Purchase({ view, orderId }: PurchaseProps): ReactElement {
    const containerRef = useRef<HTMLDivElement>(null);
    const containerWidth = useResizeWindow(containerRef);
    const isMobileView = containerWidth < MobileViewBreakpoint;
    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'orderOverview' });

    const { goToInvoice } = useShopAtWorkNavigation();
    const { data: order, isLoading: isLoadingOrder, isError } = useGetOrder(orderId);
    const { refetch } = useGetOrderExcelFile(orderId, order?.orderNumber);

    const handleOnDownloadClick = () => {
        refetch();
    };

    const handleGoToInvoiceClick = () => {
        goToInvoice(view, orderId);
    };

    const hasInvoice = useMemo(() => {
        const isLunchOrder = order?.orderLines?.some((orderLine) => orderLine.title === OrderLineTitle.Lunch);
        const isCancelled = order?.status === OrderStatusOption.Canceled;
        return !isLunchOrder && !isCancelled;
    }, [order?.orderLines, order?.status]);

    // TODO: Temporary solution for test feature
    const isFeatureVisible = isHiddenFromProduction();
    return (
        <MainContainer ref={containerRef}>
            <Header isMobileView={isMobileView}>
                <StyledBreadCrumb view={view} orderNumber={order?.orderNumber} />
                {isFeatureVisible && !isLoadingOrder && (
                    <HeaderControls>
                        {hasInvoice && (
                            <GhostPrimaryButton onClick={handleGoToInvoiceClick}>
                                {t('goToInvoiceButton')}
                            </GhostPrimaryButton>
                        )}
                        {userIsPortalAdmin && hasInvoice && (
                            <StyledGhostPrimaryButton onClick={handleOnDownloadClick}>
                                {t('downloadOrderButton')} <SvgIcon name="DownloadIcon" />
                            </StyledGhostPrimaryButton>
                        )}
                    </HeaderControls>
                )}
            </Header>
            {isLoadingOrder && <FullPageLoader text={t('orderLoadingText')} />}
            {isError && <InformationMessage type="error">{t('orderLoadingError')}</InformationMessage>}
            {order && <PurchaseDetails order={order} isMobileView={isMobileView} isVendor={false} />}
        </MainContainer>
    );
}

export default Purchase;
