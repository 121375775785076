import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    FullPageLoader,
    GhostPrimaryButton,
    Modal,
    ModalType,
    PrimaryButton,
    SvgIcon,
} from '../../../../../../components';
import { AccordionTab } from '../../../../../../components/accordion/AccordionTab';
import { Header } from '../../../../styles/ShopAtWorkStyles';
import CreateNewCategoryModal from '../../../CreateNewCategoryModal';
import { NewCategoryRemoveModalType } from '../../utils';
import { ManageCategoryLayoutLogic } from './ManageCategoryLayoutLogic';

const ManageCategoryLayoutHeader = styled.header`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    background-color: var(--primary-on-color);
`;

const ManageCategoryLayoutContent = styled.div`
    height: 100%;
    max-width: 62.857rem;
    width: 100%;
    margin: 10.571rem auto 0;
`;

const ManageCategoryLayoutTitle = styled(Header)`
    margin-bottom: 3.429rem;
`;

const ManageCategoryTabs = styled.div``;

const ModalBody = styled.div`
    padding: 1.9rem 1.14rem 1.9rem 1.7rem;
`;

const StyledGhostPrimaryButton = styled(GhostPrimaryButton)`
    &&& {
        display: flex;
        align-items: center;
    }
    svg {
        margin-right: 0.571rem;
        rect {
            fill: var(--primary-color);
        }
    }

    &:hover {
        svg {
            rect {
                fill: #ffffff;
            }
        }
    }
`;

const ModalBottom = styled.div`
    display: flex;
    justify-content: end;
    gap: 1.143rem;
`;

const StyledFullPageLoader = styled(FullPageLoader)`
    margin-top: 10.571rem;
`;

export const ManageCategoryLayout = (): ReactElement => {
    const {
        preparedData,
        isRemoveModalShown,
        isCreateNewCategoryModalShown,
        selectedCategoryId,
        isProductTypesWithCategoriesLoading,
        isRemoveProductCategoryByIdLoading,
        removeModalContent,
        goToInventory,
        hideRemoveModal,
        hideCreateNewCategoryModal,
        confirmRemove,
        createNewCategory,
        handleHeaderBack,
        focusId,
        setFocusId,
    } = ManageCategoryLayoutLogic();

    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'productCategoryAdministration' });
    const { t: tCommon } = useTranslation('common');

    const onRemoveCategoryByIdConfirmed = () => {
        const actionType = removeModalContent?.submit();
        switch (actionType) {
            case NewCategoryRemoveModalType.Remove: {
                confirmRemove(selectedCategoryId as string, removeModalContent?.name ?? 'category');
                break;
            }
            case NewCategoryRemoveModalType.Navigate: {
                goToInventory();
                break;
            }
            case NewCategoryRemoveModalType.Success: {
                hideRemoveModal();
                break;
            }
        }
    };

    return (
        <>
            <ManageCategoryLayoutHeader>
                <StyledGhostPrimaryButton onClick={handleHeaderBack}>
                    <SvgIcon name="BackArrowIcon" /> {tCommon('buttonBack')}
                </StyledGhostPrimaryButton>
                <PrimaryButton
                    onClick={() => {
                        createNewCategory(selectedCategoryId ?? null);
                    }}
                    loading={isProductTypesWithCategoriesLoading}
                    disabled={isProductTypesWithCategoriesLoading}
                >
                    {t('newCategoryButton')}
                </PrimaryButton>
            </ManageCategoryLayoutHeader>
            {isProductTypesWithCategoriesLoading ? (
                <StyledFullPageLoader />
            ) : (
                <ManageCategoryLayoutContent>
                    <ManageCategoryLayoutTitle>{t('title')}</ManageCategoryLayoutTitle>
                    <ManageCategoryTabs>
                        {preparedData?.map(
                            ({
                                id,
                                name,
                                amountOfChildren,
                                amountOfSubElements,
                                contextMenuActions,
                                suffix,
                                childElements,
                                hasParentAccordionTab,
                                isEditable,
                            }) => (
                                <AccordionTab
                                    key={id}
                                    id={id}
                                    name={name}
                                    amountOfChildren={amountOfChildren}
                                    amountOfSubElements={amountOfSubElements}
                                    suffix={suffix}
                                    contextMenuActions={contextMenuActions}
                                    hasParentAccordionTab={hasParentAccordionTab}
                                    isEditable={isEditable}
                                >
                                    {childElements?.map(
                                        ({
                                            id,
                                            name,
                                            contextMenuActions,
                                            amountOfSubElements,
                                            hasParentAccordionTab,
                                            isEditable,
                                        }) => (
                                            <AccordionTab
                                                key={id}
                                                name={name}
                                                id={id}
                                                amountOfSubElements={amountOfSubElements}
                                                contextMenuActions={contextMenuActions}
                                                suffix={suffix}
                                                hasParentAccordionTab={hasParentAccordionTab}
                                                focusId={focusId}
                                                setFocusId={setFocusId}
                                                isEditable={isEditable}
                                            />
                                        ),
                                    )}
                                </AccordionTab>
                            ),
                        )}
                    </ManageCategoryTabs>
                </ManageCategoryLayoutContent>
            )}

            <Modal
                open={isRemoveModalShown && !!selectedCategoryId}
                onClose={hideRemoveModal}
                type={ModalType.Small}
                title={removeModalContent?.title}
                hideCloseButton
                bottomContent={
                    <ModalBottom>
                        {removeModalContent?.showAbortButton ? (
                            <GhostPrimaryButton
                                onClick={hideRemoveModal}
                                disabled={isRemoveProductCategoryByIdLoading || isProductTypesWithCategoriesLoading}
                            >
                                {tCommon('cancelButton')}
                            </GhostPrimaryButton>
                        ) : null}
                        <PrimaryButton
                            onClick={onRemoveCategoryByIdConfirmed}
                            disabled={isRemoveProductCategoryByIdLoading || isProductTypesWithCategoriesLoading}
                            loading={isRemoveProductCategoryByIdLoading || isProductTypesWithCategoriesLoading}
                        >
                            {removeModalContent?.submitButtonText}
                        </PrimaryButton>
                    </ModalBottom>
                }
            >
                <ModalBody>{removeModalContent?.body}</ModalBody>
            </Modal>
            {isCreateNewCategoryModalShown && (
                <CreateNewCategoryModal
                    isCreateNewCategoryModalShown={isCreateNewCategoryModalShown}
                    hideCreateNewCategoryModal={hideCreateNewCategoryModal}
                />
            )}
        </>
    );
};
