import { PropsWithChildren, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import useGetNavigationUrl from '../../utility/hooks/useGetNavigationUrl';

interface AppLinkProps {
    to: string;
    title?: string;
    className?: string;
    onClickFunc?(): void;
}

const AppLink = ({ to, title, children, className, onClickFunc }: PropsWithChildren<AppLinkProps>): ReactElement => {
    const getUrl = useGetNavigationUrl();
    const actualTo = getUrl(to);
    return (
        <Link to={actualTo} title={title} className={className} onClick={onClickFunc}>
            {children}
        </Link>
    );
};

export default AppLink;
