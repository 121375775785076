import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

let reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_PROFILE_REQUESTED':
            return {
                ...state,
                awaitUpdateProfile: true,
            };
        case 'UPDATE_PROFILE_SUCCEEDED':
            return {
                ...state,
                awaitUpdateProfile: false,
            };
        case 'UPDATE_PROFILE_FAILED':
            return {
                ...state,
                awaitUpdateProfile: false,
                updateProfileError: action.payload,
            };
        case 'FETCH_ZONES_FOR_USER_REQUESTED':
            return {
                ...state,
                loadingZones: true,
            };
        case 'FETCH_ZONES_FOR_USER_SUCCEEDED':
            return {
                ...state,
                loadingZones: false,
                zones: action.payload,
            };
        case 'FETCH_ZONES_FOR_USER_FAILED':
            return {
                ...state,
                loadingZones: false,
                error: action.payload,
            };
        case 'FETCH_ACCESS_CARDS_FOR_USER_REQUESTED':
            return {
                ...state,
                loadingCards: true,
            };
        case 'FETCH_ACCESS_CARDS_FOR_USER_SUCCEEDED':
            return {
                ...state,
                loadingCards: false,
                cards: action.payload,
            };
        case 'FETCH_ACCESS_CARDS_FOR_USER_FAILED':
            return {
                ...state,
                loadingCards: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

const initialState = {
    awaitUpdateProfile: false,
    updateProfileError: null,
    loadingZones: false,
    zones: [],
};

export const MyProfileContext = createContext();

export const MyProfileProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        state,
        dispatch,
    };

    return <MyProfileContext.Provider value={value}> {children} </MyProfileContext.Provider>;
};

MyProfileProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
