import { ReactElement, useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { TicketType } from '../../../../../api/apiTypes/ticketingApiTypes';
import { MobileViewBreakpoint } from '../../../../../common/ScreenSizeBreakPoints';
import { PrimaryButton, SvgIcon } from '../../../../../components';
import { useNavigation } from '../../../../../utility';
import { IconButton } from '../../../../Dashboard/Articles/common';
import useTicketingNavigation from '../../../useTicketingNavigation';
import useCanCreateType from '../../useCanCreateAndDeleteType';
import { MenuItem } from './components';

const MenuContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    padding-top: 1.42rem;
    background-color: var(--surface-color-light);
    border-right: 1px solid var(--border-color);
    width: 16rem;
    flex-shrink: 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    z-index: 2;

    @media (max-width: ${MobileViewBreakpoint ?? 768}px) {
        position: absolute;
        right: 0;
        bottom: 0;
        height: calc(100% - 4rem - 4rem);
    }
`;

const Banner = styled.div`
    width: 100%;
    padding: 1.14rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--surface-color-light);
    border-bottom: 1px solid var(--border-color);
`;

const NewTypeButton = styled(PrimaryButton)`
    &&& {
        margin: 1.14rem 1.71rem;
    }
    & svg {
        margin-right: 0.57rem;
    }
`;

const TopHeader = styled.h2`
    font-size: 0.857rem;
    text-transform: uppercase;
    margin-left: 1.42rem;
    color: var(--text-medium-emphasis-color);
`;

interface TicketSideMenuProps {
    types: TicketType[];
}

const TypeMenu = ({ types }: TicketSideMenuProps): ReactElement => {
    const nav = useNavigation();
    const { getTypeTabLink, getTypeLink, getCategoryTabLink, settingsLink, goToSettings, goToCreateNewType } =
        useTicketingNavigation();
    const match = useRouteMatch<{ typeId: string }>(`/:portalName${getTypeLink(':typeId')}`);
    const categoryMatch = useRouteMatch<{ typeId: string; categoryId: string }>(
        `/:portalName${getTypeLink(':typeId')}/:categoryId/:tab`,
    );
    const typeId = match?.params.typeId;
    const categoryId = categoryMatch?.params.categoryId;
    const [expandTicketType, setExpandTicketType] = useState(true);
    const [showMenu, setShowMenu] = useState(false);
    const canCreateType = useCanCreateType();
    const isMobile = window.matchMedia(`(max-width:${MobileViewBreakpoint ?? 768}px)`)?.matches;
    const { t } = useTranslation('Ticketing');
    const handleGoBack = () => nav.goBack(goToSettings);
    const handleToggleMenu = () => setShowMenu((curr) => !curr);

    useEffect(() => setExpandTicketType(true), [typeId]);

    return (
        <>
            {isMobile ? (
                <Banner>
                    <IconButton onClick={handleGoBack}>
                        <SvgIcon name="CaretLeft" alt={t('commonTicketing.buttonGoBack')} />
                    </IconButton>
                    <IconButton onClick={handleToggleMenu}>
                        <SvgIcon name="DoubleChevronRight" alt={t('settings.openMenu')} />
                    </IconButton>
                </Banner>
            ) : null}
            <AnimatePresence>
                {!isMobile || showMenu ? (
                    <MenuContainer
                        initial={!isMobile ? false : { right: '-100%' }}
                        animate={{ right: 0 }}
                        exit={{ right: '-100%' }}
                        transition={{ type: 'easeOut', duration: 0.1 }}
                    >
                        {isMobile ? <TopHeader>{t('commonTicketing.settings')}</TopHeader> : null}
                        <MenuItem name={t('settings.overview')} isSelected={!typeId} url={settingsLink} />
                        {types?.map(({ name, id, categories }) => (
                            <MenuItem
                                key={id}
                                name={name}
                                isSelected={typeId === id}
                                isExpanded={typeId === id && expandTicketType}
                                url={getTypeTabLink(id, 'general')}
                                onClick={() => setExpandTicketType(!expandTicketType)}
                            >
                                {categories.length > 0
                                    ? categories.map((category) => (
                                          <MenuItem
                                              key={category.id}
                                              name={category.name}
                                              url={getCategoryTabLink(id, category.id, 'general')}
                                              isChild
                                              isSelected={categoryId === category.id}
                                          />
                                      ))
                                    : null}
                            </MenuItem>
                        ))}
                        {canCreateType ? (
                            <NewTypeButton onClick={goToCreateNewType}>
                                <SvgIcon name="Plus" />
                                {t('settings.newType')}
                            </NewTypeButton>
                        ) : null}
                    </MenuContainer>
                ) : null}
            </AnimatePresence>
        </>
    );
};

export default TypeMenu;
