import { FC, memo, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Button, MotionBox } from '../index';
import { DrawerProps } from './types';

const Drawer: FC<DrawerProps> = ({ children, open, onClose, restrictionHeight, isBackDrop = true }) => {
    const backDropCallback = useCallback(() => isBackDrop && onClose(), [isBackDrop, onClose]);
    return (
        <AnimatePresence>
            <Button
                cursor={isBackDrop ? 'pointer' : 'default'}
                onClick={backDropCallback}
                display={open ? 'flex' : 'none'}
                height={'100%'}
                width={'100%'}
                position={'fixed'}
                left={'0px'}
                top={'0px'}
                zIndex={999}
                background={'rgba(71, 81, 86, 0.8)'}
                align={'flex-end'}
                justify={'center'}
            >
                <MotionBox
                    maxH={restrictionHeight ?? '90%'}
                    boxShadow={'0 0 50px rgba(0, 0, 0, 0.15)'}
                    background={'var(--surface-color-light)'}
                    br={'0.571rem 0.571rem 0 0'}
                    initial={{ y: 380, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 380, opacity: 0 }}
                    transition={{ duration: 0.3, ease: 'easeIn' }}
                >
                    {children}
                </MotionBox>
            </Button>
        </AnimatePresence>
    );
};

export default memo(Drawer);
