import React, { FC } from 'react';
import MotionBoxStyled from './motionBox.styled';
import { MotionBoxProps } from './types';

const MotionBox: FC<MotionBoxProps> = ({ children, forwardedRef, ...props }) => {
    return (
        //Ts error with passing array value
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <MotionBoxStyled ref={forwardedRef} {...props}>
            {children}
        </MotionBoxStyled>
    );
};

export default MotionBox;
