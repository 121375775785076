import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PrimaryButton, GhostPrimaryButton, Modal, ModalType, SuccessDisplay } from '../../../../../../../components';
import useTicketingNavigation from '../../../../../useTicketingNavigation';

const Content = styled.div`
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const ButtonContainer = styled.div`
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    gap: 1.143rem;
`;

type SuccessModalProps = {
    onClose(): void;
    typeId: string;
};

const SuccessModal = ({ onClose, typeId }: SuccessModalProps): ReactElement => {
    const { goToSettings, goToType } = useTicketingNavigation();
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const newTicketDescription = t('newTicketDescription');

    return (
        <Modal
            title={t('header')}
            type={ModalType.Medium}
            onClose={onClose}
            open
            bottomContent={
                <ButtonContainer>
                    <GhostPrimaryButton onClick={goToSettings}>{t('goToTheOverview')}</GhostPrimaryButton>
                    <PrimaryButton onClick={() => goToType(typeId)}>{t('continueToType')}</PrimaryButton>
                </ButtonContainer>
            }
        >
            <Content>
                <SuccessDisplay
                    header={t('newInquiryTypeCreated')}
                    description={newTicketDescription}
                    alt="Create new ticket type success"
                />
            </Content>
        </Modal>
    );
};
export default SuccessModal;
