import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GhostPrimaryButton, PrimaryButton, SvgIcon } from '../../../../../components';
import { Header as HeaderTitle } from '../../../styles/ShopAtWorkStyles';
import useShopAtWorkNavigation from '../../../useShopAtWorkNavigation';

const HeaderWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.857rem;
    h2 {
        margin-bottom: 0;
    }
`;

const HeaderActions = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1.143rem;
`;

const AddNewProductButtonContainer = styled.div`
    display: flex;
    gap: 0.762rem;
    align-items: center;
`;

const ButtonText = styled.p`
    display: inline;
    white-space: nowrap;
`;

export const InventoryHeader = (): ReactElement => {
    const { t } = useTranslation('ShopAtWork');

    const { goToAddNewProduct, goToInventoryCategory } = useShopAtWorkNavigation();
    return (
        <HeaderWrapper>
            <HeaderTitle>{t('commonShopAtWork.ShopOverviewHeader.Inventory')}</HeaderTitle>
            <HeaderActions>
                <GhostPrimaryButton onClick={goToInventoryCategory}>
                    <ButtonText>{t('inventory.administrateCategoryButton')}</ButtonText>
                </GhostPrimaryButton>
                <PrimaryButton onClick={goToAddNewProduct}>
                    <AddNewProductButtonContainer>
                        <SvgIcon name="Plus" />
                        <ButtonText>{t('inventory.newProductButton')}</ButtonText>
                    </AddNewProductButtonContainer>
                </PrimaryButton>
            </HeaderActions>
        </HeaderWrapper>
    );
};
