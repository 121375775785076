import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Responsive } from 'semantic-ui-react';
import styled from 'styled-components';
import { IRootStateProps } from '../../../reducers';
import { SvgIcon } from '../../components';
import { useNavigation } from '../../utility';
import { DeviceTypes } from '../../utility/deviceTypes';
import { usePortalConfig } from '../apiQueries/useAppConfiguration';
import { MobileViewBreakpoint } from '../ScreenSizeBreakPoints';
import { SidebarMenu } from './components';

const Container = styled.div`
    display: flex;
`;

const CloseButton = styled.button`
    margin: 2rem;
    background-color: transparent;
    border: none;

    svg {
        path {
            fill: #000;
        }
    }
`;

const LogoButton = styled.button`
    background-color: transparent;
    display: flex;
    align-items: center;
    margin: 0.4rem;
    cursor: pointer;
    border: none;
    :focus-visible {
        outline: 1px solid var(--primary-color);
    }
`;

const Logo = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

interface SidebarComponentProps {
    defaultModuleName: string;
    deviceType: DeviceTypes;
    handleHideSidebar(): void;
    isSidebarVisible: boolean;
}

const SidebarComponent = ({ deviceType, isSidebarVisible, handleHideSidebar }: SidebarComponentProps): ReactElement => {
    const { push } = useNavigation();
    const { moduleName, subModuleName } = useParams<{ moduleName: string; subModuleName: string }>();
    const { logo, defaultModule } = usePortalConfig();
    const modules = useSelector((state: IRootStateProps) => state.app.modules);

    const handleLogoClick = () => {
        if (deviceType === DeviceTypes.mobile) handleHideSidebar();
        push(`/${defaultModule}`);
    };

    const handleMenuItemClick = (moduleName: string, subModuleName: string) => {
        if (deviceType === DeviceTypes.mobile) {
            const module = modules?.find(({ name }: { name: string }) => name === moduleName);
            const shouldHideSideBar = subModuleName || !module?.children;
            shouldHideSideBar && handleHideSidebar();
        }
        if (subModuleName) {
            push(`/${moduleName}/${subModuleName}`);
        } else {
            push(`/${moduleName}`);
        }
    };

    const renderSidebar = (showCloseButton: boolean) => {
        return (
            <React.Fragment>
                {renderSidebarHeader(showCloseButton)}
                <SidebarMenu
                    moduleName={moduleName}
                    subModuleName={subModuleName}
                    isSidebarVisible={isSidebarVisible}
                    handleMenuItemClick={handleMenuItemClick}
                    modules={modules}
                />
            </React.Fragment>
        );
    };

    const renderSidebarHeader = (showCloseButton: boolean) => {
        return (
            <div className="sidebar-header-container">
                <LogoButton
                    onClick={handleLogoClick}
                    onKeyPress={handleLogoClick}
                    role="button"
                    tabIndex={0}
                    id="portalBrandLogo"
                >
                    <Logo srcSet={logo} alt="portal-logo" />
                </LogoButton>
                {showCloseButton && (
                    <CloseButton onClick={handleHideSidebar}>
                        <SvgIcon name="CloseIcon" />
                    </CloseButton>
                )}
            </div>
        );
    };

    useEffect(() => {
        if (deviceType === DeviceTypes.mobile) {
            if (isSidebarVisible) {
                document.body.style.position = 'fixed';
            } else {
                document.body.style.position = '';
            }
        }
    }, [deviceType, isSidebarVisible]);

    return (
        <Container>
            <Responsive
                minWidth={MobileViewBreakpoint}
                className={isSidebarVisible ? 'sidebar-container' : 'sidebar-container collapse'}
                fireOnMount
                aria-hidden={!isSidebarVisible}
            >
                {renderSidebar(false)}
            </Responsive>
            <Responsive
                maxWidth={Responsive.onlyMobile.maxWidth}
                className={isSidebarVisible ? 'sidebar-container mobile' : 'sidebar-container mobile collapse'}
                fireOnMount
                aria-hidden={!isSidebarVisible}
            >
                {renderSidebar(true)}
            </Responsive>
        </Container>
    );
};

export default SidebarComponent;
