import { ReactElement, useEffect, useState } from 'react';
import {
    useGetUserWifiAtWorkSetPasswordNotification,
    usePostponeSetWifiAtWorkPassword,
} from '../../../../common/Notifications/apiQueries/useWifiAtWorkNotification';
import WifiAtWorkSetPasswordSuccessTooltip from './WifiAtWorkSetPasswordSuccessTooltip';
import WifiAtWorkSetPasswordTooltip from './WifiAtWorkSetPasswordTooltip';

const SetWifiPasswordNotification = (): ReactElement => {
    const { data: user } = useGetUserWifiAtWorkSetPasswordNotification();
    const [openWifiPasswordTooltip, setOpenWifiPasswordTooltip] = useState(false);
    const [openWifiPasswordSuccessTooltip, setOpenWifiPasswordSuccessTooltip] = useState(false);
    const [postponeSetWifiAtWorkPassword] = usePostponeSetWifiAtWorkPassword();

    useEffect(() => {
        setOpenWifiPasswordTooltip(!!user?.promptSetWifiAtWorkPassword);
    }, [user?.promptSetWifiAtWorkPassword]);

    const onCancelSetPasswordHandler = () => {
        postponeSetWifiAtWorkPassword();
        setOpenWifiPasswordTooltip(false);
        setOpenWifiPasswordSuccessTooltip(false);
    };

    const onSubmitSetPasswordHandler = () => {
        setOpenWifiPasswordTooltip(false);
        setOpenWifiPasswordSuccessTooltip(true);
    };

    return (
        <>
            <WifiAtWorkSetPasswordTooltip
                onSubmit={onSubmitSetPasswordHandler}
                open={openWifiPasswordTooltip}
                onClose={onCancelSetPasswordHandler}
            />
            <WifiAtWorkSetPasswordSuccessTooltip
                open={openWifiPasswordSuccessTooltip}
                onClose={() => setOpenWifiPasswordSuccessTooltip(false)}
            />
        </>
    );
};

export default SetWifiPasswordNotification;
