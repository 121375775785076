import { ReactElement, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { type TFunction, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { TicketType, UpdateTicketTypeDto } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { InputField, PrimaryButton, SvgIcon } from '../../../../../../components';
import { useUpdateTicketType } from '../../../../apiQueries/useTicketingType';
import { Header, HorizontalRule } from '../../../../common/styles';
import useCanCreateAndDeleteType from '../../../useCanCreateAndDeleteType';
import { TicketCategory, CaseworkerDescription, CaseTypePublish, DeleteType } from './components/index';

export const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
`;

const CaseTypeInputField = styled(InputField)`
    margin-right: 0.571rem;
    align-self: center;
    width: 19rem;
`;

const SaveButton = styled(PrimaryButton)`
    &&& {
        height: 2.692rem;
        margin-top: 1.429rem;
    }
`;

const SaveButtonText = styled.span`
    padding-right: 0.667rem;
`;

let existingTypesNames = [''];

const schema = (t: TFunction<'Ticketing', 'settings'>) =>
    yup.object().shape({
        name: yup
            .string()
            .required(t('errorMessageNameIsRequired'))
            .min(2, t('errorMessageNameMustHaveMinValue'))
            .trim()
            .test('isUnique', t('typeDuplicate'), function (value) {
                return value !== undefined && Array.isArray(existingTypesNames) && existingTypesNames.length
                    ? !existingTypesNames.includes(value.toString().toLowerCase())
                    : true;
            }),
    });

interface GeneralTabProps {
    type: TicketType;
    allTypes: TicketType[] | [];
    changesSaved(isSaved: boolean): void;
}

const GeneralTab = ({ type, changesSaved, allTypes }: GeneralTabProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const { t: tCommon } = useTranslation('common');
    existingTypesNames = useMemo(() => allTypes.map((type) => type.name.toLowerCase()), [allTypes]);
    const isHasPublishedCategory = type.categories.find((category) => category.isPublished) != undefined;
    const isHasCaseworkers = type.caseWorkers.length > 0;
    const canDeleteType = useCanCreateAndDeleteType();
    const formId = 'EditTicketType' + type.id;
    const [updateType, { isLoading, isSuccess }] = useUpdateTicketType();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<UpdateTicketTypeDto>({
        mode: 'onTouched',
        resolver: yupResolver(schema(t)),
        defaultValues: { name: type.name },
    });

    const onSubmit = (data: UpdateTicketTypeDto) => {
        updateType([type.id, data], { onSuccess: () => changesSaved(true) });
    };

    return (
        <>
            <Header>{t('nameOfTypeHeader')}</Header>
            <Form
                id={formId}
                onSubmit={handleSubmit(onSubmit)}
                onChange={() => changesSaved(watch('name') === type.name)}
            >
                <CaseTypeInputField
                    label="Navn"
                    {...register('name')}
                    placeholder={t('nameOfType')}
                    required
                    error={errors.name?.message}
                />
                <SaveButton
                    type="submit"
                    form={formId}
                    disabled={watch('name') === type.name || Object.keys(errors).length > 0}
                >
                    <>
                        <SaveButtonText>
                            {isLoading ? tCommon('storing') : isSuccess ? t('saved') : tCommon('saveButton')}
                        </SaveButtonText>
                        <SvgIcon name="CheckIcon" />
                    </>
                </SaveButton>
            </Form>
            <HorizontalRule />
            <TicketCategory type={type} />
            <HorizontalRule />
            <CaseworkerDescription />
            <CaseTypePublish
                id={type.id}
                hasCaseWorker={isHasCaseworkers}
                hasPublishedCategory={isHasPublishedCategory}
                isPublished={type.isPublished}
            />
            {canDeleteType && <DeleteType type={type} allTypes={allTypes} />}
        </>
    );
};

export default GeneralTab;
