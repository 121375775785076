import { ReactElement, PropsWithChildren } from 'react';

interface ExternalLinkProps {
    href: string;
    className?: string;
}

const ExternalLink = ({ href, className, children }: PropsWithChildren<ExternalLinkProps>): ReactElement => {
    return (
        <a href={href} className={className} target="_blank" rel="noreferrer">
            {children}
        </a>
    );
};

export default ExternalLink;
