import { CurrentUserContextAction } from '../../../Providers/CurrentUserProvider/types';

export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const updateCurrentUserProfileData = (
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    language: string,
    dispatch: React.Dispatch<CurrentUserContextAction>,
): void => {
    dispatch({
        type: 'UPDATE_CURRENT_USER_PROFILE_DATA',
        payload: {
            firstName: firstName,
            lastName: lastName,
            name: `${firstName} ${lastName}`,
            email: email,
            phoneNumber: phoneNumber,
            language: language,
        },
    });
};
