import { ChangeEvent, ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { Ticket, TicketImage, UploadedTicketImage } from '../../../../../../../api/apiTypes/ticketingApiTypes';
import ImageUploader from '../../../../../../../common/ImageUploader/ImageUploader';
import { MobileViewBreakpoint } from '../../../../../../../common/ScreenSizeBreakPoints';
import { Avatar, InputField, SvgIcon } from '../../../../../../../components';
import { AvatarComponentProps } from '../../../../../../../components/general/AvatarComponent';
import { SvgIconName } from '../../../../../../../components/icons/SvgIcon';
import { InputFieldPropType } from '../../../../../../../components/input/TextInput/inputField';
import { useUploadTicketImage } from '../../../../../apiQueries/useUploadTicketImage';
import { ImagePreview, Spinner } from './components/index';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 2.286rem;
`;

const FormContainer = styled.form`
    width: 100%;
    display: grid;
    grid-template-areas:
        'avatar message'
        'avatar message'
        'none files';
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto 10fr;
`;

const ReporteeAvatar = styled(Avatar)`
    grid-area: avatar;
    align-self: top;
    justify-self: center;
    margin-right: 0.571rem;

    @media (max-width: ${MobileViewBreakpoint}px) {
        display: none;
    }
`;

const InputFileContainer = styled.div`
    grid-area: message;
    flex-direction: column;
    width: 100%;
`;

const InputContainer = styled.div`
    display: flex;
    border: 1px solid var(--border-color);
    border-radius: 0.357rem;
    height: auto;
    width: 100%;
    align-items: stretch;
    word-wrap: break-word;

    :focus-within {
        outline: 1px solid var(--primary-color);
    }
`;

const StyledInput = styled(({ ...props }: InputFieldPropType) => <InputField {...props} />)`
    width: 100%;

    textarea {
        min-height: auto;
        line-height: 24px;
        background: var(--surface-color-light);
        cursor: auto;
        width: 100%;
        border: none;
        font-size: 1.143rem;
        height: auto;
        overflow: hidden;
        resize: none;

        :focus {
            outline: none;
        }
    }
`;

const MessageButton = styled.button`
    background-color: #e1f6ff;
    display: flex;
    height: auto;
    width: 3.429rem;
    border: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    :disabled {
        background-color: #d4d6d7;
    }

    :focus {
        outline-color: var(--primary-color);
    }

    svg > path {
        fill: #263238;
    }
`;

const InputFieldPrependIconButton = styled.button`
    margin: 0 1.357rem;
    line-height: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;

    :focus {
        outline-color: var(--primary-color);
        outline-offset: 3px;
    }
`;
const FilesContainer = styled.div`
    display: flex;
    grid-area: files;
    margin-top: 1.143rem;
`;

const imageField = yup.object({ imageUrl: yup.string(), imageFileName: yup.string() });
const schema = (t: TFunction<'Ticketing', 'commonTicketing'>) =>
    yup.object().shape(
        {
            message: yup.string().when('attachments', {
                is: (attachments: TicketImage[]) => !attachments?.length,
                then: yup.string().min(1, t('minimumOneCharactersOrImage')),
                otherwise: yup.string(),
            }),
            attachments: yup.array().of(imageField).max(5, 'any'),
        },
        [['message', 'attachments']],
    );

type FormFields = { message: string; attachments: TicketImage[] };
const DEFAULT_ROWS_NUMBER = 1;

interface MessageProps {
    avatar: AvatarComponentProps | null;
    onSendMessage(message: FormFields): void;
    placeholder?: string;
    inputFieldPrependIcon?: SvgIconName;
    ticket: Ticket;
    setChangesSaved(isSaved: boolean): void;
    disable?: boolean;
    submitMessageErrorMessage?: string;
    clearMessage?: boolean;
    isLoading?: boolean;
}

const Message = ({
    avatar,
    onSendMessage,
    placeholder,
    inputFieldPrependIcon,
    ticket,
    setChangesSaved,
    disable,
    submitMessageErrorMessage,
    clearMessage,
    isLoading,
}: MessageProps): ReactElement => {
    const [openImageUploader, setOpenImageUploader] = useState(false);
    const [rows, setRows] = useState(DEFAULT_ROWS_NUMBER);
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        setValue,
        getValues,
        watch,
        reset,
    } = useForm<FormFields>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema(t)),
        defaultValues: { message: '', attachments: [] },
    });

    useEffect(() => {
        if (clearMessage) {
            setValue('message', '');
            setValue('attachments', []);
            setRows(DEFAULT_ROWS_NUMBER);
        }
    }, [clearMessage, setValue]);

    const onUploadImageSuccessHandler = (result: AxiosResponse<UploadedTicketImage>): void => {
        const attachments = getValues('attachments');

        setValue('attachments', [
            ...attachments,
            { imageUrl: result.data.uncompressedUrl, imageFileName: result.data.fileName },
        ]);
        setOpenImageUploader(false);
        setChangesSaved(false);
    };

    const onSubmit = (formFields: FormFields) => {
        setChangesSaved(true);
        onSendMessage(formFields);
        reset();
    };

    useEffect(() => {
        setChangesSaved(!isDirty);
    }, [isDirty, setChangesSaved]);

    const [uploadImage, { isLoading: isUploadingImage }] = useUploadTicketImage(
        ticket.type.id,
        ticket.category.id,
        onUploadImageSuccessHandler,
    );

    const autoGrowTextAreaHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const textareaLineHeight = 24;
        const previousRows = event.target.rows;
        event.target.rows = 1;
        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) event.target.rows = currentRows;

        event.target.scrollTop = event.target.scrollHeight;
        setRows(currentRows);
    };

    const handleChange = (event: SyntheticEvent<Element, Event>) => {
        const textAreaChangeEvent = event as ChangeEvent<HTMLTextAreaElement>;
        const value = textAreaChangeEvent.target.value;
        autoGrowTextAreaHandler(textAreaChangeEvent);
        setValue('message', value);
    };
    const [attachments, setAttachments] = useState<TicketImage[]>([]);

    useEffect(() => {
        setAttachments(watch('attachments'));
    }, [watch('attachments')]);

    const removeAttachmentFile = (index: number) => {
        setValue(
            'attachments',
            attachments.filter((_, i) => i !== index),
        );
        setAttachments((prev) => prev.filter((_, i) => i !== index));
    };

    return (
        <Container>
            <FormContainer onSubmit={handleSubmit(onSubmit)}>
                {avatar && <ReporteeAvatar {...avatar} />}
                <InputFileContainer>
                    <InputContainer>
                        <StyledInput
                            type="textarea"
                            label=""
                            placeholder={placeholder}
                            readOnly={disable}
                            key="message"
                            {...register('message')}
                            onChange={handleChange}
                            error={errors.message?.message || submitMessageErrorMessage}
                            rows={rows}
                            value={watch('message')}
                        />
                        {inputFieldPrependIcon && (
                            <InputFieldPrependIconButton onClick={() => setOpenImageUploader(true)} type="button">
                                <SvgIcon name={inputFieldPrependIcon} />
                            </InputFieldPrependIconButton>
                        )}
                        <MessageButton type="submit" disabled={(!watch('message') && !watch('attachments')) || disable}>
                            {isLoading ? <Spinner /> : <SvgIcon name="PaperplaneIcon" />}
                        </MessageButton>
                    </InputContainer>
                </InputFileContainer>
                <FilesContainer>
                    {attachments.map((attachment, index) => (
                        <ImagePreview
                            key={index + attachment.imageFileName}
                            image={{
                                imageFileName: attachment.imageFileName,
                                imageUrl: attachment.imageUrl,
                            }}
                            alt={t('commentImagePreview')}
                            onRemoveImage={() => removeAttachmentFile(index)}
                        />
                    ))}
                </FilesContainer>

                {openImageUploader && (
                    <ImageUploader
                        onClose={() => setOpenImageUploader(false)}
                        uploadImage={uploadImage}
                        maxImageSize={10}
                        isUploadingImage={isUploadingImage}
                        field="ticketMessageCoverImage"
                        {...register('attachments')}
                    />
                )}
            </FormContainer>
        </Container>
    );
};

export default Message;
