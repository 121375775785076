import { ReactElement, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import type {
    Control,
    FieldErrors,
    UseFormRegister,
    UseFormTrigger,
    UseFormSetValue,
    UseFormGetValues,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormatLanguages } from '../../../../i18next';
import { Dropdown, InputField, PhoneInputField } from '../../../components';
import FormLabel from '../../../components/FormLabel';
import { DropDownProps } from '../../../components/general/dropdown/DropDown';
import type { ProfileForm } from '../../MyProfile/components/Profile';

const UserInfo = styled.div`
    font-size: 14px;
    line-height: 22px;
    color: rgba(38, 50, 56, 0.9);
`;

const InputContainer = styled.div`
    margin-bottom: 0.643rem;
`;

const StyledDropdown = styled(({ ...props }: DropDownProps) => <Dropdown {...props} />)`
    width: 100%;

    button {
        height: 2.857rem;
        padding: 0.571rem 1.143rem;
    }
`;

const StyledPhoneInputField = styled(PhoneInputField)`
    && input {
        width: 100%;
    }
`;

interface IApproveUserProps {
    control: Control<ProfileForm, any>;
    errors: FieldErrors;
    emailIsReadOnly: boolean;
    showInfo: boolean;
    onError: (value: number, errorMessage: string) => void;
    register: UseFormRegister<ProfileForm>;
    touchedFields: any;
    trigger: UseFormTrigger<ProfileForm>;
    setValue: UseFormSetValue<ProfileForm>;
    getValues: UseFormGetValues<ProfileForm>;
}

const ApproveUser = ({
    control,
    errors,
    emailIsReadOnly,
    showInfo,
    onError,
    register,
    touchedFields,
    trigger,
    setValue,
    getValues,
}: IApproveUserProps): ReactElement => {
    const validate = useCallback(
        (name: 'firstname' | 'lastname' | 'email' | 'phonenumber' | 'approved' | 'language') => {
            // forces validation on previous untouched inputs
            const fieldNames: ('firstname' | 'lastname' | 'email' | 'phonenumber')[] = [
                'firstname',
                'lastname',
                'email',
                'phonenumber',
            ];
            const touched = Object.keys(touchedFields || {});
            if (!touched.includes(name)) {
                touched.push(name);
            }
            let lastTouchedIdx = -1;
            fieldNames.forEach((field, i) => {
                if (touched.includes(field)) {
                    lastTouchedIdx = Math.max(lastTouchedIdx, i);
                }
            });
            const skipped = fieldNames.filter((_, i) => i <= lastTouchedIdx);
            for (const name of skipped) {
                trigger(name);
                setValue(name, getValues(name), { shouldTouch: true });
            }
        },
        [touchedFields, trigger, getValues, setValue],
    );

    const { t } = useTranslation('common');
    return (
        <div>
            {showInfo && <UserInfo>{t('confirmContactInformation')}</UserInfo>}
            <InputContainer>
                <InputField
                    placeholder={t('firstName')}
                    label={t('firstName')}
                    required
                    type="text"
                    error={errors?.firstname?.message}
                    {...register('firstname')}
                    onBlur={() => validate('firstname')}
                />
            </InputContainer>
            <InputContainer>
                <InputField
                    placeholder={t('lastName')}
                    label={t('lastName')}
                    required
                    type="text"
                    error={errors?.lastname?.message}
                    {...register('lastname')}
                    onBlur={() => validate('lastname')}
                />
            </InputContainer>
            <InputContainer>
                <InputField
                    placeholder={t('email')}
                    label={t('email')}
                    required
                    readOnly={emailIsReadOnly}
                    type="text"
                    error={errors?.email?.message}
                    {...register('email')}
                    onBlur={() => validate('email')}
                />
            </InputContainer>
            <InputContainer>
                <Controller
                    render={({ field: { onChange, value, onBlur } }) => {
                        return (
                            <StyledPhoneInputField
                                value={value}
                                label={t('phoneNumber')}
                                onChange={(phoneNumber) => {
                                    onChange('+' + phoneNumber);
                                }}
                                error={errors?.phonenumber?.message?.length > 0}
                                onError={(value, errorMessage) => {
                                    onError(value, errorMessage);
                                }}
                                onBlur={() => {
                                    onBlur();
                                    validate('phonenumber');
                                }}
                                helpText={errors?.phonenumber?.message}
                            />
                        );
                    }}
                    name="phonenumber"
                    control={control}
                />
            </InputContainer>
            <InputContainer>
                <FormLabel>{t('language')}</FormLabel>
                <Controller
                    name="language"
                    key="language"
                    control={control}
                    render={({ field: { onChange }, field }) => {
                        const handleOnChange = (value: string) => {
                            onChange(value);
                        };
                        return (
                            <StyledDropdown
                                items={FormatLanguages}
                                placeholder={t('language')}
                                {...field}
                                onChange={handleOnChange}
                                error={errors.productStatus?.message}
                            />
                        );
                    }}
                />
            </InputContainer>
        </div>
    );
};

export default ApproveUser;
