import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SvgIcon, Badge } from '../../../components';
import { BadgeType } from '../../../components/dataDisplay/Badge';

const BadgeText = styled.p``;

const StyledBadge = styled(Badge)`
    height: 1.714rem;
`;

interface ActivationStatusBadgeProps {
    eShopEnabled: boolean;
}

const ActivationStatusBadge = ({ eShopEnabled = false }: ActivationStatusBadgeProps): ReactElement => {
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'settings' });
    return (
        <StyledBadge type={eShopEnabled ? BadgeType.Success : BadgeType.Failure} id="activationStatusBadge">
            <SvgIcon name="DotIcon" />
            <BadgeText>{eShopEnabled ? t('eShopEnabled') : t('eShopDisabled')}</BadgeText>
        </StyledBadge>
    );
};

export default ActivationStatusBadge;
