import { AxiosResponse } from 'axios';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import { FoodOrderBooking, FoodOrderDetails } from '../../../api/apiTypes/foodApiTypes';
import {
    getFoodBookingsFromApi,
    getFoodOrderDetailsFromApi,
    updateFoodBooking,
    deleteFoodOrderFromApi,
} from '../../../api/foodApi';
import { useInvalidateMeetingBookings } from '../../Meeting/apiQueries/useMeetingBookings';
import { DeliveryMethod, OrderedFoodType, orderedFoodLine } from '../types';
import { OrderFormType } from '../useFoodOrderForm';

const foodBookingsQueryCacheKey = 'foodBookings';

export const useGetBookings = (): QueryResult<FoodOrderBooking[], string> => {
    return useQuery([foodBookingsQueryCacheKey], () =>
        getFoodBookingsFromApi().then((result) =>
            result.data?.map((item) => ({
                ...item,
                deliveryDateTime: new Date(item.deliveryDateTime),
                orderedDateTime: new Date(item.orderedDateTime),
            })),
        ),
    );
};

export const useGetOrderDetails = (orderGuid: string, enabled = true): QueryResult<OrderedFoodType, string> => {
    return useQuery(
        [foodBookingsQueryCacheKey, orderGuid],
        () =>
            getFoodOrderDetailsFromApi(orderGuid)
                .then((result) => result.data)
                .then((item) => {
                    const deliveryMethod = inferDeliveryMethod(item);
                    return {
                        ...item,
                        orderGuid,
                        changesAcceptedUntil: new Date(item.changesAcceptedUntil),
                        deliveryMethod: deliveryMethod,
                        deliveryDateTime: new Date(item.deliveryDateTime),
                        orderedDateTime: new Date(item.orderedDateTime),
                        returnDateTime:
                            deliveryMethod !== 'hente' && item.returnDateTime ? new Date(item.returnDateTime) : null,
                        foodLines: item.orderLines.map<orderedFoodLine>((orderLine) => ({
                            ...orderLine,
                            taxPriceWithTableService:
                                Math.round(orderLine.taxWithTableService * orderLine.priceNet) / 100,
                            taxPriceWithoutTableService:
                                Math.round(orderLine.taxWithoutTableService * orderLine.priceNet) / 100,
                            orderDeadline: new Date(orderLine.orderDeadline),
                            isPassedDeadline: new Date(orderLine.orderDeadline) < new Date(),
                        })),
                        departmentBusinessHourTo: new Date(item.departmentBusinessHourTo),
                        departmentBusinessHourFrom: new Date(item.departmentBusinessHourFrom),
                        attachedMeeting: item.connectedMeeting,
                        canteenName: item.canteenName,
                    };
                }),
        {
            staleTime: 1000 * 60 * 60,
            enabled: enabled && orderGuid,
        },
    );
};

export const useLoadingStateOfFoodOrderDetails = (orderGuids: string[]): boolean => {
    const cache = useQueryCache();
    const loadingQuery = orderGuids.find(
        (id: string) => cache.getQuery([foodBookingsQueryCacheKey, id])?.state?.status === 'loading',
    );
    return loadingQuery !== undefined;
};

export const useGetOrderDetailsFromCache = (): ((id: string) => FoodOrderDetails | undefined) => {
    const cache = useQueryCache();
    return (id: string) => cache.getQueryData([foodBookingsQueryCacheKey, id]);
};

export const inferDeliveryMethod = ({ attendees, deliveryLocation }: FoodOrderDetails): DeliveryMethod => {
    if (attendees) return 'oppdekning';
    if (deliveryLocation) return 'levering';
    return 'hente';
};

type updateOrderDto = OrderFormType & { orderGuid: string; reference1?: string; reference2?: string };

export const useUpdateOrder = (): MutationResultPair<AxiosResponse<never>, never, updateOrderDto, never> => {
    const queryCache = useQueryCache();
    const invalidateMeetingBookings = useInvalidateMeetingBookings();
    return useMutation(
        ({
            orderGuid,
            deliveryDateTime,
            returnTime,
            deliveryLocation,
            numberOfAttendees,
            comment,
            purposeOfMeeting,
            participants,
            foodLines,
            deliveryMethod,
            attachedMeeting,
            customReference1,
            customReference2,
            canteenName,
        }: updateOrderDto) => {
            if (deliveryMethod == 'hente') {
                return updateFoodBooking({
                    orderGuid,
                    deliveryDateTime,
                    comment,
                    purposeOfMeeting: purposeOfMeeting ?? '',
                    participants: participants ?? '',
                    reference1: customReference1 ? customReference1 : '',
                    reference2: customReference2 ? customReference2 : '',
                    orderLines: foodLines,
                    attachedMeeting: attachedMeeting ?? null,
                    canteenName,
                });
            } else if (deliveryMethod === 'levering') {
                return updateFoodBooking({
                    orderGuid,
                    deliveryDateTime,
                    comment,
                    purposeOfMeeting: purposeOfMeeting ?? '',
                    participants: participants ?? '',
                    reference1: customReference1 ? customReference1 : '',
                    reference2: customReference2 ? customReference2 : '',
                    orderLines: foodLines,
                    attachedMeeting: attachedMeeting ?? null,
                    returnDateTime: returnTime ? new Date(returnTime) : undefined,
                    deliveryLocation: deliveryLocation as string,
                });
            } else {
                return updateFoodBooking({
                    orderGuid,
                    deliveryDateTime,
                    comment,
                    purposeOfMeeting: purposeOfMeeting ?? '',
                    participants: participants ?? '',
                    reference1: customReference1 ? customReference1 : '',
                    reference2: customReference2 ? customReference2 : '',
                    orderLines: foodLines,
                    attachedMeeting: attachedMeeting ?? null,
                    returnDateTime: returnTime ? new Date(returnTime) : undefined,
                    deliveryLocation: deliveryLocation as string,
                    attendees: numberOfAttendees as number,
                });
            }
        },

        {
            onSuccess: (_, mutationVariables) => {
                queryCache.invalidateQueries([foodBookingsQueryCacheKey], { exact: true });
                const queries = queryCache.getQueries([foodBookingsQueryCacheKey, mutationVariables.orderGuid]);
                queryCache.removeQueries([foodBookingsQueryCacheKey, mutationVariables.orderGuid]);
                queries.map((query) => query.refetch());
                invalidateMeetingBookings();
            },
        },
    );
};

export const useDeleteOrder = (): MutationResultPair<AxiosResponse<never>, never, string, never> => {
    const queryCache = useQueryCache();
    const invalidateMeetingBookings = useInvalidateMeetingBookings();
    return useMutation(deleteFoodOrderFromApi, {
        onSuccess: (_, orderGuid) => {
            queryCache.invalidateQueries([foodBookingsQueryCacheKey], { exact: true });
            queryCache.invalidateQueries([foodBookingsQueryCacheKey, orderGuid]);
            invalidateMeetingBookings();
        },
    });
};

export const useInvalidateFoodOrders = (): (() => void) => {
    const queryCache = useQueryCache();
    return () => {
        queryCache.invalidateQueries([foodBookingsQueryCacheKey]);
    };
};
