import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import PrivacyPolicyModal from '../../../ApprovePrivacyPolicy/components/PrivacyPolicyModal';
import { PrivacyInfo, DeleteSelf } from './components';

const ContainerDiv = styled.div`
    background-color: var(--background-color);
    padding: 0.5rem;
    width: 100%;
    height: 100%;
`;
const StyledButton = styled(Button)`
    &&& {
        background: none;
        padding: 0.5rem;
    }
`;

const ButtonText = styled.span`
    font-size: 0.75rem;
    line-height: 0.75rem;
    font-weight: bold;
    color: var(--primary-color) !important;
`;

const PersonverDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1rem;
`;

const PrivacyComponent = () => {
    const [showPrivacyAgreement, setShowPrivacyAgreement] = useState(false);
    const { t } = useTranslation('myProfile');
    return (
        <ContainerDiv>
            <PrivacyInfo />
            <PersonverDiv>
                <StyledButton onClick={() => setShowPrivacyAgreement(true)}>
                    <ButtonText>{t('readPrivacyStatement')}</ButtonText>
                </StyledButton>
            </PersonverDiv>
            <PrivacyPolicyModal
                open={showPrivacyAgreement}
                onClose={() => setShowPrivacyAgreement(false)}
                showApproveButton={false}
            />
            <DeleteSelf />
        </ContainerDiv>
    );
};

PrivacyComponent.propTypes = {};

export default PrivacyComponent;
