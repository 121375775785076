import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { MobileViewBreakpoint } from '../../../common/ScreenSizeBreakPoints';
import { ErrorMessage, FullPageLoader } from '../../../components';
import { useTicketingType } from '../apiQueries/useTicketingType';
import useTicketingNavigation from '../useTicketingNavigation';
import { TypeMenu, TypeView, TypeOverview, CategoryView } from './components';
import NewType from './components/TypeView/NewTicketType/NewTicketType';
import useCanCreateAndDeleteType from './useCanCreateAndDeleteType';

const Container = styled.div`
    display: flex;
    width: 100%;
    overflow-wrap: break-word;

    @media (max-width: ${MobileViewBreakpoint ?? 768}px) {
        flex-direction: column;
    }
`;

const SettingsComponent = (): ReactElement => {
    const { data: types, isLoading } = useTicketingType();
    const { getTypeLink, settingsLink, createNewTypeLink, getCategoryTabLink } = useTicketingNavigation();
    const canCreateType = useCanCreateAndDeleteType();
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    if (isLoading) {
        return <FullPageLoader text="" />;
    }

    return (
        <Container>
            {types ? (
                <>
                    <TypeMenu types={types} />
                    <Switch>
                        {canCreateType ? (
                            <Route path={`/:portalName${createNewTypeLink}`}>
                                <NewType allTypeNames={types.map((type) => type.name.toLowerCase())} />
                            </Route>
                        ) : null}
                        <Route path={`/:portalName${getCategoryTabLink(':typeId', ':categoryId', ':tab')}`}>
                            <CategoryView types={types} />
                        </Route>
                        <Route path={`/:portalName${getTypeLink(':typeId')}`}>
                            <TypeView types={types} />
                        </Route>
                        <Route path={`/:portalName${settingsLink}`}>
                            <TypeOverview types={types} />
                        </Route>
                    </Switch>
                </>
            ) : (
                <ErrorMessage message={t('errorMessage')} />
            )}
        </Container>
    );
};

export default SettingsComponent;
