import { ContentState, convertFromRaw } from 'draft-js';

const parseContentModelToContentState = (rawText: string): ContentState => {
    try {
        return convertFromRaw(JSON.parse(rawText));
    } catch (ex) {
        console.error('Error parsing, just returning plain', ex);
        return ContentState.createFromText(rawText);
    }
};

export default parseContentModelToContentState;
