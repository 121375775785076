import { ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 1.714rem;
    height: 1.714rem;
    border: 3px solid #e9ebeb;
    border-top: 3px solid var(--text-placeholder-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Spinner = (): ReactElement => {
    return <Container />;
};

export default Spinner;
