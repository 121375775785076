import { AxiosError, AxiosResponse } from 'axios';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { ModuleName } from '../../../api/apiTypes/portalApiTypes';
import { CompanyActivationSettings } from '../../../api/apiTypes/shopApiTypes';
import {
    activateShop,
    getCompanyActivationSettings,
    getCompanyActivationSettingsByPortalAdmin,
} from '../../../api/shopApi';
import Perm from '../../../common/perms';
import { useModuleIsActive, useUserHasPerm } from '../../../utility';

const ShopCompanyActivationSettings = 'ShopCompanyActivationSettings';
const ShopCompanyActivatedByPortalAdmin = 'ShopCompanyActivatedByPortalAdmin';

export const useGetShopSettings = (): QueryResult<CompanyActivationSettings, string | AxiosError<string> | Error> => {
    return useQuery([ShopCompanyActivationSettings], async () => {
        const result = await getCompanyActivationSettings();
        return result.data;
    });
};

export const useGetShopIsActivatedFromPortalAdmin = (): QueryResult<boolean, string | AxiosError<string> | Error> => {
    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    const shopAtWorkModuleIsActivated = useModuleIsActive(ModuleName.ShopAtWork);
    return useQuery(
        [ShopCompanyActivatedByPortalAdmin],
        async () => {
            const result = await getCompanyActivationSettingsByPortalAdmin();
            return result.data;
        },
        {
            enabled: userIsPortalAdmin && shopAtWorkModuleIsActivated,
        },
    );
};

export const useActivateShop = (): MutationResultPair<
    AxiosResponse<string>,
    string | AxiosError<string> | Error,
    [CompanyActivationSettings],
    never
> => {
    const cache = useQueryCache();

    return useMutation(
        ([form]) => {
            return activateShop(form);
        },
        {
            onSuccess: async () => {
                await cache.invalidateQueries(ShopCompanyActivationSettings);
            },
        },
    );
};
