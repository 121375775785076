import { ReactElement } from 'react';
import styled from 'styled-components';

const disabledColor = '#677074';
const primaryColor = 'var(--primary-color)';

const SwitchBox = styled.div`
    position: relative;

    display: flex;
    justify-content: space-between;

    border: solid 1px #e6e6e6;
    border-radius: 2rem;
`;

const SwitchOption = styled.button`
    border: none;
    background: none;
    width: 100%;
    padding: 0.7rem 1.43rem;
    text-align: center;
    color: ${({ selected }: { selected: boolean }) => (selected ? 'white' : 'var(--text-medium-emphasis-color)')};
    font-weight: bold;
    white-space: nowrap;
    cursor: ${({ selected }: { selected: boolean }) => (selected ? 'default' : 'pointer')};
    z-index: 1;

    outline: none;

    :focus-visible {
        box-shadow: 0 0 0 1px var(--background-color),
            0 0 0 2px ${({ selected }: { selected: boolean }) => (selected ? disabledColor : primaryColor)};
    }
`;

const SelectedBackground = styled.div`
    position: absolute;
    top: 0;
    ${({ selected }: { selected: number }) => (selected == 1 ? 'left: 0;' : 'right: 0;')}

    height: 100%;
    width: 50%;

    border-radius: 2rem;
    background-color: var(--primary-color);
`;

interface ISwitchToggleProps {
    option1Text: string;
    option2Text: string;
    selected: number;
    onClick: (option: number) => void;
    readonly?: boolean;
    className?: string;
}

const SwitchToggle = ({
    option1Text,
    option2Text,
    selected,
    onClick,
    readonly = false,
    className,
}: ISwitchToggleProps): ReactElement => {
    if (!(selected === 1 || selected === 2)) {
        throw new RangeError('SwitchToggle selected value must be either 1 or 2');
    }
    return (
        <SwitchBox className={className}>
            <SelectedBackground selected={selected} />
            <SwitchOption
                selected={selected === 1}
                onClick={() => onClick(1)}
                disabled={readonly}
                aria-pressed={selected === 1}
            >
                {option1Text}
            </SwitchOption>
            <SwitchOption
                selected={selected === 2}
                onClick={() => onClick(2)}
                disabled={readonly}
                aria-pressed={selected === 2}
            >
                {option2Text}
            </SwitchOption>
        </SwitchBox>
    );
};

export default SwitchToggle;
