import { ReactElement, useMemo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TicketSortDirection, TicketSortingType } from '../../../../api/apiTypes/ticketingApiTypes';
import {
    PrimaryButton,
    SearchInput,
    SvgIcon,
    SearchSelect,
    SortingDrawer,
    GhostPrimaryButton,
    FilteringDrawer,
} from '../../../../components';
import { GroupedOptionType, OptionType } from '../../../../components/select/searchSelect/types';
import {
    useGetTicketAssignees,
    useGetTicketCompanies,
    useGetTicketPriorities,
    useGetTicketStatuses,
    useTicketingOptionsType,
} from '../../apiQueries/useTicketingTicket';
import { useTicketingType } from '../../apiQueries/useTicketingType';
import { FiltersType, TicketingContext } from '../../Ticketing';
import { FilterKeys, getFilterDropdownOptions, getViewFilters } from '../../utils';
import {
    renderTicketStatusOptions,
    renderPriorityOptions,
    renderCategoriesOptions,
    renderAssigneesOptions,
    renderCompaniesOptions,
    renderCategoriesAdminOptions,
} from './utils';

type ControlsWrapperProps = { isTabletOrMobileView: boolean };
const ControlsWrapper = styled.div`
    display: flex;
    flex-direction: ${({ isTabletOrMobileView }: ControlsWrapperProps) => (isTabletOrMobileView ? 'column' : 'row')};
    justify-content: space-between;
    align-items: ${({ isTabletOrMobileView }: ControlsWrapperProps) =>
        isTabletOrMobileView ? 'flex-start' : 'center'};
    flex-wrap: wrap;
    gap: 0.571rem;
    margin-bottom: 0.571rem;
`;

const SearchAndSortContainer = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.571rem;
    margin: 0.571rem 0;
`;

const SortAndFilterButton = styled(PrimaryButton)`
    height: 2.857rem;
    svg {
        rect {
            fill: var(--background-color);
        }
    }
`;

const TicketFiltersWrapper = styled.div`
    display: flex;
    gap: 0.571rem;
`;

type SearchFieldProps = { isTabletOrMobileView: boolean };
const SearchField = styled(SearchInput)`
    min-width: ${({ isTabletOrMobileView }: SearchFieldProps) => (isTabletOrMobileView ? 'auto' : '21.071rem')};
    width: 100%;
    height: 2.857rem;
    margin: 0;
    flex: 1;

    input {
        text-overflow: ellipsis;
    }
`;

const TicketFilter = styled(SearchSelect)`
    min-width: 12rem;
`;

const FilterDrawerButtonContainer = styled.div`
    gap: 0.571rem;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
`;

const OpenFilterDrawerButton = styled(GhostPrimaryButton)`
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    width: auto;
    display: flex;
    &&& {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        gap: 0.285rem;
    }
`;

const ButtonTextWrapper = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const ButtonCount = styled.p`
    display: inline;
`;

const ButtonText = styled.p`
    display: inline;
`;

export enum TicketListView {
    Worklist = 'Worklist',
    MyTickets = 'MyTickets',
    Caseworker = 'Caseworker',
    CompanyTickets = 'CompanyTickets',
}

export interface TicketFilterProps {
    isSearchable: boolean;
    key: FilterKeys;
    title: string;
    options: OptionType[] | GroupedOptionType[] | [];
    onChange: (options: OptionType[] | []) => void;
    value: OptionType[] | [];
    onResetOptions: () => void;
    autoMenuPosition: boolean;
}

interface UserTicketsControlProps {
    view: TicketListView;
    isTabletOrMobileView: boolean;
    itemsCount: number;
    isMobile: boolean;
    sortingType: TicketSortingType;
    sortingDirection: TicketSortDirection;
    filters: FiltersType;
}

const UserTicketsControl = ({
    view,
    isTabletOrMobileView,
    itemsCount,
    isMobile,
    sortingType,
    sortingDirection,
    filters,
}: UserTicketsControlProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    const { state: TicketingContextState, dispatch } = useContext(TicketingContext);
    const { data: statusesOptions } = useGetTicketStatuses();
    const { data: prioritiesOptions } = useGetTicketPriorities();
    const { data: types } = useTicketingOptionsType();
    const { data: adminTypes } = useTicketingType();
    const { data: companiesOptions } = useGetTicketCompanies();
    const { data: assigneesOptions } = useGetTicketAssignees();
    const { companies, statuses, priorities, categories, assignees } = filters;

    const [visibleDrawer, setVisibleDrawer] = useState<string | null>(null);
    const [showMobileFilters, setShowMobileFilters] = useState(false);

    const filterDropdownOptions = getFilterDropdownOptions(view, isMobile);

    const onSortDirectionHandler = () => {
        sortingDirection === TicketSortDirection.Desc
            ? dispatch({
                  type: 'UPDATE_TICKET_SORTING_DIRECTION',
                  payload: { sortingDirection: TicketSortDirection.Asc, view },
              })
            : dispatch({
                  type: 'UPDATE_TICKET_SORTING_DIRECTION',
                  payload: { sortingDirection: TicketSortDirection.Desc, view },
              });
    };

    const onChangeSearchTermHandler = (searchTerm: string) => {
        dispatch({
            type: 'UPDATE_TICKET_SEARCH_TERM',
            payload: searchTerm,
        });
    };

    const onChangeFiltersHandler = (filterType: string, selectedOptions: OptionType[]) => {
        dispatch({
            type: 'UPDATE_TICKET_FILTERS',
            payload: { [filterType]: selectedOptions, view },
        });
    };

    const ticketFilters: TicketFilterProps[] = useMemo(() => {
        const allFilters = [
            {
                onChange: (options: OptionType[] | []) => onChangeFiltersHandler(FilterKeys.Companies, options),
                value: companies,
                key: FilterKeys.Companies,
                title: t('filterDropdownOptionsLastCompany'),
                onResetOptions: () => onChangeFiltersHandler(FilterKeys.Companies, []),
                options: renderCompaniesOptions(companiesOptions),
                isSearchable: true,
                autoMenuPosition: true,
            },
            {
                onChange: (options: OptionType[] | []) => onChangeFiltersHandler(FilterKeys.Assignees, options),
                value: assignees,
                key: FilterKeys.Assignees,
                title: t('assigned'),
                onResetOptions: () => onChangeFiltersHandler(FilterKeys.Assignees, []),
                options: renderAssigneesOptions(assigneesOptions),
                isSearchable: true,
                autoMenuPosition: true,
            },
            {
                onChange: (options: OptionType[] | []) => onChangeFiltersHandler(FilterKeys.Categories, options),
                value: categories,
                key: FilterKeys.Categories,
                title: t('filterDropdownOptionsCategory'),
                onResetOptions: () => onChangeFiltersHandler(FilterKeys.Categories, []),
                options:
                    view === TicketListView.Worklist
                        ? renderCategoriesAdminOptions(adminTypes)
                        : renderCategoriesOptions(types),
                isSearchable: true,
                autoMenuPosition: true,
            },
            {
                onChange: (options: OptionType[] | []) => onChangeFiltersHandler(FilterKeys.Statuses, options),
                value: statuses,
                key: FilterKeys.Statuses,
                title: t('status'),
                onResetOptions: () => onChangeFiltersHandler(FilterKeys.Statuses, []),
                options: renderTicketStatusOptions(statusesOptions),
                isSearchable: false,
                autoMenuPosition: true,
            },
            {
                onChange: (options: OptionType[] | []) => onChangeFiltersHandler(FilterKeys.Priorities, options),
                value: priorities,
                key: FilterKeys.Priorities,
                title: t('priority'),
                onResetOptions: () => onChangeFiltersHandler(FilterKeys.Priorities, []),
                options: renderPriorityOptions(prioritiesOptions),
                isSearchable: false,
                autoMenuPosition: true,
            },
        ];

        return getViewFilters(view, allFilters);
    }, [
        companies,
        companiesOptions,
        prioritiesOptions,
        statusesOptions,
        statuses,
        priorities,
        categories,
        types,
        adminTypes,
        assigneesOptions,
        assignees,
        view,
    ]);

    return (
        <ControlsWrapper isTabletOrMobileView={isTabletOrMobileView}>
            <SearchAndSortContainer>
                <SearchField
                    isTabletOrMobileView={isTabletOrMobileView}
                    onChange={onChangeSearchTermHandler}
                    value={TicketingContextState.searchTerm}
                    placeholder={t('searchInquiries')}
                />
                {isTabletOrMobileView && (
                    <SortAndFilterButton onClick={() => setVisibleDrawer('sorting')}>
                        <SvgIcon name="SortIcon" />
                    </SortAndFilterButton>
                )}
                {isTabletOrMobileView && (
                    <SortAndFilterButton onClick={() => setShowMobileFilters(!showMobileFilters)}>
                        <SvgIcon name="FilterIcon" />
                    </SortAndFilterButton>
                )}
            </SearchAndSortContainer>

            {isTabletOrMobileView && (
                <SortingDrawer
                    isVisible={visibleDrawer === 'sorting'}
                    onClose={() => setVisibleDrawer(null)}
                    onChange={(value: unknown) =>
                        dispatch({
                            type: 'UPDATE_TICKET_SORTING_TYPE',
                            payload: { sortingType: value as TicketSortingType, view },
                        })
                    }
                    filterOptions={filterDropdownOptions}
                    filterValue={sortingType}
                    sortingDirection={sortingDirection}
                    onChangeDirection={onSortDirectionHandler}
                />
            )}

            {isTabletOrMobileView ? (
                showMobileFilters && (
                    <FilterDrawerButtonContainer>
                        {ticketFilters.map((filter) => {
                            const isVisible = visibleDrawer === filter.title;
                            return (
                                <>
                                    <OpenFilterDrawerButton
                                        key={filter.key}
                                        onClick={() => setVisibleDrawer(filter.title)}
                                    >
                                        <ButtonTextWrapper>
                                            <ButtonText>{filter.title} </ButtonText>
                                        </ButtonTextWrapper>

                                        {!!filter.value.length && (
                                            <ButtonCount> {`(${filter.value.length})`}</ButtonCount>
                                        )}
                                    </OpenFilterDrawerButton>
                                    <FilteringDrawer
                                        isVisible={isVisible}
                                        onClose={() => setVisibleDrawer(null)}
                                        onReset={filter.onResetOptions}
                                        filterName={filter.key}
                                        filterTitle={filter.title}
                                        filterOptions={filter.options}
                                        filterValue={filter.value}
                                        isSearchable={filter.isSearchable}
                                        onFilterChange={filter.onChange}
                                        itemsCount={itemsCount}
                                    ></FilteringDrawer>
                                </>
                            );
                        })}
                    </FilterDrawerButtonContainer>
                )
            ) : (
                <TicketFiltersWrapper>
                    {ticketFilters.map(
                        ({ isSearchable, value, onChange, onResetOptions, key, title, options, autoMenuPosition }) => (
                            <TicketFilter
                                key={key}
                                onChange={onChange}
                                value={value}
                                name={key}
                                title={title}
                                onResetOptions={onResetOptions}
                                options={options}
                                isSearchable={isSearchable}
                                autoMenuPosition={autoMenuPosition}
                            />
                        ),
                    )}
                </TicketFiltersWrapper>
            )}
        </ControlsWrapper>
    );
};

export default UserTicketsControl;
