import { FC, PropsWithChildren, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements } from '@stripe/react-stripe-js';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { Toggle } from '../../../../components';
import { CardForm } from '../../contexts/types';

const StyledCardElementWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.286rem;
`;

const StyledCardElementLabel = styled.span`
    font-size: 0.857rem;
    font-weight: 400;
    line-height: 1.143rem;
    letter-spacing: 0;
    color: #677074;
`;

const StyledCardNumberElement = styled(CardNumberElement)`
    border: 1px solid #d4d6d7;
    padding: 1rem 0.857rem;
    border-radius: 0.285rem;
`;

const StyledCardCvcElement = styled(CardCvcElement)`
    border: 1px solid #d4d6d7;
    padding: 1rem 0.857rem;
    border-radius: 0.285rem;
`;

const StyledCardExpirationElement = styled(CardExpiryElement)`
    border: 1px solid #d4d6d7;
    padding: 1rem 0.857rem;
    border-radius: 0.285rem;
`;

const AddNewCardExpirationAndCvcWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.571rem;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.143rem;
`;

const SwitchGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.571rem;
`;

const cardFormValidationSchema = yup.object({
    isCardNumberComplete: yup.boolean().oneOf([true]),
    isSaveCardDetails: yup.boolean(),
    isSaveCardAsDefault: yup.boolean(),
    isCardCvcComplete: yup.boolean().oneOf([true]),
    isCardExpirationComplete: yup.boolean().oneOf([true]),
});

interface CardElementProps {
    label: string;
}
const CardElement: FC<PropsWithChildren<CardElementProps>> = ({ children, label }) => {
    return (
        <StyledCardElementWrapper>
            <StyledCardElementLabel>{label}</StyledCardElementLabel>
            {children}
        </StyledCardElementWrapper>
    );
};

interface StripeCardFormProps {
    setCardFormData: (cardFormData: CardForm) => void;
}

export const StripeCardForm: FC<StripeCardFormProps> = ({ setCardFormData }) => {
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'shop' });

    const {
        control,
        formState: { isValid },
        getValues,
        setValue,
        watch,
    } = useForm<CardForm>({
        defaultValues: {
            isSaveCardDetails: false,
            isSaveCardAsDefault: false,
            isCardCvcComplete: false,
            isCardExpirationComplete: false,
            isCardNumberComplete: false,
        },
        resolver: yupResolver(cardFormValidationSchema),
        mode: 'all',
    });
    const elements = useElements();
    const cardExpirationElement = elements?.getElement('cardExpiry');
    const cardCvcElement = elements?.getElement('cardCvc');

    const [isSaveCardDetails, isSaveCardAsDefault] = watch(['isSaveCardDetails', 'isSaveCardAsDefault']);

    useEffect(() => {
        if (isValid) setCardFormData(getValues());
    }, [isValid, isSaveCardDetails, isSaveCardAsDefault]);

    return (
        <Wrapper>
            <Controller
                render={({ field: { onChange } }) => (
                    <CardElement label={t('stripeCardFormCardNumberInputLabel')}>
                        <StyledCardNumberElement
                            options={{
                                placeholder: t('stripeCardFormCardNumberInputPlaceholder'),
                                showIcon: true,
                                iconStyle: 'solid',
                            }}
                            onChange={({ complete }) => {
                                onChange(complete);
                                if (complete) {
                                    cardExpirationElement?.focus();
                                }
                            }}
                        />
                    </CardElement>
                )}
                name={'isCardNumberComplete'}
                control={control}
            />
            <AddNewCardExpirationAndCvcWrapper>
                <Controller
                    render={({ field: { onChange } }) => (
                        <CardElement label={t('stripeCardFormExpirationInputLabel')}>
                            <StyledCardExpirationElement
                                onChange={({ complete }) => {
                                    onChange(complete);
                                    if (complete) {
                                        cardCvcElement?.focus();
                                    }
                                }}
                            />
                        </CardElement>
                    )}
                    name={'isCardExpirationComplete'}
                    control={control}
                />

                <Controller
                    render={({ field: { onChange } }) => (
                        <CardElement label={t('stripeCardFormCVCInputLabel')}>
                            <StyledCardCvcElement onChange={({ complete }) => onChange(complete)} />
                        </CardElement>
                    )}
                    name={'isCardCvcComplete'}
                    control={control}
                />
            </AddNewCardExpirationAndCvcWrapper>
            <SwitchGroup>
                <Controller
                    render={({ field: { name, onChange, value } }) => (
                        <Toggle
                            name={name}
                            onChange={(_, isChecked) => {
                                if (!isChecked) {
                                    setValue('isSaveCardAsDefault', false);
                                }
                                onChange(isChecked);
                            }}
                            checked={value}
                            toggleText={t('stripeCardFormCardToggleLabel')}
                        />
                    )}
                    name={'isSaveCardDetails'}
                    control={control}
                />

                <Controller
                    render={({ field: { name, onChange, value } }) => (
                        <Toggle
                            name={name}
                            onChange={(_, isChecked) => {
                                onChange(isChecked);
                            }}
                            checked={value}
                            toggleText={t('stripeCardFormDefaultCardToggleLabel')}
                        />
                    )}
                    name={'isSaveCardAsDefault'}
                    control={control}
                />
            </SwitchGroup>
        </Wrapper>
    );
};
