import { ReactElement, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import styled from 'styled-components';
import { DeleteTypeCollectionItemDto, TicketType } from '../../../../../../../api/apiTypes/ticketingApiTypes';
import { PrimaryButton, Dropdown, GhostPrimaryButton, Modal, ModalType } from '../../../../../../../components';
import { DropDownProps } from '../../../../../../../components/general/dropdown/DropDown';
import { useNavigation } from '../../../../../../../utility';
import { useIsMobile } from '../../../../../../../utility/hooks/useIsMobile';
import { TicketTypeQueryCacheKey, useDeleteTicketType } from '../../../../../apiQueries/useTicketingType';
import { Header, Paragraph } from '../../../../../common/styles';
import useTicketingNavigation from '../../../../../useTicketingNavigation';

const FooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Container = styled.div`
    width: 100%;
`;

const ModalContentWrapper = styled.p`
    padding: 2rem;
`;

const StyledGhostPrimaryButton = styled(GhostPrimaryButton)`
    &&& {
        margin-right: 1rem;
    }
`;
const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-right: 1rem;
    }
`;

const StyledDropdown = styled(({ ...props }: DropDownProps) => <Dropdown {...props} />)`
    height: 14.286rem;
`;

interface DeleteTypeProps {
    type: TicketType;
    allTypes: TicketType[];
}

const DeleteType = ({ type, allTypes }: DeleteTypeProps): ReactElement => {
    const isMobile = useIsMobile();
    const [deleteType, { isLoading, isSuccess }] = useDeleteTicketType();
    const cache = useQueryCache();
    const { settingsLink } = useTicketingNavigation();
    const navigation = useNavigation();
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const modalBodies = useMemo(() => {
        return {
            typeHasCategories: t('typeHasCategories', { categoriesLength: type.categories.length }),
            lastType: t('lastType'),
            typeDeletedSuccessfully: t('typeDeletedSuccessfully'),
        };
    }, [type.categories.length]);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [moveCategoriesModal, setMoveCategoriesModal] = useState(false);
    const [modalBody, setModalBody] = useState(
        allTypes.length > 1 ? modalBodies.typeHasCategories : modalBodies.lastType,
    );
    const [currentIndex, setCurrentIndex] = useState(0);
    const [chosenType, setChosenType] = useState('');
    const [reassignProps, setReassignProps] = useState<DeleteTypeCollectionItemDto[]>(
        type.categories.map((category) => ({
            categoryId: category.id,
            newTypeId: '',
        })),
    );

    useEffect(() => {
        if (isSuccess) {
            setMoveCategoriesModal(false);
            setModalBody(modalBodies.typeDeletedSuccessfully);
        }
    }, [isSuccess, modalBodies.typeDeletedSuccessfully]);

    const deleteTypeRequest = () => {
        deleteType([type.id, reassignProps], {
            onSuccess: () => {
                setOpenDeleteModal(true);
                setMoveCategoriesModal(false);
                setModalBody(modalBodies.typeDeletedSuccessfully);
            },
        });
    };

    const handleChangeReassignProps = (typeId: string) => {
        reassignProps[currentIndex].newTypeId = typeId;
        setReassignProps([...reassignProps]);
    };

    const handleAbortClick = () => {
        setOpenDeleteModal(false);
        navigation.replace(settingsLink);
        if (modalBodies.typeDeletedSuccessfully == modalBody) cache.invalidateQueries(TicketTypeQueryCacheKey);
    };

    const dropdownItems = useMemo(
        () =>
            allTypes
                .filter((ticketType) => {
                    return ticketType.id != type.id; //exclude current type from new assigned ones
                })
                .map((type) => ({
                    value: type.name,
                    id: type.id,
                })),
        [allTypes, type.id],
    );

    const handleChosenTypeOnChange = (typeId: string) => {
        setChosenType(typeId);
        handleChangeReassignProps(typeId);
    };
    const StartModalFooter = (
        <FooterWrapper>
            <StyledGhostPrimaryButton onClick={() => setOpenDeleteModal(false)}>
                {tCommon('cancelButton')}
            </StyledGhostPrimaryButton>
            <PrimaryButton
                onClick={() => {
                    setMoveCategoriesModal(true);
                }}
            >
                {tCommon('next')}
            </PrimaryButton>
        </FooterWrapper>
    );

    const TextFooter = (text: string) => (
        <FooterWrapper>
            <PrimaryButton
                onClick={() => {
                    handleAbortClick();
                }}
            >
                <Paragraph>{text}</Paragraph>
            </PrimaryButton>
        </FooterWrapper>
    );

    const ModalFooter = (
        <FooterWrapper>
            {isMobile ? null : (
                <StyledGhostPrimaryButton onClick={() => handleAbortClick()}>
                    {tCommon('cancelButton')}
                </StyledGhostPrimaryButton>
            )}
            <StyledPrimaryButton
                onClick={() => {
                    const newIndex = currentIndex > 0 ? currentIndex - 1 : 0;
                    setCurrentIndex(newIndex);
                    setChosenType(reassignProps[newIndex].newTypeId);
                }}
                disabled={currentIndex === 0}
            >
                {t('previous')}
            </StyledPrimaryButton>
            {type.categories.length - 1 > currentIndex && (
                <PrimaryButton
                    onClick={() => {
                        const newIndex = currentIndex < type.categories.length - 1 ? currentIndex + 1 : currentIndex;
                        setCurrentIndex(newIndex);
                        setChosenType(reassignProps[newIndex].newTypeId);
                    }}
                    disabled={!chosenType}
                >
                    {tCommon('next')}
                </PrimaryButton>
            )}
            {type.categories.length - 1 === currentIndex && (
                <PrimaryButton onClick={() => deleteTypeRequest()} disabled={!chosenType || isLoading}>
                    {t('complete')}
                </PrimaryButton>
            )}
        </FooterWrapper>
    );

    return (
        <Container>
            <Header>{t('deleteType')}</Header>
            <Paragraph>{t('deleteTypeDescription')}</Paragraph>
            <PrimaryButton
                error
                disabled={isLoading}
                onClick={() => {
                    if (type.categories.length === 0) deleteTypeRequest();
                    else setOpenDeleteModal(true);
                }}
            >
                {tCommon('delete')}
            </PrimaryButton>
            <Modal
                key={`deleteTypeModal${type.id}`}
                type={ModalType.Medium}
                title={
                    modalBodies.typeDeletedSuccessfully == modalBody
                        ? t('typeNameDeleted', { name: type.name })
                        : t('typeNameDelete', { name: type.name })
                }
                onClose={() => handleAbortClick()}
                open={openDeleteModal}
                hideCloseButton={!isMobile}
                bottomContent={
                    modalBodies.typeHasCategories == modalBody && !moveCategoriesModal
                        ? StartModalFooter
                        : !moveCategoriesModal
                        ? TextFooter(modalBodies.typeDeletedSuccessfully == modalBody ? tCommon('closeButton') : 'OK')
                        : ModalFooter
                }
            >
                {!moveCategoriesModal && <ModalContentWrapper>{modalBody}</ModalContentWrapper>}
                {moveCategoriesModal && (
                    <ModalContentWrapper
                        key={`deleteTypeModalWrapper${
                            type.categories[currentIndex < type.categories.length ? currentIndex : 0].id
                        }`}
                    >
                        <p>{type.categories[currentIndex < type.categories.length ? currentIndex : 0].name}</p>
                        <StyledDropdown
                            items={dropdownItems}
                            onChange={(event) => handleChosenTypeOnChange(event)}
                            initialValue={chosenType}
                            label={t('moveToAnotherType')}
                            required={true}
                            placeholder={t('selectType')}
                            isScrollable={true}
                        />
                    </ModalContentWrapper>
                )}
            </Modal>
        </Container>
    );
};

export default DeleteType;
