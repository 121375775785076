import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useAnimation } from 'framer-motion';
import { DeliveryMethod } from '../../../modules/FoodForMeetings/contexts/types';
import { Button, FlexBox, MotionBox, Typography } from '../index';
import { SWITCHER_ACCEPTABLE_VALUE, WITH_TIMING_CONFIG } from './constants';
import { selectedValueType, SwitchToggleProps } from './types';

const SwitchToggle: FC<SwitchToggleProps> = ({ options, selectedValue, changeSelectedValue, activeBackground }) => {
    const activeBtnController = useAnimation();
    const activeTextController = useAnimation();

    const btnRef = useRef<HTMLButtonElement>(null);

    const changeCallback = useCallback(
        (val: selectedValueType) => () => {
            if (val !== selectedValue) {
                changeSelectedValue(val);
            }
        },
        [changeSelectedValue, selectedValue],
    );

    const contentList = useMemo(
        () => [
            {
                text: options.firstText,
                value: SWITCHER_ACCEPTABLE_VALUE[DeliveryMethod.PICKUP],
            },
            {
                text: options.secondText,
                value: SWITCHER_ACCEPTABLE_VALUE[DeliveryMethod.DELIVERY],
            },
        ],
        [options],
    );

    const activeText = useMemo(
        () => contentList.find((btn) => btn.value === selectedValue)?.text,
        [contentList, selectedValue],
    );

    const btnsRender = useMemo(() => {
        return contentList.map((btn) => (
            <MotionBox
                key={btn.value}
                animate={activeTextController}
                initial={{ opacity: 1 }}
                br={'2.143rem'}
                overflow={'hidden'}
            >
                <Button
                    forwardedRef={btnRef}
                    onClick={changeCallback(btn.value)}
                    background={'#fff'}
                    align={'center'}
                    justify={'center'}
                    width={'auto'}
                    pd={'0.571rem 1.143rem'}
                >
                    <Typography fontWeight={700} lineHeight={'1.714rem'} color={'#475156'}>
                        {btn.text}
                    </Typography>
                </Button>
            </MotionBox>
        ));
    }, [contentList, selectedValue, changeCallback]);

    useEffect(() => {
        if (btnRef.current) {
            activeBtnController?.start({
                left:
                    selectedValue === SWITCHER_ACCEPTABLE_VALUE[DeliveryMethod.DELIVERY]
                        ? btnRef.current?.getBoundingClientRect().width
                        : 0,
            });
        }
    }, [selectedValue, activeBtnController]);

    return (
        <FlexBox borderWidth={'1px'} br={'2.143rem'} overflow={'hidden'} position={'relative'} width={'auto'}>
            <MotionBox
                background={activeBackground || 'var(--primary-color)'}
                br={'2.143rem'}
                align={'center'}
                justify={'center'}
                position={'absolute'}
                top={'0rem'}
                width={'auto'}
                height={'2.857rem'}
                pd={'0.571rem 1.143rem'}
                animate={activeBtnController}
                initial={{ left: 0 }}
                transition={WITH_TIMING_CONFIG}
            >
                <Typography color={'#fff'} fontWeight={700} lineHeight={'1.714rem'} textWrap={'nowrap'}>
                    {activeText}
                </Typography>
            </MotionBox>
            {btnsRender}
        </FlexBox>
    );
};

export default memo(SwitchToggle);
