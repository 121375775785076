import { AxiosResponse } from 'axios';
import { AssetSearchModel } from './apiTypes/assetApiTypes';
import http from './infrastructure/httpService';
import { getBaseUrlApim, getPortalId } from './utils';

const urlPortalApi = process.env.REACT_APP_APIM_PORTAL_URL;

export const getAssetSearchInfo = (): Promise<AxiosResponse<AssetSearchModel[]>> => {
    const url = `${getBaseUrlApim()}/${urlPortalApi}/portals/${getPortalId()}/assets/search`;

    return http.get(url, {
        params: {
            types: 'Property,Building,Floor',
        },
    });
};
