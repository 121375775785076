import { ReactElement } from 'react';
import styled from 'styled-components';
import { TicketImage } from '../../../../../../../../api/apiTypes/ticketingApiTypes';
import { SvgIcon } from '../../../../../../../../components/';
import { ImageView } from '../../../../../common';

const ImageItemContainer = styled.div`
    display: flex;
`;

const ImageCloseButton = styled.button`
    border: none;
    background: none;
    cursor: pointer;
    width: 0.857rem;
    height: 0.857rem;
    padding: 0;
    margin: 0 0.429rem 0.429rem;

    svg {
        path {
            fill: var(--text-high-emphasis-color);
        }
    }

    :focus,
    :focus-visible {
        outline-offset: 1px;
        outline: 1px solid var(--primary-color);
    }
    :focus:not(:focus-visible) {
        outline: 0;
    }
`;

interface ImagePreviewProps {
    image: TicketImage | null;
    alt: string | undefined;
    onRemoveImage?(): void;
}

const ImagePreview = ({ image, alt, onRemoveImage }: ImagePreviewProps): ReactElement | null => {
    if (!image?.imageUrl) return null;
    return (
        <ImageItemContainer>
            <ImageView image={image} alt={alt} preview={true} />
            {onRemoveImage ? (
                <ImageCloseButton onClick={onRemoveImage}>
                    <SvgIcon name="CloseIcon" />
                </ImageCloseButton>
            ) : null}
        </ImageItemContainer>
    );
};

export default ImagePreview;
