import { AxiosPromise, AxiosResponse } from 'axios';
import { TicketListView } from '../modules/Ticketing/components/UserTickets/UserTickets';
import {
    CaseworkerModel,
    Category,
    CreateTicketComment,
    CreateTicketDto,
    CreateTicketTypeDto,
    DeleteCategoryDto,
    DeleteTypeCollectionItemDto,
    Ticket,
    TicketActivity,
    TicketComment,
    TicketPriority,
    TicketReportee,
    TicketReadCount,
    TicketReadCountDto,
    TicketSeenEntity,
    TicketSortDirection,
    TicketSortingType,
    TicketStatus,
    TicketType,
    TicketUserCount,
    UpdateTicket,
    UpdateTicketingTypeCategory,
    UpdateTicketTypeCaseworkerDto,
    UpdateTicketTypeDto,
    UploadedTicketImage,
    UserIsCaseWorker,
    UserIsCompanyAdmin,
    UserTicketType,
    TicketAsset,
    TicketOptionType,
    PreparedFiltersType,
    TicketGridResult,
    CategoryOrder,
    TicketTypeAndCategoryOption,
    TypeIntegrationDto,
} from './apiTypes/ticketingApiTypes';
import http from './infrastructure/httpService';
import { getBaseUrlApim, getPortalId } from './utils';

const urlTicket = process.env.REACT_APP_APIM_TICKET_URL;

export const getUserIsCaseWorker = (): AxiosPromise<UserIsCaseWorker> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/user`;
    return http.get<UserIsCaseWorker>(url);
};

export const getUserIsCompanyAdmin = (): AxiosPromise<UserIsCompanyAdmin> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/user/isCompanyAdmin`;
    return http.get<UserIsCompanyAdmin>(url);
};

export const getCaseworkers = (term: string, page: number, pageSize: number): AxiosPromise<CaseworkerModel[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/user/usersearch`;
    return http.get<CaseworkerModel[]>(url, { params: { term, page, pageSize } });
};

export const getTicketTypes = (): AxiosPromise<TicketType[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/types`;
    return http.get<TicketType[]>(url);
};

export const getTicketTypesOptions = (): AxiosPromise<TicketTypeAndCategoryOption[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/types/user`;
    return http.get<TicketTypeAndCategoryOption[]>(url);
};

export const getUserTicketTypes = (): AxiosPromise<UserTicketType[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/types`;
    return http.get<UserTicketType[]>(url);
};

export const deleteTicketType = (
    typeId: string,
    reassignedTypes: DeleteTypeCollectionItemDto[],
): AxiosPromise<TicketType> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/types/${typeId}`;
    return http.delete(url, {
        data: [...reassignedTypes],
    });
};

export const createTicketType = (data: CreateTicketTypeDto): AxiosPromise<TicketType> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/types`;
    return http.post<CreateTicketTypeDto, AxiosResponse<TicketType>>(url, data, {
        params: { portalId: getPortalId() },
    });
};

export const updateTicketType = (typeId: string, data: UpdateTicketTypeDto): AxiosPromise<TicketType> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/types/${typeId}`;
    return http.patch<UpdateTicketTypeDto, AxiosResponse<TicketType>>(url, data, {
        params: { portalId: getPortalId() },
    });
};

export const updateTicketTypeCaseworker = (
    typeId: string,
    data: UpdateTicketTypeCaseworkerDto,
): AxiosPromise<TicketType> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/types/${typeId}/caseworkers`;
    return http.put<UpdateTicketTypeCaseworkerDto, AxiosResponse<TicketType>>(url, data, {
        params: { portalId: getPortalId() },
    });
};

export const deleteTicketTypeCaseworker = (
    typeId: string,
    caseworkerId: string,
    newAssigneeId: string | null,
): AxiosPromise<TicketType> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/types/${typeId}/caseworkers/${caseworkerId}`;
    return http.delete<TicketType>(url, {
        params: { portalId: getPortalId(), newAssigneeId },
    });
};

export const createTicketTypeCategory = (typeId: string, category: Category): AxiosPromise<Category> => {
    category.defaultCaseWorkerId = category.defaultCaseWorker?.id;
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/type/${typeId}/category`;
    return http.post<Category, AxiosResponse<Category>>(url, category, {
        params: { portalId: getPortalId() },
    });
};

export const updateTicketTypeCategory = (
    typeId: string,
    categoryId: string,
    data: UpdateTicketingTypeCategory,
): AxiosPromise<Category> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/type/${typeId}/category/${categoryId}`;
    return http.patch<UpdateTicketingTypeCategory, AxiosResponse<Category>>(url, data, {
        params: { portalId: getPortalId() },
    });
};

export const deleteTicketTypeCategory = (
    categoryId: string,
    typeId: string,
    reassignedCategoryAndType: DeleteCategoryDto | undefined,
): AxiosPromise<Category> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/type/${typeId}/category/${categoryId}`;
    return http.delete(url, {
        data: { ...reassignedCategoryAndType },
    });
};

export const getTicketComments = (ticketId: string, page: number, pageSize: number): AxiosPromise<TicketComment[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/ticket/${ticketId}/comments`;
    return http.get<TicketComment[]>(url, { params: { page, pageSize } });
};

export const getTicketReadCount = (ticketId: string, seenEntity: TicketSeenEntity): AxiosPromise<TicketReadCount> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/ticket/${ticketId}/readcount`;
    return http.get<TicketReadCount>(url, { params: { seenEntity } });
};

export const setTicketReadCount = (ticketId: string, ticketReadCountDto: TicketReadCountDto): AxiosPromise => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/ticket/${ticketId}/readcount`;
    return http.post<TicketReadCountDto, AxiosResponse>(url, ticketReadCountDto);
};

export const createTicketComment = (
    ticketNumber: string,
    comment: CreateTicketComment,
): AxiosPromise<CreateTicketComment> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/ticket/${ticketNumber}/comment`;
    return http.post<CreateTicketComment, AxiosResponse<CreateTicketComment>>(url, comment);
};

export const getTicketInternalNotes = (
    ticketId: string,
    page: number,
    pageSize: number,
): AxiosPromise<TicketComment[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/ticket/${ticketId}/internalnotes`;
    return http.get<TicketComment[]>(url, { params: { page, pageSize } });
};

export const createTicketInternalNote = (
    ticketNumber: string,
    note: CreateTicketComment,
): AxiosPromise<CreateTicketComment> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/ticket/${ticketNumber}/internalnotes`;
    return http.post<CreateTicketComment, AxiosResponse<CreateTicketComment>>(url, note);
};

export const getTicketActivities = (
    ticketId: string,
    page: number,
    pageSize: number,
): AxiosPromise<TicketActivity[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/ticket/${ticketId}/ActivityLog`;
    return http.get<TicketActivity[]>(url, { params: { page, pageSize } });
};

export const getReporteeTickets = (
    reporteeId: string,
    page: number,
    pageSize: number,
    ticketSortingType: TicketSortingType,
    sort: TicketSortDirection | null,
    view: TicketListView,
    term: string,
    filters: PreparedFiltersType[],
): AxiosPromise<TicketGridResult> => {
    let url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}`;
    if (view === TicketListView.Worklist) {
        url = `${url}/admin/tickets/caseWorker/${reporteeId}`;
    } else if (view === TicketListView.CompanyTickets) {
        url = `${url}/admin/tickets/company`;
    } else {
        url = `${url}/tickets/user/${reporteeId}`;
    }
    return http.post<TicketGridResult>(
        url,
        filters.map((filter) => ({ ...filter, value: JSON.stringify(filter.value) })),
        {
            params: {
                page,
                pageSize,
                sort,
                ticketSortingType,
                term,
            },
        },
    );
};

export const getTicket = (ticketId: string): AxiosPromise<Ticket> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/ticket/${ticketId}`;
    return http.get<Ticket>(url);
};

export const createTicket = (ticket: CreateTicketDto, typeId: string, categoryId: string): AxiosPromise<string> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/type/${typeId}/category/${categoryId}/ticket`;
    return http.post<CreateTicketDto, AxiosResponse<string>>(url, ticket, {});
};

export function uploadImage(
    image: FormData,
    typeId: string,
    categoryId: string,
): Promise<AxiosResponse<UploadedTicketImage>> {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/type/${typeId}/category/${categoryId}/ticket/upload`;
    return http.post<FormData, AxiosResponse<UploadedTicketImage>>(url, image);
}

export const getTicketsCountForCategory = (
    typeId: string,
    categoryId: string,
    isClosed?: boolean,
): AxiosPromise<number> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/type/${typeId}/category/${categoryId}/ticket/count`;
    return http.get<number>(url, {
        params: { isClosed },
    });
};

export const updateTicket = (ticketId: string, data: UpdateTicket): AxiosPromise<Ticket> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/ticket/${ticketId}`;
    return http.patch<UpdateTicket, AxiosResponse<Ticket>>(url, data);
};

export const getTicketStatuses = (): AxiosPromise<TicketStatus[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/statuses`;
    return http.get<TicketStatus[]>(url);
};

export const getTicketPriorities = (): AxiosPromise<TicketPriority[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/priorities`;
    return http.get<TicketPriority[]>(url);
};

export const getTicketAssignees = (): AxiosPromise<TicketOptionType[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/assignees`;
    return http.get<TicketOptionType[]>(url);
};

export const getTicketCompanies = (): AxiosPromise<TicketOptionType[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/companies`;
    return http.get<TicketOptionType[]>(url);
};

export const getUserTicketCount = (
    reporteeId?: string,
    status?: string,
    getCompanyCount = false,
): AxiosPromise<TicketUserCount> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/ticketCount`;
    return http.get<TicketUserCount>(url, {
        params: { registeredById: reporteeId, status, getCompanyCount: getCompanyCount },
    });
};

export const getUserInfo = (userId: string): AxiosPromise<TicketReportee> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/user/${userId}`;
    return http.get<TicketReportee>(url);
};

export const getTicketAssets = (): AxiosPromise<TicketAsset[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/buildingsAndFloors`;
    return http.get<TicketAsset[]>(url);
};

export const changeTypeCategoriesOrder = (typeId: string, data: CategoryOrder[]): AxiosPromise<string> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/type/${typeId}/category/multiple-update`;
    return http.patch<CategoryOrder[], AxiosResponse<string>>(url, data);
};

export const getTypeInegrations = (): AxiosPromise<TypeIntegrationDto[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/integrations`;
    return http.get<TypeIntegrationDto[]>(url);
};

export const getListOfRestrictedSubmitters = (): AxiosPromise<string[]> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/roles`;
    return http.get<string[]>(url);
};

export const updateListOfRestrictedSubmitters = (roles: string[]): AxiosPromise<string> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/roles`;
    return http.post<string[], AxiosResponse<string>>(url, { restrictedRoleNames: roles });
};

export const getUserHasTicketingAccess = (): AxiosPromise<boolean> => {
    const url = `${getBaseUrlApim()}/${urlTicket}/portal/${getPortalId()}/admin/roles/hasAccess`;
    return http.get<boolean>(url);
};
