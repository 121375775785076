import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Perm } from '../../common';
import { SidebarMenuItem } from '../../components';
import { isHiddenFromProduction, useUserHasPerm } from '../../utility';
import useShopNavigation from './useShopAtWorkNavigation';

const ShopAtWorkDynamicMenuItem = (): ReactElement => {
    const {
        companyTransactionsLink,
        myPurchasesLink,
        portalTransactionsLink,
        settingsLink,
        inventoryLink,
        goToShopAtWork,
        goToCompanyTransactions,
        goToMyPurchases,
        goToPortalTransactions,
        goToSettings,
        goToInventory,
    } = useShopNavigation();
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'commonShopAtWork' });
    const { t: tCommon } = useTranslation('common');
    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    const userIsCompanyAdmin = useUserHasPerm(Perm.CompanyAdmin);
    const userCanUpdateMenu = useUserHasPerm(Perm.todaysMenuUpdate);
    const userCanUpdateMenuImage = useUserHasPerm(Perm.todaysMenuImageUpdate);
    const userHasKitchenPerm = userCanUpdateMenu || userCanUpdateMenuImage;
    const { pathname } = useLocation();

    const isShopPathName =
        pathname?.includes(companyTransactionsLink) ||
        pathname?.includes(myPurchasesLink) ||
        pathname?.includes(portalTransactionsLink) ||
        pathname?.includes(settingsLink) ||
        pathname?.includes(inventoryLink);

    // TODO: Temporary solution for test feature
    const isFeatureVisible = isHiddenFromProduction();

    return (
        <SidebarMenuItem icon="ShopIcon" isActive={isShopPathName} onClick={goToShopAtWork} text={tCommon('eCommerce')}>
            {isFeatureVisible && userIsPortalAdmin ? (
                <SidebarMenuItem
                    isActive={pathname?.includes(inventoryLink)}
                    onClick={goToInventory}
                    text={t('ShopOverviewHeader.Inventory')}
                />
            ) : null}

            <SidebarMenuItem
                isActive={pathname?.includes(myPurchasesLink)}
                onClick={goToMyPurchases}
                text={t('ShopOverviewHeader.MyPurchases')}
            />
            {userIsPortalAdmin || userIsCompanyAdmin ? (
                <SidebarMenuItem
                    isActive={pathname?.includes(companyTransactionsLink)}
                    onClick={goToCompanyTransactions}
                    text={t('ShopOverviewHeader.CompanyPurchases')}
                />
            ) : null}
            {userIsPortalAdmin || userHasKitchenPerm ? (
                <SidebarMenuItem
                    isActive={pathname?.includes(portalTransactionsLink)}
                    onClick={goToPortalTransactions}
                    text={t('ShopOverviewHeader.PortalPurchases')}
                />
            ) : null}
            {userIsPortalAdmin || userIsCompanyAdmin ? (
                <SidebarMenuItem
                    isActive={pathname?.includes(settingsLink)}
                    onClick={goToSettings}
                    text={t('ShopOverviewHeader.Settings')}
                />
            ) : null}
        </SidebarMenuItem>
    );
};

export default ShopAtWorkDynamicMenuItem;
