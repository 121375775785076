import { KeyboardEvent, ReactElement } from 'react';
import styled from 'styled-components';
import { TicketReportee } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { SvgIcon } from '../../../../../../components';
import { UserDetails } from '../../../UserDetails/index';

const Container = styled.div`
    display: flex;
    width: 100%;
    border: 1px solid #d4d6d7;
    border-radius: 5px;
    padding: 0.429rem 1.143rem;
    align-items: center;
    cursor: pointer;
    margin: 1.5rem 0 1rem;
    justify-content: space-between;

    :hover {
        background: var(--background-color);
    }

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

const Icon = styled(SvgIcon)``;

interface UserCardProps {
    user: TicketReportee;
    companyName?: string;
    onClick?(userId: string): void;
}

const UserCard = ({ user, companyName, onClick }: UserCardProps): ReactElement => {
    const onKeyPressHandler = (e: KeyboardEvent<HTMLDivElement>, userId: string) => {
        if (e.key === 'Enter') onClick && onClick(userId);
    };

    return (
        <Container
            tabIndex={0}
            onClick={() => onClick && onClick(user.id)}
            onKeyPress={(e) => onKeyPressHandler(e, user.id)}
        >
            <UserDetails user={user} companyName={companyName} />
            <Icon name="CaretRight" />
        </Container>
    );
};

export default UserCard;
