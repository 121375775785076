import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Icon, Modal, Button, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { useNavigation } from '../../../../../../utility';
import { ApplicationPaths } from '../../../../../Authorization';
import { QueryParameterNames } from '../../../../../Authorization/ApiAuthorizationConstants';
import { deleteSelfPost } from '../../../../api';

const DeleteUserButton = styled(Button)`
    &&& {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        color: #000;
        width: 100%;
        height: 56px;
        border: 1px solid rgba(38, 50, 56, 0.12);
        box-sizing: border-box;
    }
`;
const ModalContainer = styled.div`
    padding: 0;
`;
const ModalContainerHeader = styled.div`
    padding: calc(var(--spacer) * 2.5);
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
`;
const HeaderText = styled.h3`
    margin-top: 10rem;
    margin-left: 0.7rem;
`;
const ConfirmationParagraph = styled.p`
    font-size: 0.9rem;
    padding: 2rem;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
`;
const ButtonSelectionDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: calc(var(--spacer) * 1);
    border-top: 1px solid rgba(34, 36, 38, 0.15);
`;
const AbortButton = styled(Button)`
    &&& {
        background: none;
        color: var(--text-high-emphasis-color);
        font-size: 1rem;
        font-weight: bold;
    }
    &&&:focus {
        color: var(--text-high-emphasis-color);
    }
`;
const ConfirmDeletionButton = styled(Button)`
    &&& {
        background-color: var(--error-color);
        color: var(--error-on-color);
    }

    &&&:hover,
    &&&:focus {
        background-color: var(--error-color);
        color: var(--error-on-color);
    }
`;
const SlettButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
`;
const IconDiv = styled.div`
    margin-right: 0.7rem;
`;

const DeleteSelfComponent = () => {
    const { push } = useNavigation();
    const { portalName } = useParams();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [{ isDeletingUser, errorDeletingUser }, setDeleteState] = useState({
        isDeletingUser: false,
        errorDeletingUser: '',
    });
    const { t } = useTranslation('myProfile');
    const { t: tCommon } = useTranslation('common');
    const logOut = () => {
        push(`${ApplicationPaths.LogOut}?${QueryParameterNames.ReturnUrl}=/${portalName}`);
    };

    const handleDeleteUser = async () => {
        try {
            setDeleteState({ isDeletingUser: true, errorDeletingUser: '' });
            const result = await deleteSelfPost();
            if (result.isError) throw result.errorMessage;
            logOut();
            setDeleteState({ isDeletingUser: false, errorDeletingUser: '' });
        } catch (ex) {
            setDeleteState({ isDeletingUser: false, errorDeletingUser: ex });
        }
    };

    const handleCloseModalClick = () => setIsConfirmationModalOpen(false);

    return (
        <Modal
            open={isConfirmationModalOpen}
            trigger={
                <DeleteUserButton
                    onClick={() => setIsConfirmationModalOpen(true)}
                    type="button"
                    loading={isDeletingUser}
                >
                    <Icon name="warning sign" />
                    {t('deleteMyAccount')}
                </DeleteUserButton>
            }
            onClose={handleCloseModalClick}
            size="small"
        >
            <ModalContainer>
                <ModalContainerHeader>
                    <HeaderText>{tCommon('areYouSure')}</HeaderText>
                </ModalContainerHeader>
                <ConfirmationParagraph>{t('deleteAccountDetails')}</ConfirmationParagraph>
                <ButtonSelectionDiv>
                    <AbortButton onClick={handleCloseModalClick}>{tCommon('cancelButton')}</AbortButton>
                    <ConfirmDeletionButton icon loading={isDeletingUser} onClick={handleDeleteUser}>
                        <SlettButtonDiv>
                            <IconDiv>
                                <Icon name="warning sign" />
                            </IconDiv>
                            {t('deleteAccount')}
                        </SlettButtonDiv>
                    </ConfirmDeletionButton>
                </ButtonSelectionDiv>
                {errorDeletingUser && <Message error header={errorDeletingUser} />}
            </ModalContainer>
        </Modal>
    );
};

DeleteSelfComponent.propTypes = {};

export default DeleteSelfComponent;
