import { ReactElement, ReactPortal } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const PopperBackDrop = styled.div`
    width: 100%;
    height: 100%;
    z-index: 10000;
    position: absolute;
    background: rgba(71, 81, 86, 0.8);
`;

const Wrapper = styled.div`
    position: relative;
    height: 100%;
`;

const CenteredContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-height: 90%;
`;

interface PortalContainerProps {
    parentElementId: string | undefined | null;
    isMobile: boolean;
    children: ReactElement;
}

const PortalContainer = ({ parentElementId, isMobile, children }: PortalContainerProps): ReactPortal | ReactElement => {
    const element = document.querySelector(`#${parentElementId}`);

    if (element && isMobile && parentElementId) {
        return createPortal(
            <PopperBackDrop>
                <Wrapper>
                    <CenteredContainer>{children}</CenteredContainer>
                </Wrapper>
            </PopperBackDrop>,
            element,
        );
    } else return children;
};

export default PortalContainer;
