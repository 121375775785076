import { ModuleName } from '../../api/apiTypes/portalApiTypes';
import useModuleIsActive from './useModuleIsActive';

const useHasConferenceMealAccess = (): boolean => {
    const hasEmailFoodModule = useModuleIsActive(ModuleName.EmailFoodModule);
    const hasIntegrationModule = useModuleIsActive(ModuleName.IntegrationModule);
    const hasFoodForMeetingsModule = useModuleIsActive(ModuleName.FoodForMeetings);

    return hasFoodForMeetingsModule && (hasEmailFoodModule || hasIntegrationModule);
};

export default useHasConferenceMealAccess;
