import { ReactElement, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { TicketType } from '../../../../../../../api/apiTypes/ticketingApiTypes';
import { InputField, PrimaryButton, SvgIcon } from '../../../../../../../components';
import { useUpdateTicketType } from '../../../../../apiQueries/useTicketingType';
import { Paragraph, Header } from '../../../../../common/styles';
import useAutoSave from '../../../hooks/useAutoSave';

const Container = styled.div`
    padding: 1rem 0;
`;

const SaveButton = styled(PrimaryButton)`
    &&& {
        margin: 0.667rem 0;
    }
`;

interface CaseDescriptionProps {
    header: string;
    description: string;
    inputValue: string;
    name: 'externalDescription' | 'internalDescription';
    label: string;
    isSaved(isSaved: boolean): void;
    ticketType: TicketType;
    isExternalDescription: boolean;
}

const CaseDescription = ({
    header,
    description,
    inputValue,
    name,
    label,
    isSaved,
    ticketType,
    isExternalDescription,
}: CaseDescriptionProps): ReactElement => {
    const [updateType, { isLoading, isSuccess, isError }] = useUpdateTicketType();
    const status = useAutoSave(isLoading, isSuccess, isError);
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const schema = yup.object({
        [name]: yup.string(),
    });

    const {
        handleSubmit,
        register,
        formState: { errors },
        clearErrors,
        watch,
        setValue,
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            [name]: inputValue,
        },
    });

    useEffect(() => {
        setValue(name, inputValue);
    }, [inputValue, setValue, name]);

    const onChangeHandler = () => {
        clearErrors(name);
        isSaved(watch(name) === inputValue || Object.keys(errors).length > 0);
    };

    const updateField = (): TicketType => {
        if (name === 'internalDescription') return { ...ticketType, internalDescription: watch(name) };
        else if (name === 'externalDescription') return { ...ticketType, externalDescription: watch(name) };
        else return { ...ticketType };
    };

    const saveChangesHandler = () => {
        updateType([ticketType.id, updateField()], {
            onSuccess: () => {
                isSaved(true);
            },
        });
    };

    return (
        <Container>
            <Header>{header}</Header>
            <Paragraph>{description}</Paragraph>
            <form onSubmit={handleSubmit(saveChangesHandler)} onChange={onChangeHandler}>
                <InputField
                    type={isExternalDescription ? 'text' : 'textarea'}
                    label={label}
                    placeholder={t('writeDescriptionPlaceholder')}
                    rows={5}
                    {...register(name)}
                    name={name}
                    error={errors?.[name]?.message}
                    statusText={status}
                />
                <SaveButton disabled={watch(name) === inputValue || Object.keys(errors).length > 0}>
                    <SvgIcon name="CheckIcon" /> {tCommon('saveButton')}
                </SaveButton>
            </form>
        </Container>
    );
};

export default CaseDescription;
