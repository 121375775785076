import React, { ReactElement, useContext } from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { ModuleName } from '../../api/apiTypes/portalApiTypes';
import { useModuleIsActive, SidesheetContext } from '../../utility';
import { SidesheetActionNames } from '../../utility/Sidesheet/context/types';
import HelpSidesheetContent from './HelpSidesheetContent';

const HelpIconButton = styled(Button)`
    &&&& {
        background: none;
        position: relative;
        padding: 0.8rem;
    }
`;
const Help: React.FC<React.PropsWithChildren<unknown>> = (): ReactElement | null => {
    const { dispatch } = useContext(SidesheetContext);
    const hasHelpArticleModule = useModuleIsActive(ModuleName.HelpArticles);

    // TODO look into prefetching

    const handleClose = () => {
        dispatch({
            type: SidesheetActionNames.CloseSidesheet,
        });
    };

    const handleOpenHelp = () => {
        dispatch({
            type: SidesheetActionNames.OpenSidesheet,
            payload: {
                component: <HelpSidesheetContent onClose={handleClose} />,
                hideCloseIcon: true,
            },
        });
    };

    if (!hasHelpArticleModule) return null;
    return (
        <div>
            <HelpIconButton onClick={handleOpenHelp} icon="help" />
        </div>
    );
};

export default Help;
