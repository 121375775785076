import { ReactElement } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled, { css } from 'styled-components';
import { LottieAnimations } from '../index';

const SmallContainerCss = css`
    width: 14rem;
    height: 17rem;
`;

interface ContainerProps {
    background?: boolean;
    size?: AnimatedLoaderSize;
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ background }: ContainerProps) => (background ? '#ffffff' : 'none')};
    border-radius: 0.714rem;
    padding: 1.143rem 0;

    .lf-player-container {
        flex: 1;
        min-height: 0;
    }

    ${({ size }: ContainerProps) => {
        switch (size) {
            case AnimatedLoaderSize.Small:
                return SmallContainerCss;
            default:
                return SmallContainerCss;
        }
    }}
`;

const StyledPlayer = styled(Player)`
    height: 100%;
`;

const Title = styled.h2`
    font-weight: 500;
    font-size: 1.143rem;
    line-height: 1.714rem;
    text-align: center;
    margin: 0;
    padding: 0 1.143rem;
`;

enum AnimatedLoaderType {
    Cooking,
}

enum AnimatedLoaderSize {
    Small,
}

// Prepared this to extend types
const getAnimation = (animationType: AnimatedLoaderType) => {
    switch (animationType) {
        case AnimatedLoaderType.Cooking:
            return LottieAnimations.Cooking;
        default:
            return LottieAnimations.Cooking;
    }
};

interface AnimatedLoaderProps {
    title?: string;
    type?: AnimatedLoaderType;
    background?: boolean;
    size?: AnimatedLoaderSize;
}

const AnimatedLoader = ({
    title,
    type = AnimatedLoaderType.Cooking,
    background = true,
    size = AnimatedLoaderSize.Small,
}: AnimatedLoaderProps): ReactElement => {
    return (
        <Container>
            <ContainerInner size={size} background={background}>
                {title && <Title>{title}</Title>}
                <StyledPlayer loop autoplay keepLastFrame src={getAnimation(type)} />
            </ContainerInner>
        </Container>
    );
};

export default AnimatedLoader;
