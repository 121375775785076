import React, { FC, useCallback, useMemo } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Modal, ModalType } from '../../../../../../../components';
import { Button, FlexBox, StressedText, Typography } from '../../../../../../../components/styleds';
import ProductsInfoCard from '../../../ProductsInfoSection/components/ProductsInfoCard';
import { IS_SCROLL_PADDING_AFTER_ITEMS_AMOUNT } from '../../../ProductsInfoSection/constants';
import { UnableToCancelOrderPopupProps } from './types';

const UnableToCancelOrderPopup: FC<UnableToCancelOrderPopupProps> = ({ isOpened, setIsOpened, orderLines, email }) => {
    const { t } = useTranslation('Food', { keyPrefix: 'foodForMeeting' });
    const isEnglish = i18n.language === 'en';

    const hideModalCallback = useCallback(() => {
        setIsOpened(false);
    }, [setIsOpened]);

    const renderProductsCard = useMemo(() => {
        return orderLines?.map((cardItem) => (
            <ProductsInfoCard
                key={cardItem.id}
                order={cardItem}
                stripErrorMessage={t('foodOrderDetailHeaderUnableToCancelOrderInformationMessage', {
                    dayCount: cardItem.orderingDeadlineInDays,
                })}
            />
        ));
    }, [orderLines]);

    const bottomContent = useMemo(() => {
        return (
            <FlexBox gap={'1.143rem'} justify={'flex-end'}>
                <Button
                    width={'auto'}
                    borderWidth={'0.143rem'}
                    borderColor={'var(--primary-color)'}
                    pd={'0.571rem 1.143rem'}
                    br={'0.357rem'}
                    justify={'center'}
                    align={'center'}
                    background={'#fff'}
                    onClick={hideModalCallback}
                >
                    <Typography fontSize={'1.143rem'} fontWeight={700} color={'var(--primary-color)'}>
                        {t('foodOrderDetailHeaderUnableToCancelOrderCloseBtnText')}
                    </Typography>
                </Button>
            </FlexBox>
        );
    }, [hideModalCallback, t]);

    const refToMail = useCallback(
        (email: string) => () => {
            window.location.href = `mailto:${email}`;
        },
        [],
    );

    if (!orderLines) return null;
    return (
        <Modal
            type={ModalType.Medium}
            open={isOpened}
            title={t('foodOrderDetailHeaderUnableToCancelOrderTitle')}
            onClose={hideModalCallback}
            bottomContent={bottomContent}
        >
            <FlexBox pd={'1.714rem'} gap={'1.714rem'} dir={'column'} justify={'center'} align={'center'}>
                <Typography color={'#263238)'} fontSize={'1.143rem'} lineHeight={'1.714rem'} fontWeight={400}>
                    {t('foodOrderDetailHeaderUnableToCancelOrderDescription', {
                        extraText: orderLines?.length > 1 ? (isEnglish ? 's' : 'r') : '',
                    })}
                </Typography>
                <FlexBox
                    dir={'column'}
                    gap={'0.429rem'}
                    overflow={'auto'}
                    maxH={'21rem'}
                    pdR={
                        Array.isArray(orderLines) && orderLines.length > IS_SCROLL_PADDING_AFTER_ITEMS_AMOUNT
                            ? '1rem'
                            : '0rem'
                    }
                >
                    {renderProductsCard}
                </FlexBox>
                <Typography
                    color={'#263238'}
                    fontWeight={400}
                    fontSize={'1.143rem'}
                    lineHeight={'1.714rem'}
                    textAlign={'left'}
                    width={'100%'}
                >
                    {t('foodOrderDetailHeaderUnableToCancelOrderSubDescriptionPleaseText')}&nbsp;
                    <StressedText
                        color={'#001EFF'}
                        textDecoration={'underline'}
                        cursor={'pointer'}
                        onClick={refToMail(email)}
                    >
                        {t('foodOrderDetailHeaderUnableToCancelOrderSubDescriptionLinkText')}&nbsp;
                    </StressedText>
                    {t('foodOrderDetailHeaderUnableToCancelOrderSubDescriptionPleaseSubText')}
                </Typography>
            </FlexBox>
        </Modal>
    );
};
export default UnableToCancelOrderPopup;
