import { ReactElement } from 'react';
import { useUserTicketingType } from '../apiQueries/useTicketingType';
import { NewTicketProvider } from './NewTicketContext';
import { NewTicketModal } from './NewTicketModal';

const NewTicket = (): ReactElement => {
    const { data: types, isLoading } = useUserTicketingType();

    return (
        <NewTicketProvider ticketData={isLoading ? types : undefined}>
            <NewTicketModal />
        </NewTicketProvider>
    );
};

export default NewTicket;
