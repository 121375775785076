import { SyntheticEvent, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import type { DropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';

const primaryColor = 'var(--primary-color)';
const primaryColorLight = 'var(--primary-color-light)';
const primaryOnColorLight = 'var(--primary-on-color-light)';
const primaryColorDark = 'var(--primary-color-dark)';
const primaryOnColorDark = 'var(--primary-on-color-dark)';

const StyledDropdown = styled(Dropdown)`
    &&&&,
    &&&&:hover {
        color: ${primaryColor};
        background-color: ${primaryOnColorDark};
        border-radius: 0.28571429rem;
        border: 2px solid ${primaryColor};
        padding: 0.6rem 1.1rem;
        font-size: 1.1rem;
        min-width: min-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        white-space: nowrap;

        &[aria-expanded='true'] {
            background-color: ${primaryColorDark};
            border-color: ${primaryColorDark};
        }

        & [role='option'] {
            color: var(--text-high-emphasis-color);
            background: none;
        }
        & [role='option']:hover {
            color: ${primaryOnColorLight};
            background: ${primaryColorLight};
        }
        .icon {
            margin: 0;
            height: auto;
        }

        & .menu {
            max-height: none;
        }
    }

    &&&& > div:first-child,
    &&&& > div:first-child > div:first-child {
        color: inherit;
        font-weight: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &&&&&[aria-expanded='true'] > * {
        color: ${primaryOnColorDark};
        font-weight: inherit;
    }

    &&&:focus,
    :focus-visible {
        outline: 2px solid var(--primary-color);
        outline-offset: 2px;
        outline-style: solid;
    }

    &&&:focus:not(:focus-visible) {
        outline: none;
    }

    &&&&:active {
        background: ${primaryColorDark};
        color: ${primaryOnColorDark};
        outline: none;
    }
`;

export interface DropDownOption {
    key: string;
    value: string | number;
    text: string;
}

interface FilterDropdownProps {
    options: DropDownOption[];
    onChange(value: string): void;
    value?: string | number;
    placeholder?: string;
    className?: string;
}

const FilterDropdown = ({ onChange, options, value, placeholder, className }: FilterDropdownProps): JSX.Element => {
    const [expand, setExpand] = useState(false);

    const onChangeHandler = (e: SyntheticEvent, { value }: DropdownProps) => {
        onChange(value as string);
    };

    return (
        <StyledDropdown
            value={value}
            options={options}
            onChange={onChangeHandler}
            placeholder={placeholder}
            icon={expand ? 'angle up' : 'angle down'}
            selectOnBlur={false}
            selection
            onOpen={() => setExpand(true)}
            onClose={() => setExpand(false)}
            wrapSelection
            className={className}
        />
    );
};

export default FilterDropdown;
