import { Key } from 'react';

export type FilterOption<KeyType, ValueType> = {
    key: KeyType;
    value: ValueType;
    text: string;
};

export const filterOptionsHelper = <KeyType extends Key, ValueType>(
    filters: FilterOption<KeyType, ValueType>[],
    columns: Key[],
): FilterOption<KeyType, ValueType>[] => {
    return filters.filter((option) => columns.includes(option.key));
};
