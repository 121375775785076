import { ReactNode } from 'react';

interface ConditionalWrapperProps {
    condition: boolean;
    wrapper(children: ReactNode): ReactNode;
    children?: ReactNode;
}

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps): any => {
    return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;
