import { Dispatch } from 'react';
import { Profile } from '../../api/apiTypes/portalApiTypes';
import { setPrivacyPolicyUserStatus, confirmCurrentUser, updateProfile } from '../../api/portalApi';

export const approvePrivacyPolicy = async (
    version: string,
    userId: string,
    dispatch: Dispatch<unknown>,
): Promise<any> => {
    try {
        dispatch({ type: 'APPROVE_PRIVACY_POLICY_REQUESTED' });
        await setPrivacyPolicyUserStatus(version, userId, 'Approved');
        dispatch({ type: 'APPROVE_PRIVACY_POLICY_SUCCEEDED' });
    } catch (err) {
        dispatch({ type: 'APPROVE_PRIVACY_POLICY_FAILED', payload: err });
        throw err;
    }
};

//TODO - type user from form, see caller of confirmUser function. Could use Profile type directly, but then the formFields must be adjusted
export const confirmUser = async (
    user: {
        firstname: string;
        lastname: string;
        email: string;
        phonenumber: string;
        language: string;
    },
    dispatch: Dispatch<unknown>,
): Promise<any> => {
    try {
        dispatch({ type: 'CONFIRM_USER_REQUESTED' });

        const dto: Profile = {
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.email,
            phone: user.phonenumber,
            language: user.language,
        };

        await updateProfile(dto);
        await confirmCurrentUser();

        dispatch({ type: 'CONFIRM_USER_SUCCEEDED' });
    } catch (err) {
        dispatch({ type: 'CONFIRM_USER_FAILED', payload: err });
        throw err;
    }
};
