import { userManager } from '../../../Providers';

// TODO this should be moved to httpService when migration to Apim is done
const authService = {
    getAccessToken: async () => {
        let user = null;
        try {
            user = await userManager.getUser();
        } catch (e) {
            console.error('Error fetching user', e);
        }
        if (user && user.expires_in > 0) {
            return user.access_token;
        }
        userManager.removeUser();

        throw new Error('Not able to get access token');
    },
};

export default authService;
