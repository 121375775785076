import { useEffect, useState } from 'react';

const useHover = (element: HTMLElement | null): boolean => {
    const [isHoveringElement, setIsHoveringElement] = useState(false);

    useEffect(() => {
        const mouseOverElement = () => setIsHoveringElement(true);
        const mouseOutElement = () => setIsHoveringElement(false);

        element && element.addEventListener('mouseover', mouseOverElement);
        element && element.addEventListener('mouseout', mouseOutElement);

        return () => {
            window.removeEventListener('mouseover', mouseOverElement);
            window.removeEventListener('mouseout', mouseOutElement);
        };
    }, [element]);

    return isHoveringElement;
};

export default useHover;
