import React, { useContext } from 'react';
import { ShoppingCartContext } from '..';
import { ShoppingCartItem } from '../../components';
import { orderedFoodLine } from '../../types';
import { getItemPrice } from '../../utils';

const SelectedItem: React.FC<React.PropsWithChildren<orderedFoodLine>> = (foodLine: orderedFoodLine) => {
    const { state: shoppingCartState, changeFoodLine } = useContext(ShoppingCartContext);

    const handleQuantityChanged = (quantity: number) => {
        changeFoodLine(foodLine.foodLineId, quantity);
    };

    if (shoppingCartState.status === 'empty') {
        console.error('Unexpected state');
        return null;
    }

    return (
        <ShoppingCartItem
            name={foodLine.name}
            quantity={foodLine.quantity}
            minQuantity={foodLine.minOrderQuantity}
            handleQuantityChanged={handleQuantityChanged}
            priceString={getItemPrice(foodLine, shoppingCartState.deliveryMethod).toFixed(2)}
            priceUnit={foodLine.priceUnit}
            deliveryDateTime={shoppingCartState.deliveryDateTime}
            itemDeadline={foodLine.orderDeadline}
            itemIsUnavailable={foodLine.unavailable}
        />
    );
};

export default SelectedItem;
