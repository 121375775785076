import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { useGetPortalConfig } from '../app/common/apiQueries/useAppConfiguration';
import { ErrorScreen } from '../app/components';
import { useNavigation } from '../app/utility';
import { userManager } from './CurrentUserProvider/CurrentUserProvider';

const PortalConfigProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    return (
        <Switch>
            <Route path="/:portalName">
                <ChosenPortalRoute>{children}</ChosenPortalRoute>
            </Route>
            <Route exact path="/">
                <NoChosenPortalRoute />
            </Route>
        </Switch>
    );
};

const ChosenPortalRoute = ({ children }: PropsWithChildren<unknown>) => {
    const { t } = useTranslation('common');
    const { portalName } = useParams<{ portalName: string }>();
    const {
        isLoading: isLoadingPortalConfig,
        isError: isErrorLoadingPortalConfig,
        error,
    } = useGetPortalConfig(portalName);

    if (isLoadingPortalConfig) {
        return <Loader active> {t('loadingConfigurationForLoader', { portalName })}</Loader>;
    }
    if (isErrorLoadingPortalConfig) {
        return (
            <ErrorScreen
                errorMessage={error?.message.includes('404') ? t('couldNotFindThePage') : t('errorOccurred')}
            />
        );
    }

    return <>{children}</>;
};

const NoChosenPortalRoute: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { replace } = useNavigation();
    const { t } = useTranslation('common');

    // TODO: clarify information about redirect link. Because it always point to production.
    const LANDING_PAGE = 'http://www.lifeatwork.as';
    if (process.env.NODE_ENV !== 'production') return <div>{t('portalNotSelected')}</div>;

    useEffect(() => {
        const redirectUser = async () => {
            const user = await userManager.getUser();

            if (!user) {
                window.location.href = LANDING_PAGE;
                return;
            }

            if (user) {
                replace(`${user.profile.portalName}/dashboard`);
                return;
            }
        };

        redirectUser();
    }, []);
    return null;
};

export default PortalConfigProvider;
