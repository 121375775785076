import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { ExternalLink } from '../../../components';
import { getDateString } from '../../../utility/dateTimeHelper';
import useCurrentUserContext from '../../Contexts/useCurrentUserContext';

const UpdatedAndAcceptedWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
`;

const Updated = styled.div`
    color: --text-medium-emphasis-color;
`;

const Text = styled.div`
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
`;

const DateText = styled.div`
    padding: calc(var(--spacer) * 2) 0;
    font-size: 16px;
    line-height: 24px;
`;

const Accepted = styled.div`
    margin-left: calc(var(--spacer) * 2);
    color: rgba(38, 50, 56, 0.9);
`;

const AbortButton = styled(Button)`
    &&& {
        background-color: transparent;
        border: none;
        color: rgba(38, 50, 56, 0.9);
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
    }
`;

const ApproveButtonText = styled.span`
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: #ffffff;
`;

const ApproveButtonIcon = styled.span`
    color: #ffffff;
    margin-left: calc(var(--spacer) * 2);
    margin-right: 0;
`;

interface PrivacyAgreementModalProps {
    open: boolean;
    onClose: () => void;
    onApprove?: () => void;
    showApproveButton?: boolean;
}

const PrivacyAgreementModal = ({
    open,
    onClose,
    onApprove,
    showApproveButton = true,
}: PrivacyAgreementModalProps): ReactElement | null => {
    const { state } = useCurrentUserContext();
    const { t } = useTranslation('common');
    let approvedDate = null;
    let versionDate = null;

    if (!state.privacyPolicy) return null;

    if (state.privacyPolicy.approvedDate) {
        approvedDate = getDateString(new Date(state.privacyPolicy.approvedDate));
    }

    if (state.privacyPolicy.versionDateTime) {
        versionDate = getDateString(new Date(state.privacyPolicy.versionDateTime));
    }

    return (
        <Modal open={open} onClose={onClose} size="mini">
            <Modal.Header
                style={{
                    padding: 'calc(var(--spacer) * 3)',
                    fontSize: '20px',
                    fontWeight: 900,
                    lineHeight: '17px',
                }}
            >
                {t('privacyStatement')}
            </Modal.Header>
            <Modal.Content
                scrolling
                style={{
                    padding: 'calc(var(--spacer) * 3)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <UpdatedAndAcceptedWrapper>
                    <Updated>
                        <Text>{t('updatedDate')}</Text>
                        <DateText>{versionDate}</DateText>
                    </Updated>
                    {state.privacyPolicy && state.privacyPolicy.approvedDate && (
                        <Accepted>
                            <Text>{t('accepted')}</Text>
                            <DateText>{approvedDate}</DateText>
                        </Accepted>
                    )}
                </UpdatedAndAcceptedWrapper>
                <ExternalLink href={state.privacyPolicy.url ?? ''}>{t('privacyStatement')}</ExternalLink>
            </Modal.Content>
            <Modal.Actions>
                <AbortButton onClick={onClose}>{t('cancelButton')}</AbortButton>
                {showApproveButton && (
                    <Button
                        onClick={onApprove}
                        style={{
                            backgroundColor: 'var(--primary-color)',
                        }}
                    >
                        <ApproveButtonText>{t('approve')}</ApproveButtonText>
                        <ApproveButtonIcon>
                            <Icon name="arrow right" style={{ opacity: 1 }} />
                        </ApproveButtonIcon>
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default PrivacyAgreementModal;
