import { ReactElement, useEffect, useState, MouseEvent, useRef, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Ticket, TicketPriority, TicketStatus, TicketType } from '../../../../../api/apiTypes/ticketingApiTypes';
import { useCurrentUserContext } from '../../../../../common';
import RoutePromptModal from '../../../../../common/RoutePromptModal';
import { MobileViewBreakpoint } from '../../../../../common/ScreenSizeBreakPoints';
import { AvatarComponentProps } from '../../../../../components/general/AvatarComponent';
import { TicketingContext } from '../../../Ticketing';
import useTicketingNavigation, { WorkListTabPath } from '../../../useTicketingNavigation';
import { TicketListView } from '../../UserTickets/UserTickets';
import { ResolveTicketButton, ResolveTicketModal } from '../TicketDetails/components';
import TicketRecipientBadge from '../TicketDetails/components/TicketRecipientBadge';
import TicketDetails from '../TicketDetails/TicketDetails';
import { ActivityTab, CommentsTab, InternalNotesTab } from './index';

const Information = styled.p`
    font-size: 1.143rem;
    line-height: 1.714rem;
`;

type CardProps = { isViewWithoutSidePanel: boolean };
const Card = styled.div`
    border-radius: ${({ isViewWithoutSidePanel }: CardProps) => (isViewWithoutSidePanel ? 'none' : '0.71rem')};
    margin: ${({ isViewWithoutSidePanel }: CardProps) => (isViewWithoutSidePanel ? '0' : '2rem')};
    box-shadow: ${({ isViewWithoutSidePanel }: CardProps) =>
        isViewWithoutSidePanel ? 'none' : '0px 4px 10px rgba(0, 0, 0, 0.15)'};
    overflow-y: auto;
    background: var(--surface-color-light);
    width: 100%;

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

const TabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: 0;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    ::-webkit-scrollbar {
        display: none; /* chrome and safari */
    }
`;

type StyledTabProps = { disabled: boolean };
const StyledTab = styled.button`
    font-weight: ${({ disabled }: StyledTabProps) => (disabled ? 'bold;' : 'normal;')};
    font-size: 1rem;
    color: ${({ disabled }: StyledTabProps) => (disabled ? '#263238;' : '#677074;')};
    white-space: nowrap;

    && {
        border-bottom: ${({ disabled }: StyledTabProps) =>
            disabled
                ? `
             2px solid blue ;
           `
                : 'gray;'};
    }

    padding: 1.143rem;
    border: none;
    margin: 0 0.1rem;
    background-color: var(--surface-color-light);
    cursor: pointer;
    text-transform: capitalize;

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

const TabsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.179rem 1.714rem;

    @media (max-width: ${MobileViewBreakpoint}px) {
        padding: 0 0 2.179rem;
    }
`;

const HorizontalRule = styled.hr`
    border: 1px solid #d4d6d7;
    margin: 0;
`;

type ContentProps = { isMobile: boolean };
const Content = styled.div`
    padding: ${({ isMobile }: ContentProps) => (isMobile ? '0 1.143rem 1.143rem 1.143rem' : '0.571rem 1.714rem')};
`;

const TicketInfoWrapper = styled.div``;

const TicketInfoWrapperTabletView = styled(TicketInfoWrapper)`
    padding: 1rem;
    border: 1px solid #d4d6d7;
    border-top: none;
    border-left: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TicketInfoNumber = styled.p`
    font-weight: 900;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    line-height: 1.357rem;
    color: #111111;
    margin: 0;
`;

const TicketInfoTitle = styled.p`
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 0.85rem;
    line-height: 16px;
    margin: 0;
    overflow-wrap: break-word;
`;

const TicketInfoHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: 1px solid #d4d6d7;
    border-top: none;
    border-left: none;
`;

const TicketInfoBadgeContainer = styled.div`
    margin-left: auto;
    margin-right: 0.571rem;
`;

interface TicketTabs {
    ticket: Ticket;
    containerWidth: number;
    types: TicketType[] | undefined;
    statusOptions: TicketStatus[] | undefined;
    priorityOptions: TicketPriority[] | undefined;
    isViewWithoutSidePanel: boolean;
    isMobileView: boolean;
    caseWorkerCanEditTicket: boolean;
    userIsReportee: boolean;
    view: TicketListView;
}

const TicketTabs = ({
    ticket,
    types,
    statusOptions,
    priorityOptions,
    isViewWithoutSidePanel,
    isMobileView,
    caseWorkerCanEditTicket,
    userIsReportee,
    view,
}: TicketTabs): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    const { t: tCommon } = useTranslation('common');
    const { state: TicketingContextState } = useContext(TicketingContext);
    const cardRef = useRef(null);
    const history = useHistory();
    const currentLocation = useLocation();
    const { getTicketLink, goToTicketLink } = useTicketingNavigation();
    const { typeId } = useParams<{ typeId: string }>();
    const { state: currentUserContext } = useCurrentUserContext();
    const currentUser: AvatarComponentProps = useMemo(() => {
        return {
            firstName: currentUserContext.currentUser?.firstName ?? '',
            lastName: currentUserContext.currentUser?.lastName ?? '',
            image: currentUserContext.currentUser?.avatar,
        };
    }, [currentUserContext]);

    const [changesSavedTicketTabs, setChangesSavedTicketTabs] = useState(true);
    const [changesSavedTicketDetails, setChangesSavedTicketDetails] = useState(true);
    const match = useRouteMatch<{ reporteeId: string; ticketId: string; tab: WorkListTabPath }>(
        `/:portalName${getTicketLink(view, ':ticketId', ':tab' as WorkListTabPath)}`,
    );

    const tabMatch = match?.params.tab ?? 'comments';
    const [toggleState, setToggleState] = useState<WorkListTabPath>(tabMatch);
    const [activeTab, setActiveTab] = useState(document.getElementById(`#${tabMatch}`));
    const [goToTab, setGoToTab] = useState<WorkListTabPath>(tabMatch);
    const setActiveTabOnScreenRotation = toggleState === 'ticketDetails' ? 'comments' : toggleState;

    const [openModalTabletView, setOpenModalTabletView] = useState(false);

    useEffect(() => setToggleState(tabMatch), [tabMatch]);
    useEffect(() => activeTab?.scrollIntoView({ block: 'nearest', behavior: 'smooth' }), [activeTab]);

    const clickTabHandler = (event: MouseEvent<HTMLButtonElement>, tab: WorkListTabPath) => {
        if (!ticket) return;
        setGoToTab(tab);
        setActiveTab(event.currentTarget);
        goToTicketLink(view, ticket.number, tab);

        const switchTabOnSmallScreen = () => changesSavedTicketDetails && changesSavedTicketTabs && setToggleState(tab);
        isViewWithoutSidePanel ? switchTabOnSmallScreen() : null;
    };

    useEffect(() => {
        if (isViewWithoutSidePanel) return;
        goToTicketLink(view, ticket.number, setActiveTabOnScreenRotation);
    }, [isViewWithoutSidePanel, view, ticket.number, setActiveTabOnScreenRotation]);

    const ticketRecipientBadge = !!ticket.externalId && (
        <TicketRecipientBadge>{`#${ticket.externalId} - ${ticket.recipient}`}</TicketRecipientBadge>
    );

    return (
        <>
            <RoutePromptModal
                when={!changesSavedTicketTabs}
                navigate={history.push}
                shouldBlockNavigation={(location) => {
                    if (location.pathname === currentLocation.pathname) return false;
                    else return !changesSavedTicketTabs;
                }}
                modalTitle={tCommon('warning')}
                modalContent={<Information>{t('ticketTabsInformation')}</Information>}
                modalCancelButtonText={tCommon('backToEditing')}
                modalOkButtonText={tCommon('discardChanges')}
                onOkButtonClick={() => {
                    setChangesSavedTicketTabs(true);
                    setToggleState(goToTab);
                }}
            />
            <Card isViewWithoutSidePanel={isViewWithoutSidePanel} key={typeId} ref={cardRef}>
                {isMobileView && (
                    <TicketInfoHeader>
                        <TicketInfoWrapper>
                            <TicketInfoNumber>#{ticket.number}</TicketInfoNumber>
                            <TicketInfoTitle>{ticket.title}</TicketInfoTitle>
                        </TicketInfoWrapper>
                        {ticketRecipientBadge}
                    </TicketInfoHeader>
                )}

                {!isMobileView && isViewWithoutSidePanel && (
                    <>
                        <TicketInfoWrapperTabletView>
                            <div>
                                <TicketInfoNumber>#{ticket.number}</TicketInfoNumber>
                                <TicketInfoTitle>{ticket.title}</TicketInfoTitle>
                            </div>
                            <TicketInfoBadgeContainer>{ticketRecipientBadge}</TicketInfoBadgeContainer>

                            <ResolveTicketButton
                                isMobileView={isMobileView}
                                onClick={() => setOpenModalTabletView(true)}
                                text={t('resolveModalCheckAsSolved')}
                                ticket={ticket}
                            />
                        </TicketInfoWrapperTabletView>

                        {openModalTabletView && (
                            <ResolveTicketModal
                                userIsReportee={userIsReportee}
                                caseWorkerCanEditTicket={caseWorkerCanEditTicket}
                                key="resolveTicketModal"
                                onClose={() => setOpenModalTabletView(false)}
                                open={openModalTabletView}
                                ticket={ticket}
                            />
                        )}
                    </>
                )}

                <TabsWrapper>
                    <TabsContainer>
                        {isViewWithoutSidePanel && (
                            <StyledTab
                                disabled={toggleState === 'ticketDetails'}
                                onClick={(e) => clickTabHandler(e, 'ticketDetails')}
                                id="ticketDetails"
                            >
                                {t('tabTicketDetails')}
                            </StyledTab>
                        )}
                        <StyledTab
                            disabled={
                                toggleState === 'comments' ||
                                (isViewWithoutSidePanel && toggleState === 'internalNotes')
                            }
                            onClick={(e) => clickTabHandler(e, 'comments')}
                            id="comments"
                        >
                            {t('tabComments')}
                        </StyledTab>
                        {!isViewWithoutSidePanel && caseWorkerCanEditTicket && (
                            <StyledTab
                                disabled={toggleState === 'internalNotes'}
                                onClick={(e) => clickTabHandler(e, 'internalNotes')}
                                id="internalNotes"
                            >
                                {t('tabNotes')}
                            </StyledTab>
                        )}
                        <StyledTab
                            disabled={toggleState === 'activity'}
                            onClick={(e) => clickTabHandler(e, 'activity')}
                            id="activity"
                        >
                            {t('tabActivity')}
                        </StyledTab>
                    </TabsContainer>
                    {!isViewWithoutSidePanel && <HorizontalRule />}
                </TabsWrapper>
                <Content isMobile={isViewWithoutSidePanel}>
                    <Switch>
                        <Route path={`/:portalName${getTicketLink(view, ':ticketId', 'comments')}`}>
                            <CommentsTab
                                key="ticketComments"
                                setChangesSaved={setChangesSavedTicketTabs}
                                scrollRef={cardRef}
                                currentUserAvatar={currentUser}
                                ticket={ticket}
                                isMobile={isViewWithoutSidePanel}
                                caseWorkerCanEditTicket={TicketingContextState.caseWorkerCanEditTicket}
                                view={view}
                            />
                        </Route>
                        {caseWorkerCanEditTicket && (
                            <Route path={`/:portalName${getTicketLink(view, ':ticketId', 'internalNotes')}`}>
                                <InternalNotesTab
                                    key="ticketInternalNotes"
                                    setChangesSaved={setChangesSavedTicketTabs}
                                    scrollRef={cardRef}
                                    currentUserAvatar={currentUser}
                                    ticket={ticket}
                                    isMobile={isViewWithoutSidePanel}
                                    caseWorkerCanEditTicket={TicketingContextState.caseWorkerCanEditTicket}
                                    view={view}
                                />
                            </Route>
                        )}
                        <Route path={`/:portalName${getTicketLink(view, ':ticketId', 'activity')}`}>
                            <ActivityTab ticketId={ticket.id} key="ticketActivity" scrollRef={cardRef} />
                        </Route>
                        {isViewWithoutSidePanel && (
                            <Route path={`/:portalName${getTicketLink(view, ':ticketId', 'ticketDetails')}`}>
                                <TicketDetails
                                    ticket={ticket}
                                    types={types}
                                    statusOptions={statusOptions}
                                    priorityOptions={priorityOptions}
                                    key={`ticketDetails-${ticket.id}`}
                                    isViewWithoutSidePanel={isViewWithoutSidePanel}
                                    isMobileView={isMobileView}
                                    caseWorkerCanEditTicket={caseWorkerCanEditTicket}
                                    userIsReportee={userIsReportee}
                                    setChangesSavedTicketDetails={setChangesSavedTicketDetails}
                                    view={view}
                                />
                            </Route>
                        )}
                    </Switch>
                </Content>
            </Card>
        </>
    );
};
export default TicketTabs;
