import { ReactElement } from 'react';
import styled from 'styled-components';
import { TicketRegisteredBy } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { Avatar } from '../../../../../../components';
import { getDeviceLanguageDateFormat } from '../../../../../../utility/dateUtilities/getDeviceLanguageDateFormat';

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.6rem;
`;

const UserName = styled.span`
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75rem;
`;

const DateSent = styled.span`
    font-size: 0.75rem;
    color: var(--text-medium-emphasis);
`;

interface MessageDetailsProps {
    user: TicketRegisteredBy | undefined;
    sentDate: Date | null;
}
const MessageDetails = ({ user, sentDate }: MessageDetailsProps): ReactElement => {
    const formattedSentDate = sentDate && `${getDeviceLanguageDateFormat(sentDate, 'Pp')}`;

    return (
        <Container key="messageDetails">
            {user && <Avatar {...user} image={user.avatar} />}
            <DetailsContainer>
                <UserName>{[user?.firstName, user?.lastName].join(' ').trim()}</UserName>
                <DateSent>{formattedSentDate}</DateSent>
            </DetailsContainer>
        </Container>
    );
};

export default MessageDetails;
