import { ReactElement } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import { ExternalLink } from '../../../components';

const PrivacyWrapper = styled.div`
    margin-bottom: 1.71rem;
`;

const PrivacyInfo = styled.div`
    padding-top: calc(var(--spacer) * 3);
    font-size: 14px;
    line-height: 22px;
    color: rgba(38, 50, 56, 0.9);
`;

type PrivacyAgreementLinkProps = { fontSize: string };
const PrivacyAgreementLink = styled(ExternalLink)`
    color: var(--primary-color);
    text-decoration: underline;
    font-size: ${(props: PrivacyAgreementLinkProps) => props.fontSize};
    cursor: pointer;

    &:hover {
        color: rgba(38, 50, 56, 0.9);
    }
`;

const Approve = styled.div`
    padding-top: calc(var(--spacer) * 3);
`;

const StyledCheckboxLabel = styled.label`
    &&& {
        font-size: 16px;
        color: var(--primary-color);
        padding-right: 3px;
    }
`;

interface ApprovePrivacyPolicyProps {
    control: Control<any>;
    errors: FieldErrors;
    hasApproved: boolean;
    privacyPolicyUrl: string;
}

const ApprovePrivacyPolicy = ({
    control,
    errors,
    hasApproved,
    privacyPolicyUrl,
}: ApprovePrivacyPolicyProps): ReactElement => {
    const { t } = useTranslation('common');
    return (
        <PrivacyWrapper>
            <PrivacyInfo>
                {t('approvePrivacyPolicyInfo')}
                <PrivacyAgreementLink fontSize="14px" href={privacyPolicyUrl}>
                    {t('privacyStatement')}.
                </PrivacyAgreementLink>
            </PrivacyInfo>
            <Approve>
                <Controller
                    render={({ field: { onChange, value } }) => {
                        return (
                            <>
                                <Checkbox
                                    label={
                                        <StyledCheckboxLabel htmlFor="approved">
                                            {t('readAndApproved')}
                                        </StyledCheckboxLabel>
                                    }
                                    error={errors?.approved?.message.length > 0}
                                    onClick={(_, data) => onChange(data.checked)}
                                    checked={value}
                                />
                                <PrivacyAgreementLink fontSize="1.2rem" href={privacyPolicyUrl}>
                                    {t('privacyStatement')}
                                </PrivacyAgreementLink>
                            </>
                        );
                    }}
                    name="approved"
                    control={control}
                    defaultValue={hasApproved}
                />
            </Approve>
        </PrivacyWrapper>
    );
};

export default ApprovePrivacyPolicy;
