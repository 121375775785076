import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PrimaryButton, TertiaryButton } from '../../../../components/index';
import WifiAtWorkTooltip from '../../WifiAtWorkTooltip';
import WifiPasswordFormModal from './WifiAtWorkSetPasswordFormModal';

const Description = styled.p`
    margin: 0;
    font-size: 1rem;
    line-height: 1.714rem;
    padding: 1.143rem 0;
    text-align: center;
`;

const ButtonsContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

interface WifiAtWorkSetPasswordTooltipProps {
    open: boolean;
    onSubmit(): void;
    onClose(): void;
}

const WifiAtWorkSetPasswordTooltip = ({ open, onClose, onSubmit }: WifiAtWorkSetPasswordTooltipProps): ReactElement => {
    const [openWifiPasswordModal, setOpenWifiPasswordModal] = useState(false);
    const handleSubmitPassword = () => {
        setOpenWifiPasswordModal(false);
        onSubmit();
    };
    const { t } = useTranslation('wifiAtWork');

    return (
        <WifiAtWorkTooltip open={open} onClose={onClose}>
            <>
                <Description>{t('wifiAtWorkSetPasswordTooltip')}</Description>
                <ButtonsContainer>
                    <TertiaryButton onClick={onClose}>{t('notNow')}</TertiaryButton>
                    <PrimaryButton onClick={() => setOpenWifiPasswordModal(true)}>{t('setPassword')}</PrimaryButton>
                </ButtonsContainer>

                {openWifiPasswordModal && (
                    <WifiPasswordFormModal
                        onSubmit={handleSubmitPassword}
                        open
                        onClose={() => setOpenWifiPasswordModal(false)}
                    />
                )}
            </>
        </WifiAtWorkTooltip>
    );
};

export default WifiAtWorkSetPasswordTooltip;
