import { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Ticket, UserTickets } from '../components/index';
import { TicketListView } from '../components/UserTickets/UserTickets';
import useTicketingNavigation from '../useTicketingNavigation';

const MyTickets = (): ReactElement => {
    const { getTicketLink } = useTicketingNavigation();

    return (
        <Switch>
            <Route path={`/:portalName${getTicketLink(TicketListView.MyTickets, ':ticketId', ':tab')}`}>
                <Ticket view={TicketListView.MyTickets} />
            </Route>
            <Route>
                <UserTickets view={TicketListView.MyTickets}></UserTickets>
            </Route>
        </Switch>
    );
};

export default MyTickets;
