import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { sidebarOperations } from '../../../common/Sidebar/duck';
import { useNavigation } from '../../../utility';
import { useIsMobile } from '../../../utility/hooks/useIsMobile';
import { RootLocation } from '../../Dashboard/DailyMenu/EditDailyMenu/EditDailyMenu';

export enum ColumnName {
    StatusCheckbox = 'statusCheckbox',
    Status = 'status',
    OrderNumber = 'orderNumber',
    MenuType = 'menuType',
    DeliveryTime = 'deliveryTime',
    DeliveryLocation = 'deliveryLocation',
    Canteen = 'canteen',
    Customer = 'customer',
    Items = 'items',
    PaymentMethod = 'paymentMethod',
    HasComments = 'hasComments',
    Total = 'total',
}

const useKitchenAdminNavigation = (): {
    ordersLink: string;
    weeklyMenuLink: string;
    customersManagementLink: string;
    settingsLink: string;
    goToOrdersLink(): void;
    goToOrderLink(orderId: string): void;
    getOrderLink(orderId: string): string;
    goToCustomersManagementLink(): void;
    goToSettingsLink(): void;
    goToWeeklyMenuLink(): void;
} => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    return useMemo(() => {
        const ordersLink = '/admin/vendors/orders';
        const customersManagementLink = '/admin/vendors';
        const settingsLink = '/admin/vendors/settings';
        const weeklyMenuLink = '/dashboard/dailyMenu/edit';
        const onNavigate = (url: string, shouldCloseSideBar = true) => {
            if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
            navigation.push(url);
        };

        const getOrderLink = (orderId: string) => `${ordersLink}/${orderId}`;

        return {
            ordersLink,
            weeklyMenuLink,
            customersManagementLink,
            settingsLink,
            goToOrdersLink: () => onNavigate(ordersLink),
            goToCustomersManagementLink: () => onNavigate(customersManagementLink),
            goToSettingsLink: () => onNavigate(settingsLink),
            goToOrderLink: (orderId: string) => onNavigate(getOrderLink(orderId)),
            getOrderLink,
            goToWeeklyMenuLink: () => navigation.replace(weeklyMenuLink, { page: RootLocation.SideBar }),
        };
    }, [navigation]);
};

export default useKitchenAdminNavigation;
