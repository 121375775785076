export type NotificationStatus = 'Sent' | 'Read' | 'Dismissed' | 'Seen';

export enum UpdateNotificationStatusType {
    Sent = 'Sent',
    Read = 'Read',
    Dismissed = 'Dismissed',
    Seen = 'Seen',
}

export type UpdateNotificationStatus = {
    id: string;
    status: NotificationStatus;
};

export enum NotificationCategory {
    Meeting = '40',
    CMS = '70',
    Ticketing = '80',
    WifiAtWork = '90',
    MeetingApprovalRequests = '48',
    HealthAndFitness = '191',
}

export interface NotificationDto {
    id: string;
    from: string | null;
    to: string;
    subject: string;
    text: string;
    moduleId: NotificationCategory;
    created: string;
    updated: string;
    status: NotificationStatus;
    linkedItemId: string | null;
    portalId: string;
}
