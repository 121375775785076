import { ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { CreateTicketDto } from '../../../api/apiTypes/ticketingApiTypes';
import RoutePromptModal from '../../../common/RoutePromptModal';
import {
    GhostPrimaryButton,
    InformationMessage,
    PrimaryButton,
    Modal,
    ModalType,
    SuccessDisplay,
    DimmerLoader,
} from '../../../components';
import { useNavigation } from '../../../utility';
import useDashboardNavigation from '../../Dashboard/useDashboardNavigation';
import { useCreateTicket } from '../apiQueries/useTicketingTicket';
import { TicketTypeQueryCacheKey, useUserTicketingType } from '../apiQueries/useTicketingType';
import useTicketingNavigation, { FromNewTicketPath } from '../useTicketingNavigation';
import { FormStep, CategoryStep, TypeStep } from './components/steps';
import { NewTicketContext } from './NewTicketContext';
import useNewTicketSteps, { NewTicketFormStep } from './UseNewTicketSteps';

const StyledModal = styled(Modal)`
    #modalContentContainer {
        overflow-y: auto;
    }
`;

const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.78rem;
    overflow-y: auto;
`;

const SuccessContainer = styled.div`
    padding-top: calc(0.1rem + 4vh);
    padding-bottom: calc(0.1rem + 4vh);

    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LoaderContainer = styled.div`
    position: relative;
    padding: calc(5vw + 8rem);
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-left: 1.4rem !important;
`;

const ModalNavigation = styled.div``;
const ModalNavigationTitle = styled.div`
    font-weight: bold;
    font-size: 1.142rem;
    line-height: 1.714rem;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    color: var(--text-high-emphasis-color);
`;

export type NewTicketForm = {
    title: string;
    description: string;
    building: string;
    floor: string;
};
const TITLE_MAX_LENGTH = 80;

const schema = (t: TFunction<'Ticketing', 'newTicket'>) => {
    return yup.object({
        building: yup.string().when('$isFVDIntegrationActivated', {
            is: true,
            then: yup.string().required(t('errorMessageBuildingIsRequired')),
        }),
        floor: yup.object().nullable(),
        title: yup
            .string()
            .required(t('errorMessageTitleRequired'))
            .max(TITLE_MAX_LENGTH, t('errorMessageMaxTitleCharacters', { maxTitleCharacters: TITLE_MAX_LENGTH })),
        description: yup.string().required(t('errorMessageDescriptionRequired')),
    });
};

export const NewTicketModal = (): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'newTicket' });
    const { t: tCommon } = useTranslation('common');
    const nav = useNavigation();
    const history = useHistory<FromNewTicketPath>();

    const [close, setClose] = useState(false);

    const { goToUserTickets, userTicketsLink } = useTicketingNavigation();
    const { state: newTicketContext, dispatch } = useContext(NewTicketContext);
    const { goToFrontPage } = useDashboardNavigation();

    const { data: types, isLoading: isTypesLoading, error: typesError } = useUserTicketingType();
    const [createTicket, { isLoading: isRequestLoading, isError: isCreateTicketError }] = useCreateTicket();
    const stepHook = useNewTicketSteps();
    const cache = useQueryCache();
    const shouldNavigate = newTicketContext.currentStep > 1 && newTicketContext.currentStep < 4;
    const fromPathMyTickets = useMemo(() => {
        return history.location.state?.fromPath?.includes(userTicketsLink);
    }, [userTicketsLink, history]);

    const currentType = newTicketContext.ticketData.find((item) => item.id === newTicketContext.ticketType);

    const isFVDIntegrationActivated = !!currentType?.integrationId;

    const methods = useForm<NewTicketForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema(t)),
        context: { isFVDIntegrationActivated },
        defaultValues: {
            building: '',
            floor: '',
            title: '',
            description: '',
        },
    });
    const { handleSubmit, clearErrors } = methods;

    useEffect(() => {
        cache.invalidateQueries(TicketTypeQueryCacheKey);
        dispatch({ type: 'UPDATE_TYPES', payload: types || [] });

        // Handling empty types and types with single category
        if (types?.length === 1 && types[0].categories.length === 1) {
            stepHook.goToStep(3);

            dispatch({ type: 'UPDATE_TICKET_TYPE', payload: types[0].id });
            dispatch({ type: 'UPDATE_TICKET_CATEGORY', payload: types[0].categories[0].id });
        } else if (types?.length === 1) {
            stepHook.goToStep(2);

            dispatch({ type: 'UPDATE_TICKET_TYPE', payload: types[0].id });
        }
    }, [types, dispatch, isTypesLoading, stepHook, cache]);

    const getTypeById = useMemo(() => {
        return types?.find((item) => newTicketContext.ticketType === item.id);
    }, [types, newTicketContext.ticketType]);

    const getCategoryById = useMemo(() => {
        return getTypeById?.categories?.find((item) => newTicketContext.ticketCategory === item.id);
    }, [newTicketContext.ticketCategory, getTypeById]);

    const modalTitle =
        newTicketContext.currentStep < NewTicketFormStep.Result
            ? t('newTicketModalTitleStepLessFour')
            : t('requestHasSent');

    const onClose = useCallback(() => {
        setClose(true);
        nav.goBack(goToFrontPage);
    }, [goToFrontPage, nav]);

    const showCategoryName = newTicketContext.currentStep === NewTicketFormStep.TicketForm && getCategoryById;
    const isLoading = isTypesLoading || isRequestLoading;

    const returnBackFromForm = () => {
        clearErrors();
        currentType && currentType.categories?.length > 1 ? stepHook.goBack() : stepHook.goToStep(1);
    };
    const getCurrentStepContent = () => {
        if (isLoading)
            return (
                <LoaderContainer>
                    <DimmerLoader>{t('newTicketModalLoad')}</DimmerLoader>
                </LoaderContainer>
            );
        if (typesError)
            return <InformationMessage type={'error'}>{t('newTicketModalErrorMessage')}</InformationMessage>;

        switch (newTicketContext.currentStep) {
            case NewTicketFormStep.SelectCategory:
                return <TypeStep />;
            case NewTicketFormStep.SelectSubcategory:
                return <CategoryStep />;
            case NewTicketFormStep.TicketForm:
                return <FormStep submitError={isCreateTicketError} />;
            case NewTicketFormStep.Result:
                return (
                    <SuccessContainer>
                        <SuccessDisplay
                            header={t('requestHasSent')}
                            description={t('successStepDescription')}
                            alt="Create new ticket success"
                        />
                    </SuccessContainer>
                );
        }
    };

    const handleModalSubmit = (data: NewTicketForm) => {
        if (newTicketContext.ticketType && newTicketContext.ticketCategory) {
            const { ticketForm } = newTicketContext;
            const ticket: CreateTicketDto = {
                title: data.title,
                description: data.description,
                attachments: ticketForm.attachments,
                building: ticketForm.building?.id
                    ? { id: ticketForm.building?.id, name: ticketForm.building?.label }
                    : null,
                floor: ticketForm.floor?.id ? { id: ticketForm.floor?.id, name: ticketForm.floor?.label } : null,
            };

            createTicket([ticket, newTicketContext.ticketType, newTicketContext.ticketCategory], {
                onSuccess: () => {
                    stepHook.goNext();
                },
            });
        }
    };

    const getCurrentStepButtons = useCallback((): ReactNode | undefined => {
        if (isRequestLoading || isTypesLoading) return undefined;
        else {
            switch (newTicketContext.currentStep) {
                case NewTicketFormStep.SelectCategory:
                    return undefined;
                case NewTicketFormStep.SelectSubcategory:
                    return (
                        newTicketContext.ticketData.length > 1 && (
                            <ButtonContainer>
                                <GhostPrimaryButton onClick={() => stepHook.goBack()}>
                                    {tCommon('buttonBack')}
                                </GhostPrimaryButton>
                            </ButtonContainer>
                        )
                    );
                case NewTicketFormStep.TicketForm:
                    return (
                        <ButtonContainer>
                            <GhostPrimaryButton onClick={returnBackFromForm}>
                                {tCommon('buttonBack')}
                            </GhostPrimaryButton>
                            <StyledPrimaryButton onClick={handleSubmit(handleModalSubmit)}>
                                {tCommon('buttonSend')}
                            </StyledPrimaryButton>
                        </ButtonContainer>
                    );
                case NewTicketFormStep.Result:
                    return (
                        <ButtonContainer>
                            <GhostPrimaryButton onClick={onClose}>{tCommon('closeButton')}</GhostPrimaryButton>
                            {!fromPathMyTickets && (
                                <StyledPrimaryButton onClick={() => goToUserTickets()}>
                                    {t('myTicketsButton')}
                                </StyledPrimaryButton>
                            )}
                        </ButtonContainer>
                    );
                default:
                    return undefined;
            }
        }
    }, [
        newTicketContext.ticketData,
        newTicketContext.ticketType,
        newTicketContext.currentStep,
        isRequestLoading,
        isTypesLoading,
        newTicketContext.ticketForm.formIsValid,
        stepHook,
        handleModalSubmit,
        goToUserTickets,
        onClose,
        fromPathMyTickets,
    ]);

    return (
        <>
            <StyledModal
                type={ModalType.Large}
                open
                title={modalTitle}
                onClose={onClose}
                bottomContent={getCurrentStepButtons()}
                bordered
            >
                <FormProvider {...methods}>
                    <MainContainer>
                        {shouldNavigate && (
                            <ModalNavigation>
                                <ModalNavigationTitle>
                                    {getTypeById?.name}
                                    {showCategoryName && ` - ${getCategoryById.name}`}
                                </ModalNavigationTitle>
                            </ModalNavigation>
                        )}
                        {getCurrentStepContent()}
                    </MainContainer>
                </FormProvider>
            </StyledModal>
            <RoutePromptModal
                when={close}
                navigate={nav.push}
                shouldBlockNavigation={() => shouldNavigate}
                modalTitle={t('modalWindowTitle')}
                modalContent={<p>{t('newTicketPromptModalContent')}</p>}
                modalCancelButtonText={tCommon('backToEditing')}
                modalOkButtonText={tCommon('discardChanges')}
            />
        </>
    );
};
