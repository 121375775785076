import { Key, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { CSSProperties } from 'styled-components';
import styled from 'styled-components';
import {
    InventorySortDirection,
    InventorySortingType,
    Product,
    ProductTypes,
} from '../../../../../api/apiTypes/shopApiTypes';
import { Badge } from '../../../../../components';
import { Table, OrderEnum } from '../../../../../components/table';
import { getFormattedCurrency } from '../../../../../utility';
import { getProductStatusBadgeType } from '../../../../../utility/getBadgeType';
import { ShopOverviewView } from '../../../ShopAtWork';
import useShopAtWorkNavigation from '../../../useShopAtWorkNavigation';
import {
    getViewColumns,
    InventoryColumns,
    getProductStatusTranslation,
    getProductTypeOptionTranslation,
} from '../utils';
import InventorySkeletonLoader from './InventorySkeletonLoader';

const TableContainer = styled.div``;

const StyledBadge = styled(Badge)`
    height: 1.714rem;
`;

type TableProps = { collapse?: boolean };
const StyledTable = styled(Table)`
    tr {
        /* TODO remove when detailed view is implemented */
        cursor: pointer;
        ${({ collapse }: TableProps) =>
            collapse && {
                display: 'grid',
                gridTemplateAreas: `'${InventoryColumns.Name} ${InventoryColumns.Price}' 
                '${InventoryColumns.Type} ${InventoryColumns.Type}'
                 '${InventoryColumns.Supplier} ${InventoryColumns.Status}'`,
                gridTemplateColumns: 'auto auto',
            }}
    }
    td {
        ${({ collapse }: TableProps) =>
            collapse && {
                padding: '0.4rem',
                marginBottom: 0,
                ':before': {
                    content: 'none',
                },
            }};
    }
`;

const TableCell = styled.p`
    font-size: 1.143rem;
    margin: 0;
`;

const TableHeaderLabel = styled.p``;

const ProductStatus = styled(TableCell)`
    display: flex;
    justify-content: flex-start;
`;

type ProductNameProps = { collapse?: boolean };
const ProductName = styled(TableCell)`
    ${({ collapse }: ProductNameProps) =>
        collapse && {
            fontWeight: 900,
            fontSize: '1.428rem',
        }}
`;

type ProductTypeProps = { collapse?: boolean };
const ProductType = styled(TableCell)`
    ${({ collapse }: ProductTypeProps) =>
        collapse && {
            fontSize: '1.142rem',
        }}
`;

type ProductPriceProps = { collapse?: boolean };
const ProductPrice = styled(TableCell)`
    ${({ collapse }: ProductPriceProps) =>
        collapse && {
            fontSize: '1.428rem',
        }}
`;

const ProductCategory = styled(TableCell)``;
const ProductSupplier = styled(TableCell)``;

export type ColumnType = {
    name: string;
    path: InventorySortingType;
    key: Key;
    label: string | ReactElement;
    width: string;
    content?(product: Product): JSX.Element | null;
    headerOnly?: boolean;
    cellColSpan?: number;
    style?: CSSProperties;
}[];

interface InventoryTableProps {
    products: Product[];
    onSort(sortColumn: { path: InventorySortingType; order: InventorySortDirection }): void;
    sortColumn: { path: InventorySortingType; product: InventorySortDirection };
    includeColumns?: string[];
    view: ShopOverviewView;
    isLoading: boolean;
    collapse?: boolean;
}

const InventoryTable = ({
    products,
    onSort,
    sortColumn,
    view,
    isLoading,
    collapse,
}: InventoryTableProps): ReactElement => {
    const { goToEditProduct } = useShopAtWorkNavigation();
    const mappedProducts = useMemo(() => products.map((product) => ({ ...product, id: product.id })), [products]);
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'commonShopAtWork' });

    const onRowClickHandler = (id: string) => goToEditProduct(id);

    const onSortHandler = (sortColumn: { path: InventorySortingType; order: OrderEnum }) => {
        const modifiedProduct =
            sortColumn.order === OrderEnum.asc ? InventorySortDirection.Asc : InventorySortDirection.Desc;
        onSort({ path: sortColumn.path, order: modifiedProduct });
    };

    const modifiedSortColumn = useMemo(() => {
        const modifiedProduct = sortColumn.product === InventorySortDirection.Asc ? 'asc' : 'desc';
        return { path: sortColumn.path, order: modifiedProduct };
    }, [sortColumn]);

    const allColumns = useMemo((): ColumnType => {
        const allColumns: ColumnType = [
            {
                name: 'title',
                path: InventorySortingType.Name,
                key: InventoryColumns.Name,
                label: <TableHeaderLabel>{t('ProductColumnsTranslation.Name')}</TableHeaderLabel>,
                width: '20%',
                style: collapse
                    ? {
                          gridArea: InventoryColumns.Name,
                          paddingBottom: 0,
                      }
                    : undefined,
                content: function renderContent(product) {
                    return <ProductName collapse={collapse}>{product.title}</ProductName>;
                },
            },
            {
                name: 'status',
                path: InventorySortingType.Status,
                key: InventoryColumns.Status,
                label: <TableHeaderLabel>{t('ProductColumnsTranslation.Status')}</TableHeaderLabel>,
                width: '15%',
                style: collapse
                    ? {
                          gridArea: InventoryColumns.Status,
                          display: 'flex',
                          justifyContent: 'flex-end',
                      }
                    : undefined,
                content: function renderContent(product) {
                    return (
                        <ProductStatus>
                            <StyledBadge type={getProductStatusBadgeType(product.status)}>
                                {getProductStatusTranslation(product.status)}
                            </StyledBadge>
                        </ProductStatus>
                    );
                },
            },
            {
                name: 'type',
                path: InventorySortingType.Type,
                key: InventoryColumns.Type,
                label: <TableHeaderLabel>{t('ProductColumnsTranslation.Type')}</TableHeaderLabel>,
                width: '15%',
                style: collapse
                    ? {
                          gridArea: InventoryColumns.Type,
                          paddingTop: 0,
                      }
                    : undefined,
                content: function renderContent(product) {
                    return (
                        <ProductType collapse={collapse}>
                            {getProductTypeOptionTranslation(product?.category?.type?.name as ProductTypes) ?? '-'}
                            {collapse && product?.category?.name ? '> ' + product.category.name || '' : null}
                        </ProductType>
                    );
                },
            },
            {
                name: 'category',
                path: InventorySortingType.Category,
                key: InventoryColumns.Category,
                label: <TableHeaderLabel>{t('ProductColumnsTranslation.Category')}</TableHeaderLabel>,
                width: '15%',
                style: collapse
                    ? {
                          gridArea: InventoryColumns.Type,
                          paddingTop: 0,
                      }
                    : undefined,
                content: function renderContent(product) {
                    return <ProductCategory>{product?.category?.name || '-'}</ProductCategory>;
                },
                headerOnly: collapse,
            },
            {
                name: 'price',
                path: InventorySortingType.Price,
                key: InventoryColumns.Price,
                label: <TableHeaderLabel>{t('ProductColumnsTranslation.Price')}</TableHeaderLabel>,
                width: '15%',
                style: collapse
                    ? {
                          gridArea: InventoryColumns.Price,
                          paddingTop: 0,
                          display: 'flex',
                          justifyContent: 'flex-end',
                      }
                    : undefined,
                content: function renderContent(product) {
                    return (
                        <ProductPrice collapse={collapse}>{getFormattedCurrency(product.price) || '-'}</ProductPrice>
                    );
                },
            },
            {
                name: 'supplier',
                path: InventorySortingType.Supplier,
                key: InventoryColumns.Supplier,
                label: <TableHeaderLabel>{t('ProductColumnsTranslation.Supplier')}</TableHeaderLabel>,
                width: '15%',
                style: collapse
                    ? {
                          gridArea: InventoryColumns.Supplier,
                          paddingTop: 0,
                      }
                    : undefined,
                content: function renderContent(product) {
                    return <ProductSupplier>{product.canteenName || '-'}</ProductSupplier>;
                },
            },
        ];

        return getViewColumns(view, allColumns);
    }, [view, collapse]);

    return (
        <TableContainer>
            <StyledTable
                isLoading={isLoading}
                columns={allColumns}
                sortColumn={modifiedSortColumn}
                onSort={onSortHandler}
                data={mappedProducts}
                onRowClick={onRowClickHandler}
                collapse={collapse}
                noResultMessage="Ingen treff"
            />
            {isLoading && (
                <InventorySkeletonLoader numberOfItems={20} show={isLoading} collapse={collapse} columns={allColumns} />
            )}
        </TableContainer>
    );
};

export default InventoryTable;
