import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Loader } from '../..';
import { clearQueryCache } from '../../../..';
import { OwnUser, PortalDto } from '../../../api/apiTypes/portalApiTypes';
import { Checkbox, GhostPrimaryButton, InformationMessage, Modal, ModalType, PrimaryButton } from '../../../components';
import { useNavigation } from '../../../utility';
import { useSetDefaultPortal, useSetSelectedPortal } from '../../../utility/hooks/usePortal';
import { usePortalConfig } from '../../apiQueries/useAppConfiguration';
import { ApplicationPaths, QueryParameterNames } from '../../Authorization';
import { shoppingcartState } from '../../LocalStorageKeys/LocalStorageKeys';
import PortalRadioButton from './PortalRadio';

const Paragraph = styled.p`
    font-size: 1.125rem;
    padding: 1.143rem;
    padding-bottom: 0.571rem;
`;
const LoaderContainer = styled.div`
    padding: 8rem;
`;
const DefaultSelection = styled.div`
    padding: 1.7rem;
`;
const DefaultSelectionDescription = styled.p`
    margin-top: 0.57rem;
`;

const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
`;

export enum SelectPortalPageName {
    Profile = 'Profile',
    Authorization = 'Authorization',
}
interface SwitchPortalModalProps {
    onClose: () => void;
    portals: PortalDto[];
    user: OwnUser;
    hideCloseButton?: boolean;
    pageName: SelectPortalPageName;
    headerText: string;
}

const portalConfigKey = 'portalConfig';
const getLocalStorageKey = (portalName: string) => `data-${portalConfigKey}-${portalName}`;

const SwitchPortalModalWindow = ({
    onClose,
    portals,
    user,
    hideCloseButton,
    headerText,
    pageName,
}: SwitchPortalModalProps): ReactElement => {
    const { t } = useTranslation('myProfile');
    const { t: tCommon } = useTranslation('common');
    const { replace, push } = useNavigation();
    const { portalId } = usePortalConfig();

    const [isCheckedDefaultPortal, setIsCheckedDefaultPortal] = useState(false);
    const [selectedPortalId, setSelectedPortalId] = useState<number | null>(null);
    const [setDefaultPortal, { isLoading: isLoadingDefaultPortal, isError: isErrorToChangePortal }] =
        useSetDefaultPortal();
    const [setSelectPortal, { isLoading: isLoadingSelectPortal, isError: isErrorToSelectPortal }] =
        useSetSelectedPortal();

    useEffect(() => {
        setSelectedPortalId(portalId);
    }, [portalId]);

    useEffect(() => {
        const isSelectedPortalIsDefault = portals?.find(
            (portal: PortalDto) => portal.id === selectedPortalId,
        )?.isDefault;
        setIsCheckedDefaultPortal(isSelectedPortalIsDefault ?? false);
    }, [selectedPortalId]);

    const onSuccessAction = (portalName: string) => {
        const clearLocalStorageOnSwitchPortal = () => {
            localStorage.removeItem(shoppingcartState);
            localStorage.removeItem(getLocalStorageKey(user.portalName)); //We are removing the old portal config from local storage
        };
        clearLocalStorageOnSwitchPortal();
        clearQueryCache();
        replace(`/${portalName}/Dashboard`, {}, true);
        window.location.reload();
        onClose();
    };
    const onChangePortal = async () => {
        const selectedPortal = portals.find((portal) => portal.id === selectedPortalId);

        if (selectedPortalId) {
            await setSelectPortal(selectedPortalId, {
                onSuccess: () => {
                    if (!isCheckedDefaultPortal) {
                        onSuccessAction(selectedPortal?.alias ?? '');
                    }
                },
            });
            if (isCheckedDefaultPortal) {
                await setDefaultPortal(selectedPortalId, {
                    onSuccess: () => {
                        onSuccessAction(selectedPortal?.alias ?? '');
                    },
                });
            }
        }
    };
    const isLoading = isLoadingDefaultPortal || isLoadingSelectPortal;
    const isError = isErrorToChangePortal || isErrorToSelectPortal;
    const goToAuthorizationPage = () => {
        push(`${ApplicationPaths.LogOut}?${QueryParameterNames.ReturnUrl}=/${user.portalName}`);
    };

    const bottomContent = () => {
        if (pageName === SelectPortalPageName.Profile) {
            return (
                <ButtonsContainer>
                    <GhostPrimaryButton onClick={onClose}>{tCommon('cancelButton')}</GhostPrimaryButton>
                    <PrimaryButton onClick={onChangePortal}>{t('switchPortal')}</PrimaryButton>
                </ButtonsContainer>
            );
        }
        return (
            <ButtonsContainer>
                <GhostPrimaryButton onClick={goToAuthorizationPage}>{tCommon('previous')}</GhostPrimaryButton>
                <PrimaryButton disabled={!selectedPortalId} onClick={onChangePortal}>
                    {tCommon('next')}
                </PrimaryButton>
            </ButtonsContainer>
        );
    };

    return (
        <Modal
            type={ModalType.Small}
            open
            hideCloseButton={hideCloseButton}
            title={headerText}
            onClose={onClose}
            bottomContent={bottomContent()}
        >
            <div>
                {isLoading && (
                    <LoaderContainer>
                        <Loader size="medium" />
                    </LoaderContainer>
                )}

                {!isLoading && (
                    <>
                        <div>
                            <Paragraph>{t('switchPortalModalDescription')}</Paragraph>
                            {portals?.map(({ name, frontPageImage, address, id, isDefault }) => (
                                <PortalRadioButton
                                    key={id}
                                    value={id}
                                    address={address}
                                    portalImage={frontPageImage}
                                    name={name}
                                    isChecked={selectedPortalId === id}
                                    isDefault={isDefault}
                                    onClick={() => setSelectedPortalId(id)}
                                />
                            ))}
                        </div>

                        <DefaultSelection>
                            <Checkbox
                                id="selectDefaultPortal"
                                label={t('switchPortalModalSetDefaultPortalCheckBoxText')}
                                checked={isCheckedDefaultPortal}
                                onChange={(_, checked) => setIsCheckedDefaultPortal(checked)}
                            />
                            <DefaultSelectionDescription>
                                {t('defaultSelectionDescriptionText')}
                            </DefaultSelectionDescription>
                        </DefaultSelection>
                    </>
                )}

                {isError && <InformationMessage type={'error'}>{t('changePortalErrorMessage')}</InformationMessage>}
            </div>
        </Modal>
    );
};

export default SwitchPortalModalWindow;
