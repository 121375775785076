import { AxiosError, AxiosResponse } from 'axios';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { ContentConfigModel, ImageModel } from '../../api/apiTypes/cmsApiTypes';
import { getPortalContentConfig, updatePortalContentMenuCoverImage } from '../../api/cmsApi';

const contentConfigKey = 'portal-content-config';

export const usePortalContentConfig = (): QueryResult<ContentConfigModel> => {
    return useQuery(
        contentConfigKey,
        async () => {
            const result = await getPortalContentConfig();
            return result.data;
        },
        { staleTime: 1000 * 60 * 60 },
    );
};

export const useUpdateContentConfigFoodCoverImage = (): MutationResultPair<
    AxiosResponse<ContentConfigModel>,
    Error | AxiosError<string>,
    [ImageModel | null],
    never
> => {
    const cache = useQueryCache();
    return useMutation<AxiosResponse<ContentConfigModel>, Error | AxiosError<string>, [ImageModel | null], never>(
        async ([image]) => {
            return await updatePortalContentMenuCoverImage(image);
        },
        {
            onSuccess: (response) => {
                const query = cache.getQuery<ContentConfigModel>(contentConfigKey);
                query?.setData(() => {
                    return response.data;
                });
            },
        },
    );
};

export const useSetMenuImageInCache = (): ((image: ImageModel | null) => void) => {
    const cache = useQueryCache();
    return (image: ImageModel | null) => {
        const query = cache.getQuery<ContentConfigModel>(contentConfigKey);
        query?.setData((data) => {
            return { ...(data as ContentConfigModel), foodMenuCoverImage: image, isDefaultFoodMenuCoverImage: !image };
        });
    };
};
