import { FoodLine, DeliveryMethod, orderedFoodLine } from './types';

type addFoodLineAction = { type: 'ADD_FOOD_LINE'; payload: addedOrderPayload };
type removeFoodLineAction = { type: 'REMOVE_FOOD_LINE'; payload: { foodLineId: number } };
type changeFoodLineAction = { type: 'CHANGE_FOOD_LINE'; payload: editedFoodLine };
type changeDeliveryDateTimeAction = {
    type: 'CHANGE_DELIVERY_DATETIME';
    payload: { deliveryDateTime: Date; meetingId: string | null };
};
type changeDeliveryMethodAction = { type: 'CHANGE_DELIVERY_METHOD'; payload: { deliveryMethod: DeliveryMethod } };
type updateFoodLinesAction = { type: 'UPDATE_FOOD_LINES'; payload: { foodLines: FoodLine[] } };
type removeOrderAction = { type: 'REMOVE_ORDER' };
export type OrderAction =
    | addFoodLineAction
    | removeFoodLineAction
    | changeFoodLineAction
    | changeDeliveryDateTimeAction
    | changeDeliveryMethodAction
    | updateFoodLinesAction
    | removeOrderAction;

type addedOrderPayload = { foodLine: orderedFoodLine };

export type editedFoodLine = {
    foodLineId: number;
    quantity: number;
};

export type OrderState = {
    foodLines: orderedFoodLine[];
    deliveryDateTime: Date;
    deliveryMethod: DeliveryMethod;
    attachedMeeting: string | null;
};

const orderReducer = (state: OrderState, action: OrderAction): OrderState => {
    switch (action.type) {
        case 'ADD_FOOD_LINE':
            return handleAddFood(state, action);
        case 'CHANGE_FOOD_LINE':
            return handleChangeFoodLine(state, action);
        case 'REMOVE_FOOD_LINE':
            return handleRemoveFoodLine(state, action);
        case 'CHANGE_DELIVERY_DATETIME':
            return handleChangeDeliveryDateTime(state, action);
        case 'CHANGE_DELIVERY_METHOD':
            return handleChangeDeliveryMethod(state, action);
        case 'UPDATE_FOOD_LINES':
            return handleUpdateFoodLines(state, action);
        case 'REMOVE_ORDER':
            return handleRemoveOrder(state);
        default:
            return state;
    }
};

const handleAddFood = (state: OrderState, { payload }: addFoodLineAction): OrderState => {
    if (Array.isArray(state.foodLines) && !state.foodLines.length) {
        return { ...state, foodLines: [payload.foodLine] };
    }

    // Add item to existing(state) order
    const existingFoodLine = state.foodLines.find((foodLine) => foodLine.foodLineId == payload.foodLine.foodLineId);
    if (!existingFoodLine) {
        return {
            ...state,
            foodLines: [payload.foodLine, ...state.foodLines],
        };
    }

    const updatedFoodLine = {
        ...existingFoodLine,
        quantity: existingFoodLine.quantity + payload.foodLine.quantity,
    };
    const updatedFoodLines = [
        ...state.foodLines.map((foodLine) => {
            if (foodLine.foodLineId != updatedFoodLine.foodLineId) return foodLine;
            else {
                return updatedFoodLine;
            }
        }),
    ];
    return {
        ...state,
        foodLines: updatedFoodLines,
    };
};

const handleChangeFoodLine = (state: OrderState, { payload }: changeFoodLineAction): OrderState => {
    const otherFoodLines = state.foodLines.filter((foodLine) => foodLine.foodLineId !== payload.foodLineId);
    if (payload.quantity === 0) {
        if (otherFoodLines.length === 0) {
            return { ...state, foodLines: [] };
        }
        return { ...state, foodLines: [...otherFoodLines] };
    }

    const foodLine = state.foodLines.find((foodLine) => foodLine.foodLineId === payload.foodLineId);
    if (!foodLine) {
        console.error('Unexpected state');
        return state;
    }

    return {
        ...state,
        foodLines: [
            ...state.foodLines.map((oldFoodLine) => {
                if (oldFoodLine.foodLineId != foodLine.foodLineId) return oldFoodLine;
                else {
                    return { ...foodLine, ...payload };
                }
            }),
        ],
    };
};

const handleRemoveFoodLine = (state: OrderState, { payload }: removeFoodLineAction): OrderState => {
    const foodLines = state.foodLines.filter((foodLine) => foodLine.foodLineId !== payload.foodLineId);
    if (foodLines.length === 0) {
        return { ...state, foodLines: [] };
    }
    return { ...state, foodLines };
};

const handleChangeDeliveryDateTime = (state: OrderState, { payload }: changeDeliveryDateTimeAction): OrderState => {
    return { ...state, deliveryDateTime: payload.deliveryDateTime, attachedMeeting: payload.meetingId };
};

const handleChangeDeliveryMethod = (state: OrderState, action: changeDeliveryMethodAction): OrderState => {
    return { ...state, deliveryMethod: action.payload.deliveryMethod };
};

const handleUpdateFoodLines = (state: OrderState, action: updateFoodLinesAction): OrderState => {
    if (!state || !state.foodLines) {
        return state;
    }

    return {
        ...state,
        foodLines: state.foodLines.map((foodLine) => {
            const newFoodLine = action.payload.foodLines.find(
                (newFoodLine) => newFoodLine.foodLineId === foodLine.foodLineId,
            );
            if (!newFoodLine) {
                console.error('Could not find foodline in updatedFoodLine');
                return { ...foodLine, unavailable: true };
            }
            return { ...foodLine, ...newFoodLine, unavailable: undefined };
        }),
    };
};

const handleRemoveOrder = (state: OrderState): OrderState => {
    return { ...state, foodLines: [] };
};

export default orderReducer;
