import { useEffect, useMemo } from 'react';
import { isWithinInterval, max } from 'date-fns';
import { MyBooking } from '../../../../api/apiTypes/bookingApiTypes';
import { IBookingsAPI } from '../../../Meeting/types';

const useUpdateDeliveryTimeIfOutsideMeeting = (
    selectedMeeting: IBookingsAPI | undefined | MyBooking,
    deliveryDateTime: Date,
    setDeliveryDateTime: (dateTime: Date, meetingId: string | null) => void,
    minDate: Date,
    departmentBusinessHoursFrom: Date,
): boolean => {
    const newDeliveryTime = useMemo(() => {
        if (
            selectedMeeting &&
            !isWithinInterval(deliveryDateTime, {
                start: selectedMeeting.startTime,
                end: selectedMeeting.endTime,
            })
        ) {
            const openingHours = new Date(departmentBusinessHoursFrom ?? new Date());
            openingHours.setFullYear(
                selectedMeeting.startTime.getFullYear(),
                selectedMeeting.startTime.getMonth(),
                selectedMeeting.startTime.getDate(),
            );
            const newDeliveryTime = max([selectedMeeting.startTime, openingHours, minDate]);
            if (
                isWithinInterval(newDeliveryTime, {
                    start: selectedMeeting.startTime,
                    end: selectedMeeting.endTime,
                })
            ) {
                return newDeliveryTime;
            } else {
                return selectedMeeting.startTime;
            }
        }
        return null;
    }, [deliveryDateTime, departmentBusinessHoursFrom, minDate, selectedMeeting]);
    useEffect(() => {
        const updateDeliveryTimeIfCreatedNew = () => {
            if (newDeliveryTime !== null) {
                setDeliveryDateTime(newDeliveryTime, (selectedMeeting as IBookingsAPI).id);
            }
        };
        updateDeliveryTimeIfCreatedNew();
    }, [newDeliveryTime, selectedMeeting, setDeliveryDateTime]);

    return newDeliveryTime !== null;
};

export default useUpdateDeliveryTimeIfOutsideMeeting;
