import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useNavigation } from '../../utility';
import useCurrentUserContext from '../Contexts/useCurrentUserContext';
import {
    selectedAllergyValues,
    selectedBuildingId,
    shoppingcartState,
    recentlyUsedCategories,
} from '../LocalStorageKeys/LocalStorageKeys';
import { QueryParameterNames } from './ApiAuthorizationConstants';
import { ErrorAuthorization } from './components';
import parsePortalName from './parsePortalName';

interface LogoutProps {
    action: 'logout-callback' | 'logout';
}

const Logout: React.FC<React.PropsWithChildren<LogoutProps>> = ({ action }) => {
    const { userManager } = useCurrentUserContext();
    const [isError, setIsError] = useState(false);
    const queryReturnUrl = new URLSearchParams(window.location.search).get(QueryParameterNames.ReturnUrl);
    const { replace } = useNavigation();
    const returnUrl = !queryReturnUrl ? '/' : queryReturnUrl;
    const { t } = useTranslation('authorization');
    useEffect(() => {
        const clearLocalStorageOnLogout = () => {
            localStorage.removeItem(selectedAllergyValues);
            localStorage.removeItem(shoppingcartState);
            localStorage.removeItem(selectedBuildingId);
            localStorage.removeItem(recentlyUsedCategories);
        };
        clearLocalStorageOnLogout();
    }, []);

    useEffect(() => {
        const handleAction = async () => {
            try {
                if (action === 'logout') {
                    await userManager.signoutRedirect({ state: { returnUrl } });
                } else if (action === 'logout-callback') {
                    const result = await userManager.signoutRedirectCallback();
                    if (result.error) {
                        throw result.error;
                    } else {
                        // Lets direct the user back to the login
                        await userManager.signinRedirect({
                            state: { returnUrl: result.state.returnUrl },
                            extraQueryParams: { portalName: parsePortalName(result.state.returnUrl) },
                        });
                    }
                }
            } catch (e) {
                setIsError(true);
            }
        };
        handleAction();
    }, [action, queryReturnUrl, replace, returnUrl, userManager]);

    if (isError) {
        return <ErrorAuthorization type="Logout" />;
    }

    const renderLoader = (message: string) => {
        return (
            <Dimmer active inverted>
                <Loader inverted>{message}</Loader>
            </Dimmer>
        );
    };

    switch (action) {
        case 'logout':
            return renderLoader(t('preparingLogOut'));
        case 'logout-callback':
            return renderLoader(t('completingLogOut'));
        default:
            throw new Error(t('unknownAction'));
    }
};

export default Logout;
