import { css } from 'styled-components';
import { MEDIA_INDEX } from './constants';
import {
    IBorderStyles,
    IMarginPaddingStyles,
    IOverflowStyles,
    IPositionStyles,
    IFlexStyles,
    ISizeStyles,
} from './types';

export const INCLUDE_ARRAY_VALUE = <T>(obj: T): boolean => {
    if (typeof obj !== 'object' || obj === null) return false;

    return Object.entries(obj).some((value) => Array.isArray(value));
};

export const GET_STYLE_VALUE = <T>(value: T, mediaIndex?: number): T =>
    Array.isArray(value) ? value[mediaIndex ?? MEDIA_INDEX.DESKTOP] ?? value[value.length - 1] : value;

export const SIZE_STYLES = (
    { width = '100%', height = 'auto', minW = 'auto', minH = 'auto', maxW = 'none', maxH = 'none' }: ISizeStyles,
    mediaIndex?: number,
): ReturnType<typeof css> => {
    return css`
        width: ${GET_STYLE_VALUE(width, mediaIndex)};
        height: ${GET_STYLE_VALUE(height, mediaIndex)};
        min-width: ${GET_STYLE_VALUE(minW, mediaIndex)};
        min-height: ${GET_STYLE_VALUE(minH, mediaIndex)};
        max-width: ${GET_STYLE_VALUE(maxW, mediaIndex)};
        max-height: ${GET_STYLE_VALUE(maxH, mediaIndex)};
    `;
};

export const FLEX_STYLES = (
    {
        display = 'flex',
        dir = 'row',
        wrap = 'nowrap',
        justify = 'flex-start',
        align = 'stretch',
        content = 'stretch',
        flex = 'initial',
        grow = 0,
        shrink = 1,
        basis = 'auto',
        gap = '0px',
    }: IFlexStyles,
    mediaIndex?: number,
): ReturnType<typeof css> => {
    return css`
        display: ${GET_STYLE_VALUE(display, mediaIndex)};
        flex-direction: ${GET_STYLE_VALUE(dir, mediaIndex)};
        flex-wrap: ${GET_STYLE_VALUE(wrap, mediaIndex)};
        justify-content: ${GET_STYLE_VALUE(justify, mediaIndex)};
        align-items: ${GET_STYLE_VALUE(align, mediaIndex)};
        align-content: ${GET_STYLE_VALUE(content, mediaIndex)};
        flex: ${GET_STYLE_VALUE(flex, mediaIndex)};
        flex-grow: ${GET_STYLE_VALUE(grow, mediaIndex)};
        flex-shrink: ${GET_STYLE_VALUE(shrink, mediaIndex)};
        flex-basis: ${GET_STYLE_VALUE(basis, mediaIndex)};
        gap: ${GET_STYLE_VALUE(gap, mediaIndex)};
    `;
};

export const MARGIN_PADDING_STYLES = (
    { mr = '0px', mrT, mrR, mrB, mrL, pd = '0px', pdT, pdR, pdB, pdL }: IMarginPaddingStyles,
    mediaIndex?: number,
): ReturnType<typeof css> => {
    return css`
        margin: ${GET_STYLE_VALUE(mr, mediaIndex)};
        ${mrT && `margin-top: ${GET_STYLE_VALUE(mrT, mediaIndex)};`}
        ${mrR && `margin-right: ${GET_STYLE_VALUE(mrR, mediaIndex)};`}
        ${mrB && `margin-bottom: ${GET_STYLE_VALUE(mrB, mediaIndex)};`}
        ${mrL && `margin-left: ${GET_STYLE_VALUE(mrL, mediaIndex)};`}
        padding: ${GET_STYLE_VALUE(pd, mediaIndex)};
        ${pdT && `padding-top: ${GET_STYLE_VALUE(pdT, mediaIndex)};`}
        ${pdR && `padding-right: ${GET_STYLE_VALUE(pdR, mediaIndex)};`}
        ${pdB && `padding-bottom: ${GET_STYLE_VALUE(pdB, mediaIndex)};`}
        ${pdL && `padding-left: ${GET_STYLE_VALUE(pdL, mediaIndex)};`}
    `;
};

export const BORDER_STYLES = (
    {
        borderStyle = 'solid',
        borderWidth = '0px',
        borderColor = '#D4D6D7',
        br,
        brT,
        brR,
        brB,
        brL,
        borderWidthTop,
        borderWidthRight,
        borderWidthBottom,
        borderWidthLeft,
    }: IBorderStyles,
    mediaIndex?: number,
): ReturnType<typeof css> => {
    return css`
        border-style: ${GET_STYLE_VALUE(borderStyle, mediaIndex)};
        border-width: ${GET_STYLE_VALUE(borderWidth, mediaIndex)};
        border-color: ${GET_STYLE_VALUE(borderColor, mediaIndex)};
        border-radius: ${br ? GET_STYLE_VALUE(br, mediaIndex) : 'initial'};
        ${brT &&
        `border-top-left-radius: ${GET_STYLE_VALUE(brT, mediaIndex)}; 
        border-top-right-radius: ${GET_STYLE_VALUE(brT, mediaIndex)};`}
        ${brR &&
        `border-top-right-radius: ${GET_STYLE_VALUE(brR, mediaIndex)};
         border-bottom-right-radius: ${GET_STYLE_VALUE(brR, mediaIndex)};`}
      ${brB &&
        `border-bottom-right-radius: ${GET_STYLE_VALUE(brB, mediaIndex)};
         border-bottom-left-radius: ${GET_STYLE_VALUE(brB, mediaIndex)};`}
      ${brL &&
        `border-bottom-left-radius: ${GET_STYLE_VALUE(brL, mediaIndex)};
         border-top-left-radius: ${GET_STYLE_VALUE(brL, mediaIndex)};`}
      ${borderWidthTop && `border-top-width: ${GET_STYLE_VALUE(borderWidthTop, mediaIndex)};`}
      ${borderWidthRight && `border-right-width: ${GET_STYLE_VALUE(borderWidthRight, mediaIndex)};`}
      ${borderWidthBottom && `border-bottom-width: ${GET_STYLE_VALUE(borderWidthBottom, mediaIndex)};`}
      ${borderWidthLeft && `border-left-width: ${GET_STYLE_VALUE(borderWidthLeft, mediaIndex)};`}
    `;
};

export const OVERFLOW_STYLES = (
    { overflow = 'visible', overflowX, overflowY }: IOverflowStyles,
    mediaIndex?: number,
): ReturnType<typeof css> => {
    return css`
        overflow: ${GET_STYLE_VALUE(overflow, mediaIndex)};
        ${overflowX && `overflow-x: ${GET_STYLE_VALUE(overflowX, mediaIndex)};`}
        ${overflowY && `overflow-y: ${GET_STYLE_VALUE(overflowY, mediaIndex)};`}
    `;
};

export const POSITION_STYLES = (
    { position = 'static', top, right, bottom, left, zIndex = 'auto' }: IPositionStyles,
    mediaIndex?: number,
): ReturnType<typeof css> => {
    return css`
        position: ${GET_STYLE_VALUE(position, mediaIndex)};
        ${top && `top: ${GET_STYLE_VALUE(top, mediaIndex)};`}
        ${right && `right: ${GET_STYLE_VALUE(right, mediaIndex)};`}
        ${bottom && `bottom: ${GET_STYLE_VALUE(bottom, mediaIndex)};`}
        ${left && `left: ${GET_STYLE_VALUE(left, mediaIndex)};`}
        z-index: ${GET_STYLE_VALUE(zIndex, mediaIndex)};
    `;
};
