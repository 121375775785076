import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import WifiAtWorkTooltip from '../../WifiAtWorkTooltip';

const Description = styled.p`
    margin: 0;
    font-size: 1rem;
    line-height: 1.714rem;
    padding: 1.143rem 0;
    text-align: center;
`;

interface WifiAtWorkSetPasswordSuccessTooltipProps {
    open: boolean;
    onClose(): void;
}

const WifiAtWorkSetPasswordSuccessTooltip = ({
    open,
    onClose,
}: WifiAtWorkSetPasswordSuccessTooltipProps): ReactElement => {
    const { t } = useTranslation('wifiAtWork');
    return (
        <WifiAtWorkTooltip open={open} onClose={onClose}>
            <Description>{t('wifiAtWorkSetPasswordSuccessTooltip')}</Description>
        </WifiAtWorkTooltip>
    );
};

export default WifiAtWorkSetPasswordSuccessTooltip;
