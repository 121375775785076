import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GhostPrimaryButton, PrimaryButton } from '../../../components';
import Modal, { ModalType } from '../../../components/general/Modal';

const Container = styled.div`
    padding: 2rem;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

interface PromptModalProps {
    isOpen: boolean;
    onAcceptCallback(): void;
    onClose(): void;
}

const PromptModal = ({ isOpen, onAcceptCallback, onClose }: PromptModalProps): ReactElement => {
    const { t } = useTranslation('Admin', { keyPrefix: 'tenantsAdmin' });
    const getBottomContent = (): ReactElement => {
        return (
            <ButtonsWrapper>
                <GhostPrimaryButton onClick={onClose}>{t('warningModalCancelButton')}</GhostPrimaryButton>
                <PrimaryButton onClick={onAcceptCallback}>{t('warningModalOkButton')}</PrimaryButton>
            </ButtonsWrapper>
        );
    };
    return (
        <Modal
            type={ModalType.Small}
            title={t('warningModalTitle')}
            bottomContent={getBottomContent()}
            open={isOpen}
            onClose={onClose}
            hideCloseButton
        >
            <Container>
                <p>{t('warningModalContent')}</p>
            </Container>
        </Modal>
    );
};
export default PromptModal;
