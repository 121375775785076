import React, { FC, SVGProps } from 'react';

const DownArrowStrokeIconSvg: FC<{ fill?: string }> = (props: SVGProps<SVGSVGElement>) => (
    <svg width={16} height={17} fill="none" {...props}>
        <path
            fill={props.fill || '#475156'}
            d="M7.585 12.37 2.838 7.621a.586.586 0 0 1 0-.829l.554-.554a.586.586 0 0 1 .828 0L8 10l3.78-3.762c.229-.229.6-.228.828 0l.553.554c.23.23.23.6 0 .83l-4.747 4.746a.586.586 0 0 1-.829 0Z"
        />
    </svg>
);
export default DownArrowStrokeIconSvg;
