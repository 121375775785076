import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Category, TicketType } from '../../../../../../api/apiTypes/ticketingApiTypes';
import CaseworkerCard, { CaseWorkerType } from '../../../../../../components/caseWorkerCard/CaseworkerCard';
import { useUpdateTicketingTypeCategory } from '../../../../apiQueries/useTicketingTypeCategory';
import { Header, Paragraph, ChangesSaved } from '../../../../common/styles';
import useAutoSave from '../../hooks/useAutoSave';

const ResponsibleCaseworkerCardWrapper = styled.div`
    padding-top: 0.571rem;
    top: 0;
    width: 100%;
`;

const ResponsibleCaseworkerCard = styled((props) => <CaseworkerCard {...props} />)`
    margin: 0.571rem 0;
`;

interface ResponsibleCaseworkerTabProps {
    category: Category;
    ticketType: TicketType;
}

const ResponsibleCaseworkerTab = ({ ticketType, category }: ResponsibleCaseworkerTabProps): ReactElement => {
    const [updateCategory, { isLoading, isSuccess, isError }] = useUpdateTicketingTypeCategory();
    const status = useAutoSave(isLoading, isSuccess, isError);
    const isOneCaseWorkerAvailable = ticketType.caseWorkers.length === 1;
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const { t: tCommon } = useTranslation('common');

    const onClickSetCaseworkerHandler = (guid: string) => {
        const defaultCaseWorker = category.defaultCaseWorker;
        if (guid !== defaultCaseWorker?.id) {
            if (defaultCaseWorker === null && guid === 'defaultNone') return;
            updateCategory([ticketType.id, category.id, { defaultCaseWorkerId: guid === 'defaultNone' ? null : guid }]);
        }
    };

    return (
        <>
            <Header>{t('responsibleCaseworkerTabHeader')}</Header>
            <Paragraph>{t('responsibleCaseworkerTabParagraph')}</Paragraph>
            <ChangesSaved>{status}</ChangesSaved>
            <ResponsibleCaseworkerCardWrapper>
                <ResponsibleCaseworkerCard
                    id="defaultNone"
                    key="defaultNone"
                    name={tCommon('noName')}
                    email={t('newInquaryWillBeWithoutManager')}
                    hideAvatarImage
                    onClick={onClickSetCaseworkerHandler}
                    type={CaseWorkerType.Select}
                    radioName="newTicketAssignee"
                    selectCaseworker={category.defaultCaseWorker === null}
                    isDisabled={isOneCaseWorkerAvailable}
                />
                {ticketType.caseWorkers.length > 0 &&
                    ticketType.caseWorkers.map(({ id, email, avatar, firstName, lastName }) => {
                        return (
                            <ResponsibleCaseworkerCard
                                id={id}
                                key={id}
                                name={`${firstName} ${lastName}`}
                                email={email}
                                avatar={avatar}
                                onClick={onClickSetCaseworkerHandler}
                                type={CaseWorkerType.Select}
                                radioName="newTicketAssignee"
                                selectCaseworker={
                                    id === category?.defaultCaseWorker?.id || ticketType.caseWorkers.length === 1
                                }
                            />
                        );
                    })}
            </ResponsibleCaseworkerCardWrapper>
        </>
    );
};

export default ResponsibleCaseworkerTab;
