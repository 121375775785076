import { AxiosResponse } from 'axios';
import { SimpleCanteen, Vendor } from './apiTypes/foodForMeetingsApiTypes';
import { CanteenWithAvailabilityParams } from './apiTypes/shopApiTypes';
import http from './infrastructure/httpService';
import { getBaseUrlApim, getPortalId } from './utils';

const APIM_SHOP_URL = process.env.REACT_APP_APIM_SHOP_URL;

export const getAllAvailableCanteens = async ({
    suggestedCanteenIds,
    isDelivery,
    orderDateTime,
    menuType,
}: CanteenWithAvailabilityParams): Promise<AxiosResponse<Vendor[]>> => {
    const portalId = getPortalId();
    const url = `${getBaseUrlApim()}/${APIM_SHOP_URL}/portal/${portalId}/canteens`;
    return http.get<Vendor[]>(url, { params: { suggestedCanteenIds, isDelivery, orderDateTime, menuType } });
};

export const getCanteensByVendorId = (vendorId: string): Promise<AxiosResponse<Vendor[]>> => {
    const url = `${getBaseUrlApim()}/${APIM_SHOP_URL}/portal/${getPortalId()}/canteens/vendor/${vendorId}`;
    return http.get<Vendor[]>(url);
};

export const getDailyMenuCanteens = (): Promise<AxiosResponse<SimpleCanteen[]>> => {
    const url = `${getBaseUrlApim()}/${APIM_SHOP_URL}/portal/${getPortalId()}/canteens/dailyMenu/admin`;
    return http.get<SimpleCanteen[]>(url);
};

export const getSimpleCanteensByDefault = (): Promise<AxiosResponse<SimpleCanteen[]>> => {
    const url = `${getBaseUrlApim()}/${APIM_SHOP_URL}/portal/${getPortalId()}/canteens/dailyMenu`;
    return http.get<SimpleCanteen[]>(url);
};
