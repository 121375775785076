import React, { FC, useMemo } from 'react';
import { FlexBox, Typography } from '../../../../../../components/styleds';
import StressedTextStyled from '../../../../../../components/styleds/stressedText/stressedText.styled';
import { FormSectionCardProps } from './types';

const FormSectionCard: FC<FormSectionCardProps> = ({
    title,
    children,
    isRequiredStar,
    footerContent,
    headerBtn,
    isActiveBorder,
    skeleton,
    isError,
}) => {
    const footerContentRender = useMemo(() => {
        if (!footerContent) return null;
        if (typeof footerContent === 'string') {
            return (
                <FlexBox pd={'1.143rem'} background={'#FBFBFB'} brB={'0.357rem'}>
                    <Typography
                        color={'#677074'}
                        fontSize={'1rem'}
                        fontWeight={400}
                        lineHeight={'1.643rem'}
                        fontStyle={'italic'}
                    >
                        {footerContent}
                    </Typography>
                </FlexBox>
            );
        } else return footerContent;
    }, [footerContent]);
    return (
        <FlexBox
            skeleton={skeleton}
            dir={'column'}
            br={'0.357rem'}
            background={'#fff'}
            boxShadow={'0px 0.286rem 0.714rem 0px rgba(0, 0, 0, 0.15)'}
            pdT={'1.714rem'}
            gap={'1.143rem'}
            height={'100%'}
            borderWidth={'1px'}
            borderColor={isError ? 'var(--error-color)' : isActiveBorder ? '#009773' : '#fff'}
        >
            <FlexBox pdL={'1.714rem'} pdR={'1.714rem'} justify={'space-between'} align={'flex-start'}>
                <Typography
                    fontSize={'1.143rem'}
                    lineHeight={'1.714rem'}
                    letterSpacing={'0.014rem'}
                    fontWeight={900}
                    color={'rgba(38, 50, 56, 0.90)'}
                >
                    {title}&nbsp;
                    {isRequiredStar && (
                        <StressedTextStyled
                            fontSize={'1.143rem'}
                            fontWeight={900}
                            lineHeight={'1.714rem'}
                            letterSpacing={'0.014rem'}
                            color={'#B40000'}
                        >
                            *
                        </StressedTextStyled>
                    )}
                </Typography>
                {headerBtn}
            </FlexBox>
            <FlexBox pdL={'1.714rem'} pdR={'1.714rem'} dir={'column'} gap={'1.143rem'} pdB={'1.714rem'}>
                {children}
            </FlexBox>
            {footerContentRender}
        </FlexBox>
    );
};

export default FormSectionCard;
