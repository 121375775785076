import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Divider, Form, Image, Modal, Button, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { GenericUploader } from '../../../components';
import { uploadAvatar } from '../api';

const allowedFileTypes = ['.gif', '.jpeg', '.jpg', '.svg', '.tif', '.tiff', '.png'];

const ImageWrapper = styled.div`
    display: inline-block;
    position: relative;
    height: 120px;
    width: 120px;
    overflow: hidden;
    border-radius: 50%;

    :hover {
        box-shadow: 0px 0px 20px 0px grey;
    }
`;

const AvatarImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const UpdateAvatarComponent = ({ avatarUrl, onAvatarUpdated }) => {
    const [file, setFile] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [{ isSaving, errorMessage }, setSaveState] = useState({
        isSaving: false,
        errorMessage: null,
    });
    const { t } = useTranslation('myProfile');
    const { t: tCommon } = useTranslation('common');
    const handleTriggerClick = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);

    const handleSubmit = async () => {
        setSaveState({ isSaving: true, errorMessage: null });

        try {
            const result = await uploadAvatar(file);
            if (result.status !== 200) {
                setSaveState({
                    isSaving: false,
                    errorMessage: result.errorMessage,
                });
                return;
            }
            onAvatarUpdated(result.data);
            setSaveState({ isSaving: false, errorMessage: null });
            setIsModalOpen(false);
        } catch (err) {
            setSaveState({ isSaving: false, errorMessage: err.message });
        }
    };

    const handleFileUpload = (files) => setFile(files[0]);

    const fileUploadRef = useRef();
    const handleSelect = () => {
        if (fileUploadRef.current) {
            fileUploadRef.current.click();
        }
    };

    const fileIsTooLarge = file && file.size > 50 * 1024 * 1024;
    const currentAvatarUrl = avatarUrl || 'placeholder.png';
    return (
        <Modal
            trigger={
                <ImageWrapper onClick={handleTriggerClick}>
                    <AvatarImage src={currentAvatarUrl} alt="profile image" />
                </ImageWrapper>
            }
            open={isModalOpen}
            onClose={handleClose}
        >
            <Modal.Header>
                <h1>{t('selectFile')}</h1>
            </Modal.Header>
            <Modal.Content>
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Image
                        size="medium"
                        src={file ? URL.createObjectURL(file) : currentAvatarUrl}
                        circular={true}
                        alt="Your uploaded image"
                        style={{
                            height: '15em',
                            width: '15em',
                            objectFit: 'cover',
                        }}
                    />
                </span>
                <Form onSubmit={handleSubmit} style={{ margin: '2em', marginTop: '0em' }}>
                    <GenericUploader
                        ref={fileUploadRef}
                        onFilesUploaded={handleFileUpload}
                        acceptedFileTypes={allowedFileTypes}
                    />
                    {errorMessage && <Message error header={errorMessage} visible />}
                    {fileIsTooLarge && <Message error header={t('imageTooLarge')} visible />}
                    <Form.Group style={{ margin: '0' }}>
                        <Button type="button" onClick={handleSelect} className="primary-button">
                            {t('select')}
                        </Button>
                        <p
                            style={{
                                marginLeft: '0.7em',
                                paddingTop: '0.6em',
                                fontSize: '1.2em',
                            }}
                        >
                            {file ? file.name : null}
                        </p>
                    </Form.Group>
                    <Divider
                        style={{
                            marginTop: '0',
                        }}
                    />
                    <Form.Group
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '0',
                        }}
                    >
                        <Button onClick={handleClose}>{tCommon('closeButton')}</Button>
                        <Button
                            type="submit"
                            name="submit"
                            className="primary-button"
                            style={{ margin: '0em' }}
                            loading={isSaving}
                            disabled={fileIsTooLarge || !file}
                        >
                            {tCommon('saveButton')}
                        </Button>
                    </Form.Group>
                </Form>
            </Modal.Content>
        </Modal>
    );
};

UpdateAvatarComponent.propTypes = {
    avatarUrl: PropTypes.string,
    onAvatarUpdated: PropTypes.func.isRequired,
};

export default UpdateAvatarComponent;
