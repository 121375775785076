import { ReactElement, RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroller from 'react-infinite-scroller';
import styled from 'styled-components';
import LoaderComponent from '../../../../../../common/Loader/LoaderComponent';
import { InformationMessage } from '../../../../../../components';
import { useGetInfiniteTicketActivities } from '../../../../apiQueries/useTicketActivities';
import { ActivityItem, ActivitySkeletonLoader } from './components/index';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const HorizontalRule = styled.hr`
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 1.429rem 0;
`;

const LoaderWrapper = styled.div`
    height: 5rem;
`;

interface ActivityTabProps {
    ticketId: string;
    scrollRef: RefObject<HTMLElement>;
}

const ActivityTab = ({ ticketId, scrollRef }: ActivityTabProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    const { data, isLoading, isFetchingMore, isError, canFetchMore, fetchMore } =
        useGetInfiniteTicketActivities(ticketId);
    const activities = useMemo(() => (data ?? []).flat(), [data]);

    const handleFetchMore = () => {
        if (!isFetchingMore) fetchMore();
    };

    return (
        <Container>
            {isLoading ? (
                <ActivitySkeletonLoader numberOfItems={20} show={isLoading} />
            ) : isError || !activities ? (
                <InformationMessage type="error">{t('activityTabInformation')}</InformationMessage>
            ) : (
                <InfiniteScroller
                    threshold={200}
                    hasMore={canFetchMore}
                    loadMore={handleFetchMore}
                    loader={
                        <LoaderWrapper key={0}>
                            <LoaderComponent text={t('activityTabLoadMessage')} />
                        </LoaderWrapper>
                    }
                    useWindow={false}
                    getScrollParent={() => scrollRef.current}
                >
                    {activities.map((activity) => (
                        <div key={activity.id}>
                            <ActivityItem activity={activity} />
                            <HorizontalRule />
                        </div>
                    ))}
                </InfiniteScroller>
            )}
        </Container>
    );
};

export default ActivityTab;
