import { forwardRef, SyntheticEvent } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import {
    NotificationCategory,
    NotificationDto,
    NotificationStatus,
    UpdateNotificationStatusType,
} from '../../../../../../api/apiTypes/msgApiTypes';
import { useNotification } from '../../../../context';
import { useNotificationNavigation } from '../../../../hooks';
import NotificationTimeDisplay from './NotificationTimeDisplay';

interface NotificationContainerType {
    status: NotificationStatus;
}

const NotificationContainer = styled.a`
    color: inherit;
    border: none;
    cursor: pointer;
    padding: 1em;
    margin: 0.2rem;
    margin-bottom: var(--spacer);
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${({ status }: NotificationContainerType) =>
        status === UpdateNotificationStatusType.Sent || status === UpdateNotificationStatusType.Seen
            ? 'rgba(41, 98, 255, 0.1);'
            : 'var(--background-color);'};

    &:hover {
        color: inherit;
        background-color: rgba(41, 98, 255, 0.1);
    }

    &:focus-visible {
        outline: 1px solid var(--primary-color);
        border-radius: 0.5rem;
    }
`;

const IconContainer = styled.div`
    padding-right: 0.75rem;
`;

const TextContainer = styled.div`
    text-align: start;
    flex: 1;
`;

const NotificationHeader = styled.h5`
    font-size: 1.1em;
    overflow-wrap: anywhere;
`;

const NotificationTextParagraph = styled.p`
    font-size: 1em;
    font-weight: 200;
    color: var(--background-on-color);
    overflow-wrap: anywhere;
`;

const DeleteNotificationButton = styled(Button)`
    &:focus-visible {
        outline: 1px solid var(--primary-color);
        border-radius: 0.5rem;
    }
`;

const NotificationElement = forwardRef(function ForwardRefedNotificationElement(
    { notification }: { notification: NotificationDto; onPressNotification?: () => void },
    ref: any,
) {
    const { id, status, created, subject, text } = notification;
    const { updateNotificationStatuses } = useNotification();
    const { onNavigationHandler } = useNotificationNavigation();

    const handleDismissClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        updateNotificationStatuses([id], UpdateNotificationStatusType.Dismissed);
    };

    const handleKeyDownDismiss = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Enter') {
            handleDismissClick(e);
        }
    };

    const handleKeyDownNotification = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            onNavigationHandler(notification);
        }
    };

    const getNotificationSubjectIcon = (subject: NotificationCategory): SemanticICONS => {
        switch (subject) {
            case NotificationCategory.Meeting:
                return 'calendar alternate outline';
            case NotificationCategory.CMS:
                return 'newspaper outline';
            case NotificationCategory.Ticketing:
                return 'comment alternate outline';
            case NotificationCategory.WifiAtWork:
                return 'wifi';
            case NotificationCategory.MeetingApprovalRequests:
                return 'calendar alternate outline';
            case NotificationCategory.HealthAndFitness:
                return 'calendar alternate outline';
            default:
                return 'info circle';
        }
    };

    return (
        <NotificationContainer
            tabIndex={0}
            onKeyDown={handleKeyDownNotification}
            status={status}
            ref={ref}
            aria-label={`open notification ${subject}`}
            onClick={(e) => {
                e.stopPropagation();
                onNavigationHandler(notification);
            }}
        >
            <IconContainer>
                <Icon name={getNotificationSubjectIcon(notification.moduleId)} />
            </IconContainer>
            <TextContainer>
                {text && subject ? <NotificationHeader>{subject}</NotificationHeader> : null}
                <NotificationTextParagraph>{!text ? subject : text}</NotificationTextParagraph>
                <NotificationTimeDisplay dateTime={new Date(created)} />
            </TextContainer>
            <DeleteNotificationButton
                icon="trash"
                onKeyDown={handleKeyDownDismiss}
                aria-label="delete notification"
                style={{ marginLeft: 'var(--spacer)', background: 'none' }}
                onClick={handleDismissClick}
            />
        </NotificationContainer>
    );
});

export default NotificationElement;
