import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import Dropdown, { DropDownProps } from './DropDown';

type ControlledDropdownProps = Omit<Omit<Omit<Omit<DropDownProps, 'value'>, 'onChange'>, 'onBlur'>, 'name'> & {
    control?: any;
    name: string;
    defaultValue?: string;
};

const ControlledDropdown = ({
    control,
    name,
    defaultValue,
    ...dropdownProps
}: ControlledDropdownProps): ReactElement => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Dropdown
                    onChange={onChange}
                    onBlur={onBlur}
                    initialValue={value instanceof Date ? value.toISOString() : value}
                    value={value instanceof Date ? value.toISOString() : value}
                    name={name}
                    ref={ref}
                    {...dropdownProps}
                />
            )}
        />
    );
};

export default ControlledDropdown;
