import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { VehicleDto, VehicleFuelType, VehicleType } from '../../../../api/apiTypes/portalApiTypes';
import { SvgIcon } from '../../../../components';
import { VehicleControlActions } from '../Profile';

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1.14rem;
    border: 1px solid #d4d6d7;
    border-radius: 5px;
    color: #263238;
`;

const IconWrapper = styled.div`
    padding: 0.9rem;
    border-radius: 50%;
    border: 1px solid #475156;
    width: 3.14rem;
    height: 3.13rem;
    margin-right: 1.57rem;
`;

const Details = styled.div`
    font-size: 1rem;
    flex: 1;
`;
const MoreInformationContainer = styled.div`
    position: relative;
`;
const MoreInformation = styled.div`
    border-radius: 50%;
    width: 2.85rem;
    height: 2.85rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9ebeb;
    border: none;
`;
const LicenseDetails = styled.div`
    font-size: 1.2rem;
    font-weight: 700;
`;

const ControlPanel = styled.div`
    border: 1px solid #d4d6d7;
    border-radius: 5px;
    position: absolute;
    background: white;
    top: 100%;
    right: 0;
    width: 13.7rem;
    z-index: 1;
`;
const ControlElement = styled.button`
    padding: 0.57rem 1.2rem;
    outline: none;
    border: none;
    background-color: transparent;
    width: 100%;
    text-align: start;
    cursor: pointer;
    &:hover {
        background-color: var(--primary-color-light);
        color: white;
    }
`;

const EditControlElement = styled(ControlElement)`
    border-bottom: 1px solid #d4d6d7;
`;

interface VehicleProps {
    data: VehicleDto;
    manageVehicle: (
        event: React.MouseEvent<HTMLButtonElement>,
        actionType: VehicleControlActions,
        data: VehicleDto,
    ) => void;
}

const VehicleCard = ({ data, manageVehicle }: VehicleProps): ReactElement => {
    const { t } = useTranslation('myProfile');
    const [vehicleControlPanel, setVehicleControlPanel] = useState(false);
    const openVehicleControlPanel = () => {
        setVehicleControlPanel((prev) => !prev);
    };
    const editVehicleItem = (event: React.MouseEvent<HTMLButtonElement>) => {
        manageVehicle(event, VehicleControlActions.Edit, data);
        setVehicleControlPanel(false);
    };
    const deleteVehicleItem = (event: React.MouseEvent<HTMLButtonElement>) => {
        manageVehicle(event, VehicleControlActions.Remove, data);
        setVehicleControlPanel(false);
    };
    return (
        <Container>
            <IconWrapper>
                <SvgIcon name="DirectionCarIcon" />
            </IconWrapper>
            <Details>
                <LicenseDetails>{data.licensePlate}</LicenseDetails>
                <div>{VehicleType[data.type]}</div>
                <div>{VehicleFuelType[data.fuelType]}</div>
            </Details>
            <MoreInformationContainer>
                <MoreInformation onClick={openVehicleControlPanel}>
                    <SvgIcon name="MoreVertIcon" />
                </MoreInformation>
                {vehicleControlPanel && (
                    <ControlPanel>
                        <EditControlElement onClick={editVehicleItem}>{t('editDetailsButton')}</EditControlElement>
                        <ControlElement onClick={deleteVehicleItem}>{t('removeVehicleButton')}</ControlElement>
                    </ControlPanel>
                )}
            </MoreInformationContainer>
        </Container>
    );
};

export default VehicleCard;
