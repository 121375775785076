import { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Perm from '../../../../common/perms';
import { MobileViewBreakpoint } from '../../../../common/ScreenSizeBreakPoints';
import {
    BackButton,
    Badge,
    FullPageLoader,
    GhostPrimaryButton,
    InformationMessage,
    SvgIcon,
} from '../../../../components';
import { useUserHasPerm } from '../../../../utility';
import { getOrderStatusBadgeType } from '../../../../utility/getBadgeType';
import useResizeWindow from '../../../../utility/hooks/useResizeWindow';
import { useGetOrderExcelFile, useGetOrderInvoice } from '../../apiQueries/useShopOrders';
import { ShopOverviewView } from '../../ShopAtWork';
import useShopAtWorkNavigation from '../../useShopAtWorkNavigation';
import { getShopAtWorkStatusTranslation } from '../PurchasesOverview/utils';
import TotalSum from '../TotalSum';
import InvoiceDetails from './components/InvoiceDetails';
import InvoiceTable from './components/InvoiceTable';

const StyledBadge = styled(Badge)`
    height: 1.714rem;
`;

const StyledGhostPrimaryButton = styled(GhostPrimaryButton)`
    svg {
        margin-left: 0.714rem;

        path {
            fill: var(--primary-color);
        }
    }
`;

const StyledBackButton = styled(BackButton)`
    &&& {
        max-width: fit-content;

        @media (max-width: ${MobileViewBreakpoint}px) {
            max-width: 100%;
        }
    }
`;

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const InvoiceContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1.143rem;
`;

const InvoiceWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

type HeaderProps = {
    isMobileView: boolean;
};
const Header = styled.header`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.929rem;
    flex-direction: ${({ isMobileView }: HeaderProps) => (isMobileView ? 'column' : 'row')};
    gap: 1.143rem;
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1.071rem;
    justify-content: ${({ isMobileView }: HeaderProps) => (isMobileView ? 'space-between' : 'flex-start')};
`;

const Title = styled.h2`
    font-weight: 700;
    font-size: 1.714rem;
    line-height: 2.714rem;
    margin-bottom: 1.714rem;
`;

const Subtitle = styled.h3`
    font-weight: 900;
    margin-bottom: 1.714rem;
    font-size: 1.429rem;
    line-height: 2.286rem;
`;

interface InvoiceProps {
    view: ShopOverviewView;
    orderId: string;
}

const Invoice = ({ view, orderId }: InvoiceProps): ReactElement => {
    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    const containerRef = useRef<HTMLDivElement>(null);
    const containerWidth = useResizeWindow(containerRef);
    const isMobileView = containerWidth < MobileViewBreakpoint;
    const { goToOrderLink } = useShopAtWorkNavigation();
    const { data: orderInvoice, isLoading: isLoadingInvoice, isError } = useGetOrderInvoice(orderId);
    const { refetch } = useGetOrderExcelFile(orderId, orderInvoice?.order.orderNumber);
    const { t } = useTranslation('ShopAtWork');

    const handleOnDownloadClick = () => {
        refetch();
    };

    const handleBackClick = () => {
        goToOrderLink(view, orderId);
    };

    return (
        <MainContainer ref={containerRef}>
            <Header isMobileView={isMobileView}>
                <StyledBackButton onClick={handleBackClick} />
                {!!orderInvoice && (
                    <HeaderWrapper isMobileView={isMobileView}>
                        <StyledBadge type={getOrderStatusBadgeType(orderInvoice.order.status)}>
                            {getShopAtWorkStatusTranslation(orderInvoice.order.status)}
                        </StyledBadge>
                        {userIsPortalAdmin && (
                            <StyledGhostPrimaryButton onClick={handleOnDownloadClick}>
                                {t('invoice.downloadButton')} <SvgIcon name="DownloadIcon" />
                            </StyledGhostPrimaryButton>
                        )}
                    </HeaderWrapper>
                )}
            </Header>
            {isLoadingInvoice && <FullPageLoader text={t('invoice.loadingInvoice')} />}
            {isError && <InformationMessage type="error">{t('invoice.downloadErrorText')}</InformationMessage>}
            {!!orderInvoice && (
                <InvoiceContainer>
                    <InvoiceWrapper>
                        <Title>{t('invoice.title')}</Title>
                        <InvoiceDetails orderInvoice={orderInvoice} />
                    </InvoiceWrapper>

                    <Subtitle>{t('invoice.subtitle')}</Subtitle>
                    <InvoiceTable orderLines={orderInvoice.order.orderLines} isMobileView={isMobileView} />

                    <TotalSum order={orderInvoice.order} isMobileView={isMobileView} />
                </InvoiceContainer>
            )}
        </MainContainer>
    );
};

export default Invoice;
