import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SvgIcon } from '../../../../../../components';

const Button = styled.button`
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    padding: 0;
    margin: 0;
    background-color: transparent;
    width: fit-content;
    align-items: center;
    border: none;

    :focus {
        outline-color: var(--primary-color);
        outline-offset: 3px;
    }
`;

const ButtonText = styled.p`
    font-size: 1.143rem;
    font-weight: bold;
    color: #677074;
    margin: 0;
`;

const Icon = styled.div`
    line-height: 0;
`;

interface ReplyButtonProps {
    onClick(): void;
}

const ReplyButton = ({ onClick }: ReplyButtonProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    return (
        <Button type="button" onClick={onClick}>
            <ButtonText>{t('replyButton')}</ButtonText>
            <Icon>
                <SvgIcon name="ReplyIcon" />
            </Icon>
        </Button>
    );
};

export default ReplyButton;
