import styled from 'styled-components';

const InlineButton = styled.span`
    padding: 0 0.29rem;
    text-decoration: underline;
    color: var(--text-link-color);
    cursor: pointer;
`;

export default InlineButton;
