import { Key } from 'react';
import { t } from 'i18next';
import { TicketPriority, TicketSortingType, TicketStatus } from '../../api/apiTypes/ticketingApiTypes';
import { DropDownOption } from '../../components/select/FilterDropdown';
import { filterOptionsHelper } from '../../helpers/filterOptionsHelper';
import { ColumnType } from './components/UserTickets/components/UserTicketTable';
import { TicketListView, TicketFilterProps } from './components/UserTickets/UserTickets';
import { TicketingFilters, TicketingState } from './Ticketing';

export enum Columns {
    Notification = 'varsler',
    Priority = 'prioritet',
    Subject = 'emne',
    Type = 'type',
    Category = 'kategori',
    Status = 'status',
    LastUpdated = 'sistoppdatert',
    Caseworker = 'saksbehandler',
    Company = 'selskap',
    Location = 'plassering',
    Created = 'opprettet',
    Requester = 'rekvirenten',
    CaseNumber = 'hendvendelsesnummer',
}

export enum FilterKeys {
    Assignees = 'assignees',
    Companies = 'companies',
    Categories = 'categories',
    Statuses = 'statuses',
    Priorities = 'priorities',
}

export const worklistAndReporteeMobileColumns: Key[] = [
    Columns.Notification,
    Columns.Priority,
    Columns.Subject,
    Columns.Type,
    Columns.Category,
    Columns.Status,
    Columns.LastUpdated,
    Columns.Company,
    Columns.CaseNumber,
    Columns.Location,
];

export const companyTicketListMobileColumns: Key[] = [
    Columns.Notification,
    Columns.Subject,
    Columns.Type,
    Columns.Category,
    Columns.Status,
    Columns.LastUpdated,
    Columns.Requester,
    Columns.CaseNumber,
    Columns.Created,
];

export const myTicketListMobileColumns: Key[] = [
    Columns.Notification,
    Columns.Priority,
    Columns.Subject,
    Columns.Type,
    Columns.Category,
    Columns.Status,
    Columns.LastUpdated,
    Columns.Company,
    Columns.CaseNumber,
    Columns.Created,
];

export const myTicketsColumns: Key[] = [
    Columns.Notification,
    Columns.Subject,
    Columns.Type,
    Columns.Category,
    Columns.Status,
    Columns.LastUpdated,
    Columns.Company,
    Columns.Created,
];
export const worklistAndReporteeColumns: Key[] = [
    Columns.Notification,
    Columns.Priority,
    Columns.Subject,
    Columns.Type,
    Columns.Category,
    Columns.Status,
    Columns.LastUpdated,
    Columns.Caseworker,
    Columns.Company,
    Columns.Location,
];

export const companyTicketsColumns: Key[] = [
    Columns.Notification,
    Columns.Subject,
    Columns.Type,
    Columns.Category,
    Columns.Status,
    Columns.Requester,
    Columns.LastUpdated,
    Columns.Created,
];

export const companyTicketsFilters: Key[] = [FilterKeys.Categories, FilterKeys.Statuses];

export const myTicketsFilters: Key[] = [FilterKeys.Categories, FilterKeys.Statuses, FilterKeys.Priorities];

export const worklistAndReporteeFilters: Key[] = [
    FilterKeys.Assignees,
    FilterKeys.Categories,
    FilterKeys.Statuses,
    FilterKeys.Priorities,
    FilterKeys.Companies,
];

export const getTicketingSettings = (context: TicketingState): TicketingFilters => {
    switch (context.ticketListView) {
        case TicketListView.MyTickets:
            return context.myTicket;
        case TicketListView.Worklist:
            return context.worklist;
        case TicketListView.Caseworker:
            return context.caseworker;
        case TicketListView.CompanyTickets:
            return context.companyTickets;
        default:
            return context.myTicket;
    }
};

const allFilterOptionsWithTranslation = () => {
    return [
        {
            key: Columns.Subject,
            value: TicketSortingType.Subject,
            text: t('Ticketing:commonTicketing.filterDropdownOptionsSubject'),
        },
        {
            key: Columns.Category,
            value: TicketSortingType.Category,
            text: t('Ticketing:commonTicketing.filterDropdownOptionsCategory'),
        },
        {
            key: Columns.Created,
            value: TicketSortingType.Created,
            text: t('Ticketing:commonTicketing.filterDropdownOptionsLastCreated'),
        },
        {
            key: Columns.Location,
            value: TicketSortingType.Location,
            text: t('Ticketing:commonTicketing.filterDropdownOptionsLastLocation'),
        },
        {
            key: Columns.Priority,
            value: TicketSortingType.Priority,
            text: t('Ticketing:commonTicketing.filterDropdownOptionsPriority'),
        },
        { key: Columns.Requester, value: TicketSortingType.Requester, text: t('Ticketing:commonTicketing.petitioner') },
        {
            key: Columns.Caseworker,
            value: TicketSortingType.Assignee,
            text: t('Ticketing:commonTicketing.filterDropdownOptionsLastAssignee'),
        },
        {
            key: Columns.Company,
            value: TicketSortingType.Company,
            text: t('Ticketing:commonTicketing.filterDropdownOptionsLastCompany'),
        },
        {
            key: Columns.LastUpdated,
            value: TicketSortingType.LastUpdate,
            text: t('Ticketing:commonTicketing.filterDropdownOptionsLastUpdate'),
        },
        {
            key: Columns.Status,
            value: TicketSortingType.Status,
            text: t('Ticketing:commonTicketing.filterDropdownOptionsStatus'),
        },
        {
            key: Columns.Type,
            value: TicketSortingType.Type,
            text: t('Ticketing:commonTicketing.filterDropdownOptionsType'),
        },
        {
            key: Columns.Notification,
            value: TicketSortingType.Notification,
            text: t('Ticketing:commonTicketing.filterDropdownOptionsAlerts'),
        },
    ];
};

export const getFilterDropdownOptions = (view: TicketListView, isMobile: boolean): DropDownOption[] => {
    const filterOptions = allFilterOptionsWithTranslation();
    if ((view === TicketListView.Worklist || view === TicketListView.Caseworker) && isMobile)
        return filterOptionsHelper(filterOptions, worklistAndReporteeMobileColumns);

    if (view === TicketListView.MyTickets && isMobile)
        return filterOptionsHelper(filterOptions, myTicketListMobileColumns);
    if (view === TicketListView.CompanyTickets && isMobile)
        return filterOptionsHelper(filterOptions, companyTicketListMobileColumns);
    if (view === TicketListView.MyTickets) return filterOptionsHelper(filterOptions, myTicketsColumns);
    if (view === TicketListView.CompanyTickets) return filterOptionsHelper(filterOptions, companyTicketsColumns);

    return filterOptionsHelper(filterOptions, worklistAndReporteeColumns);
};

const filteredColumns = (allColumns: ColumnType, columns: Key[]) =>
    allColumns.filter((option) => columns.includes(option.key));

export const getViewColumns = (view: TicketListView, allColumns: ColumnType, isMobile?: boolean): ColumnType => {
    if (isMobile && (view === TicketListView.Worklist || view === TicketListView.Caseworker))
        return filteredColumns(allColumns, worklistAndReporteeMobileColumns);
    if (isMobile && view === TicketListView.CompanyTickets)
        return filteredColumns(allColumns, companyTicketListMobileColumns);
    if (isMobile && view === TicketListView.MyTickets) return filteredColumns(allColumns, myTicketListMobileColumns);
    if (view === TicketListView.MyTickets) return filteredColumns(allColumns, myTicketsColumns);
    if (view === TicketListView.CompanyTickets) return filteredColumns(allColumns, companyTicketsColumns);

    return filteredColumns(allColumns, worklistAndReporteeColumns);
};

export const getStatusTicketTranslation = (status: TicketStatus): string => {
    const ticketStatuses = {
        New: t('Ticketing:commonTicketing.ticketStatusTranslationNew'),
        Open: t('Ticketing:commonTicketing.ticketStatusTranslationOpen'),
        Pending: t('Ticketing:commonTicketing.ticketStatusTranslationPending'),
        Solved: t('Ticketing:commonTicketing.ticketStatusTranslationSolved'),
        Closed: t('Ticketing:commonTicketing.ticketStatusTranslationClosed'),
    };
    return ticketStatuses[status] || status;
};

export const getPriorityTranslation = (priority: TicketPriority): string => {
    const ticketPriorities = {
        Low: t('Ticketing:commonTicketing.ticketPriorityLow'),
        Medium: t('Ticketing:commonTicketing.ticketPriorityMedium'),
        High: t('Ticketing:commonTicketing.ticketPriorityHigh'),
        Critical: t('Ticketing:commonTicketing.ticketPriorityCritical'),
    };
    return ticketPriorities[priority] || priority;
};

const getFilteredFilters = (allFilters: TicketFilterProps[], filterKeys: Key[]) => {
    return allFilters.filter((option) => filterKeys.includes(option.key));
};

export const getViewFilters = (view: TicketListView, allFilters: TicketFilterProps[]): TicketFilterProps[] => {
    if (view === TicketListView.Worklist || view === TicketListView.Caseworker)
        return getFilteredFilters(allFilters, worklistAndReporteeFilters);
    if (view === TicketListView.CompanyTickets) return getFilteredFilters(allFilters, companyTicketsFilters);
    if (view === TicketListView.MyTickets) return getFilteredFilters(allFilters, myTicketsFilters);

    return getFilteredFilters(allFilters, worklistAndReporteeColumns);
};
