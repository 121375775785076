import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CustomerProps } from './types';
const Card = styled.div`
    width: 100%;
    padding: 1.714rem;
    border: 1px solid;
    border-radius: 0.357rem;
    overflow: hidden;
    height: 100%;
    border-color: var(--text-icons-secondary);
`;

const CardTitle = styled.h3`
    color: var(--text-icons-secondary);
    font-style: normal;
    font-weight: 900;
    font-size: 1.428rem;
    line-height: 2.285rem;
    margin-bottom: 8px;
`;

const CardData = styled.div`
    display: flex;
    gap: 8px;
`;

const InformationParagraph = styled.p`
    color: var(--text-icons-secondary);
    font-size: 1.286rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.714rem;
    letter-spacing: 0.014rem;
    margin-bottom: 0.571rem;
`;

const StressInformationParagraph = styled(InformationParagraph)`
    font-weight: 900;
`;

const Paragraph = styled.p`
    font-weight: 400;
    font-size: 0.857rem;
    line-height: 1.428rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 4px;
`;

const TextArea = styled.textarea`
    width: 100%;
    border-radius: 0.714rem;
    border: 1px solid #d4d6d7;
    padding: 0.571rem 1.142rem;
    pointer-events: none;
    resize: none;
    color: var(--text-icons-secondary);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
`;

const Customer: FC<CustomerProps> = ({ customer }) => {
    const { title, userInfo, companyName, email, areaValue, areaTitle, hideComment } = customer;
    const { t } = useTranslation('common');
    return (
        <Card>
            <CardTitle>{title || ''}</CardTitle>
            <CardData>
                <InformationParagraph>{userInfo || ''}</InformationParagraph>
                <StressInformationParagraph>{companyName || ''}</StressInformationParagraph>
            </CardData>
            <CardData>
                <StressInformationParagraph>{`${t('email')}:`}</StressInformationParagraph>
                <InformationParagraph>{email || ''}</InformationParagraph>
            </CardData>
            {!hideComment && (
                <>
                    <Paragraph>{areaTitle || ''}</Paragraph>
                    <TextArea readOnly rows={4}>
                        {areaValue || ''}
                    </TextArea>
                </>
            )}
        </Card>
    );
};

export default Customer;
