import { ReactElement, useState, useEffect, ChangeEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InputField, PrimaryButton, SvgIcon } from '../../../../../components';
import { IconButton } from '../../../../../components/accordion/StyledAccordionTab';
import {
    useDeleteGuestRegistrationKey,
    useGetGuestRegistrationKey,
    useCreateGuestRegistrationKey,
} from '../../../../GuestRegistration/utils/useAnonymousUserRegistration';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.57rem;
`;

const StyledTitle = styled.p`
    font-size: 1.142rem;
    font-weight: 900;
    margin-bottom: 0;
`;

const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: 2rem;
    right: 0.57rem;
`;
const InputWrapper = styled.div`
    width: 100%;
    position: relative;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    width: 100%;
    gap: 0.57rem;
`;

const Clue = styled.p`
    position: absolute;
    right: 0;
    top: 100%;
    padding: 0.57rem 0.714rem;
    background-color: #000000bb;
    color: white;
    pointer-events: none;
    transition: opacity 0.3s ease;
`;

const VisitorRegistrationKey = (): ReactElement => {
    const { t } = useTranslation('Admin', { keyPrefix: 'tenantsAdmin' });

    const {
        data: fetchedGuestRegistrationKey,
        isLoading: isLoadingGuestRegistrationKey,
        isError: isErrorLoadingGuestRegistrationKey,
    } = useGetGuestRegistrationKey();

    const [
        createGuestRegistrationKey,
        { isLoading: isCreatingGuestRegistrationKey, isError: isErrorCreatingGuestRegistrationKey },
    ] = useCreateGuestRegistrationKey();

    const [
        deleteGuestRegistrationKey,
        { isLoading: isDeletingGuestRegistrationKey, isError: isErrorDeletingGuestRegistrationKey },
    ] = useDeleteGuestRegistrationKey();
    const [guestRegistrationKey, setGuestRegistrationKey] = useState('');
    const [copiedGuestRegistrationKey, setCopiedGuestRegistrationKey] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        setCopiedGuestRegistrationKey(false);
        fetchedGuestRegistrationKey && setGuestRegistrationKey(fetchedGuestRegistrationKey);
    }, [fetchedGuestRegistrationKey]);

    const copiedOnClipboard = () => {
        if (guestRegistrationKey) {
            navigator.clipboard.writeText(guestRegistrationKey);
            setCopiedGuestRegistrationKey(true);
        }
    };
    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setGuestRegistrationKey(event.target.value);
    };
    const error =
        isErrorLoadingGuestRegistrationKey || isErrorDeletingGuestRegistrationKey || isErrorCreatingGuestRegistrationKey
            ? t('errorMessageLoadingUpdatingRegistrationKey')
            : '';

    const manageGuestRegistrationKey = () => {
        guestRegistrationKey
            ? deleteGuestRegistrationKey('', {
                  onSuccess: () => setGuestRegistrationKey(''),
              })
            : createGuestRegistrationKey('', {
                  onSuccess: ({ data }) => {
                      setGuestRegistrationKey(data);
                  },
              });
    };
    const handleEvent = (event: MouseEvent<HTMLButtonElement>): void => {
        if (event.type === 'mouseenter') {
            setIsHovered(true);
        }
        if (event.type === 'mouseleave') {
            setIsHovered(false);
        }
    };
    const isLoading = isLoadingGuestRegistrationKey || isCreatingGuestRegistrationKey || isDeletingGuestRegistrationKey;
    const showClue = isHovered && copiedGuestRegistrationKey;
    return (
        <Container>
            <StyledTitle>{t('guestRegistrationKeyTitle')}</StyledTitle>
            <div>{t('guestRegistrationKeyDescription')}</div>
            <InputWrapper>
                <InputField
                    type="text"
                    label={t('guestRegistrationKeyInputLabel')}
                    placeholder={t('guestRegistrationKeyInputPlaceholder')}
                    value={guestRegistrationKey}
                    onChange={handleInputChange}
                    onBlur={() => null}
                    name="guestRegistrationKey"
                    readOnly
                    isLoading={isLoading}
                    error={error}
                />
                <StyledIconButton onClick={copiedOnClipboard} onMouseEnter={handleEvent} onMouseLeave={handleEvent}>
                    <SvgIcon name="CopyToClipboardIcon" alt="Copy to clipboard" />
                </StyledIconButton>
                {showClue && (
                    <Clue>{t(`${copiedGuestRegistrationKey ? 'copiedToClipboardClue' : 'copyToClipboardClue'}`)}</Clue>
                )}
            </InputWrapper>

            <StyledPrimaryButton
                disabled={isLoading || !!error}
                error={guestRegistrationKey}
                onClick={manageGuestRegistrationKey}
            >
                <SvgIcon name="VisitorRegistrationKeyIcon" />
                {t(
                    `${
                        guestRegistrationKey ? 'revokeGuestRegistrationKeyButton' : 'generateGuestRegistrationKeyButton'
                    }`,
                )}
            </StyledPrimaryButton>
        </Container>
    );
};

export default VisitorRegistrationKey;
