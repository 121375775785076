import { ReactElement, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { type TFunction, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { Category, TicketType } from '../../../../../../../../../api/apiTypes/ticketingApiTypes';
import {
    InputField,
    Modal,
    PrimaryButton,
    GhostPrimaryButton,
    FullPageLoader,
    ErrorMessage,
    ModalType,
} from '../../../../../../../../../components';
import { useCreateTicketCategory } from '../../../../../../../apiQueries/useTicketingTypeCategory';
import { Paragraph } from '../../../../../../../common/styles';

const StyledForm = styled.form``;

const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    padding: 1.7rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-left: 1rem;
    }
`;

const LoaderContainer = styled.div`
    min-height: min(80vh, 15rem);
    display: flex;
    align-items: center;
`;

interface CategoryModalProps {
    open: boolean;
    onClose: () => void;
    type: TicketType;
}

let existingCategoriesForTicketType: string[] = [];

const schema = (t: TFunction) =>
    yup.object().shape({
        name: yup
            .string()
            .required(t('errorMessageCategoryNameRequired'))
            .min(2, t('errorMessageNameMustHaveMinValue'))
            .max(100, t('errorMessageValueMustHaveMaxLetters'))
            .trim()
            .test('isUnique', t('settings.errorMessageNameMustBeUnique'), function (value) {
                return value !== undefined &&
                    Array.isArray(existingCategoriesForTicketType) &&
                    existingCategoriesForTicketType.length
                    ? !existingCategoriesForTicketType.includes(value.toString().toLowerCase())
                    : true;
            }),
        externalDescription: yup.string().max(80, t('errorMessageMax80Characters')),
    });

const formId = 'newCategory';
const TicketCategoryModal = ({ open, onClose, type }: CategoryModalProps): ReactElement => {
    const [createCategory, { isLoading, isError, isSuccess }] = useCreateTicketCategory();
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    existingCategoriesForTicketType = type.categories.map((x) => x.name.toLowerCase());
    useEffect(() => {
        if (isSuccess) onClose();
    }, [isSuccess, onClose]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Category>({
        mode: 'onTouched',
        resolver: yupResolver(schema(t)),
        defaultValues: { name: '' },
    });

    const onSubmit = (data: Category) => {
        createCategory([type.id, data]);
    };

    return (
        <>
            <Modal
                type={ModalType.Small}
                open={open}
                title={isLoading ? t('createsCategory') : t('newCategoryButton')}
                onClose={onClose}
                hideCloseButton
                error={isError}
                bottomContent={
                    <ButtonContainer>
                        <GhostPrimaryButton type="button" onClick={onClose}>
                            {tCommon('cancelButton')}
                        </GhostPrimaryButton>
                        <StyledPrimaryButton
                            type="submit"
                            form={formId}
                            disabled={isLoading || Object.keys(errors).length > 0 ? true : false}
                        >
                            {tCommon('addButton')}
                        </StyledPrimaryButton>
                    </ButtonContainer>
                }
            >
                {isLoading ? (
                    <LoaderContainer>
                        <FullPageLoader text="" />
                    </LoaderContainer>
                ) : isError ? (
                    <LoaderContainer>
                        <ErrorMessage message={t('errorOccuredCreatingCategory')} />
                    </LoaderContainer>
                ) : (
                    <ContentContainer>
                        <Paragraph>{t('whatNameOfCategory')}</Paragraph>
                        <StyledForm id={formId} onSubmit={handleSubmit(onSubmit)}>
                            <InputField
                                label={tCommon('name')}
                                placeholder={t('nameOfCategoryPlaceholder')}
                                required
                                error={errors.name?.message}
                                {...register('name')}
                            />
                        </StyledForm>
                    </ContentContainer>
                )}
            </Modal>
        </>
    );
};

export default TicketCategoryModal;
