import { TicketingProvider } from '../Ticketing';
import MyTickets from './MyTickets';

export default function CompanyTicketsWithProvider() {
    return (
        <TicketingProvider>
            <MyTickets />
        </TicketingProvider>
    );
}
