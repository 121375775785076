import { RefObject, useEffect, useState } from 'react';

const useDragFile = (dropZoneRef: RefObject<HTMLElement>): { droppedFile: File | null; dragging: boolean } => {
    const [dragging, setDragging] = useState<boolean>(false);
    const [dragCounter, setDragCounter] = useState<number>(0);
    const [droppedFile, setDroppedFile] = useState<File | null>(null);

    useEffect(() => {
        const commonEventHandler = (e: DragEvent): void => {
            e.preventDefault();
            e.stopPropagation();
        };

        const handleDragEnter = (e: DragEvent): void => {
            commonEventHandler(e);
            setDragCounter(dragCounter + 1);
            if (e?.dataTransfer?.items && e.dataTransfer.items.length > 0) setDragging(true);
        };

        const handleDragLeave = (e: DragEvent): void => {
            commonEventHandler(e);
            setDragCounter(dragCounter - 1);
            if (dragCounter - 1 <= 0) setDragging(false);
        };

        const handleDragOver = (e: DragEvent): void => {
            commonEventHandler(e);
        };

        const handleFileDrop = (e: DragEvent): void => {
            commonEventHandler(e);
            setDragging(false);
            if (e?.dataTransfer?.files && e.dataTransfer.files.length > 0) {
                setDroppedFile(e?.dataTransfer?.files[0]);
                e.dataTransfer.clearData();
                setDragCounter(0);
            }
        };

        const currentDropZoneRef = dropZoneRef?.current;
        currentDropZoneRef?.addEventListener('dragenter', handleDragEnter);
        currentDropZoneRef?.addEventListener('dragleave', handleDragLeave);
        currentDropZoneRef?.addEventListener('dragover', handleDragOver);
        currentDropZoneRef?.addEventListener('drop', handleFileDrop);

        return () => {
            currentDropZoneRef?.removeEventListener('dragenter', handleDragEnter);
            currentDropZoneRef?.removeEventListener('dragleave', handleDragLeave);
            currentDropZoneRef?.removeEventListener('dragover', handleDragOver);
            currentDropZoneRef?.removeEventListener('drop', handleFileDrop);
        };
    });

    return {
        droppedFile,
        dragging,
    };
};

export default useDragFile;
