import { LanguagesEnum } from './app/utility/languageUtilities/language.enum';
import kitchenAdminEn from './locales/en/Admin/kitchenAdmin.json';
import tenantsAdminEn from './locales/en/Admin/tenantsAdmin.json';
import usersAdminEn from './locales/en/Admin/usersAdmin.json';
import authorizationEn from './locales/en/authorization.json';
import commonEn from './locales/en/common.json';
import articlesEn from './locales/en/Dashboard/articles.json';
import canteenPassEn from './locales/en/Dashboard/canteenPass.json';
import dailyMenuEn from './locales/en/Dashboard/dailyMenu.json';
import frontPageEn from './locales/en/Dashboard/frontPage.json';
import eventsEn from './locales/en/events.json';
import allergensEn from './locales/en/Food/allergens.json';
import commonFoodEn from './locales/en/Food/common.json';
import foodForMeetingEn from './locales/en/Food/foodForMeeting.json';
import foodOrderEn from './locales/en/Food/foodOrder.json';
import manageFoodOrderModalEn from './locales/en/Food/manageFoodOrderModal.json';
import myFoodBookingsEn from './locales/en/Food/myBookings.json';
import shoppingCardEn from './locales/en/Food/shoppingCard.json';
import guestRegistrationEn from './locales/en/guestRegistration.json';
import approveMeeting from './locales/en/Meeting/approveMeetings.json';
import cancelMeetingModal from './locales/en/Meeting/cancelMeetingModal.json';
import commonMeeting from './locales/en/Meeting/common.json';
import meetingOverview from './locales/en/Meeting/meetingOverview.json';
import myCalendar from './locales/en/Meeting/myCalendar.json';
import roomOverview from './locales/en/Meeting/roomOverview.json';
import meetingSettings from './locales/en/Meeting/settings.json';
import myProfileEn from './locales/en/myProfile.json';
import notificationEn from './locales/en/notification.json';
import shopAtWorkAdministrateProductCategoryEn from './locales/en/ShopAtWork/administrateProductCategory.json';
import commonShopAtWorkEn from './locales/en/ShopAtWork/common.json';
import shopAtWorkInventoryEn from './locales/en/ShopAtWork/inventory.json';
import shopAtWorkInvoiceEn from './locales/en/ShopAtWork/invoice.json';
import shopAtWorkOrderOverviewEn from './locales/en/ShopAtWork/orderOverview.json';
import shopAtWorkPortalPurchasesEn from './locales/en/ShopAtWork/portalPurchases.json';
import shopAtWorkProductFormEn from './locales/en/ShopAtWork/productForm.json';
import shopAtWorkSettingsEn from './locales/en/ShopAtWork/settings.json';
import shopAtWorkShop from './locales/en/ShopAtWork/shop.json';
import commonTicketingEn from './locales/en/Ticketing/commonTicketing.json';
import newTicketEn from './locales/en/Ticketing/newTicket.json';
import settingsEn from './locales/en/Ticketing/settings.json';
import commonVendorSettings from './locales/en/VendorSettings/common.json';
import wardrobeEn from './locales/en/wardrobe.json';
import wifiAtWorkEn from './locales/en/wifiAtWork.json';
import kitchenAdmin from './locales/no/Admin/kitchenAdmin.json';
import tenantsAdmin from './locales/no/Admin/tenantsAdmin.json';
import usersAdmin from './locales/no/Admin/usersAdmin.json';
import authorizationNo from './locales/no/authorization.json';
import commonNo from './locales/no/common.json';
import articlesNo from './locales/no/Dashboard/articles.json';
import canteenPassNo from './locales/no/Dashboard/canteenPass.json';
import dailyMenuNo from './locales/no/Dashboard/dailyMenu.json';
import frontPageNo from './locales/no/Dashboard/frontPage.json';
import eventsNo from './locales/no/events.json';
import allergens from './locales/no/Food/allergens.json';
import commonFood from './locales/no/Food/common.json';
import foodForMeetingNo from './locales/no/Food/foodForMeeting.json';
import foodOrder from './locales/no/Food/foodOrder.json';
import manageFoodOrderModal from './locales/no/Food/manageFoodOrderModal.json';
import myFoodBookings from './locales/no/Food/myBookings.json';
import shoppingCard from './locales/no/Food/shoppingCard.json';
import guestRegistrationNo from './locales/no/guestRegistration.json';
import approveMeetingNo from './locales/no/Meeting/approveMeetings.json';
import cancelMeetingModalNo from './locales/no/Meeting/cancelMeetingModal.json';
import commonMeetingNo from './locales/no/Meeting/common.json';
import meetingOverviewNo from './locales/no/Meeting/meetingOverview.json';
import myCalendarNo from './locales/no/Meeting/myCalendar.json';
import roomOverviewNo from './locales/no/Meeting/roomOverview.json';
import meetingSettingsNo from './locales/no/Meeting/settings.json';
import myProfileNO from './locales/no/myProfile.json';
import notificationNo from './locales/no/notification.json';
import shopAtWorkAdministrateProductCategory from './locales/no/ShopAtWork/administrateProductCategory.json';
import commonShopAtWorkNo from './locales/no/ShopAtWork/common.json';
import shopAtWorkInventory from './locales/no/ShopAtWork/inventory.json';
import shopAtWorkInvoice from './locales/no/ShopAtWork/invoice.json';
import shopAtWorkOrderOverview from './locales/no/ShopAtWork/orderOverview.json';
import shopAtWorkPortalPurchases from './locales/no/ShopAtWork/portalPurchases.json';
import shopAtWorkProductForm from './locales/no/ShopAtWork/productForm.json';
import shopAtWorkSettings from './locales/no/ShopAtWork/settings.json';
import shopAtWorkShopNo from './locales/no/ShopAtWork/shop.json';
import commonTicketing from './locales/no/Ticketing/commonTicketing.json';
import newTicket from './locales/no/Ticketing/newTicket.json';
import settings from './locales/no/Ticketing/settings.json';
import commonVendorSettingsNo from './locales/no/VendorSettings/common.json';
import wardrobeNo from './locales/no/wardrobe.json';
import wifiAtWorkNo from './locales/no/wifiAtWork.json';

type DropdownElement = {
    value: string;
    id: string;
};

export const Languages: LanguagesEnum[] = [LanguagesEnum.no, LanguagesEnum.en];

export const LanguagesLongForm: Record<LanguagesEnum, string> = {
    no: 'Norsk',
    en: 'English',
};

export const FormatLanguages: DropdownElement[] = Object.entries(LanguagesLongForm).map(([shortForm, longForm]) => {
    return { id: shortForm, value: longForm };
});

export const defaultNS = 'Ticketing';
export const resources = {
    no: {
        Ticketing: {
            settings,
            newTicket,
            commonTicketing,
        },
        ShopAtWork: {
            inventory: shopAtWorkInventory,
            productCategoryAdministration: shopAtWorkAdministrateProductCategory,
            orderOverview: shopAtWorkOrderOverview,
            portalPurchases: shopAtWorkPortalPurchases,
            settings: shopAtWorkSettings,
            productForm: shopAtWorkProductForm,
            invoice: shopAtWorkInvoice,
            commonShopAtWork: commonShopAtWorkNo,
            shop: shopAtWorkShopNo,
        },
        Admin: {
            usersAdmin,
            tenantsAdmin,
            kitchenAdmin,
        },
        Food: {
            allergens,
            myFoodBookings,
            foodOrder,
            commonFood,
            shoppingCard,
            manageFoodOrderModal,
            foodForMeeting: foodForMeetingNo,
        },
        Dashboard: {
            articles: articlesNo,
            canteenPass: canteenPassNo,
            dailyMenu: dailyMenuNo,
            frontPage: frontPageNo,
        },
        Meeting: {
            meetingOverview: meetingOverviewNo,
            roomOverview: roomOverviewNo,
            myCalendar: myCalendarNo,
            cancelMeetingModal: cancelMeetingModalNo,
            settings: meetingSettingsNo,
            commonMeeting: commonMeetingNo,
            approveMeeting: approveMeetingNo,
        },
        VendorSettings: {
            common: commonVendorSettingsNo,
        },
        common: commonNo,
        authorization: authorizationNo,
        myProfile: myProfileNO,
        notification: notificationNo,
        wifiAtWork: wifiAtWorkNo,
        guestRegistration: guestRegistrationNo,
        wardrobe: wardrobeNo,
        events: eventsNo,
    },

    en: {
        Ticketing: {
            settings: settingsEn,
            newTicket: newTicketEn,
            commonTicketing: commonTicketingEn,
        },
        ShopAtWork: {
            inventory: shopAtWorkInventoryEn,
            productCategoryAdministration: shopAtWorkAdministrateProductCategoryEn,
            orderOverview: shopAtWorkOrderOverviewEn,
            portalPurchases: shopAtWorkPortalPurchasesEn,
            settings: shopAtWorkSettingsEn,
            productForm: shopAtWorkProductFormEn,
            invoice: shopAtWorkInvoiceEn,
            commonShopAtWork: commonShopAtWorkEn,
            shop: shopAtWorkShop,
        },
        Admin: {
            usersAdmin: usersAdminEn,
            tenantsAdmin: tenantsAdminEn,
            kitchenAdmin: kitchenAdminEn,
        },
        Food: {
            allergens: allergensEn,
            myFoodBookings: myFoodBookingsEn,
            foodOrder: foodOrderEn,
            commonFood: commonFoodEn,
            shoppingCard: shoppingCardEn,
            manageFoodOrderModal: manageFoodOrderModalEn,
            foodForMeeting: foodForMeetingEn,
        },
        Dashboard: {
            articles: articlesEn,
            canteenPass: canteenPassEn,
            dailyMenu: dailyMenuEn,
            frontPage: frontPageEn,
        },
        Meeting: {
            meetingOverview: meetingOverview,
            roomOverview: roomOverview,
            myCalendar: myCalendar,
            cancelMeetingModal: cancelMeetingModal,
            settings: meetingSettings,
            commonMeeting: commonMeeting,
            approveMeeting: approveMeeting,
        },
        VendorSettings: {
            common: commonVendorSettings,
        },
        wifiAtWork: wifiAtWorkEn,
        authorization: authorizationEn,
        myProfile: myProfileEn,
        notification: notificationEn,
        common: commonEn,
        guestRegistration: guestRegistrationEn,
        wardrobe: wardrobeEn,
        events: eventsEn,
    },
} as const;
