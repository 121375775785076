import { ReactElement, useContext } from 'react';
import { ModuleName } from '../../../../../api/apiTypes/portalApiTypes';
import openExternalLink from '../../../../../helpers/openExternalLink';
import { useModuleIsActive } from '../../../../../utility';
import { NewTicketContext } from '../../NewTicketContext';
import useNewTicketSteps from '../../UseNewTicketSteps';
import { NewTicketList } from '../NewTicketList';

export const TypeStep = (): ReactElement => {
    const isTicketingExternalLinkModuleActive = useModuleIsActive(ModuleName.TicketingExternalLink);
    const { state: newTicketContext, dispatch } = useContext(NewTicketContext);
    const stepHook = useNewTicketSteps();

    const handleUpdateType = (id: string) => {
        dispatch({ type: 'UPDATE_TICKET_TYPE', payload: id });

        const choosenType = newTicketContext.ticketData.find((type) => type.id === id);
        if (choosenType && choosenType.categories.length === 1) {
            const firstCategoryByType = choosenType.categories[0];
            const isExternalLink = 'externalLinkUrl' in firstCategoryByType;
            if (isExternalLink && isTicketingExternalLinkModuleActive && firstCategoryByType.externalLinkUrl) {
                openExternalLink(firstCategoryByType.externalLinkUrl);
            } else {
                dispatch({ type: 'UPDATE_TICKET_CATEGORY', payload: choosenType.categories[0].id });
                stepHook.goNext();
            }
        }
    };

    return <NewTicketList items={newTicketContext.ticketData || []} onclick={handleUpdateType} />;
};
