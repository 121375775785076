import { KeyboardEvent, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { TicketImage } from '../../../../api/apiTypes/ticketingApiTypes';
import { Modal as FullSizeImageModal, ModalType } from '../../../../components';

type SmallSizeImageProps = { preview: boolean };
const SmallSizeImage = styled.img`
    object-fit: cover;
    border-radius: 0.357rem;
    width: ${({ preview }: SmallSizeImageProps) => (preview ? '3.857rem' : '100%')};
    height: ${({ preview }: SmallSizeImageProps) => (preview ? '3.857rem' : '8.571rem')};

    cursor: pointer;

    :hover {
        filter: brightness(70%);
        transition: filter 0.2s ease;
    }

    :focus,
    :focus-visible {
        filter: brightness(70%);
        transition: filter 1s ease;
        outline-offset: 3px;
        outline: 1px solid var(--primary-color);
    }
    :focus:not(:focus-visible) {
        outline: 0;
    }
`;

const Wrapper = styled.div`
    padding-top: 4.25rem;
    height: 100%;
    width: 100%;
`;

const ImageContainer = styled.div`
    overflow: auto;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2f2f2;
`;

const ImageFullView = styled.img`
    && {
        height: auto;
        width: auto;
    }
`;

const ImageFullViewFileName = styled.h4`
    font-weight: 700;
    font-size: 1.071rem;
    text-align: center;
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

interface ImageViewProps {
    image: TicketImage;
    alt: string | undefined;
    smallImageViewHeight?: number;
    className?: string;
    preview?: boolean;
    onlyFullView?: boolean;
    onClose?: () => void;
}

const ImageView = ({
    image,
    alt,
    smallImageViewHeight = 120,
    className,
    preview = false,
    onlyFullView,
    onClose,
}: ImageViewProps): ReactElement | null => {
    const [fullViewImage, setFullViewImage] = useState(!!onlyFullView);

    const onKeyPressImageHandler = (e: KeyboardEvent) => {
        if (e.code === 'Enter' || e.code === 'Space') setFullViewImage(true);
    };

    const handleClose = () => {
        onClose && onClose();
        setFullViewImage(false);
    };

    return image.imageUrl ? (
        <>
            {!onlyFullView && (
                <SmallSizeImage
                    tabIndex={0}
                    src={image.imageUrl}
                    alt={alt + ' liten størrelse'}
                    height={smallImageViewHeight}
                    onClick={() => setFullViewImage(true)}
                    onKeyPress={onKeyPressImageHandler}
                    preview={preview}
                    className={className}
                />
            )}

            <FullSizeImageModal
                open={fullViewImage}
                onClose={handleClose}
                type={ModalType.Image}
                bottomContent={
                    image.imageFileName && <ImageFullViewFileName>{image.imageFileName}</ImageFullViewFileName>
                }
            >
                <Wrapper>
                    <ImageContainer>
                        <ImageFullView src={image.imageUrl} alt={alt + ' full størrelse'} />
                    </ImageContainer>
                </Wrapper>
            </FullSizeImageModal>
        </>
    ) : null;
};

export default ImageView;
