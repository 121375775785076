import { RefObject, useEffect, useState } from 'react';

const useResizeWindow = (ref: RefObject<HTMLDivElement | null>): number => {
    const [containerWidth, setContainerWidth] = useState<number>(ref.current?.clientWidth ?? 1200);

    useEffect(() => {
        const resize = () => {
            setContainerWidth(ref?.current?.clientWidth ?? 0);
        };
        window.addEventListener('resize', resize);
        resize();
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [ref]);

    return containerWidth;
};

export default useResizeWindow;
