import React, { useEffect, useMemo, useRef } from 'react';
import { addMinutes, isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';
import { getDeviceLanguageDateFormat } from '../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import useRect from '../../../utility/hooks/useRect';
import { ContainerDiv, SelectorContainer, TimeHeader, OptionsContainer, OptionsList } from './components';

const CenteredInput = styled(Input)`
    &&& > input {
        text-align: center;
        margin-top: 0.6rem;
        margin-bottom: 0.6rem;
    }
`;

const hourContainerName = 'hour';
const minuteContainerName = 'minute';

interface TimeInputProps {
    selectedTime: Date;
    onChangeSelectedTime: (newTime: Date) => void;
    earliestTime: Date;
    latestTime: Date;
}
const TimeInput: React.FC<React.PropsWithChildren<TimeInputProps>> = ({
    selectedTime,
    onChangeSelectedTime,
    earliestTime,
    latestTime,
}: TimeInputProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const rect = useRect(containerRef);
    const { t } = useTranslation('common');
    const getHourString = (date: Date) => getDeviceLanguageDateFormat(date, 'HH');
    const getMinuteString = (date: Date) => getDeviceLanguageDateFormat(date, 'mm');
    const [timeOptions, minutes, hours] = useMemo(() => {
        const getTimeOptions = () => {
            const startTime = new Date(earliestTime);
            startTime.setSeconds(0);
            startTime.setMilliseconds(0);
            const endTime = new Date(latestTime);
            if (!isSameDay(startTime, endTime)) {
                console.error('Unexpected, opening and closing is not same day');
            }

            // If startTime is not whole 15 minutes
            if (startTime.getMinutes() % 15 !== 0) {
                startTime.setMinutes(startTime.getMinutes() + 15 - (startTime.getMinutes() % 15));
            }

            const dateOptions: Date[] = [];
            let current = startTime;
            while (current.valueOf() <= endTime.valueOf()) {
                dateOptions.push(current);

                current = addMinutes(current, 15);
            }
            return dateOptions;
        };
        const timeOptions = getTimeOptions();
        const minutes = new Set<string>();
        const hours = new Set<string>();
        timeOptions.forEach((option) => {
            minutes.add(getMinuteString(option));
            hours.add(getHourString(option));
        });

        const sortAndMapMinutes = (minutes: Set<string>): { value: string; invalid: boolean }[] =>
            Array.from(minutes)
                .sort((a, b) => parseInt(a) - parseInt(b))
                .map((value: string): { value: string; invalid: boolean } => {
                    const dateRepresentation = new Date(selectedTime);
                    dateRepresentation.setMinutes(parseInt(value));
                    const invalid: boolean =
                        dateRepresentation < timeOptions[0] || dateRepresentation > timeOptions[timeOptions.length - 1];
                    return { value, invalid };
                });

        const mapHours = (hours: Set<string>): { value: string; invalid: boolean }[] =>
            Array.from(hours).map((value) => ({ value, invalid: false }));
        return [timeOptions, sortAndMapMinutes(minutes), mapHours(hours)];
    }, [earliestTime, latestTime, selectedTime]);

    const scrollToOption = (containerName: string, dataValue: string, smooth = true) => {
        if (containerRef.current) {
            const container = containerRef.current.querySelector(`[data-containerName="${containerName}"]`);
            const item = containerRef.current.querySelector<HTMLElement>(`[data-value="${containerName}${dataValue}"]`);
            if (container && item) {
                container.scrollTo({
                    top: item.offsetTop - rect.height / 2 - item.getBoundingClientRect().height / 3,
                    behavior: smooth ? 'smooth' : 'auto',
                });
            }
        }
    };

    const scrollToHour = (newDate: Date, smooth = true) => {
        scrollToOption(hourContainerName, getHourString(newDate), smooth);
    };

    const scrollToMinute = (newDate: Date, smooth = true) => {
        scrollToOption(minuteContainerName, getMinuteString(newDate), smooth);
    };

    useEffect(() => {
        scrollToHour(selectedTime, false);
        scrollToMinute(selectedTime, false);
        // We only want this on mount and when sizes changed, delegate scrolling responsibility to action handlers
    }, [rect]);

    const handleHourChange = (value: string) => {
        let newDate = new Date(selectedTime);
        newDate.setHours(parseInt(value));
        if (newDate > timeOptions[timeOptions.length - 1]) {
            newDate = timeOptions[timeOptions.length - 1];
        } else if (newDate < timeOptions[0]) {
            newDate = timeOptions[0];
        }
        onChangeSelectedTime(newDate);
        scrollToHour(newDate);
        if (selectedTime.getMinutes() !== newDate.getMinutes()) {
            scrollToMinute(newDate);
        }
    };

    const handleMinuteChange = (value: string) => {
        let newDate = new Date(selectedTime);
        newDate.setMinutes(parseInt(value));
        if (newDate > timeOptions[timeOptions.length - 1]) {
            newDate = timeOptions[timeOptions.length - 1];
        } else if (newDate < timeOptions[0]) {
            newDate = timeOptions[0];
        }
        onChangeSelectedTime(newDate);
        scrollToMinute(newDate);
    };

    return (
        <>
            <ContainerDiv>
                <label htmlFor="deliveryTime">{t('chooseWhenFoodDelivery')}</label>
                <CenteredInput
                    id="deliveryTime"
                    value={`${getHourString(selectedTime)}:${getMinuteString(selectedTime)}`}
                    readOnly
                />
            </ContainerDiv>
            {timeOptions?.length > 0 ? (
                <SelectorContainer>
                    <TimeHeader>{t('time')}</TimeHeader>
                    <OptionsContainer ref={containerRef}>
                        <OptionsList
                            containerName={hourContainerName}
                            options={hours}
                            selectedValue={getHourString(selectedTime)}
                            handleValueChange={handleHourChange}
                            marginToAdd={rect.height}
                            alignRight
                        />
                        <OptionsList
                            containerName={minuteContainerName}
                            options={minutes}
                            selectedValue={getMinuteString(selectedTime)}
                            handleValueChange={handleMinuteChange}
                            marginToAdd={rect.height}
                        />
                    </OptionsContainer>
                </SelectorContainer>
            ) : null}
        </>
    );
};

export default TimeInput;
