import { ReactElement, useEffect, useMemo, useState, MouseEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Switch, Route, useHistory, useLocation, useRouteMatch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { TicketType } from '../../../../../api/apiTypes/ticketingApiTypes';
import RoutePromptModal from '../../../../../common/RoutePromptModal';
import { useIsMobile } from '../../../../../utility/hooks/useIsMobile';
import { useTypeIntegrations, useUpdateTicketType } from '../../../apiQueries/useTicketingType';
import useTicketingNavigation, { TypeTabPath } from '../../../useTicketingNavigation';
import TitleBar from '../TitleBar';
import CaseworkerTab from './CaseworkerTab/CaseworkersTab';
import DescriptionTab from './DescriptionTab/DescriptionTab';
import IntegrationsTab from './FDVIntegrationTab/FDVIntegrationTab';
import GeneralTab from './GeneralTab/GeneralTab';

const Information = styled.p`
    font-size: 1.143rem;
    line-height: 1.714rem;
`;

type CardProps = { isMobile: boolean };
const Card = styled.div`
    border-radius: ${({ isMobile }: CardProps) => (isMobile ? 'none' : '0.71rem')};
    margin: ${({ isMobile }: CardProps) => (isMobile ? '0' : '2rem')};
    padding: 1.714rem 0;
    box-shadow: ${({ isMobile }: CardProps) => (isMobile ? 'none' : '0px 4px 10px rgba(0, 0, 0, 0.15)')};
    overflow-y: auto;
    background: var(--surface-color-light);
    max-width: 85.714rem;

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

type TabsWrapperProps = { isMobile: boolean };
const TabsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ isMobile }: TabsWrapperProps) => (isMobile ? '2.179rem 0' : '2.179rem 1.714rem;')};
`;

const TabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: 0;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    ::-webkit-scrollbar {
        display: none; /* chrome and safari */
    }
`;

const HorizontalRule = styled.hr`
    border: 1px solid #d4d6d7;
    margin: 0;
`;

type StyledTabProps = { disabled: boolean };
const StyledTab = styled.button`
    font-weight: ${({ disabled }: StyledTabProps) => (disabled ? 'bold;' : 'normal;')};
    font-size: 1rem;
    color: ${({ disabled }: StyledTabProps) => (disabled ? '#263238;' : '#677074;')};
    && {
        border-bottom: ${({ disabled }: StyledTabProps) =>
            disabled
                ? `
             2px solid blue ;
           `
                : 'gray;'};
    }

    padding: 1.143rem;
    border: none;
    margin: 0 0.1rem;
    background-color: var(--surface-color-light);
    cursor: pointer;

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

const Content = styled.div`
    padding: 0 1.714rem;
`;

interface TypeViewProps {
    types: TicketType[];
}

const TypeView = ({ types }: TypeViewProps): ReactElement | null => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const { t: tCommon } = useTranslation('common');
    const cardRef = useRef(null);
    const history = useHistory();
    const currentLocation = useLocation();
    const isMobile = useIsMobile();
    const { getTypeTabLink, goToTypeTab } = useTicketingNavigation();
    const { typeId } = useParams<{ typeId: string }>();
    const [changesSaved, setChangesSaved] = useState(true);
    const match = useRouteMatch<{ typeId: string; tab: TypeTabPath }>(
        `/:portalName${getTypeTabLink(':typeId', ':tab' as TypeTabPath)}`,
    );
    const tabMatch = match?.params.tab ?? 'general';
    const [toggleState, setToggleState] = useState<TypeTabPath>(tabMatch);
    const [goToTab, setGoToTab] = useState<TypeTabPath>(tabMatch);
    const [activeTab, setActiveTab] = useState(document.getElementById(`#${tabMatch}`));
    const { data: integrationsArray } = useTypeIntegrations();

    const selectedType = useMemo(() => {
        return types.find(({ id }) => id === typeId);
    }, [typeId, types]);

    const isHasPublishedCategory = !!selectedType?.categories.find((category) => category.isPublished);
    const isHasCaseworkers = !!selectedType?.caseWorkers.length;
    const isPublishButtonHidden = !isHasPublishedCategory || !isHasCaseworkers;

    const selectedTypeIntegration = useMemo(
        () =>
            integrationsArray?.find((item) => {
                return item.id === selectedType?.integrationId;
            }),
        [integrationsArray, selectedType],
    );

    useEffect(() => {
        setToggleState(tabMatch);
    }, [tabMatch]);

    useEffect(() => {
        activeTab?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }, [activeTab]);

    const changesSavedHandler = (isSaved: boolean) => {
        setChangesSaved(isSaved);
    };

    const clickTabHandler = (event: MouseEvent<HTMLButtonElement>, tab: TypeTabPath) => {
        setGoToTab(tab);
        setActiveTab(event.currentTarget);

        switch (tab) {
            case 'general':
                goToTypeTab(typeId, 'general');
                changesSaved && setToggleState('general');
                break;
            case 'description':
                goToTypeTab(typeId, 'description');
                changesSaved && setToggleState('description');
                break;
            case 'caseworkers':
                goToTypeTab(typeId, 'caseworkers');
                changesSaved && setToggleState('caseworkers');
                break;
            case 'FDVIntegration':
                goToTypeTab(typeId, 'FDVIntegration');
                changesSaved && setToggleState('FDVIntegration');
                break;
        }
    };

    const [updateType] = useUpdateTicketType();

    const handleOnClickPublish = (isPublished: boolean) => {
        updateType([typeId, { isPublished }]);
    };

    return selectedType ? (
        <>
            <RoutePromptModal
                when={!changesSaved}
                navigate={history.push}
                shouldBlockNavigation={(location) => {
                    if (location.pathname === currentLocation.pathname) return false;
                    else return !changesSaved;
                }}
                modalTitle={tCommon('warning')}
                modalContent={<Information>{t('typeViewModalContent')}</Information>}
                modalCancelButtonText={tCommon('backToEditing')}
                modalOkButtonText={tCommon('discardChanges')}
                onOkButtonClick={() => {
                    setChangesSaved(true);
                    setToggleState(goToTab);
                }}
            />
            <Card isMobile={isMobile} key={typeId} ref={cardRef}>
                <TitleBar
                    integrationName={selectedTypeIntegration?.description}
                    name={selectedType.name}
                    isPublished={selectedType?.isPublished}
                    handleOnClickPublish={handleOnClickPublish}
                    isPublishButtonHidden={isPublishButtonHidden}
                />
                <TabsWrapper isMobile={isMobile}>
                    <TabsContainer>
                        <StyledTab
                            disabled={toggleState === 'general'}
                            onClick={(e) => clickTabHandler(e, 'general')}
                            id="general"
                        >
                            {t('typeViewTabGeneral')}
                        </StyledTab>
                        <StyledTab
                            disabled={toggleState === 'description'}
                            onClick={(e) => clickTabHandler(e, 'description')}
                            id="description"
                        >
                            {t('typeViewTabDescription')}
                        </StyledTab>
                        <StyledTab
                            disabled={toggleState === 'caseworkers'}
                            onClick={(e) => clickTabHandler(e, 'caseworkers')}
                            id="caseworkers"
                        >
                            {t('typeViewTabCaseManagers')}
                        </StyledTab>

                        {!!integrationsArray?.length && (
                            <StyledTab
                                disabled={toggleState === 'FDVIntegration'}
                                onClick={(e) => clickTabHandler(e, 'FDVIntegration')}
                                id="FDVIntegrasjon"
                            >
                                {t('FDVIntegration')}
                            </StyledTab>
                        )}
                    </TabsContainer>
                    <HorizontalRule />
                </TabsWrapper>
                <Content>
                    <Switch>
                        <Route path={`/:portalName${getTypeTabLink(typeId, 'general')}`}>
                            <GeneralTab
                                type={selectedType}
                                key={selectedType.id}
                                changesSaved={changesSavedHandler}
                                allTypes={types}
                            />
                        </Route>
                        <Route path={`/:portalName${getTypeTabLink(typeId, 'description')}`}>
                            <DescriptionTab
                                ticketType={selectedType}
                                key={selectedType.id}
                                changesSaved={changesSavedHandler}
                            />
                        </Route>
                        <Route path={`/:portalName${getTypeTabLink(typeId, 'caseworkers')}`} key={selectedType.id}>
                            <CaseworkerTab ticketType={selectedType} scrollRef={cardRef} />
                        </Route>
                        {/*TODO? -  portal admin check */}
                        <Route path={`/:portalName${getTypeTabLink(typeId, 'FDVIntegration')}`} key={selectedType.id}>
                            <IntegrationsTab integrationsArray={integrationsArray} ticketType={selectedType} />
                        </Route>
                        <Redirect to={`/:portalName${getTypeTabLink(typeId, 'general')}`} />
                    </Switch>
                </Content>
            </Card>
        </>
    ) : null;
};
export default TypeView;
