import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CaseworkerModel } from '../../../../../../../api/apiTypes/ticketingApiTypes';
import { SearchInput } from '../../../../../../../components';
import CaseworkerCard, { CaseWorkerType } from '../../../../../../../components/caseWorkerCard/CaseworkerCard';

const CaseworkerSearchInput = styled(SearchInput)`
    margin: 0;
`;

const Container = styled.div`
    position: relative;
`;

const SearchForCaseworkerContainer = styled.div`
    z-index: 1;
    position: relative;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;

const AddedCaseworkerCardWrapper = styled.div`
    padding-top: 0.571rem;
    position: absolute;
    top: 0;
    width: 100%;
`;

const AddedCaseworkerCard = styled((props) => <CaseworkerCard {...props} />)`
    margin: 0.571rem 0;
`;

interface CaseworkerSearchProps {
    caseworkers: CaseworkerModel[] | [];
    selectedCaseworkerId: string | null;
    onSelectNewAssignee(guid: string): void;
}

const ExistingCaseworkerSearch = ({
    caseworkers,
    onSelectNewAssignee,
    selectedCaseworkerId,
}: CaseworkerSearchProps): ReactElement => {
    const [searchField, setSearchField] = useState('');
    const { t: tCommon } = useTranslation('common');

    const filteredUsers = useMemo(() => {
        const washString = (input: string): string => {
            return input.replace(/\s+/g, '').toLowerCase();
        };

        const includesSearch = (searchQuery: string, searchContent: string): boolean => {
            if (!searchQuery || !searchContent) return false;
            return washString(searchContent).includes(washString(searchQuery));
        };

        return caseworkers.filter((caseworker: CaseworkerModel) =>
            includesSearch(searchField, `${caseworker.firstName} ${caseworker.lastName}`),
        );
    }, [caseworkers, searchField]);

    const onClickSearchCaseworkerHandler = (guid: string) => {
        setSearchField('');
        onSelectNewAssignee(guid);
    };

    return (
        <div>
            <CaseworkerSearchInput
                placeholder={tCommon('searchByName')}
                value={searchField}
                onChange={(e) => setSearchField(e)}
            />
            <Container>
                <SearchForCaseworkerContainer>
                    {filteredUsers?.map(({ id, firstName, lastName, email, avatar }) => {
                        return (
                            <CaseworkerCard
                                id={id}
                                key={id}
                                name={`${firstName} ${lastName}`}
                                email={email}
                                avatar={avatar}
                                onClick={onClickSearchCaseworkerHandler}
                                type={CaseWorkerType.Search}
                            />
                        );
                    })}
                </SearchForCaseworkerContainer>
                <AddedCaseworkerCardWrapper>
                    {caseworkers.length > 0 &&
                        caseworkers.map(({ id, email, avatar, firstName, lastName }) => {
                            return (
                                <AddedCaseworkerCard
                                    id={id}
                                    key={id}
                                    name={`${firstName} ${lastName}`}
                                    email={email}
                                    avatar={avatar}
                                    onClick={onClickSearchCaseworkerHandler}
                                    type={CaseWorkerType.Select}
                                    radioName="newTicketAssignee"
                                    selectCaseworker={id === selectedCaseworkerId || caseworkers.length === 1}
                                />
                            );
                        })}
                </AddedCaseworkerCardWrapper>
            </Container>
        </div>
    );
};

export default ExistingCaseworkerSearch;
