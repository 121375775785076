import { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { TicketPriority } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { OptionType } from '../../../../../../components/general/dropdown/DropDown';
import SvgIcon, { SvgIconName } from '../../../../../../components/icons/SvgIcon';
import { getPriorityTranslation } from '../../../../utils';

const theme = {
    Critical: {
        iconName: 'ArrowChevronIcon' as SvgIconName,
        iconColor: '#FF5E5E',
        textColor: 'var(--error-color-dark)',
    },
    High: {
        iconName: 'ArrowIcon' as SvgIconName,
        iconColor: '#FF5E5E',
        textColor: '#263238',
    },
    Medium: {
        iconName: 'DotIcon' as SvgIconName,
        iconColor: '#263238',
        textColor: '#263238',
    },
    Low: {
        iconName: 'ArrowIcon' as SvgIconName,
        iconColor: '#73CF2A',
        textColor: '#263238',
    },
};

const Container = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
`;

type IconWrapper = { priority: TicketPriority };
const IconWrapper = styled.div`
    display: flex;
    flex: 2.571rem 0 0;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    padding-right: 0.571rem;

    svg {
        transform: ${({ priority }: IconWrapper) => (priority === 'Low' ? 'rotate(180deg)' : 'none')};

        rect {
            width: 1.429rem;
            height: 1.429rem;
        }

        path {
            fill: ${({ priority }: IconWrapper) => theme[priority]?.iconColor ?? 'red'};
        }
    }
`;

const Text = styled.p`
    color: ${({ priority }: IconWrapper) => theme[priority]?.textColor ?? 'grey'};
    font-size: 1.143rem;
    line-height: 1.714rem;
    margin: 0;
`;

interface TicketPriorityComponentProps {
    priority: TicketPriority;
}

const TicketPriorityComponent = ({
    priority,
    children,
}: PropsWithChildren<TicketPriorityComponentProps>): ReactElement => {
    const mappedPriority = getPriorityTranslation(priority);
    return (
        <Container>
            <IconWrapper priority={priority}>
                <SvgIcon name={theme[priority]?.iconName ?? 'InfoCircle'} />
            </IconWrapper>
            <Text priority={priority}>{priority === children ? mappedPriority : children}</Text>
        </Container>
    );
};

export const renderPriorityOptions = (
    priorityOptions: TicketPriority[] | undefined,
    withText = true,
): OptionType[] | [] => {
    if (priorityOptions) {
        return priorityOptions.map((priorityOption: TicketPriority) => {
            return {
                id: priorityOption,
                value: (
                    <TicketPriorityComponent priority={priorityOption}>
                        {withText && priorityOption}
                    </TicketPriorityComponent>
                ),
            };
        });
    } else return [];
};

export default TicketPriorityComponent;
