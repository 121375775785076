import { ReactElement } from 'react';
import { Divider } from 'semantic-ui-react';
import styled from 'styled-components';
import { useUserHasPerm } from '../../../../utility';
import Perm from '../../../perms';
import VisitorRegistrationKey from './components/VisitorRegistrationKey';
import VisitorRegistrationToggle from './components/VisitorRegistrationToggle';

const Container = styled.div`
    padding: 0 1.142rem;
    background-color: var(--background-color);
    width: 100%;
`;

const StyledDivider = styled(Divider)`
    && {
        margin: 0.57rem 0;
    }
`;

interface VisitorRegistrationComponentProps {
    isEnabled: boolean;
}
const VisitorRegistrationComponent = ({ isEnabled }: VisitorRegistrationComponentProps): ReactElement => {
    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    return (
        <Container>
            {userIsPortalAdmin && (
                <>
                    <VisitorRegistrationKey />
                    <StyledDivider />
                </>
            )}
            <VisitorRegistrationToggle isEnabled={isEnabled} />
        </Container>
    );
};

export default VisitorRegistrationComponent;
