import { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { TicketType } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { Paragraph, HorizontalRule } from '../../../../common/styles';
import CaseDescription from './components/CaseDescription';

interface DescriptionTabProps {
    ticketType: TicketType;
    changesSaved(isSaved: boolean): void;
}

const DescriptionTab = ({ ticketType, changesSaved }: DescriptionTabProps): ReactElement => {
    const currentLocation = useLocation();
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });

    const [formsSaved, setFormsSaved] = useState<{
        externalDescription: boolean;
        internalDescription: boolean;
    }>({
        externalDescription: true,
        internalDescription: true,
    });

    useEffect(() => {
        setFormsSaved({ externalDescription: true, internalDescription: true });
    }, [currentLocation]);

    useEffect(() => {
        changesSaved(formsSaved.externalDescription && formsSaved.internalDescription);
    }, [formsSaved]);

    return (
        <>
            <Paragraph>{t('descriptionTabParagraph')}</Paragraph>
            <CaseDescription
                header={t('writeDescriptionForUserHeader')}
                description={t('newTicketUsersDescriptionParagraph')}
                inputValue={ticketType.externalDescription}
                name="externalDescription"
                label={t('descriptionTabLabelFieldForUser')}
                isSaved={(isSaved: boolean) => setFormsSaved({ ...formsSaved, externalDescription: isSaved })}
                ticketType={ticketType}
                isExternalDescription={true}
            />
            <HorizontalRule />
            <CaseDescription
                header={t('writeDescriptionForcaseManagers')}
                description={t('informationWillBeVisibleOnlyForCaseManager')}
                inputValue={ticketType.internalDescription}
                name="internalDescription"
                label={t('descriptionTabLabelFieldForManager')}
                isSaved={(isSaved: boolean) => setFormsSaved({ ...formsSaved, internalDescription: isSaved })}
                ticketType={ticketType}
                isExternalDescription={false}
            />
        </>
    );
};

export default DescriptionTab;
