import { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { AppLink, SvgIcon } from '../../../../../../components';
import { getComputedCssProperty } from '../../../../../../utility';

interface MenuLinkProps {
    selected?: boolean;
    expanded?: boolean;
}
const MenuLink = styled(AppLink)`
    font-size: 1.14rem;
    background: ${({ selected }: MenuLinkProps) =>
        selected ? `${getComputedCssProperty('--primary-color-light')}1f` : 'none'}; /* stylelint-disable-line */
    font-weight: ${({ selected }: MenuLinkProps) => (selected ? 'bold' : 'normal')};
    border: none;
    padding: 0 1.42rem;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
        border-top: 1px solid var(--border-color);
    }

    :focus,
    :focus-visible {
        border: 1px solid var(--primary-color);
    }
    :focus:not(:focus-visible) {
        border: none;
        border-bottom: 1px solid var(--border-color);
    }

    & svg {
        min-width: 17px;
        transform: ${({ expanded }: MenuLinkProps) => (expanded ? 'rotate(0.5turn)' : 'none')};
    }
`;

interface MenuTextProps {
    child?: boolean;
    isSelected?: boolean;
}

const MenuText = styled.p`
    color: var(--text-high-emphasis-color);
    padding: 0.857rem 0;
    border-left: ${({ child, isSelected }: MenuTextProps) =>
        child
            ? isSelected
                ? '2px solid var(--text-link-color)'
                : '1px solid var(--text-high-emphasis-color)'
            : 'none'};
    padding-left: ${({ child }: MenuTextProps) => (child ? '1.14rem' : '0')};
    margin: 0;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

interface MenuItemProps {
    url: string;
    name: string;
    isChild?: boolean;
    isSelected?: boolean;
    isExpanded?: boolean;
    onClick?(): void;
}

const MenuItem = ({
    url,
    isChild,
    name,
    isSelected,
    isExpanded,
    onClick,
    children,
}: PropsWithChildren<MenuItemProps>): ReactElement => {
    return (
        <>
            <MenuLink selected={isChild ? isSelected : isSelected} expanded={isExpanded} to={url} onClickFunc={onClick}>
                <MenuText child={isChild} isSelected={isSelected}>
                    {name}
                </MenuText>
                {children ? <SvgIcon name="CaretDown" /> : null}
            </MenuLink>
            {isExpanded ? children : null}
        </>
    );
};

export default MenuItem;
