import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Checkbox, GhostPrimaryButton, InputField, Modal, ModalType, PrimaryButton } from '../../../../components';
import { useGetWifiPolicy } from '../../apiQueries/useGetWifiPolicy';
import { useUpdateWifiPassword } from '../../apiQueries/useUpdateWifiPassword';

const StyledModal = styled(Modal)`
    #modalContentContainer {
        overflow-y: auto;
    }
`;
const TermsContainer = styled.div`
    display: flex;
`;
const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    > {
        :first-child {
            margin-right: 1.071rem !important;
        }
    }
`;
const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.78rem;
    overflow-y: auto;
`;

const InputContainer = styled.div`
    margin-bottom: 1rem;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-right: 1.071rem;
`;

const StyledText = styled.p`
    font-weight: 500;
    font-size: 1.142rem;
    line-height: 1.714rem;
    letter-spacing: 0.014rem;
`;

const StyledListOfRequirements = styled.ul`
    padding: 0;
    margin-bottom: 2.285rem;
`;

const StyledCheckboxLabel = styled.label``;

const StyledLink = styled.a`
    color: inherit;
    text-decoration: underline;
    text-shadow: none;
`;

type StyledRequirementProps = { isError: boolean };

const StyledRequirement = styled.li`
    ${({ isError }: StyledRequirementProps) => isError && { color: 'darkgrey' }};
    display: flex;
    align-items: center;

    > div {
        background-color: ${({ isError }: StyledRequirementProps) => (isError ? 'darkgrey' : 'var(--success-color)')};
    }
`;

const StyledIndicator = styled.div`
    margin-right: 1.16rem;
    height: 0.571rem;
    width: 0.571rem;
    border-radius: 50%;
`;

const StyledStepContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledSuccessTitle = styled.p`
    color: var(--success-color-dark);
    font-weight: 900;
    font-size: 1.428rem;
    line-height: 2.285rem;
`;

const StyledSuccessText = styled.p`
    font-size: 1.142rem;
    line-height: 1.428rem;
`;

const StyledErrorTitle = styled.p`
    font-weight: 900;
    font-size: 1.428rem;
    line-height: 2.285rem;
`;

const StyledErrorText = styled.p`
    font-weight: 400;
    font-size: 1.142rem;
    line-height: 1.428rem;
`;

const LoaderContainer = styled.div`
    position: relative;
    padding: 8rem;
`;

const PASSWORD_MIN_LENGTH = 8;

const passwordSchema = {
    password: yup
        .string()
        .required('Skriv inn passordet ditt')
        .min(PASSWORD_MIN_LENGTH)

        .test('capitalLetters', 'password must be at least 1 capital letter', (value: string | undefined) =>
            value ? /(?=.*[A-Z])/.test(value) : false,
        )
        .test('smallLetters', 'password must be at least 1 small letter', (value: string | undefined) =>
            value ? /(?=.*[a-z])/.test(value) : false,
        )
        .test('digits', 'password must be at least 1 digit', (value: string | undefined) =>
            value ? /\d/.test(value) : false,
        ),
};

const termsSchema = {
    terms: yup.boolean().oneOf([true]),
};

type Form = {
    password: string;
    terms: boolean;
};

interface WifiPasswordModalProps {
    open: boolean;
    onClose(): void;
    modalType?: WifiPasswordModalType;
    onSubmit?(): void;
}

enum WifiPasswordModalSteps {
    PasswordStep = 0,
    SuccessStep = 1,
}

export enum WifiPasswordModalType {
    SetPassword = 'setPassword',
    ChangePassword = 'changePassword',
}

const WifiPasswordModal = ({
    open,
    onClose,
    modalType = WifiPasswordModalType.SetPassword,
    onSubmit,
}: WifiPasswordModalProps): ReactElement => {
    const [currentStep, setCurrentStep] = useState<WifiPasswordModalSteps>(WifiPasswordModalSteps.PasswordStep);
    const { data: policyStatus, isLoading: policyIsLoading } = useGetWifiPolicy({
        enabled: modalType === WifiPasswordModalType.SetPassword,
    });
    const initialValues = {
        password: '',
        terms: false,
    };

    const [
        updatePassword,
        {
            isLoading: isUpdateWifiPasswordLoading,
            error: updateWifiPasswordError,
            isSuccess: isUpdateWifiPasswordSuccess,
            isError: isUpdateWifiPasswordError,
            reset: resetUpdateWifiPassword,
        },
    ] = useUpdateWifiPassword();

    useEffect(() => {
        isUpdateWifiPasswordSuccess && setCurrentStep(WifiPasswordModalSteps.SuccessStep);
    }, [isUpdateWifiPasswordSuccess]);

    const schema =
        modalType === WifiPasswordModalType.ChangePassword
            ? yup.object(passwordSchema)
            : yup.object({ ...passwordSchema, ...termsSchema });

    const {
        formState: { errors, isValid },
        control,
        getValues,
        handleSubmit,
    } = useForm<Form>({
        mode: 'all',
        criteriaMode: 'all',
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const handleCloseModal = () => {
        onClose();
    };

    const handleTryAgain = () => {
        resetUpdateWifiPassword();
    };

    const checkPasswordError = (fieldKey: string) => {
        return !getValues().password.length || !!errors.password?.types?.[fieldKey];
    };
    const submitPassword = async ({ password }: Form) => {
        if (isValid) {
            await updatePassword(password);
            onSubmit && onSubmit();
        }
    };
    const { t } = useTranslation('wifiAtWork');
    const { t: tCommon } = useTranslation('common');
    const listOfRequirements = (
        <StyledListOfRequirements>
            <StyledRequirement isError={checkPasswordError('capitalLetters')}>
                <StyledIndicator />
                {t('capitalLetter')}
            </StyledRequirement>
            <StyledRequirement isError={checkPasswordError('smallLetters')}>
                <StyledIndicator />
                {t('smallLetter')}
            </StyledRequirement>
            <StyledRequirement isError={checkPasswordError('digits')}>
                <StyledIndicator />
                {t('oneNumber')}
            </StyledRequirement>
            <StyledRequirement isError={checkPasswordError('min')}>
                <StyledIndicator />
                {t('eightCharacters')}
            </StyledRequirement>
        </StyledListOfRequirements>
    );

    const labelCustom = (
        <StyledCheckboxLabel htmlFor="terms">
            {t('readUnderstoodAccept')}
            <StyledLink href={policyStatus?.url} target="_blank">
                {t('termsAndConditions')}
            </StyledLink>
        </StyledCheckboxLabel>
    );

    const getCurrentStepContent = () => {
        const errorMessage = t('passwordDoesNotMeetAllRequirements');

        if (updateWifiPasswordError) {
            return (
                <StyledStepContainer>
                    <picture>
                        <source srcSet="./empty-icon.webp" type="image/webp" />
                        <source srcSet="./empty-icon.png" type="image/png" />
                        <img src="empty-icon.png" alt="Set wifi password error" />
                    </picture>
                    <StyledErrorTitle>{t('passwordWasNotChanged')}</StyledErrorTitle>
                    <StyledErrorText>{t('tryLatterOrContactSupport')}</StyledErrorText>
                </StyledStepContainer>
            );
        }
        if (isUpdateWifiPasswordLoading) {
            return (
                <LoaderContainer>
                    <Dimmer active inverted>
                        <Loader active inverted>
                            {tCommon('load')}
                        </Loader>
                    </Dimmer>
                </LoaderContainer>
            );
        }
        return currentStep === WifiPasswordModalSteps.PasswordStep ? (
            <>
                <StyledText>{t('passwordRequirements')}</StyledText>
                <form key="SetWifiPasswordForm" onSubmit={handleSubmit(submitPassword)}>
                    <InputContainer>
                        <Controller
                            control={control}
                            name="password"
                            render={({ field: { onChange }, field }) => {
                                const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    onChange(event);
                                };
                                return (
                                    <InputField
                                        type="password"
                                        label={t('password')}
                                        required
                                        error={
                                            errors?.password?.type === 'required'
                                                ? errors?.password.message
                                                : errors.password
                                                ? errorMessage
                                                : undefined
                                        }
                                        {...field}
                                        onChange={handleChange}
                                    />
                                );
                            }}
                        />
                    </InputContainer>
                    {listOfRequirements}
                    {modalType === 'setPassword' ? (
                        <TermsContainer>
                            <Controller
                                control={control}
                                name="terms"
                                render={({ field: { onChange, value }, field }) => {
                                    const handleChange = (
                                        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                    ) => {
                                        onChange(event);
                                    };
                                    return (
                                        <Checkbox
                                            label={policyIsLoading ? t('loadingTerms') : labelCustom}
                                            id="terms"
                                            checked={value}
                                            {...field}
                                            onChange={handleChange}
                                            disable={policyIsLoading}
                                        />
                                    );
                                }}
                            />
                        </TermsContainer>
                    ) : null}
                </form>
            </>
        ) : (
            <StyledStepContainer>
                <picture>
                    <source srcSet="./SuccessState.webp" type="image/webp" />
                    <source srcSet="./SuccessState.jpg" type="image/jpeg" />
                    <img src="SuccessState.jpg" alt="Create new ticket success" />
                </picture>
                <StyledSuccessTitle> {t('youJustSetYourPassword')} </StyledSuccessTitle>
                <StyledSuccessText>{t('youCanConnectToWiFiAtWorkWithNewPassword')}</StyledSuccessText>
            </StyledStepContainer>
        );
    };
    const buttons = () => {
        if (isUpdateWifiPasswordError) {
            return (
                <ButtonsContainer>
                    <GhostPrimaryButton onClick={handleCloseModal}>{tCommon('cancelButton')}</GhostPrimaryButton>
                    <StyledPrimaryButton onClick={handleTryAgain}>{tCommon('tryAgain')}</StyledPrimaryButton>
                </ButtonsContainer>
            );
        }
        return currentStep === WifiPasswordModalSteps.PasswordStep ? (
            <ButtonsContainer>
                <GhostPrimaryButton onClick={handleCloseModal}>{tCommon('cancelButton')}</GhostPrimaryButton>

                <StyledPrimaryButton disabled={!isValid} onClick={handleSubmit(submitPassword)}>
                    {tCommon('confirm')}
                </StyledPrimaryButton>
            </ButtonsContainer>
        ) : (
            <ButtonsContainer>
                <StyledPrimaryButton onClick={handleCloseModal}>{tCommon('closeButton')}</StyledPrimaryButton>
            </ButtonsContainer>
        );
    };

    return (
        <StyledModal
            type={ModalType.Small}
            open={open}
            title={modalType === WifiPasswordModalType.SetPassword ? t('setWiFiPassword') : t('changeWiFiPassword')}
            onClose={handleCloseModal}
            bottomContent={buttons()}
            bordered
            hideCloseButton
        >
            <Container>{getCurrentStepContent()}</Container>
        </StyledModal>
    );
};

export default WifiPasswordModal;
