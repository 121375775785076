import styled from 'styled-components';

const Header = styled.h2`
    font-size: 1.286rem;
    line-height: 1.714rem;
    letter-spacing: 0.2px;
`;

const HorizontalRule = styled.hr`
    border: 1px solid #d4d6d7;
    margin: 1.714rem 0;
`;

export { Header, HorizontalRule };
