import React, { FC, SVGProps } from 'react';
const SuccessIconSvg: FC = (props: SVGProps<SVGSVGElement>) => (
    <svg width={156} height={137} fill="none" {...props}>
        <path
            fill="#73CF2A"
            d="M76.313 108.414c28.324 0 51.285-22.961 51.285-51.285 0-28.324-22.961-51.286-51.285-51.286-28.324 0-51.285 22.962-51.285 51.286s22.961 51.285 51.285 51.285Z"
        />
        <path
            stroke="#006729"
            strokeMiterlimit={10}
            strokeWidth={4}
            d="M76.313 102.714c25.176 0 45.586-20.41 45.586-45.585 0-25.177-20.41-45.586-45.586-45.586s-45.586 20.41-45.586 45.586 20.41 45.585 45.586 45.585Z"
        />
        <path
            stroke="#006729"
            strokeMiterlimit={10}
            strokeWidth={4}
            d="M76.313 102.714c25.176 0 45.586-20.41 45.586-45.585 0-25.177-20.41-45.586-45.586-45.586s-45.586 20.41-45.586 45.586 20.41 45.585 45.586 45.585Z"
        />
        <path
            fill="#D2F8B6"
            d="M76.313 101.089c24.279 0 43.961-19.682 43.961-43.96 0-24.279-19.682-43.96-43.96-43.96-24.28 0-43.961 19.681-43.961 43.96 0 24.278 19.682 43.96 43.96 43.96Z"
        />
        <path
            fill="#E9EBEB"
            d="M75.835 131.658c22.79 0 41.266-1.72 41.266-3.841 0-2.121-18.475-3.841-41.266-3.841-22.791 0-41.267 1.72-41.267 3.841 0 2.121 18.476 3.841 41.267 3.841Z"
        />
        <path
            fill="#fff"
            d="M76.314 92.517c19.544 0 35.388-15.844 35.388-35.388 0-19.545-15.844-35.39-35.388-35.39-19.545 0-35.39 15.845-35.39 35.39 0 19.544 15.845 35.388 35.39 35.388Z"
        />
        <path
            stroke="#73CF2A"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={3}
            d="m141.524 47.41 3.462-4.152M14.163 73.57l5.299 1.114M135.391 86.606l-1.804-5.098M13.395 44.739l5.332-.902"
        />
        <path
            fill="#73CF2A"
            d="M14.675 60.056c-.924 0-1.67-.745-1.67-1.67 0-.923.746-1.67 1.659-1.67h.011c.924 0 1.67.747 1.67 1.67 0 .924-.746 1.67-1.67 1.67ZM137.439 62.917c-.924 0-1.67-.746-1.67-1.67 0-.923.746-1.67 1.67-1.67h.011c.924 0 1.67.747 1.67 1.67 0 .924-.757 1.67-1.681 1.67ZM25.44 90.892c-.924 0-1.67-.746-1.67-1.67 0-.924.746-1.67 1.659-1.67h.01c.925 0 1.67.746 1.67 1.67 0 .924-.745 1.67-1.67 1.67ZM5.658 75.185c-.924 0-1.67-.746-1.67-1.67 0-.924.746-1.67 1.659-1.67h.011c.924 0 1.67.746 1.67 1.67 0 .924-.746 1.67-1.67 1.67ZM151.41 78.09c-.924 0-1.67-.746-1.67-1.67 0-.924.746-1.67 1.67-1.67h.011c.924 0 1.669.746 1.669 1.67 0 .924-.756 1.67-1.68 1.67Z"
        />
        <path
            stroke="#D2F8B6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={14}
            d="M98.522 42.913 67.196 74.239 52.034 59.233"
        />
        <path
            stroke="#006729"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={4}
            d="M98.522 42.913 67.196 74.239 52.034 59.233"
        />
    </svg>
);

export default SuccessIconSvg;
