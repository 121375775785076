import { useContext } from 'react';
import { CurrentUserContext } from '../../../Providers';
import { CurrentUserContextValue } from '../../../Providers/CurrentUserProvider/types';

const useCurrentUserContext = (): CurrentUserContextValue => {
    const context = useContext(CurrentUserContext);
    if (!context) throw new Error('Unexpected, no user contexts');
    return context;
};

export default useCurrentUserContext;
