import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const CustomBoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.571rem;
`;

const CustomBoxContent = styled.div`
    border: 1px solid #d4d6d7;
    border-radius: 0.357rem;
    padding: 1.143rem;
`;

const CustomBoxTitle = styled.span`
    font-size: 1.143rem;
    font-weight: 900;
    line-height: 1.714rem;
    color: #263238;
`;

interface CustomBoxProps {
    title?: string;
}

export const CustomBox: FC<PropsWithChildren<CustomBoxProps>> = ({ children, title }) => {
    return (
        <CustomBoxWrapper>
            {title && <CustomBoxTitle>{title}</CustomBoxTitle>}
            <CustomBoxContent>{children}</CustomBoxContent>
        </CustomBoxWrapper>
    );
};
