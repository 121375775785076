import { AxiosError } from 'axios';
import type { InfiniteQueryResult } from 'react-query';
import { useInfiniteQuery } from 'react-query';
import { TicketActivity } from '../../../api/apiTypes/ticketingApiTypes';
import { getTicketActivities } from '../../../api/ticketingApi';

export const TicketActivityCacheKey = 'TicketActivityCacheKey';

export const useGetInfiniteTicketActivities = (
    ticketId: string,
): InfiniteQueryResult<TicketActivity[], Error | AxiosError<string>> => {
    const numberOfTicketActivities = 20;
    return useInfiniteQuery<TicketActivity[], Error | AxiosError<string>>(
        [TicketActivityCacheKey, ticketId],
        async (_, ticketId, page): Promise<TicketActivity[]> => {
            const response = await getTicketActivities(ticketId, page ?? 0, numberOfTicketActivities);
            return response.data;
        },
        {
            getFetchMore: (lastPage, allPages) => {
                if (lastPage.length !== numberOfTicketActivities) {
                    return false;
                }
                return allPages.length;
            },
            staleTime: 1000 * 20,
            refetchInterval: 1000 * 20,
        },
    );
};
