import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexBox, Typography } from '../../../../../components/styleds';
import MediaFileReupdater from '../../../../../components/styleds/mediaFileReupdater';
import UploadAnCoverImageBlock from '../../../../../components/styleds/uploadAnCoverImageBlock';
import UploadMediaModal from '../../../../../components/styleds/uploadMediaModal';
import { useGetUploadDailyMenuImage, useUploadDailyMenuImage } from '../../useDailyMenu';

interface MenuImageEditingProps {
    canteenId: string;
}

const MenuImageEditing = ({ canteenId }: MenuImageEditingProps): ReactElement => {
    const [uploadImage] = useUploadDailyMenuImage(canteenId);
    const [isUploadMediaModal, setIsUploadMediaModal] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [isEditView, setIsEditView] = useState(false);
    const { data: storeImage } = useGetUploadDailyMenuImage(canteenId);
    const { t } = useTranslation('Dashboard');

    const showModalHandler = useCallback(
        (val: boolean, isEditView = false) =>
            () => {
                setIsEditView(isEditView);
                setIsUploadMediaModal(val);
            },
        [],
    );

    return (
        <FlexBox dir={'column'} gap={'1rem'}>
            <Typography fontSize={'1.1rem'} lineHeight={'1.71rem'}>
                {t('dailyMenu.menuImageEditingText')}
            </Typography>
            {!!storeImage && (
                <MediaFileReupdater showModalHandler={showModalHandler(true, true)} file={file} imageUrl={storeImage} />
            )}
            {!file && !isUploadMediaModal && !storeImage && (
                <UploadAnCoverImageBlock showModalHandler={showModalHandler(true)} />
            )}
            <UploadMediaModal
                isEditView={isEditView}
                hideModalCallback={showModalHandler(false)}
                isOpened={isUploadMediaModal}
                setFile={setFile}
                file={file}
                uploadImageToStorage={uploadImage}
            />
        </FlexBox>
    );
};

export default MenuImageEditing;
