import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import type { CheckboxProps } from 'semantic-ui-react';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import { DeliveryMethod } from '../types';

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    z-index: 0;
`;

type DeliveryOptionPhraseProps = { checked: boolean };
const DeliveryOptionPhrase = styled.p<DeliveryOptionPhraseProps>`
    && {
        margin: 0.5rem;
        font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-weight: ${({ checked }: DeliveryOptionPhraseProps) => (checked ? 'bold' : 400)};
        line-height: 1.2rem;
        font-size: 1rem;
        color: #475156;
        white-space: nowrap;
    }
`;

const PrimaryToggler = styled(Checkbox)`
    &&&&& input ~ label:before {
        background-color: var(--primary-color) !important;
    }
    &&&&& input ~ label:after {
        background-color: var(--primary-on-color);
        width: 1.2rem;
        height: 1.2rem;
        top: 0.2rem;
        left: 0.2rem;
        box-shadow: none;
    }
    &&&&& input:checked ~ label:after {
        left: 2.1rem;
        box-shadow: none;
    }

    &.read-only {
        opacity: 0.6;
    }
`;

interface DeliverySelectorProps {
    deliveryMethod: DeliveryMethod;
    handleDeliveryMethodChanged: (deliveryMethod: DeliveryMethod) => void;
    readOnly?: boolean;
}

const DeliverySelector: React.FC<React.PropsWithChildren<DeliverySelectorProps>> = ({
    deliveryMethod,
    handleDeliveryMethodChanged,
    readOnly = false,
}: DeliverySelectorProps) => {
    const handleChange = (e: SyntheticEvent, { checked }: CheckboxProps) => {
        if (checked) {
            handleDeliveryMethodChanged('levering');
        } else {
            handleDeliveryMethodChanged('hente');
        }
    };
    const { t } = useTranslation('Food', { keyPrefix: 'commonFood' });
    return (
        <FlexContainer>
            <DeliveryOptionPhrase checked={deliveryMethod === 'hente'}>{t('pickup')}</DeliveryOptionPhrase>
            <PrimaryToggler toggle checked={deliveryMethod !== 'hente'} onChange={handleChange} readOnly={readOnly} />
            <DeliveryOptionPhrase checked={deliveryMethod !== 'hente'}>{t('delivery')}</DeliveryOptionPhrase>
        </FlexContainer>
    );
};

export default DeliverySelector;
