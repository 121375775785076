import type { AxiosError, AxiosResponse } from 'axios';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import {
    getCompanyAccessControls,
    getAllAccessControls,
    updatedUserAccess,
    createNewPin,
    updateAccessGroupsInCompany,
    getAllAccessGroups,
    createUserMobileKey,
    reassignUserMobileKeyByUser,
    reassignUserMobileKey,
    createUserMobileKeyByUser,
    deleteUserMobileKeyByUser,
    deleteUserMobileKey,
    getAvailableAccessSystemsForUserOwn,
    getAdminAvailableAccessSystemsForUser,
    getAllBookableObjectAccessList,
    getPincodeIsEnabled,
} from '../../../../../../api/accessControlApi';
import {
    AccessNode,
    AccessControlFormData,
    MobileKeyActivationAvailability,
    AccessSystemNode,
} from '../../AccessControl/utils';
import { orgCacheKey } from './useContactInfo';

const accessCacheKey = 'accessCacheKey';
const orgAccessControl = 'orgAccessControl';
const DigitalMobileKeyCacheKey = 'DigitalMobileKeyCacheKey';
const DigitalMobileKeyCacheKeyByUser = 'DigitalMobileKeyCacheKeyByUser';
export const roomAccessControlKey = 'roomAccessControlKey';
const PinCodeIsEnabledCacheKey = 'PinCodeIsEnabledCacheKey';

export type CompanyAdminUserAccess = {
    id: string;
    name: string;
    isActive: boolean;
    canUserUpdateGroup: boolean;
};

interface UpdateUserAccessProps {
    updatedArray: AccessControlFormData[];
    currentUserId: string;
    userId: string;
    companyId: string;
}

export type CompanyAccessList = {
    id: string;
    name: string;
};

export type AllAccessList = {
    description: string;
    domain?: AllAccessList;
    accessSystem?: AllAccessList;
    externalId: string;
    id: string;
    name: string;
};

export type AccessGroupType = {
    id: string;
    name: string;
    groups: string[] | [];
};

export const useGetCompanyAccessList = (
    isAccessControlAvailable: boolean,
    companyId?: string,
): QueryResult<CompanyAccessList[], AxiosError<string>> => {
    return useQuery(
        [orgAccessControl, companyId, isAccessControlAvailable],
        async () => {
            const { data } = await getCompanyAccessControls(companyId as string);
            return data;
        },
        { enabled: companyId && isAccessControlAvailable },
    );
};

export const useGetAllAccessGroups = (
    userHaveAccess: boolean,
    companyId?: string,
): QueryResult<AllAccessList[], AxiosError<string>> => {
    return useQuery(
        [orgAccessControl, companyId, userHaveAccess],

        async () => {
            const { data } = await getAllAccessGroups();

            return data;
        },
        { enabled: companyId && userHaveAccess },
    );
};

export const useUpdateAccessGroupsInCompany = (): MutationResultPair<
    AxiosResponse<CompanyAccessList[]>,
    Error | AxiosError<string>,
    { accessGroups: AccessGroupType },
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        async ({ accessGroups }) => {
            return await updateAccessGroupsInCompany({ accessGroups });
        },
        {
            onSuccess: () => {
                cache.invalidateQueries([accessCacheKey], { exact: true });
            },
        },
    );
};

export const useUpdateUserAccessInfo = (): MutationResultPair<
    AxiosResponse<AccessNode[]>,
    Error | AxiosError<string>,
    { updatedArray: AccessControlFormData[]; currentUserId: string; userId: string; companyId: string },
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        async ({ updatedArray, currentUserId, userId }: UpdateUserAccessProps) => {
            return await updatedUserAccess(updatedArray, currentUserId, userId);
        },
        {
            onSuccess: (_, { currentUserId, userId, companyId }) => {
                cache.invalidateQueries([accessCacheKey, currentUserId], { exact: true });
                cache.invalidateQueries([accessCacheKey, currentUserId, userId]);
                cache.invalidateQueries([orgCacheKey, companyId]);
            },
        },
    );
};

export const useGetAllAccessList = (
    isAccessModuleAvailable: boolean,
): QueryResult<AccessNode[], AxiosError<string>> => {
    return useQuery(
        [orgAccessControl],
        async () => {
            const { data } = await getAllAccessControls();
            return data;
        },
        {
            enabled: isAccessModuleAvailable,
            staleTime: Infinity,
        },
    );
};
export const useGetAllBookableObjectAccessList = (
    isEnabled: boolean,
    roomId: string,
): QueryResult<AccessNode[], AxiosError<string>> => {
    return useQuery(
        [roomAccessControlKey, roomId, isEnabled],
        async () => {
            const { data } = await getAllBookableObjectAccessList(roomId);
            return data;
        },
        {
            enabled: isEnabled,
            staleTime: Infinity,
        },
    );
};

export const useUpdatePIN = (): MutationResultPair<
    AxiosResponse<string>,
    string | AxiosError<string> | Error,
    string,
    never
> => {
    return useMutation((pin) => {
        return createNewPin(pin);
    });
};

export const useGetMobileKeyActivationAvailabilities = (
    userId: string,
    actualUserAccessGroups: AccessNode[] | undefined,
): QueryResult<AccessSystemNode[], AxiosError<string>> => {
    return useQuery(
        [DigitalMobileKeyCacheKey, userId, actualUserAccessGroups],
        async () => {
            const { data } = await getAdminAvailableAccessSystemsForUser(userId);
            return data;
        },
        {
            enabled: !!userId,
        },
    );
};

export const useGetMobileKeyActivationAvailabilitiesByUser = (): QueryResult<
    MobileKeyActivationAvailability[],
    AxiosError<string>
> => {
    return useQuery([DigitalMobileKeyCacheKeyByUser], async () => {
        const { data } = await getAvailableAccessSystemsForUserOwn();
        return data;
    });
};

export const useCreateDigitalMobileKey = (): MutationResultPair<
    AxiosResponse,
    string | AxiosError<string> | Error,
    [string, string],
    never
> => {
    const cache = useQueryCache();
    return useMutation(([accessSystemId, userGuid]) => createUserMobileKey(accessSystemId, userGuid), {
        onSuccess: (_, [_access, userGuid]) => {
            cache.invalidateQueries([DigitalMobileKeyCacheKey, userGuid]);
        },
    });
};

export const useCreateDigitalMobileKeyByUser = (): MutationResultPair<
    AxiosResponse,
    string | AxiosError<string> | Error,
    [string],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([accessSystemId]) => createUserMobileKeyByUser(accessSystemId),

        {
            onSuccess: () => {
                cache.invalidateQueries([DigitalMobileKeyCacheKeyByUser]);
            },
        },
    );
};

export const useReassignDigitalMobileKey = (): MutationResultPair<
    AxiosResponse,
    string | AxiosError<string> | Error,
    [string, string],
    never
> => {
    const cache = useQueryCache();
    return useMutation(([accessSystemId, userId]) => reassignUserMobileKey(accessSystemId, userId), {
        onSuccess: (_, [_access, userGuid]) => {
            cache.invalidateQueries([DigitalMobileKeyCacheKey, userGuid]);
        },
    });
};

export const useReassignDigitalMobileKeyByUser = (): MutationResultPair<
    AxiosResponse,
    string | AxiosError<string> | Error,
    [string],
    never
> => {
    const cache = useQueryCache();
    return useMutation(([accessSystemId]) => reassignUserMobileKeyByUser(accessSystemId), {
        onSuccess: () => {
            cache.invalidateQueries([DigitalMobileKeyCacheKeyByUser]);
        },
    });
};

export const useDeleteDigitalMobileKey = (): MutationResultPair<
    AxiosResponse,
    string | AxiosError<string> | Error,
    [string, string],
    never
> => {
    const cache = useQueryCache();
    return useMutation(([accessSystemId, userId]) => deleteUserMobileKey(accessSystemId, userId), {
        onSuccess: (_, [_access, userGuid]) => {
            cache.invalidateQueries([DigitalMobileKeyCacheKey, userGuid]);
        },
    });
};

export const useDeleteDigitalMobileKeyByUser = (): MutationResultPair<
    AxiosResponse,
    string | AxiosError<string> | Error,
    [string],
    never
> => {
    const cache = useQueryCache();
    return useMutation(([accessSystemId]) => deleteUserMobileKeyByUser(accessSystemId), {
        onSuccess: () => {
            cache.invalidateQueries([DigitalMobileKeyCacheKeyByUser]);
        },
    });
};

export const useGetPincodeIsEnabled = (): QueryResult<boolean, AxiosError<string>> => {
    return useQuery({
        queryKey: [PinCodeIsEnabledCacheKey],
        queryFn: async () => {
            const { data } = await getPincodeIsEnabled();
            return data;
        },
    });
};
