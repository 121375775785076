import { LanguagesEnum } from './language.enum';

export const getBrowserLanguage = (initialUsersLanguage: string): LanguagesEnum => {
    // Substring below because in specification we have several languages which use patter "en-XX"
    const formattedUsersLanguage = initialUsersLanguage.substring(0, 2);
    if (formattedUsersLanguage === 'en') return LanguagesEnum.en;
    if (formattedUsersLanguage === 'nb' || formattedUsersLanguage === 'no' || formattedUsersLanguage === 'nn')
        return LanguagesEnum.no;
    // Default language - English
    return LanguagesEnum.en;
};
