import { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SvgIcon } from '../../../../components';
import useResizeWindow from '../../../../utility/hooks/useResizeWindow';

const PublishButton = styled.button`
    position: relative;
    background: none;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;

    svg {
        width: 1.571rem;
        height: 1.571rem;
    }

    :focus,
    :focus-visible {
        outline: 1px solid var(--primary-color);
    }
    :focus:not(:focus-visible) {
        outline: 0;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.714rem;
`;

const Header = styled.h4`
    font-size: 1.429rem;
    line-height: 2.286rem;
    margin: 0;
    overflow-x: hidden;
`;
type PublishedContainerProps = {
    isTabletOrMobileView: boolean;
};

const PublishedContainer = styled.div`
    display: flex;
    align-items: 'center';
    margin-left: 1rem;
    line-height: 2.286rem;
    flex-direction: ${({ isTabletOrMobileView }: PublishedContainerProps) =>
        isTabletOrMobileView ? 'column-reverse' : 'row'};
`;

const PublishedText = styled.span`
    margin-left: 0.75rem;
    font-weight: bold;
    font-size: 1.143rem;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    color: var(--text-high-emphasis-color);
`;

const IntegrationTag = styled.span`
    background-color: #e9ebeb;
    border-radius: 30px;
    text-align: center;
    padding: 0 0.571rem;
    font-weight: 600;
    font-size: 0.857rem;
    white-space: nowrap;
    height: fit-content;
    align-self: center;
`;

interface TitleBarProps {
    name: string;
    isPublished: boolean;
    integrationName?: string;
    className?: string;
    handleOnClickPublish: (isPublished: boolean) => void;
    isPublishButtonHidden?: boolean;
}

const TitleBar = ({
    name,
    isPublished,
    integrationName,
    className,
    handleOnClickPublish,
    isPublishButtonHidden = false,
}: TitleBarProps): ReactElement => {
    const DESKTOP_BREAKPOINT = 550;

    const handleOnButtonClick = (evt: React.MouseEvent<HTMLElement>) => {
        handleOnClickPublish(!isPublished);
        evt.preventDefault();
    };
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });

    const conainerRef = useRef<HTMLDivElement>(null);
    const containerWidth = useResizeWindow(conainerRef);
    const isTabletOrMobileView = containerWidth < DESKTOP_BREAKPOINT;

    return (
        <Container className={className} ref={conainerRef}>
            <Header>{name}</Header>
            <PublishedContainer isTabletOrMobileView={isTabletOrMobileView}>
                {integrationName && (
                    <IntegrationTag>
                        {t('FDVActivated')} {integrationName}
                    </IntegrationTag>
                )}
                {!isPublishButtonHidden &&
                    (isPublished ? (
                        <PublishButton onClick={handleOnButtonClick} aria-label={t('published')}>
                            <SvgIcon name="VisibilityOn" />
                            <PublishedText>{t('published')}</PublishedText>
                        </PublishButton>
                    ) : (
                        <PublishButton onClick={handleOnButtonClick} aria-label={t('hidden')}>
                            <SvgIcon name="VisibilityOff" />
                            <PublishedText>{t('hidden')}</PublishedText>
                        </PublishButton>
                    ))}
            </PublishedContainer>
        </Container>
    );
};

export default TitleBar;
