import orderReducer, { OrderState, OrderAction } from '../../orderReducer';

type initialState = { status: 'empty' } & OrderState;
type receivedInitialValues = { status: 'receivedInitValues' } & OrderState;

export type ShoppingCartState = initialState | receivedInitialValues;

const reducer = (state: ShoppingCartState, action: OrderAction): ShoppingCartState => {
    if (state.status === 'empty' && action.type === 'CHANGE_DELIVERY_DATETIME') {
        const validStateDateTime = state.deliveryDateTime && state.deliveryDateTime > action.payload.deliveryDateTime;
        return {
            status: 'receivedInitValues',
            // The assumption below is that the first dateTime we receive is the earliest possible deliveryTime, so if state is before than you can't order anyways
            deliveryDateTime: validStateDateTime ? state.deliveryDateTime : action.payload.deliveryDateTime,
            deliveryMethod: state.deliveryMethod ?? 'hente',
            foodLines: state.foodLines || [],
            attachedMeeting: validStateDateTime ? state.attachedMeeting : null,
        };
    } else if (state.status === 'empty') {
        console.error('Unexpected state');
    }

    return { ...state, ...orderReducer(state as receivedInitialValues, action) };
};

export default reducer;
