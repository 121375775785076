import { RefObject, useEffect } from 'react';

const useOnMouseClickOutsideElement = (
    ref: RefObject<HTMLElement>,
    handler: (event: MouseEvent | TouchEvent) => void,
    disabled = false,
): void => {
    useEffect(() => {
        if (!disabled) {
            const listener = (event: MouseEvent | TouchEvent) => {
                if (!ref.current || ref.current.contains(event.target as Node)) return;
                handler(event);
            };
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        }
    }, [ref, handler, disabled]);
};

export default useOnMouseClickOutsideElement;
