import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Category, TicketType } from '../../../../../../../../api/apiTypes/ticketingApiTypes';
import { PrimaryButton, GhostPrimaryButton, SvgIcon } from '../../../../../../../../components';
import { useIsMobile } from '../../../../../../../../utility/hooks/useIsMobile';
import { Header, Paragraph } from '../../../../../../common/styles';
import CaseCategoryCard from '../CaseCategoryCard';
import ChangeCategoryOrderModal from './components/ChangeCategoryOrderModal';
import TicketCategoryModal from './components/TicketCategoryModal';

const Container = styled.div`
    width: 100%;
`;

const NewCategoryButton = styled(PrimaryButton)`
    &&& {
        margin: 1.143rem 0.571rem 1.143rem 0;
    }
`;

const NewCategoryButtonText = styled.span`
    padding-left: 0.667rem;
`;

const ChangeCategoryOrderButton = styled(GhostPrimaryButton)`
    &&& {
        margin: 1.143rem 0;
    }
`;

const ChangeCategoryOrderButtonText = styled.span``;

type CaseCategoryCardsProps = { isMobile: boolean };
const CaseCategoryCards = styled.div`
    display: flex;
    overflow-x: auto;
    flex-wrap: ${({ isMobile }: CaseCategoryCardsProps) => (isMobile ? 'nowrap' : 'wrap')};
    padding: 0.857rem 0;

    scrollbar-width: 0;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    ::-webkit-scrollbar {
        display: none; /* chrome and safari */
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
`;
interface TicketCategoryProps {
    type: TicketType;
}

const TicketCategory = ({ type }: TicketCategoryProps): ReactElement => {
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [isChangeCategoryOrderModalOpen, setIsChangeCategoryOrderModalOpen] = useState(false);
    const isMobile = useIsMobile();
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const handleModalClose = () => setIsCategoryModalOpen(false);
    const handleAddNewCategory = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsCategoryModalOpen(true);
    };

    return (
        <Container>
            <Header>{t('categoryHeader')}</Header>
            <ButtonsContainer>
                <NewCategoryButton onClick={handleAddNewCategory}>
                    <SvgIcon name="Plus" />
                    <NewCategoryButtonText>{t('newCategoryButton')}</NewCategoryButtonText>
                </NewCategoryButton>
                <ChangeCategoryOrderButton onClick={setIsChangeCategoryOrderModalOpen}>
                    <ChangeCategoryOrderButtonText>{t('changeOrder')}</ChangeCategoryOrderButtonText>
                </ChangeCategoryOrderButton>
            </ButtonsContainer>
            {isChangeCategoryOrderModalOpen && type.categories.length > 1 && (
                <ChangeCategoryOrderModal
                    typeId={type.id}
                    categories={type.categories}
                    onClose={() => setIsChangeCategoryOrderModalOpen(false)}
                />
            )}
            {isCategoryModalOpen && (
                <TicketCategoryModal open={isCategoryModalOpen} onClose={handleModalClose} type={type} />
            )}
            <Paragraph>{t('ticketCategoryDescription')}</Paragraph>
            <CaseCategoryCards isMobile={isMobile}>
                {type.categories.map((category: Category) => (
                    <CaseCategoryCard category={category} key={category.id} typeId={type?.id} />
                ))}
            </CaseCategoryCards>
        </Container>
    );
};

export default TicketCategory;
