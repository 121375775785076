import { ChangeEvent, ReactElement, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { VehicleDto, VehicleFuelType, VehicleModel, VehicleType } from '../../../../api/apiTypes/portalApiTypes';
import {
    Dropdown,
    FailureDisplay,
    GhostPrimaryButton,
    InputField,
    Modal,
    ModalType,
    PrimaryButton,
    SuccessDisplay,
} from '../../../../components';
import { useCreateVehicle, useDeleteVehicle, useUpdateVehicle } from '../../../../utility/hooks/useVehicles';
import { VehicleControlActions } from '../Profile';

const StyledModal = styled(Modal)`
    #modalContentContainer {
        overflow-y: auto;
    }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.78rem;
    overflow-y: auto;
`;
const InputContainer = styled.div`
    margin-bottom: 0.643rem;
`;
const Header = styled.h3`
    font-size: 1.142rem;
    line-height: 1.428rem;
`;
const StyledDropdown = styled(Dropdown)`
    width: 100%;

    button {
        height: 2.857rem;
        padding: 0.571rem 1.143rem;
    }
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
`;

const Wrapper = styled.div`
    text-align: center;
`;
interface VehiclesModalProps {
    open: false | VehicleControlActions;
    onClose(): void;
    modalType: VehicleControlActions;
    vehicleData?: VehicleDto;
}

type Form = {
    licensePlate: string;
    vehicleType?: string;
    vehicleFuelType?: string;
};

const VehiclesModal = ({ open, onClose, modalType, vehicleData }: VehiclesModalProps): ReactElement => {
    const { t } = useTranslation('myProfile');
    const { t: tCommon } = useTranslation('common');

    const schema = yup.object().shape({
        licensePlate: yup
            .string()
            .required()
            .matches(/^[A-Z0-9]{1,7}$/, t('fieldNotValid')),
        vehicleFuelType: yup.string().required(t('fieldNotValid')),
        vehicleType: yup.string().required(t('fieldNotValid')),
    });

    const handleCloseModal = () => {
        onClose();
    };

    const {
        handleSubmit,
        control,
        getValues,
        formState: { errors },
    } = useForm<Form>({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            licensePlate: vehicleData?.licensePlate,
            vehicleFuelType: vehicleData && VehicleFuelType[vehicleData.fuelType],
            vehicleType: vehicleData && VehicleType[vehicleData.type],
        },
    });

    const vehicleTypes = Object.keys(VehicleType).filter((v) => isNaN(Number(v)));
    const vehicleFuelTypes = Object.keys(VehicleFuelType).filter((v) => isNaN(Number(v)));

    const vehicleTypeOptions = useMemo(() => {
        return vehicleTypes.map((item) => ({ value: item, id: item }));
    }, [vehicleTypes]);

    const vehicleFuelTypesOptions = useMemo(() => {
        return vehicleFuelTypes.map((item) => ({ value: item, id: item }));
    }, [vehicleFuelTypes]);

    const [
        createVehicle,
        { isLoading: isCreateVehicleLoading, isSuccess: isVehicleSuccessfulAdded, isError: isErrorToAddedVehicle },
    ] = useCreateVehicle();
    const [
        updateVehicle,
        { isLoading: isUpdateVehicleLoading, isSuccess: isVehicleSuccessfulUpdated, isError: isErrorToUpdateVehicle },
    ] = useUpdateVehicle();
    const [
        deleteVehicle,
        { isLoading: isDeleteVehicleLoading, isSuccess: isVehicleSuccessfulDeleted, isError: isErrorToDeletedVehicle },
    ] = useDeleteVehicle();

    const isError = isErrorToAddedVehicle || isErrorToDeletedVehicle || isErrorToUpdateVehicle;
    const isSuccess = isVehicleSuccessfulAdded || isVehicleSuccessfulDeleted || isVehicleSuccessfulUpdated;
    const isLoading = isCreateVehicleLoading || isUpdateVehicleLoading || isDeleteVehicleLoading;
    const manageVehicle = () => {
        const { licensePlate, vehicleFuelType, vehicleType } = getValues();

        if (vehicleType && licensePlate && vehicleFuelType) {
            const vehicleInfo: VehicleModel = {
                licensePlate: licensePlate,
                fuelType: VehicleFuelType[vehicleFuelType as keyof typeof VehicleFuelType],
                type: VehicleType[vehicleType as keyof typeof VehicleType],
            };
            switch (modalType) {
                case VehicleControlActions.Create:
                    return createVehicle(vehicleInfo);
                case VehicleControlActions.Edit:
                    return vehicleData?.id && updateVehicle([vehicleInfo, vehicleData?.id]);
            }
        }
    };

    const onDeleteVehicle = () => {
        vehicleData && deleteVehicle(vehicleData.id);
    };
    const title = () => {
        switch (modalType) {
            case VehicleControlActions.Create:
                return t('addVehicleModalTitle');
            case VehicleControlActions.Edit:
                return t('vehicleDetailsModalTitle');
            default:
                return t('removeVehicleModalTitle');
        }
    };

    const header = () => {
        switch (modalType) {
            case VehicleControlActions.Create:
                return t('addVehicleModalDescription');
            case VehicleControlActions.Edit:
                return t('vehicleDetailsModalTitle');
            default:
                return t('removeVehicleModalHeader');
        }
    };

    const successMessage = () => {
        switch (modalType) {
            case VehicleControlActions.Create:
                return t('vehicleSuccessfulAddedMessage');
            case VehicleControlActions.Edit:
                return t('vehicleSuccessfulUpdatedMessage');
            default:
                return t('vehicleSuccessfulDeletedMessage');
        }
    };

    const getActionButtons = () => {
        if (isSuccess || isError) {
            return (
                <ActionContainer>
                    <PrimaryButton onClick={handleCloseModal}>{tCommon('closeButton')}</PrimaryButton>
                </ActionContainer>
            );
        }

        let primaryButtonAction;
        let primaryButtonText;

        if (modalType === VehicleControlActions.Create) {
            primaryButtonAction = handleSubmit(manageVehicle);
            primaryButtonText = t('addVehicleButtonText');
        } else if (modalType === VehicleControlActions.Edit) {
            primaryButtonAction = handleSubmit(manageVehicle);
            primaryButtonText = tCommon('saveButton');
        } else {
            primaryButtonAction = onDeleteVehicle;
            primaryButtonText = t('removeVehicleButtonText');
        }

        return (
            <ActionContainer>
                <GhostPrimaryButton onClick={handleCloseModal}>{tCommon('cancelButton')}</GhostPrimaryButton>
                <PrimaryButton loading={isLoading} onClick={primaryButtonAction}>
                    {primaryButtonText}
                </PrimaryButton>
            </ActionContainer>
        );
    };

    return (
        <StyledModal
            type={ModalType.Small}
            open={open as boolean}
            title={title()}
            onClose={handleCloseModal}
            bordered
            hideCloseButton
            bottomContent={getActionButtons()}
        >
            <Container>
                {isSuccess && (
                    <Wrapper>
                        <SuccessDisplay alt={successMessage()} header={successMessage()} />
                    </Wrapper>
                )}
                {isError && (
                    <Wrapper>
                        <FailureDisplay header={tCommon('somethingWentWrong')} alt={tCommon('somethingWentWrong')} />
                    </Wrapper>
                )}
                {!isError && !isSuccess && (
                    <>
                        <Header>{header()}</Header>
                        {modalType !== VehicleControlActions.Remove && (
                            <>
                                <InputContainer>
                                    <Controller
                                        name="vehicleType"
                                        control={control}
                                        render={({ field: { onChange }, field }) => {
                                            const handleChange = (value: string) => {
                                                onChange(value);
                                            };
                                            return (
                                                <StyledDropdown
                                                    label={t('selectVehicleTypeLabelText')}
                                                    placeholder={t('selectTypePlaceholder')}
                                                    items={vehicleTypeOptions}
                                                    required
                                                    error={errors?.vehicleType?.message}
                                                    {...field}
                                                    onChange={handleChange}
                                                />
                                            );
                                        }}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <Controller
                                        name="vehicleFuelType"
                                        key="vehicleFuelType"
                                        control={control}
                                        render={({ field: { onChange }, field }) => {
                                            const handleChange = (value: string) => {
                                                onChange(value);
                                            };
                                            return (
                                                <StyledDropdown
                                                    label={t('selectFuelTypeLabelText')}
                                                    placeholder={t('selectTypePlaceholder')}
                                                    items={vehicleFuelTypesOptions}
                                                    required
                                                    {...field}
                                                    onChange={handleChange}
                                                    error={errors?.vehicleFuelType?.message}
                                                />
                                            );
                                        }}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <Controller
                                        name="licensePlate"
                                        key="licensePlate"
                                        control={control}
                                        render={({ field: { onChange }, field }) => {
                                            const handleChange = (
                                                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                            ) => {
                                                onChange(event.target.value.toUpperCase());
                                            };
                                            return (
                                                <InputField
                                                    placeholder={t('licensePlatePlaceholder')}
                                                    label={t('enterLicensePlateLabelText')}
                                                    required
                                                    type="text"
                                                    error={errors?.licensePlate?.message}
                                                    {...field}
                                                    onChange={handleChange}
                                                />
                                            );
                                        }}
                                    />
                                </InputContainer>
                            </>
                        )}
                    </>
                )}
            </Container>
        </StyledModal>
    );
};

export default VehiclesModal;
