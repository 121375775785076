import { Dispatch, ReactElement } from 'react';

export enum SidesheetActionNames {
    OpenSidesheet = 'OPEN_SIDESHEET',
    CloseSidesheet = 'CLOSE_SIDESHEET',
    SetPromptOnClose = 'SET_PROMPT_ON_CLOSE',
}

export type SidesheetContextAction =
    | { type: SidesheetActionNames.OpenSidesheet; payload: ComponentState }
    | { type: SidesheetActionNames.CloseSidesheet }
    | { type: SidesheetActionNames.SetPromptOnClose; payload: boolean };

export interface ComponentState {
    component: ReactElement;
    hideCloseIcon?: boolean;
}

export interface SidesheetState {
    componentState: ComponentState | null;
    isOpen: boolean;
    promptOnClose: boolean;
}

export type SidesheetContextType = {
    state: SidesheetState;
    dispatch: Dispatch<SidesheetContextAction>;
};
