import { ReactElement, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Loader } from '../../../../../../common';
import LoaderContainer from '../../../../../../common/Loader/LoaderContainer';
import RoutePromptModal from '../../../../../../common/RoutePromptModal';
import { TabletPortraitViewBreakpoint } from '../../../../../../common/ScreenSizeBreakPoints';
import {
    FormLabel,
    FullPageLoader,
    GhostPrimaryButton,
    InputField,
    Modal,
    ModalType,
    PrimaryButton,
    SvgIcon,
} from '../../../../../../components';
import ErrorDisplay from '../../../../../../components/error/ErrorDisplay';
import { NumberInput } from '../../../../../../components/input/NumberInput/NumberInput';
import { InputFieldPropType } from '../../../../../../components/input/TextInput/inputField';
import { OptionType } from '../../../../../../components/select/searchSelect/types';
import StyledSelect from '../../../../../../components/select/StyledSelect';
import { useNavigation } from '../../../../../../utility';
import CreateNewCategoryModal from '../../../CreateNewCategoryModal';
import {
    DangerPrimaryButton,
    FormColumn,
    FormControlsWrapper,
    FormElementContainer,
    FormGroup,
    FormGroupDescription,
    FormGroupsWrapper,
    FormGroupTitle,
    ModalBody,
    ModalBottom,
    ModalContent,
    ProductFormHeader,
    ProductFormHeaderAction,
    ProductFormHeaderActions,
    ProductFormLayoutWrapper,
    StyledDropdown,
    StyledGhostPrimaryButton,
    StyledHeaderTitle,
} from './StyledProductFormLayout';
import { useProductFormLogic } from './useProductFormLogic';

const CategoriesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 0.643rem;

    @media (max-width: ${TabletPortraitViewBreakpoint}px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

const GhostNewCategoryButton = styled(GhostPrimaryButton)`
    &&& {
        display: flex;
        gap: 0.571rem;
        align-items: center;
        height: 2.7rem;
    }
`;

const StyledTextAreaInput = styled(({ ...props }: InputFieldPropType) => <InputField {...props} />)`
    textarea {
        resize: none;
    }
`;

const statuses = [
    { value: 'Aktiv', id: '0' },
    { value: 'Inaktiv', id: '10' },
];

export const ProductFormLayout = (): ReactElement => {
    const {
        values: {
            prepareProductTypes,
            preparedCategories,
            errorModalContext,
            isActionLoading,
            isCategoriesDropdownLoading,
            isSubmitButtonDisabled,
            isCategoriesFetching,
            isEditableProductLoading,
            isEditForm,
            isFormVisible,
            isModalVisible,
            preparedAllergens,
            isAllergensLoading,
            isAllergensLoadingSuccess,
            showProductInputs,
        },
        methods: { onSubmit, onAbortForm, onAbortModal, onRemove, setIsModalVisible },
        form: { handleSubmit, control, register, setValue, errors, isDirty, isSubmitted },
    } = useProductFormLogic();

    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'productForm' });
    const { t: tCommon } = useTranslation('common');

    const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] = useState(false);
    const nav = useNavigation();
    const shouldBlockNavigation = !isSubmitted && isDirty;

    const handleCreateCategoryModalClose = () => setIsCreateCategoryModalOpen(false);

    return (
        <>
            {isEditableProductLoading && <FullPageLoader text={t('downloadProductInfoLoader')} />}
            {isFormVisible && (
                <ProductFormLayoutWrapper onSubmit={handleSubmit(onSubmit)}>
                    <ProductFormHeader>
                        <StyledHeaderTitle>{t('title')}</StyledHeaderTitle>
                        <ProductFormHeaderActions>
                            <ProductFormHeaderAction>
                                <StyledGhostPrimaryButton type="button" onClick={onAbortForm} loading={isActionLoading}>
                                    {tCommon('cancelButton')}
                                </StyledGhostPrimaryButton>
                            </ProductFormHeaderAction>
                            {isEditForm && (
                                <ProductFormHeaderAction>
                                    <DangerPrimaryButton type="button" onClick={() => setIsModalVisible(true)}>
                                        {t('deleteProductButton')}
                                    </DangerPrimaryButton>
                                </ProductFormHeaderAction>
                            )}
                            <ProductFormHeaderAction>
                                <PrimaryButton
                                    type="submit"
                                    disabled={isSubmitButtonDisabled}
                                    loading={isActionLoading}
                                >
                                    {isEditForm ? tCommon('saveButton') : t('addButton')}
                                </PrimaryButton>
                            </ProductFormHeaderAction>
                        </ProductFormHeaderActions>
                    </ProductFormHeader>
                    <FormGroupsWrapper>
                        <FormColumn>
                            <FormGroup>
                                <FormGroupTitle>{t('productDetailsSubtitle')}</FormGroupTitle>
                                <FormGroupDescription>{t('productDetailsDescription')}</FormGroupDescription>
                                <FormControlsWrapper direction="column">
                                    <FormElementContainer>
                                        <FormLabel>{t('productTypeInputLabel')}</FormLabel>
                                        <Controller
                                            name="productId"
                                            key="productId"
                                            control={control}
                                            render={({ field: { onChange }, field }) => {
                                                const handleOnChange = (value: string) => {
                                                    setValue('productId', value);
                                                    onChange(value);
                                                };
                                                return (
                                                    <StyledDropdown
                                                        items={prepareProductTypes}
                                                        placeholder={t('productTypeInputPlaceholder')}
                                                        {...field}
                                                        onChange={handleOnChange}
                                                        error={errors.productId?.message}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormElementContainer>
                                    <CategoriesWrapper>
                                        <FormElementContainer>
                                            <FormLabel>{t('productCategoryInputLabel')}</FormLabel>
                                            <Controller
                                                name="categoryId"
                                                key="categoryId"
                                                control={control}
                                                render={({ field: { onChange }, field }) => {
                                                    const handleOnChange = (value: string) => {
                                                        setValue('categoryId', value);
                                                        onChange(value);
                                                    };
                                                    return (
                                                        <StyledDropdown
                                                            items={preparedCategories}
                                                            readOnly={isCategoriesDropdownLoading}
                                                            placeholder={t('productCategoryInputPlaceholder')}
                                                            {...field}
                                                            onChange={handleOnChange}
                                                            error={errors.categoryId?.message}
                                                            loading={isCategoriesFetching}
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormElementContainer>
                                        <GhostNewCategoryButton
                                            type="button"
                                            loading={isActionLoading}
                                            onClick={() => setIsCreateCategoryModalOpen(true)}
                                        >
                                            <SvgIcon name="Plus" /> {t('newCategory')}
                                        </GhostNewCategoryButton>
                                    </CategoriesWrapper>

                                    <FormElementContainer>
                                        <Controller
                                            name="title"
                                            key="title"
                                            control={control}
                                            render={() => {
                                                return (
                                                    <InputField
                                                        placeholder={t('productNameInputPlaceholder')}
                                                        label={t('productNameInputLabel')}
                                                        required
                                                        type="text"
                                                        error={errors?.title?.message}
                                                        {...register('title')}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormElementContainer>

                                    {showProductInputs && (
                                        <>
                                            <FormElementContainer>
                                                <Controller
                                                    name="description"
                                                    key="description"
                                                    control={control}
                                                    render={() => {
                                                        return (
                                                            <StyledTextAreaInput
                                                                placeholder={t('productNameInputPlaceholder')}
                                                                label={t('productDescriptionInputLabel')}
                                                                type="textarea"
                                                                rows={5}
                                                                {...register('description')}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </FormElementContainer>
                                            <FormElementContainer>
                                                {isAllergensLoading && (
                                                    <LoaderContainer>
                                                        <Loader />
                                                    </LoaderContainer>
                                                )}
                                                {isAllergensLoadingSuccess && (
                                                    <Controller
                                                        name="components"
                                                        control={control}
                                                        render={({ field: { onChange, value }, field }) => {
                                                            const handleSetValue = (
                                                                value: OptionType | OptionType[],
                                                            ) => {
                                                                setValue('components', value || []);
                                                                onChange(value);
                                                            };
                                                            return (
                                                                <StyledSelect
                                                                    label="Allergens"
                                                                    closeMenuOnSelect={false}
                                                                    isMulti
                                                                    options={preparedAllergens}
                                                                    {...field}
                                                                    onChange={handleSetValue}
                                                                    value={value || []}
                                                                    isCommonSelect
                                                                    hideSelectedOptions={false}
                                                                    controlShouldRenderValue
                                                                    backspaceRemovesValue={false}
                                                                    disabled={false}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </FormElementContainer>
                                        </>
                                    )}
                                </FormControlsWrapper>
                            </FormGroup>

                            <FormGroup>
                                <FormGroupTitle>{t('productPriceTitle')}</FormGroupTitle>
                                <FormGroupDescription>{t('productPriceDescription')}</FormGroupDescription>
                                <FormControlsWrapper direction="row" gap={1.071}>
                                    <FormElementContainer>
                                        <Controller
                                            name="price"
                                            key="price"
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                const handleOnChange = (handledValue: number) => {
                                                    setValue('price', handledValue);
                                                    onChange(handledValue);
                                                };
                                                return (
                                                    <NumberInput
                                                        placeholder={t('productPriceWithTaxInputPlaceholder')}
                                                        label={t('productPriceWithTaxInputLabel')}
                                                        required
                                                        suffix={' NOK'}
                                                        min={0}
                                                        max={Infinity}
                                                        error={errors?.price?.message}
                                                        {...register('price')}
                                                        thousandSeparator=" "
                                                        onChange={handleOnChange}
                                                        value={value}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormElementContainer>
                                    <FormElementContainer>
                                        <Controller
                                            name="taxPercent"
                                            key="taxPercent"
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                const handleOnChange = (handledValue: number) => {
                                                    setValue('taxPercent', handledValue);
                                                    onChange(handledValue);
                                                };
                                                return (
                                                    <NumberInput
                                                        placeholder={t('productPriceTaxPercentInputPlaceholder')}
                                                        label={t('productPriceTaxPercentInputLabel')}
                                                        required
                                                        error={errors?.taxPercent?.message}
                                                        {...register('taxPercent')}
                                                        min={0}
                                                        max={Infinity}
                                                        suffix={'%'}
                                                        onChange={handleOnChange}
                                                        thousandSeparator=" "
                                                        value={value}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormElementContainer>
                                </FormControlsWrapper>
                            </FormGroup>
                        </FormColumn>
                        <FormColumn>
                            <FormGroup>
                                <FormGroupTitle>{t('productStatusTitle')}</FormGroupTitle>
                                <FormGroupDescription> {t('productStatusDescription')}</FormGroupDescription>
                                <FormControlsWrapper direction="column">
                                    <FormElementContainer>
                                        <FormLabel>{t('productStatusInputLabel')}</FormLabel>
                                        <Controller
                                            name="productStatus"
                                            key="productStatus"
                                            control={control}
                                            render={({ field: { onChange }, field }) => {
                                                const handleOnChange = (value: string) => {
                                                    onChange(value);
                                                };
                                                return (
                                                    <StyledDropdown
                                                        items={statuses}
                                                        placeholder={t('productStatusInputPlaceholder')}
                                                        {...field}
                                                        onChange={handleOnChange}
                                                        error={errors.productStatus?.message}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormElementContainer>
                                </FormControlsWrapper>
                            </FormGroup>
                            {/*// TODO: we are blocked because we dont have real data for that input*/}
                            {/*<FormGroup>*/}
                            {/*    <FormGroupTitle>Leverandør</FormGroupTitle>*/}
                            {/*    <FormGroupDescription>Velg hvem som administrerer dette produktet</FormGroupDescription>*/}
                            {/*    <FormControlsWrapper direction="column">*/}
                            {/*        <FormElementContainer>*/}
                            {/*            <InputSelect*/}
                            {/*                label="Leverandør"*/}
                            {/*                isClearable={false}*/}
                            {/*                id="vendor"*/}
                            {/*                options={prepareCompanies}*/}
                            {/*                placeholder={PlaceholderTranslation.PORTAL}*/}
                            {/*                showSearchIcon*/}
                            {/*                isLoading={isLoadingCompanies}*/}
                            {/*                loadingMessage="loading..."*/}
                            {/*                disable={false}*/}
                            {/*                name="vendor"*/}
                            {/*                onChange={(company: any) => {*/}
                            {/*                    setValue('vendor', company.companyGuid);*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        </FormElementContainer>*/}
                            {/*    </FormControlsWrapper>*/}
                            {/*</FormGroup>*/}
                        </FormColumn>
                    </FormGroupsWrapper>
                </ProductFormLayoutWrapper>
            )}
            <Modal
                onClose={() => setIsModalVisible(false)}
                open={isModalVisible || !!errorModalContext}
                type={ModalType.Small}
                title={!!errorModalContext ? tCommon('fail') : t('confirmDeletionModalTitle')}
                hideCloseButton
                bottomContent={
                    <ModalBottom>
                        <GhostPrimaryButton onClick={onAbortModal}>{tCommon('cancelButton')}</GhostPrimaryButton>
                        {!errorModalContext && (
                            <PrimaryButton onClick={onRemove}>{tCommon('deleteButton')}</PrimaryButton>
                        )}
                    </ModalBottom>
                }
            >
                <ModalBody>
                    {errorModalContext && (
                        <ErrorDisplay header={errorModalContext.title}>{errorModalContext.body}</ErrorDisplay>
                    )}
                    {!errorModalContext && <ModalContent>{t('confirmDeletionModalMessage')}</ModalContent>}
                </ModalBody>
            </Modal>
            <RoutePromptModal
                navigate={nav.push}
                shouldBlockNavigation={() => shouldBlockNavigation}
                modalTitle={tCommon('warning')}
                modalContent={<p>{t('routePromptText')}</p>}
                modalCancelButtonText={tCommon('buttonBack')}
                modalOkButtonText={tCommon('discardChanges')}
            />
            {isCreateCategoryModalOpen && (
                <CreateNewCategoryModal
                    isCreateNewCategoryModalShown={isCreateCategoryModalOpen}
                    hideCreateNewCategoryModal={handleCreateCategoryModalClose}
                />
            )}
        </>
    );
};
