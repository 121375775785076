import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OrderSortDirection, InventorySortDirection } from '../../../api/apiTypes/shopApiTypes';
import { TicketSortDirection } from '../../../api/apiTypes/ticketingApiTypes';
import GhostPrimaryButton from '../../buttons/GhostPrimaryButton';
import PrimaryButton from '../../buttons/PrimaryButton';
import SvgIcon from '../../icons/SvgIcon';
import { DropDownOption } from '../../select/FilterDropdown';
import Drawer from '../Drawer';

const StyledPrimaryButton = styled(PrimaryButton)`
    height: 3.142rem;
    svg {
        rect {
            fill: var(--background-color);
        }
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const StyledGhostPrimaryButton = styled(GhostPrimaryButton)`
    &&& {
        font-size: 1.142rem;
        padding: 0.857rem;
        margin-right: 0.642rem;
    }

    width: 100%;
`;

const SortingOrdersList = styled.ul`
    list-style: none;
    padding: 0;
`;

type SortingOrdersItemProps = {
    isChosen: boolean;
};

const SortingOrdersItem = styled.li`
    cursor: pointer;
    padding: 0.5rem 1.142rem 0.571rem 1.142rem;
    border: 1px solid #d4d6d7;
    :focus {
        outline: 1px solid var(--primary-color);
    }
    &:not(:last-child) {
        border-bottom: none;
    }
    font-weight: ${({ isChosen }: SortingOrdersItemProps) => (isChosen ? '600' : '400')};
`;

const BottomContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 1.142rem;
    border-top: 1px solid #e9ebeb;
`;

interface FilteringDrawerProps {
    isVisible: boolean;
    onClose(): void;
    filterOptions: DropDownOption[];
    filterValue?: string | number;
    onChange(value: string | number): void;
    // TODO create global sort type for ticketing and s@w
    sortingDirection: OrderSortDirection | TicketSortDirection | InventorySortDirection;
    onChangeDirection(): void;
}

const SortingDrawer = ({
    isVisible,
    onClose,
    filterOptions,
    filterValue,
    onChange,
    sortingDirection,
    onChangeDirection,
}: FilteringDrawerProps): ReactElement => {
    const handleConfirm = () => {
        onClose();
    };

    const handleEnterPress = (event: React.KeyboardEvent, value: string | number) =>
        event.key === 'Enter' && onChange(value);
    const { t } = useTranslation('common');
    return (
        <Drawer onClose={onClose} open={isVisible}>
            <>
                <ButtonsContainer>
                    <StyledGhostPrimaryButton onClick={onClose}>{t('sortBy')}</StyledGhostPrimaryButton>
                    <StyledPrimaryButton onClick={onChangeDirection}>
                        <SvgIcon
                            name={
                                sortingDirection === OrderSortDirection.Desc
                                    ? 'DescendingSortIcon'
                                    : 'AscendingSortIcon'
                            }
                        />
                    </StyledPrimaryButton>
                </ButtonsContainer>
                <SortingOrdersList>
                    {filterOptions.map((option) => (
                        <SortingOrdersItem
                            tabIndex={0}
                            key={option.key}
                            isChosen={filterValue === option.value}
                            onClick={() => onChange(option.value)}
                            onKeyUp={(e) => handleEnterPress(e, option.value)}
                        >
                            {option.text}
                        </SortingOrdersItem>
                    ))}
                </SortingOrdersList>
                <BottomContentContainer>
                    <PrimaryButton onClick={handleConfirm}>{t('resetFilters')}</PrimaryButton>
                </BottomContentContainer>
            </>
        </Drawer>
    );
};

export default SortingDrawer;
