import { ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ShimmerAnimation } from '../../../../components/common';

const greyColorLight = '#e9ebeb';
const greyColor = '#cccccc';
const greyColorDark = '#677074';

const fadeOut = keyframes`
    0% {
    opacity: 100%;
    }
    100% {
    opacity: 0%;
    }
`;

type WrapperProps = { show?: boolean };
const Wrapper = styled.div`
    position: relative;
    width: 100%;
    animation: ${({ show }: WrapperProps) =>
        show
            ? 'none;'
            : css`
                  ${fadeOut} 0.1s linear forwards;
              `};
`;

type CardProps = { showResponseButton: boolean };
const Card = styled.div`
    width: 100%;
    height: ${({ showResponseButton }: CardProps) => (showResponseButton ? '200px' : '150px')};
    border: 1px solid #d4d6d7;
    border-radius: 5px;
    display: flex;
    padding: 1.143rem;
    margin-bottom: 1.143rem;
`;

const Content = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const SkeletonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 8px;
`;

const Avatar = styled.div`
    background-color: ${greyColor};
    height: 40px;
    width: 40px;
    border-radius: 50%;
    min-width: 40px;
`;

const AuthorDisplay = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
`;

const UserName = styled.div`
    background: ${greyColorLight};
    width: 81px;
    height: 20px;
    border-radius: 5px;
    margin-bottom: 0.285rem;
`;

const EditInfo = styled.div`
    background: ${greyColorLight};
    width: 139px;
    height: 16px;
    border-radius: 5px;
`;

const CommentContainer = styled.div`
    padding: 1.143rem 0;
`;

const CommentLineLong = styled.div`
    width: 100%;
    height: 21px;
    background-color: ${greyColor};
    margin: 0.571rem 0;
    border-radius: 5px;
`;

const CommentLineShort = styled(CommentLineLong)`
    width: 92%;
`;

const ResponseButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;

const ResponseButton = styled.div`
    width: 59px;
    height: 21px;
    background-color: ${greyColorDark};
    border-radius: 5px;
`;

interface CommentSkeletonLoaderProps {
    show: boolean;
    numberOfItems?: number;
    showResponseButton?: boolean;
}

const CommentSkeletonLoader = ({
    show,
    numberOfItems = 20,
    showResponseButton = false,
}: CommentSkeletonLoaderProps): ReactElement => {
    let cards = [];
    const card = (index: number) => (
        <Card showResponseButton={showResponseButton} key={index}>
            <Wrapper show={show}>
                <Content>
                    <AuthorDisplay>
                        <Avatar />
                        <Container>
                            <UserName />
                            <EditInfo />
                        </Container>
                    </AuthorDisplay>
                    <CommentContainer>
                        <CommentLineLong />
                        <CommentLineShort />
                    </CommentContainer>
                    {showResponseButton && (
                        <ResponseButtonContainer>
                            <ResponseButton />
                        </ResponseButtonContainer>
                    )}
                </Content>
                <ShimmerAnimation />
            </Wrapper>
        </Card>
    );
    cards = Array.from({ length: numberOfItems }, (_, index) => card(index));
    return <SkeletonWrapper>{cards}</SkeletonWrapper>;
};
export default CommentSkeletonLoader;
