import { AxiosError, type AxiosResponse } from 'axios';
import type { MutationResultPair } from 'react-query';
import { useMutation } from 'react-query';
import { UploadedTicketImage } from '../../../api/apiTypes/ticketingApiTypes';
import { uploadImage } from '../../../api/ticketingApi';

export const useUploadTicketImage = (
    typeId: string,
    categoryId: string,
    successCallback?: (res: AxiosResponse<UploadedTicketImage>) => void,
    errorCallback?: (res: AxiosError<string>) => void,
): MutationResultPair<AxiosResponse<UploadedTicketImage>, AxiosError<string>, FormData, never> => {
    return useMutation((image) => uploadImage(image, typeId, categoryId), {
        onSuccess: (result) => successCallback && successCallback(result),
        onError: (error) => errorCallback && errorCallback(error),
    });
};
