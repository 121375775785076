import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { prefix as authenticationPrefix } from '../../common/Authorization/ApiAuthorizationConstants';

const useGetNavigationUrl = (): ((url: string) => string) => {
    const { portalName } = useParams<{ portalName: string }>();
    return useCallback(
        (url: string): string => {
            if (!portalName) return url;
            if (!url.startsWith('/')) return url;
            if (url.startsWith(authenticationPrefix)) return url;
            if (url.startsWith(`/${portalName}`)) return url;

            return `/${portalName}${url}`;
        },
        [portalName],
    );
};
export default useGetNavigationUrl;
