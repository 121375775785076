import { TFunction } from 'react-i18next';
import * as yup from 'yup';
import { ProductCategoryV2 } from '../../../api/apiTypes/shopApiTypes';
import FoodProductPlaceholder from './images/FoodProductPlaceholder.webp';
import LifeAtWorkLogoDark from './images/LifeAtWorkLogo_dark.png';
import OfficeIllustration from './images/OfficeIllustration.webp';
import { ShopType } from './types';

export type FormSchema = {
    companyName: string;
    address: string;
    location: string;
    organizationNumber: string;
    postalCode: string;
    country: string;
    contactFirstName: string;
    contactSurName: string;
    contactEmail: string;
    contactPhoneNumber: string;
};

export const schema = (t: TFunction<'ShopAtWork', 'settings'>): any =>
    yup.object().shape({
        companyName: yup.string().required(t('companyNameRequired')),
        address: yup.string().required(t('streetAddressRequired')),
        location: yup.string().required(t('placeRequired')),
        organizationNumber: yup
            .string()
            .required(t('organizationNumberRequired'))
            .matches(/^[0-9]+$/, t('mustContainNumberOnly'))
            .min(9, t('mustContainExactlyNineDigits'))
            .max(9, t('mustContainExactlyNineDigits')),
        postalCode: yup
            .string()
            .required(t('postalCodeRequired'))
            .matches(/^[0-9]+$/, t('mustContainNumberOnly')),
        country: yup.string().required(t('countryRequired')),
        contactFirstName: yup.string().required(t('nameRequired')),
        contactSurName: yup.string().required(t('lastNameRequired')),
        contactEmail: yup.string().email(t('emailNotValid')).required(t('emailRequired')),
        contactPhoneNumber: yup.string(),
    });

const getLocalImage = (name?: string) => {
    const imageMap: Record<string, string> = {
        LifeAtWorkLogoDark: LifeAtWorkLogoDark,
        FoodProductPlaceholder: FoodProductPlaceholder,
    };
    return name ? imageMap[name] : OfficeIllustration;
};

export const getProductImagePlaceholder = (category?: ProductCategoryV2 | null): string => {
    return getLocalImage(
        {
            [ShopType.Food]: 'FoodProductPlaceholder',
            [ShopType.General]: 'LifeAtWorkLogoDark',
            [ShopType.Premises]: 'LifeAtWorkLogoDark',
            [ShopType.Services]: 'LifeAtWorkLogoDark',
        }[category?.type.name || ShopType.Food],
    );
};
