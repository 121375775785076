// eslint-disable-next-line no-restricted-imports
import { format, isValid } from 'date-fns';
import { nb, enGB } from 'date-fns/locale';
import i18n from 'i18next';
import { LanguagesEnum } from '../languageUtilities/language.enum';

export const getDeviceLanguageDateFormat = (inputDate: string | Date, dateFormat = 'P'): string => {
    let locale: Locale = enGB;

    switch (i18n.language) {
        case LanguagesEnum.en:
            locale = enGB;
            break;
        case LanguagesEnum.no:
            locale = nb;
            break;
    }

    const date = typeof inputDate === 'string' ? new Date(inputDate) : inputDate;

    if (!isValid(date)) {
        console.error('Invalid date has been provided!');
        return '';
    }

    return format(date, dateFormat, { locale });
};
