import { AxiosError, AxiosResponse } from 'axios';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import {
    Category,
    CategoryOrder,
    DeleteCategoryDto,
    UpdateTicketingTypeCategory,
} from '../../../api/apiTypes/ticketingApiTypes';
import {
    changeTypeCategoriesOrder,
    createTicketTypeCategory,
    deleteTicketTypeCategory,
    getTicketsCountForCategory,
    updateTicketTypeCategory,
} from '../../../api/ticketingApi';
import { TicketTypeQueryCacheKey } from './useTicketingType';

const TicketCountClosedKey = 'TicketCountClosedKey';
const TicketCountOpenKey = 'TicketCountOpenKey';

export const useCreateTicketCategory = (): MutationResultPair<
    AxiosResponse<Category>,
    string | AxiosError<string> | Error,
    [string, Category],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([typeId, category]) => {
            return createTicketTypeCategory(typeId, category);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(TicketTypeQueryCacheKey);
            },
        },
    );
};

export const useUpdateTicketingTypeCategory = (): MutationResultPair<
    AxiosResponse<Category>,
    string | AxiosError<string> | Error,
    [string, string, UpdateTicketingTypeCategory],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([typeId, categoryId, category]) => {
            return updateTicketTypeCategory(typeId, categoryId, category);
        },
        {
            onSuccess: (response) => {
                cache.setQueryData<Category[]>([TicketTypeQueryCacheKey], (curr) => {
                    if (!curr) return curr as unknown as Category[];
                    return curr.map((type) => {
                        if (type.id === response.data.id) {
                            return response.data;
                        } else {
                            return type;
                        }
                    });
                });
                cache.invalidateQueries(TicketTypeQueryCacheKey);
            },
        },
    );
};

export const useDeleteTypeCategory = (): MutationResultPair<
    AxiosResponse<Category>,
    string | AxiosError<string> | Error,
    [string, string, DeleteCategoryDto | undefined],
    never
> => {
    return useMutation(([typeId, categoryId, deleteCategoryDto]) => {
        return deleteTicketTypeCategory(typeId, categoryId, deleteCategoryDto);
    });
};

export const useGetTicketsCountForCategory = (
    typeId: string,
    categoryId: string,
    isClosed?: boolean,
): QueryResult<number, string | AxiosError<string> | Error> => {
    return useQuery([isClosed ? TicketCountClosedKey : TicketCountOpenKey], async () => {
        const result = await getTicketsCountForCategory(typeId, categoryId, isClosed);
        return result.data;
    });
};

export const useChangeTypeCategoriesOrder = (): MutationResultPair<
    AxiosResponse<string>,
    string | AxiosError<string> | Error,
    [string, CategoryOrder[]],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([typeId, categoriesWithOrder]) => {
            return changeTypeCategoriesOrder(typeId, categoriesWithOrder);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(TicketTypeQueryCacheKey);
            },
        },
    );
};
