import styled from 'styled-components';
import { MobileViewBreakpoint } from '../../common/ScreenSizeBreakPoints';
import { getComputedCssProperty } from '../../utility';
import { AccordionTabChildrenContainerProps, AccordionTabStyledProps } from './types';

export const StyledAccordionTab = styled.div`
    display: grid;
    background-color: ${({
        selected,
        backgroundColor,
        selectedBackgroundColor,
        hasChildren,
    }: AccordionTabStyledProps) =>
        selected && hasChildren
            ? selectedBackgroundColor || getComputedCssProperty('--primary-color-light') + '1F'
            : backgroundColor || 'var(--surface-color-light)'};
    grid-template-columns:
        [name-input]1fr [article-count]minmax(min-content, 11.42rem) [category-count]minmax(2rem, min-content)
        [context-menu]2.3rem;
    grid-gap: 1.14rem;
    width: 100%;
    align-items: center;

    padding: ${({ hasParentAccordionTab }: AccordionTabStyledProps) =>
        hasParentAccordionTab ? '0.86rem 1.14rem 0.86rem 2.571rem' : '0.86rem 1.14rem'};
    border-top: 1px solid #e9ebeb;
    border-bottom: 1px solid #e9ebeb;
    border-left: ${({ hasParentAccordionTab }: AccordionTabStyledProps) =>
        !hasParentAccordionTab ? '1px solid #e9ebeb' : 'none'};
    border-right: ${({ hasParentAccordionTab }: AccordionTabStyledProps) =>
        !hasParentAccordionTab ? '1px solid #e9ebeb' : 'none'};
    text-align: center;

    cursor: ${({ hasChildren }: AccordionTabStyledProps) => (hasChildren ? 'pointer' : 'default')};

    [aria-expanded='true'] & > button > svg {
        transform: rotate(180deg);
    }

    & > :last-child {
        grid-column: context-menu;
    }

    @media (max-width: ${MobileViewBreakpoint}px) {
        grid-template-columns: [name-input]1fr min-content [category-count]minmax(2rem, min-content) [context-menu]2.3rem;
    }
`;

export const IconButton = styled.button`
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    display: flex;
    cursor: pointer;
    svg {
        min-width: 16px;
    }
    :focus,
    :focus-visible {
        outline: 2px solid var(--primary-color);
        outline-offset: 4px;
    }
    :focus:not(:focus-visible) {
        outline: none;
    }
`;

export const AccordionChildrenContainer = styled.div`
    background-color: ${({ backgroundColor }: AccordionTabChildrenContainerProps) => {
        return backgroundColor || getComputedCssProperty('--primary-color-light') + '0A';
    }};
    border-right: 1px solid #e9ebeb;
    border-left: 1px solid #e9ebeb;
    border-bottom: 1px solid #e9ebeb;
`;

export const StyledNameInputWrapper = styled.div`
    display: flex;
`;
