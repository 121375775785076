import { FC } from 'react';
import ErrorStrip from '../../../../../../../components/errorStrip';
import { FlexBox, Image, Typography } from '../../../../../../../components/styleds';
import { getProductImagePlaceholder } from '../../../../../../ShopAtWork/Settings/utils';
import { ProductsInfoCardProps } from './types';

const ProductsInfoCard: FC<ProductsInfoCardProps> = ({ order, stripErrorMessage }) => {
    return (
        <FlexBox dir={'column'} br={'0.571rem'} borderWidth={'1px'} borderColor={'#D4D6D7'}>
            <FlexBox gap={'1.143rem'} pd={'1rem 1.143rem'}>
                <FlexBox gap={'1.143rem'} align={'center'} justify={'flex-start'}>
                    <FlexBox width={'auto'} align={'center'} justify={'center'} br={'1.286rem'} overflow={'hidden'}>
                        <Image
                            minW={'4.286rem'}
                            height={'4.286rem'}
                            objectFit="contain"
                            src={order.imageUrl ?? getProductImagePlaceholder()}
                            loading="lazy"
                            alt={`product image ${order.title}`}
                        />
                    </FlexBox>
                    <FlexBox dir={'column'} gap={'0.286rem'} justify={'center'}>
                        <Typography
                            color={'#263238'}
                            fontWeight={700}
                            fontSize={'1.286rem'}
                            lineHeight={'1.714rem'}
                            letterSpacing={'0.2px'}
                            textWrap={'wrap'}
                            whiteSpace={'pre-wrap'}
                            width={'100%'}
                        >
                            {order.title}
                        </Typography>
                    </FlexBox>
                </FlexBox>
                <FlexBox align={'center'} gap={'0.286rem'} justify={'center'} width={'auto'}>
                    <Typography
                        color={'#475156'}
                        fontSize={'1rem'}
                        fontWeight={400}
                        lineHeight={'1.643rem'}
                        whiteSpace={'nowrap'}
                    >
                        {`${order.amount} x ${(
                            order.priceOneItem +
                            (order.priceOneItem * order.taxOneItemPercent) / 100
                        ).toFixed(2)} NOK`}
                    </Typography>
                </FlexBox>
                <FlexBox align={'center'} justify={'center'} width={'auto'}>
                    <Typography
                        color={'#475156'}
                        fontSize={'1rem'}
                        fontWeight={400}
                        lineHeight={'1.643rem'}
                        whiteSpace={'nowrap'}
                    >
                        VAT {order.taxOneItemPercent}%
                    </Typography>
                </FlexBox>
                <FlexBox align={'center'} justify={'flex-end'} width={'auto'}>
                    <Typography color={'#263238'} fontSize={'1.143rem'} fontWeight={700} lineHeight={'1.429rem'}>
                        {order.totalPrice.toFixed(2)}
                    </Typography>
                </FlexBox>
            </FlexBox>
            <ErrorStrip background={'#F1F5F9'} iconColor={'#001EFF'} textContent={stripErrorMessage} />
        </FlexBox>
    );
};

export default ProductsInfoCard;
