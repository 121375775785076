import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Badge } from '../../../components';

interface CustomRadioProps {
    disabled?: boolean;
}

interface PortalRadioButtonProps {
    name: string;
    value: number;
    portalImage: string;
    address: string;
    isChecked: boolean;
    isDefault: boolean;
    onClick: () => void;
}

const RadioContainer = styled.label`
    display: grid;
    grid-template-columns: 5.714rem auto auto 1.428rem;
    align-items: center;
    overflow-x: hidden;
    gap: 8px;
    border-bottom: 1px solid #d4d6d7;
    gap: 1rem;
    padding: 0.857rem 1.714rem 0.857rem 1.14rem;

    :focus-within > div {
        outline-offset: 2px;
    }
    &:hover {
        background-color: #f6fcff;
    }

    :focus-visible,
    :focus {
        outline: none;
        background-color: #f6fcff;
    }

    :focus:not(:focus-visible) {
        outline: none;
        background-color: #f6fcff;
    }
`;

const RadioInput = styled.input`
    opacity: 0;
    width: 0;

    &[type='radio']:checked ~ div > div {
        background: var(--primary-color);
        width: calc(1.14rem - 8px);
        height: calc(1.14rem - 8px);
        margin: 4px;
        border-radius: 50%;
    }
    &[type='radio']:checked ~ span {
        border-color: var(--primary-color);
        font-weight: bold;
        color: var(--primary-color);
    }
`;

const ImageWrapper = styled.div`
    height: 5.72rem;
    width: 5.72rem;
    border-radius: 0.3rem;
    overflow: hidden;
`;
const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const ContentHeadersWrapper = styled.div`
    width: 100%;
    min-width: 7.14rem;
`;
const ContentCardHeader = styled.p`
    font-size: 1.438rem;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #263238;
`;

const CustomRadio = styled.div`
    border: ${({ disabled }: CustomRadioProps) => (disabled ? '1px solid #E9EBEB' : '2px solid var(--primary-color)')};
    background-color: ${({ disabled }: CustomRadioProps) => (disabled ? 'var(--background-color)' : 'inherit')};
    display: block;
    border-radius: 50%;
    min-width: 1.14rem;
    min-height: 1.14rem;
`;

const ContentCardSubHeader = styled.p`
    margin: 0;
    color: #677074;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledBadge = styled(Badge)`
    font-size: 0.85rem;
    font-weight: 600;
    white-space: nowrap;
    color: #001eff;
    background: #e1f6ff;
    padding: 0.3rem 0.57rem;
    text-align: center;
`;

const RadioIconContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const PortalRadioButton = ({
    name,
    value,
    portalImage,
    address,
    isChecked,
    isDefault,
    onClick,
}: PortalRadioButtonProps): ReactElement => {
    const { t } = useTranslation('myProfile');
    return (
        <RadioContainer onClick={onClick}>
            <ImageWrapper>
                <Image src={portalImage ?? 'PortalDefaultImage.jpg'} alt="Portal Image" />
            </ImageWrapper>
            <ContentHeadersWrapper>
                <ContentCardHeader>{name}</ContentCardHeader>
                <ContentCardSubHeader>{address}</ContentCardSubHeader>
            </ContentHeadersWrapper>
            <div>{isDefault && <StyledBadge>{t('defaultPortal')}</StyledBadge>}</div>
            <RadioIconContainer>
                <RadioInput type="radio" name={name} value={value} checked={isChecked} tabIndex={0} />
                <CustomRadio disabled={false} aria-hidden={true}>
                    <div />
                </CustomRadio>
            </RadioIconContainer>
        </RadioContainer>
    );
};

export default PortalRadioButton;
