import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SidebarMenuItem } from '../../components';
import { useGetCategories } from '../Dashboard/Articles/ApiQueries/useCategories';
import useArticlesNavigation from './useArticlesNavigation';

const ArticlesDynamicMenuItem = (): ReactElement => {
    const { goToSelectedArticleCategory, pathname, articleOverviewLink, goToArticleOverview } = useArticlesNavigation();
    const { t: tCommon } = useTranslation('common');
    const { data: categories } = useGetCategories(true, false);
    const isArticlePathName = pathname?.includes(articleOverviewLink);
    return (
        <SidebarMenuItem
            icon="ArticlesIcon"
            isActive={isArticlePathName}
            onClick={goToArticleOverview}
            text={tCommon('articles')}
        >
            {categories?.map((category) => (
                <SidebarMenuItem
                    key={category.id}
                    isActive={pathname?.includes(`/${category.id}`)}
                    onClick={() => goToSelectedArticleCategory(category.id)}
                    text={category.name}
                />
            ))}
        </SidebarMenuItem>
    );
};

export default ArticlesDynamicMenuItem;
