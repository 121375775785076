import { ReactElement, useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { SvgIcon } from '../../components';
import { ImagePreview } from '../../modules/Ticketing/components/Ticket/TicketTabs/components/Message/components';
import { FileState } from './ImageUploader';

type ContainerProps = { isCompact: boolean };
const Container = styled.div`
    padding: 1.142rem;
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    max-height: 5.714rem;
    ${({ isCompact }: ContainerProps) =>
        isCompact &&
        css`
            border: 1px solid #d4d6d7;
            border-radius: 3px;
            padding: 1rem;
        `}
`;

const TextSpan = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const FileName = styled.h4`
    margin: 0;
    overflow-x: hidden;
    text-overflow: ellipsis;
    flex-grow: 2;
`;

const DetailsSpan = styled.span`
    display: flex;
    flex-direction: column;
    color: var(--text-disabled-color);
`;

const IconContainer = styled.div`
    margin: auto;
    margin-right: 0;
`;

const DividerSpan = styled.span`
    color: var(--text-medium-emphasis-color);
    font-weight: bold;
    margin: 0 0.3em;
    padding: 0.3rem;
`;

const SvgIconButton = styled.button`
    border: none;
    border-radius: 50%;
    background: #e9ebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.285rem;
    height: 2.285rem;

    cursor: pointer;

    :focus,
    :focus-visible {
        outline: 1px solid var(--primary-color);
    }
    :focus:not(:focus-visible) {
        outline: 0;
    }

    svg {
        path {
            fill: #475156;
        }
    }
`;
const StyledSuccessIconWrapper = styled(({ ...props }) => <motion.div {...props} />)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.285rem;
    height: 2.285rem;
    svg {
        height: 1.428rem;
        width: 1.428rem;

        path {
            fill: var(--success-color-dark);
        }
    }
`;

const ErrorMessageWrapper = styled(({ ...props }) => <motion.div {...props} />)`
    display: flex;
    align-items: center;
    gap: 0.214rem;

    svg {
        height: 1.428rem;
        width: 1.428rem;
    }
`;

const ErrorMessage = styled.p`
    color: var(--error-color-dark);
    font-weight: 600;
`;

const ImageDetails = styled.p``;

const Divider = styled.hr`
    width: 100%;
    border-top: 1px solid #22242626;
    border-bottom: 1px solid #ffffff19;
`;

interface UploadedImageDisplayProps {
    file: File;
    handleRemoveImage(imageName: string): void;
    handleRefetchImage?(imageName: string): void;
    widthDivider?: boolean;
    fileState?: FileState;
    isCompact: boolean;
}

const UploadedImageDisplay = ({
    widthDivider,
    file,
    handleRemoveImage,
    handleRefetchImage,
    fileState,
    isCompact = true,
}: UploadedImageDisplayProps): ReactElement => {
    const fileUrl = useMemo(() => {
        return URL.createObjectURL(file);
    }, [file]);
    const { t } = useTranslation('Dashboard', { keyPrefix: 'articles' });
    const sizeInKb = (file.size / 1024).toFixed(2);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        fileState === FileState.Success && setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 2000);
    }, [fileState]);

    const renderIconButton = () => {
        if (showSuccess) {
            return (
                <StyledSuccessIconWrapper
                    initial={{ opacity: 1 }}
                    animate={{
                        opacity: [1, 1, 1, 1, 1, 1, 0],
                        y: ['0.7rem', '-0.7rem', '0.3rem', '-0.3rem', '0.1rem', '-0.1rem', '0rem'],
                        duration: 1,
                    }}
                >
                    <SvgIcon name="SuccessIcon" />
                </StyledSuccessIconWrapper>
            );
        }
        if (fileState === FileState.Loading) {
            return <Loader active size="small" inline></Loader>;
        }
        if (fileState === FileState.Error && !!handleRefetchImage) {
            return (
                <SvgIconButton aria-label={t('removeLoadedImg')} onClick={() => handleRefetchImage(file.name)}>
                    <SvgIcon name="UndoIcon" />
                </SvgIconButton>
            );
        }
        if (file) {
            return (
                <SvgIconButton aria-label={t('removeLoadedImg')} onClick={() => handleRemoveImage(file.name)}>
                    <SvgIcon name="TrashIcon" />
                </SvgIconButton>
            );
        }
        return null;
    };

    return (
        <>
            <Container isCompact={isCompact}>
                <ImagePreview
                    image={{
                        imageFileName: file.name,
                        imageUrl: fileUrl,
                    }}
                    alt={t('previewUploadedImg')}
                />
                <>
                    <TextSpan>
                        <FileName>{file.name}</FileName>
                        <DetailsSpan>
                            <ImageDetails>
                                {isCompact ? (
                                    <>{sizeInKb} KB</>
                                ) : (
                                    <>
                                        {sizeInKb} MB <DividerSpan>|</DividerSpan>
                                        {new Date(file.lastModified).toLocaleDateString()}
                                    </>
                                )}
                                {fileState === FileState.Error ? (
                                    <ErrorMessageWrapper
                                        animate={{
                                            opacity: [0, 1],
                                            y: ['0.3rem', '-0.3rem', '0.2rem', '-0.2rem', '0.1rem', '-0.1rem', '0rem'],
                                            duration: 1,
                                        }}
                                    >
                                        <SvgIcon name="AlertIcon" />
                                        <ErrorMessage>{t('errorMessageUploadFailed')}</ErrorMessage>
                                    </ErrorMessageWrapper>
                                ) : null}
                            </ImageDetails>
                        </DetailsSpan>
                    </TextSpan>
                    <IconContainer>{renderIconButton()}</IconContainer>
                </>
            </Container>
            {widthDivider && <Divider />}
        </>
    );
};

export default UploadedImageDisplay;
