import { ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { MobileViewBreakpoint } from '../../../../../../../common/ScreenSizeBreakPoints';
import { ShimmerAnimation } from '../../../../../components/common';

const greyColor = '#e9ebeb';
const greyColorDark = '#d4d6d7';

const fadeOut = keyframes`
0% {
  opacity: 100%;
}
100% {
  opacity: 0%;
}
`;

type WrapperProps = { show?: boolean };
const Wrapper = styled.div`
    position: relative;
    animation: ${({ show }: WrapperProps) =>
        show
            ? 'none;'
            : css`
                  ${fadeOut} 0.1s linear forwards;
              `};
`;

const ItemContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
`;

const HorizontalRule = styled.hr`
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 1.429rem 0;
`;

const ActivityWrapper = styled.div`
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: ${MobileViewBreakpoint}px) {
        width: 100%;
    }
`;

const Avatar = styled.div`
    background-color: ${greyColorDark};
    height: 40px;
    width: 40px;
    border-radius: 50%;
    min-width: 40px;
    margin-right: 2.6%;
`;

const Description = styled.div`
    width: 100%;
    height: 17px;
    background-color: ${greyColorDark};
`;

const Updated = styled.div`
    width: 103px;
    min-width: 103px;
    height: 17px;
    background-color: ${greyColor};
    margin-left: 10%;
`;

interface ActivitySkeletonLoaderProps {
    show?: boolean;
    numberOfItems?: number;
}

const ActivitySkeletonLoader = ({ show, numberOfItems = 1 }: ActivitySkeletonLoaderProps): ReactElement => {
    let items = [];
    const item = (index: number) => (
        <div key={index}>
            <Wrapper show={show}>
                <ItemContainer>
                    <Avatar />
                    <Description />
                    <Updated />
                </ItemContainer>
                <ShimmerAnimation />
            </Wrapper>
            <HorizontalRule />
        </div>
    );

    items = Array.from({ length: numberOfItems }, (_, index) => item(index));
    return <ActivityWrapper>{items}</ActivityWrapper>;
};

export default ActivitySkeletonLoader;
