import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SidebarMenuItem } from '../../components';
import useFoodForMeetingsNavigation from './useFoodForMeetingsNavigation';

const FoodForMeetingsDynamicMenuItem = (): ReactElement => {
    const { t } = useTranslation('common');

    const { pathname } = useLocation();
    const { goToFoodForMeetings, foodForMeetingsLink } = useFoodForMeetingsNavigation();

    return (
        <SidebarMenuItem
            icon="CutleryIcon"
            isActive={pathname?.includes(foodForMeetingsLink)}
            onClick={goToFoodForMeetings}
            text={t('foodForMeetingsMenuLabel')}
        />
    );
};

export default FoodForMeetingsDynamicMenuItem;
