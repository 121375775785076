import { Key, ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import type { CSSProperties } from 'styled-components';
import { Ticket, TicketSortDirection, TicketSortingType } from '../../../../../api/apiTypes/ticketingApiTypes';
import { SvgIcon } from '../../../../../components';
import { getDeviceLanguageDateFormat } from '../../../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import useScrollToElement from '../../../../../utility/hooks/useScrollToElement';
import { useGetTicketPriorities, useGetTicketStatuses } from '../../../apiQueries/useTicketingTicket';
import { TicketingContext } from '../../../Ticketing';
import useTicketingNavigation from '../../../useTicketingNavigation';
import { Columns, getViewColumns } from '../../../utils';
import { placeholderOption, TicketPriorityComponent, TicketStatusBadge } from '../../Ticket/TicketDetails/components';
import { TicketListView } from '../UserTickets';
import { Table, OrderEnum } from './../../../../../components/table';
import UserTicketSkeletonLoader from './UserTicketSkeletonLoader';

const TableContainer = styled.div``;

type TableProps = { collapse?: boolean };
const StyledTable = styled(Table)`
    tr {
        ${({ collapse }: TableProps) =>
            collapse && {
                display: 'grid',
                gridTemplateAreas: `'. ${Columns.CaseNumber} ${Columns.Status}'
                '. ${Columns.LastUpdated} ${Columns.Status}'
                '${Columns.Notification} ${Columns.Subject} ${Columns.Subject}'
                '${Columns.Priority} ${Columns.Subject} ${Columns.Subject}'
                '. ${Columns.Requester} ${Columns.Requester}'
                '. ${Columns.Company} ${Columns.Company}'
                '. ${Columns.Location} ${Columns.Location}'
                '. ${Columns.Created} ${Columns.Created}'`,
                gridTemplateColumns: '3.5rem auto auto',
            }}
    }
    td {
        ${({ collapse }: TableProps) =>
            collapse && {
                padding: '0.4rem',
                marginBottom: 0,
                ':before': {
                    content: 'none',
                },
            }};
    }
`;

const MessageNotificationContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.286rem;
    grid-area: ${Columns.Notification};

    svg {
        min-width: 1.71rem;
        min-height: 1.71rem;
    }
`;

const NumbersOfNotifications = styled.p`
    min-width: 2rem;
    font-size: 0.857rem;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const TableCell = styled.p`
    font-size: 1.143rem;
    margin: 0;
`;

const SubjectLabelContainer = styled.div`
    display: flex;
`;

const SubjectLabelText = styled(TableCell)`
    padding-right: 2.857rem;
`;

const TypeCategoryLabelText = styled(TableCell)`
    padding-right: 1.143rem;
`;

const SubjectTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Subject = styled(TableCell)`
    letter-spacing: 0.2px;
`;

const PriorityLabel = styled(TableCell)`
    padding-left: 0.5rem;
`;

const SubjectText = styled.p`
    font-size: 0.857rem;
    margin: 0;
`;

const StatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.571rem;
    min-width: 4.763rem;
`;

const LastUpdated = styled(TableCell)``;

const LastUpdatedText = styled.p`
    color: #26323899;
    padding-right: 0.3rem;
    font-size: 0.857rem;
    margin: 0;
`;

const LastUpdatedDate = styled.span`
    color: initial;
    font-size: 0.857rem;
`;

const Created = styled(TableCell)``;
const CreatedContainer = styled.div`
    p {
        margin: 0;
        font-size: 1rem;
    }
`;
const CreatedText = styled.p`
    color: #26323899;
    padding-right: 0.3rem;
`;

const CreatedDate = styled.span`
    color: initial;
`;

const Location = styled.div`
    display: flex;
    flex-direction: column;
`;

const Asset = styled(TableCell)``;
const Assignee = styled(TableCell)``;
const Requester = styled(TableCell)``;
const Company = styled(TableCell)``;

export type ColumnType = {
    name: string;
    path: TicketSortingType;
    key: Key;
    label: string | ReactElement;
    width: string;
    content?(ticket: Ticket): JSX.Element | null;
    headerOnly?: boolean;
    cellColSpan?: number;
    style?: CSSProperties;
}[];

interface UserTicketTableProps {
    tickets: Ticket[];
    onSort(sortColumn: { path: TicketSortingType; order: TicketSortDirection }): void;
    sortColumn: { path: TicketSortingType; order: TicketSortDirection };
    includeColumns?: string[];
    view: TicketListView;
    isLoading: boolean;
    collapse?: boolean;
}

const UserTicketTable = ({
    tickets,
    onSort,
    sortColumn,
    view,
    isLoading,
    collapse,
}: UserTicketTableProps): ReactElement => {
    const { state: TicketContextState, dispatch } = useContext(TicketingContext);
    const { data: statusOptions } = useGetTicketStatuses();
    const { data: priorityOptions } = useGetTicketPriorities();
    const { goToTicketLink } = useTicketingNavigation();
    const { t } = useTranslation('Ticketing');
    useScrollToElement(TicketContextState.fromTicketNumber?.toString());

    const mappedTickets = useMemo(() => {
        return tickets.map((ticket) => {
            return { ...ticket, id: ticket.number };
        });
    }, [tickets]);

    const onRowClickHandler = (itemId: string) => {
        dispatch({ type: 'UPDATE_FROM_TICKET_NUMBER', payload: itemId });
        goToTicketLink(view, itemId, 'comments', true);
    };

    const onSortHandler = (sortColumn: { path: TicketSortingType; order: OrderEnum }) => {
        const modifiedOrder = sortColumn.order === 'asc' ? TicketSortDirection.Asc : TicketSortDirection.Desc;
        onSort({ path: sortColumn.path, order: modifiedOrder });
    };

    const modifiedSortColumn = useMemo(() => {
        const modifiedOrder = sortColumn.order === TicketSortDirection.Asc ? 'asc' : 'desc';
        return { path: sortColumn.path, order: modifiedOrder };
    }, [sortColumn]);

    const allColumns = useMemo((): ColumnType => {
        const getStatusElement = (ticketStatus: string): JSX.Element | null => {
            const selectedOption = statusOptions?.find((status: string) => status === ticketStatus);
            return selectedOption ? (
                <TicketStatusBadge status={selectedOption}>{selectedOption}</TicketStatusBadge>
            ) : null;
        };

        const getPriorityElement = (ticketPriority: string): JSX.Element | null => {
            const selectedOption = priorityOptions?.find((priority: string) => priority === ticketPriority);
            return selectedOption ? <TicketPriorityComponent priority={selectedOption} /> : null;
        };

        const allColumns: ColumnType = [
            {
                name: 'notifications',
                path: TicketSortingType.Notification,
                key: Columns.Notification,
                label: <SvgIcon name="MessageIcon" />,
                width: '5%',
                style: { gridArea: Columns.Notification, gridRowStart: 'auto' },
                content: function renderContent({ unreadCommentsAndInternalNotes }: Ticket) {
                    return (
                        <MessageNotificationContainer>
                            {unreadCommentsAndInternalNotes ? (
                                <>
                                    <SvgIcon name="MessageNotificationIcon" />
                                    <NumbersOfNotifications>{unreadCommentsAndInternalNotes}</NumbersOfNotifications>
                                </>
                            ) : (
                                <SvgIcon name="MessageIcon" />
                            )}
                        </MessageNotificationContainer>
                    );
                },
            },
            {
                name: 'priority',
                path: TicketSortingType.Priority,
                key: Columns.Priority,
                label: <PriorityLabel>P</PriorityLabel>,
                width: '5%',
                style: { gridArea: Columns.Priority, gridRowEnd: 'auto' },
                content: function renderContent(ticket: Ticket) {
                    return getPriorityElement(ticket.priority);
                },
            },
            {
                name: 'subject',
                path: TicketSortingType.Subject,
                key: Columns.Subject,
                label: (
                    <SubjectLabelText id="subject">
                        {t('commonTicketing.filterDropdownOptionsSubject')}
                    </SubjectLabelText>
                ),
                width: '20%',
                style: { gridArea: Columns.Subject, margin: 'auto 0' },
                content: function renderContent(ticket: Ticket) {
                    return (
                        <SubjectTextContainer>
                            <Subject>{ticket.title}</Subject>
                            <SubjectText>{`${ticket.type.name} > ${ticket.category.name}`}</SubjectText>
                            {!collapse && <SubjectText>{`${t('commonTicketing.case')} ${ticket.number}`}</SubjectText>}
                        </SubjectTextContainer>
                    );
                },
                cellColSpan: 3,
            },
            {
                name: 'caseNumber',
                path: TicketSortingType.CaseNumber,
                key: Columns.CaseNumber,
                label: <SubjectLabelText id="caseNumber"></SubjectLabelText>,
                width: '20%',
                style: collapse ? { gridArea: Columns.CaseNumber, paddingBottom: 0 } : undefined,
                content: function renderContent(ticket: Ticket) {
                    return (
                        <SubjectText>
                            {t('commonTicketing.case')} {ticket.number}
                        </SubjectText>
                    );
                },
            },
            {
                name: 'type',
                path: TicketSortingType.Type,
                key: Columns.Type,
                label: (
                    <SubjectLabelContainer>
                        <TypeCategoryLabelText id="type">{t('commonTicketing.typeLabel')}</TypeCategoryLabelText>
                        <TypeCategoryLabelText>{'>'}</TypeCategoryLabelText>
                    </SubjectLabelContainer>
                ),
                width: '0%',
                style: { gridArea: Columns.Type },
                headerOnly: true,
            },
            {
                name: 'category',
                path: TicketSortingType.Category,
                key: Columns.Category,
                label: (
                    <TypeCategoryLabelText id="category">{t('commonTicketing.categoryLabel')}</TypeCategoryLabelText>
                ),
                width: '0%',
                style: { gridArea: Columns.Type },
                headerOnly: true,
            },
            {
                name: 'status',
                path: TicketSortingType.Status,
                key: Columns.Status,
                label: placeholderOption('Status').value,
                width: '5%',
                style: collapse
                    ? {
                          gridArea: Columns.Status,
                          alignSelf: 'center',
                          display: 'flex',
                          justifyContent: 'flex-end',
                      }
                    : undefined,
                content: function renderContent(ticket: Ticket) {
                    return <StatusContainer>{getStatusElement(ticket.status)}</StatusContainer>;
                },
            },
            {
                name: 'last update',
                path: TicketSortingType.LastUpdate,
                key: Columns.LastUpdated,
                label: t('commonTicketing.lastUpdated'),
                width: '10%',
                style: collapse ? { gridArea: Columns.LastUpdated, paddingTop: 0, paddingRight: 0 } : undefined,
                content: function renderContent(ticket: Ticket) {
                    return (
                        <LastUpdated>
                            {collapse ? (
                                <>
                                    <LastUpdatedText>
                                        {t('commonTicketing.updated')}:
                                        <LastUpdatedDate>
                                            {getDeviceLanguageDateFormat(
                                                new Date(ticket?.updatedDate),
                                                'MMMM dd, yyyy',
                                            )}
                                        </LastUpdatedDate>
                                    </LastUpdatedText>
                                </>
                            ) : (
                                getDeviceLanguageDateFormat(new Date(ticket?.updatedDate))
                            )}
                        </LastUpdated>
                    );
                },
            },
            {
                name: 'assignee',
                path: TicketSortingType.Assignee,
                key: Columns.Caseworker,
                label: t('commonTicketing.filterDropdownOptionsLastAssignee'),
                width: '10%',
                style: { gridArea: Columns.Caseworker },
                content: function renderContent(ticket: Ticket) {
                    return (
                        <Assignee>
                            {ticket.assignee ? `${ticket.assignee?.firstName} ${ticket.assignee?.lastName}` : '-'}
                        </Assignee>
                    );
                },
            },
            {
                name: 'requester',
                path: TicketSortingType.Requester,
                key: Columns.Requester,
                label: t('commonTicketing.petitioner'),
                width: '10%',
                style: { gridArea: Columns.Requester },
                content: function renderContent(ticket: Ticket) {
                    return <Requester>{`${ticket.registeredBy.firstName} ${ticket.registeredBy.lastName}`}</Requester>;
                },
            },
            {
                name: 'company',
                path: TicketSortingType.Company,
                key: Columns.Company,
                label: t('commonTicketing.filterDropdownOptionsLastCompany'),
                width: '10%',
                style: { gridArea: Columns.Company },
                content: function renderContent(ticket: Ticket) {
                    return <Company>{`${ticket.companyName}`}</Company>;
                },
            },
            {
                name: 'location',
                path: TicketSortingType.Location,
                key: Columns.Location,
                label: t('commonTicketing.placement'),
                width: '10%',
                style: { gridArea: Columns.Location },
                content: function renderContent(ticket: Ticket) {
                    return (
                        <Location>
                            <Asset>{ticket.building?.name ?? '-'}</Asset>
                            {ticket.floor?.name && <Asset>{ticket.floor?.name}</Asset>}
                        </Location>
                    );
                },
            },
            {
                name: 'created date',
                path: TicketSortingType.Created,
                key: Columns.Created,
                label: t('commonTicketing.ticketDetailsCreated'),
                width: '10%',
                style: { gridArea: Columns.Created },
                content: function renderContent(ticket: Ticket) {
                    return collapse ? (
                        <CreatedContainer>
                            <CreatedText>
                                {t('commonTicketing.ticketDetailsCreated')}:
                                <CreatedDate>{getDeviceLanguageDateFormat(new Date(ticket?.createdDate))}</CreatedDate>
                            </CreatedText>
                        </CreatedContainer>
                    ) : (
                        <Created>{getDeviceLanguageDateFormat(new Date(ticket?.createdDate))}</Created>
                    );
                },
            },
        ];

        return getViewColumns(view, allColumns, collapse);
    }, [priorityOptions, statusOptions, view, collapse]);

    const filteredColumns = useMemo(() => {
        if (collapse) {
            return allColumns.filter(
                (column) => column.path !== TicketSortingType.Type && column.path !== TicketSortingType.Category,
            );
        } else return allColumns;
    }, [allColumns, collapse]);

    return (
        <TableContainer>
            <StyledTable
                isLoading={isLoading}
                columns={filteredColumns}
                sortColumn={modifiedSortColumn}
                onSort={onSortHandler}
                data={mappedTickets}
                onRowClick={onRowClickHandler}
                collapse={collapse}
                noResultMessage={t('commonTicketing.noResult')}
            />
            {isLoading && (
                <UserTicketSkeletonLoader
                    numberOfItems={20}
                    show={isLoading}
                    collapse={collapse}
                    columns={allColumns}
                />
            )}
        </TableContainer>
    );
};

export default UserTicketTable;
