import { PropsWithChildren, ReactElement, createContext, useContext, useMemo } from 'react';
import type { IsFetchingMoreValue } from 'react-query';
import { useSelector } from 'react-redux';
import { IRootStateProps } from '../../../reducers';
import { NotificationDto, UpdateNotificationStatusType } from '../../api/apiTypes/msgApiTypes';
import { useGetInfiniteNotifications, useUpdateNotificationStatuses } from './hooks/useNotifications';

export interface NotificationContextProps {
    notifications: NotificationDto[] | [];
    isLoadingNotifications: boolean;
    errorLoadingNotifications: boolean;
    isLoadingMoreNotifications: IsFetchingMoreValue;
    errorLoadingMoreNotifications: boolean;
    fetchMore: () => void;
    canFetchMore?: boolean;
    updateNotificationStatuses: (notificationIds: string[], status: UpdateNotificationStatusType) => void;
    updateSeenNotifications: (notification: NotificationDto) => void;
}

const initialState: NotificationContextProps = {
    notifications: [],
    isLoadingNotifications: false,
    errorLoadingNotifications: false,
    isLoadingMoreNotifications: false,
    errorLoadingMoreNotifications: false,
    fetchMore: () => null,
    canFetchMore: false,
    updateNotificationStatuses: () => null,
    updateSeenNotifications: () => null,
};

export const NotificationContext = createContext<NotificationContextProps>({
    ...initialState,
});

const NotificationContextProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const { selectedLang } = useSelector((state: IRootStateProps) => state.app);

    const {
        data,
        isLoading: isLoadingNotifications,
        isError: errorLoadingNotifications,
        fetchMore,
        isFetchingMore,
        canFetchMore,
    } = useGetInfiniteNotifications(selectedLang);

    //TODO - Handling of loading and error missing
    const [updateNotificationStatuses] = useUpdateNotificationStatuses(selectedLang);

    const notifications = useMemo(() => (data ? data.flat() : []), [data]);

    const handleFetchMore = () => {
        if (!isFetchingMore) fetchMore();
    };

    const updateNotificationStatusesHandler = (notificationIds: string[], status: UpdateNotificationStatusType) => {
        updateNotificationStatuses([notificationIds, status]);
    };

    const updateSeenNotificationsHandler = (notification: NotificationDto) => {
        updateNotificationStatusesHandler([notification.id], UpdateNotificationStatusType.Read);
    };

    const value: NotificationContextProps = {
        notifications,
        isLoadingNotifications,
        errorLoadingNotifications,
        isLoadingMoreNotifications: isFetchingMore ?? false,
        errorLoadingMoreNotifications: false,
        fetchMore: handleFetchMore,
        canFetchMore,
        updateNotificationStatuses: updateNotificationStatusesHandler,
        updateSeenNotifications: updateSeenNotificationsHandler,
    };

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

function useNotification(): NotificationContextProps {
    return useContext(NotificationContext);
}

export { NotificationContextProvider, useNotification };
