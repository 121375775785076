import { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import NameInputHandler from '../../modules/Dashboard/Articles/Categories/components/NameInputHandler';
import { useUpdateProductCategoryById } from '../../modules/ShopAtWork/apiQueries/useShopFilters';
import { SvgIcon } from '../index';
import { AccordionContextMenu } from './AccordionContextMenu';
import { AccordionCount } from './AccordionCount';
import { StyledAccordion } from './StyledAccordion';
import {
    AccordionChildrenContainer,
    StyledAccordionTab,
    IconButton,
    StyledNameInputWrapper,
} from './StyledAccordionTab';
import { AccordionTabProps } from './types';
import { getAmountWithSuffix } from './utils';

export const AccordionTab = ({
    children,
    name,
    id,
    amountOfChildren,
    amountOfSubElements,
    suffix,
    contextMenuActions,
    hasParentAccordionTab,
    setFocusId,
    focusId,
    isEditable,
}: PropsWithChildren<AccordionTabProps>): ReactElement => {
    const [isTabSelected, setIsTabSelected] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [updateProductCategoryById] = useUpdateProductCategoryById();
    const onSave = (name: string) => {
        updateProductCategoryById({ name, id });
    };
    const onClick = () => {
        if (amountOfChildren) {
            setIsTabSelected((prev) => !prev);
        }
    };

    useEffect(() => {
        if (!amountOfChildren && isTabSelected) {
            setIsTabSelected(false);
        }
    }, [amountOfChildren]);

    return (
        <StyledAccordion aria-expanded={children ? isTabSelected : false}>
            <StyledAccordionTab
                selected={children ? isTabSelected : false}
                onClick={onClick}
                hasChildren={!!amountOfChildren}
                hasParentAccordionTab={hasParentAccordionTab}
            >
                <StyledNameInputWrapper>
                    <NameInputHandler
                        key={name}
                        name={name}
                        onSave={onSave}
                        inputId={id}
                        inputRef={inputRef}
                        focusId={focusId}
                        setFocusId={setFocusId}
                        isEditable={isEditable}
                    />
                </StyledNameInputWrapper>
                <AccordionCount>
                    {/*check on undefined because amountOfSubElements can be 0, in boolean contexts it is false*/}
                    {suffix && typeof amountOfSubElements !== 'undefined'
                        ? getAmountWithSuffix(amountOfSubElements, suffix)
                        : amountOfSubElements}
                </AccordionCount>
                {children && !!amountOfChildren ? (
                    <IconButton>
                        <AccordionCount>{amountOfChildren}</AccordionCount>
                        <SvgIcon name="CaretDown" alt="se underkategorier" />
                    </IconButton>
                ) : null}
                <AccordionContextMenu
                    actions={contextMenuActions}
                    id={id}
                    amountOfSubElements={amountOfSubElements}
                    name={name}
                />
            </StyledAccordionTab>
            {children && isTabSelected ? <AccordionChildrenContainer>{children}</AccordionChildrenContainer> : null}
        </StyledAccordion>
    );
};
