import { useContext, useCallback, useMemo, useState, useEffect, ReactElement, BaseSyntheticEvent } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Tab, Label, Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import * as yup from 'yup';
import { appOperations } from '../../../../duck';
import { VehicleDto, ModuleName, OwnUser } from '../../../api/apiTypes/portalApiTypes';
import { useModuleIsActive, useNavigation } from '../../../utility';
import useGetNavigationUrl from '../../../utility/hooks/useGetNavigationUrl';
import { LanguagesEnum } from '../../../utility/languageUtilities/language.enum';
import ApproveUser from '../../ApprovePrivacyPolicy/components/ApproveUser';
import { ApplicationPaths, QueryParameterNames } from '../../Authorization/ApiAuthorizationConstants';
import useCurrentUserContext from '../../Contexts/useCurrentUserContext';
import * as api from '../api';
import { useGetUserByIdInfo } from '../apiQueries/useGetUserByIdInfo';
import { MyProfileContext } from '../context';
import { phoneRegExp, updateCurrentUserProfileData } from '../utils';
import SwitchPortalModalWindow, { SelectPortalPageName } from './SwitchPortalModal';
import UpdateAvatarComponent from './UpdateAvatarComponent';
import Vehicle from './VehicleComponent/Vehicle';

export enum VehicleControlActions {
    Create = 'Create',
    Edit = 'Edit',
    Remove = 'Remove',
}
const AvatarSection = styled.div`
    display: flex;
    flex: 1;
    flex-grow: 0;
    justify-content: center;
    background-color: #ffffff;
    padding-top: calc(var(--spacer) * 8);
    padding-bottom: calc(var(--spacer) * 8);
    border-bottom: 1px solid rgba(38, 50, 56, 0.12);
    flex-basis: auto;
`;

const AvatarWrapper = styled.div`
    position: relative;
    height: 100%;
`;

const AvatarLabel = styled(Label)`
    &&& {
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        background-color: var(--primary-color);
    }
`;

const ActionsSection = styled.div`
    display: flex;
    flex: 1;
    flex-basis: auto;
    flex-grow: 0;
    justify-content: center;
    background-color: #fbfbfb;
    padding-top: var(--spacer);
    padding-bottom: var(--spacer);
    border-bottom: 1px solid rgba(38, 50, 56, 0.12);
    min-height: 3em;
`;

const Form = styled.form`
    display: 'flex';
    flex-direction: 'column';
    flex-grow: 1;
    background: 'var(--surface-color-light)';
`;

const StyledButton = styled(Button)`
    &&& {
        background-color: transparent;
        padding: 0.5em;

        &:hover {
            background-color: transparent;
        }
    }
`;

const ButtonIcon = styled(Icon)`
    &&& {
        color: var(--primary-color);
        font-size: 12px;
    }
`;

const ButtonText = styled.span`
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    color: var(--primary-color);
`;

const ProfileSection = styled.div`
    flex-grow: 1;
    padding: calc(var(--spacer) * 3) calc(var(--spacer) * 8.75) var(--spacer) calc(var(--spacer) * 8.75);
`;

const SaveSection = styled.div`
    flex-grow: 0;
    padding: var(--spacer);
`;

const SaveButton = styled(Button)`
    &&& {
        height: 3.571rem;
        border: 1px solid var(--primary-color);
    }
`;

const SaveButtonText = styled.span`
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: var(--primary-color);
`;

export type ProfileForm = {
    approved: boolean;
    firstname: string;
    lastname: string;
    email: string;
    phonenumber: string;
    language: string;
};

interface ProfileProps {
    user: OwnUser;
    onClickPrivacyPolicy: () => void;
}

const Profile = ({ user, onClickPrivacyPolicy }: ProfileProps): ReactElement => {
    const nav = useNavigation();
    const getNavigationUrl = useGetNavigationUrl();
    const { i18n, t } = useTranslation('myProfile');
    const { t: tCommon } = useTranslation('common');

    const { dispatch } = useContext(MyProfileContext);
    const reduxDispatch = useDispatch();
    const { dispatch: globalDispatch } = useCurrentUserContext();
    const { data: userDataByCompanyInfo } = useGetUserByIdInfo();
    const { vehicles } = userDataByCompanyInfo ?? {};

    const parkingModuleIsActivated = useModuleIsActive(ModuleName.Parking);
    const [phoneInputError, setPhoneInputError] = useState(
        user.phoneNumber
            ? { value: 0, errorMessage: '' }
            : {
                  value: 14,
                  errorMessage: tCommon('errorMessagePhoneNumberRequired'),
              },
    );
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const [openSwitchPortalModalWindow, setOpenSwitchPortalModalWindow] = useState(false);

    const [selectedVehicle, setSelectedVehicle] = useState<VehicleDto | undefined>(undefined);
    const [vehicleModalType, setVehicleModalType] = useState<false | VehicleControlActions>(false);
    const userHasMultiplePortals = user.portals?.length ?? 0 > 1;

    const validationSchema = useMemo(
        () =>
            yup.object({
                approved: yup.boolean().oneOf([true], tCommon('errorMessagePrivacyPolicyMustApproved')),
                firstname: yup.string().required(tCommon('errorMessageFirstNameRequired')),
                lastname: yup.string().required(tCommon('errorMessageLastNameRequired')),
                email: yup
                    .string()
                    .email(tCommon('errorMessageEmailNotValid'))
                    .required(tCommon('errorMessageEmailRequired')),
                phonenumber: yup
                    .string()
                    .required(tCommon('errorMessagePhoneNumberRequired'))
                    .matches(phoneRegExp, tCommon('invalidPhoneNumber')),
                language: yup.string(),
            }),
        [phoneInputError],
    );

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        trigger,
        register,
        setValue,
        getValues,
    } = useForm<ProfileForm>({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            firstname: user.firstName,
            lastname: user.lastName,
            email: user.email,
            phonenumber: user.phoneNumber,
            language: user.language || i18n.language,
        },
    });

    useEffect(() => {
        trigger('phonenumber');
    }, [phoneInputError]);

    const formIsValid = useCallback((errors: FieldErrors) => {
        if (Object.keys(errors || {}).length > 0) {
            return false;
        }

        return true;
    }, []);

    const getInitials = useCallback(() => {
        let initials = '';
        if (user.firstName && user.lastName) {
            initials = `${user.firstName.substring(0, 1)}${user.lastName.substring(0, 1)}`;
        }
        return initials;
    }, [user]);

    const submitForm = async (data: ProfileForm) => {
        const { language, email, firstname, lastname, phonenumber } = data;

        try {
            setIsSubmittingForm(true);
            await api.updateProfile(
                { ...user, email: email, firstName: firstname, lastName: lastname, phone: phonenumber, language },
                dispatch,
            );
            updateCurrentUserProfileData(
                data.firstname,
                data.lastname,
                data.email,
                data.phonenumber,
                data.language,
                globalDispatch,
            );
            reduxDispatch(appOperations.setSelectedLang(language as LanguagesEnum));
            const shouldReload = user.language !== language;
            shouldReload && window.location.reload();
        } catch (ex) {
            console.error(ex);
        } finally {
            setIsSubmittingForm(false);
        }
    };

    const onSubmit = (data: ProfileForm, e: BaseSyntheticEvent<object, any, any> | undefined) => {
        if (e instanceof Event) e.preventDefault();
        if (formIsValid(errors)) submitForm(data);
    };

    const handleLogOut = () => {
        nav.push(`${ApplicationPaths.LogOut}?${QueryParameterNames.ReturnUrl}=${getNavigationUrl('/')}`);
    };

    const handleAvatarUpdated = (newValue: string) => {
        globalDispatch({ type: 'UPDATE_CURRENT_USER_AVATAR', payload: newValue });
    };

    const handleSwitchPortal = () => {
        setOpenSwitchPortalModalWindow(true);
    };

    const manageVehicleItem = (
        event: React.MouseEvent<HTMLButtonElement>,
        actionType: VehicleControlActions,
        vehicle?: VehicleDto,
    ) => {
        event.preventDefault();
        setVehicleModalType(actionType);
        setSelectedVehicle(vehicle);
    };

    return (
        <Tab.Pane
            as={'div'}
            attached={'bottom'}
            style={{
                margin: 0,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <AvatarSection>
                <AvatarWrapper>
                    <UpdateAvatarComponent avatarUrl={user.avatar} onAvatarUpdated={handleAvatarUpdated} />
                    <AvatarLabel circular size="big">
                        {getInitials()}
                    </AvatarLabel>
                </AvatarWrapper>
            </AvatarSection>
            <ActionsSection>
                {userHasMultiplePortals ? (
                    <StyledButton onClick={handleSwitchPortal}>
                        <ButtonIcon name="sync" />
                        <ButtonText>{t('switchPortal')}</ButtonText>
                    </StyledButton>
                ) : null}
                <StyledButton onClick={handleLogOut}>
                    <ButtonIcon name="user" />
                    <ButtonText>{t('logOut')}</ButtonText>
                </StyledButton>
                <StyledButton onClick={onClickPrivacyPolicy}>
                    <ButtonIcon name="check" />
                    <ButtonText>{t('seePrivacy')}</ButtonText>
                </StyledButton>
            </ActionsSection>
            <Form onSubmit={handleSubmit((data, e) => onSubmit(data, e))}>
                <ProfileSection>
                    <ApproveUser
                        control={control}
                        errors={errors}
                        emailIsReadOnly={true}
                        onError={(value, errorMessage) => {
                            setPhoneInputError({ value, errorMessage });
                        }}
                        showInfo={false}
                        register={register}
                        touchedFields={touchedFields}
                        trigger={trigger}
                        setValue={setValue}
                        getValues={getValues}
                    />

                    {parkingModuleIsActivated ? (
                        <Vehicle
                            manageVehicleItem={manageVehicleItem}
                            vehicleModalType={vehicleModalType}
                            selectedVehicle={selectedVehicle}
                            setVehicleModalType={setVehicleModalType}
                            vehicles={vehicles ?? []}
                        />
                    ) : null}
                </ProfileSection>
                <SaveSection>
                    <SaveButton
                        fluid
                        basic
                        loading={isSubmittingForm}
                        type="submit"
                        disabled={Object.keys(errors || {}).length > 0 ? true : false}
                    >
                        {!isSubmittingForm && <SaveButtonText>{tCommon('saveButton')}</SaveButtonText>}
                    </SaveButton>
                </SaveSection>
                {openSwitchPortalModalWindow && (
                    <SwitchPortalModalWindow
                        headerText={t('switchPortal')}
                        hideCloseButton={false}
                        portals={user.portals}
                        user={user}
                        onClose={() => setOpenSwitchPortalModalWindow(false)}
                        pageName={SelectPortalPageName.Profile}
                    />
                )}
            </Form>
        </Tab.Pane>
    );
};

export default Profile;
