import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import CustomErrorBoundary from '../Error/CustomErrorBoundary';
import { MyProfileProvider } from './context';
import MyProfileContainer, { MyProfileProps } from './MyProfile';

const ErrorContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MyProfile = ({ defaultTab }: MyProfileProps): ReactElement => {
    const { t: tCommon } = useTranslation('common');

    return (
        <CustomErrorBoundary
            errorComponent={({ reset }: { reset: () => void }) => (
                <ErrorContainer>
                    <Button onClick={reset}>{tCommon('errorOccurredTryAgain')}</Button>
                </ErrorContainer>
            )}
        >
            <MyProfileProvider>
                <MyProfileContainer defaultTab={defaultTab} />
            </MyProfileProvider>
        </CustomErrorBoundary>
    );
};

export default MyProfile;
