import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Icon, Button, Label, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { IRootStateProps } from '../../../../reducers';
import { MobileViewBreakpoint } from '../../../common/ScreenSizeBreakPoints';
import { useNavigation } from '../../../utility';
import { getDeviceLanguageDateFormat } from '../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import { calculateGrossSum } from '../utils';
import { SelectedItem } from './components';
import { ShoppingCartContext } from '.';

const ShoppingCartWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const ShoppingCartContentWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const ShoppingCartContentHeaderWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    padding: calc(var(--spacer) * 2);
    padding-bottom: 0;
`;

const ShoppingCartContentTitle = styled.span`
    font-size: 16px;
    color: rgba(38, 50, 56, 0.9);
    font-weight: 500;
`;

const DeleteOrderButton = styled(Button)`
    &&& {
        background-color: rgba(38, 50, 56, 0.08);
        color: --text-medium-emphasis-color;
        padding: 0.25rem 0.5rem;
    }
`;

const ShoppingCartItemsWrapper = styled.div`
    background-color: #fbfbfb;
    overflow-y: auto;
    max-height: 715px;
`;

const ShoppingCartFooter = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: calc(var(--spacer) * 1.75) calc(var(--spacer) * 3) calc(var(--spacer) * 1.75) calc(var(--spacer) * 3);
`;

const ShoppingCartSum = styled.span`
    font-size: 20px;
    font-weight: 900;
    color: rgba(38, 50, 56, 0.9);
`;

const ShoppingCart: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { state: shoppingCartState, removeOrder } = useContext(ShoppingCartContext);
    const modules = useSelector<IRootStateProps, { name: string }[]>((state) => state?.app?.modules);
    const nav = useNavigation();
    const location = useLocation();
    const { t } = useTranslation('Food', { keyPrefix: 'shoppingCard' });
    const [cartIsOpen, setCartIsOpen] = useState<boolean>(false);

    const foodlinesLength = shoppingCartState.status === 'empty' ? 0 : shoppingCartState.foodLines.length;
    const shoopingCartLine = shoppingCartState.status === 'empty' ? undefined : shoppingCartState.foodLines;

    const numberOfItemsInCart =
        shoppingCartState.status === 'empty'
            ? 0
            : shoppingCartState.foodLines.reduce((curr, { quantity }) => curr + quantity, 0);
    const shoppingCartLinesRef = useRef(0);
    useEffect(() => {
        if (shoopingCartLine && foodlinesLength > 0 && numberOfItemsInCart !== shoppingCartLinesRef.current) {
            // Only open on desktop and not when confirming the order
            if (
                !window.matchMedia(`(max-width:${MobileViewBreakpoint}px)`)?.matches &&
                location.pathname.toLowerCase().includes('/food/foodorder') &&
                !location.pathname.toLowerCase().includes('/food/foodorder/confirm')
            ) {
                setCartIsOpen(true);
            }
            shoppingCartLinesRef.current = numberOfItemsInCart;
        }
    }, [foodlinesLength, location.pathname, numberOfItemsInCart, shoopingCartLine, shoppingCartState.status]);

    if (!modules || !modules.find((module) => module.name === 'Food')) {
        return null;
    }

    const handleCloseCart = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (!(e.target as HTMLElement).classList.contains('addToCart')) setCartIsOpen(false);
    };

    const handleOpenCart = () => {
        if (window.matchMedia(`(max-width:${MobileViewBreakpoint}px)`)?.matches) {
            handleGoToRegister();
            return;
        }
        setCartIsOpen(true);
    };

    const handleGoToRegister = () => {
        if (!location.pathname.toLowerCase().includes('/food/foodorder/confirm')) {
            nav.push('/Food/FoodOrder/confirm');
        }
        setCartIsOpen(false);
    };

    const grossSumString =
        shoppingCartState.status === 'empty'
            ? 0
            : calculateGrossSum(shoppingCartState.deliveryMethod, shoppingCartState.foodLines)?.toFixed(2);

    return (
        <Popup
            trigger={
                <div>
                    <Button
                        onClick={handleOpenCart}
                        disabled={foodlinesLength === 0}
                        style={{ background: 'none', position: 'relative', padding: '0.8rem' }}
                    >
                        <Icon name="shopping cart" />
                        {numberOfItemsInCart > 0 && (
                            <Label circular color="red" attached="top right" size="small">
                                {numberOfItemsInCart}
                            </Label>
                        )}
                    </Button>
                </div>
            }
            onClose={handleCloseCart}
            position="top right"
            flowing
            style={{
                padding: 0,
                width: '30em',
            }}
            on={['click']}
            open={cartIsOpen && foodlinesLength > 0}
        >
            {foodlinesLength > 0 && shoppingCartState.status !== 'empty' && (
                <ShoppingCartWrapper>
                    <ShoppingCartContentWrapper key={shoppingCartState.deliveryDateTime.toISOString()}>
                        <ShoppingCartContentHeaderWrapper>
                            <ShoppingCartContentTitle>
                                {`${getDeviceLanguageDateFormat(
                                    shoppingCartState.deliveryDateTime,
                                    'd. MMMM HH:mm',
                                )} (kr ${grossSumString})`}
                            </ShoppingCartContentTitle>
                            <DeleteOrderButton
                                size="tiny"
                                onClick={() => removeOrder()}
                                aria-label={`Remove order from cart`}
                            >
                                {t('deleteOrder')}
                            </DeleteOrderButton>
                        </ShoppingCartContentHeaderWrapper>
                        <ShoppingCartItemsWrapper>
                            {shoppingCartState.foodLines.map((orderedLine) => (
                                <SelectedItem {...orderedLine} key={orderedLine.foodLineId} />
                            ))}
                        </ShoppingCartItemsWrapper>
                    </ShoppingCartContentWrapper>
                    <ShoppingCartFooter>
                        <ShoppingCartSum>{t('shoppingCartSum', { grossSumString })}</ShoppingCartSum>

                        <Button
                            onClick={handleGoToRegister}
                            size="large"
                            style={{
                                marginLeft: 'calc(var(--spacer) * 3)',
                                backgroundColor: 'var(--primary-color)',
                                color: '#fff',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                margin: 0,
                            }}
                            aria-label="Go to checkout"
                        >
                            {t('goToCheckout')}
                            <Icon name="arrow right" style={{ opacity: 1 }} />
                        </Button>
                    </ShoppingCartFooter>
                </ShoppingCartWrapper>
            )}
        </Popup>
    );
};

export default ShoppingCart;
