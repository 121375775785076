import { Key, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import type { CSSProperties } from 'styled-components';
import styled from 'styled-components';
import { OrderLine } from '../../../../../api/apiTypes/shopApiTypes';
import { getFormattedCurrency } from '../../../../../utility';

type IsMobileViewProps = { isMobileView: boolean };
const Table = styled.table`
    position: relative;
    border-spacing: 0 1rem;
    border-collapse: collapse;
    padding: 0 0.5rem;
    width: 100%;
    border-bottom: ${({ isMobileView }: IsMobileViewProps) => (isMobileView ? '2px solid #aaaaaa' : 'none')};
`;

const TableHeader = styled.thead``;
const Header = styled.th`
    white-space: nowrap;
    font-size: 1.143rem;
    line-height: 1.714rem;
    text-align: left;
    padding: 0.429rem;
    padding-bottom: 0.714rem;
    border-bottom: 2px solid #aaaaaa;

    &:first-child {
        padding-left: 1rem;
    }

    :focus-visible,
    :focus {
        outline: none;
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

const Row = styled.tr`
    border-bottom: 1px solid #aaaaaa;
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding: 0.714rem 0.429rem;
    word-break: break-word;
    max-width: 0;

    &:first-child {
        padding-left: 1rem;
        min-width: 3.7rem;
    }
`;

const OrderLineCell = styled.p`
    font-size: 1.143rem;
    margin: 0;
`;

const OrderLineCellBold = styled(OrderLineCell)`
    font-weight: 700;
`;

const ProductContainer = styled.div`
    font-size: 1rem;
`;

const ProductTitle = styled.p`
    font-weight: 700;
`;

const ProductDescription = styled.p`
    font-weight: 400;
`;

const RightAligned = styled.div`
    text-align: right;
`;

export enum ProductsColumns {
    Products = 'products',
    Amount = 'amount',
    Price = 'price',
    MVA = 'mva',
    Total = 'total',
}

export type ColumnType = {
    name: string;
    key: Key;
    label: string | ReactElement;
    width: string;
    content?(orderLine: OrderLine): JSX.Element | null;
    headerOnly?: boolean;
    cellColSpan?: number;
    style?: CSSProperties;
}[];

type ProductsTableProps = {
    orderLines: OrderLine[];
    isMobileView: boolean;
};
const ProductsTable = ({ orderLines, isMobileView }: ProductsTableProps): ReactElement => {
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'orderOverview' });

    const allColumns: ColumnType = [
        {
            name: 'products',
            key: ProductsColumns.Products,
            label: t('tableLabelProducts'),
            width: '40%',
            content: function renderContent(orderLine: OrderLine) {
                return (
                    <ProductContainer>
                        <ProductTitle>{orderLine.title}</ProductTitle>
                        <ProductDescription>{orderLine.description}</ProductDescription>
                    </ProductContainer>
                );
            },
        },
        {
            name: 'amount',
            key: ProductsColumns.Amount,
            label: t('tableLabelAmount'),
            width: '15%',
            content: function renderContent(orderLine: OrderLine) {
                return <OrderLineCell>{orderLine.amount}</OrderLineCell>;
            },
        },
        {
            name: 'price',
            key: ProductsColumns.Price,
            label: t('tableLabelPrice'),
            width: '15%',
            content: function renderContent(orderLine: OrderLine) {
                return <OrderLineCell>{getFormattedCurrency(orderLine.priceOneItem)}</OrderLineCell>;
            },
        },
        {
            name: 'mva',
            key: ProductsColumns.MVA,
            label: t('tableLabelVAT'),
            width: '10%',
            content: function renderContent(orderLine: OrderLine) {
                return <OrderLineCell>{`${getFormattedCurrency(orderLine.taxOneItemPercent)}%`}</OrderLineCell>;
            },
        },

        {
            name: 'total',
            key: ProductsColumns.Total,
            label: t('tableLabelTotal'),
            width: '20%',
            style: { textAlign: 'right' },
            content: function renderContent(orderLine: OrderLine) {
                return (
                    <RightAligned>
                        <OrderLineCellBold>{getFormattedCurrency(orderLine.totalPrice)}</OrderLineCellBold>
                    </RightAligned>
                );
            },
        },
    ];

    const mobileViewColumns: Key[] = [ProductsColumns.Products, ProductsColumns.Amount, ProductsColumns.Total];

    const filteredColumns = isMobileView
        ? allColumns.filter((column) => mobileViewColumns.includes(column.key))
        : allColumns;

    return (
        <Table isMobileView={isMobileView}>
            <TableHeader>
                <Row>
                    {filteredColumns.map((column) => (
                        <Header key={column.key} style={{ width: column.width, ...column.style }} tabIndex={0}>
                            {column.label}
                        </Header>
                    ))}
                </Row>
            </TableHeader>
            <TableBody>
                {orderLines.map((order) => (
                    <Row key={order.id}>
                        {filteredColumns.map((column) => (
                            <TableCell key={order.id + column.key} data-label={`${column.key}:`}>
                                {column.content && column.content(order)}
                            </TableCell>
                        ))}
                    </Row>
                ))}
            </TableBody>
        </Table>
    );
};

export default ProductsTable;
