import { ReactElement, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { FDVIntegrationTypes, TicketType, TypeIntegrationDto } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { GhostPrimaryButton, Modal, ModalType, PrimaryButton, RadioButton } from '../../../../../../components';
import { useUpdateTicketType } from '../../../../apiQueries/useTicketingType';

const Header = styled.div`
    font-weight: 900;
    font-size: 1.285rem;
    line-height: 1.714rem;
`;
const Information = styled.div`
    font-weight: 400;
    font-size: 1.142rem;
    line-height: 1.714rem;
    margin-bottom: 1.714rem;
`;

const UpdatingStatusBar = styled.div`
    font-size: 0.857rem;
    line-height: 1.142rem;
    text-align: end;
    display: flex;
    justify-content: end;
    margin-bottom: 0.5rem;
`;

const LoaderContainer = styled.div``;

const ErrorMessage = styled.p`
    color: var(--error-color);
`;

const FDVStatusList = styled.ul`
    list-style: none;
    padding: 0;

    & :not(:last-child) {
        margin-bottom: 0.5rem;
    }
`;

const FDVStatusItem = styled.li`
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #d4d6d7;
    padding: 0.714rem 1.142rem 0.714rem 4.571rem;
`;
const FDVStatusTitle = styled.p`
    font-size: 0.857rem;
    line-height: 1.428rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
`;

const FDVStatusDesc = styled.p`
    font-size: 0.857rem;
    line-height: 1.142rem;
    margin: 0;
    color: #677074;
`;

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-left: 1rem;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    padding: 1.7rem;
`;

interface FDVIntegrationProps {
    ticketType: TicketType;
    integrationsArray?: TypeIntegrationDto[];
}

const FDVIntegration = ({ ticketType, integrationsArray }: FDVIntegrationProps): ReactElement => {
    const [updateType, { isLoading, isSuccess, isError }] = useUpdateTicketType();
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const { t: tCommon } = useTranslation('common');
    const [integrationId, setIntegrationId] = useState<string | null>('');
    const handleChangeIntegration = (): void => {
        updateType([ticketType.id, { integrationId }]);
        setShowModal(false);
    };

    const integrations = useMemo(() => {
        const noIntegrationItem = { id: null, description: t('restrictSubmittersIncative') };
        const preparedIntegrations = integrationsArray
            ? integrationsArray.map((item) => ({
                  id: item.id,
                  description: item.description,
              }))
            : [];
        return [noIntegrationItem, ...preparedIntegrations];
    }, [integrationsArray]);

    const [showModal, setShowModal] = useState(false);
    const showWarningMessage = (e: SyntheticEvent, id: string | null) => {
        e.stopPropagation();
        setShowModal(true);
        setIntegrationId(id);
    };

    const closeModalHandler = () => {
        setShowModal(false);
    };

    const getIntegrationDescription = (type: string): string => {
        switch (type) {
            case FDVIntegrationTypes.CuroTech:
                return 'FDVIntegrationCuroTechDesc';
            case FDVIntegrationTypes.Famac:
                return 'FDVIntegrationFamacDesc';
            case FDVIntegrationTypes.None:
                return 'FDVIntegrationNoFDV';
            default:
                return t('FDVIntegrationDefaultDesc', { type: type || '' });
        }
    };

    return (
        <>
            <Modal
                type={ModalType.Small}
                open={showModal}
                title={t('changeOfFDVIntegrationTitle')}
                bottomContent={
                    <Container>
                        <GhostPrimaryButton onClick={closeModalHandler}>{tCommon('cancelButton')}</GhostPrimaryButton>
                        <StyledPrimaryButton onClick={handleChangeIntegration}>
                            {tCommon('continue')}
                        </StyledPrimaryButton>
                    </Container>
                }
                onClose={closeModalHandler}
                hideCloseButton
            >
                <ContentContainer>{t('changeOfFDVIntegrationContent')}</ContentContainer>
            </Modal>
            <Header>{t('FDVIntegrationHeader')}</Header>
            <Information>{t('FDVIntegrationInfo')}</Information>
            <UpdatingStatusBar>
                {isLoading && (
                    <LoaderContainer>
                        <Loader active inline size="mini"></Loader>
                    </LoaderContainer>
                )}
                {isSuccess && t('FDVIntegrationSuccessMessage')}
                {isError && <ErrorMessage>{t('FDVIntegrationErrorMessage')}</ErrorMessage>}
            </UpdatingStatusBar>
            <FDVStatusList>
                {integrations?.map((integration) => {
                    return (
                        <FDVStatusItem key={integration.id} onClick={(e) => showWarningMessage(e, integration.id)}>
                            <div>
                                <FDVStatusTitle>{integration.description}</FDVStatusTitle>
                                <FDVStatusDesc>{getIntegrationDescription(integration.description)}</FDVStatusDesc>
                            </div>
                            <RadioButton
                                name={integration.description}
                                value={integration.id || ''}
                                checked={ticketType.integrationId === integration.id}
                            />
                        </FDVStatusItem>
                    );
                })}
            </FDVStatusList>
        </>
    );
};
export default FDVIntegration;
