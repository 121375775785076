export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
};

export const prefix = '/authentication';

export const ApplicationPaths = {
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/login/`,
    LoginCallback: `${prefix}/login-callback/`,
    SilentCallback: `${prefix}/silent-callback/`,
    LogOut: `${prefix}/logout/`,
    LogOutCallback: `${prefix}/logout-callback/`,
};
