import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TicketComment, TICKET_SOLVED_GUID } from '../../../../../../../api/apiTypes/ticketingApiTypes';
import { ImageView } from '../../../../common';
import { MessageDetails, ReplyButton, SeenStatus } from '../index';
import { TicketSolvedComment } from './components/index';

type CardProps = { highlighted: boolean };
const Card = styled.div`
    border: 1px solid ${({ highlighted }: CardProps) => (highlighted ? '#001EFF' : '#d4d6d7')};
    border-radius: 5px;
    width: 100%;
    display: flex;
    background-color: ${({ highlighted }: CardProps) => (highlighted ? '#F6FCFF' : 'none')};
    transition: 0.5s ease-out all;
    word-break: break-word;
`;

const Content = styled.div`
    margin: 1.143rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

const MessageDetailsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Message = styled.p`
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding: 1.143rem 0;
    white-space: pre-line;
`;

const StyledImageView = styled(ImageView)`
    && {
        width: 240px;
    }
`;

const ReplyButtonContainer = styled.div`
    align-self: flex-end;
`;

const ImagesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1.143rem;
`;
interface MessageCardProps {
    highlighted: boolean;
    seenByReporter?: boolean;
    comment: TicketComment;
    onReply?(): void;
    showMessageDetails?: boolean;
}

const MessageCard = ({
    highlighted,
    onReply,
    seenByReporter = false,
    comment,
    showMessageDetails = true,
}: MessageCardProps): ReactElement => {
    const { t } = useTranslation('Ticketing');
    return (
        <Card highlighted={highlighted}>
            <Content>
                {showMessageDetails && (
                    <MessageDetailsContainer>
                        <MessageDetails user={comment.registeredBy} sentDate={new Date(comment.createdDate)} />
                        {seenByReporter && <SeenStatus />}
                    </MessageDetailsContainer>
                )}
                {comment.description === TICKET_SOLVED_GUID ? (
                    <TicketSolvedComment />
                ) : (
                    <>
                        <Message>{comment.description}</Message>
                        <ImagesContainer>
                            {comment.ticketImages?.map((image, index) => (
                                <StyledImageView
                                    key={index + image.imageUrl}
                                    image={image}
                                    alt={t('commonTicketing.inquiryComment')}
                                />
                            ))}
                        </ImagesContainer>

                        {onReply && (
                            <ReplyButtonContainer>
                                <ReplyButton onClick={onReply} />
                            </ReplyButtonContainer>
                        )}
                    </>
                )}
            </Content>
        </Card>
    );
};

export default MessageCard;
