import { ReactElement } from 'react';
import styled from 'styled-components';
import { Tooltip } from '../../components/index';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const WifiAtWorkLogo = styled.picture`
    object-fit: cover;
    width: 6.929rem;
`;
interface WifiAtWorkTooltipProps {
    children: ReactElement;
    open: boolean;
    onClose(): void;
}

const WifiAtWorkTooltip = ({ children, open, onClose }: WifiAtWorkTooltipProps): ReactElement => {
    return (
        <Tooltip open={open} onClose={onClose}>
            <Content>
                <WifiAtWorkLogo aria-hidden="true">
                    <source srcSet="./WifiAtWorkLogo.webp" type="image/webp" />
                    <source srcSet="./WifiAtWorkLogo.png" type="image/png" />
                    <WifiAtWorkLogo as="img" src="WifiAtWorkLogo.png" alt="Wifi@Work logo" />
                </WifiAtWorkLogo>
                {children}
            </Content>
        </Tooltip>
    );
};

export default WifiAtWorkTooltip;
