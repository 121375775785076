import { KeyboardEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DateInput, PrimaryButton, SvgIcon } from '../..';
import { useIsMobile } from '../../../utility/hooks/useIsMobile';
import useKeyboardOutsideFocusDetection from '../../../utility/hooks/useKeyboardOutsideFocusDetection';
import useOnMouseClickOutsideElement from '../../../utility/hooks/useMouseClickOutsideElement';
import { Position, useFitPosition } from '../searchSelect/useFitPosition';

export enum FilterView {
    Desktop = 'desktop',
    Mobile = 'mobile',
}
interface SelectWrapperType {
    position: Position;
    view: FilterView;
}

interface ArrowContainerType {
    isOpen: boolean;
    isGroupHeadingArrow?: boolean;
}

type ContainerProps = {
    view: FilterView;
};

const Container = styled.div`
    position: relative;
    display: ${({ view }: ContainerProps) => (view === FilterView.Desktop ? 'inline-flex' : 'flex')};
    flex-direction: column;
`;

const SelectWrapper = styled.div`
    position: ${({ view }: SelectWrapperType) => (view === FilterView.Desktop ? 'absolute' : 'static')};
    z-index: 1000;
    top: 2.857rem;
    right: ${({ position }: SelectWrapperType) => (position === Position.Right ? '0' : 'auto')};
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.142rem;
    background-color: white;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.15);
`;

const DatePickersWrapper = styled.div`
    margin-bottom: 0.714rem;
`;

const ButtonControlsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledDateInput = styled(DateInput)`
    padding: 0.67857143em 1em;
    min-width: 15rem;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    align-self: end;
`;
interface ButtonWrapperProps {
    view: FilterView;
}
const ButtonWrapper = styled.div`
    cursor: pointer;
    background-color: var(--primary-on-color);
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    height: 2.857rem;
    display: flex;
    align-items: center;
    justify-content: ${({ view }: ButtonWrapperProps) => (view === FilterView.Mobile ? 'center' : 'space-between')};
    gap: 0.571rem;
    width: 100%;
    padding: ${({ view }: ButtonWrapperProps) => (view === FilterView.Mobile ? '0.857rem' : '0 1.143rem;')};

    :focus-visible {
        outline: 2px solid var(--primary-color);
        outline-offset: 2px;
        border-radius: 5px;
    }
`;

const ButtonTextWrapper = styled.div`
    display: flex;
    min-width: 0;
`;

const ButtonText = styled.p`
    font-weight: 700;
    font-size: 1.143rem;
    line-height: 1.714rem;
    color: var(--primary-color);
    user-select: none;
    margin: 0;

    &:first-child {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 0.357rem;
    }
`;

const ArrowButtonIcon = styled.div`
    display: inline-flex;
    margin-left: ${({ isGroupHeadingArrow }: ArrowContainerType) => (isGroupHeadingArrow ? 'auto' : '0')};
    padding-left: ${({ isGroupHeadingArrow }: ArrowContainerType) => (isGroupHeadingArrow ? '0.357rem' : '0')};

    svg {
        width: 0.857rem;
        height: 0.857rem;
        transform: ${({ isOpen }: ArrowContainerType) => (isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};

        path {
            fill: var(--primary-color);
        }
    }
`;

const ResetButtonIcon = styled.div`
    display: inline-flex;
    margin-right: 0.571rem;

    svg {
        width: 0.714rem;
        height: 0.714rem;

        path {
            fill: var(--primary-color);
        }
    }

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
        outline-offset: 3px;
        outline-style: auto;
    }
`;

export type TimePeriodTuple = [Date, Date];

interface TimeSelectType {
    onChange: (period: TimePeriodTuple) => void;
    value: TimePeriodTuple | [];
    nameStartField: string;
    nameEndField: string;
    title: string | string[];
    onResetOptions: () => void;
    className?: string;
    autoMenuPosition?: boolean;
    expanded?: boolean;
    onClose?(): void;
    minDate?: Date;
    maxDate?: Date;
    view?: FilterView;
    isOutsideClickCloser?: boolean;
}

const TimeRangeSelect = ({
    onChange,
    value,
    nameStartField,
    nameEndField,
    title,
    onResetOptions,
    className,
    autoMenuPosition = false,
    expanded = false,
    onClose,
    minDate,
    maxDate,
    view = FilterView.Desktop,
    isOutsideClickCloser,
}: TimeSelectType): ReactElement => {
    const { t } = useTranslation('common');
    const isMobile = useIsMobile();
    const [isSelectVisible, setIsSelectVisible] = useState(expanded);
    const containerRef = useRef<HTMLDivElement>(null);
    const selectWrapperRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<any>(null);
    const fitPosition = useFitPosition(selectWrapperRef, isSelectVisible);
    const [startTime, endTime] = value;
    const [selectedStartDate, setSelectedStartDate] = useState(startTime);
    const [selectedEndDate, setSelectedEndDate] = useState(endTime);

    useEffect(() => {
        setSelectedStartDate(startTime);
    }, [startTime]);

    useEffect(() => {
        setSelectedEndDate(endTime);
    }, [endTime]);

    const resetFilter = () => {
        setIsSelectVisible(false);
    };

    const isDesktop = view === FilterView.Desktop && !isMobile && !isOutsideClickCloser;
    useOnMouseClickOutsideElement(containerRef, resetFilter, isDesktop);
    useKeyboardOutsideFocusDetection(containerRef, resetFilter, isDesktop);

    const handleKeyControl = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Tab' && view !== FilterView.Mobile) setIsSelectVisible((p) => !p);
    };
    const onKeyDownHandler = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Tab' && view !== FilterView.Mobile) setIsSelectVisible(false);
    };

    useEffect(() => {
        if (isSelectVisible) selectRef?.current?.focus();
    }, [isSelectVisible]);

    const handleButtonClick = () => {
        if (view === 'mobile') {
            onClose && onClose();
            return;
        }
        setIsSelectVisible((p) => !p);
    };

    const handleStartTimeChange = (newDate: Date) => {
        const updatedStartDate = new Date(newDate.setHours(0, 0));
        setSelectedStartDate(updatedStartDate);
    };

    const handleEndTimeChange = (newDate: Date) => {
        const updatedEndDate = new Date(newDate.setHours(23, 59));
        setSelectedEndDate(updatedEndDate);
    };

    const checkDaysBetweenDates = (startDate?: Date, endDate?: Date): number | null => {
        if (!!startDate && !!endDate) {
            const copiedStartDate = new Date(startDate);
            const copiedEndDate = new Date(endDate);
            copiedStartDate.setHours(0, 0, 0, 0);
            copiedEndDate.setHours(0, 0, 0, 0);
            const timeDifference = Math.abs(copiedEndDate.getTime() - copiedStartDate.getTime());
            return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        }
        return -1;
    };

    const dayDifference = useMemo(() => {
        return checkDaysBetweenDates(startTime, endTime);
    }, [startTime, endTime]);

    const displayedDayDifferenceValue =
        dayDifference !== null && dayDifference >= 0 ? `(${dayDifference.toString()})` : null;

    const handleApply = (startDate: Date, endDate: Date) => {
        onChange([startDate, endDate]);
        setIsSelectVisible(false);
    };

    return (
        <Container ref={containerRef} className={className} view={view}>
            <ButtonWrapper
                view={view}
                tabIndex={0}
                onKeyUp={handleKeyControl}
                onClick={handleButtonClick}
                onKeyDown={onKeyDownHandler}
            >
                <ButtonTextWrapper>
                    <ButtonText>{title}</ButtonText>
                    <ButtonText>{displayedDayDifferenceValue}</ButtonText>
                </ButtonTextWrapper>
                <ButtonControlsWrapper>
                    {value?.length ? (
                        <ResetButtonIcon
                            onClick={(evt) => {
                                evt.stopPropagation();
                                onResetOptions();
                            }}
                            onKeyUp={(event: KeyboardEvent<HTMLElement>) => {
                                if (event.key === 'Enter') onResetOptions();
                            }}
                            tabIndex={0}
                        >
                            <SvgIcon name="CloseIcon" />
                        </ResetButtonIcon>
                    ) : null}
                    {view === 'desktop' && (
                        <ArrowButtonIcon isOpen={isSelectVisible}>
                            <SvgIcon name="ArrowPrimary" alt="arrow" />
                        </ArrowButtonIcon>
                    )}
                </ButtonControlsWrapper>
            </ButtonWrapper>
            {isSelectVisible && (
                <SelectWrapper
                    view={view}
                    ref={selectWrapperRef}
                    position={autoMenuPosition ? fitPosition : Position.Left}
                >
                    <ContentWrapper>
                        <DatePickersWrapper id={'site-container'}>
                            <StyledDateInput
                                name={nameStartField}
                                label={t('datePickerStartDateLabel')}
                                dateformat="d.MM.yyyy"
                                placeholder={t('selectDate')}
                                value={selectedStartDate}
                                onChange={handleStartTimeChange}
                                icon={{ name: 'calendar alternate' }}
                                parentElementId="site-container"
                                minDate={minDate}
                                maxDate={selectedEndDate}
                            />
                            <StyledDateInput
                                name={nameEndField}
                                label={t('datePickerEndDateLabel')}
                                dateformat="d.MM.yyyy"
                                placeholder={t('selectDate')}
                                value={selectedEndDate}
                                onChange={handleEndTimeChange}
                                icon={{ name: 'calendar alternate' }}
                                parentElementId="site-container"
                                minDate={selectedStartDate}
                                maxDate={maxDate}
                            />
                        </DatePickersWrapper>
                        <StyledPrimaryButton
                            disabled={!selectedStartDate || !selectedEndDate}
                            onClick={() => handleApply(selectedStartDate!, selectedEndDate!)}
                        >
                            {t('apply')}
                        </StyledPrimaryButton>
                    </ContentWrapper>
                </SelectWrapper>
            )}
        </Container>
    );
};

export default TimeRangeSelect;
