import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { MobileViewBreakpoint } from '../../../../../common/ScreenSizeBreakPoints';
import { GhostPrimaryButton, SvgIcon } from '../../../../../components';
import { useNavigation } from '../../../../../utility';
import { useIsMobile } from '../../../../../utility/hooks/useIsMobile';
import useArticlesNavigation from '../../../../Articles/useArticlesNavigation';
import { IconButton } from '../../../Articles/common/';

const Banner = styled.div`
    width: 100%;
    padding: 1.14rem 1.75rem;
    display: grid;
    grid-template-columns: fit-content(100%) auto fit-content(100%);
    grid-column-gap: 0.5rem;
    background-color: var(--surface-color-light);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    z-index: 1;

    @media (max-width: ${MobileViewBreakpoint}px) {
        width: 100%;
        height: 3.4rem;
        padding: 1.15rem;
    }
`;

const StartContainer = styled.div`
    width: max-content;
`;

const EditFoodMenuActionBar = (): ReactElement => {
    const nav = useNavigation();
    const { goToArticleOverview } = useArticlesNavigation();
    const handleGoBackClick = () => nav.goBack(goToArticleOverview);
    const isMobile = useIsMobile();
    const { t: tCommon } = useTranslation('common');
    return (
        <Banner>
            <StartContainer>
                {isMobile ? (
                    <IconButton onClick={handleGoBackClick}>
                        <SvgIcon name="CaretLeft" />
                    </IconButton>
                ) : (
                    <GhostPrimaryButton onClick={handleGoBackClick}>
                        <Icon name="arrow left" /> {tCommon('buttonBack')}
                    </GhostPrimaryButton>
                )}
            </StartContainer>
        </Banner>
    );
};

export default EditFoodMenuActionBar;
