import { ReactElement, useState, useMemo, useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router';
import type { SingleValue } from 'react-select';
import styled from 'styled-components';
import * as yup from 'yup';
import { Ticket, TicketPriority, TicketStatus, TicketType } from '../../../../../api/apiTypes/ticketingApiTypes';
import { useCurrentUserContext } from '../../../../../common';
import RoutePromptModal from '../../../../../common/RoutePromptModal';
import { MobileViewBreakpoint } from '../../../../../common/ScreenSizeBreakPoints';
import { Dropdown, InputField, PrimaryButton, GhostPrimaryButton, InputSelect } from '../../../../../components';
import { getDeviceLanguageDateFormat } from '../../../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import { useUpdateTicket } from '../../../apiQueries/useTicketingTicket';
import { Header, Paragraph } from '../../../common/styles';
import { TicketingContext } from '../../../Ticketing';
import useTicketingNavigation from '../../../useTicketingNavigation';
import { AssetSelection, ImageView } from '../../common';
import { AssetForm } from '../../common/AssetSelection';
import { TicketListView } from '../../UserTickets/UserTickets';
import {
    TicketStatusBadge,
    renderStatusOptions,
    renderPriorityOptions,
    ChangeTypeModal,
    UserCard,
    Accordion,
    ResolveTicketButton,
    ResolveTicketModal,
    ChangeCaseworkerModal,
    TicketRecipientBadge,
} from './components';
import { getSelectedAndPublishedCategoryOptions, getTicketDetailsFormData } from './utils/utils';

type ContainerWrapperProps = { isMobile: boolean };

const Container = styled.div`
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-top: 1.42rem;
    background-color: var(--surface-color-light);
    width: 100%;
    flex-shrink: 0;
    box-shadow: ${({ isMobile }: ContainerWrapperProps) => (isMobile ? 'none' : '0px 4px 10px rgba(0, 0, 0, 0.15)')};

    @media (max-width: ${MobileViewBreakpoint}px) {
        width: 100%;
        padding-top: 0;
    }
`;

const InformationContainer = styled.div`
    background-color: var(--background-color);
    padding: 0.857rem;
    border: 1px solid #e9ebeb;
`;

const Content = styled.div`
    padding: ${({ isMobile }: ContainerWrapperProps) => (isMobile ? '0' : '1.142rem 1.714rem')};
    overflow: visible;
`;

const CaseHeaderContainer = styled.div`
    padding: 1.142rem 1.714rem;
    @media (max-width: ${MobileViewBreakpoint}px) {
        width: 100%;
        border: 1px solid #d4d6d7;
    }
`;

const TicketHeader = styled(Header)`
    margin: 0;
    overflow-wrap: break-word;
`;

const TicketText = styled.p`
    margin: 0.6rem 0;
    font-size: 1rem;
    line-height: 1.714rem;
    color: var(--text-placeholder-color);
`;

const TicketDetailsForm = styled.form``;

const StatusText = styled.p`
    margin: 0;
    font-size: 1rem;
    line-height: 1.714rem;
    color: var(--text-placeholder-color);
`;

const TicketStatusContainer = styled.div`
    margin-left: 1rem;
`;

const StatusFieldContainer = styled.div`
    margin: 0.6rem 0;
    display: flex;
`;

const StyledDropdown = styled(Dropdown)`
    width: 100%;

    button {
        height: 2.857rem;
        padding: 0.571rem 1.143rem;
    }
`;
const StyledInputField = styled(InputField)`
    width: 75%;

    input {
        width: 100%;
    }

    @media (max-width: ${MobileViewBreakpoint}px) {
        width: 100%;
    }
`;

const DropdownContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0.571rem;
    padding-bottom: 0.643rem;
    align-items: flex-start;

    @media (max-width: ${MobileViewBreakpoint}px) {
        flex-direction: column;
    }
`;

const SelectCaseworkerInputSelect = styled(InputSelect)`
    width: calc(75% - 1rem - 3px);
    padding: 0 1px;

    @media (max-width: ${MobileViewBreakpoint}px) {
        width: 100%;
    }
`;

const AlignedGhostPrimaryButton = styled(GhostPrimaryButton)`
    &&&& {
        height: 2.692rem;
        margin-top: 1.429rem;
        min-width: fit-content;
        width: 25%;

        @media (max-width: ${MobileViewBreakpoint}px) {
            margin-top: 0;
            width: 50%;
        }
    }
`;

const StyledAssetSelection = styled(AssetSelection)`
    flex-direction: column;
    > div {
        width: 100%;
    }
`;

const SaveButton = styled(PrimaryButton)`
    &&& {
        width: 100%;
        margin: 2rem 0;
    }
`;

const DescriptionHeader = styled.h2`
    font-size: 1rem;
`;

const Description = styled.p`
    word-break: break-word;
    font-size: 1.143rem;
    line-height: 1.714rem;
    color: #475156;
`;

const BadgeContainer = styled.div`
    display: flex;
    padding: 0 1.714rem;
    overflow: visible;
`;

type Form = {
    assignee: { id: string; label: string } | undefined;
    status: TicketStatus;
    priority: TicketPriority;
    type: string;
    category: string;
    description: string;
} & AssetForm;

interface TicketDetailsProps {
    ticket: Ticket;
    types: TicketType[] | undefined;
    statusOptions: TicketStatus[] | undefined;
    priorityOptions: TicketPriority[] | undefined;
    isViewWithoutSidePanel: boolean;
    isMobileView: boolean;
    caseWorkerCanEditTicket: boolean;
    userIsReportee: boolean;
    setChangesSavedTicketDetails?(isSaved: boolean): void;
    view: TicketListView;
}

const TicketDetails = ({
    ticket,
    types,
    statusOptions,
    priorityOptions,
    isViewWithoutSidePanel,
    isMobileView,
    caseWorkerCanEditTicket,
    userIsReportee,
    setChangesSavedTicketDetails,
}: TicketDetailsProps): ReactElement => {
    const history = useHistory();
    const currentLocation = useLocation();
    const { state: currentUserContext } = useCurrentUserContext();
    const { dispatch } = useContext(TicketingContext);
    const { goToReporteeTicketsLink } = useTicketingNavigation();
    const [openChangeTypeModal, setOpenChangeTypeModal] = useState(false);
    const [openModalMobileView, setOpenModalMobileView] = useState(false);
    const [openChangeCaseworkerModal, setOpenChangeCaseworkerModal] = useState(false);
    const [updateTicket, { isLoading: isUpdatingTicket }] = useUpdateTicket();
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    const { t: tCommon } = useTranslation('common');
    const schema = useMemo(() => {
        return yup.object().shape({
            assignee: yup.object({
                id: yup.string().test('assigneeId', t('caseManagerRequired'), (assigneeId: string | undefined) => {
                    if (ticket.assignee && !assigneeId) return Boolean(!ticket.assignee?.id);
                    else return true;
                }),
                label: yup.string(),
            }),
            status: yup.string().required(t('statusRequired')),
            priority: yup.string().required(t('priorityRequired')),
            type: yup.string().required(t('typeRequired')),
            category: yup.string().required(t('categoryRequired')),
            building: yup.object({ id: yup.string(), label: yup.string() }).nullable(),
            floor: yup.object({ id: yup.string(), label: yup.string() }).nullable(),
        });
    }, [ticket]);

    const caseworkersForTicketTypeOptions =
        useMemo(() => {
            if (types) {
                return types
                    .find((type) => type.id === ticket.type.id)
                    ?.caseWorkers.map((caseworker) => ({
                        id: caseworker.id,
                        label: `${caseworker.firstName} ${caseworker.lastName}`,
                    }));
            }
        }, [types, ticket.type]) ?? [];

    const isUserACaseworkerForCurrentTicket = useMemo(() => {
        return caseworkersForTicketTypeOptions.find(
            (caseworker) => caseworker.id === currentUserContext.currentUser?.id,
        );
    }, [types, ticket.type]);

    const disableForm = useMemo(() => {
        return (isUpdatingTicket || ticket.status === 'Closed') && caseWorkerCanEditTicket;
    }, [ticket.status, isUpdatingTicket, caseWorkerCanEditTicket]);

    const categoryOptions = useMemo(() => {
        if (types && ticket) {
            return getSelectedAndPublishedCategoryOptions(types, ticket.type.id, ticket.category.id);
        } else return null;
    }, [types, ticket]);

    const methods = useForm<Form>({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: getTicketDetailsFormData(ticket),
    });
    const {
        handleSubmit,
        control,
        reset,
        setValue,
        watch,
        formState: { errors, isDirty, isValid },
        register,
    } = methods;

    useEffect(() => {
        reset(getTicketDetailsFormData(ticket));
    }, [JSON.stringify(ticket)]);

    const saveChangesHandler = (formValues: Form) => {
        if (ticket) {
            updateTicket(
                [
                    ticket.id,
                    {
                        newCategoryId: formValues.category,
                        priority: formValues.priority,
                        status: formValues.status,
                        assigneeId: formValues.assignee?.id,
                        building: formValues.building?.id
                            ? { id: formValues.building.id, name: formValues.building.label }
                            : null,
                        floor: formValues.floor?.id ? { id: formValues.floor.id, name: formValues.floor.label } : null,
                    },
                ],
                {
                    onSuccess: () => {
                        setChangesSavedTicketDetails?.(true);
                        reset(formValues);
                    },
                },
            );
        }
    };

    const clickAssignYourselfAsCaseworkerHandler = () => {
        if (ticket.assignee?.id) setOpenChangeCaseworkerModal(true);
        else if (currentUserContext.currentUser)
            setValue(
                'assignee',
                { id: currentUserContext.currentUser.id, label: currentUserContext.currentUser.name },
                {
                    shouldDirty: currentUserContext.currentUser.id !== ticket.assignee?.id,
                },
            );
    };

    const onClickReporteeHandler = () => {
        dispatch({ type: 'UPDATE_FROM_TICKET_NUMBER', payload: ticket.number });
        goToReporteeTicketsLink(ticket.registeredBy.id, currentLocation.pathname);
    };

    return (
        <>
            <RoutePromptModal
                when={isDirty}
                navigate={history.push}
                shouldBlockNavigation={(location) => {
                    const removeLastPathItem = (path: string) =>
                        path.includes('/') ? path.split('/').slice(0, -1).join() : path;
                    if (
                        (location.pathname === currentLocation.pathname ||
                            removeLastPathItem(location.pathname) === removeLastPathItem(currentLocation.pathname)) &&
                        !isMobileView
                    )
                        return false;
                    else return isDirty;
                }}
                modalTitle={tCommon('warning')}
                modalContent={<Paragraph>{t('ticketDetailsModalContent')}</Paragraph>}
                modalCancelButtonText={tCommon('backToEditing')}
                modalOkButtonText={tCommon('discardChanges')}
                onOkButtonClick={() => {
                    setChangesSavedTicketDetails?.(true);
                    reset();
                }}
            />
            <Container isMobile={isViewWithoutSidePanel}>
                {ticket && types && (
                    <>
                        {!isViewWithoutSidePanel && (
                            <CaseHeaderContainer>
                                <Header>{`${t('case')}${ticket.number}`}</Header>
                            </CaseHeaderContainer>
                        )}
                        {ticket.externalId && !isViewWithoutSidePanel && (
                            <BadgeContainer>
                                <TicketRecipientBadge>{`#${ticket.externalId} - ${ticket.recipient}`}</TicketRecipientBadge>
                            </BadgeContainer>
                        )}
                        <Content isMobile={isViewWithoutSidePanel}>
                            <TicketHeader>{ticket.title}</TicketHeader>
                            <TicketText>
                                {t('ticketDetailsCreated')}
                                {` ${getDeviceLanguageDateFormat(
                                    new Date(ticket.createdDate),
                                    'd. MMMM yyyy',
                                )} kl. ${getDeviceLanguageDateFormat(new Date(ticket.createdDate), 'HH:mm')}`}
                            </TicketText>
                            {isMobileView && (
                                <>
                                    <ResolveTicketButton
                                        isMobileView={isMobileView}
                                        onClick={() => setOpenModalMobileView(true)}
                                        text={t('ticketDetailsSetAsResolve')}
                                        ticket={ticket}
                                    />
                                    {openModalMobileView && (
                                        <ResolveTicketModal
                                            userIsReportee={userIsReportee}
                                            caseWorkerCanEditTicket={caseWorkerCanEditTicket}
                                            key="resolveTicketModal"
                                            onClose={() => setOpenModalMobileView(false)}
                                            open={openModalMobileView}
                                            ticket={ticket}
                                        />
                                    )}
                                </>
                            )}
                            {caseWorkerCanEditTicket ? (
                                <>
                                    <UserCard
                                        user={ticket.registeredBy}
                                        companyName={ticket.companyName}
                                        onClick={onClickReporteeHandler}
                                    />
                                    <FormProvider {...methods}>
                                        <TicketDetailsForm
                                            onSubmit={handleSubmit(saveChangesHandler)}
                                            key="ticketDetails"
                                            id="ticketDetails"
                                        >
                                            <DropdownContainer>
                                                <Controller
                                                    control={control}
                                                    name="assignee"
                                                    render={({ field }) => {
                                                        const handleChange = (
                                                            selectedValue: SingleValue<{
                                                                id: string;
                                                                label: string;
                                                            } | null>,
                                                        ) => {
                                                            setValue(
                                                                'assignee',
                                                                selectedValue
                                                                    ? { ...selectedValue }
                                                                    : { id: '', label: '' },
                                                                {
                                                                    shouldDirty: selectedValue !== ticket.assignee,
                                                                    shouldValidate: true,
                                                                },
                                                            );
                                                            setChangesSavedTicketDetails?.(false);
                                                        };
                                                        return (
                                                            <SelectCaseworkerInputSelect
                                                                label={t('selectCaseworkerInputSelectLabel')}
                                                                isClearable={true}
                                                                id="assignee"
                                                                error={errors.assignee?.id?.message}
                                                                options={caseworkersForTicketTypeOptions}
                                                                placeholder={t(
                                                                    'selectCaseworkerInputSelectPlaceholder',
                                                                )}
                                                                showSearchIcon
                                                                disable={disableForm}
                                                                {...field}
                                                                onChange={handleChange}
                                                            />
                                                        );
                                                    }}
                                                />
                                                <AlignedGhostPrimaryButton
                                                    key="assignYourselfToTicket"
                                                    type="button"
                                                    onClick={clickAssignYourselfAsCaseworkerHandler}
                                                    disabled={
                                                        disableForm ||
                                                        ticket.assignee?.id === currentUserContext.currentUser?.id ||
                                                        !isUserACaseworkerForCurrentTicket
                                                    }
                                                >
                                                    {t('takeCase')}
                                                </AlignedGhostPrimaryButton>
                                            </DropdownContainer>
                                            <DropdownContainer>
                                                <Controller
                                                    name="status"
                                                    control={control}
                                                    defaultValue={ticket.status}
                                                    render={({ field: { onChange }, field }) => {
                                                        const handleChange = (selectedItemId: string) => {
                                                            onChange(selectedItemId);
                                                            setChangesSavedTicketDetails?.(false);
                                                        };
                                                        return (
                                                            <StyledDropdown
                                                                label={t('status')}
                                                                items={renderStatusOptions(statusOptions)}
                                                                required
                                                                readOnly={disableForm}
                                                                {...field}
                                                                onChange={handleChange}
                                                            />
                                                        );
                                                    }}
                                                />
                                                <Controller
                                                    name="priority"
                                                    control={control}
                                                    defaultValue={ticket.priority}
                                                    render={({ field: { onChange }, field }) => {
                                                        const handleChange = (selectedItemId: string) => {
                                                            onChange(selectedItemId);
                                                            setChangesSavedTicketDetails?.(false);
                                                        };
                                                        return (
                                                            <StyledDropdown
                                                                label={t('priority')}
                                                                items={renderPriorityOptions(priorityOptions)}
                                                                required
                                                                readOnly={disableForm}
                                                                {...field}
                                                                onChange={handleChange}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </DropdownContainer>
                                            <StyledAssetSelection
                                                buildingLabel={t('buildingLabel')}
                                                buildingValue={watch('building')}
                                                floorLabel={t('floorLabel')}
                                                floorValue={watch('floor')}
                                                key="updateTicketAsset"
                                                onBuildingChange={(selectedValue) => {
                                                    setValue('building', selectedValue, {
                                                        shouldDirty: selectedValue !== ticket.building,
                                                    });
                                                    setValue('floor', null, {
                                                        shouldDirty: watch('floor') !== ticket.floor,
                                                    });
                                                }}
                                                onFloorChange={(selectedValue) => {
                                                    setValue('floor', selectedValue, {
                                                        shouldDirty: selectedValue !== ticket.floor,
                                                        shouldValidate: true,
                                                    });
                                                }}
                                                disable={disableForm}
                                            />
                                            <Accordion disableAndShowContent={!isViewWithoutSidePanel}>
                                                <DropdownContainer>
                                                    <StyledInputField
                                                        type="text"
                                                        label={t('typeLabel')}
                                                        required
                                                        readOnly={true}
                                                        {...register('type')}
                                                        name="type"
                                                        error={errors?.type?.message}
                                                    />
                                                    <AlignedGhostPrimaryButton
                                                        key="changeTicketTypeAndCategory"
                                                        type="button"
                                                        onClick={() => setOpenChangeTypeModal(true)}
                                                        disabled={disableForm}
                                                    >
                                                        {t('changeTypeModalButtonChange')}
                                                    </AlignedGhostPrimaryButton>
                                                </DropdownContainer>
                                                <DropdownContainer>
                                                    <Controller
                                                        name="category"
                                                        control={control}
                                                        defaultValue={ticket.category.id}
                                                        render={({ field: { onChange }, field }) => {
                                                            const handleChange = (selectedItemId: string) => {
                                                                onChange(selectedItemId);
                                                            };
                                                            return (
                                                                <StyledDropdown
                                                                    label={t('categoryLabel')}
                                                                    items={categoryOptions ?? []}
                                                                    initialValue={ticket.category.id}
                                                                    required
                                                                    key={`category-${ticket.category.id}`}
                                                                    readOnly={disableForm}
                                                                    {...field}
                                                                    value={ticket.category.id}
                                                                    onChange={handleChange}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </DropdownContainer>
                                            </Accordion>
                                            <SaveButton disabled={!isDirty || !isValid || isUpdatingTicket}>
                                                {t('storeButton')}
                                            </SaveButton>
                                        </TicketDetailsForm>
                                    </FormProvider>

                                    {currentUserContext.currentUser && (
                                        <ChangeCaseworkerModal
                                            open={openChangeCaseworkerModal}
                                            ticket={ticket}
                                            newCaseworkerId={currentUserContext.currentUser.id}
                                            onClose={() => setOpenChangeCaseworkerModal(false)}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    <TicketText>
                                        {t('lastUpdated')}:
                                        {` ${getDeviceLanguageDateFormat(
                                            new Date(ticket.updatedDate),
                                            'd. MMMM',
                                        )} kl. ${getDeviceLanguageDateFormat(new Date(ticket.updatedDate), 'HH:mm')}`}
                                    </TicketText>
                                    <StatusFieldContainer>
                                        <StatusText>{t('status')}:</StatusText>
                                        <TicketStatusContainer>
                                            <TicketStatusBadge status={ticket.status}>
                                                {ticket.status}
                                            </TicketStatusBadge>
                                        </TicketStatusContainer>
                                    </StatusFieldContainer>
                                </>
                            )}
                            <InformationContainer>
                                <Header>{t('submittedDetails')}</Header>
                                {!caseWorkerCanEditTicket && (
                                    <>
                                        <DescriptionHeader>{t('placement')}</DescriptionHeader>
                                        <Description>
                                            {t('informationContainerDescription', {
                                                buildingName: ticket.building?.name,
                                                floorName: ticket.floor?.name,
                                            })}
                                        </Description>
                                    </>
                                )}
                                <DescriptionHeader>{t('description')}</DescriptionHeader>
                                <Description>{ticket.description}</Description>
                                <ImageView image={ticket.ticketImage} alt="beskrivelsesbilde" />
                                {openChangeTypeModal && (
                                    <ChangeTypeModal
                                        key="changeTypeModal"
                                        ticket={ticket}
                                        ticketTypes={types ?? []}
                                        open={openChangeTypeModal}
                                        onClose={() => setOpenChangeTypeModal(false)}
                                        onUpdateSuccess={(newTypeName: string, newCategoryId: string) => {
                                            setValue('type', newTypeName);
                                            setValue('category', newCategoryId);
                                            setOpenChangeTypeModal(false);
                                        }}
                                    />
                                )}
                            </InformationContainer>
                        </Content>
                    </>
                )}
            </Container>
        </>
    );
};

export default TicketDetails;
