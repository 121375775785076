import { ReactElement, RefObject, useMemo } from 'react';
import {
    CreateTicketComment,
    Ticket,
    TicketSeenEntity,
    TicketStatus,
} from '../../../../../../api/apiTypes/ticketingApiTypes';
import { AvatarComponentProps } from '../../../../../../components/general/AvatarComponent';
import { useCreateTicketComment, useGetInfiniteTicketComments } from '../../../../apiQueries/useTicketComments';
import { TicketListView } from '../../../UserTickets/UserTickets';
import { CommentType } from '../components/Comments';
import { Comments } from '../components/index';
import { useCommentHighlighting } from '../hooks/useCommentHighlighting';

interface CommentsTabProps {
    setChangesSaved(isSaved: boolean): void;
    scrollRef: RefObject<HTMLElement>;
    currentUserAvatar: AvatarComponentProps | null;
    isMobile: boolean;
    ticket: Ticket;
    caseWorkerCanEditTicket: boolean;
    view: TicketListView;
}

const CommentsTab = ({
    setChangesSaved,
    scrollRef,
    currentUserAvatar,
    ticket,
    isMobile,
    caseWorkerCanEditTicket,
    view,
}: CommentsTabProps): ReactElement => {
    const { data, isLoading, isFetchingMore, isError, canFetchMore, fetchMore } = useGetInfiniteTicketComments(
        ticket.number,
    );

    const [
        createTicketComment,
        { isLoading: isLoadingCreatingComment, isSuccess: isSuccessCreatingComment, isError: isErrorCreatingComment },
    ] = useCreateTicketComment();
    const comments = useMemo(() => (data ?? []).flat(), [data]);
    const disableSubmitMessageButton = isLoadingCreatingComment || ticket.status === TicketStatus.Closed;

    const handleFetchMore = () => {
        if (!isFetchingMore) fetchMore();
    };

    const onSubmitCommentHandler = (comment: CreateTicketComment) => {
        createTicketComment([ticket.number, { ...comment }]);
    };

    useCommentHighlighting(comments, isLoading, ticket.number, TicketSeenEntity.Comments);

    return (
        <Comments
            ticket={ticket}
            comments={comments}
            canFetchMore={canFetchMore}
            fetchMore={handleFetchMore}
            currentUserAvatar={currentUserAvatar}
            disableSubmitMessageButton={disableSubmitMessageButton}
            isLoading={isLoading}
            isError={isError}
            key={CommentType.Comments}
            isErrorCreatingComment={isErrorCreatingComment}
            isSuccessCreatingComment={isSuccessCreatingComment}
            isLoadingCreatingComment={isLoadingCreatingComment}
            onSubmitComment={onSubmitCommentHandler}
            scrollRef={scrollRef}
            setChangesSaved={setChangesSaved}
            isMobile={isMobile}
            caseWorkerCanEditTicket={caseWorkerCanEditTicket}
            view={view}
            commentType={CommentType.Comments}
        />
    );
};

export default CommentsTab;
