import { AxiosError } from 'axios';
import type { InfiniteQueryResult, QueryResult } from 'react-query';
import { useInfiniteQuery, useQuery } from 'react-query';
import {
    UserIsCaseWorker,
    CaseworkerModel,
    TicketReportee,
    UserIsCompanyAdmin,
} from '../../../api/apiTypes/ticketingApiTypes';
import {
    getCaseworkers,
    getUserHasTicketingAccess,
    getUserInfo,
    getUserIsCaseWorker,
    getUserIsCompanyAdmin,
} from '../../../api/ticketingApi';

const UserInfoCacheKey = 'UserInfoCacheKey';
export const UserIsCaseWorkerCacheKey = 'UserIsCaseWorkerCacheKey';
export const UserIsCompanyAdminCacheKey = 'UserIsCompanyAdminCacheKey';
const CaseworkerSearchKey = 'CaseworkerSearchKey';
export const UserHasTicketingAccessCacheKey = 'UserHasTicketingAccessCacheKey';

export const useUserIsCaseWorker = (
    enabled: boolean,
): QueryResult<UserIsCaseWorker, string | AxiosError<string> | Error> => {
    return useQuery(
        [UserIsCaseWorkerCacheKey],
        async () => {
            const result = await getUserIsCaseWorker();
            return result.data;
        },
        {
            staleTime: 1000 * 60 * 10,
            cacheTime: Infinity,
            enabled,
            refetchInterval: 1000 * 60,
        },
    );
};

export const useUserIsCompanyAdmin = (): QueryResult<UserIsCompanyAdmin, string | AxiosError<string> | Error> => {
    return useQuery([UserIsCompanyAdminCacheKey], async () => {
        const result = await getUserIsCompanyAdmin();
        return result.data;
    });
};

const take = 20;
export const useGetInfiniteCaseworker = (
    term: string,
): InfiniteQueryResult<CaseworkerModel[], Error | AxiosError<string>> => {
    return useInfiniteQuery<CaseworkerModel[], Error | AxiosError<string>>(
        [CaseworkerSearchKey, term],
        async (_: any, term: string, page: number): Promise<CaseworkerModel[]> => {
            if (term.length > 0) {
                const response = await getCaseworkers(term, page ?? 0, take);
                return response.data;
            }
            return [];
        },
        {
            getFetchMore: (lastPage: string[] | any, allPages: string[] | any) => {
                if (lastPage && lastPage.length !== take) {
                    return false;
                }
                return allPages.length;
            },
            cacheTime: 1000 * 30,
            staleTime: 1000 * 60 * 5,
        },
    );
};

export const useGetUserInfo = (
    userId: string,
    enabled: boolean,
): QueryResult<TicketReportee, string | AxiosError<string> | Error> => {
    return useQuery(
        [UserInfoCacheKey, userId],
        async () => {
            const result = await getUserInfo(userId);
            return result.data;
        },
        { enabled: enabled },
    );
};

export const useUserHasTicketingAccess = (): QueryResult<boolean, string | AxiosError<string> | Error> => {
    return useQuery([UserHasTicketingAccessCacheKey], async () => {
        const result = await getUserHasTicketingAccess();
        return result.data;
    });
};
