import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isWithinInterval, max } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { AppLink } from '../../../../../../../components';
import { useNavigation } from '../../../../../../../utility';
import { useGetUserMeetingBookings } from '../../../../../../Meeting/apiQueries/useMeetingBookings';
import { useGetMenu, useGetMinDate } from '../../../../../apiQueries/useMenu';
import { InfoMessage, SelectMeetingModal } from '../../../../../components';
import { ShoppingCartContext } from '../../../../../ShoppingCart';
import { getInvalidMeetingMessage } from '../../../../../utils';
import useUpdateDeliveryTimeIfOutsideMeeting from '../../../useUpdateDeliveryTimeIfOutsideMeeting';
import { SelectedMeeting } from './components';

const ErrorPhrase = styled.div`
    padding: 1rem;
    background-color: var(--error-color);
    color: var(--error-on-color);
    border-radius: 5px;
`;

const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
`;

const LoaderMessage = styled.p`
    margin-left: 1rem;
`;

const BookMeetingLink = styled(AppLink)`
    &,
    :hover {
        text-decoration: underline;
        color: inherit;
    }
`;

interface SelectMeetingProps {
    handleCancel: () => void;
}

const SelectMeeting: React.FC<React.PropsWithChildren<SelectMeetingProps>> = ({ handleCancel }: SelectMeetingProps) => {
    const { isFetching: isFetchingMeetings, data: meetingBookings, isError } = useGetUserMeetingBookings();
    const { state: shoppingCartState, setDeliveryDateTime } = useContext(ShoppingCartContext);
    const [selectorIsOpen, setSelectorIsOpen] = useState<boolean>(false);
    const nav = useNavigation();
    const location = useLocation();
    const { data: foodMenu } = useGetMenu(shoppingCartState.deliveryDateTime);
    const minDate = useGetMinDate();
    const selectedMeetingId = new URLSearchParams(location.search).get('meetingId');
    const { t } = useTranslation('Food');
    const sortedOwnMeetings = useMemo(() => {
        if (!meetingBookings) return meetingBookings;
        return meetingBookings
            .filter((booking) => booking.isUsersOwn && booking.endTime > new Date())
            .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
    }, [meetingBookings]);
    const selectedMeeting = sortedOwnMeetings?.find((meeting) => meeting.id === selectedMeetingId);

    const setMeeting = useCallback(
        (meetingId: string, deliveryDateTime: Date) => {
            setDeliveryDateTime(deliveryDateTime, meetingId);
            const queryParams = new URLSearchParams(location.search);
            queryParams.set('meetingId', meetingId);
            nav.replace(`${location.pathname}?${queryParams.toString()}`);
            setSelectorIsOpen(false);
        },
        [nav, location.pathname, location.search, setDeliveryDateTime],
    );

    const handleCancelSelectMeeting = () => {
        if (selectedMeeting) {
            setSelectorIsOpen(false);
        } else {
            handleCancel();
        }
    };

    useEffect(() => {
        const selectOnlyMeeting = () => {
            const queryParams = new URLSearchParams(location.search);
            if (sortedOwnMeetings && sortedOwnMeetings.length === 1 && !queryParams.has('meetingId')) {
                setDeliveryDateTime(
                    max([
                        sortedOwnMeetings[0].startTime,
                        minDate ?? new Date(),
                        foodMenu?.departmentBusinessHoursFrom ?? new Date(),
                    ]),
                    sortedOwnMeetings[0].id,
                );
            }
        };
        if (!shoppingCartState.attachedMeeting) selectOnlyMeeting();
    }, [
        location.pathname,
        location.search,
        sortedOwnMeetings,
        setDeliveryDateTime,
        minDate,
        foodMenu?.departmentBusinessHoursFrom,
        shoppingCartState.attachedMeeting,
    ]);

    useEffect(() => {
        const setMeetingFromQueryParams = () => {
            if (selectedMeetingId && sortedOwnMeetings && selectedMeetingId !== shoppingCartState.attachedMeeting) {
                const querySelectedMeeting = sortedOwnMeetings.find(({ id }) => id + '' === selectedMeetingId);
                if (querySelectedMeeting) {
                    setDeliveryDateTime(
                        max([
                            querySelectedMeeting.startTime,
                            minDate ?? new Date(),
                            foodMenu?.departmentBusinessHoursFrom ?? new Date(),
                        ]),
                        querySelectedMeeting.id,
                    );
                }
            }
        };
        setMeetingFromQueryParams();
    }, [
        sortedOwnMeetings,
        setDeliveryDateTime,
        selectedMeetingId,
        shoppingCartState.attachedMeeting,
        minDate,
        foodMenu?.departmentBusinessHoursFrom,
    ]);

    useEffect(() => {
        const setMeetingToParams = () => {
            if (shoppingCartState.attachedMeeting && !selectedMeetingId) {
                const queryParams = new URLSearchParams(location.search);
                queryParams.set('meetingId', shoppingCartState.attachedMeeting);
                nav.replace(`${location.pathname}?${queryParams.toString()}`);
            }
        };
        setMeetingToParams();
    }, [nav, location.pathname, location.search, shoppingCartState.attachedMeeting, selectedMeetingId]);

    const willUpdateDateTime = useUpdateDeliveryTimeIfOutsideMeeting(
        selectedMeeting,
        shoppingCartState.deliveryDateTime,
        setDeliveryDateTime,
        minDate ?? new Date(),
        foodMenu?.departmentBusinessHoursFrom ?? new Date(),
    );

    if (isFetchingMeetings && !selectedMeeting) {
        return (
            <LoaderContainer>
                <Loader active inline />
                <LoaderMessage>{t('foodOrder.loadingMeeting')}</LoaderMessage>
            </LoaderContainer>
        );
    }
    if (isError || !sortedOwnMeetings) {
        return <ErrorPhrase>{t('foodOrder.errorMessageLoadMeetings')}</ErrorPhrase>;
    }

    if (sortedOwnMeetings.length === 0) {
        return (
            <InfoMessage>
                {t('foodOrder.youHaveNotMeetings')}
                <BookMeetingLink to="/Meeting/MeetingOverview">{t('foodOrder.bookMeetingNow')}</BookMeetingLink>
            </InfoMessage>
        );
    }

    const handleMeetingClick = () => {
        setSelectorIsOpen(true);
    };

    const invalidMessage =
        selectedMeeting && foodMenu && minDate
            ? getInvalidMeetingMessage(
                  selectedMeeting.startTime,
                  selectedMeeting.endTime,
                  foodMenu.departmentBusinessHoursFrom,
                  foodMenu.departmentBusinessHoursTo,
                  minDate,
                  t,
              )
            : '';
    const invalidDateTimeMessage =
        selectedMeeting && foodMenu && minDate && !willUpdateDateTime
            ? !isWithinInterval(shoppingCartState.deliveryDateTime, {
                  start: selectedMeeting.startTime,
                  end: selectedMeeting.endTime,
              }) ||
              !isWithinInterval(shoppingCartState.deliveryDateTime, {
                  start: foodMenu.departmentBusinessHoursFrom,
                  end: foodMenu.departmentBusinessHoursTo,
              }) ||
              shoppingCartState.deliveryDateTime < minDate
                ? t('foodOrder.deliveryTimeBadChooseAnother')
                : ''
            : '';

    return (
        <>
            {selectedMeeting ? (
                <SelectedMeeting
                    onClick={handleMeetingClick}
                    title={selectedMeeting.title}
                    deliveryDateTime={shoppingCartState.deliveryDateTime}
                    meetingStartTime={selectedMeeting.startTime}
                    roomName={selectedMeeting.room.name}
                    organizerName={selectedMeeting.organizer?.name ?? ''}
                    buildingName={selectedMeeting.room.buildingName}
                    withCaret={sortedOwnMeetings.length !== 1}
                    errorMessage={invalidMessage || invalidDateTimeMessage}
                />
            ) : null}
            {(!isFetchingMeetings && !selectedMeeting) || selectorIsOpen ? (
                <SelectMeetingModal
                    defaultMeeting={selectedMeeting}
                    meetings={sortedOwnMeetings}
                    selectMeeting={setMeeting}
                    handleClose={handleCancelSelectMeeting}
                    defaultDeliveryTime={
                        !selectedMeeting || invalidMessage
                            ? null
                            : max([
                                  shoppingCartState.deliveryDateTime,
                                  minDate ?? new Date(),
                                  foodMenu?.departmentBusinessHoursFrom ?? new Date(),
                              ])
                    }
                />
            ) : null}
        </>
    );
};

export default SelectMeeting;
