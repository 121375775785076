import { ReactElement, useState } from 'react';
import type { UseFormRegister, FieldValues, Control, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AllergenType, DailyMenuType } from '../../../../../api/apiTypes/shopApiTypes';
import { SvgIcon, InputField, AllergenSelectController } from '../../../../../components';
import { capitalizeFirstLetter } from '../../../../../utility';
import { getDeviceLanguageDateFormat } from '../../../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import { getTranslatedAllergens } from '../../../../../utility/hooks/useGetTranslatedAllergens';
import { CreateMenuForm } from './MenuForm';

type DayHeaderProps = { isError: boolean; expanded: boolean };

const DayHeader = styled.button`
    display: flex;
    background: var(--background-color);
    border: 1px solid var(--border-color);
    width: 100%;
    justify-content: space-between;
    padding: 1.14rem;
    cursor: pointer;
    ${({ isError }: DayHeaderProps) => isError && { color: 'var(--error-color)' }}
    & svg {
        transform: rotate(${({ expanded }: DayHeaderProps) => (expanded ? '0.5turn' : '0turn')});
    }
`;

const FormContainer = styled.div`
    padding: 1.71rem;
    border: 1px solid var(--border-color);
    background: var(--surface-color-light);
    row-gap: 1.71rem;
    display: grid;
`;

const DayTitle = styled.legend`
    margin: 0;
    font-weight: bold;
    font-size: 1.14rem;
`;

type MenuFieldError = {
    allergens?: { id?: FieldError; name?: FieldError }[] | FieldError;
    date?: FieldError;
    title?: FieldError;
    description?: FieldError;
};
interface MenuItemForm extends Omit<DailyMenuType, 'date'> {
    date: string;
}

interface DayMenuInputProps<T extends FieldValues> {
    index: number;
    register: UseFormRegister<CreateMenuForm>;
    control: Control<T>;
    allergens: AllergenType[];
    error?: MenuFieldError;
    menu: MenuItemForm;
}

const DayMenuInput = <T extends FieldValues>({
    index,
    register,
    control,
    allergens,
    error,
    menu,
}: DayMenuInputProps<T>): ReactElement => {
    const { t } = useTranslation('Dashboard', { keyPrefix: 'dailyMenu' });
    const { t: tAllergens } = useTranslation('Food', { keyPrefix: 'allergens' });

    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded((curr) => !curr);
    const translatedAllergens = getTranslatedAllergens(allergens, tAllergens);

    return (
        <div aria-expanded={expanded}>
            <DayHeader onClick={toggleExpanded} expanded={expanded} isError={!!error} type="button">
                <DayTitle>
                    {capitalizeFirstLetter(getDeviceLanguageDateFormat(menu.date, 'EEEE'))}&nbsp;
                    {getDeviceLanguageDateFormat(menu.date, 'd/M')}
                    {menu.title ? ` - ${menu.title}` : null}
                </DayTitle>
                <SvgIcon name="CaretDown" />
            </DayHeader>
            {expanded ? (
                <FormContainer>
                    <InputField
                        label={t('labelTitle')}
                        placeholder={t('placeholderEnterTitle')}
                        type="text"
                        error={error?.title?.message}
                        {...register(`menus.${index}.title`)}
                    />

                    <AllergenSelectController
                        label={t('labelAllergens')}
                        name={`menus.${index}.allergens`}
                        control={control}
                        placeholder={t('allergensSelectPlaceholder')}
                        allergens={translatedAllergens}
                    />

                    <InputField
                        label={t('labelDescription')}
                        placeholder={t('placeholderDescribeMenu')}
                        type="textarea"
                        {...register(`menus.${index}.description`)}
                    />
                </FormContainer>
            ) : null}
        </div>
    );
};

export default DayMenuInput;
