import { AxiosResponse, AxiosError } from 'axios';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Role, UpdateUser, User, Users } from '../../../../../../api/apiTypes/portalApiTypes';
import {
    deleteSelf,
    deleteUserFromCompany,
    getUserForCompanyInfo,
    getUsersAccessibleRoles,
    getUsersForCompany,
    restoreUserFromCompany,
    updateUserForCompanyInfo,
} from '../../../../../../api/portalApi';
import { getPortalId } from '../../../../../../api/utils';
import { UserHasTicketingAccessCacheKey } from '../../../../../Ticketing/apiQueries/useTicketingUser';

export const orgCacheKey = 'orgCacheKey';
export const UserAccessibleRolesCacheKey = 'UserAccessibleRolesCacheKey';

export const useGetUserForCompanyInfo = (
    selectedCompanyId: string,
    { guid }: Users,
): QueryResult<User, AxiosError<string>> => {
    return useQuery([orgCacheKey, selectedCompanyId, guid], async () => {
        const { data } = await getUserForCompanyInfo(selectedCompanyId, guid);
        return data;
    });
};

export const useGetUsersForCompany = (
    selectedCompanyId: string | undefined,
): QueryResult<Users[], AxiosError<string>> => {
    return useQuery(
        [orgCacheKey, selectedCompanyId],
        async () => {
            const { data } = await getUsersForCompany(getPortalId().toString(), selectedCompanyId as string);
            return data.items;
        },
        { enabled: selectedCompanyId },
    );
};

export const useGetUsersAccessibleRoles = (): QueryResult<Role[], AxiosError<string>> => {
    return useQuery([UserAccessibleRolesCacheKey], async () => {
        const { data } = await getUsersAccessibleRoles();
        return data;
    });
};

export const useInvalidateUsersForCompany = (): ((selectedCompanyId: string) => void) => {
    const cache = useQueryCache();
    return (selectedCompanyId: string) => cache.invalidateQueries([orgCacheKey, selectedCompanyId]);
};

export const useUpdateUserForCompanyInfo = (): MutationResultPair<
    AxiosResponse<UpdateUser>,
    Error | AxiosError<string>,
    { selectedCompanyId: string; userId: string; user: User },
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        async ({ selectedCompanyId, userId, user }) => {
            const name = [user.firstName, user.lastName].join(' ').trim();
            const dto = {
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                name: name,
                email: user.email,
                phone: user.phone,
                activeFrom: user.activeFrom,
                activeTo: user.activeTo,
                roles: user.roles as unknown as string[],
                status: user.status,
                guid: user.guid,
                language: user.language,
            };
            return await updateUserForCompanyInfo(selectedCompanyId, userId, dto);
        },
        {
            onSuccess: (_, { selectedCompanyId, userId }) => {
                cache.invalidateQueries([orgCacheKey, selectedCompanyId], { exact: true });
                cache.invalidateQueries([orgCacheKey, selectedCompanyId, userId]);
                cache.invalidateQueries([UserHasTicketingAccessCacheKey]);
            },
        },
    );
};

export const useDeleteUserForCompanyInfo = (): MutationResultPair<
    AxiosResponse<UpdateUser>,
    Error | AxiosError<string>,
    [string, string],
    never
> => {
    const cache = useQueryCache();
    return useMutation<AxiosResponse<UpdateUser>, Error | AxiosError<string>, [string, string], never>(
        async ([companyId, userId]) => {
            return await deleteUserFromCompany(getPortalId().toString(), companyId, userId);
        },
        {
            onSuccess: (_, [companyId]) => {
                cache.invalidateQueries([orgCacheKey, companyId], { exact: true });
            },
        },
    );
};

export const useRestoreUserForCompanyInfo = (): MutationResultPair<
    AxiosResponse<UpdateUser>,
    Error | AxiosError<string>,
    [string, string],
    never
> => {
    const cache = useQueryCache();
    return useMutation<AxiosResponse<UpdateUser>, Error | AxiosError<string>, [string, string], never>(
        async ([companyId, userId]) => {
            return await restoreUserFromCompany(getPortalId().toString(), companyId, userId);
        },
        {
            onSuccess: (_, [companyId]) => {
                cache.invalidateQueries([orgCacheKey, companyId], { exact: true });
            },
        },
    );
};

export const useDeleteSelf = (): MutationResultPair<AxiosResponse, Error | AxiosError<string>, [string], never> => {
    return useMutation<AxiosResponse, Error | AxiosError<string>, [string], never>(
        async () => {
            return await deleteSelf();
        },
        {
            onSuccess: () => {
                // since the user deleted themselves, reload the page to show the page about account deletion
                location.reload();
            },
        },
    );
};
