import React, { FC, useCallback, useEffect, useMemo, useRef, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import DownArrowIconSvg from '../../icons/DownArrowIconSvg';
import TickIconSvg from '../../icons/TickIconSvg';
import { Button, FlexBox, Typography } from '../index';
import { DROPDOWN_HEIGHT, MARGIN_GAP, MAX_DROPDOWN_HEIGHT, MIN_DROPDOWN_HEIGHT, REM_SIZE } from './constants';
import { DropdownItem, DropdownProps } from './types';

const Dropdown: FC<DropdownProps> = ({
    list,
    value,
    onChange,
    placeholder,
    minWidth,
    disabled,
    inputBg,
    outlineBg,
    listWidth,
}) => {
    const { t } = useTranslation('common');

    const containerRef = useRef<HTMLDivElement>(null);
    const [dropdownMaxHeight, setDropdownMaxHeight] = useState<number>(0);
    const [openUpwards, setOpenUpwards] = useState(false);

    const [isOpened, setIsOpened] = useState(false);

    const changeSelectItemCallback = useCallback(
        (item: DropdownItem) => (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onChange(item);
            setIsOpened(false);
        },
        [onChange],
    );

    const calculateDropdownMaxHeight = useCallback(() => {
        if (containerRef.current) {
            const buttonRect = containerRef.current.getBoundingClientRect();
            const spaceBelow = window.innerHeight / REM_SIZE - buttonRect.bottom / REM_SIZE;
            const spaceAbove = buttonRect.top / REM_SIZE;

            const spaceAvailable =
                spaceBelow < MIN_DROPDOWN_HEIGHT / REM_SIZE && spaceAbove > spaceBelow
                    ? spaceAbove - MARGIN_GAP / REM_SIZE
                    : spaceBelow - MARGIN_GAP / REM_SIZE;

            const maxHeight = Math.min(spaceAvailable, MAX_DROPDOWN_HEIGHT / REM_SIZE);
            setDropdownMaxHeight(maxHeight);

            setOpenUpwards(spaceBelow < MIN_DROPDOWN_HEIGHT / REM_SIZE && spaceAbove > spaceBelow);
        }
    }, []);

    useEffect(() => {
        if (isOpened) {
            calculateDropdownMaxHeight();
        }
    }, [isOpened, calculateDropdownMaxHeight]);

    const handleDocumentClick = useCallback(
        (e: globalThis.MouseEvent) => {
            if (isOpened && containerRef.current && !containerRef.current.contains(e.target as Node)) {
                setIsOpened(false);
            }
        },
        [isOpened],
    );

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [handleDocumentClick]);

    const listRender = useMemo(() => {
        return list.map((item) => {
            const isActive = item.id === value?.id;

            return (
                <Button
                    background={isActive ? '#F8FAFC' : '#fff'}
                    key={item.id}
                    pd={'0.571rem 1.143rem'}
                    onClick={changeSelectItemCallback(item)}
                    hoverBackground={'var(--primary-color)'}
                    hoverTextColor={'#fff'}
                    justify={'space-between'}
                    align={'center'}
                >
                    <Typography fontSize={'1.143rem'} fontWeight={400} lineHeight={'1.714rem'} color={'#000'}>
                        {item.value}
                    </Typography>
                    {isActive && <TickIconSvg fill={'#677074'} />}
                </Button>
            );
        });
    }, [list, changeSelectItemCallback, value?.id]);

    const showListCallback = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();

            if (!listRender.length || disabled) return null;

            setIsOpened((prev) => !prev);
        },
        [listRender, disabled],
    );

    return (
        <FlexBox forwardedRef={containerRef} minW={minWidth ?? 'auto'}>
            <Button
                height={`${DROPDOWN_HEIGHT / REM_SIZE}rem`}
                background={inputBg || '#fff'}
                borderWidth={'1px'}
                borderColor={outlineBg || '#D4D6D7'}
                position={'relative'}
                onClick={showListCallback}
                pd={'0.571rem 1.143rem'}
                pdR={'0rem'}
                justify={'space-between'}
                align={'center'}
                br={'0.357rem'}
                opacity={disabled ? 0.6 : 1}
                cursor={disabled ? 'not-allowed' : 'pointer'}
            >
                <Typography
                    color={outlineBg || '#475156'}
                    fontSize={'1.143rem'}
                    fontWeight={400}
                    lineHeight={'1.714rem'}
                    lineClamp={'1'}
                >
                    {value?.value ?? placeholder ?? t('selectItem')}
                </Typography>
                <FlexBox
                    width={'auto'}
                    justify={'center'}
                    align={'center'}
                    transform={`rotate(${isOpened ? '180' : '0'}deg)`}
                >
                    <DownArrowIconSvg fill={outlineBg} />
                </FlexBox>
                <FlexBox
                    maxH={`${dropdownMaxHeight}rem`}
                    overflow={'auto'}
                    boxShadow={'0px 0.071rem 0.1rem 0px rgba(0, 0, 0, 0.20)'}
                    position={'absolute'}
                    top={openUpwards ? `-${dropdownMaxHeight + 0.25}rem` : `${DROPDOWN_HEIGHT / REM_SIZE}rem`}
                    display={isOpened ? 'flex' : 'none'}
                    left={'0px'}
                    dir={'column'}
                    borderWidth={'1px'}
                    zIndex={1}
                    width={listWidth || '100%'}
                >
                    {listRender}
                </FlexBox>
            </Button>
        </FlexBox>
    );
};

export default Dropdown;
