import { ReactElement, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ModuleName } from '../../../../../../api/apiTypes/portalApiTypes';
import { Category, TicketType, UpdateTicketingTypeCategory } from '../../../../../../api/apiTypes/ticketingApiTypes';
import useModuleIsActive from '../../../../../../utility/hooks/useModuleIsActive';
import {
    useGetTicketsCountForCategory,
    useUpdateTicketingTypeCategory,
} from '../../../../apiQueries/useTicketingTypeCategory';
import { HorizontalRule } from '../../../../common/styles';
import { CategoryDuplicate, CategoryPublish, DeleteCategoryModal, CategoryEditForm } from './components';

const categoryNameSchema = (t: TFunction<'Ticketing', 'settings'>) => {
    return yup.object().shape({
        name: yup
            .string()
            .required(t('errorMessageNameIsRequired'))
            .min(2, t('errorMessageNameMustHaveMinValue'))
            .max(100, t('errorMessageNameMustHaveMaxValue'))
            .test(`unique-category`, t('errorMessageNameIsDublicateValue'), function (value, { options: { context } }) {
                return value !== undefined && context?.existingCategoryNamesWithoutCurrentLowerCased.length
                    ? !context?.existingCategoryNamesWithoutCurrentLowerCased.includes(value.toString().toLowerCase())
                    : true;
            }),
    });
};

const externalUrlSchema = (t: TFunction<'Ticketing', 'settings'>) => {
    return yup.object().shape({
        externalLinkUrl: yup
            .string()
            .test(
                'is-valid-url',
                t('invalidURL'),
                (value) => !value || /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/.test(value.trim()),
            ),
    });
};

const defaultStateValues = {
    name: {
        isSuccess: false,
        isLoading: false,
    },
    externalLinkUrl: {
        isSuccess: false,
        isLoading: false,
    },
};

interface GeneralTabProps {
    category: Category;
    typeId: string;
    existingTicketTypeCategoryNames: string[];
    changesSaved(isSaved: boolean): void;
    allTypes: TicketType[];
}
const GeneralTab = ({
    category,
    typeId,
    existingTicketTypeCategoryNames,
    changesSaved,
    allTypes,
}: GeneralTabProps): ReactElement => {
    const [categoryUpdateStatus, setCategoryUpdateStatus] =
        useState<Record<string, { isLoading: boolean; isSuccess: boolean }>>(defaultStateValues);
    const isExternalLinkUrlModuleIsActive = useModuleIsActive(ModuleName.TicketingExternalLink);
    const existingCategoryNamesWithoutCurrentLowerCased = existingTicketTypeCategoryNames
        .filter((categoryName: string) => {
            return categoryName !== category.name;
        })
        .map((categoryName) => categoryName.toLowerCase());

    const { data: ticketCountClosed } = useGetTicketsCountForCategory(typeId, category.id, true);
    const { data: ticketCountOpen } = useGetTicketsCountForCategory(typeId, category.id);
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const formIdCategoryName = 'EditCategoryName' + category.id;
    const formIdCategoryExternalUrl = 'EditCategoryExternalUrl' + category.id;
    const [updateCategory] = useUpdateTicketingTypeCategory();
    useUpdateTicketingTypeCategory();

    const onSubmit = async (data: UpdateTicketingTypeCategory) => {
        const updatingFieldName = 'externalLinkUrl' in data ? 'externalLinkUrl' : 'name';
        setCategoryUpdateStatus((p) => ({ ...p, [updatingFieldName]: { isLoading: true, isSuccess: false } }));
        await updateCategory([typeId, category.id, data], {
            onSuccess: () => changesSaved(true),
        });
        setCategoryUpdateStatus((p) => ({ ...p, [updatingFieldName]: { isLoading: false, isSuccess: true } }));
    };

    return (
        <>
            <CategoryEditForm
                headerLabel={t('whatNameOfThisCategory')}
                formId={formIdCategoryName}
                defaultValues={{ name: category.name }}
                isSuccess={categoryUpdateStatus.name.isSuccess}
                isLoading={categoryUpdateStatus.name.isLoading}
                onSubmit={onSubmit}
                formSchema={yupResolver(categoryNameSchema(t))}
                context={{ existingCategoryNamesWithoutCurrentLowerCased }}
                formFieldConfig={{
                    name: 'name',
                    label: tCommon('categoryName'),
                    required: true,
                    placeholder: tCommon('categoryName'),
                }}
                changesSaved={changesSaved}
            />
            <HorizontalRule />
            {isExternalLinkUrlModuleIsActive && (
                <>
                    <CategoryEditForm
                        headerLabel={t('doesCategoryOpensExternalUrl')}
                        description={t('userWillBeRedirectedToExternalLink')}
                        formId={formIdCategoryExternalUrl}
                        defaultValues={{ externalLinkUrl: category.externalLinkUrl ?? '' }}
                        isSuccess={categoryUpdateStatus.externalLinkUrl.isSuccess}
                        isLoading={categoryUpdateStatus.externalLinkUrl.isLoading}
                        onSubmit={onSubmit}
                        formSchema={yupResolver(externalUrlSchema(t))}
                        formFieldConfig={{
                            name: 'externalLinkUrl',
                            label: t('enterValidUrlLabel'),
                            required: false,
                            placeholder: t('enterValidUrlLabel'),
                        }}
                        changesSaved={changesSaved}
                    />
                    <HorizontalRule />
                </>
            )}
            <CategoryPublish categoryId={category.id} typeId={typeId} isPublished={category.isPublished} />
            <HorizontalRule />
            <CategoryDuplicate
                category={category}
                existingTicketTypeCategoryNames={existingTicketTypeCategoryNames}
                typeId={typeId}
            />
            <HorizontalRule />
            <DeleteCategoryModal
                category={category}
                typeId={typeId}
                disableDeleteCategory={existingTicketTypeCategoryNames.length <= 1}
                allTypes={allTypes}
                ticketCountOpen={ticketCountOpen ?? 0}
                ticketCountClosed={ticketCountClosed ?? 0}
            />
        </>
    );
};

export default GeneralTab;
