import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Category, TicketType } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { MobileViewBreakpoint } from '../../../../../../common/ScreenSizeBreakPoints';
import CaseworkerCard, { CaseWorkerType } from '../../../../../../components/caseWorkerCard/CaseworkerCard';
import { useUpdateTicketingTypeCategory } from '../../../../apiQueries/useTicketingTypeCategory';
import { Header, Paragraph, ChangesSaved } from '../../../../common/styles';
import useAutoSave from '../../hooks/useAutoSave';

const CaseworkersWrapper = styled.div`
    padding-top: 0.571rem;
`;

type NotificationCaseworkerCardProps = { isMobile: boolean };
const NotificationCaseworkerCard = styled(CaseworkerCard)`
    margin: 0.571rem 0;
    flex-direction: ${({ isMobile }: NotificationCaseworkerCardProps) => (isMobile ? 'column' : 'row')};
    align-items: ${({ isMobile }: NotificationCaseworkerCardProps) => (isMobile ? 'column' : 'center')};
`;

interface NotificationTabProps {
    category: Category;
    ticketType: TicketType;
}

const NotificationTab = ({ category, ticketType }: NotificationTabProps): ReactElement => {
    const [updateCategory, { isLoading, isSuccess, isError }] = useUpdateTicketingTypeCategory();
    const status = useAutoSave(isLoading, isSuccess, isError);
    const isMobile = window.matchMedia(`(max-width:${MobileViewBreakpoint ?? 768}px)`)?.matches;
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const onClickSetNotificationHandler = (caseWorkerId: string, pushEnabled: boolean, emailEnabled: boolean) => {
        const updatedNotificationsSettings = category.notificationSettings.map((caseworker) =>
            caseworker.caseWorkerId === caseWorkerId ? { caseWorkerId, pushEnabled, emailEnabled } : caseworker,
        );

        updateCategory([
            ticketType.id,
            category.id,
            {
                notificationSettings: updatedNotificationsSettings,
            },
        ]);
    };

    return (
        <>
            <Header>{t('chooseHowCaseManagersAreNotifiedHeader')}</Header>
            <Paragraph>{t('chooseHowCaseManagersAreNotifiedParagraph')}</Paragraph>
            <ChangesSaved>{status}</ChangesSaved>
            <CaseworkersWrapper>
                {ticketType.caseWorkers.length > 0 &&
                    ticketType.caseWorkers.map(({ id, email, avatar, firstName, lastName }) => {
                        return (
                            <NotificationCaseworkerCard
                                isMobile={isMobile}
                                id={id}
                                key={id}
                                name={`${firstName} ${lastName}`}
                                email={email}
                                avatar={avatar}
                                onClick={() => null}
                                type={CaseWorkerType.Notification}
                                selectCaseworker={false}
                                onClickNotification={onClickSetNotificationHandler}
                                notificationsSettings={category.notificationSettings.find(
                                    (caseworker) => caseworker.caseWorkerId === id,
                                )}
                                isError={isError}
                                isLoading={isLoading}
                            />
                        );
                    })}
            </CaseworkersWrapper>
        </>
    );
};

export default NotificationTab;
