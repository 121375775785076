import { FC } from 'react';
import ButtonStyled from './button.styled';
import { IButtonProps } from './types';

const Button: FC<IButtonProps> = ({ children, onClick, forwardedRef, type, form, ...props }) => {
    return (
        //TS Glitch with flex-direction
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <ButtonStyled ref={forwardedRef} onClick={onClick} {...props} type={type || 'button'} form={form}>
            {children}
        </ButtonStyled>
    );
};

export default Button;
