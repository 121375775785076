import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexBox, StressedText, Typography } from '../../../../../components/styleds';
import { getFormattedCurrency } from '../../../../../utility';
import { OrderSummaryProps } from './types';

const SummarySection: FC<OrderSummaryProps> = ({ isLoadingOrder, order }) => {
    const { t } = useTranslation('Food', { keyPrefix: 'foodForMeeting' });

    const deliveryFeeServices = useMemo(() => {
        const deliveryFeeGross = order
            ? order.deliveryFeeExcludingTax + (order.deliveryFeeExcludingTax * order.feeTaxPercent) / 100
            : 0;
        return getFormattedCurrency(deliveryFeeGross ?? 0);
    }, [order]);

    const tableSetUpServiceFee = useMemo(() => {
        const serviceFeeGross = order
            ? order.serviceFeeExcludingTax + (order.serviceFeeExcludingTax * order.feeTaxPercent) / 100
            : 0;
        return getFormattedCurrency((order?.attendees || 1) * (serviceFeeGross ?? 0));
    }, [order]);

    return (
        <FlexBox
            dir={'column'}
            background={'#fff'}
            boxShadow={'0px 4px 10px 0px rgba(0, 0, 0, 0.15)'}
            br={'10px'}
            overflow={'hidden'}
            skeleton={isLoadingOrder}
        >
            <FlexBox dir={'column'} pd={'1.143rem 1.714rem'}>
                <FlexBox justify={'space-between'} align={'center'}>
                    <Typography color={'#475156'} fontWeight={400} fontSize={'1.143rem'} lineHeight={'1.714rem'}>
                        {t('foodOrderOrderSummaryDiscountTitle')}
                    </Typography>
                    <Typography
                        color={'#475156'}
                        fontWeight={400}
                        fontSize={'1.143rem'}
                        lineHeight={'1.714rem'}
                        textAlign={'right'}
                    >
                        {order?.discount?.toFixed(2)?.replace('.', ',')}
                    </Typography>
                </FlexBox>
                <FlexBox justify={'space-between'} align={'center'}>
                    <Typography color={'#475156'} fontWeight={400} fontSize={'1.143rem'} lineHeight={'1.714rem'}>
                        {t('foodOrderOrderSummaryDiscountVat')}
                    </Typography>
                    <Typography
                        color={'#475156'}
                        fontWeight={400}
                        fontSize={'1.143rem'}
                        lineHeight={'1.714rem'}
                        textAlign={'right'}
                    >
                        {order?.tax.toFixed(2)}
                    </Typography>
                </FlexBox>
            </FlexBox>

            <FlexBox
                dir={'column'}
                pd={'1.143rem 1.714rem'}
                borderWidthTop={'1px'}
                borderColor={'#E9EBEB'}
                gap={'0.285rem'}
            >
                <FlexBox justify={'space-between'} align={'center'}>
                    <Typography color={'#475156'} fontWeight={400} fontSize={'1.143rem'} lineHeight={'1.714rem'}>
                        {t('foodOrderOrderSummaryDeliveryFee')}
                    </Typography>
                    <Typography
                        color={'#475156'}
                        fontWeight={400}
                        fontSize={'1.143rem'}
                        lineHeight={'1.714rem'}
                        textAlign={'right'}
                    >
                        {deliveryFeeServices}
                    </Typography>
                </FlexBox>
                <FlexBox justify={'space-between'} align={'center'}>
                    <Typography color={'#475156'} fontWeight={400} fontSize={'1.143rem'} lineHeight={'1.714rem'}>
                        {t('foodOrderOrderSummaryTableSetUpFee')}
                    </Typography>
                    <Typography
                        color={'#475156'}
                        fontWeight={400}
                        fontSize={'1.143rem'}
                        lineHeight={'1.714rem'}
                        textAlign={'right'}
                    >
                        {tableSetUpServiceFee}
                    </Typography>
                </FlexBox>
            </FlexBox>

            <FlexBox dir={'column'} pd={'1.143rem 1.714rem'} background={'rgba(0, 30, 255, 0.05)'}>
                <FlexBox justify={'space-between'} align={'center'}>
                    <Typography color={'#475156'} fontWeight={400} fontSize={'1.143rem'} lineHeight={'1.714rem'}>
                        {t('foodOrderOrderSummaryAmountTitle')}
                    </Typography>
                    <Typography
                        color={'#475156'}
                        fontWeight={400}
                        fontSize={'1.143rem'}
                        lineHeight={'1.714rem'}
                        textAlign={'right'}
                    >
                        {order?.totalExcludingTax.toFixed(2)}
                    </Typography>
                </FlexBox>
                <FlexBox justify={'space-between'} align={'center'}>
                    <Typography color={'#475156'} fontWeight={400} fontSize={'1.143rem'} lineHeight={'1.714rem'}>
                        <StressedText color={'#000'} fontSize={'1.429rem'} lineHeight={'2.286rem'} fontWeight={700}>
                            {t('foodOrderOrderSummaryAmountTotalTitle')}
                            &nbsp;
                        </StressedText>
                        {t('foodOrderOrderSummaryAmountTotalMeasure')}
                    </Typography>
                    <Typography
                        color={'#000'}
                        fontSize={'1.429rem'}
                        lineHeight={'2.286rem'}
                        fontWeight={700}
                        textAlign={'right'}
                    >
                        {order?.total.toFixed(2)}
                    </Typography>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    );
};
export default SummarySection;
