import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    ChangeType,
    TicketActivity,
    TicketPriority,
    TicketStatus,
    TypeCategoryActivityChange,
} from '../../../../../../../api/apiTypes/ticketingApiTypes';
import { MobileViewBreakpoint } from '../../../../../../../common/ScreenSizeBreakPoints';
import { Avatar } from '../../../../../../../components';
import { getDeviceLanguageDateFormat } from '../../../../../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import { TicketPriorityComponent, TicketStatusBadge } from '../../../TicketDetails/components';
import { MessageCard } from '../../components';

const Container = styled.div`
    display: grid;
    grid-template-areas:
        'avatar userName description timestamp'
        '. internalNote internalNote internalNote';
    grid-template-rows: auto auto;
    grid-template-columns: min-content min-content auto;
    gap: 0 0.714rem;
    width: 100%;
    align-items: center;

    @media (max-width: ${MobileViewBreakpoint}px) {
        display: grid;
        gap: 0 0.714rem;
        grid-template-areas:
            'avatar userName userName'
            'avatar timestamp timestamp'
            '. description description'
            '. internalNote internalNote';
        grid-template-rows: auto auto 1fr;
        grid-template-columns: auto 5fr auto;
    }
`;

const ChangedByAvatar = styled.div`
    grid-area: avatar;
`;

const ChangedByName = styled.p`
    grid-area: userName;
    margin: 0;
    font-weight: bold;
    font-size: 1.143rem;
    line-height: 1.714rem;
    min-width: max-content;
`;

const ActivityDescription = styled.div`
    grid-area: description;
    @media (max-width: ${MobileViewBreakpoint}px) {
        padding-top: 1rem;
    }
`;

const Description = styled.p`
    margin: 0;
`;

const BoldText = styled.span`
    color: #475156;
    font-weight: bold;
    margin: 0;
`;

const DescriptionWithBadge = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: 2.5rem;
`;

const Updated = styled.p`
    grid-area: timestamp;
    min-width: fit-content;
    margin: 0;
    margin-left: auto;

    @media (max-width: ${MobileViewBreakpoint}px) {
        margin-left: 0;
    }
`;

const InternalNoteContainer = styled.div`
    grid-area: internalNote;
`;

interface ActivityItemProps {
    activity: TicketActivity;
}

const ActivityItem = ({ activity }: ActivityItemProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    const formattedUpdatedDate = useMemo(() => {
        return getDeviceLanguageDateFormat(activity.createdDate, 'Pp');
    }, [activity]);

    const textHandler = (changeType: ChangeType, oldValue: string, newValue: string) => {
        switch (changeType) {
            case ChangeType.CreateTicket:
                return <Description>{t('createdInquiry')}</Description>;
            case ChangeType.AssignToOther:
                return (
                    <Description>
                        {t('assignedTo')} <BoldText>{newValue}</BoldText>
                    </Description>
                );
            case ChangeType.ChangeTicketStatus:
                return (
                    <DescriptionWithBadge>
                        <Description>{t('changeStatusTo')}</Description>
                        <TicketStatusBadge status={newValue as TicketStatus}>{newValue}</TicketStatusBadge>
                    </DescriptionWithBadge>
                );
            case ChangeType.ChangeInternalTicketStatus:
                return (
                    <Description>
                        {t('changeInternalStatusTo')} {newValue}
                    </Description>
                );
            case ChangeType.ChangeCategory:
                return (
                    <Description>
                        {t('changeCategoryFrom')} <BoldText>{oldValue}</BoldText>
                        {t('to')}
                        <BoldText>{newValue}</BoldText>
                    </Description>
                );
            case ChangeType.ChangeTypeAndCategory: {
                const oldValueParsed: TypeCategoryActivityChange = JSON.parse(oldValue);
                const newValueParsed: TypeCategoryActivityChange = JSON.parse(newValue);

                return (
                    <Description>
                        {t('changeTypeFrom')} <BoldText>{oldValueParsed.TypeName}</BoldText> {t('to')}
                        <BoldText>{newValueParsed.TypeName}</BoldText>
                        {t('andCategoryFrom')}
                        <BoldText>{oldValueParsed.CategoryName}</BoldText> {t('to')}
                        <BoldText>{newValueParsed.CategoryName}</BoldText>
                    </Description>
                );
            }
            case ChangeType.AddInternalNote:
                return <Description>{t('addedInternalMemo')}</Description>;

            case ChangeType.ChangePriority:
                return (
                    <DescriptionWithBadge>
                        <Description>{t('changedPriorityTo')}</Description>
                        <TicketPriorityComponent priority={newValue as TicketPriority}>
                            {newValue}
                        </TicketPriorityComponent>
                    </DescriptionWithBadge>
                );

            case ChangeType.AssignToSelf:
                return (
                    <Description>
                        {t('assignedTo')} <BoldText>{t('itself')}</BoldText>
                    </Description>
                );

            default:
                break;
        }
    };

    return (
        <Container>
            <ChangedByAvatar>
                {activity.registeredBy && <Avatar {...activity.registeredBy} image={activity.registeredBy.avatar} />}
            </ChangedByAvatar>
            <ChangedByName>
                {activity.registeredBy.firstName} {activity.registeredBy.lastName}
            </ChangedByName>
            <ActivityDescription>
                {textHandler(activity.changeType, activity.oldValue, activity.newValue)}
            </ActivityDescription>
            <Updated>{formattedUpdatedDate}</Updated>
            {activity.internalNote && (
                <InternalNoteContainer>
                    <MessageCard
                        comment={{ ...activity.internalNote }}
                        highlighted={false}
                        showMessageDetails={false}
                    />
                </InternalNoteContainer>
            )}
        </Container>
    );
};

export default ActivityItem;
