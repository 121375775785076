import styled from 'styled-components';

const Separator = styled.div`
    width: 1px;
    height: 100%;
    border-left: 1px solid var(--border-color);
    margin: 0 1rem;
`;

export default Separator;
