import i18n, { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-fs-backend';
import { initReactI18next } from 'react-i18next';
import { defaultNS, Languages, resources } from './i18next';

use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        ns: [
            'myProfile',
            'Dashboard',
            'Ticketing',
            'Meeting',
            'ShopAtWork',
            'VendorSettings',
            'common',
            'Admin',
            'Food',
            'authorization',
            'notification',
            'WIFIAtWork',
        ],
        defaultNS: defaultNS,
        fallbackLng: 'en',
        debug: false,
        supportedLngs: Languages,
        interpolation: {
            escapeValue: false,
        },
        resources,
    });

export default i18n;
