import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const theme = {
    Failure: {
        backgroundColor: '#FFD4D4',
        color: 'var(--error-color-dark)',
        icon: '#F2453D',
    },
    Success: {
        backgroundColor: '#D2F8B6',
        color: 'var(--success-color-dark)',
        icon: '#00C853',
    },
    Warning: {
        backgroundColor: '#FFF6C9',
        color: '#7A6700',
        icon: '#7A6700',
    },
    Default: {
        backgroundColor: '#E9EBEB',
        color: '#475156',
        icon: '#475156',
    },
    Primary: {
        backgroundColor: '#E1F6FF',
        color: '#001EFF',
        icon: '#001EFF',
    },
};

export enum BadgeType {
    Failure = 'Failure',
    Success = 'Success',
    Warning = 'Warning',
    Default = 'Default',
    Primary = 'Primary',
}

type ContainerProps = { type: BadgeType };
const Container = styled.span`
    display: flex;
    align-items: center;
    background-color: ${({ type }: ContainerProps) => theme[type]?.backgroundColor ?? 'grey'};
    color: ${({ type }: ContainerProps) => theme[type]?.color ?? 'white'};
    height: auto;
    width: auto;
    min-width: fit-content;
    padding: 0.571rem 1.142rem;
    border-radius: 2.143rem;

    svg {
        margin-right: 0.714rem;
        width: 0.571rem;
        height: 0.571rem;
        circle {
            fill: ${({ type }: ContainerProps) => theme[type]?.icon ?? 'lightgrey'};
        }
    }
`;

interface BadgeProps {
    type?: BadgeType;
    id?: string;
    className?: string;
    children: ReactNode | string;
}

const Badge = ({ type = BadgeType.Default, id, className, children }: PropsWithChildren<BadgeProps>): ReactElement => {
    return (
        <Container type={type} id={id} className={className}>
            {children}
        </Container>
    );
};

export default Badge;
