import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Invoice } from '../../../../../api/apiTypes/shopApiTypes';
import { getFormattedDate } from '../../Purchase/components/utils';
import { getShopArWorkFormOfPaymentTranslation } from '../../PurchasesOverview/utils';

const Information = styled.div`
    display: flex;
    gap: 1.714rem;
`;

const InformationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.714rem;
    flex: 1;
`;

const InformationContainer = styled.div``;

const TextWrapper = styled.div`
    display: flex;
    gap: 0.571rem;

    &:not(:last-child) {
        margin-bottom: 0.429rem;
    }
`;

const Text = styled.p`
    margin: 0;
`;

const Label = styled.p`
    font-weight: 700;
    margin: 0;
`;

interface InvoiceDetailsProps {
    orderInvoice: Invoice;
}

const InvoiceDetails = ({ orderInvoice }: InvoiceDetailsProps): ReactElement => {
    const { t, i18n } = useTranslation('ShopAtWork');
    const language = i18n.language;
    const {
        portalName,
        portalAddress,
        portalCountry,
        portalOrganizationNumber,
        order,
        createdBy,
        orderReference,
        customerName,
        contactPersonFirstName,
        customerAddress,
        customerCountry,
        customerOrganizationNumber,
    } = orderInvoice;
    return (
        <Information>
            <InformationWrapper>
                <InformationContainer>
                    <Label>{t('invoice.supplier')}</Label>
                    <Text>{portalName}</Text>
                    <Text>{portalAddress}</Text>
                    <Text>{portalCountry}</Text>
                    <Text>
                        {t('invoice.organizationNumber')}
                        {portalOrganizationNumber}
                    </Text>
                </InformationContainer>
                <InformationContainer>
                    <TextWrapper>
                        <Label>{t('invoice.purchaseOrderNumber')}</Label>
                        <Text>{order.orderNumber}</Text>
                    </TextWrapper>
                    <TextWrapper>
                        <Label>{t('invoice.paymentType')}:</Label>
                        <Text>{getShopArWorkFormOfPaymentTranslation(order.paymentType)}</Text>
                    </TextWrapper>
                    <TextWrapper>
                        <Label>{t('invoice.oderDate')}</Label>
                        <Text>{getFormattedDate(order.createdDate, language)}</Text>
                    </TextWrapper>
                </InformationContainer>
            </InformationWrapper>
            <InformationWrapper>
                <InformationContainer>
                    <Label>{t('invoice.createdBy')}</Label>
                    <Text>
                        {createdBy.firstName} {orderInvoice.createdBy.lastName}
                    </Text>
                </InformationContainer>
                <InformationContainer>
                    <Label>{t('invoice.orderReference')}</Label>
                    <Text>{orderReference}</Text>
                </InformationContainer>
                <InformationContainer>
                    <Label>{t('invoice.customerName')}</Label>
                    <Text>{customerName}</Text>
                    <Text>
                        {`${t('invoice.contactPersonPrefix')} ${contactPersonFirstName} ${
                            orderInvoice.contactPersonLastName
                        }`}
                    </Text>
                    <Text>{customerAddress}</Text>
                    <Text>{customerCountry}</Text>
                    <Text>
                        {t('invoice.organizationNumber')}
                        {customerOrganizationNumber}
                    </Text>
                </InformationContainer>
            </InformationWrapper>
        </Information>
    );
};

export default InvoiceDetails;
