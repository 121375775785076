import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SidebarMenuItem } from '../../components';
import { useUserIsMeetingsApprover } from './apiQueries/useMeetingBookings';
import useMeetingNavigation from './useMeetingNavigation';

const MeetingDynamicMenuItem = (): ReactElement => {
    const { t } = useTranslation('common');
    const { data: userIsMeetingsApprover } = useUserIsMeetingsApprover();
    const {
        goToMeetingOverview,
        settingsLink,
        myCalendarLink,
        meetingOverviewLink,
        approvalRequestsLink,
        goToMyCalendar,
        goToSettings,
        goToApprovalRequests,
        userHaveMeetingPortalSettingsWritePerm,
    } = useMeetingNavigation();
    const { pathname } = useLocation();

    const isMeetingPathName =
        pathname?.includes(myCalendarLink) ||
        pathname?.includes(meetingOverviewLink) ||
        pathname?.includes(settingsLink) ||
        pathname?.includes(approvalRequestsLink);
    return (
        <SidebarMenuItem
            icon="CalendarIcon"
            isActive={isMeetingPathName}
            onClick={goToMeetingOverview}
            text={t('meetingBooking')}
        >
            {userIsMeetingsApprover ? (
                <SidebarMenuItem
                    isActive={pathname?.includes(approvalRequestsLink)}
                    onClick={goToApprovalRequests}
                    text={t('approvalRequestsMenuLabel')}
                />
            ) : null}
            <SidebarMenuItem
                isActive={pathname?.includes(meetingOverviewLink)}
                onClick={goToMeetingOverview}
                text={t('meetingBooking')}
            />
            <SidebarMenuItem
                isActive={pathname?.includes(myCalendarLink)}
                onClick={goToMyCalendar}
                text={t('myCalendar')}
            />
            {userHaveMeetingPortalSettingsWritePerm ? (
                <SidebarMenuItem
                    isActive={pathname?.includes(settingsLink)}
                    onClick={goToSettings}
                    text={t('settingsMenuLabel')}
                />
            ) : null}
        </SidebarMenuItem>
    );
};

export default MeetingDynamicMenuItem;
