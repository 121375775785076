import { AxiosPromise } from 'axios';
import qs from 'qs';
import { NotificationDto, NotificationStatus } from './apiTypes/msgApiTypes';
import http from './infrastructure/httpService';
import { getBaseUrlApim, getPortalId } from './utils';

const urlMsg = process.env.REACT_APP_APIM_MSG_URL;

export const markNotifications = (
    notificationIds: string[],
    status: NotificationStatus,
): AxiosPromise<NotificationDto[]> => {
    const body = notificationIds.map((id) => ({ id, status }));
    return http.patch(`${getBaseUrlApim()}/${urlMsg}/portals/${getPortalId()}/notifications`, body);
};

export const getAllNotifications = (
    lang: string,
    options: { skip?: number; take?: number } = {},
): AxiosPromise<NotificationDto[]> => {
    const params = qs.stringify(options, { addQueryPrefix: true });
    return http.get(`${getBaseUrlApim()}/${urlMsg}/${lang}/portals/${getPortalId()}/notifications/all${params}`);
};

export const getUnseenNotificationsCount = (): AxiosPromise<number> => {
    const url = `${getBaseUrlApim()}/${urlMsg}/portals/${getPortalId()}/notifications/unreadCount`;
    return http.get(url);
};

export const markAllSentNotificationsAsSeen = (): AxiosPromise<undefined> => {
    const url = `${getBaseUrlApim()}/${urlMsg}/portals/${getPortalId()}/notifications/seen`;
    return http.post(url);
};
