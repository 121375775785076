import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigation } from '../../utility';
import { RootLocation } from './DailyMenu/EditDailyMenu/EditDailyMenu';

const useDashboardNavigation = (): {
    getFrontPageLink: string;
    goToFrontPage(): void;
    editDailyMenuLink: string;
    goToEditDailyMenu(): void;
    goToCanteenPass(): void;
    pathname: string;
} => {
    const navigation = useNavigation();
    const { pathname } = useLocation();

    return useMemo(() => {
        const frontPageLink = '/dashboard';
        const editDailyMenuLink = '/dashboard/dailyMenu/edit';
        const canteenPassLink = '/dashboard/canteenPass';
        return {
            pathname,
            editDailyMenuLink,
            getFrontPageLink: frontPageLink,
            goToFrontPage: () => navigation.push(frontPageLink),
            goToEditDailyMenu: () => navigation.push(editDailyMenuLink, { page: RootLocation.Dashboard }),
            goToCanteenPass: () => navigation.push(canteenPassLink),
        };
    }, [navigation]);
};
export default useDashboardNavigation;
