import styled, { css } from 'styled-components';
import { BORDER_STYLES, FLEX_STYLES, MARGIN_PADDING_STYLES, OVERFLOW_STYLES, POSITION_STYLES } from '../stylesHelpers';
import { IButtonStyles } from './types';

const ButtonStyled = styled.button.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})<IButtonStyles>`
    width: ${({ width }) => width ?? '100%'};
    height: ${({ height }) => height ?? '2.857rem'};
    background: ${({ background }) => background ?? 'var(--primary-color)'};
    color: ${({ color }) => color ?? '#000'};
    cursor: ${({ cursor }) => cursor ?? 'pointer'};
    opacity: ${({ opacity }) => opacity ?? 1};
    border-radius: ${({ borderRadius }) => borderRadius ?? 0};
    ${(props) =>
        props.hoverBackground &&
        `&:hover {
            background: ${props.hoverBackground};
            & > p {
                color: ${props.hoverTextColor ?? '#000'} ; 
            }
        }`}

    ${(props) => FLEX_STYLES(props)};
    ${(props) => MARGIN_PADDING_STYLES(props)};
    ${(props) => BORDER_STYLES(props)};
    ${(props) => OVERFLOW_STYLES(props)};
    ${(props) => POSITION_STYLES(props)};
    ${({ disabled }) =>
        disabled &&
        css`
            background: #d4d6d7;
            cursor: not-allowed;
            opacity: 0.6;
            &:disabled path {
                fill: #a8adaf;
                cursor: auto;
            }
        `}
`;

export default ButtonStyled;
