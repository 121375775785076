import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { IRootStateProps } from '../../../../reducers';
import { Module, ModuleName } from '../../../api/apiTypes/portalApiTypes';
import { isHealthAndFitness } from '../../../helpers/isService';
import ArticlesDynamicMenuItem from '../../../modules/Articles/ArticlesDynamicMenuItem';
import PinnedCategoriesDynamicMenuItem from '../../../modules/Articles/PinnedCategoriesDynamicMenuItem';
import EventsDynamicMenuItem from '../../../modules/Booking/EventsDynamicMenuItem';
import DashboardDynamicMenuItem from '../../../modules/Dashboard/DashboardDynamicMenuItem';
import FoodDynamicMenuItem from '../../../modules/Food/FoodDynamicMenuItem';
import FoodExternalDynamicMenuItem from '../../../modules/Food/FoodExternalDynamicMenuItem';
import FoodForMeetingsDynamicMenuItem from '../../../modules/FoodForMeetings/FoodForMeetingsDynamicMenuItem';
import MeetingDynamicMenuItem from '../../../modules/Meeting/MeetingDynamicMenuItem';
import HouseOfOsloDynamicMenuItem from '../../../modules/MeetingHouseOfOslo/HouseOfOsloDynamicMenuItem';
import { ShopDynamicMenuItem } from '../../../modules/ShopAtWork';
import { TicketingDynamicMenuItem } from '../../../modules/Ticketing';
import { useUserHasTicketingAccess, useUserIsCaseWorker } from '../../../modules/Ticketing/apiQueries/useTicketingUser';
import TicketingExternalDynamicMenuItem from '../../../modules/Ticketing/TicketingExternalDynamicMenuItem';
import WardrobeDynamicMenuItem from '../../../modules/Wardrobe/WardrobeDynamicMenuItem';
import { useUserHasPerm, useHasConferenceMealAccess } from '../../../utility';
import { Service } from '../../lifeAtWorkServices';
import Perm from '../../perms';

interface DynamicMenuItemProps {
    module: Module;
}

const findSubModuleByName = (modules: Module[], targetName: ModuleName): string | null => {
    for (const module of modules) {
        if (module.name === targetName) {
            return module.name;
        }
        if (module.children.length > 0) {
            const foundModule = findSubModuleByName(module.children, targetName);
            if (foundModule) {
                return foundModule;
            }
        }
    }
    return null;
};

const DynamicMenuItem = ({ module }: DynamicMenuItemProps): ReactElement | null => {
    const { data: userHasTicketingAccess } = useUserHasTicketingAccess();
    const isFoodForMeetingActivated = useHasConferenceMealAccess();
    const canEditTypes = useUserHasPerm(Perm.TicketingTypesUpdate);
    const { data: isUserCaseWorker } = useUserIsCaseWorker(!canEditTypes);

    const services = useSelector<IRootStateProps, Service[]>((state) => state.app.services);

    const healthAndFitnessIsActivated = !!services.find(isHealthAndFitness);

    if (module?.name === ModuleName.Dashboard) return <DashboardDynamicMenuItem />;
    if (module?.name === ModuleName.FoodExternal) return <FoodExternalDynamicMenuItem />;
    if (module?.name === ModuleName.Meeting) return <MeetingDynamicMenuItem />;
    if (module?.name === ModuleName.Food && !isFoodForMeetingActivated) return <FoodDynamicMenuItem />;
    if (module?.name === ModuleName.FoodForMeetings && isFoodForMeetingActivated)
        return <FoodForMeetingsDynamicMenuItem />;
    if (
        module?.name === ModuleName.Booking &&
        healthAndFitnessIsActivated &&
        findSubModuleByName(module.children, ModuleName.HealthAndFitness)
    )
        return <EventsDynamicMenuItem />;
    if (module?.name === ModuleName.Ticketing && (userHasTicketingAccess || isUserCaseWorker?.isCaseWorker))
        return <TicketingDynamicMenuItem />;
    if (module?.name === ModuleName.TicketingExternal) return <TicketingExternalDynamicMenuItem />;
    if (module?.name === ModuleName.Wardrobe) return <WardrobeDynamicMenuItem />;
    if (module?.name === ModuleName.ShopAtWork) return <ShopDynamicMenuItem />;
    if (module.name === ModuleName.Articles)
        return (
            <>
                <ArticlesDynamicMenuItem />
                <PinnedCategoriesDynamicMenuItem />
            </>
        );
    if (module.name === ModuleName.MeetingHouseOfOslo) return <HouseOfOsloDynamicMenuItem />;
    return null;
};

export default DynamicMenuItem;
