import { getPortalIdStatic } from '../common/apiQueries/useAppConfiguration';
import { getApimUrl } from '../utility/envReader';

export const getBaseUrlApim = (): string => {
    const value: string = getApimUrl();
    if (!value) throw new Error('Could not find apimUrl');
    return value;
};

export const getPortalId = (): number => {
    const portalId: number = getPortalIdStatic();
    if (!portalId) throw new Error("Can't get portalId");
    return portalId;
};
