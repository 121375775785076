import { ReactElement, PropsWithChildren, ChangeEvent } from 'react';
import styled from 'styled-components';

interface CustomRadioProps {
    disabled?: boolean;
}

const RadioContainer = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;

    :focus-within > div {
        outline: 1px solid var(--primary-color);
        outline-offset: 2px;
    }
`;

const RadioInput = styled.input`
    opacity: 0;
    width: 0;

    &[type='radio']:checked ~ div > div {
        background: var(--primary-color);
        width: calc(1.14rem - 8px);
        height: calc(1.14rem - 8px);
        margin: 2px;
        border-radius: 50%;
    }
    &[type='radio']:checked ~ span {
        border-color: var(--primary-color);
        font-weight: bold;
        color: var(--primary-color);
    }
`;

const CustomRadio = styled.div`
    border: ${({ disabled }: CustomRadioProps) => (disabled ? '1px solid #E9EBEB' : '2px solid var(--primary-color)')};
    background-color: ${({ disabled }: CustomRadioProps) => (disabled ? 'var(--background-color)' : 'inherit')};
    display: block;
    border-radius: 50%;
    min-width: 1.14rem;
    min-height: 1.14rem;
`;

const Label = styled.span`
    padding: 0.71rem 0 0.71rem 1.14rem;
`;

interface RadioButtonProps {
    name: string;
    value: string;
    defaultChecked?: boolean;
    checked?: boolean;
    disabled?: boolean;
    onChange?(e: ChangeEvent<HTMLInputElement>): void;
}

const RadioButton = ({
    name,
    value,
    children,
    defaultChecked,
    checked,
    disabled,
    onChange,
}: PropsWithChildren<RadioButtonProps>): ReactElement => {
    return (
        <RadioContainer>
            <RadioInput
                type="radio"
                name={name}
                value={value}
                onChange={onChange}
                defaultChecked={defaultChecked}
                checked={checked}
                tabIndex={0}
            />
            <CustomRadio disabled={disabled} aria-hidden={true}>
                <div />
            </CustomRadio>
            {children && <Label> {children}</Label>}
        </RadioContainer>
    );
};

export default RadioButton;
