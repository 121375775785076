import React, {
    BaseSyntheticEvent,
    KeyboardEvent,
    PropsWithChildren,
    ReactNode,
    ReactPortal,
    SyntheticEvent,
    useState,
} from 'react';
import type { State } from '@popperjs/core';
import { startOfMonth, endOfMonth, isBefore } from 'date-fns';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { createPortal } from 'react-dom';
import type { IconProps } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { SvgIcon } from '../..';
import { getDeviceLanguageDateFormat } from '../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import { useIsMobile } from '../../../utility/hooks/useIsMobile';
import { getCustomNBLocale } from './utils';
import 'react-datepicker/dist/react-datepicker.css';

const locale = getCustomNBLocale();
registerLocale('nb', locale);

const ResetButton = styled.button`
    font-size: 100%;
    font-family: inherit;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    padding: 0.5rem;
    margin-left: auto;
    color: var(--text-placeholder-color);

    svg {
        height: 12px;
        width: 12px;
    }

    :focus,
    :focus-visible {
        outline: 1px solid var(--primary-color);
        outline-style: auto;
    }
    :focus:not(:focus-visible) {
        outline: none;
    }
`;

const StyledDatePicker = createGlobalStyle`
    .react-datepicker > * {
        font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    }

    .calendar-picker {
        background-color: var(--surface-color-light);
        font-size: 1rem;
        box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
        border-radius: 5px;
        border: none;
        cursor: auto;
    }

    .react-datepicker-popper {
        z-index: 4;
    }
    
    .react-datepicker__input-container > * {
        font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
        background-color: #fff;
        border: none !important;
        text-align: left;
        width: 100%;
        cursor: pointer;
        outline: none !important;
    }

    #endDate::placeholder {
        font-size: 0.875rem;
    }

    .react-datepicker__header {
        background-color: #fff;
        border-bottom: none;
    }

    .react-datepicker__current-month,
    .react-datepicker-year-header {
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.5rem;
        color: var(--text-high-emphasis-color) !important;
        border-bottom: 1px solid #d4d6d7;
        padding: 1rem 0;
        cursor: pointer;
    }

    .react-datepicker__day {
        line-height: 3rem;
        width: 3rem;
        outline: none;
        border-radius: 50%;
    }

    .react-datepicker__day--disabled, .react-datepicker__day--excluded {
        color: #6e6e6e;

        :hover {
            color: #000 !important;
            background-color: grey !important;
        }
    }

    .react-datepicker__day:hover {
        background-color: var(--primary-color-light);
        border-radius: 50%;
        color: #fff;
    }

    .react-datepicker__day-name {
        line-height: 3rem;
        width: 3rem;
        color: var(--primary-color);
    }

    .react-datepicker__day--selected {
        background-color: var(--primary-color);
        border-radius: 50%;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: #fff;
        box-shadow: 0px 0px 0px 2px var(--primary-color-light);
        border-radius: 50%;
        color: #000;
    }

    .react-datepicker__day--keyboard-selected.react-datepicker__day--disabled.react-datepicker__day--excluded  {  
        background-color: grey;
        box-shadow: none;
        color: #000 !important;
    }

    .react-datepicker__day--keyboard {
        border: none;
    }

    .react-datepicker__month-wrapper {
        width: auto;
        display: flex;
        justify-content: space-around;
    }

    .react-datepicker__month-text {
        width: 6rem !important;
        margin: 6px !important;
    }

    .react-datepicker__month {
        line-height: 3rem;
        width: inherit;
    }

    .react-datepicker__month-text:hover {
        background-color: var(--primary-color-light) !important;
        color: #fff;
    }

    .react-datepicker__month--selected {
        background-color: var(--primary-color);
        outline: none;   
    }

    .react-datepicker__month-text--keyboard-selected {
        background-color: var(--primary-color-light) !important;
        color: #fff;
        outline: none;
    }

    .react-datepicker__month--disabled.react-datepicker__month-text--keyboard-selected {
        background-color: grey !important;
        color: #000 !important;
    }

    .react-datepicker__navigation {
        width: 30px;
        height: 15px;
        border: none;
        outline: none;
        margin: 5px;
        padding: 1.7rem;
        position: absolute;
        top: 0;
    }

    .react-datepicker__navigation--previous:hover {
        color: red !important;
    }

    @media (max-width: 450px), (max-height: 650px) {
        .react-datepicker__day-name {
            line-height: 2.5rem;
            width: 2.5rem;
        }
        .react-datepicker__day {
            line-height: 2.5rem;
            width: 2.5rem;
            outline: none;
        }

        .react-datepicker__month {
            line-height: 2.3rem; 
            outline: none;
        }
    }

    @media (max-height: 350px) {
        .react-datepicker__day-name {
            line-height: 7vh;
            width: 2.3rem;
        }
        .react-datepicker__day {
            line-height: 7vh;
            width: 2.3rem;
            outline: none;
        }

        .react-datepicker__month {
                line-height: 7vh; 
                outline: none;
            }
    }

    @media (max-width: 300px) {
        .react-datepicker__day-name {
            line-height: 2.5rem;
            width: 10vw;
        }
        .react-datepicker__day {
            line-height: 2.5rem;
            width: 10vw;
            outline: none;
        }

        .react-datepicker__month {
            line-height: 2.3rem; 
            outline: none;
        }

        .react-datepicker__month-text {
            width: 4rem !important;
        }
    }
`;

type StyledDatePickerWrapper = { hideBorder: boolean; hasValue: boolean; disabled: boolean; height?: string };
const StyledDatePickerWrapper = styled.div`
    display: flex;
    height: ${({ height }: StyledDatePickerWrapper) => height || 'auto'};
    background-color: var(--surface-color-light);
    border: ${({ hideBorder }: StyledDatePickerWrapper) =>
        hideBorder ? 'none' : '1px solid var(--border-color) !important'};
    border-radius: 5px !important;
    white-space: nowrap;
    width: 100%;
    padding: 0 1rem;
    align-items: center;
    min-width: 150px;
    cursor: pointer;
    .datePicker {
        color: ${({ hasValue }: StyledDatePickerWrapper) =>
            hasValue ? 'var(--text-high-emphasis-color)' : 'var(--text-placeholder-color)'};
    }
    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
            opacity: 0.6;
            input {
                cursor: not-allowed;
            }
        `}
`;

const StyledIcon = styled(Icon)`
    &&& {
        background-color: var(--surface-color-light);
        box-shadow: none;
        align-self: center;
        color: var(--text-medium-emphasis-color);
    }
`;

const Label = styled.label`
    display: inline-block;
    background-color: transparent;
    padding: 0;
    margin: 0em 0em 0.28571429rem 0em;
    font-size: 0.8571rem;
    font-weight: normal;
    line-height: 1rem;
    color: var(--text-placeholder-color);
`;

const HelpText = styled.p`
    font-size: 0.8571rem !important;
`;

const PopperBackDrop = styled.div`
    width: 100%;
    height: 100%;
    z-index: 10000;
    position: absolute;
    background: rgba(71, 81, 86, 0.8);
`;

const PopperButton = styled.button`
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: #e9ebeb;
    padding: 12px;
    border: none;
    margin: 1rem;
`;

const ButtonContainer = styled.div`
    position: absolute;
    bottom: -75px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
`;

const StyledPopperButtonIcon = styled(Icon)`
    color: #475156;
    && {
        margin: 0;
    }
`;

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    overflow-y: auto;
`;

interface IDatePickerProps {
    name: string;
    label?: string;
    value?: Date | null;
    onChange(selectedDate: Date | undefined): void;
    icon?: IconProps;
    minDate?: Date | null;
    maxDate?: Date | null;
    dateformat?: string;
    disabled?: boolean;
    excludeDates?: Date[];
    hideBorder?: boolean;
    placeholder?: string;
    placeholderText?: string;
    helpText?: string;
    parentElementId?: string;
    resettable?: boolean;
    className?: string;
    height?: string;
    isPreventDefault?: boolean;
}

const DatePicker: React.FC<React.PropsWithChildren<IDatePickerProps>> = ({
    name,
    label,
    value,
    minDate,
    maxDate,
    dateformat = 'd.MM.yyyy',
    icon,
    onChange,
    disabled = false,
    excludeDates,
    hideBorder = false,
    placeholder = '',
    placeholderText = '',
    helpText = '',
    parentElementId = 'site-container',
    resettable,
    className,
    height,
    isPreventDefault,
}: IDatePickerProps): JSX.Element => {
    const isMobile = useIsMobile();
    const [openCalendar, setOpenCalendar] = useState<boolean>(false);
    const [showMonthYearPicker, setShowMonthYearPicker] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
    const handleChange = (date: Date, e: SyntheticEvent): void => {
        if (date && !disabled) {
            if (showMonthYearPicker) {
                setShowMonthYearPicker(false);
            } else {
                if (!isMobile) {
                    onChange(date);
                    setOpenCalendar(false);
                }
            }
            !isPreventDefault && setSelectedDate(date);
        }
    };

    const handleCloseCalendar = (): void => {
        setOpenCalendar(false);
        setShowMonthYearPicker(false);
    };

    const onClickCalendarHandler = (e: BaseSyntheticEvent): void => {
        e.preventDefault();
        const targetClassName = e.target.className;
        const targetId = e.target.id;

        if (targetClassName === 'react-datepicker__current-month') {
            setShowMonthYearPicker(true);
        } else if (targetClassName.includes('react-datepicker__month-text')) {
            setShowMonthYearPicker(false);
        } else if ((targetId === 'datePickerWrapper' || targetId === 'datePickerIcon') && !disabled)
            setOpenCalendar(true);
    };

    const onKeyDownHandler = (e: KeyboardEvent): void => {
        if (e.code === 'Tab' || e.code === 'Escape') {
            handleCloseCalendar();
        }
    };

    const onClickSetDateForMobileHandler = (): void => {
        if (selectedDate && !disabled) {
            if (showMonthYearPicker) {
                setShowMonthYearPicker(false);
            } else {
                onChange(selectedDate);
                setOpenCalendar(false);
            }
        }
    };

    const onClickCancelDateForMobileHandler = (): void => {
        setSelectedDate(undefined);
        if (showMonthYearPicker) setShowMonthYearPicker(false);
        setOpenCalendar(false);
    };

    const onClickResetHandler = (e: SyntheticEvent): void => {
        e.stopPropagation();
        setSelectedDate(undefined);
        resettable && onChange(undefined);
    };

    const onSelectHandler = (selectedDate: Date): void => {
        if (minDate && isBefore(selectedDate, minDate)) setSelectedDate(minDate);
    };

    const CalendarContainer = ({ children }: PropsWithChildren<unknown>): ReactPortal | ReactNode | undefined => {
        const element = document.querySelector(`#${parentElementId}`);

        if (element) {
            return createPortal(
                <PopperBackDrop>
                    <Wrapper>{children}</Wrapper>
                </PopperBackDrop>,
                element,
            );
        }
        return children;
    };

    return (
        <>
            {label && <Label>{label}</Label>}
            <StyledDatePickerWrapper
                hideBorder={hideBorder}
                onClick={onClickCalendarHandler}
                id="datePickerWrapper"
                className={className}
                hasValue={!!value}
                height={height}
                disabled={disabled}
            >
                {icon ? <StyledIcon name={icon.name} id="datePickerIcon" /> : null}
                <ReactDatePicker
                    onSelect={onSelectHandler}
                    onFocus={() => setOpenCalendar(true)}
                    open={openCalendar}
                    id={name}
                    name={name}
                    calendarClassName="calendar-picker"
                    locale={locale}
                    selected={selectedDate ?? value}
                    value={value ? getDeviceLanguageDateFormat(value, dateformat) : placeholder}
                    placeholderText={placeholderText}
                    minDate={showMonthYearPicker && minDate ? startOfMonth(minDate) : minDate}
                    maxDate={showMonthYearPicker && maxDate ? endOfMonth(maxDate) : maxDate}
                    onChange={handleChange}
                    className="datePicker"
                    popperClassName="datePickerPopper"
                    dateFormat={dateformat}
                    dateFormatCalendar="MMMM, yyyy"
                    popperModifiers={
                        isMobile
                            ? [
                                  {
                                      name: 'preventOverflow',
                                      options: {
                                          rootBoundary: 'viewport',
                                          tether: false,
                                          altAxis: true,
                                      },
                                  },
                                  {
                                      name: 'computeStyles',
                                      fn({ state }: { state: State }) {
                                          state.styles.popper = {
                                              ...state.styles.popper,
                                              position: 'fixed',
                                              left: `${(window.innerWidth - state.rects.popper.width) / 2}px`,
                                              top: '50%',
                                              transform: 'translateY(-50%)',
                                          };
                                          return state;
                                      },
                                  },
                              ]
                            : undefined
                    }
                    disabled={disabled}
                    excludeDates={showMonthYearPicker ? [] : excludeDates}
                    showMonthYearPicker={showMonthYearPicker}
                    onClickOutside={handleCloseCalendar}
                    onKeyDown={onKeyDownHandler}
                    autoComplete="off"
                    readOnly={isMobile}
                    popperContainer={isMobile && openCalendar ? CalendarContainer : undefined}
                    showPopperArrow={!isMobile}
                >
                    {isMobile && (
                        <ButtonContainer>
                            <PopperButton onClick={onClickCancelDateForMobileHandler}>
                                <StyledPopperButtonIcon name="cancel" aria-label="avbryt" />
                            </PopperButton>
                            <PopperButton onClick={onClickSetDateForMobileHandler}>
                                <StyledPopperButtonIcon name="check" aria-label="bekreft dato" />
                            </PopperButton>
                        </ButtonContainer>
                    )}
                </ReactDatePicker>
                <StyledDatePicker />
                {resettable && value && (
                    <ResetButton onClick={onClickResetHandler} type="button">
                        <SvgIcon name="UndoIcon" alt="Tilbakestill dato" />
                    </ResetButton>
                )}
            </StyledDatePickerWrapper>
            {helpText && <HelpText>{helpText}</HelpText>}
        </>
    );
};

export default DatePicker;
