import React, { FC, SVGProps } from 'react';
const DownArrowIconSvg: FC<{ fill?: string }> = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={33} height={33} fill="none" {...props}>
        <path
            fill={props.fill || '#475156'}
            d="m11.781 15.786 3.453 3.453c.52.52 1.36.52 1.88 0l3.454-3.453c.84-.84.24-2.28-.947-2.28h-6.907c-1.186 0-1.773 1.44-.933 2.28Z"
        />
    </svg>
);
export default DownArrowIconSvg;
