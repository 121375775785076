import { AxiosError, AxiosResponse } from 'axios';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import {
    CreateTicketTypeDto,
    DeleteTypeCollectionItemDto,
    TicketType,
    TypeIntegrationDto,
    UpdateTicketTypeCaseworkerDto,
    UpdateTicketTypeDto,
    UserTicketType,
} from '../../../api/apiTypes/ticketingApiTypes';
import {
    createTicketType,
    deleteTicketType,
    deleteTicketTypeCaseworker,
    getTicketTypes,
    getTypeInegrations,
    getUserTicketTypes,
    updateTicketType,
    updateTicketTypeCaseworker,
} from '../../../api/ticketingApi';
import { UserIsCaseWorkerCacheKey } from './useTicketingUser';

export const TicketTypeQueryCacheKey = 'TicketType';
export const UserTicketTypeQueryCacheKey = 'UserTicketType';
export const TypeIntegrationsQueryCacheKey = 'TypeIntegrationsQueryCacheKey';

export const useTicketingType = (): QueryResult<TicketType[], string | AxiosError<string> | Error> => {
    return useQuery(
        [TicketTypeQueryCacheKey],
        async () => {
            const result = await getTicketTypes();
            return result.data;
        },
        {
            staleTime: 1000 * 20,
        },
    );
};

export const useUserTicketingType = (): QueryResult<UserTicketType[], string | AxiosError<string> | Error> => {
    return useQuery(
        [UserTicketTypeQueryCacheKey],
        async () => {
            const result = await getUserTicketTypes();
            return result.data;
        },
        {
            staleTime: 1000 * 20,
        },
    );
};

export const useCreateTicketType = (): MutationResultPair<
    AxiosResponse<TicketType>,
    string | AxiosError<string> | Error,
    CreateTicketTypeDto,
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        (data) => {
            return createTicketType(data);
        },
        {
            onSuccess: (response) => {
                cache.setQueryData<TicketType[] | undefined>([TicketTypeQueryCacheKey], (curr) => {
                    if (!curr) return curr;
                    return curr.map((type) => {
                        if (type.id === response.data.id) {
                            return response.data;
                        } else {
                            return type;
                        }
                    });
                });
                cache.invalidateQueries(TicketTypeQueryCacheKey);
            },
        },
    );
};

export const useDeleteTicketType = (): MutationResultPair<
    AxiosResponse<TicketType>,
    string | AxiosError | Error,
    [string, DeleteTypeCollectionItemDto[]],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([id, reassignValues]) => {
            return deleteTicketType(id, reassignValues);
        },
        { onSuccess: () => cache.invalidateQueries(UserIsCaseWorkerCacheKey) },
    );
};

export const useUpdateTicketType = (): MutationResultPair<
    AxiosResponse<TicketType>,
    string | AxiosError<string> | Error,
    [string, UpdateTicketTypeDto],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([id, type]) => {
            return updateTicketType(id, type);
        },
        {
            onSuccess: ({ data }) => {
                cache.setQueryData<TicketType[] | undefined>([TicketTypeQueryCacheKey], (curr) => {
                    if (!curr) return curr;
                    return curr.map((type) => {
                        if (type.id === data.id) {
                            return data;
                        } else {
                            return type;
                        }
                    });
                });
                cache.invalidateQueries(TicketTypeQueryCacheKey);
                cache.invalidateQueries(UserIsCaseWorkerCacheKey);
            },
        },
    );
};

export const useUpdateTicketTypeCaseworker = (): MutationResultPair<
    AxiosResponse<TicketType>,
    string | AxiosError<string> | Error,
    [string, UpdateTicketTypeCaseworkerDto],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([id, caseworker]) => {
            return updateTicketTypeCaseworker(id, caseworker);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(TicketTypeQueryCacheKey);
                cache.invalidateQueries(UserIsCaseWorkerCacheKey);
            },
        },
    );
};

export const useDeleteTicketTypeCaseworker = (): MutationResultPair<
    AxiosResponse<TicketType>,
    string | AxiosError<string> | Error,
    [string, string, string | null],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([id, caseworkerId, newAssigneeGuid]) => {
            return deleteTicketTypeCaseworker(id, caseworkerId, newAssigneeGuid);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(TicketTypeQueryCacheKey);
                cache.invalidateQueries(UserIsCaseWorkerCacheKey);
            },
        },
    );
};

export const useTypeIntegrations = (): QueryResult<TypeIntegrationDto[], string | AxiosError<string> | Error> => {
    return useQuery(
        [TypeIntegrationsQueryCacheKey],
        async () => {
            const result = await getTypeInegrations();
            return result.data;
        },
        {
            staleTime: 1000 * 20,
        },
    );
};
