import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GhostPrimaryButton, InformationMessage, PrimaryButton } from '../../../../../../../components';
import { useIsMobile } from '../../../../../../../utility/hooks/useIsMobile';
import { useUpdateTicketType } from '../../../../../apiQueries/useTicketingType';
import { Header, Paragraph } from '../../../../../common/styles';

const Container = styled.div`
    width: 100%;
    margin-bottom: 1.714rem;
`;

type ButtonContainerProps = { isMobile: boolean };
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: ${({ isMobile }: ButtonContainerProps) => (isMobile ? 'column-reverse' : 'row')};
    gap: ${({ isMobile }: ButtonContainerProps) => (isMobile ? '1rem' : 0)};
`;

const PublishTypeWarning = styled(InformationMessage)`
    margin-top: 0;
`;

const ButtonWrapper = styled.p`
    margin: 0 0.7rem 0 0;
`;

interface CaseTypePublishProps {
    id: string;
    hasCaseWorker: boolean;
    hasPublishedCategory: boolean;
    isPublished: boolean;
}
const CaseTypePublish = ({
    id,
    isPublished,
    hasPublishedCategory,
    hasCaseWorker,
}: CaseTypePublishProps): ReactElement => {
    const [updateType] = useUpdateTicketType();
    const isMobile = useIsMobile();
    const { t } = useTranslation('Ticketing');
    const { t: tCommon } = useTranslation('common');
    const handleOnClickPublish = () => {
        updateType([id, { isPublished: true }]);
    };

    const handleOnClickUnPublish = () => {
        updateType([id, { isPublished: false }]);
    };

    return (
        <Container>
            <Header>{t('commonTicketing.caseTypePublishHeader')}</Header>
            <Paragraph>{t('commonTicketing.caseTypePublishParagraph')}</Paragraph>
            {!isPublished ? (
                <ButtonContainer isMobile={isMobile}>
                    <ButtonWrapper>
                        <PrimaryButton
                            onClick={handleOnClickPublish}
                            disabled={!(hasCaseWorker && hasPublishedCategory)}
                        >
                            {tCommon('publish')}
                        </PrimaryButton>
                    </ButtonWrapper>
                    {!hasCaseWorker || !hasPublishedCategory ? (
                        <PublishTypeWarning type="warning">
                            {t('commonTicketing.caseTypePublishWarningMessage')}
                        </PublishTypeWarning>
                    ) : null}
                </ButtonContainer>
            ) : (
                <GhostPrimaryButton onClick={handleOnClickUnPublish}>{t('commonTicketing.hide')}</GhostPrimaryButton>
            )}
        </Container>
    );
};

export default CaseTypePublish;
