import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const HeaderMessage = styled.h4`
    text-align: center;
    width: 100%;
    margin-top: 1em;
`;

interface NotificationsFetchStatusProps {
    errorLoadingNotifications: boolean;
    noNotifications: boolean;
}

const NotificationsFetchStatus = ({
    errorLoadingNotifications,
    noNotifications,
}: NotificationsFetchStatusProps): ReactElement | null => {
    const { t } = useTranslation('notification');
    if (errorLoadingNotifications) {
        return <HeaderMessage>{t('errorOccurredLoadingNotifications')}</HeaderMessage>;
    }
    if (noNotifications) {
        return <HeaderMessage>{t('youHaveNoNotifications')}</HeaderMessage>;
    }
    return null;
};

export default NotificationsFetchStatus;
