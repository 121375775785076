import { EditorState } from 'draft-js';

const getSelectedText = (editorState: EditorState): string => {
    const selection = editorState.getSelection();
    const firstKey = selection.getStartKey(),
        firstOffset = selection.getStartOffset(),
        lastKey = selection.getEndKey(),
        lastOffset = selection.getEndOffset();
    const contentState = editorState.getCurrentContent();
    const isSameBlock = firstKey === lastKey;
    const startingBlock = contentState.getBlockForKey(firstKey);
    let selectedText = '';
    if (isSameBlock) {
        selectedText = startingBlock.getText().slice(firstOffset, lastOffset);
    } else {
        selectedText = startingBlock.getText().slice(firstOffset);
        let blockKey = firstKey;
        while (blockKey !== lastKey) {
            const nextBlock = contentState.getBlockAfter(blockKey);

            if (nextBlock?.getKey() !== lastKey) {
                selectedText += nextBlock?.getText();
            } else {
                selectedText += nextBlock.getText().slice(0, lastOffset);
            }
            blockKey = nextBlock?.getKey() ?? '';
        }
    }
    return selectedText;
};

export default getSelectedText;
