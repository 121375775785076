import { ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';

const keyframesShimmer = keyframes`
  0% {
    background-position: 100% 100%;
  }

  75% {
    background-position: -8% 0;
  }

  100% {
    background-position: -8% 0;
  }
`;

const shimmerAnimation = css`
    display: inline-block;
    background: linear-gradient(to right, transparent 35%, rgba(255, 255, 255, 0.7) 45% 15%, transparent 55% 80%);
    background-size: 250% 250%;
    background-repeat: no-repeat;
    animation: ${keyframesShimmer} 2s infinite ease;
`;

const Shimmer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${shimmerAnimation}
`;

const ShimmerAnimation = (): ReactElement => {
    return <Shimmer />;
};

export default ShimmerAnimation;
