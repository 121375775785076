import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useUpdatedTimeDifference } from '../../../../hooks';

const NotificationTimeParagraph = styled.p`
    font-size: 0.8em;
    font-weight: 600;
    color: #53565b;
`;

interface NotificationTimeDisplayProps {
    dateTime: Date;
}

const NotificationTimeDisplay = ({ dateTime = new Date() }: NotificationTimeDisplayProps): ReactElement => {
    const { t } = useTranslation('notification');
    const timeString = useUpdatedTimeDifference(new Date(dateTime));

    return (
        <NotificationTimeParagraph>
            {t('received')} {timeString}
        </NotificationTimeParagraph>
    );
};

export default NotificationTimeDisplay;
