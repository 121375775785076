import { ReactElement, ReactNode, PropsWithChildren } from 'react';
import styled from 'styled-components';

const TabsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const TabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: 0;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    ::-webkit-scrollbar {
        display: none; /* chrome and safari */
    }
`;

interface TabsProps {
    className?: string;
    children: ReactNode;
}

const Tabs = ({ children, className }: PropsWithChildren<TabsProps>): ReactElement => {
    return (
        <TabsWrapper className={className}>
            <TabsContainer>{children}</TabsContainer>
        </TabsWrapper>
    );
};

export default Tabs;
