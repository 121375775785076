export const supportedImageFormats = [
    'image/apng',
    'image/avif',
    'image/gif',
    'image/jpg',
    'image/jpeg',
    'image/jfif',
    'image/pjpeg',
    'image/pjp',
    'image/png',
    'image/svg',
    'image/webp',
];

export const validateImage = (image: File, maxImageSizeMB: number): true | 'WrongFormat' | 'ToBig' => {
    if (!supportedImageFormats.includes(image.type)) return 'WrongFormat';
    if (image.size >= maxImageSizeMB * 1024 * 1024) return 'ToBig';

    return true;
};
