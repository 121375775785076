import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Ticket } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { Modal, ModalType, PrimaryButton, GhostPrimaryButton, InformationMessage } from '../../../../../../components';
import { useUpdateTicket } from '../../../../apiQueries/useTicketingTicket';
import { Paragraph } from '../../../../common/styles';

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-left: 1rem;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    padding: 1.7rem;
`;

const MessageWrapper = styled.div`
    margin-bottom: 1rem;
`;

interface ChangeCaseworkerModalProps {
    newCaseworkerId: string;
    ticket: Ticket;
    onClose(): void;
    open: boolean;
}

const ChangeCaseworkerModal = ({
    newCaseworkerId,
    ticket,
    open,
    onClose,
}: ChangeCaseworkerModalProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    const { t: tCommon } = useTranslation('common');
    const [updateTicket, { isLoading: isUpdatingTicket, isError: isErrorUpdatingTicket }] = useUpdateTicket();

    const updateTicketHandler = () => {
        updateTicket(
            [
                ticket.id,
                {
                    assigneeId: newCaseworkerId,
                },
            ],
            { onSuccess: () => onClose() },
        );
    };

    return (
        <Modal
            title={t('changeCaseManagerTitle')}
            onClose={onClose}
            hideCloseButton={true}
            open={open}
            type={ModalType.Small}
            bottomContent={
                <ButtonContainer>
                    <GhostPrimaryButton onClick={onClose}>{tCommon('cancelButton')}</GhostPrimaryButton>
                    <StyledPrimaryButton onClick={updateTicketHandler} disabled={isUpdatingTicket}>
                        {t('changeCaseManager')}
                    </StyledPrimaryButton>
                </ButtonContainer>
            }
        >
            <ContentContainer>
                {isErrorUpdatingTicket && (
                    <MessageWrapper>
                        <InformationMessage key="resolveTicketModalError" type="error">
                            {t('errorOccurredMessage')}
                        </InformationMessage>
                    </MessageWrapper>
                )}
                <Paragraph>{t('changeCaseManagerParagraph')}</Paragraph>
            </ContentContainer>
        </Modal>
    );
};

export default ChangeCaseworkerModal;
