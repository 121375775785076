import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import './loader.css';

class LoaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
        };
    }

    componentDidMount() {
        const { phrases } = this.props;
        this.setState({
            text: _.get(_.find(phrases, ['guid', '02707d5b-1e37-47f9-8eb0-b8919fcacbfe']), 'value'),
        });
    }

    render() {
        return (
            <Segment className="loader-content-container">
                <Dimmer active inverted>
                    <Loader inverted />
                </Dimmer>
            </Segment>
        );
    }
}

LoaderComponent.propTypes = {
    phrases: PropTypes.array,
};

export default LoaderComponent;
