import { AxiosError, AxiosResponse } from 'axios';
import type { QueryResult, MutationResultPair } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { User } from '../../../api/apiTypes/portalApiTypes';
import { getUserForCompanyInfo, postponeSetWifiAtWorkPassword } from '../../../api/portalApi';
import useCurrentUserContext from '../../Contexts/useCurrentUserContext';

const WifiAtWorkSetPasswordNotificationCacheKey = 'WifiAtWorkSetPasswordNotificationCacheKey';

export const useGetUserWifiAtWorkSetPasswordNotification = (): QueryResult<User, AxiosError<string> | Error> => {
    const { state: userInfo } = useCurrentUserContext();
    return useQuery(
        [WifiAtWorkSetPasswordNotificationCacheKey],
        async () => {
            const { data } = await getUserForCompanyInfo(
                userInfo.currentUser?.companyId ?? '',
                userInfo.currentUser?.id ?? '',
            );
            return data;
        },
        { staleTime: 1000 * 60 * 60 * 24 },
    );
};

export const usePostponeSetWifiAtWorkPassword = (): MutationResultPair<
    AxiosResponse<string>,
    string | AxiosError<string> | Error,
    never,
    never
> => {
    const cache = useQueryCache();
    const { state: userInfo } = useCurrentUserContext();

    return useMutation(() => postponeSetWifiAtWorkPassword(userInfo.currentUser?.companyId ?? ''), {
        onSuccess: () => cache.invalidateQueries(WifiAtWorkSetPasswordNotificationCacheKey),
    });
};
