import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexBox } from '../../../../../components/styleds';
import OrderCard from '../components/OrderCard';
import ProductsInfoCard from './components/ProductsInfoCard';
import { IS_SCROLL_PADDING_AFTER_ITEMS_AMOUNT } from './constants';
import { ProductsInfoProps } from './types';

const ProductsInfoSection: FC<ProductsInfoProps> = ({ isLoadingOrder, orderLines }) => {
    const { t } = useTranslation('Food', { keyPrefix: 'foodForMeeting' });

    const renderProductsCard = useMemo(() => {
        return orderLines?.map((cardItem) => <ProductsInfoCard key={cardItem.id} order={cardItem} />);
    }, [orderLines]);

    return (
        <OrderCard title={t('foodOrderProductsInfoTitle')} isSkeleton={isLoadingOrder}>
            <FlexBox
                dir={'column'}
                gap={'0.429rem'}
                overflow={'auto'}
                maxH={'21rem'}
                pdR={
                    Array.isArray(orderLines) && orderLines.length > IS_SCROLL_PADDING_AFTER_ITEMS_AMOUNT
                        ? '1rem'
                        : '0rem'
                }
            >
                {renderProductsCard}
            </FlexBox>
        </OrderCard>
    );
};

export default ProductsInfoSection;
