import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { ITags, IHelpArticle } from '../../../api/apiTypes/cmsApiTypes';
import SearchInput from '../../../components/search/SearchInput';
import Article from './Article';
import ArticleItem from './ArticleItem';
import StatusFilterComponent from './StatusFilterComponent';

const ArticleListTableContainer = styled.div`
    margin: 1rem 0;
`;

const NewArticleButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: static;
    width: 100%;
    margin: 1rem 0;
`;

const NewArticleButton = styled(Button)`
    &&& {
        background-color: var(--primary-color);
        color: var(--primary-on-color);
        margin: 0;
    }
`;

const ButtonText = styled.span`
    font-size: 1rem;
    margin-left: 1rem;
    color: var(--primary-color);
`;

interface ArticleListProps {
    articles: IHelpArticle[];
    isLoading: boolean;
    onClose(): void;
}

const ArticleList: React.FC<React.PropsWithChildren<ArticleListProps>> = ({
    articles,
    isLoading,
    onClose,
}: ArticleListProps): JSX.Element => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedArticle, setSelectedArticle] = useState<IHelpArticle | null>(null);
    const { t } = useTranslation('Dashboard', { keyPrefix: 'articles' });
    const onArticleCloseHandler = () => {
        onClose();
        setTimeout(() => {
            setSelectedArticle(null);
        }, 400);
    };

    const canWriteHelpArticles = false;
    const handleOnAddNewArticle = () => {
        alert('hi');
    };

    const filteredArticles = useMemo((): IHelpArticle[] => {
        let filteredArticles = articles;
        if (searchTerm) {
            filteredArticles = filteredArticles.filter(
                (m: IHelpArticle) =>
                    m.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    m.tags.some((tags: ITags) => tags.name.toLowerCase().includes(searchTerm.toLowerCase())),
            );
        }
        return filteredArticles;
    }, [articles, searchTerm]);

    return (
        <>
            {canWriteHelpArticles && !selectedArticle ? <StatusFilterComponent /> : null}
            {!selectedArticle && (
                <SearchInput onChange={setSearchTerm} value={searchTerm} placeholder={t('searchForArticles')} />
            )}
            <ArticleListTableContainer>
                <Loader active={isLoading} size="medium">
                    {t('loadingHelpArticles')}
                </Loader>
                {canWriteHelpArticles && !selectedArticle ? (
                    <NewArticleButtonContainer>
                        <NewArticleButton circular icon="plus circle" onClick={handleOnAddNewArticle} size="Large" />
                        <ButtonText>{t('newHelpArticle')}</ButtonText>
                    </NewArticleButtonContainer>
                ) : null}
                {!selectedArticle &&
                    filteredArticles.map((article: IHelpArticle) => (
                        <ArticleItem
                            article={article}
                            key={article.id}
                            onSelectArticle={(article: IHelpArticle) => setSelectedArticle(article)}
                        />
                    ))}
                {selectedArticle && (
                    <Article
                        key={selectedArticle.id}
                        article={selectedArticle}
                        onArticleBack={() => setSelectedArticle(null)}
                        onArticleClose={onArticleCloseHandler}
                    />
                )}
            </ArticleListTableContainer>
        </>
    );
};

export default ArticleList;
