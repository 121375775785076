import type { TFunction } from 'react-i18next';
import { ArticleModel } from '../../../../api/apiTypes/cmsApiTypes';

const getArticleStatusText = (
    status: ArticleModel['status'],
    isEditing: boolean,
    t: TFunction<'Dashboard', 'articles'>,
): string => {
    if (status === 'Published') return isEditing ? t('allChangesHaveBeenPublished') : t('autorArticleInfoPublished');
    if (status === 'PublishedButChanged') return isEditing ? t('unpublishedChanges') : t('autorArticleInfoPublished');
    if (status === 'Draft') return t('draft');
    if (status === 'Archived' || status === 'ArchivedButChanged') return t('archived');
    if (status === 'Planned') return t('planned');
    if (status === 'PlannedButChanged') return t('planned');
    throw Error('Unknown status');
};

export default getArticleStatusText;
