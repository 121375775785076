import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SidebarMenuItem } from '../../components';
import useDashboardNavigation from './useDashboardNavigation';

const DashboardDynamicMenuItem = (): ReactElement => {
    const { t } = useTranslation('common');
    const { pathname } = useLocation();
    const { goToFrontPage, getFrontPageLink } = useDashboardNavigation();
    return (
        <SidebarMenuItem
            icon="HomeIcon"
            isActive={pathname?.toLowerCase().endsWith(getFrontPageLink.toLowerCase())}
            onClick={goToFrontPage}
            text={t('dashboardMenuLabel')}
        ></SidebarMenuItem>
    );
};

export default DashboardDynamicMenuItem;
