import { ReactElement } from 'react';
import randomColor from 'randomcolor';
import styled from 'styled-components';

const Container = styled.div`
    overflow: hidden;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
`;

const AvatarImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
`;

interface AvatarLabelProps {
    name: string;
}
const AvatarLabel = styled.div`
    color: #ffff;
    background-color: ${({ name }: AvatarLabelProps) =>
        randomColor({
            luminosity: 'dark',
            seed: name,
        })};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    height: 100%;
`;

export interface AvatarComponentProps {
    firstName: string;
    lastName?: string;
    image?: string | null;
    hideImage?: boolean;
    className?: string;
}
const AvatarComponent = ({ firstName, lastName, image, hideImage, className }: AvatarComponentProps): ReactElement => {
    let content = null;
    if (image) {
        content = <AvatarImage src={image} alt={`${firstName} ${lastName}'s avatar`} />;
    } else {
        const initials = (firstName ? firstName[0] : '') + (lastName ? lastName[0] : '') ?? '?';
        content = !hideImage && <AvatarLabel name={`${firstName} ${lastName}`}>{initials.toUpperCase()}</AvatarLabel>;
    }

    return <Container className={className}>{content}</Container>;
};

export default AvatarComponent;
