import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexBox, StressedText, Typography } from '../../../../../components/styleds';
import OrderCard from '../components/OrderCard';
import { CustomerSectionProps } from './types';

const CustomerSection: FC<CustomerSectionProps> = ({ isLoadingOrder, customer, comment }) => {
    const { t } = useTranslation('Food', { keyPrefix: 'foodForMeeting' });

    return (
        <OrderCard title={t('foodOrderCustomerSectionTitle')} isSkeleton={isLoadingOrder}>
            <FlexBox dir={'column'} gap={'1.143rem'}>
                <FlexBox dir={'column'} gap={'0.571rem'}>
                    <Typography fontSize={'1.286rem'} fontWeight={500} lineHeight={'1.714rem'} letterSpacing={'0.2px'}>
                        {customer?.firstName}&nbsp;
                        {customer?.lastName}&nbsp;
                        <StressedText color={'#475156'} fontWeight={900}>
                            {customer?.companyName}
                        </StressedText>
                    </Typography>
                    <Typography fontSize={'1.286rem'} fontWeight={500} lineHeight={'1.714rem'} letterSpacing={'0.2px'}>
                        <StressedText color={'#475156'} fontWeight={900}>
                            {t('foodOrderCustomerSectionEmail')}
                        </StressedText>
                        {customer?.email}
                    </Typography>
                </FlexBox>

                {comment && (
                    <Typography
                        color={'#475156'}
                        fontWeight={400}
                        fontSize={'1.143rem'}
                        lineHeight={'1.714rem'}
                        textTransform={'uppercase'}
                        letterSpacing={'1px'}
                    >
                        {t('foodOrderCustomerSectionComments')}
                    </Typography>
                )}
                {comment && (
                    <Typography
                        fontStyle={'italic'}
                        color={'#475156'}
                        fontWeight={400}
                        fontSize={'1.143rem'}
                        lineHeight={'1.714rem'}
                    >
                        {comment}
                    </Typography>
                )}
            </FlexBox>
        </OrderCard>
    );
};
export default CustomerSection;
