import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { useGetHelpArticles } from './apiQueries/useHelpArticles';
import { ArticleList } from './Components';

const ContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    height: 100%;
`;
const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 0.06rem solid rgba(38, 50, 56, 0.12);
    padding: 1rem;
`;

const CloseButton = styled(Button)`
    &&& {
        background-color: transparent;
        margin: 0;

        &:hover {
            background-color: transparent;
        }
    }
`;

const HeaderLabel = styled.h3`
    margin: 0;
    padding-left: 0.2rem;
    align-self: center;
`;
const ContentDiv = styled.div`
    padding: 1rem;
    overflow-y: auto;
`;

interface HelpSidesheetContentProps {
    onClose(): void;
}

const HelpSidesheetContent: React.FC<React.PropsWithChildren<HelpSidesheetContentProps>> = ({ onClose }) => {
    const { data: articles, isLoading: isLoadingArticles } = useGetHelpArticles();
    const { t } = useTranslation('common');
    return (
        <ContainerDiv>
            <HeaderContainer>
                <HeaderLabel>{t('help')}</HeaderLabel>
                <CloseButton icon="close" onClick={onClose} />
            </HeaderContainer>
            <ContentDiv>
                <ArticleList articles={articles ? articles : []} isLoading={isLoadingArticles} onClose={onClose} />
            </ContentDiv>
        </ContainerDiv>
    );
};

export default HelpSidesheetContent;
