import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { IHelpArticle } from '../../../api/apiTypes/cmsApiTypes';

const ArticleItemContainer = styled.div``;

const ArticleItemWrapper = styled.div`
    padding: 1rem 0;
    width: 100%;
    display: grid;
    grid-template-areas:
        'icon title title title'
        'icon ingress ingress ingress';
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 6fr;
    gap: 0.5rem 0;
`;

const StyledIcon = styled(Icon)`
    grid-area: icon;
    justify-self: center;
    align-self: center;
    &&& {
        color: var(--primary-color);
        font-size: 1.5rem;
    }
`;

const HorizontalRule = styled.hr`
    border: 0;
    height: 1px;
    background: #d4d6d7;
`;

const Title = styled.div`
    grid-area: title;
    color: var(--primary-color);
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Ingress = styled.div`
    grid-area: ingress;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IArticleItem {
    article: IHelpArticle;
    onSelectArticle(article: IHelpArticle): void;
}

const ArticleItem: React.FC<React.PropsWithChildren<IArticleItem>> = ({
    article,
    onSelectArticle,
}: IArticleItem): JSX.Element => {
    return (
        <ArticleItemContainer key={article.id}>
            <ArticleItemWrapper>
                <StyledIcon name={article.icon} />
                <Title onClick={() => onSelectArticle(article)}>{article.title}</Title>
                <Ingress>{article.ingress}</Ingress>
            </ArticleItemWrapper>
            <HorizontalRule />
        </ArticleItemContainer>
    );
};

export default ArticleItem;
