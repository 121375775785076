import { ImageModel } from '../../../../api/apiTypes/cmsApiTypes';
import { imageSource } from '../../../../components/SmartImage';

const mapFromImageModelToImageSource = (model: ImageModel): imageSource[] => {
    const isFormatsExists = !!model.formats;
    if (!isFormatsExists) return [model];
    return [model, ...Object.values(model.formats)];
};

export default mapFromImageModelToImageSource;
