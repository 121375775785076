import { RefObject, useEffect, useState } from 'react';

export enum Position {
    Left,
    Right,
    Top,
    Bottom,
}

export const useFitPosition = (element: RefObject<HTMLElement>, isVisible?: boolean): Position => {
    const [position, setPosition] = useState<Position>(Position.Left);

    useEffect(() => {
        if (!element.current) {
            return;
        }
        const el = element?.current?.getBoundingClientRect();
        const right = el?.right || 0;
        const left = el?.left || 0;
        const top = el?.top || 0;
        const bottom = el?.bottom || 0;
        const innerWidth = window.innerWidth;
        const innerHeight = window.innerHeight;
        if (right > innerWidth) {
            setPosition(Position.Right);
        } else if (left < 0) {
            setPosition(Position.Left);
        } else if (bottom > innerHeight) {
            setPosition(Position.Top);
        } else if (top < 0) {
            setPosition(Position.Bottom);
        }
    }, [element, isVisible]);

    return position;
};
