import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const InfoCardDiv = styled.div`
    background-color: var(--surface-color-light);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.11);
    padding: 1em;
`;

const InfoHeader = styled.h2`
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-weight: 900;
    font-size: 1.9em;
    color: var(--text-high-emphasis-color);
    margin: 0;
`;

const InfoSubHeader = styled.p`
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    color: var(--text-medium-emphasis-color);
`;

const InfoDescriptionList = styled.ul`
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 1em;
    color: var(--text-hight-emphasis-color);
    list-style: none;
    padding: 0;
`;

const InfoDescriptionItem = styled.li`
    margin: 1em 0em;
`;

const PrivacyInfoComponent = (): ReactElement => {
    const { t } = useTranslation('myProfile');
    return (
        <InfoCardDiv>
            <InfoHeader>{t('privacyInfoHeader')}</InfoHeader>
            <InfoSubHeader>{t('privacyInfoSubHeader')}</InfoSubHeader>
            <InfoDescriptionList>
                <InfoDescriptionItem>{t('privacyInfoDescription1')}</InfoDescriptionItem>
                <InfoDescriptionItem>{t('privacyInfoDescription2')}</InfoDescriptionItem>
                <InfoDescriptionItem>{t('privacyInfoDescription3')}</InfoDescriptionItem>
            </InfoDescriptionList>
        </InfoCardDiv>
    );
};

export default PrivacyInfoComponent;
