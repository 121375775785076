import React, { FC } from 'react';
import ImageStyled from './image.styled';
import { IImageProps } from './types';

const Image: FC<IImageProps> = (props) => {
    //Ts error with passing array value
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <ImageStyled {...props} />;
};

export default Image;
