import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ToggleCard from '../../../../../components/toggle/ToggleCard';
import { useUpdateUserThemselvesVisitationStatus } from '../../../../../modules/Admin/UsersAdmin/apiQueries/useVisitorRegistration';

const Container = styled.div`
    display: flex;
`;

const StyledTitle = styled.p`
    font-size: 1.142rem;
    font-weight: 900;
`;
interface VisitorRegistrationComponentProps {
    isEnabled: boolean;
}
const VisitorRegistrationToggle = ({ isEnabled }: VisitorRegistrationComponentProps): ReactElement => {
    const [updateUserVisitationStatus, { isLoading }] = useUpdateUserThemselvesVisitationStatus();
    const { t } = useTranslation('myProfile');
    const handleToggle = (): void => {
        updateUserVisitationStatus({ isEnabled: !isEnabled });
    };

    return (
        <Container>
            <ToggleCard
                topContent={<StyledTitle>{t('visitorRegistration')}</StyledTitle>}
                toggleConfig={{
                    checked: isEnabled,
                    name: 'visitorRegistration',
                    onChange: handleToggle,
                    disable: isLoading,
                    toggleText: t('profileVisitorRegistrationSubtitle'),
                    description: t('profileVisitorRegistrationDesc'),
                }}
            />
        </Container>
    );
};

export default VisitorRegistrationToggle;
