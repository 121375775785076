import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import type { QueryResult } from 'react-query';
import { Company } from '../../../../api/apiTypes/portalApiTypes';
import { getCompanies } from '../../../../api/portalApi';
import { Perm } from '../../../../common';
import { useUserHasPerm } from '../../../../utility';

export const UsersAdminCompaniesCacheKey = 'UsersAdminCompaniesCacheKey';

export const useGetUsersAdminCompaniesList = (): QueryResult<Company[], AxiosError<string> | Error> => {
    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    return useQuery(
        [UsersAdminCompaniesCacheKey],
        async () => {
            const { data } = await getCompanies();
            return data.items;
        },
        {
            staleTime: Infinity,
            enabled: userIsPortalAdmin,
        },
    );
};
