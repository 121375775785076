import React from 'react';
import type { SemanticShorthandItem, IconProps } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';

interface ChangeAmountButtonProps {
    disabled?: boolean;
    icon: SemanticShorthandItem<IconProps>;
    onClick: () => void;
    ariaLabel?: string;
}

const ChangeAmountButton: React.FC<React.PropsWithChildren<ChangeAmountButtonProps>> = ({
    icon,
    onClick,
    ariaLabel,
    disabled = false,
}: ChangeAmountButtonProps) => {
    return (
        <Button
            circular
            icon={icon}
            disabled={disabled}
            onClick={onClick}
            size="mini"
            style={{
                backgroundColor: 'rgba(41, 98, 255, 0.1',
                color: 'var(--primary-color)',
                margin: 0,
            }}
            aria-label={ariaLabel}
            type="button"
        />
    );
};

export default ChangeAmountButton;
