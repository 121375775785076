import { SidesheetState, SidesheetContextAction } from './types';

export const initialState = {
    componentState: null,
    isOpen: false,
    promptOnClose: false,
};

export const reducer = (state: SidesheetState, action: SidesheetContextAction): SidesheetState => {
    const { type } = action;
    switch (type) {
        case 'OPEN_SIDESHEET':
            if (action.payload.component) {
                return {
                    ...state,
                    isOpen: true,
                    componentState: {
                        component: action.payload.component,
                        hideCloseIcon: !!action.payload.hideCloseIcon,
                    },
                };
            } else {
                return {
                    ...state,
                    componentState: null,
                };
            }
        case 'CLOSE_SIDESHEET':
            return {
                ...state,
                isOpen: false,
                componentState: null,
            };
        case 'SET_PROMPT_ON_CLOSE':
            return {
                ...state,
                promptOnClose: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};
