import * as yup from 'yup';

const schema = yup.string().email();

const isValidEmail = async (value: string, allowEmpty = false): Promise<boolean> => {
    if (value == '') return allowEmpty;
    return await schema.isValid(value);
};

export default isValidEmail;
