import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { SvgIcon } from '../../../components';
import { SvgIconName } from '../../../components/icons/SvgIcon';

const MenuItem = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    color: var(--placeholder-color);
`;

type MenuItemIconProps = { isActive: boolean };
const MenuItemIcon = styled.div`
    display: flex;
    width: 3.5rem;

    svg {
        height: 1.857rem;
        width: 1.857rem;

        path {
            fill: var(--primary-color);
            fill-opacity: ${({ isActive }: MenuItemIconProps) => (isActive ? 1 : 0.8)};
        }

        g {
            rect {
                fill: var(--primary-color);
                fill-opacity: ${({ isActive }: MenuItemIconProps) => (isActive ? 1 : 0.8)};
            }
        }
    }
`;

type MenuItemTextProps = { isActive: boolean };
const MenuItemText = styled.div`
    flex: 1;
    font-size: 1rem;
    line-height: 1.143rem;
    letter-spacing: 0.014rem;

    ${({ isActive }: MenuItemTextProps) =>
        isActive
            ? css`
                  font-weight: bold;
                  color: var(--primary-color-dark) !important;
              `
            : css`
                  color: var(--text-placeholder-color);
              `}
`;

type MenuItemNavProps = { isActive: boolean };
const MenuItemNavIcon = styled.div`
    svg {
        transform: rotate(-90deg);
        path {
            fill: var(--primary-color);
            fill-opacity: ${({ isActive }: MenuItemNavProps) => (isActive ? 1 : 0.8)};
        }
    }
`;

interface BottomMenuItemProps {
    icon: SvgIconName;
    text: string;
    module: string;
    subModule: string;
    onClick(module: string, subModule: string): void;
}

const BottomMenuItem = ({ module, subModule, text, onClick, icon }: BottomMenuItemProps): ReactElement => {
    const { pathname } = useLocation();
    const isActive = pathname.includes(`/${module}/${subModule}`);

    const handleOnClick = () => {
        onClick(module, subModule);
    };

    return (
        <MenuItem onClick={handleOnClick}>
            <MenuItemIcon isActive={isActive}>
                <SvgIcon name={icon} />
            </MenuItemIcon>
            <MenuItemText isActive={isActive}>{text}</MenuItemText>
            <MenuItemNavIcon isActive={isActive}>
                <SvgIcon name="FilledCaretDownIcon" />
            </MenuItemNavIcon>
        </MenuItem>
    );
};

export default BottomMenuItem;
