import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GhostPrimaryButton, PrimaryButton } from '../../../../../../../components';
import { useUpdateTicketingTypeCategory } from '../../../../../apiQueries/useTicketingTypeCategory';
import { Paragraph } from '../../../../../common/styles';

const Container = styled.div`
    width: 100%;
    margin-bottom: 1.714rem;
`;

const HeaderParagraph = styled.p`
    font-size: 1.286rem;
    line-height: 1.714rem;
`;

const ButtonWrapper = styled.p`
    margin: 0 0.7rem 0 0;
`;

interface CategoryPublishProps {
    typeId: string;
    categoryId: string;
    isPublished: boolean;
}
const CategoryPublish = ({ typeId, categoryId, isPublished }: CategoryPublishProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const [updateCategory] = useUpdateTicketingTypeCategory();

    const handleOnClickPublish = (isPublished: boolean) => {
        updateCategory([
            typeId,
            categoryId,
            {
                isPublished,
            },
        ]);
    };

    return (
        <Container>
            <HeaderParagraph>{t('categoryPublishHeader')}</HeaderParagraph>
            <Paragraph>{t('categoryPublishParagraph')}</Paragraph>
            <Paragraph>
                {!isPublished ? (
                    <ButtonWrapper>
                        <PrimaryButton onClick={() => handleOnClickPublish(true)} disabled={isPublished}>
                            {t('categoryPublishPublish')}
                        </PrimaryButton>
                    </ButtonWrapper>
                ) : (
                    <GhostPrimaryButton onClick={() => handleOnClickPublish(false)} disabled={!isPublished}>
                        {t('categoryPublishHide')}
                    </GhostPrimaryButton>
                )}
            </Paragraph>
        </Container>
    );
};

export default CategoryPublish;
