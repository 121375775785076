import { AxiosError } from 'axios';
import type { QueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { DropdownTicketAsset } from '../../../api/apiTypes/ticketingApiTypes';
import { getTicketAssets } from '../../../api/ticketingApi';

const TicketAssetsCacheKey = 'TicketAssetsCacheKey';

export const useGetTicketAssets = (): QueryResult<DropdownTicketAsset[], AxiosError<string> | Error> => {
    return useQuery([TicketAssetsCacheKey], async () => {
        const result = await getTicketAssets();

        return result.data.map((building) => ({
            id: building.id,
            label: building.name,
            floors: building.floors.map((floor) => ({
                id: floor.id,
                label: floor.name,
            })),
        }));
    });
};
