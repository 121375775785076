import { AxiosError, AxiosResponse } from 'axios';
import type { MutationResultPair } from 'react-query';
import { useMutation, useQueryCache } from 'react-query';
import { changeWifiAtWorkPassword } from '../../../api/portalApi';

const UpdateWifiPasswordCacheKey = 'UpdateWifiPasswordCacheKey';
export const useUpdateWifiPassword = (): MutationResultPair<
    AxiosResponse<string>,
    string | AxiosError<string> | Error,
    string,
    never
> => {
    const cache = useQueryCache();

    return useMutation(
        (newPassword) => {
            return changeWifiAtWorkPassword({ password: newPassword });
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(UpdateWifiPasswordCacheKey);
            },
        },
    );
};
