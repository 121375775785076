import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import useGetNavigationUrl from '../../utility/hooks/useGetNavigationUrl';

interface ToProp<State> {
    pathname: string;
    state: State;
}

interface AppRedirectProps<State> {
    to: string | ToProp<State>;
    push?: boolean;
}

/* eslint-disable */
const AppRedirect = <State extends unknown>({ to, push = false }: AppRedirectProps<State>): ReactElement => {
    const getUrl = useGetNavigationUrl();
    const actualTo = getUrl((to as ToProp<State>).pathname ? (to as ToProp<State>).pathname : (to as string));
    return (
        <Redirect to={to instanceof String ? actualTo : { ...(to as ToProp<State>), pathname: actualTo }} push={push} />
    );
};

export default AppRedirect;
