import React, { SyntheticEvent } from 'react';
import type { InputProps } from 'semantic-ui-react';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';
import { ISizeStyles } from '../styleds/types';

const StyledInput = styled(Input)<ISizeStyles>`
    margin: 1rem 0;
`;
interface ArticleSearchComponentProps {
    onChange: (newValue: string) => void;
    onFocus?: (e: SyntheticEvent) => void;
    placeholder?: string;
    value: string;
    className?: string;
}

const SearchInput: React.FC<React.PropsWithChildren<ArticleSearchComponentProps>> = (
    { onChange, placeholder, value: stateValue, className, onFocus }: ArticleSearchComponentProps,
    ...props
): JSX.Element => {
    const handleChange = (e: React.SyntheticEvent, { value }: InputProps) => {
        onChange(value);
    };

    return (
        <StyledInput
            icon="search"
            iconPosition="left"
            placeholder={placeholder}
            value={stateValue}
            onChange={handleChange}
            fluid
            className={className}
            onFocus={onFocus}
            {...props}
        />
    );
};

export default SearchInput;
