import { useEffect } from 'react';

type EventType = 'click' | 'keydown';

const useDocumentEventListener = <Event extends keyof DocumentEventMap>(
    type: EventType,
    handler: (event: DocumentEventMap[Event]) => void,
): void => {
    useEffect(() => {
        document.addEventListener(type, handler as EventListener);
        return () => {
            document.removeEventListener(type, handler as EventListener);
        };
    }, [handler, type]);
};

export default useDocumentEventListener;
