import * as React from 'react';
import { FC } from 'react';
const InfoCircleIconSvg: FC<{ fill?: string }> = ({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none">
        <path
            fill={fill ?? '#475156'}
            fillRule="evenodd"
            d="M10 1.854a8.336 8.336 0 0 0-8.333 8.333c0 4.6 3.733 8.333 8.333 8.333s8.334-3.733 8.334-8.333S14.6 1.854 10 1.854ZM9.167 6.02v1.667h1.667V6.02H9.167Zm1.667 7.5a.836.836 0 0 1-.834.834.836.836 0 0 1-.833-.833v-3.334c0-.458.375-.833.833-.833.459 0 .834.375.834.833v3.334Zm-7.5-3.333A6.676 6.676 0 0 0 10 16.854a6.676 6.676 0 0 0 6.667-6.667A6.676 6.676 0 0 0 10 3.52a6.676 6.676 0 0 0-6.666 6.667Z"
            clipRule="evenodd"
        />
    </svg>
);
export default InfoCircleIconSvg;
