import styled, { css } from 'styled-components';
import { MEDIA_BREAKPOINTS, MEDIA_INDEX } from '../constants';
import { INCLUDE_ARRAY_VALUE, SIZE_STYLES } from '../stylesHelpers';
import { IImageProps } from './types';

const ImageStyled = styled.img.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})<IImageProps>`
    object-fit: ${({ objectFit }) => objectFit ?? 'contain'};
    ${(props) => SIZE_STYLES(props)};
    ${(props) =>
        INCLUDE_ARRAY_VALUE(props) &&
        css`
            @media (max-width: ${MEDIA_BREAKPOINTS.TABLET}px) {
                ${SIZE_STYLES(props, MEDIA_INDEX.TABLET)}
            }
            @media (max-width: ${MEDIA_BREAKPOINTS.MOBILE}px) {
                ${SIZE_STYLES(props, MEDIA_INDEX.MOBILE)}
            }
        `};
`;

export default ImageStyled;
