import { ReactElement } from 'react';
import type { ButtonProps } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledButton = styled.button`
    background: none;
    color: var(--primary-color);
    padding: 0.57rem 1.14rem;
    font-size: 1.14rem;
    min-height: min-content;
    margin: 0;
    border: none;
    display: flex;
    align-items: center;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-weight: 700;
    border-radius: 0.5rem;
    cursor: pointer;

    .path {
        fill: var(--primary-color);
    }

    :hover {
        color: var(--primary-on-color-light);
        background: var(--primary-color-light);
    }

    :active {
        background: var(--primary-color-dark);
        color: var(--primary-on-color-dark);
        outline: none;
    }

    :focus {
        background: var(--primary-color);
        color: var(--primary-on-color);
        outline: var(--primary-color);
        outline-offset: 2px;
        outline-style: auto;
        background-clip: padding-box;
    }

    :disabled {
        background: #d4d6d7;
        color: #a8adaf;
        opacity: 1 !important;
        cursor: default;
    }

    :disabled path {
        fill: #a8adaf;
        cursor: auto;
    }

    :is(:hover, :active, :focus)not:(disabled) path {
        fill: var(--primary-on-color);
    }
`;

const TertiaryButton = ({ ...props }: ButtonProps): ReactElement => {
    return <StyledButton {...props} />;
};

export default TertiaryButton;
