import { useCallback, useContext, useMemo } from 'react';
import { NewTicketContext } from './NewTicketContext';

export enum NewTicketFormStep {
    SelectCategory = 1,
    SelectSubcategory = 2,
    TicketForm = 3,
    Result = 4,
}

interface NewTicketStepsState {
    goNext: () => void;
    goBack: () => void;
    goToStep: (step: NewTicketFormStep) => void;
}

const useNewTicketSteps = (): NewTicketStepsState => {
    const { dispatch } = useContext(NewTicketContext);

    const goNext = useCallback((): void => {
        dispatch({ type: 'NEXT_STEP' });
    }, [dispatch]);

    const goBack = useCallback((): void => {
        dispatch({ type: 'PREVIOUS_STEP' });
    }, [dispatch]);

    const goToStep = useCallback(
        (step: NewTicketFormStep): void => {
            dispatch({ type: 'SET_STEP', payload: step });
        },
        [dispatch],
    );

    return useMemo(() => ({ goNext, goBack, goToStep }), [goNext, goBack, goToStep]);
};

export default useNewTicketSteps;
