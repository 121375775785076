import { GboMenuType } from '../../../../../api/apiTypes/shopApiTypes';
import { getDeviceLanguageDateFormat } from '../../../../../utility/dateUtilities/getDeviceLanguageDateFormat';

export const getFormattedDate = (date: string, language: string): string => {
    return `${getDeviceLanguageDateFormat(new Date(date), 'PP')} ${
        language === 'no' ? 'kl.' : ''
    } ${getDeviceLanguageDateFormat(new Date(date), 'HH:mm')}`;
};

export const getMenuType = (menuType: GboMenuType): string | null => {
    switch (menuType) {
        case GboMenuType.FoodForMeeting:
            return 'Food for meeting';
        case GboMenuType.Kiosk:
            return 'Kiosk';
        case GboMenuType.Lunch:
            return 'Lunch';
        default:
            return null;
    }
};
