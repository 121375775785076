import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SvgIcon } from '../../../../../../components';

const Container = styled.div`
    display: flex;
    gap: 0.5rem;
`;

const Text = styled.p`
    font-size: 0.857rem;
    line-height: 1.429rem;
    text-transform: uppercase;
    letter-spacing: 0.071rem;
    margin: 0;
`;

const Icon = styled.div`
    line-height: 0;
`;

const SeenStatus = (): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    return (
        <Container>
            <Text>{t('seenStatus')}</Text>
            <Icon>
                <SvgIcon name="VisibilityOn" />
            </Icon>
        </Container>
    );
};

export default SeenStatus;
