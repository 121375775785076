import { ReactElement, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray } from 'react-hook-form';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { CreateTicketTypeDto } from '../../../../../../api/apiTypes/ticketingApiTypes';
import RoutePromptModal from '../../../../../../common/RoutePromptModal';
import { MobileViewBreakpoint } from '../../../../../../common/ScreenSizeBreakPoints';
import {
    PrimaryButton,
    GhostPrimaryButton,
    SvgIcon,
    InputField,
    InformationMessage,
} from '../../../../../../components';
import { InputFieldPropType } from '../../../../../../components/input/TextInput/inputField';
import { useNavigation } from '../../../../../../utility';
import { useIsMobile } from '../../../../../../utility/hooks/useIsMobile';
import { useCreateTicketType } from '../../../../apiQueries/useTicketingType';
import { Paragraph, HorizontalRule } from '../../../../common/styles';
import { AbortModal, SuccessModal } from './components';

const ScrollContainer = styled.div`
    overflow-y: auto;
    @media (max-width: ${MobileViewBreakpoint}px) {
        padding-bottom: 6rem;
    }
`;

type SettingsCardProps = { isMobile: boolean };
const SettingsCard = styled.div`
    border-radius: 0.4rem;
    max-width: 85.714rem;
    margin: ${({ isMobile }: SettingsCardProps) => (isMobile ? '0' : '2rem')};
    padding: 1.714rem;
    background: var(--surface-color-light);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    color: var(--text-high-emphasis-color);
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const MainHeader = styled.h2`
    margin-bottom: 2.29rem;
    font-size: 1.43rem;
`;

const TypeDescriptor = styled(Paragraph)`
    margin-bottom: 1.71rem;
`;

const SectionHeader = styled.h3`
    font-size: 1.143rem;
    font-weight: 700;
    margin-bottom: 0.643rem;
`;

const Footer = styled.div`
    margin: 1.57rem 0;
    display: flex;
    grid-gap: 0.57rem;
    justify-content: flex-end;
    @media (max-width: ${MobileViewBreakpoint}px) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        background-color: var(--surface-color-light);
        margin: 0;
        padding: 1.71rem;
    }
`;

const NewCategoryButton = styled(PrimaryButton)`
    align-self: flex-start;
    padding: 0;

    & svg {
        margin-right: 0.57rem;
    }
`;

const StyledHorizontalRule = styled(HorizontalRule)`
    margin: 2.285rem 0;
`;

const StyledInputField = styled(({ ...props }: InputFieldPropType) => <InputField {...props} />)`
    margin-bottom: 2.285rem;
`;

type RemoveCategoryButton = { inputFieldHasError: boolean };
const RemoveCategoryButton = styled(PrimaryButton)`
    &&& {
        height: 2.685rem;
        margin-top: ${({ inputFieldHasError }: RemoveCategoryButton) => (inputFieldHasError ? '0' : '0.571rem')};
        align-self: ${({ inputFieldHasError }: RemoveCategoryButton) => inputFieldHasError && 'center'};
        margin-left: 1.17rem;
        border: none;
        border-radius: 50%;
        background: #e9ebeb;
        padding: 0;
        text-align: center;
        width: 2.686rem;

        & path {
            fill: #475156;
        }

        :hover,
        :focus-visible {
            background: var(--primary-color);
            outline: none;
            border: none;
        }
    }
`;
const CategoryInputWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    margin-top: 0.57rem;

    & > *:first-child {
        flex: 1;
    }
`;
let existingTypesNames = [''];

const schema = (t: TFunction<'Ticketing', 'settings'>) =>
    yup.object({
        name: yup
            .string()
            .required(t('errorMessageGiveTypeName'))
            .min(2, t('errorMessageValueMustHaveMinLetters'))
            .trim()
            .test('isUnique', t('errorMessageNameMustBeUnique'), function (value) {
                return value !== undefined && Array.isArray(existingTypesNames) && existingTypesNames.length
                    ? !existingTypesNames.includes(value.toString().toLowerCase())
                    : true;
            }),
        categories: yup
            .array()
            .of(
                yup.object().shape({
                    name: yup
                        .string()
                        .required(t('errorMessageCategoryNameRequired'))
                        .min(2, t('errorMessageValueMustHaveMinLetters'))
                        .max(100, t('errorMessageValueMustHaveMaxLetters')),
                }),
            )
            .min(1, t('errorMessageAddCategory')),
        internalDescription: yup.string(),
        externalDescription: yup.string(),
    });

interface NewTicketTypeProps {
    allTypeNames: string[] | [];
}

const NewTicketType = ({ allTypeNames }: NewTicketTypeProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const { t: tCommon } = useTranslation('common');
    const isMobile = useIsMobile();
    existingTypesNames = useMemo(() => allTypeNames, [allTypeNames]);
    const [showAbortModal, setShowAbortModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [createType, { isLoading, isError, data: createdTypeData }] = useCreateTicketType();
    const nav = useNavigation();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isDirty, isValid },
        reset,
    } = useForm<CreateTicketTypeDto>({
        mode: 'onChange',
        resolver: yupResolver(schema(t)),
        defaultValues: { name: '', categories: [], internalDescription: '', externalDescription: '' },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'categories',
    });

    const onSubmit = (data: CreateTicketTypeDto) => {
        createType(data, {
            onSuccess: () => {
                setShowSuccessModal(true);
                reset();
            },
        });
    };

    const handleAddNewCategoryField = () => append({});
    const handleRemoveCategoryOnClick = (index: number) => remove(index);
    const handleAbortCreateNewType = () => setShowAbortModal(true);
    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleCloseAbortModal = () => setShowAbortModal(false);

    return (
        <ScrollContainer>
            <RoutePromptModal
                navigate={nav.push}
                shouldBlockNavigation={() => !showAbortModal && !showSuccessModal && isDirty}
                modalTitle={t('routePromptModalTitle')}
                modalContent={<p>{t('routePromptModalContent')}</p>}
                modalCancelButtonText={tCommon('backToEditing')}
                modalOkButtonText={tCommon('discardChanges')}
            />
            <SettingsCard isMobile={isMobile}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <MainHeader>{t('header')}</MainHeader>
                    <TypeDescriptor>{t('createNewTypeDescription')}</TypeDescriptor>
                    <SectionHeader>{t('nameOfTypeHeader')}</SectionHeader>
                    <InputField
                        type="text"
                        label={t('newTicketLabelInputName')}
                        placeholder={t('newTicketPlaceholderInputName')}
                        error={errors.name?.message}
                        required
                        {...register('name')}
                    />
                    <StyledHorizontalRule />
                    <SectionHeader>{t('categoryHeader')}</SectionHeader>
                    <Paragraph>{t('categoryDescription')}</Paragraph>
                    <NewCategoryButton onClick={handleAddNewCategoryField}>
                        <SvgIcon name="Plus" />
                        {t('newCategoryButton')}
                    </NewCategoryButton>
                    {fields.map((field, index) => (
                        <CategoryInputWrapper key={field.id}>
                            <InputField
                                type="text"
                                label={t('newTicketLabelInputCategoriesIndexName')}
                                placeholder={t('newTicketLabelPlaceholderCategoriesIndexName')}
                                error={errors.categories && errors.categories[index]?.name?.message}
                                required
                                {...register(`categories.${index}.name`)}
                            />
                            <RemoveCategoryButton
                                onClick={() => handleRemoveCategoryOnClick(index)}
                                inputFieldHasError={errors.categories && errors.categories[index]?.name?.message}
                            >
                                <SvgIcon name="SubtractButtonIcon" alt="Fjern kategori" />
                            </RemoveCategoryButton>
                        </CategoryInputWrapper>
                    ))}
                    <StyledHorizontalRule />
                    <SectionHeader>{t('writeInternalDescriptionHeader')}</SectionHeader>
                    <Paragraph>{t('writeInternalDescriptionDescription')}</Paragraph>
                    <StyledInputField
                        type="text"
                        label={t('newTicketUsersDescriptionHeader')}
                        placeholder={t('newTicketPlaceholderExternalDescription')}
                        rows={5}
                        error={errors.externalDescription?.message}
                        {...register('externalDescription')}
                    />
                    <SectionHeader>{t('writeDescriptionForUserHeader')}</SectionHeader>
                    <Paragraph>{t('writeDescriptionForUserDescription')}</Paragraph>
                    <StyledInputField
                        type="textarea"
                        label={t('newTicketManagerDescription')}
                        placeholder={t('newTicketPlaceholderInternalDescription')}
                        rows={5}
                        error={errors.internalDescription?.message}
                        {...register('internalDescription')}
                    />
                    {isError ? (
                        <InformationMessage type="error">{t('newTicketErrorMessage')}</InformationMessage>
                    ) : null}
                    <Footer>
                        <GhostPrimaryButton type="button" onClick={handleAbortCreateNewType}>
                            {tCommon('cancelButton')}
                        </GhostPrimaryButton>
                        <PrimaryButton loading={isLoading} type="submit" disabled={!isValid || isLoading}>
                            {tCommon('saveButton')}
                        </PrimaryButton>
                    </Footer>
                    {showAbortModal ? <AbortModal onRegret={handleCloseAbortModal} /> : null}
                    {showSuccessModal ? (
                        <SuccessModal typeId={createdTypeData?.data?.id || ''} onClose={handleCloseSuccessModal} />
                    ) : null}
                </form>
            </SettingsCard>
        </ScrollContainer>
    );
};
export default NewTicketType;
