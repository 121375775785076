import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Modal, ModalType, PrimaryButton } from '../../../../../../../components';

const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    padding: 1.7rem;
`;

const Paragraph = styled.p`
    font-size: 1.143rem;
    line-height: 1.714rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-left: 1rem;
    }
`;

interface NewAssigneeModalProps {
    open: boolean;
    onClose(): void;
}

const CantRemoveCaseworkerModal = ({ open, onClose }: NewAssigneeModalProps): ReactElement => {
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    return (
        <Modal
            title={t('cannotRemoveCaseManager')}
            onClose={() => null}
            open={open}
            type={ModalType.Small}
            hideCloseButton={true}
            bottomContent={
                <ButtonContainer>
                    <StyledPrimaryButton onClick={onClose}>{tCommon('closeButton')}</StyledPrimaryButton>
                </ButtonContainer>
            }
        >
            <ContentContainer>
                <Paragraph>{t('cannotRemoveCaseManagerDescription')}</Paragraph>
            </ContentContainer>
        </Modal>
    );
};

export default CantRemoveCaseworkerModal;
