import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReloadIconSvg from '../../icons/ReloadIconSvg';
import { Button, FlexBox, Image, Typography } from '../index';
import { MediaFileReupdaterProps } from './types';

const MediaFileReupdater: FC<MediaFileReupdaterProps> = ({ file, showModalHandler, imageUrl }) => {
    const { t } = useTranslation('events');
    const sizeInKb = file ? (file.size / 1024).toFixed(2) : '0';

    if (!file && !imageUrl) return null;
    return (
        <FlexBox
            justify={'space-between'}
            align={'center'}
            pd={'1.143rem 1.429rem'}
            br={'0.357rem'}
            borderWidth={'0.071rem'}
            borderColor={'#D4D6D7'}
        >
            <FlexBox gap={'0.857rem'}>
                <FlexBox width={'3.214rem'} height={'3.214rem'} br={'0.214rem'} overflow={'hidden'}>
                    <Image src={file ? URL.createObjectURL(file) : imageUrl} />
                </FlexBox>
                {file && (
                    <FlexBox dir={'column'}>
                        <Typography color={'#475156'} fontSize={'1rem'} fontWeight={700} lineHeight={'1.714rem'}>
                            {file.name}
                        </Typography>
                        <Typography color={'#677074'} fontWeight={400} lineHeight={'1.714rem'} fontSize={'1rem'}>
                            {`${sizeInKb} KB`}
                        </Typography>
                    </FlexBox>
                )}
            </FlexBox>
            <FlexBox gap={'0.429rem'} width={'auto'} align={'center'}>
                <Typography color={'#475156'} fontSize={'0.857rem'} fontWeight={600} lineHeight={'1.143rem'}>
                    {t('coverImageBlockEditImageBtnLabel')}
                </Typography>
                <Button
                    background={'#E9EBEB'}
                    br={'50%'}
                    pd={'0.286rem'}
                    width={'2.286rem'}
                    height={'2.286rem'}
                    align={'center'}
                    justify={'center'}
                    onClick={showModalHandler}
                >
                    <ReloadIconSvg fill={'var(--primary-color)'} />
                </Button>
            </FlexBox>
        </FlexBox>
    );
};

export default MediaFileReupdater;
