import { ReactElement } from 'react';
import styled from 'styled-components';
import { TicketReportee } from '../../../../api/apiTypes/ticketingApiTypes';
import { Avatar } from '../../../../components';

const UserInfo = styled.div`
    display: flex;
    align-items: center;
`;

type StyledAvatarProps = { size: string };
const StyledAvatar = styled(Avatar)`
    width: ${({ size }: StyledAvatarProps) => (size === 'big' ? '4.571rem' : '2.857rem')};
    height: ${({ size }: StyledAvatarProps) => (size === 'big' ? '4.571rem' : '2.857rem')};
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.6rem;
`;

const UserName = styled.span`
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.857rem;
    line-height: 1.429rem;
    color: #263238;
`;

const Email = styled.span`
    font-size: 0.857rem;
    color: var(--text-medium-emphasis);
`;

const Company = styled(Email)``;
const PhoneNumber = styled(Email)``;

interface UserDetailsProps {
    user: TicketReportee;
    companyName?: string;
    avatarSize?: 'default' | 'big';
    className?: string;
}

const UserDetails = ({ user, companyName, avatarSize = 'default', className }: UserDetailsProps): ReactElement => {
    return (
        <UserInfo className={className}>
            <StyledAvatar firstName={user.firstName} lastName={user.lastName} image={user.avatar} size={avatarSize} />
            <DetailsContainer>
                <UserName>{[user.firstName, user.lastName].join(' ').trim()}</UserName>
                <Email>{user.email}</Email>
                <Company>{user.companyName ?? companyName}</Company>
                <PhoneNumber>{user.phone}</PhoneNumber>
            </DetailsContainer>
        </UserInfo>
    );
};

export default UserDetails;
