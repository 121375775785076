import { Key } from 'react';
import { t } from 'i18next';
import {
    InventoryFilterType,
    PreparedFiltersType,
    InventorySortingType,
    ProductStatusOption,
    ProductStatus,
    ProductTypeOption,
    ProductTypes,
} from '../../../../api/apiTypes/shopApiTypes';
import { OptionType } from '../../../../components/select/searchSelect/types';
import { ShopOverviewView } from '../../ShopAtWork';
import { ColumnType } from './components/InventoryTable';

export enum InventoryColumns {
    Name = 'name',
    Status = 'status',
    Type = 'type',
    Category = 'category',
    Price = 'price',
    Supplier = 'supplier',
}

export const ProductColumns: Key[] = [
    InventoryColumns.Name,
    InventoryColumns.Status,
    InventoryColumns.Type,
    InventoryColumns.Category,
    InventoryColumns.Price,
    InventoryColumns.Supplier,
];

export enum FilterKeys {
    Statuses = 'statuses',
    Types = 'types',
}

interface FiltersType {
    statuses: OptionType[] | [];
    types: OptionType[] | [];
}

export interface ModalInformationInterface {
    title: string;
    body: string;
    submitButtonText: string;
    showAbortButton: boolean;
    name: string;
    submit: () => NewCategoryRemoveModalType;
}

export const prepareInventoryFilters = (filters: FiltersType): PreparedFiltersType[] => {
    return Object.entries(filters).map(([type, options]) => {
        let enumFilterType: InventoryFilterType;
        switch (type) {
            case FilterKeys.Statuses:
                enumFilterType = InventoryFilterType.Status;
                break;
            case FilterKeys.Types:
                enumFilterType = InventoryFilterType.Type;
                break;
            default:
                enumFilterType = InventoryFilterType.Status;
                break;
        }
        return { type: enumFilterType, value: options.map((option: OptionType) => option.value) };
    });
};

interface InventoryFilterOption {
    key: InventoryColumns;
    value: InventorySortingType;
    text: string;
}

export const allFilterOptions = (): InventoryFilterOption[] => {
    return [
        {
            key: InventoryColumns.Name,
            value: InventorySortingType.Name,
            text: t('ShopAtWork:commonShopAtWork.ProductColumnsTranslation.Name'),
        },
        {
            key: InventoryColumns.Status,
            value: InventorySortingType.Status,
            text: t('ShopAtWork:commonShopAtWork.ProductColumnsTranslation.Status'),
        },
        {
            key: InventoryColumns.Type,
            value: InventorySortingType.Type,
            text: t('ShopAtWork:commonShopAtWork.ProductColumnsTranslation.Type'),
        },
        {
            key: InventoryColumns.Category,
            value: InventorySortingType.Category,
            text: t('ShopAtWork:commonShopAtWork.ProductColumnsTranslation.Category'),
        },
        {
            key: InventoryColumns.Price,
            value: InventorySortingType.Price,
            text: t('ShopAtWork:commonShopAtWork.ProductColumnsTranslation.Price'),
        },
        {
            key: InventoryColumns.Supplier,
            value: InventorySortingType.Supplier,
            text: t('ShopAtWork:commonShopAtWork.ProductColumnsTranslation.Supplier'),
        },
    ];
};

export const renderStatusOptions = (options: ProductStatusOption[] | undefined): OptionType[] | [] => {
    return options
        ? options.map((option) => ({ value: option, label: getProductStatusOptionTranslation(option) }))
        : [];
};

export const getProductTypeOptionTranslation = (productTypeOption: ProductTypes): string => {
    const productTypes = {
        Generelt: t('ShopAtWork:commonShopAtWork.ProductTypesTranslation.Generelt'),
        Lokaler: t('ShopAtWork:commonShopAtWork.ProductTypesTranslation.Lokaler'),
        Mat: t('ShopAtWork:commonShopAtWork.ProductTypesTranslation.Mat'),
        Tjenester: t('ShopAtWork:commonShopAtWork.ProductTypesTranslation.Tjenester'),
        Punkt: 'ShopAtWork:commonShopAtWork.ProductTypesTranslation.Punkt',
        Medlemskap: 'ShopAtWork:commonShopAtWork.ProductTypesTranslation.Medlemskap',
        Mellomrom: 'ShopAtWork:commonShopAtWork.ProductTypesTranslation.Mellomrom',
    };

    return productTypes[productTypeOption] || '-';
};

export const renderTypesOptions = (options: ProductTypeOption[] | undefined): OptionType[] => {
    return options
        ? options.map((option) => ({ value: option.id, label: getProductTypeOptionTranslation(option.name) }))
        : [];
};

const filteredColumns = (allColumns: ColumnType, columns: Key[]) =>
    allColumns.filter((option) => columns.includes(option.key));

export const getViewColumns = (view: ShopOverviewView, allColumns: ColumnType): ColumnType => {
    if (view === ShopOverviewView.Inventory) return filteredColumns(allColumns, ProductColumns);
    return filteredColumns(allColumns, ProductColumns);
};

export const getProductStatusTranslation = (productStatus: ProductStatus): string => {
    if (productStatus === ProductStatus.Active) return t('common:active');
    if (productStatus === ProductStatus.Disabled) return t('common:inactive');
    return '-';
};

export const getProductStatusOptionTranslation = (productStatusOption: ProductStatusOption): string => {
    const options = {
        [ProductStatusOption.Active]: t('common:active'),
        [ProductStatusOption.Disabled]: t('common:inactive'),
    };
    return options[productStatusOption] || '-';
};

export enum NewCategoryRemoveModalType {
    Remove,
    Navigate,
    Success,
}

export const getRemovalModalBodyContent = ({
    name,
    productsCount,
    type,
}: {
    name: string;
    productsCount: number;
    type: NewCategoryRemoveModalType;
}): ModalInformationInterface => {
    switch (type) {
        case NewCategoryRemoveModalType.Remove:
            return {
                title: t('ShopAtWork:commonShopAtWork.doYouWantToDeleteThisCategory'),
                body: t('ShopAtWork:commonShopAtWork.confirmDeletionModalMessage'),
                submitButtonText: t('ShopAtWork:commonShopAtWork.deleteCategoryDeleteButton'),
                showAbortButton: true,
                name,
                submit: () => NewCategoryRemoveModalType.Remove,
            };
        case NewCategoryRemoveModalType.Navigate:
            return {
                title: t('ShopAtWork:commonShopAtWork.doYouWantToDeleteThisCategory'),
                body: t('ShopAtWork:commonShopAtWork.confirmDeletionModalMessageInNavigateModal', { productsCount }),
                submitButtonText: t('ShopAtWork:commonShopAtWork.deleteCategoryDeleteButtonNavigateModal'),
                showAbortButton: true,
                name,
                submit: () => NewCategoryRemoveModalType.Navigate,
            };
        case NewCategoryRemoveModalType.Success:
            return {
                title: t('ShopAtWork:commonShopAtWork.categoryDeletedSuccessTitle', { name }),
                body: t('ShopAtWork:commonShopAtWork.categoryDeletedSuccessMessage'),
                submitButtonText: t('ShopAtWork:commonShopAtWork.deleteCategoryCloseButton'),
                showAbortButton: false,
                name,
                submit: () => NewCategoryRemoveModalType.Success,
            };
    }
};
