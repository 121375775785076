import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useNavigation } from '../../utility';
import useCurrentUserContext from '../Contexts/useCurrentUserContext';
import { QueryParameterNames } from './ApiAuthorizationConstants';
import { ErrorAuthorization } from './components';
import parsePortalName from './parsePortalName';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export interface LoginProps {
    action: 'login' | 'login-callback' | 'silent-callback';
}
const Login: React.FC<React.PropsWithChildren<LoginProps>> = ({ action }) => {
    const { userManager } = useCurrentUserContext();
    const [isError, setIsError] = useState(false);
    const { replace } = useNavigation();
    const returnUrl = new URLSearchParams(window.location.search).get(QueryParameterNames.ReturnUrl) ?? '/';
    const portalName = parsePortalName(returnUrl);
    const { t } = useTranslation('authorization');
    useEffect(() => {
        const handleLogin = async () => {
            try {
                if (action === 'login') {
                    await userManager.signinRedirect({
                        state: { returnUrl },
                        useReplaceToNavigate: true,
                        extraQueryParams: { portalName: portalName || 'default' },
                    });
                } else if (action === 'login-callback') {
                    const result = await userManager.signinCallback();
                    replace(result.state.returnUrl);
                } else if (action === 'silent-callback') {
                    await userManager.signinCallback();
                }
            } catch (e) {
                console.error(e);
                setIsError(true);
            }
        };
        handleLogin();
    }, [action, portalName, replace, returnUrl, userManager]);

    if (isError) {
        return <ErrorAuthorization type="Login" />;
    }

    const renderLoader = (message: string): ReactElement => {
        return (
            <Dimmer active inverted>
                <Loader inverted>{message}</Loader>
            </Dimmer>
        );
    };

    switch (action) {
        case 'login':
            return renderLoader(t('preparingLogin'));
        case 'login-callback':
            return renderLoader(t('completingLogin'));
        case 'silent-callback':
            // This only happens in an iframe
            return null;
        default:
            throw new Error('Unknown action');
    }
};

export { Login };
