import { Key, KeyboardEvent, ReactElement, useState } from 'react';
import { motion } from 'framer-motion';
import { get } from 'lodash';
import styled, { CSSProperties } from 'styled-components';
import { ColumnType } from './Table';

const StyledTableBody = styled.tbody`
    td:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    td:last-child {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
    }
`;

type TableRowProps = { collapse?: boolean; highlightSelectedRow?: boolean };
const TableRow = styled.tr`
    cursor: pointer;
    box-shadow: inset 0 0 0 1px #d4d6d7;
    height: 6.071rem;
    min-height: 6.071rem;
    border-radius: 5px;
    overflow: hidden;
    background-color: ${({ highlightSelectedRow }) => (highlightSelectedRow ? '#E1F6FF' : 'transparent')};

    :hover {
        background: var(--background-color);
    }

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
    }

    :focus:not(:focus-visible) {
        outline: none;
    }

    ${({ collapse }: TableRowProps) =>
        collapse && { height: 'auto', display: 'block', marginBottom: '1rem', padding: '0.5rem' }};
`;

type TableCellProps = { collapse?: boolean };
const TableCell = styled.td`
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding: 0.714rem 0.429rem;
    word-break: break-word;
    max-width: 0;

    &:first-child {
        padding-left: 1rem;
        min-width: 3.7rem;
    }
    ${({ collapse }: TableCellProps) =>
        collapse && {
            height: 'auto',
            display: 'block',
            marginBottom: '1rem',
            padding: '0.5rem',
            maxWidth: 'none',

            '&:first-child': {
                paddingLeft: '0.429rem',
                minWidth: 0,
            },

            ':before': {
                content: 'attr(data-label)',
                paddingRight: '0.5rem',
                fontSize: '1rem',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                float: 'left',
            },
        }};
`;

type CellType = { id: string; [key: string]: unknown };
export interface TableBodyProps {
    data: CellType[];
    columns: {
        path?: ColumnType;
        key: Key;
        content?(item: string): string | JSX.Element | null;
        cellColSpan?: number;
        style?: CSSProperties;
    }[];
    onRowClick(rowId: string): void;
    collapse?: boolean;
    showHighlight?: boolean;
    className?: string;
}

const TableBody = ({ data, columns, onRowClick, collapse, className, showHighlight }: TableBodyProps): ReactElement => {
    const [selectedLine, setSelectedLine] = useState('');

    const renderCell = (
        item: unknown,
        column: { content?(item: unknown): string | JSX.Element | null; path?: ColumnType },
    ) => {
        if (column.content) return column.content(item);
        return column.path ? get(item, column.path) : item;
    };

    const createKey = (item: CellType, column: { path?: ColumnType; key: Key }) => {
        return item.id + (column.path ?? column.key);
    };

    const onKeyPressHandler = (e: KeyboardEvent<HTMLTableRowElement>, itemId: string) => {
        if (e.key === 'Enter') onRowClick(itemId);
    };

    const handleRowClick = (itemId: string) => {
        onRowClick(itemId);
        if (showHighlight) {
            setSelectedLine(itemId);
        }
    };

    return (
        <StyledTableBody className={className}>
            {data.map((item) => (
                <TableRow
                    as={motion.tr}
                    animate={{ x: 0, opacity: 1 }}
                    initial={{ x: 20, opacity: 0 }}
                    collapse={collapse}
                    key={item.id}
                    id={item.id}
                    tabIndex={0}
                    transition={{ type: 'tween', duration: 0.2, ease: 'easeOut' }}
                    onKeyPress={(e: KeyboardEvent<HTMLTableRowElement>) => onKeyPressHandler(e, item.id)}
                    onClick={() => handleRowClick(item.id)}
                    highlightSelectedRow={selectedLine === item.id && showHighlight}
                >
                    {columns.map((column) => (
                        <TableCell
                            key={createKey(item, column)}
                            data-label={`${column.key}:`}
                            colSpan={(column.cellColSpan as number) || 1}
                            collapse={collapse}
                            style={column.style}
                        >
                            {renderCell(item, column)}
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </StyledTableBody>
    );
};

export default TableBody;
