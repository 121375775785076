import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootStateProps } from '../../../reducers';
import { Perm } from '../../common';

const useUserHasPerm = (perm: Perm): boolean => {
    const perms = useSelector<IRootStateProps, string[]>((state) => state.app.perms);

    return useMemo(() => perms.includes(perm), [perms, perm]);
};

export default useUserHasPerm;
