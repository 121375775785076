import React, { forwardRef, useContext } from 'react';
import { NotificationDto } from '../../../../../api/apiTypes/msgApiTypes';
import { NotificationContext } from '../../../context';
import NotificationElement from './components/NotificationElement';

const NotificationsDisplay = forwardRef(function ForwardRefedNotificationsDisplay(_props: any, ref: any): any {
    const { notifications } = useContext(NotificationContext);

    return notifications.map((notification: NotificationDto) => {
        return (
            <React.Fragment key={notification.id}>
                <NotificationElement key={notification.id} ref={ref} notification={notification} />
            </React.Fragment>
        );
    });
});

export default NotificationsDisplay;
