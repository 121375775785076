import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigation } from '../../utility';
import { useIsMobile } from '../../utility/hooks/useIsMobile';

interface UseEventsNavigationReturnType {
    userEventsSessionLink: string;
    myCalendarLink: string;
    userSessionsLink: string;
    settingsLink: string;
    goToEventsCategories: () => void;
    goToEvents: () => void;
    goToEventByIdLink: (eventId: string) => void;
    goToCreateSession: () => void;
    goToSession: () => void;
    goToEventsHealthAndFitness: () => void;
    goToEditSession: (sessionId: string) => void;
}

const useEventsNavigation = (): UseEventsNavigationReturnType => {
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const navigation = useNavigation();

    return useMemo(() => {
        const userEventsSessionLink = '/Booking/events';
        const userEventsCreateSessionLink = '/Booking/events/create';
        const healthAndFitnessLink = '/Booking/events/overview';
        const userSessionsLink = '/Booking/events/sessions';
        const myCalendarLink = `/Booking/events/myCalendar`;
        const settingsLink = '/Booking/events/settings';
        const categoriesLink = '/Booking/events/categories';
        const getEventByIdLink = (eventId: string) => {
            return userEventsSessionLink + `/${eventId}`;
        };

        const onNavigate = (url: string) => {
            navigation.push(url);
        };
        const goToEventsCategories = () => onNavigate(categoriesLink);
        const goToEvents = () => onNavigate(userEventsSessionLink);
        const goToCreateSession = () => onNavigate(userEventsCreateSessionLink);
        const goToSession = () => onNavigate(userSessionsLink);
        const goToEventsHealthAndFitness = () => onNavigate(healthAndFitnessLink);
        const goToEditSession = (sessionId: string) => onNavigate(`${userEventsSessionLink}/${sessionId}/edit`);

        return {
            userEventsSessionLink,
            myCalendarLink,
            settingsLink,
            userSessionsLink,
            goToEventsCategories,
            goToEventsHealthAndFitness,
            goToCreateSession,
            goToEvents,
            goToSession,
            goToEventByIdLink: (eventId: string) => onNavigate(getEventByIdLink(eventId)),
            goToEditSession,
        };
    }, [navigation, isMobile, dispatch]);
};
export default useEventsNavigation;
