import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { usePortalConfig } from '../../common/apiQueries/useAppConfiguration';
import { SidebarMenuItem } from '../../components';

const FoodExternalDynamicMenuItem = (): ReactElement => {
    const { t } = useTranslation('Dashboard', { keyPrefix: 'frontPage' });
    const { foodForMeetingRoomLink } = usePortalConfig();

    const onExternalFoodClick = () => {
        window.open(foodForMeetingRoomLink || '', '_blank');
    };

    return <SidebarMenuItem icon="CutleryIcon" text={t('food')} onClick={onExternalFoodClick} />;
};

export default FoodExternalDynamicMenuItem;
