import { forwardRef } from 'react';
import { connect } from 'react-redux';
import { sidebarOperations } from './duck';
import SidebarComponent from './SidebarComponent';

const mapStateToProps = (state: any, props: any) => {
    const { menu, deviceType } = state.app;
    const { isSidebarVisible } = state.sidebar;
    return {
        menu,
        isSidebarVisible,
        deviceType,
        ...props,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const handleShowSidebar = () => {
        dispatch(sidebarOperations.handleShowSidebar());
    };
    const handleHideSidebar = () => {
        dispatch(sidebarOperations.handleHideSidebar());
    };
    return { handleShowSidebar, handleHideSidebar };
};

const SidebarContainer = connect(mapStateToProps, mapDispatchToProps)(SidebarComponent);

export default forwardRef(function ForwardRefedSidebarContainer(props, ref) {
    return <SidebarContainer {...props} forwardedRef={ref} />;
});
