import { TicketingProvider } from '../Ticketing';
import Settings from './SettingsComponents';

export default function SettingsWithProvider() {
    return (
        <TicketingProvider>
            <Settings />
        </TicketingProvider>
    );
}
