import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

interface ContainerProps {
    dark: boolean;
}

const Container = styled.div`
    background-color: ${({ dark }: ContainerProps) => (dark ? '#263238' : '#ffd4d4')};
    color: ${({ dark }: ContainerProps) => (dark ? '#FFFFFF' : '#263238')};
    border-radius: 5px;
    padding: 0.5rem;
    margin-top: 0.5rem;
    display: flex;
    flex-grow: 1;
    align-items: center;
    align-self: flex-start;
`;

const ForbiddenIcon = styled(Icon)`
    color: var(--error-color-dark);
    transform: rotate(45deg);

    && {
        margin: 0 0.571rem;
    }
`;

interface ErrorMessageProps {
    message: string;
    dark?: boolean;
    className?: string;
}

const ErrorMessage: React.FC<React.PropsWithChildren<ErrorMessageProps>> = ({
    message,
    dark = false,
    className,
}: ErrorMessageProps) => {
    return (
        <Container className={className} dark={dark}>
            <ForbiddenIcon name="minus circle" />
            <p>{message}</p>
        </Container>
    );
};

export default ErrorMessage;
