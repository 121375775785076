import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { ErrorMessage } from '../../../../../../../../components';
import { getDeviceLanguageDateFormat } from '../../../../../../../../utility/dateUtilities/getDeviceLanguageDateFormat';

const MeetingContainer = styled(Button)`
    && {
        display: flex;
        align-items: center;
        background: none;
        padding: 0.5rem 1rem;
    }
`;

const TitleHeader = styled.h3`
    margin: 0;
    font-size: 1.5rem;
    margin-right: 1rem;
    color: var(--text-high-emphasis-color);
`;

const DeliveryTimeHeader = styled.h4`
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
    color: var(--text-medium-emphasis-color);
`;

const TopContainer = styled.div`
    display: flex;
    align-items: flex-end;
`;

const DetailsInfo = styled.p`
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    text-align: left;
    font-size: 0.85rem;
    color: var(--text-medium-emphasis-color);
`;

const CaretIcon = styled(Icon)`
    &&&&&&&& {
        margin-left: 1rem;
    }
`;

interface SelectedMeetingProps {
    title: string;
    deliveryDateTime: Date;
    meetingStartTime: Date;
    roomName: string;
    organizerName: string;
    buildingName: string;
    onClick: () => void;
    errorMessage: string;
    withCaret?: boolean;
}

const SelectedMeeting: React.FC<React.PropsWithChildren<SelectedMeetingProps>> = ({
    title,
    deliveryDateTime,
    meetingStartTime,
    roomName,
    organizerName,
    buildingName,
    onClick,
    errorMessage,
    withCaret = false,
}: SelectedMeetingProps) => {
    const { t } = useTranslation('Food', { keyPrefix: 'foodOrder' });
    return (
        <MeetingContainer onClick={onClick}>
            <div>
                <TopContainer>
                    <TitleHeader>{title}</TitleHeader>{' '}
                    <DeliveryTimeHeader>
                        {t('deliveryTime', { date: getDeviceLanguageDateFormat(deliveryDateTime, 'kk:mm') })}
                    </DeliveryTimeHeader>
                </TopContainer>
                <DetailsInfo>
                    {getDeviceLanguageDateFormat(new Date(meetingStartTime), 'eee d MMM kk:mm')} {' - '}
                    {roomName}, {organizerName},{buildingName}
                </DetailsInfo>
                {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
            </div>
            {withCaret ? <CaretIcon name="caret down" /> : null}
        </MeetingContainer>
    );
};

export default SelectedMeeting;
