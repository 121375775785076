import { AxiosError, AxiosResponse } from 'axios';
import type { MutationResultPair, InfiniteQueryResult } from 'react-query';
import { useInfiniteQuery, useQueryCache, useMutation } from 'react-query';
import {
    CreateTicketComment,
    TicketCommentWithSeen,
    TicketReadCountDto,
    TicketSeenEntity,
} from '../../../api/apiTypes/ticketingApiTypes';
import {
    createTicketComment,
    getTicketComments,
    getTicketReadCount,
    setTicketReadCount,
} from '../../../api/ticketingApi';
import userHasSeen from './utils/userHasSeen';

const TicketCommentsCacheKey = 'TicketCommentsCacheKey';
const TicketCommentsReadCountCacheKey = 'TicketCommentsReadCountCacheKey';

export const useGetInfiniteTicketComments = (
    ticketId: string,
): InfiniteQueryResult<TicketCommentWithSeen[], Error | AxiosError<string>> => {
    const numberOfTicketComments = 20;
    return useInfiniteQuery<TicketCommentWithSeen[], Error | AxiosError<string>>(
        [TicketCommentsCacheKey, ticketId],
        async (_, ticketId, page): Promise<TicketCommentWithSeen[]> => {
            const response = await getTicketComments(ticketId, page ?? 0, numberOfTicketComments);
            const responseCount = await getTicketReadCount(ticketId, TicketSeenEntity.Comments);
            const { allCount, readCount } = responseCount.data;

            return userHasSeen(response.data, allCount - readCount);
        },
        {
            getFetchMore: (lastPage, allPages) => {
                if (lastPage.length !== numberOfTicketComments) {
                    return false;
                }
                return allPages.length;
            },
            staleTime: 1000 * 20,
            refetchInterval: 1000 * 20,
            forceFetchOnMount: true, //set it to true so the comment could be retrieved every time the user loads ticket page
        },
    );
};

export const useCreateTicketComment = (): MutationResultPair<
    AxiosResponse<CreateTicketComment>,
    string | AxiosError<string> | Error,
    [string, CreateTicketComment],
    never
> => {
    const cache = useQueryCache();

    return useMutation(
        ([ticketNumber, comment]) => {
            return createTicketComment(ticketNumber, comment);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(TicketCommentsCacheKey);
            },
        },
    );
};

export const useSetReadCount = (): MutationResultPair<
    AxiosResponse,
    string | AxiosError<string> | Error,
    [string, TicketSeenEntity],
    never
> => {
    const cache = useQueryCache();

    return useMutation(
        ([ticketNumber, seenEntity]) => {
            const dto: TicketReadCountDto = {
                seenEntity: seenEntity,
                lastSeenDateTime: new Date(),
            };
            return setTicketReadCount(ticketNumber, dto);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(TicketCommentsReadCountCacheKey);
            },
        },
    );
};
