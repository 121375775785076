import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { sidebarOperations } from '../../common/Sidebar/duck';
import { SidebarMenuItem } from '../../components';
import { useNavigation } from '../../utility';
import { useIsMobile } from '../../utility/hooks/useIsMobile';
import useEventsNavigation from './useEventsNavigation';

const EventsDynamicMenuItem = (): ReactElement => {
    const { t } = useTranslation('events');
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const { pathname } = useLocation();
    const navigation = useNavigation();
    const { userEventsSessionLink, userSessionsLink, myCalendarLink, settingsLink, goToEventsHealthAndFitness } =
        useEventsNavigation();

    const onNavigate = (url: string, shouldCloseSideBar = true) => {
        if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
        navigation.push(url);
    };

    const goToEvents = () => onNavigate(userSessionsLink, false);
    const goToMyCalendar = () => onNavigate(myCalendarLink, false);

    const isEventsPathName =
        pathname?.includes(userEventsSessionLink) ||
        pathname?.includes(myCalendarLink) ||
        pathname?.includes(settingsLink);

    return (
        <SidebarMenuItem
            icon="FavoriteBorderIcon"
            isActive={isEventsPathName}
            onClick={goToEventsHealthAndFitness}
            text={t('healthAndFitnessMenuLabel')}
        >
            <SidebarMenuItem
                isActive={pathname?.includes(userSessionsLink)}
                onClick={goToEvents}
                text={t('sessionsMenuLabel')}
            />
            <SidebarMenuItem
                isActive={pathname?.includes(myCalendarLink)}
                onClick={goToMyCalendar}
                text={t('myCalendarMenuLabel')}
            />
        </SidebarMenuItem>
    );
};

export default EventsDynamicMenuItem;
