import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const ServiceContainerButton = styled.button`
    display: flex;
    align-items: center;
    width: 100%;

    padding: 1em;
    border: solid 1px var(--border-color);
    background: var(--surface-color-light);
`;

const ButtonPhrase = styled.p`
    margin-left: 1em;

    font-weight: bold;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 1.1em;
`;

const FullWidthActionButton = ({ buttonText, icon, isLoading, onClick }) => {
    return (
        <ServiceContainerButton onClick={onClick}>
            {icon && <Icon name={isLoading ? 'circle notch' : icon} loading={isLoading} />}
            {buttonText && <ButtonPhrase>{buttonText}</ButtonPhrase>}
        </ServiceContainerButton>
    );
};

FullWidthActionButton.propTypes = {
    buttonText: PropTypes.string,
    icon: PropTypes.string,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
};

export default FullWidthActionButton;
