import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { ErrorMessage } from '../../../components';
import { ChangeAmountButton } from '.';

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    border-bottom: 1px solid rgba(38, 50, 56, 0.12);
    padding: calc(var(--spacer) * 2);
    background-color: ${({ error }: { error: boolean | undefined }) => (error ? '#FFD4D4' : 'var(--background-color)')};
`;

const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const QuantityButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    touch-action: manipulation;
    margin-right: 1.5rem;
    min-width: 8.8rem;
`;

const ItemsTitleAndCost = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const ItemsTitle = styled.div`
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: rgba(38, 50, 56, 0.9);
    margin-bottom: 0.5rem;
`;

const ItemsCost = styled.div`
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: rgba(38, 50, 56, 0.38);
`;

const QuantityText = styled.span`
    font-size: 20px;
    font-weight: bold;
    color: rgba(38, 50, 56, 0.9);
    margin-left: calc(var(--spacer) * 3);
    margin-right: calc(var(--spacer) * 3);
`;

const StyledIcon = styled(Icon)`
    &&& {
        color: #2f313182;
        :hover {
            color: #2f3131d1;
            cursor: pointer;
        }
    }
`;

interface ShoppingCartItemProps {
    name: string;
    quantity?: number;
    minQuantity?: number;
    handleQuantityChanged?: (quantity: number) => void;
    priceString: string;
    priceUnit: string;
    handleRemoveItem?: () => void;
    deliveryDateTime?: Date;
    itemDeadline?: Date;
    itemIsUnavailable?: boolean;
    showDeadlineMessage?: boolean;
}

const ShoppingCartItem: React.FC<React.PropsWithChildren<ShoppingCartItemProps>> = ({
    name,
    quantity,
    minQuantity,
    handleQuantityChanged,
    priceString,
    priceUnit,
    handleRemoveItem,
    itemDeadline,
    itemIsUnavailable,
    showDeadlineMessage = true,
}: ShoppingCartItemProps) => {
    const handleQuantityIncreased = () => {
        if (handleQuantityChanged && quantity) {
            handleQuantityChanged(quantity + 1);
        }
    };

    const handleQuantityDecreased = () => {
        if (handleQuantityChanged && quantity) {
            handleQuantityChanged(quantity - 1);
        }
    };
    const deadlineIsInPast = itemDeadline && itemDeadline < new Date();
    const isInvalid = deadlineIsInPast || itemIsUnavailable;
    const { t } = useTranslation('Food', { keyPrefix: 'commonFood' });
    return (
        <ItemContainer error={isInvalid && showDeadlineMessage}>
            <InfoContainer>
                <QuantityButtonsWrapper>
                    {handleQuantityChanged && (
                        <ChangeAmountButton
                            icon="minus"
                            disabled={quantity === 1 || minQuantity === quantity || (isInvalid && !showDeadlineMessage)}
                            onClick={handleQuantityDecreased}
                            aria-label={`Reduce quantity item by one`}
                        />
                    )}
                    {quantity !== undefined ? <QuantityText>{quantity}</QuantityText> : null}
                    {handleQuantityChanged && (
                        <ChangeAmountButton
                            icon="plus"
                            disabled={isInvalid && !showDeadlineMessage}
                            onClick={handleQuantityIncreased}
                            aria-label={`Increase quantity of item by one`}
                        />
                    )}
                </QuantityButtonsWrapper>
                <ItemsTitleAndCost>
                    <ItemsTitle>{name}</ItemsTitle>
                    <ItemsCost>{`${priceString}kr ${priceUnit}`}</ItemsCost>
                </ItemsTitleAndCost>
                {handleRemoveItem && (showDeadlineMessage || !isInvalid) ? (
                    <StyledIcon name="trash alternate" size="large" onClick={handleRemoveItem} />
                ) : null}
            </InfoContainer>
            {showDeadlineMessage &&
                (isInvalid ? (
                    <ErrorMessage
                        dark
                        message={
                            deadlineIsInPast
                                ? t('deliveryTimeTooShortBookToAnotherDay')
                                : t('errorMessageItemNotAvailable')
                        }
                    />
                ) : null)}
        </ItemContainer>
    );
};

export default ShoppingCartItem;
