import { ReactElement } from 'react';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';

const LoaderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface FullPageLoaderProps {
    text?: string;
    className?: string;
}

const FullPageLoader = ({ text, className }: FullPageLoaderProps): ReactElement => {
    return (
        <LoaderContainer className={className}>
            <Loader active inline>
                {text}
            </Loader>
        </LoaderContainer>
    );
};

export default FullPageLoader;
