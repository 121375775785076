import { Key } from 'react';
import { t } from 'i18next';
import {
    OrderStatusOption,
    OrderSortingType,
    PreparedFiltersType,
    PurchasesFilterType,
    EmployeeOption,
    PaymentOption,
    PaymentTypes,
} from '../../../../api/apiTypes/shopApiTypes';
import { DropDownOption } from '../../../../components/select/FilterDropdown';
import { OptionType } from '../../../../components/select/searchSelect/types';
import { filterOptionsHelper } from '../../../../helpers/filterOptionsHelper';
import { ShopOverviewView, ShopAtWorkPurchaseFilters, ShopAtWorkState } from '../../ShopAtWork';
import { ColumnType } from './components/PurchasesTable';
import { PurchasesFilterProps } from './PurchasesOverview';

export enum PurchasesColumns {
    Status = 'status',
    OrderNumber = 'ordrenummer',
    Created = 'opprettet',
    Company = 'selskap',
    Items = 'ordrelinjer',
    Total = 'total',
    Payment = 'betalingsmiddel',
    Employee = 'ansatt',
}

export enum FilterKeys {
    Statuses = 'statuses',
    Payments = 'payments',
    Companies = 'companies',
    Employees = 'employees',
    // Customers = 'customers',
}

export interface FiltersType {
    statuses: OptionType[] | [];
    payments: OptionType[] | [];
    employees: OptionType[] | [];
    companies: OptionType[] | [];
    // customers: OptionType[] | [];
}

export const myOrdersColumns: Key[] = [
    PurchasesColumns.Status,
    PurchasesColumns.OrderNumber,
    PurchasesColumns.Created,
    PurchasesColumns.Payment,
    PurchasesColumns.Items,
    PurchasesColumns.Total,
];

export const companyOrdersColumns: Key[] = [
    PurchasesColumns.Status,
    PurchasesColumns.OrderNumber,
    PurchasesColumns.Created,
    PurchasesColumns.Employee,
    PurchasesColumns.Items,
    PurchasesColumns.Total,
];

export const portalOrdersColumns: Key[] = [
    PurchasesColumns.Status,
    PurchasesColumns.OrderNumber,
    PurchasesColumns.Created,
    PurchasesColumns.Company,
    PurchasesColumns.Items,
    PurchasesColumns.Total,
];

interface PurchaseFilterOption {
    key: PurchasesColumns;
    value: OrderSortingType;
    text: string;
}

export const myOrdersFilters: Key[] = [FilterKeys.Statuses, FilterKeys.Payments];
export const companyOrdersFilters: Key[] = [FilterKeys.Statuses, FilterKeys.Employees];
export const portalOrdersFilters: Key[] = [FilterKeys.Statuses, FilterKeys.Companies];

export const getShopSettings = (context: ShopAtWorkState): ShopAtWorkPurchaseFilters => {
    switch (context.shopOverviewView) {
        case ShopOverviewView.MyPurchases:
            return context.myOrders;
        case ShopOverviewView.CompanyPurchases:
            return context.companyOrders;
        case ShopOverviewView.PortalPurchases:
            return context.portalOrders;
        default:
            return context.myOrders;
    }
};

const filteredColumns = (allColumns: ColumnType, columns: Key[]) =>
    allColumns.filter((option) => columns.includes(option.key));

export const getViewColumns = (view: ShopOverviewView, allColumns: ColumnType): ColumnType => {
    if (view === ShopOverviewView.MyPurchases) return filteredColumns(allColumns, myOrdersColumns);
    if (view === ShopOverviewView.CompanyPurchases) return filteredColumns(allColumns, companyOrdersColumns);

    return filteredColumns(allColumns, portalOrdersColumns);
};

export const preparePurchaseFilters = (filters: FiltersType): PreparedFiltersType[] => {
    return Object.entries(filters).map(([type, options]) => {
        let enumFilterType: PurchasesFilterType;
        switch (type) {
            case FilterKeys.Statuses:
                enumFilterType = PurchasesFilterType.Status;
                break;
            case FilterKeys.Payments:
                enumFilterType = PurchasesFilterType.Payment;
                break;
            case FilterKeys.Employees:
                enumFilterType = PurchasesFilterType.Employee;
                break;
            case FilterKeys.Companies:
                enumFilterType = PurchasesFilterType.Company;
                break;
            default:
                enumFilterType = PurchasesFilterType.Status;
                break;
        }
        return { type: enumFilterType, value: options.map((option: OptionType) => option.value) };
    });
};

export const getShopAtWorkStatusTranslation = (status: OrderStatusOption): string => {
    const shopArWorkStatuses = {
        Canceled: t('ShopAtWork:commonShopAtWork.canceled'),
        Delivered: t('ShopAtWork:commonShopAtWork.delivered'),
        Ordered: t('ShopAtWork:commonShopAtWork.ordered'),
        Deleted: t('ShopAtWork:commonShopAtWork.deleted'),
        Approved: t('ShopAtWork:commonShopAtWork.approved'),
    };
    return shopArWorkStatuses[status] || status;
};

export const getShopArWorkFormOfPaymentTranslation = (payment: PaymentOption): string => {
    const paymentTranslations: Record<PaymentOption, string> = {
        [PaymentOption.CustomerInvoice]: t('ShopAtWork:invoice.customerInvoice'),
        [PaymentOption.Stripe]: t('ShopAtWork:invoice.stripe'),
    };

    return paymentTranslations[payment] || t('ShopAtWork:invoice.customerInvoice');
};

const getPaymentTranslation = (payment: PaymentTypes): string => {
    const paymentTypes: Record<PaymentTypes, string> = {
        CustomerInvoice: t('ShopAtWork:invoice.customerInvoice'),
        Stripe: t('ShopAtWork:invoice.stripe'),
    };
    return paymentTypes[payment] || payment;
};
export const renderStatusOptions = (options: OrderStatusOption[] | undefined): OptionType[] => {
    return options ? options.map((option) => ({ value: option, label: getShopAtWorkStatusTranslation(option) })) : [];
};

export const renderPaymentOptions = (options: any[] | undefined): OptionType[] | [] => {
    return options ? options.map((option) => ({ value: option, label: getPaymentTranslation(option) })) : [];
};

export const renderEmployeeOptions = (options: EmployeeOption[] | undefined): OptionType[] | [] => {
    return options ? options.map((option) => ({ value: option.id, label: option.name })) : [];
};

export const renderCompanyOptions = (options: any[] | undefined): OptionType[] | [] => {
    return options ? options.map((option) => ({ value: option.id, label: option.name })) : [];
};

export const renderMenuTypeOptions = (options: string[] | undefined): OptionType[] | [] => {
    return options ? options.map((option) => ({ value: option, label: option })) : [];
};

const getFilteredFilters = (allFilters: PurchasesFilterProps[], filterKeys: Key[]) => {
    return allFilters.filter((option) => filterKeys.includes(option.key));
};

export const getViewFilters = (view: ShopOverviewView, allFilters: PurchasesFilterProps[]): PurchasesFilterProps[] => {
    if (view === ShopOverviewView.MyPurchases) return getFilteredFilters(allFilters, myOrdersFilters);
    if (view === ShopOverviewView.CompanyPurchases) return getFilteredFilters(allFilters, companyOrdersFilters);
    if (view === ShopOverviewView.PortalPurchases) return getFilteredFilters(allFilters, portalOrdersFilters);

    return getFilteredFilters(allFilters, myOrdersFilters);
};

const allShopAtWorkFilterOptions = (): PurchaseFilterOption[] => {
    return [
        {
            key: PurchasesColumns.Status,
            value: OrderSortingType.Status,
            text: t('ShopAtWork:portalPurchases.statusFilterOption'),
        },
        {
            key: PurchasesColumns.OrderNumber,
            value: OrderSortingType.OrderNumber,
            text: t('ShopAtWork:portalPurchases.orderNumberFilterOption'),
        },
        {
            key: PurchasesColumns.Created,
            value: OrderSortingType.Date,
            text: t('ShopAtWork:portalPurchases.dateCreatedFilterOption'),
        },
        {
            key: PurchasesColumns.Payment,
            value: OrderSortingType.Payment,
            text: t('ShopAtWork:portalPurchases.purchasesFilterPayments'),
        },
        {
            key: PurchasesColumns.Employee,
            value: OrderSortingType.Employee,
            text: t('ShopAtWork:portalPurchases.employeeFilterOption'),
        },
        {
            key: PurchasesColumns.Company,
            value: OrderSortingType.Company,
            text: t('ShopAtWork:portalPurchases.companyFilterOption'),
        },
        {
            key: PurchasesColumns.Items,
            value: OrderSortingType.Items,
            text: t('ShopAtWork:portalPurchases.orderFilterOption'),
        },
        {
            key: PurchasesColumns.Total,
            value: OrderSortingType.Total,
            text: t('ShopAtWork:portalPurchases.totalFilterOption'),
        },
    ];
};

export const getFilterDropdownOptions = (view: ShopOverviewView, isMobile: boolean): DropDownOption[] => {
    const filterOptions = allShopAtWorkFilterOptions();
    if (view === ShopOverviewView.MyPurchases && isMobile) return filterOptionsHelper(filterOptions, myOrdersColumns);
    if (view === ShopOverviewView.CompanyPurchases && isMobile)
        return filterOptionsHelper(filterOptions, companyOrdersColumns);
    if (view === ShopOverviewView.PortalPurchases && isMobile)
        return filterOptionsHelper(filterOptions, portalOrdersColumns);

    return filterOptionsHelper(filterOptions, myOrdersColumns);
};

export const purchasesHeader = (view: ShopOverviewView): string => {
    const purchasesHeaders = {
        MyPurchases: t('ShopAtWork:portalPurchases.myPurchases'),
        CompanyPurchases: t('ShopAtWork:portalPurchases.companyPurchases'),
        PortalPurchases: t('ShopAtWork:portalPurchases.portalPurchases'),
        Inventory: t('ShopAtWork:portalPurchases.inventory'),
    };
    return purchasesHeaders[view] || '';
};
