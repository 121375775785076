import { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import type { RouteProps } from 'react-router-dom';
import { Route, useLocation } from 'react-router-dom';
import { Dimmer } from 'semantic-ui-react';
import { Loader } from '..';
import { UserStatus } from '../../api/apiTypes/portalApiTypes';
import { AppRedirect } from '../../components';
import useCurrentUserContext from '../Contexts/useCurrentUserContext';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';

interface IAuthorizeRouteProps extends RouteProps {
    layout?: ({ children }: PropsWithChildren<unknown>) => ReactElement;
}
const AuthorizeRoute = ({
    children,
    layout: Layout,
    ...rest
}: PropsWithChildren<IAuthorizeRouteProps>): JSX.Element => {
    const { state } = useCurrentUserContext();
    const location = useLocation();
    const { currentUser } = state;
    const { t } = useTranslation('authorization');
    if (state.isLoadingCurrentUser) {
        return (
            <Dimmer active inverted>
                <Loader>{t('loadingUser')}</Loader>
            </Dimmer>
        );
    }
    const renderAuthenticatedRoute = () => {
        if (!location.pathname.includes('/deleted-user')) {
            if (currentUser?.status === UserStatus.DeletedBySelf || currentUser?.status === UserStatus.DeletedByOther) {
                return deletedUserRoute();
            }
        }

        if (!location.pathname.includes('/approve-privacy-policy')) {
            if (!state.currentUser?.confirmedDate || !state.privacyPolicy?.approvedDate) {
                return approvePrivacyPolicyRoute();
            }
        }

        if (Layout) {
            return <Layout>{children}</Layout>;
        }
        return children;
    };

    const renderLoginRoute = () => {
        const returnUrl = `${window.location.pathname}${window.location.search ? `${window.location.search}` : ''}`;

        return <AppRedirect to={`${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${returnUrl}`} />;
    };
    const approvePrivacyPolicyRoute = () => {
        return (
            <AppRedirect<{ returnUrl: string; search: string }>
                to={{
                    pathname: '/approve-privacy-policy',
                    state: {
                        returnUrl: location.pathname,
                        search: location.search,
                    },
                }}
            />
        );
    };
    const deletedUserRoute = () => {
        return (
            <AppRedirect
                to={{
                    pathname: '/deleted-user',
                    state: { returnUrl: location.pathname },
                }}
            />
        );
    };

    return <Route {...rest} render={() => (state.currentUser ? renderAuthenticatedRoute() : renderLoginRoute())} />;
};

export default AuthorizeRoute;
