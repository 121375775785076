export interface OptionType {
    label: string;
    value: string;
    isFixed?: boolean;
}

export interface GroupedOptionType {
    label: string;
    options: OptionType[];
}

export enum LayoutPosition {
    Absolute = 'absolute',
    Static = 'static',
}
