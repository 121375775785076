import { useMemo } from 'react';
import type { AxiosError, AxiosResponse } from 'axios';
import { addSeconds, endOfDay, formatISO, isSameDay, startOfDay } from 'date-fns';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import {
    ApproveBookings,
    Booking,
    BookingForManagingFoodOrder,
    Building,
    MyBooking,
    RoomWithBuildingId,
    UpdateMeetingRoomBooking,
} from '../../../api/apiTypes/bookingApiTypes';
import { ModuleName } from '../../../api/apiTypes/portalApiTypes';
import {
    deleteMeetingRoomBooking,
    getBookings,
    getDeclinedBookingForManagingFoodOrder,
    getMyBookings,
    updateMeetingRoomBooking,
    approveMeeting,
    declineMeeting,
    getUserIsApprover,
    getApprovalBookings,
    getBookingDetailsByPeriodExcelFile,
} from '../../../api/bookingApi';
import { useModuleIsActive } from '../../../utility';
import { useInvalidateFoodOrders } from '../../Food/apiQueries/useBookings';
import { IBookingsAPI } from '../types';
import { useBuildings } from './useMeetingRooms';

const meetingBookingsQueryKey = 'meetingBookings';
const myMeetingBookingsQueryKey = 'myMeetingBookings';
const meetingApprovedBookingsQueryKey = 'meetingApprovedBookingsQueryKey';
const userIsMeetingsApproverCacheKey = 'userIsMeetingsApproverCacheKey';
const declinedBookingForManagingFoodOrderCacheKey = 'declinedBookingForManagingFoodOrderCacheKey';
const today = new Date();
today.setHours(0, 0, 0, 0);

const maxDate = new Date(100000000000000);
export type UserIsMeetingsApprover = boolean;

export const useGetUserMeetingBookings = (
    startDate: Date | null = null,
    endDate: Date | null = null,
): QueryResult<MyBooking[], string | Error> => {
    return useQuery(
        [myMeetingBookingsQueryKey, startDate, endDate],
        async () => {
            const result = await getMyBookings(startDate ?? today, endDate ?? maxDate);
            return result.data
                .map((item) => ({
                    ...item,
                    startTime: new Date(item.startDateTime),
                    endTime: new Date(item.endDateTime),
                }))
                .filter(({ endTime }) => endTime > new Date());
        },
        {
            // staleTime: 300 * 1000,
        },
    );
};

export const useGetDeclinedBookingForManagingFoodOrder = (
    bookingId: string,
    enabled: boolean,
): QueryResult<BookingForManagingFoodOrder, string | Error> => {
    return useQuery(
        [declinedBookingForManagingFoodOrderCacheKey],
        async () => {
            const result = await getDeclinedBookingForManagingFoodOrder(bookingId);
            return result.data;
        },
        {
            staleTime: 300 * 1000,
            enabled,
        },
    );
};

export const useGetMeetingBookings = (date: Date | null = null): QueryResult<IBookingsAPI[], string | Error> => {
    const meetingModuleIsActive = useModuleIsActive(ModuleName.Meeting);
    const getConstantTimeOfDay = (date: Date): Date => {
        const newOne = new Date(date);
        newOne.setMilliseconds(0);
        newOne.setSeconds(0);
        newOne.setMinutes(0);
        newOne.setHours(12);
        return newOne;
    };

    const { data: buildings, isLoading: isLoadingBuildings, isFetching: isFetchingBuildings } = useBuildings();

    const innerDate = useMemo(() => {
        return date ? getConstantTimeOfDay(date) : null;
    }, [date]);
    const queryCache = useQueryCache();
    const extraSecondToRetrieveMidnightBookings = 1;

    const {
        data: bookings,
        isLoading,
        isFetching,
        ...rest
    } = useQuery<Booking[], string | Error>(
        [meetingBookingsQueryKey, innerDate],
        async () => {
            const result = await getBookings(
                date ? startOfDay(date) : today,
                date ? addSeconds(endOfDay(date), extraSecondToRetrieveMidnightBookings) : maxDate,
            );
            return result.data;
        },
        {
            staleTime: 60 * 1000,
            initialData: () => {
                const data = queryCache.getQueryData<Booking[]>([meetingBookingsQueryKey, null]);
                if (data && innerDate) {
                    return data.filter(({ startDateTime }) => isSameDay(new Date(startDateTime), innerDate));
                }
            },
            enabled: meetingModuleIsActive,
        },
    );

    return {
        ...(rest as QueryResult<IBookingsAPI[], string | Error>),
        data: useMemo(() => {
            if (!buildings || !bookings) return [];
            const allRooms = buildings
                .map((building: Building) => [...building.rooms.map((room) => ({ ...room, buildingId: building.id }))])
                .flat();

            return bookings.map((booking) => ({
                ...booking,
                startTime: new Date(booking.startDateTime),
                endTime: new Date(booking.endDateTime),
                room: allRooms.find((room) => room.id === booking.room.id) as RoomWithBuildingId,
            }));
        }, [bookings, buildings]),
        isLoading: isLoading || isLoadingBuildings,
        isFetching: isFetching || isFetchingBuildings,
    };
};

export const useUpdateMeeting = (): MutationResultPair<
    AxiosResponse<never>,
    string,
    UpdateMeetingRoomBooking,
    never
> => {
    const queryCache = useQueryCache();
    const invalidateFoodOrders = useInvalidateFoodOrders();
    return useMutation<AxiosResponse<never>, string, UpdateMeetingRoomBooking, never>(updateMeetingRoomBooking, {
        onSuccess: () => {
            queryCache.invalidateQueries([meetingBookingsQueryKey]);
            invalidateFoodOrders();
        },
    });
};

export const useDeleteMeeting = (): MutationResultPair<AxiosResponse<never>, never, string, never> => {
    const queryCache = useQueryCache();
    const invalidateFoodOrders = useInvalidateFoodOrders();
    return useMutation(deleteMeetingRoomBooking, {
        onSuccess: () => {
            queryCache.invalidateQueries([meetingBookingsQueryKey]);
            invalidateFoodOrders();
        },
    });
};

export const useInvalidateMeetingBookings = (): (() => void) => {
    const queryCache = useQueryCache();
    return async () => {
        await queryCache?.invalidateQueries([meetingBookingsQueryKey]);
        await queryCache?.refetchQueries([myMeetingBookingsQueryKey]);
    };
};

export const useUpdateApproversMeetings = (
    bookingId: string,
): MutationResultPair<AxiosResponse<never>, string | AxiosError, [string], unknown> => {
    const queryCache = useQueryCache();
    return useMutation(() => approveMeeting(bookingId), {
        onSuccess: () => {
            queryCache.invalidateQueries([meetingApprovedBookingsQueryKey]);
        },
    });
};

export const useUpdateDeclinedMeetings = (): MutationResultPair<
    AxiosResponse<void>,
    string | AxiosError,
    [string, string],
    unknown
> => {
    const queryCache = useQueryCache();
    return useMutation(([bookingId, declineReason]) => declineMeeting(bookingId, declineReason), {
        onSuccess: () => {
            queryCache.invalidateQueries([meetingApprovedBookingsQueryKey]);
        },
    });
};

export const useUserIsMeetingsApprover = (): QueryResult<
    UserIsMeetingsApprover,
    string | AxiosError<string> | Error
> => {
    return useQuery([userIsMeetingsApproverCacheKey], async () => {
        const result = await getUserIsApprover();
        return result.data;
    });
};

export const useGetApprovalMeetingBookings = (
    date: Date | null = null,
): QueryResult<ApproveBookings[], string | Error> => {
    const meetingModuleIsActive = useModuleIsActive(ModuleName.Meeting);
    const { data: userIsMeetingsApprover } = useUserIsMeetingsApprover();
    const enabledGetApprovalMeetings = meetingModuleIsActive && userIsMeetingsApprover;
    const getConstantTimeOfDay = (date: Date): Date => {
        const newOne = new Date(date);
        newOne.setMilliseconds(0);
        newOne.setSeconds(0);
        newOne.setMinutes(0);
        newOne.setHours(12);
        return newOne;
    };

    const innerDate = useMemo(() => {
        return date ? getConstantTimeOfDay(date) : null;
    }, [date]);
    const queryCache = useQueryCache();

    const {
        data: bookings,
        isLoading,
        isFetching,
        ...rest
    } = useQuery<ApproveBookings[], string | Error>(
        [meetingApprovedBookingsQueryKey, innerDate],
        async () => {
            const result = await getApprovalBookings(date ? startOfDay(date) : today, date ? endOfDay(date) : maxDate);
            return result.data;
        },
        {
            staleTime: 60 * 1000,
            initialData: () => {
                const data = queryCache.getQueryData<ApproveBookings[]>([meetingBookingsQueryKey, null]);
                if (data && innerDate) {
                    return data.filter(({ startDate }) => isSameDay(new Date(startDate), innerDate));
                }
            },

            enabled: enabledGetApprovalMeetings,
        },
    );

    return {
        ...(rest as QueryResult<ApproveBookings[], string | Error>),

        data: useMemo(() => {
            if (!bookings) return [];

            return bookings.map((booking) => ({
                ...booking,
                startDate: new Date(booking.startDate),
                endDate: new Date(booking.endDate),
            }));
        }, [bookings]),
        isLoading: isLoading,
        isFetching: isFetching,
    };
};

export const useGetBookingDataByPeriodExcelFile = (): MutationResultPair<
    AxiosResponse<Blob, string>,
    AxiosError<string>,
    [string, string, string],
    never
> => {
    return useMutation(
        async ([dateFrom, dateTo, ianaLocaleString]) => {
            return getBookingDetailsByPeriodExcelFile(dateFrom, dateTo, ianaLocaleString);
        },
        {
            onSuccess: (file) => {
                const url = window.URL.createObjectURL(new Blob([file.data]));
                const link = document.createElement('a');
                const todayDate = formatISO(new Date());
                link.href = url;
                link.setAttribute('download', `Booking-${todayDate}.xlsx`);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            },
        },
    );
};
