import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ProductCategoryOptionWithCount,
    ProductCategoryWithSubCategories,
} from '../../../../../../api/apiTypes/shopApiTypes';
import {
    AccordionContextMenuAction,
    AccordionSubCategoryInterface,
    AccordionTabProps,
    SuffixInterface,
} from '../../../../../../components/accordion/types';
import { OptionType } from '../../../../../../components/general/dropdown/DropDown';
import {
    useGetProductTypesByPortalIdWithCategories,
    useRemoveProductCategoryById,
} from '../../../../apiQueries/useShopFilters';
import useShopAtWorkNavigation from '../../../../useShopAtWorkNavigation';
import {
    getProductTypeOptionTranslation,
    getRemovalModalBodyContent,
    ModalInformationInterface,
    NewCategoryRemoveModalType,
} from '../../utils';

const suffix: SuffixInterface = {
    empty: 'Ingen Produkts',
    single: 'Produkt',
    plural: 'Produkts',
};

interface ManageCategoryLayoutLogicReturnType {
    preparedData: AccordionTabProps[];
    isRemoveModalShown: boolean;
    isCreateNewCategoryModalShown: boolean;
    isProductTypesWithCategoriesLoading: boolean;
    isRemoveProductCategoryByIdLoading: boolean;
    preparedProductTypesForModalDropdown: OptionType[];
    removeModalContent: ModalInformationInterface | null;
    goToInventory: () => void;
    hideRemoveModal: () => void;
    hideCreateNewCategoryModal: () => void;
    confirmRemove: (id: string, name: string) => void;
    selectedCategoryId: string | null;
    createNewCategory: (id: string | null) => void;
    handleHeaderBack: () => void;
    focusId: string;
    setFocusId: (id: string) => void;
}

export const ManageCategoryLayoutLogic = (): ManageCategoryLayoutLogicReturnType => {
    const [isRemoveModalShown, setIsRemoveModalShown] = useState<boolean>(false);
    const [isCreateNewCategoryModalShown, setIsCreateNewCategoryModalShown] = useState<boolean>(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
    const [focusId, setFocusId] = useState<string>('');
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'productCategoryAdministration' });
    const { t: tCommon } = useTranslation('common');

    const [removeModalContent, setRemoveModalContent] = useState<ModalInformationInterface | null>(null);
    const [removeProductCategoryById, { isLoading: isRemoveProductCategoryByIdLoading }] =
        useRemoveProductCategoryById(selectedCategoryId);
    const { data: productTypesWithCategories, isLoading: isProductTypesWithCategoriesLoading } =
        useGetProductTypesByPortalIdWithCategories();
    const { goToInventory } = useShopAtWorkNavigation();

    const handleRemoveAction = ({ id, productsCount, name }: { id: string; productsCount: number; name: string }) => {
        setSelectedCategoryId(id);
        setRemoveModalContent(
            productsCount
                ? getRemovalModalBodyContent({ name, productsCount, type: NewCategoryRemoveModalType.Navigate })
                : getRemovalModalBodyContent({ name, productsCount, type: NewCategoryRemoveModalType.Remove }),
        );
        setIsRemoveModalShown(true);
    };

    const confirmRemove = async (id: string, name: string) => {
        await removeProductCategoryById(id, {
            onSuccess: () => {
                setRemoveModalContent(
                    getRemovalModalBodyContent({
                        name,
                        productsCount: 0,
                        type: NewCategoryRemoveModalType.Success,
                    }),
                );
            },
        });
    };

    const handleCreateNewCategoryModal = (id: string | null): void => {
        setSelectedCategoryId(id);
        setIsCreateNewCategoryModalShown(true);
    };

    const handleHeaderBack = (): void => {
        goToInventory();
    };

    const renameAction: AccordionContextMenuAction = {
        text: t('renameActionText'),
        onClick: ({ id }: { id: string }): void => {
            setFocusId(id);
        },
    };

    const removeAction: AccordionContextMenuAction = {
        text: tCommon('deleteButton'),
        onClick: ({
            id,
            amountOfSubElements: productsCount,
            name,
        }: {
            id: string;
            amountOfSubElements: number;
            name: string;
        }): void => {
            handleRemoveAction({ id, productsCount, name });
        },
    };

    const createNewCategoryAction: AccordionContextMenuAction = {
        text: t('newCategoryButton'),
        onClick: ({ id }: { id: string | null }): void => handleCreateNewCategoryModal(id),
    };

    const prepareSubCategoriesForAccordionTab = ({
        id,
        name,
        countProducts,
    }: ProductCategoryOptionWithCount): AccordionSubCategoryInterface => {
        return {
            id,
            name,
            amountOfSubElements: countProducts,
            contextMenuActions: [renameAction, removeAction],
            hasParentAccordionTab: true,
            isEditable: true,
        };
    };

    const prepareCategoriesForAccordionTab = (product: ProductCategoryWithSubCategories): AccordionTabProps => ({
        id: product.id,
        name: getProductTypeOptionTranslation(product.name),
        suffix,
        contextMenuActions: [createNewCategoryAction],
        amountOfChildren: product.childElements?.length ?? 0,
        amountOfSubElements: product.productsCount,
        childElements: product.childElements?.map(prepareSubCategoriesForAccordionTab),
        hasParentAccordionTab: false,
        isEditable: false,
    });

    const preparedData: AccordionTabProps[] = useMemo(() => {
        if (!productTypesWithCategories) return [];
        const categories: AccordionTabProps[] = productTypesWithCategories.map((product) => {
            return prepareCategoriesForAccordionTab(product);
        });
        return categories;
    }, [productTypesWithCategories]);

    const preparedProductTypesForModalDropdown: OptionType[] = useMemo(() => {
        if (!productTypesWithCategories) return [];
        const dropdownOptions: OptionType[] = preparedData.map((productType) => {
            return {
                id: productType.id,
                value: productType.name,
            };
        });

        return dropdownOptions;
    }, [preparedData]);

    return {
        preparedData,
        isRemoveModalShown,
        isCreateNewCategoryModalShown,
        selectedCategoryId,
        isProductTypesWithCategoriesLoading,
        preparedProductTypesForModalDropdown,
        isRemoveProductCategoryByIdLoading,
        removeModalContent,
        goToInventory,
        hideRemoveModal: () => setIsRemoveModalShown(false),
        hideCreateNewCategoryModal: () => {
            setSelectedCategoryId(null);
            setIsCreateNewCategoryModalShown(false);
        },
        confirmRemove,
        createNewCategory: handleCreateNewCategoryModal,
        handleHeaderBack,
        focusId,
        setFocusId,
    };
};
