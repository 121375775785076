import { AxiosError } from 'axios';
import type { InfiniteQueryResult } from 'react-query';
import { useInfiniteQuery } from 'react-query';
import {
    OrderSortDirection,
    OrderSortingType,
    PreparedFiltersType,
    UserOrderGridResult,
} from '../../../../api/apiTypes/shopApiTypes';
import { getAllUsersOrdersForKitchenAdminInPortal } from '../../../../api/shopApi';

export const AllUsersOrdersIPortalsCacheKey = 'AllUsersOrdersIPortalsCacheKey';

export const useGetInfiniteAllUsersOrdersForKitchenAdminInPortal = (
    sortingType: OrderSortingType,
    sortDirection: OrderSortDirection | null,
    searchTerm: string,
    filters: PreparedFiltersType[],
    vendorId?: string,
): InfiniteQueryResult<UserOrderGridResult, Error | AxiosError<string>> => {
    const numberOfOrders = 20;

    return useInfiniteQuery<UserOrderGridResult, AxiosError<string> | Error>(
        [AllUsersOrdersIPortalsCacheKey, sortingType, sortDirection, searchTerm, filters, vendorId],
        async (_, sortingType, sortDirection, searchTerm, filters, vendorId, page): Promise<UserOrderGridResult> => {
            const { data } = await getAllUsersOrdersForKitchenAdminInPortal(
                page ?? 0,
                numberOfOrders,
                sortingType,
                sortDirection,
                searchTerm,
                filters,
                vendorId,
            );
            return data;
        },
        {
            getFetchMore: (lastPage, allPages) => (lastPage.data.length !== numberOfOrders ? false : allPages.length),
            enabled: !!vendorId,
        },
    );
};
