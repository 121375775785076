import type { QueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { getPrivacyPolicyStatus } from '../../../api/portalApi';
import { PrivacyPolicyStatus, PolicyType } from './../../../api/apiTypes/portalApiTypes';

const getWifiPolicyCacheKey = 'getWifiPolicyCacheKey';

export const useGetWifiPolicy = ({ enabled } = { enabled: true }): QueryResult<PrivacyPolicyStatus> => {
    return useQuery(
        getWifiPolicyCacheKey,
        async () => {
            const res = await getPrivacyPolicyStatus(PolicyType.Wifi);
            return res.data;
        },
        { enabled },
    );
};
