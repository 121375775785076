import React, { useRef } from 'react';
import styled from 'styled-components';

const ListContainer = styled.div`
    overflow: hidden;
    flex-grow: 1;
    touch-action: none;
`;

interface ContainerProps {
    marginToAdd: number;
    alignRight?: boolean;
}
const List = styled.div`
    list-style: none;
    padding: 0;
    margin: ${({ marginToAdd }: ContainerProps) => marginToAdd / 1.75}px 0;
    text-align: ${({ alignRight }: ContainerProps) => (alignRight ? 'right' : 'left')};
    z-index: 2;
    display: flex;
    flex-direction: column;
`;
interface OptionItemProps {
    selected: boolean;
    invalid?: boolean;
}
const OptionItem = styled.button`
    &&& {
        padding: 0.5rem;
        color: ${({ selected, invalid = false }: OptionItemProps) =>
            selected
                ? 'var(--primary-color)'
                : invalid
                ? 'var(--text-medium-emphasis-color)'
                : 'var(--text-high-emphasis)'};
        font-weight: bold;
        font-size: 1.15rem;
        border: none;
        background: none;
        text-align: inherit;
        outline: none;
    }
    &&&:focus {
        color: ${({ selected }: OptionItemProps) => (selected ? 'var(--primary-color)' : 'var(--primary-color-dark)')};
    }
`;

interface OptionsListProps {
    containerName: string;
    options: { value: string; invalid: boolean }[];
    selectedValue: string;
    handleValueChange: (newValue: string) => void;
    marginToAdd: number;
    alignRight?: boolean;
}

const OptionsList: React.FC<React.PropsWithChildren<OptionsListProps>> = ({
    containerName,
    options,
    selectedValue,
    handleValueChange,
    marginToAdd,
    alignRight = false,
}: OptionsListProps) => {
    const handleScroll = (event: React.WheelEvent) => {
        const delta = event.deltaY;
        const indexOfCurrent = options.findIndex(({ value }) => value === selectedValue);
        const numberOfSnaps = delta > 0 ? Math.max(Math.round(delta / 100), 1) : Math.min(Math.round(delta / 100), -1);
        const newValue = options[Math.max(Math.min(options.length - 1, indexOfCurrent + numberOfSnaps), 0)]?.value;
        handleValueChange(newValue);
    };

    const touchRef = useRef({ pos: 0, origIndex: 0 });
    const handleTouchStart = ({ touches }: React.TouchEvent) => {
        touchRef.current = {
            pos: touches[0].clientY,
            origIndex: options.findIndex(({ value }) => value === selectedValue),
        };
    };

    const handleTouchMove = ({ touches }: React.TouchEvent) => {
        const movedDistance = touchRef.current.pos - touches[0].clientY;
        const numberOfSnaps = Math.round(movedDistance / 30);
        const selectedValue =
            options[Math.max(Math.min(options.length - 1, touchRef.current.origIndex + numberOfSnaps), 0)]?.value;
        handleValueChange(selectedValue);
    };

    return (
        <ListContainer
            data-containername={containerName}
            onWheel={handleScroll}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
        >
            <List marginToAdd={marginToAdd} alignRight={alignRight}>
                {options.map(({ value, invalid }) => (
                    <OptionItem
                        onClick={() => handleValueChange(value)}
                        key={value}
                        data-value={`${containerName}${value}`}
                        selected={value === selectedValue}
                        invalid={invalid}
                        tabIndex={invalid ? undefined : 0}
                    >
                        {value}
                    </OptionItem>
                ))}
            </List>
        </ListContainer>
    );
};

export default OptionsList;
