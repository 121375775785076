import { EditorState } from 'draft-js';

const containsTextContent = (state: EditorState): boolean => {
    return (
        state
            .getCurrentContent()
            .getBlocksAsArray()
            .find((block) => block.getText() !== '') !== undefined
    );
};

export default containsTextContent;
