import { RefObject, useEffect } from 'react';

export default function useScrollToElement(ref: RefObject<HTMLElement> | string | undefined): void {
    useEffect(() => {
        const scrollToElement = (element: HTMLElement | null) => setTimeout(() => element?.scrollIntoView(), 100);
        if (typeof ref === 'object' && ref.current) {
            const element = document.getElementById(ref.current.id);
            scrollToElement(element);
        } else if (typeof ref === 'string') {
            const element = document.getElementById(ref);
            scrollToElement(element);
        }
    }, []);
}
