import { ReactElement, SyntheticEvent, useRef, useState } from 'react';
import styled, { CSSObject } from 'styled-components';

const ExpandedContainer = styled.div`
    position: relative;
`;

const StyledMenu = styled.div`
    position: absolute;
    right: 0;
    background-color: var(--surface-color-light);
    border: 1px solid var(--border-color);
    width: max-content;
    font-size: 1.14rem;
    display: flex;
    flex-direction: column;
    z-index: 10;
`;

const DropdownItem = styled.button`
    min-width: 14.286rem;
    background: var(--surface-color-white);
    border: none;
    border-bottom: 1px solid var(--border-color);
    padding: 0.57rem 1.14rem;
    text-align: left;
    cursor: pointer;

    :last-child {
        border: none;
    }

    :hover {
        background-color: var(--primary-color-light);
        color: var(--primary-on-color-light);
    }

    :focus {
        outline-style: normal;
        outline-width: 1px;
        outline-color: var(--primary-color);
    }
`;

interface OptionProps {
    onClick: (...args: any) => void;
    text: string;
}

interface TriggerProps {
    isOpen: boolean;
    onClick(): void;
    disabled: boolean;
    buttonRef: React.MutableRefObject<HTMLElement | null>;
}

interface MenuDropdownProps {
    Trigger(props: TriggerProps): ReactElement;
    items: OptionProps[];
    onChangeState?: (open: boolean) => void;
    open?: boolean;
    menuStyleConfig?: CSSObject;
    itemStyleConfig?: CSSObject;
}

const MenuDropdown = ({
    Trigger,
    items,
    onChangeState,
    open: forcedOpenState,
    menuStyleConfig,
    itemStyleConfig,
}: MenuDropdownProps): ReactElement => {
    const [internalIsOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLElement>(null);
    const disabledDropdown = items.length === 0;

    const handleSetOpen = (open: boolean) => {
        if (onChangeState) {
            onChangeState(open);
        }
        setIsOpen(open);
    };

    const handleOpen = (e?: SyntheticEvent, ...args: any[]) => {
        e?.preventDefault();
        e?.stopPropagation();
        handleSetOpen(true);

        // We need the timeout because its losing focus if not :(
        setTimeout(() => buttonRef.current?.focus(), 0);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Escape') {
            handleSetOpen(false);
        }
    };

    const handleDropdownClose = () => {
        setTimeout(() => {
            if (!dropdownRef.current?.contains(document.activeElement)) {
                handleSetOpen(false);
            }
        });
    };

    const handleAction = (e: SyntheticEvent, onClick: (...args: any) => void, rest: any) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(rest);
        handleSetOpen(false);
    };

    const shouldBeOpen = forcedOpenState !== undefined ? forcedOpenState : internalIsOpen;

    return (
        <ExpandedContainer aria-expanded={shouldBeOpen} onBlur={handleDropdownClose} ref={dropdownRef}>
            <Trigger onClick={handleOpen} disabled={disabledDropdown} isOpen={shouldBeOpen} buttonRef={buttonRef} />
            {internalIsOpen ? (
                <StyledMenu onKeyDown={handleKeyDown} style={menuStyleConfig}>
                    {items.map(({ text, onClick, ...rest }) => {
                        return (
                            <DropdownItem
                                key={text}
                                onClick={(event) => handleAction(event, onClick, rest)}
                                tabIndex={0}
                                style={itemStyleConfig}
                            >
                                {text}
                            </DropdownItem>
                        );
                    })}
                </StyledMenu>
            ) : null}
        </ExpandedContainer>
    );
};

export default MenuDropdown;
