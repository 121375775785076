import { useState, useCallback } from 'react';

const useClientRect = (): [
    (node: HTMLElement | null) => void,
    (
        | ClientRect
        | ({
              width: number;
              height: number;
              left: number;
              top: number;
              right: number;
              bottom: number;
          } & { node: HTMLElement | null })
    ),
] => {
    const [node, setRect] = useState<HTMLElement | null>(null);

    const ref = useCallback((node: HTMLElement | null) => {
        setRect(node);
    }, []);

    const rect = node?.getBoundingClientRect();
    return [
        ref,
        {
            ...(rect
                ? {
                      width: rect.width,
                      height: rect.height,
                      left: rect.left,
                      top: rect.top,
                      right: rect.right,
                      bottom: rect.bottom,
                      x: rect.x,
                      y: rect.y,
                  }
                : { height: 0, width: 0, bottom: 0, left: 0, right: 0, top: 0 }),
            node: node,
        },
    ];
};

export default useClientRect;
