import React, { FC, SVGProps } from 'react';
const ReloadIconSvg: FC<{ fill?: string }> = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <mask
            id="a"
            width={20}
            height={20}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: 'alpha',
            }}
        >
            <path
                fill="#000"
                d="M14.701 5.293a6.625 6.625 0 0 0-5.4-1.925c-3.058.308-5.574 2.792-5.916 5.85a6.654 6.654 0 0 0 12.617 3.65.835.835 0 0 0-.75-1.2.81.81 0 0 0-.734.442 4.995 4.995 0 0 1-5.666 2.758A4.956 4.956 0 0 1 5.118 11.1a5.002 5.002 0 0 1 4.875-6.1 4.93 4.93 0 0 1 3.517 1.484l-1.258 1.258c-.526.525-.159 1.425.583 1.425h2.991a.836.836 0 0 0 .834-.833V5.343c0-.742-.9-1.117-1.425-.592l-.534.542Z"
            />
        </mask>
        <g mask="url(#a)">
            <path fill={props.fill ?? '#475156'} d="M0 0h20v20H0z" />
        </g>
    </svg>
);
export default ReloadIconSvg;
