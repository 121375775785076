import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const MessageContainer = styled.div`
    padding: 0.65rem;
    background-color: #e1f6ff;
    display: flex;
    border-radius: 5px;
`;

const InfoIcon = styled(Icon)`
    color: var(--primary-color);
`;

const MessagePhrase = styled.p`
    color: #263238;
    margin-left: 0.5rem;
`;

interface InfoMessageProps {
    children: React.ReactNode;
}

const InfoMessage: React.FC<React.PropsWithChildren<InfoMessageProps>> = ({ children }: InfoMessageProps) => {
    return (
        <MessageContainer>
            <div>
                <InfoIcon name="info circle" />
            </div>
            <MessagePhrase>{children}</MessagePhrase>
        </MessageContainer>
    );
};

export default InfoMessage;
