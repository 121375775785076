import React, { FC, useMemo } from 'react';
import InfoCircleIconSvg from '../icons/InfoCircleIconSvg';
import { Typography, FlexBox } from '../styleds';
import { ErrorStripProps } from './types';

const ErrorStrip: FC<ErrorStripProps> = ({ textContent, background, iconColor, isShadow }) => {
    const textContentRender = useMemo(() => {
        if (typeof textContent === 'string') {
            return (
                <Typography fontSize={'1rem'} fontWeight={400} lineHeight={'1.714rem'}>
                    {textContent}
                </Typography>
            );
        } else {
            return textContent;
        }
    }, [textContent]);
    return (
        <FlexBox
            display={!textContent ? 'none' : 'flex'}
            boxShadow={isShadow ? '0rem 0.285rem 0.714rem 0rem rgba(0, 0, 0, 0.15)' : 'none'}
            brB={'0.357rem'}
            background={background ?? '#FFF6C9'}
            pd={'0.571rem 1.142rem'}
            gap={'0.571rem'}
            align={'center'}
        >
            <InfoCircleIconSvg fill={iconColor ?? '#D2B100'} />
            {textContentRender}
        </FlexBox>
    );
};

export default ErrorStrip;
