export const MEDIA_BREAKPOINTS: Record<string, number> = {
    MOBILE: 600,
    TABLET: 900,
    DESKTOP: 1024,
};

export const MEDIA_INDEX = {
    MOBILE: 2,
    TABLET: 1,
    DESKTOP: 0,
};
