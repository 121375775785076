import styled from 'styled-components';
import { BORDER_STYLES, MARGIN_PADDING_STYLES } from '../stylesHelpers';
import { IInputStyles } from './types';

export const InputStyled = styled.input.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})<IInputStyles>`
    width: ${({ width }) => width ?? '100%'};
    height: ${({ height }) => height ?? '2.857rem'};
    text-align: ${({ textAlign }) => textAlign ?? 'start'};
    background: ${({ background }) => background ?? 'var(--primary-color)'};
    color: ${({ color }) => color ?? '#000'};
    cursor: pointer;
    outline-color: ${({ outlineColor }) => outlineColor ?? 'auto'};
    outline-style: ${({ outlineStyle }) => outlineStyle ?? 'none'};
    outline-width: ${({ outlineWidth }) => outlineWidth ?? 'medium'};
    ${(props) => MARGIN_PADDING_STYLES(props)};
    ${(props) => BORDER_STYLES(props)};

    /* Ensuring the spinners (arrows) are visible */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: auto;
        margin: 0;
    }

    /* For Firefox */
    &[type='number'] {
        -moz-appearance: textfield; /* Use this line if you want to hide spinners in Firefox */
    }
`;

export default InputStyled;
