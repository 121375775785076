import { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import * as yup from 'yup';
import { GhostPrimaryButton, InputField, Modal, ModalType, PrimaryButton } from '../../../../components';
import { useUpdatePIN } from '../../../../modules/Admin/UsersAdmin/components/UserAdministrationSidesheet/ApiQueries/useAccessControlInfo';

const PIN_MAX_MIN_LENGTH = 4;

const StyledModal = styled(Modal)`
    #modalContentContainer {
        overflow-y: auto;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1.071rem;
`;
const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.78rem;
    overflow-y: auto;
`;

const InputContainer = styled.div`
    margin-bottom: 1rem;
`;

const StyledStepContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledErrorTitle = styled.p`
    font-weight: 900;
    font-size: 1.428rem;
    line-height: 2.285rem;
`;

const StyledErrorText = styled.p`
    font-weight: 400;
    font-size: 1.142rem;
    line-height: 1.428rem;
`;

const LoaderContainer = styled.div`
    position: relative;
    padding: 8rem;
`;

type Form = {
    pin: string | null;
    pinRepeat: string | null;
};

interface ACPinModalProps {
    open: boolean;
    onClose(): void;
}

const AccessControlPinModal = ({ open, onClose }: ACPinModalProps): ReactElement => {
    const { t } = useTranslation('myProfile');
    const { t: tCommon } = useTranslation('common');
    const [updatePin, { isLoading: isUpdatePinLoading, error: isUpdatePinError, isSuccess: isUpdatePinSuccess }] =
        useUpdatePIN();
    const pinSchema = {
        pin: yup
            .string()
            .required(t('pinRequired'))
            .matches(/^[0-9]+$/, t('mustContainNumberOnly'))
            .length(PIN_MAX_MIN_LENGTH, t('pinCodeError4Digits')),
        pinRepeat: yup
            .string()
            .oneOf([yup.ref('pin'), null], t('pinMustMatch'))
            .required(t('confirmPinRequired')),
    };
    const initialValues = {
        pin: null,
        pinRepeat: null,
    };

    const {
        formState: { errors, isValid },
        register,
        handleSubmit,
    } = useForm<Form>({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(yup.object(pinSchema)),
        defaultValues: initialValues,
    });

    const handleCloseModal = () => {
        onClose();
    };

    const onSubmit = (formValues: Form) => {
        formValues.pin && updatePin(formValues.pin);
    };

    const getCurrentStepContent = () => {
        if (isUpdatePinError) {
            return (
                <StyledStepContainer>
                    <picture>
                        <source srcSet="./empty-icon.webp" type="image/webp" />
                        <source srcSet="./empty-icon.png" type="image/png" />
                        <img src="empty-icon.png" alt="Set PIN error" />
                    </picture>
                    <StyledErrorTitle>{t('pinWasNotChange')}</StyledErrorTitle>
                    <StyledErrorText>{tCommon('tryLatterOrContactSupport')}</StyledErrorText>
                </StyledStepContainer>
            );
        }
        if (isUpdatePinLoading) {
            return (
                <LoaderContainer>
                    <Dimmer active inverted>
                        <Loader active inverted>
                            {tCommon('load')}
                        </Loader>
                    </Dimmer>
                </LoaderContainer>
            );
        }
        if (isUpdatePinSuccess) {
            return (
                <StyledStepContainer>
                    <picture>
                        <source srcSet="./SuccessState.webp" type="image/webp" />
                        <source srcSet="./SuccessState.jpg" type="image/jpeg" />
                        <img src="SuccessState.jpg" alt="PIN successfully changed" />
                    </picture>
                    <StyledErrorTitle>{t('pinSuccessfullyChanged')}</StyledErrorTitle>
                </StyledStepContainer>
            );
        }
        return (
            <form onSubmit={handleSubmit(onSubmit)} id="setPIN">
                <InputContainer>
                    <InputField
                        type="number"
                        {...register('pin')}
                        label={t('pinCode')}
                        required
                        placeholder={t('enterNewPin')}
                        error={errors?.pin?.message}
                    />
                </InputContainer>
                <InputContainer>
                    <InputField
                        type="number"
                        label={t('repeatPin')}
                        placeholder={t('repeatPinPlaceholder')}
                        required
                        {...register('pinRepeat')}
                        error={errors?.pinRepeat?.message}
                    />
                </InputContainer>
            </form>
        );
    };
    const buttons = () => {
        if (isUpdatePinSuccess) {
            return (
                <ButtonsContainer>
                    <PrimaryButton onClick={handleCloseModal}>{tCommon('closeButton')}</PrimaryButton>
                </ButtonsContainer>
            );
        }
        return (
            <ButtonsContainer>
                <GhostPrimaryButton onClick={handleCloseModal}>{tCommon('cancelButton')}</GhostPrimaryButton>
                <PrimaryButton type="submit" form={'setPIN'} disabled={!isValid || isUpdatePinLoading}>
                    {t('changePINCode')}
                </PrimaryButton>
            </ButtonsContainer>
        );
    };

    return (
        <StyledModal
            type={ModalType.Small}
            open={open}
            title={t('changePINCodeTitle')}
            onClose={handleCloseModal}
            bottomContent={buttons()}
            bordered
            hideCloseButton
        >
            <Container>{getCurrentStepContent()}</Container>
        </StyledModal>
    );
};

export default AccessControlPinModal;
