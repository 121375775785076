import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ArticleModel } from '../../../../../api/apiTypes/cmsApiTypes';
import { MenuDropdown, SvgIcon } from '../../../../../components';
import useArticlesNavigation from '../../../../Articles/useArticlesNavigation';
import { useGetArticle } from '../../ApiQueries/useArticles';
import { ModalStateType } from '../../components/ArticlesList';
import { fromArticleModelToArticleDto } from '../../utils';

type TriggerButtonProps = { show?: boolean };
const TriggerButton = styled.button`
    background-color: ${({ show }: TriggerButtonProps) =>
        show ? 'var(--primary-color-light)' : 'var(--surface-color)'};

    border-radius: 50%;
    cursor: pointer;
    width: 2.28rem;
    height: 2.28rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    :focus {
        border: 0.0625rem solid var(--primary-color);
        outline: none;
    }

    & path {
        fill: var(--text-medium-emphasis-color);
    }
`;

interface IArticleCardContextMenuProps {
    articleId: string;
    status: ArticleModel['status'];
    handleOpenModal(arg: ModalStateType): void;
}

const ArticleCardContextMenu: React.FC<React.PropsWithChildren<IArticleCardContextMenuProps>> = ({
    articleId,
    status,
    handleOpenModal,
}: IArticleCardContextMenuProps): JSX.Element => {
    const [shouldOpenModal, setShouldOpenModal] = useState<'RevertToDraft' | 'Delete' | false>(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const { editArticle } = useArticlesNavigation();
    const { data: article, isLoading } = useGetArticle(articleId, true, menuIsOpen);

    const handleEditArticleClick = useCallback(() => {
        editArticle(articleId as string);
    }, [articleId, editArticle]);

    const handleRevertToDraft = () => {
        setShouldOpenModal('RevertToDraft');
    };

    const handleArchive = () => {
        handleOpenModal({ type: 'Archive', props: { articleId } });
    };

    const handleDelete = () => {
        setShouldOpenModal('Delete');
    };

    useEffect(() => {
        // We defer opening some modals until we have loaded the data
        if (shouldOpenModal && !isLoading && article) {
            if (shouldOpenModal === 'RevertToDraft') {
                handleOpenModal({
                    type: 'RevertToDraft',
                    props: {
                        articleId,
                        getDto: () => fromArticleModelToArticleDto(article),
                        onEditArticleClick: handleEditArticleClick,
                    },
                });
            } else {
                handleOpenModal({
                    type: 'Delete',
                    props: { articleId, isLoadingArticle: isLoading },
                });
            }
            setShouldOpenModal(false);
        }
    }, [articleId, article, handleEditArticleClick, handleOpenModal, isLoading, shouldOpenModal]);

    const isPublished = status.startsWith('Published') || status.startsWith('Planned');
    const isArchived = status.startsWith('Archived');
    const isDraft = status === 'Draft';
    const canRevertToDraft = isPublished || isArchived;
    const canArchive = isPublished && !isArchived;
    const canDelete = isArchived || isDraft;
    const { t } = useTranslation('Dashboard', { keyPrefix: 'articles' });
    return (
        <MenuDropdown
            Trigger={({ onClick, isOpen, buttonRef }) => (
                <TriggerButton onClick={onClick} show={isOpen} ref={buttonRef as MutableRefObject<HTMLButtonElement>}>
                    <SvgIcon name="EllipsisIcon"></SvgIcon>
                </TriggerButton>
            )}
            open={menuIsOpen}
            onChangeState={setMenuIsOpen}
            items={
                [
                    { text: t('edit'), onClick: handleEditArticleClick },
                    canRevertToDraft && { text: t('actionBunnerResetToDraft'), onClick: handleRevertToDraft },
                    canArchive && { text: t('archiveModalArchivesButton'), onClick: handleArchive },
                    canDelete && { text: t('actionDeleteButton'), onClick: handleDelete },
                ].filter(Boolean) as { text: string; onClick: () => void }[]
            }
        />
    );
};

export default ArticleCardContextMenu;
