import { createContext, PropsWithChildren, ReactElement, useContext, useState } from 'react';
import styled from 'styled-components';

const ModalContext = createContext<null | HTMLElement>(null);

const MountDiv = styled.div`
    height: 0;
    width: 0;
    overflow: visible;
`;
const ModalProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    return (
        <>
            <ModalContext.Provider value={ref}>{children}</ModalContext.Provider>
            <MountDiv
                id="modalContainer"
                ref={(element) => {
                    setRef(element);
                }}
            />
        </>
    );
};

const useModalContext = (): HTMLElement => {
    const value = useContext(ModalContext);
    if (!value) throw Error('ModalContext must be higher in the three, for modals to work');
    return value;
};

export { ModalProvider, useModalContext };
