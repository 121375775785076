import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Perm } from '../../common';
import { sidebarOperations } from '../../common/Sidebar/duck';
import { useNavigation, useUserHasPerm } from '../../utility';
import { useIsMobile } from '../../utility/hooks/useIsMobile';

const useMeetingNavigation = (): {
    meetingOverviewLink: string;
    myCalendarLink: string;
    settingsLink: string;
    approvalRequestsLink: string;
    goToMeetingOverview(): void;
    goToMyCalendar(): void;
    goToSettings(): void;
    goToApprovalRequests(): void;
    userHaveMeetingPortalSettingsWritePerm: boolean;
} => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const userHaveMeetingPortalSettingsWritePerm = useUserHasPerm(Perm.MeetingPortalSettingsWrite);

    return useMemo(() => {
        const meetingOverviewLink = '/Meeting/MeetingOverview';
        const myCalendarLink = `/Meeting/MyCalendar`;
        const settingsLink = '/Meeting/Settings';
        const approvalRequestsLink = '/Meeting/ApprovalRequests';
        const onNavigate = (url: string, shouldCloseSideBar = true) => {
            if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
            navigation.push(url);
        };

        return {
            meetingOverviewLink,
            myCalendarLink,
            settingsLink,
            approvalRequestsLink,
            goToMeetingOverview: () => onNavigate(meetingOverviewLink),
            goToMyCalendar: () => onNavigate(myCalendarLink),
            goToSettings: () => onNavigate(settingsLink),
            goToApprovalRequests: () => onNavigate(approvalRequestsLink),
            userHaveMeetingPortalSettingsWritePerm,
        };
    }, [navigation]);
};
export default useMeetingNavigation;
