import type { TFunction } from 'i18next';
import { AllergenType } from '../../api/apiTypes/shopApiTypes';
import logger from '../../api/infrastructure/logService';

export const getAllergenTranslation = (allergen: string, t: TFunction): string | null => {
    switch (allergen) {
        case 'Almond':
            return t('almond');
        case 'Barley':
            return t('barley');
        case 'Brazil nut':
            return t('brazilNut');
        case 'Cashews':
            return t('cashews');
        case 'Crustaceans':
            return t('crustaceans');
        case 'Hazelnut':
            return t('hazelnut');
        case 'Macadamias':
            return t('macadamias');
        case 'Oats':
            return t('oats');
        case 'Pecan':
            return t('pecan');
        case 'Pistachio nut':
            return t('pistachioNut');
        case 'Rye':
            return t('rye');
        case 'Soyabeans':
            return t('soyabeans');
        case 'Sulphur':
            return t('sulphur');
        case 'Walnut':
            return t('walnut');
        case 'Wheat':
            return t('wheat');
        case 'Celery':
            return t('celery');
        case 'Egg':
            return t('egg');
        case 'Fish':
            return t('fish');
        case 'Gluten':
            return t('gluten');
        case 'Lupine':
            return t('lupine');
        case 'Lupin':
            return t('lupine');
        case 'Milk':
            return t('milk');
        case 'Molluscs':
            return t('molluscs');
        case 'Mustard':
            return t('mustard');
        case 'Nuts':
            return t('nuts');
        case 'Peanuts':
            return t('peanuts');
        case 'Sesame':
            return t('sesame');
        case 'Shellfish':
            return t('shellfish');
        case 'Soya':
            return t('soya');
        case 'Sulfite':
            return t('sulfite');

        default:
            logger.log(new Error(`Unknown allergen: ${allergen}`));
            return null;
    }
};

export const getTranslatedAllergens = (allergens: AllergenType[], tFunction: TFunction): AllergenType[] =>
    allergens
        .map(({ id, name }) => ({
            id,
            name: getAllergenTranslation(name, tFunction) || name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
