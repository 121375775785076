import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Category } from '../../../../../../../../../api/apiTypes/ticketingApiTypes';
import LoaderComponent from '../../../../../../../../../common/Loader/LoaderComponent';
import {
    ErrorMessage,
    GhostPrimaryButton,
    Modal,
    ModalType,
    PrimaryButton,
    SvgIcon,
} from '../../../../../../../../../components';
import { useChangeTypeCategoriesOrder } from '../../../../../../../apiQueries/useTicketingTypeCategory';

const StyledModal = styled(Modal)`
    #modalContentContainer {
        overflow-y: auto;
    }
`;

const Description = styled.p`
    :first-child {
        margin-bottom: 1.714rem;
    }
`;

const CategoriesList = styled.ul`
    margin: 0;
    padding: 0;
`;

const CategoryListItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.571rem 1.142rem;
    list-style: none;
    border: 1px solid #e9ebeb;

    &:not(:last-child) {
        border-bottom: none;
    }
`;

const CategoryName = styled.p`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    font-size: 1.142rem;
    line-height: 1.714rem;
    margin: 0;
`;

const IconsContainer = styled.div`
    display: flex;
    margin-right: 2.142rem;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.78rem;
    overflow-y: auto;
`;

const ArrowButtonUp = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.2rem 0.571rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    svg {
        path {
            fill: #677074;
        }
    }
    :active {
        background-color: #f5f5f5;
    }
    :focus {
        outline: 2px solid var(--primary-color) !important;
        outline-style: auto;
        outline-offset: 2px;
    }
`;

const ArrowButtonDown = styled(ArrowButtonUp)`
    transform: rotate(180deg);
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    &&& * {
        margin-right: 1.142rem;
        :last-child {
            margin-right: 0;
        }
    }
`;

const LoaderWrapper = styled.div`
    height: 3rem;
`;

interface ChangeCategoryOrderModalProps {
    categories: Category[];
    typeId: string;
    onClose(): void;
}

const ChangeCategoryOrderModal = ({ categories, typeId, onClose }: ChangeCategoryOrderModalProps): ReactElement => {
    const [itemList, setItemList] = useState(categories);
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const [changeTypeCategoriesOrder, { isLoading, isError, isSuccess }] = useChangeTypeCategoriesOrder();

    useEffect(() => {
        isSuccess && onClose();
    }, [isSuccess]);

    const moveItem = (direction: 'up' | 'down', index: number) => {
        if (direction === 'up' && index < 1) return;
        if (direction === 'down' && index >= itemList.length - 1) return;

        const categoriesArrayCopy = itemList.slice();
        const currentElement = categoriesArrayCopy[index];

        const offsetCount = direction === 'up' ? index - 1 : index + 1;
        categoriesArrayCopy.splice(index, 1);
        categoriesArrayCopy.splice(offsetCount, 0, currentElement);

        setItemList(categoriesArrayCopy);
    };

    const listIsChanged = () => {
        return JSON.stringify(categories) === JSON.stringify(itemList);
    };

    const handleSave = () => {
        // The reverse index is used here because the endpoint sorts orders from highest index to lower
        const reversedList = [...itemList].reverse();
        const categoriesWithOrder = itemList.map((category, index) => ({
            categoryId: reversedList[index].id,
            orderValue: index,
        }));
        changeTypeCategoriesOrder([typeId, categoriesWithOrder]);
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <StyledModal
            open
            title={t('changeOrder')}
            type={ModalType.Large}
            onClose={() => onClose()}
            bordered
            bottomContent={
                isLoading ? (
                    <LoaderWrapper>
                        <LoaderComponent />
                    </LoaderWrapper>
                ) : (
                    <ButtonsContainer>
                        <GhostPrimaryButton onClick={handleCancel}>{tCommon('cancelButton')}</GhostPrimaryButton>
                        <PrimaryButton disabled={listIsChanged()} onClick={handleSave}>
                            {tCommon('saveButton')}
                        </PrimaryButton>
                    </ButtonsContainer>
                )
            }
        >
            <Container>
                <Description>{t('hereYouCanChangeOrderCategories')}</Description>
                <Description>{t('entrantsSeeFollowingCategories')}</Description>
                <CategoriesList>
                    {itemList.map((category, index) => (
                        <CategoryListItem key={category.id}>
                            <CategoryName>{category.name}</CategoryName>
                            <IconsContainer>
                                <ArrowButtonUp onClick={() => moveItem('up', index)} aria-label={t('moveCategoryUp')}>
                                    <SvgIcon name="ArrowIcon" />
                                </ArrowButtonUp>
                                <ArrowButtonDown
                                    onClick={() => moveItem('down', index)}
                                    aria-label={t('moveCategoryDown')}
                                >
                                    <SvgIcon name="ArrowIcon" />
                                </ArrowButtonDown>
                            </IconsContainer>
                        </CategoryListItem>
                    ))}
                </CategoriesList>
                {isError && <ErrorMessage message={tCommon('tryAgain')} />}
            </Container>
        </StyledModal>
    );
};

export default ChangeCategoryOrderModal;
