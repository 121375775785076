import styled from 'styled-components';
import { Dropdown, GhostPrimaryButton, PrimaryButton } from '../../../../../../components';
import { Header } from '../../../../styles/ShopAtWorkStyles';

export const ProductFormLayoutWrapper = styled.form`
    max-width: 53.857rem;
    width: 100%;
    margin: 0 auto;
`;

export const ProductFormHeader = styled.header`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2.286rem;
`;

export const StyledHeaderTitle = styled(Header)`
    margin: 0;
`;

export const ProductFormHeaderActions = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    gap: 1.714rem;
`;

export const ProductFormHeaderAction = styled.li`
    list-style: none;
`;

export const StyledGhostPrimaryButton = styled(GhostPrimaryButton)`
    height: 100%;
`;

export const DangerPrimaryButton = styled(PrimaryButton)`
    &&& {
        background-color: var(--error-color-dark);
        &:hover,
        &:focus {
            background-color: var(--error-color-light);
        }
        &:active {
            background-color: var(--error-color-dark) !important;
        }
    }
`;

export const FormGroupsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
`;

export const FormColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    min-width: 13.857rem;

    &:nth-child(1) {
        flex: 4;
        width: max(13.857rem, 36rem);
    }

    &:nth-child(2) {
        flex: 1;
    }
`;

export type FormControlsWrapperProps = { direction: 'row' | 'column'; gap?: number };
export const FormControlsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${({ direction }: FormControlsWrapperProps) => direction};
    gap: ${({ gap }: FormControlsWrapperProps) => (gap ? `${gap}rem` : '0.643rem')};
`;

export type FormElementContainerProps = { width?: string };
export const FormElementContainer = styled.div`
    flex: 1;
    max-width: ${({ width }: FormElementContainerProps) => width || '100%'};
`;

export const FormGroup = styled.div``;

export const FormGroupTitle = styled.p`
    font-weight: 700;
    font-size: 1.143rem;
    line-height: 1.714rem;
    color: #000000;
    margin-bottom: 0.571rem;
`;

export const FormGroupDescription = styled.p`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.714rem;
    color: #000000;
    margin-bottom: 1.214rem;
`;

export const StyledDropdown = styled(Dropdown)`
    width: 100%;

    button {
        height: 2.857rem;
        padding: 0.571rem 1.143rem;
    }
`;

export const ModalBottom = styled.div`
    display: flex;
    justify-content: end;
    gap: 1.143rem;
`;

export const ModalBody = styled.div`
    padding: 1.9rem 1.14rem 1.9rem 1.7rem;
`;

export const ModalContent = styled.div`
    font-size: 1rem;
`;
