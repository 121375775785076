import React, { FC, SVGProps } from 'react';
const ChargeIconSvg: FC<{ stroke?: string }> = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={78} height={78} fill="none" {...props}>
        <g
            stroke={props.stroke || '#001EFF'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={1.839}
            clipPath="url(#a)"
        >
            <path d="M53.298 56.208c3.508.716 8.518-1.305 10.776-3.29a7.055 7.055 0 0 0 5.1 2.2 7.045 7.045 0 0 0 7.048-7.048 7.032 7.032 0 0 0-6.658-7.028c-.408-5.645-4.73-10.181-10.26-10.94.02-.234.02-.448.02-.682 0-5.84-4.73-10.59-10.59-10.59-2.064 0-3.991.584-5.626 1.615a13.446 13.446 0 0 0-11.973-7.32c-7.398 0-13.394 5.977-13.433 13.355-4.75 1.538-8.332 5.724-9.072 10.824a9.661 9.661 0 0 0-6.852 9.248 9.654 9.654 0 0 0 9.656 9.656 9.563 9.563 0 0 0 6.132-2.2 13.393 13.393 0 0 0 5.782 1.304" />
            <path d="M39.078 64.875c8.848 0 16.022-7.174 16.022-16.022 0-8.849-7.174-16.022-16.022-16.022-8.849 0-16.022 7.173-16.022 16.022 0 8.848 7.173 16.022 16.022 16.022ZM39.087 57.802V41.955" />
            <path d="m33.578 47.445 5.51-5.49 5.49 5.49" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M.065.065h77.87v77.87H.066z" />
            </clipPath>
        </defs>
    </svg>
);
export default ChargeIconSvg;
