import { OrderStatusOption, ProductStatus, VendorOrderStatusOption } from '../api/apiTypes/shopApiTypes';
import { BadgeType } from '../components/dataDisplay/Badge';

export const getOrderStatusBadgeType = (orderStatus: OrderStatusOption | VendorOrderStatusOption): BadgeType => {
    if (orderStatus === OrderStatusOption.Delivered) return BadgeType.Success;
    if (orderStatus === OrderStatusOption.Canceled) return BadgeType.Failure;
    return BadgeType.Default;
};

export const getProductStatusBadgeType = (productStatus: ProductStatus): BadgeType =>
    ({
        [ProductStatus.Active]: BadgeType.Success,
        [ProductStatus.Disabled]: BadgeType.Default,
    }[productStatus] || BadgeType.Default);
