import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Vendor } from '../../../../api/apiTypes/shopApiTypes';

const Card = styled.div`
    width: 100%;
    padding: 1.142rem;
    border: 1px solid #000000;
    border-radius: 0.357rem;
`;

const CardTitle = styled.h3`
    font-weight: 900;
    font-size: 1.428rem;
    line-height: 2.285rem;
`;

const CardData = styled.div``;

const ItemSubtitle = styled.h4`
    font-weight: 700;
    font-size: 1.142rem;
    line-height: 1.714rem;
    margin: 0;
    margin-right: 0.571rem;
`;
const InformationParagraph = styled.div`
    font-weight: 400;
    font-size: 1.142rem;
    line-height: 1.714rem;
    margin-bottom: 0.571rem;
    display: flex;
`;

interface SellerInformationProps {
    seller?: Vendor;
}

const SellerInformation = ({ seller }: SellerInformationProps): ReactElement => {
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'orderOverview' });
    const sellerAddress: string = seller ? `${seller.street}, ${seller.zipCode} ${seller.city} ` : t('notAvailable');
    return (
        <Card>
            <CardTitle>{t('seller')}</CardTitle>
            <CardData>
                <InformationParagraph>{seller?.name ?? ''}</InformationParagraph>
                <InformationParagraph>
                    <ItemSubtitle>{t('organizationNumber')}: </ItemSubtitle>
                    {seller?.organizationNumber ?? t('notAvailable')}
                </InformationParagraph>
                <InformationParagraph>
                    <ItemSubtitle>{t('organizationAdresse')}: </ItemSubtitle>
                    {sellerAddress}
                </InformationParagraph>
            </CardData>
        </Card>
    );
};

export default SellerInformation;
