import { ComponentProps, ReactElement } from 'react';
import { Button, Icon, Label, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { useNotification } from '../../context';
import { useGetUnseenNotificationsCount, useMarkAllSentNotificationsAsSeen } from '../../hooks/useNotifications';

const StyledLoader = styled(Loader)`
    && {
        z-index: 1;
        width: '20.5px';
    }
`;

interface NotificationsTriggerProps {
    spaceRight?: number;
    onClick?: () => void;
    props?: ComponentProps<typeof Button>;
}

const NotificationsTrigger = ({ onClick, spaceRight = 5, ...props }: NotificationsTriggerProps): ReactElement => {
    const { isLoadingNotifications, errorLoadingNotifications } = useNotification();
    const { data: unseenNotificationsCount = 0 } = useGetUnseenNotificationsCount();
    const [markAllSentNotificationsAsSeen] = useMarkAllSentNotificationsAsSeen();

    const onClickButtonHandler = () => {
        if (onClick) {
            onClick();
            markAllSentNotificationsAsSeen();
        }
    };

    return (
        <Button
            {...props}
            icon
            style={{
                marginRight: `calc(var(--spacer) * ${spaceRight})`,
                backgroundColor: '#fff',
                position: 'relative',
                height: '2.7em', // dependant on Navbar height, used to position popup
            }}
            aria-label="Show notifications"
            onClick={onClickButtonHandler}
        >
            {isLoadingNotifications ? (
                <StyledLoader active size="small" inline />
            ) : errorLoadingNotifications ? (
                <Icon circular size="small" name="times" color="red" inverted />
            ) : (
                <>
                    <Icon name="bell" />
                    {unseenNotificationsCount > 0 ? (
                        <Label
                            color="red"
                            circular
                            size="tiny"
                            attached="top right"
                            aria-label={'unread notifications'}
                        >
                            {unseenNotificationsCount}
                        </Label>
                    ) : null}
                </>
            )}
        </Button>
    );
};

export default NotificationsTrigger;
