import { KeyboardEvent, PropsWithChildren, ReactElement, MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { SvgIcon } from '../index';

type InputWrapperStateProps = {
    isError?: boolean;
    isExpandable?: boolean;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.286rem;
`;

const Wrapper = styled.div`
    padding: 0.571rem 0.857rem;
    max-height: 8.143rem;
    overflow: auto;
    border: ${({ isError }: InputWrapperStateProps) =>
        isError ? '1px solid var(--error-color)' : '1px solid #d4d6d7'};
    border-radius: 0.357rem;
    display: flex;
    flex-wrap: ${({ isExpandable }: InputWrapperStateProps) => (isExpandable ? 'nowrap' : 'wrap')};
    gap: 4px;
    cursor: ${({ isExpandable }: InputWrapperStateProps) => (isExpandable ? 'pointer' : 'auto')};
    position: relative;
    ${({ isExpandable }: InputWrapperStateProps) =>
        isExpandable &&
        css`
            :focus,
            :focus-visible {
                outline: 1px solid var(--primary-color);
            }

            :focus:not(:focus-visible) {
                outline: 0;
            }
        `}
`;

const Label = styled.p`
    font-size: 0.857rem;
    line-height: 1.143rem;
    color: ${({ isError }: InputWrapperStateProps) =>
        isError ? 'var(--error-color)' : 'var(--text-placeholder-color)'};
    margin: 0;
`;

type ExpandButtonProps = { isExpanded?: boolean };
const ExpandButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    gap: 1.143rem;

    svg {
        transform: ${({ isExpanded }: ExpandButtonProps) => (isExpanded ? 'rotate(180deg)' : 'none')};
        transition: transform 200ms;
    }
`;

const Separator = styled.div`
    width: 1px;
    height: 100%;
    background-color: #d4d6d7;
`;

interface InputWrapperProps {
    label: string;
    description?: string;
    error?: string;
    className?: string;
    expanded?: boolean;
    setExpanded?: () => void;
}

const InputWrapper = ({
    label,
    description,
    error,
    className,
    children,
    setExpanded,
    expanded,
}: PropsWithChildren<InputWrapperProps>): ReactElement => {
    const handleOnClick = (event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setExpanded && setExpanded();
    };

    const onKeyPressHandler = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') handleOnClick(e);
    };

    return (
        <Container className={className}>
            <Label isError={!!error}>{label}</Label>
            <Wrapper
                tabIndex={!!setExpanded ? 0 : undefined}
                onClick={handleOnClick}
                onKeyPress={onKeyPressHandler}
                isExpandable={!!setExpanded}
                isError={!!error}
            >
                {children}
                {setExpanded && (
                    <ExpandButton isExpanded={expanded}>
                        <Separator />
                        <SvgIcon name="CaretDown" />
                    </ExpandButton>
                )}
            </Wrapper>
            {description && <Label isError={!!error}>{description}</Label>}
        </Container>
    );
};

export default InputWrapper;
