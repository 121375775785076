import { ChangeEvent, ReactElement, SyntheticEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { MobileViewBreakpoint } from '../../../../../common/ScreenSizeBreakPoints';
import { getComputedCssProperty } from '../../../../../utility';

const NameInput = styled.input`
    border: none;
    background: none;
    outline: none;
    font-size: 1.1rem;
    color: var(--text-high-emphasis-color);
    margin: 0;
    text-align: left;
    font-weight: normal;

    :focus {
        background: ${getComputedCssProperty('--primary-color-light') + '1f'};
        font-weight: bold;

        @media (max-width: ${MobileViewBreakpoint}px) {
            font-size: 1rem;
        }
    }
`;

interface NameInputHandlerProps {
    name: string;
    onSave(newName: string): void;
    onClick?: () => void;
    inputRef: React.RefObject<HTMLInputElement>;
    focusId?: string;
    setFocusId?: (isFocus: string) => void;
    inputId?: string;
    isEditable?: boolean;
}

const delay = 200;
const NameInputHandler = ({
    name,
    onClick,
    onSave,
    inputRef,
    focusId,
    setFocusId,
    inputId,
    isEditable = true,
}: NameInputHandlerProps): ReactElement => {
    const timer = useRef<number>(0);
    const prevent = useRef<boolean>(false);
    const [value, setValue] = useState(name);
    const [isDoubleClicked, setIsDoubleClicked] = useState(false);

    const handleClick = (e: SyntheticEvent) => {
        if (isEditable) {
            e.stopPropagation();
        }
        timer.current = window.setTimeout(function () {
            if (!prevent.current && onClick) {
                onClick();
            }
            prevent.current = false;
        }, delay);
    };
    const handleDoubleClick = (e: SyntheticEvent) => {
        e.preventDefault();
        clearTimeout(timer.current);
        prevent.current = true;
        setIsDoubleClicked(true);
        setTimeout(() => {
            inputRef.current?.focus();
        }, 50);
    };

    const handleSave = (newValue: string) => {
        if (newValue && name !== newValue) {
            onSave(newValue);
            setFocusId && setFocusId('');
        } else {
            setValue(name);
        }
    };

    const handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        setIsDoubleClicked(false);
        handleSave(newValue);
        setFocusId && setFocusId('');
    };

    const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const newValue = e.currentTarget.value;
            handleSave(newValue);
        } else if (e.key === ' ' && inputRef.current) {
            setValue((curr) => {
                const min = Math.min(inputRef.current?.selectionStart ?? 0, inputRef.current?.selectionEnd ?? 0);
                const max = Math.max(inputRef.current?.selectionStart ?? 0, inputRef.current?.selectionEnd ?? 0);
                return curr.substring(0, min) + ' ' + curr.substring(max);
            });
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
    };

    useEffect(() => {
        if (focusId && focusId === inputId && inputRef.current) inputRef.current.focus();
        if (!focusId && inputRef.current) inputRef.current.blur();
    }, [focusId, inputId]);

    const commonProps = {
        ref: inputRef,
        onClick: handleClick,
        onDoubleClick: handleDoubleClick,
        onFocus: handleDoubleClick,
    };
    if (isEditable && (isDoubleClicked || focusId === inputId)) {
        return (
            <>
                <NameInput
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeydown}
                    {...commonProps}
                />
            </>
        );
    } else {
        return (
            <>
                <NameInput {...commonProps} as={'h5'}>
                    {value}
                </NameInput>
            </>
        );
    }
};

export default NameInputHandler;
