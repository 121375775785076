import { EditorState, ContentState, Modifier, SelectionState } from 'draft-js';

const removeSelectionStyle = (editorState: EditorState): ContentState => {
    const content = editorState.getCurrentContent();
    return Modifier.removeInlineStyle(
        editorState.getCurrentContent(),
        new SelectionState({
            anchorKey: content.getFirstBlock().getKey(),
            anchorOffset: 0,
            focusKey: content.getLastBlock().getKey(),
            focusOffset: content.getLastBlock().getLength(),
            hasFocus: true,
            isBackward: false,
        }),
        'SELECTION-TEXT-COLOR',
    );
};

export default removeSelectionStyle;
