import { ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ShimmerAnimation } from '../../../../../components';
import { PurchasesColumns } from '../utils';
import { ColumnType } from './PurchasesTable';

const greyColorLight = '#e4e4e4';
const greyColor = '#c4c4c4';

const fadeOut = keyframes`
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
`;

type WrapperProps = { show?: boolean };
const Wrapper = styled.div`
    position: relative;
    padding: 0 0.5rem 1rem 0.5rem;
    animation: ${({ show }: WrapperProps) =>
        show
            ? 'none;'
            : css`
                  ${fadeOut} 0.1s linear forwards;
              `};
`;

type ContainerProps = { collapse?: boolean };
const Container = styled.div`
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    box-shadow: inset 0 0 0 1px #d4d6d7;
    padding: 0.714rem 1rem;

    ${({ collapse }: ContainerProps) =>
        collapse && {
            marginTop: '1rem',
            display: 'grid',
            justifyContent: 'unset',
            height: '18.5rem',
            gridTemplateAreas: `'${PurchasesColumns.OrderNumber} ${PurchasesColumns.Status}'
                '${PurchasesColumns.Created} ${PurchasesColumns.Status}'
                '${PurchasesColumns.Items} ${PurchasesColumns.Total}'
                '${PurchasesColumns.Payment} ${PurchasesColumns.Payment}'
                '${PurchasesColumns.Employee} ${PurchasesColumns.Employee}'
                '${PurchasesColumns.Company} ${PurchasesColumns.Company}'`,
            gridTemplateColumns: 'auto 8rem',
            gridAutoRows: 'auto 3rem 7.5rem auto auto',
        }}
`;

const PurchasesWrapper = styled.div`
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const ItemContainer = styled.div`
    display: contents;
    align-items: center;
    margin-bottom: 1rem;
`;

const SkeletonItem = styled.div`
    height: 1.285rem;
    background-color: ${greyColorLight};
    border-radius: 0.357rem;
`;

type StatusProps = { collapse: boolean };
const Status = styled.div`
    width: 15%;
    min-width: 4.286rem;
    background-color: ${greyColor};
    height: 1.714rem;
    border-radius: 2.143rem;
    grid-area: ${PurchasesColumns.Status};
    justify-self: flex-end;

    ${({ collapse }: StatusProps) =>
        collapse && {
            alignSelf: 'flex-start',
        }}
`;

type OrderNumberProps = { collapse: boolean };
const OrderNumber = styled(SkeletonItem)`
    width: ${({ collapse }: OrderNumberProps) => (collapse ? '50%' : '10%')};
    grid-area: ${PurchasesColumns.OrderNumber};

    ${({ collapse }: OrderNumberProps) =>
        collapse && {
            alignSelf: 'flex-end',
        }}
`;

type CreatedDateProps = { collapse: boolean };
const CreatedDate = styled(SkeletonItem)`
    width: ${({ collapse }: CreatedDateProps) => (collapse ? '70%' : '15%')};
    grid-area: ${PurchasesColumns.Created};

    ${({ collapse }: CreatedDateProps) =>
        collapse && {
            alignSelf: 'flex-start',
        }}
`;

type PaymentProps = { collapse: boolean };
const Payment = styled(SkeletonItem)`
    width: ${({ collapse }: PaymentProps) => (collapse ? '40%' : '20%')};
    grid-area: ${PurchasesColumns.Payment};
`;

const Employee = styled(Payment)`
    grid-area: ${PurchasesColumns.Employee};
`;

const Company = styled(Payment)`
    grid-area: ${PurchasesColumns.Company};
`;

type ItemsContainerProps = { collapse: boolean };
const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    width: ${({ collapse }: ItemsContainerProps) => (collapse ? '200%' : '30%')};
    grid-area: ${PurchasesColumns.Items};
`;

const Item = styled(SkeletonItem)`
    background-color: ${greyColor};
    grid-area: ${PurchasesColumns.Items};
`;

const ItemLong = styled(Item)`
    width: 50%;
`;

const ItemShort = styled(Item)`
    width: 40%;
`;

type TotalProps = { collapse: boolean };
const Total = styled(SkeletonItem)`
    width: ${({ collapse }: TotalProps) => (collapse ? '60%' : '10%')};
    grid-area: ${PurchasesColumns.Total};

    ${({ collapse }: TotalProps) =>
        collapse && {
            justifySelf: 'flex-end',
        }}
`;

interface PurchasesSkeletonLoaderProps {
    show?: boolean;
    numberOfItems?: number;
    collapse?: boolean;
    columns: ColumnType;
}

const PurchasesSkeletonLoader = ({
    show,
    collapse = false,
    numberOfItems = 1,
    columns,
}: PurchasesSkeletonLoaderProps): ReactElement => {
    const viewColumns = columns.map((column) => column.key);
    const item = (index: number) => (
        <Wrapper show={show} key={index}>
            <Container collapse={collapse}>
                <ItemContainer>
                    {viewColumns.includes(PurchasesColumns.Status) && <Status collapse={collapse} />}
                </ItemContainer>
                <ItemContainer>
                    {viewColumns.includes(PurchasesColumns.OrderNumber) && <OrderNumber collapse={collapse} />}
                </ItemContainer>
                <ItemContainer>
                    {viewColumns.includes(PurchasesColumns.Created) && <CreatedDate collapse={collapse} />}
                </ItemContainer>
                <ItemContainer>
                    {viewColumns.includes(PurchasesColumns.Payment) && <Payment collapse={collapse} />}
                </ItemContainer>
                <ItemContainer>
                    {viewColumns.includes(PurchasesColumns.Employee) && <Employee collapse={collapse} />}
                </ItemContainer>
                <ItemContainer>
                    {viewColumns.includes(PurchasesColumns.Company) && <Company collapse={collapse} />}
                </ItemContainer>
                {viewColumns.includes(PurchasesColumns.Items) ? (
                    <ItemsContainer collapse={collapse}>
                        <ItemLong />
                        <ItemShort />
                        <ItemLong />
                    </ItemsContainer>
                ) : null}
                <ItemContainer>
                    {viewColumns.includes(PurchasesColumns.Total) && <Total collapse={collapse} />}
                </ItemContainer>
            </Container>
            <ShimmerAnimation />
        </Wrapper>
    );
    const items = Array.from({ length: numberOfItems }, (_, index) => item(index));
    return <PurchasesWrapper>{items}</PurchasesWrapper>;
};

export default PurchasesSkeletonLoader;
