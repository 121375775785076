import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootStateProps } from '../../../reducers';
import { Service } from '../../common/lifeAtWorkServices';

const useServiceIsActive = (service: Service): boolean => {
    const services = useSelector<IRootStateProps, Service[]>((state) => state.app.services);

    return useMemo(() => services.includes(service), [service, services]);
};

export default useServiceIsActive;
