import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PrimaryButton, SvgIcon } from '../../../../components';
import WifiPasswordModal, {
    WifiPasswordModalType,
} from '../../../../modules/WifiAtWork/SetPasswordTooltip/components/WifiAtWorkSetPasswordFormModal';

const Container = styled.div`
    background-color: var(--background-color);
    padding: 0.5rem;
    width: 100%;
    height: 100%;
`;
const StyledButton = styled(PrimaryButton)``;

const ButtonText = styled.span`
    font-weight: 700;
    font-size: 1.142rem;
    line-height: 1.714rem;
    margin-left: 0.571rem;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1.714rem 1.142rem;
`;

const StyledTitle = styled.p`
    font-weight: 700;
    font-size: 1.142rem;
    line-height: 1.714rem;
`;

const PasswordComponent = (): ReactElement => {
    const [showChangeWifiPasswordModal, setShowChangeWifiPasswordModal] = useState(false);
    const { t } = useTranslation('myProfile');
    return (
        <Container>
            <ContentContainer>
                <StyledTitle>{t('managePasswords')}</StyledTitle>
                <StyledButton onClick={() => setShowChangeWifiPasswordModal(true)}>
                    <SvgIcon name="KeyIcon" />
                    <ButtonText>{t('changeWiFiAtWorkPassword')}</ButtonText>
                </StyledButton>
            </ContentContainer>
            {/*  TODO check if user has installed wifi@work password */}
            {showChangeWifiPasswordModal && (
                <WifiPasswordModal
                    open={showChangeWifiPasswordModal}
                    onClose={() => setShowChangeWifiPasswordModal(false)}
                    modalType={WifiPasswordModalType.ChangePassword}
                />
            )}
        </Container>
    );
};

export default PasswordComponent;
