import { useDispatch } from 'react-redux';
import { sidebarOperations } from '../../common/Sidebar/duck';
import { useNavigation } from '../../utility';
import { useIsMobile } from '../../utility/hooks/useIsMobile';

interface UseArticlesNavigationReturnType {
    houseOfOsloLink: string;
    goToHouseOfOsloPage(): void;
}

const useHouseOfOsloNavigation = (): UseArticlesNavigationReturnType => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const houseOfOsloLink = '/MeetingHouseOfOslo';

    const onNavigate = (url: string, shouldCloseSideBar = true) => {
        if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
        navigation.push(url);
    };
    return {
        houseOfOsloLink,
        goToHouseOfOsloPage: () => onNavigate(houseOfOsloLink, false),
    };
};

export default useHouseOfOsloNavigation;
