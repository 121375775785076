import { RefObject, useEffect } from 'react';

const useKeyboardOutsideFocusDetection = (
    ref: RefObject<HTMLElement>,
    handler: (event: KeyboardEvent) => void,
    disabled = false,
): void => {
    useEffect(() => {
        if (!disabled) {
            const listener = (event: KeyboardEvent) => {
                if (!ref.current || ref.current.contains(event.target as Node)) return;
                handler(event);
            };
            document.addEventListener('keyup', listener);
            return () => {
                document.removeEventListener('keyup', listener);
            };
        }
    }, [ref, handler, disabled]);
};

export default useKeyboardOutsideFocusDetection;
