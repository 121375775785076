import { KeyboardEvent, ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SvgIcon } from '../..';
import useKeyboardOutsideFocusDetection from '../../../utility/hooks/useKeyboardOutsideFocusDetection';
import useOnMouseClickOutsideElement from '../../../utility/hooks/useMouseClickOutsideElement';
import StyledSelect from '../StyledSelect';
import { GroupedOptionType, LayoutPosition, OptionType } from './types';
import { Position, useFitPosition } from './useFitPosition';

export enum FilterView {
    Desktop = 'desktop',
    Mobile = 'mobile',
}
interface SelectWrapperType {
    position: Position;
    view: FilterView;
}

interface ArrowContainerType {
    isOpen: boolean;
    isGroupHeadingArrow?: boolean;
}

type ContainerProps = {
    view: FilterView;
};

const Container = styled.div`
    position: relative;
    display: ${({ view }: ContainerProps) => (view === FilterView.Desktop ? 'inline-flex' : 'flex')};
    flex-direction: column;
`;

const SelectWrapper = styled.div`
    position: ${({ view }: SelectWrapperType) => (view === FilterView.Desktop ? 'absolute' : 'static')};
    z-index: 1000;
    top: 2.857rem;
    right: ${({ position }: SelectWrapperType) => (position === Position.Right ? '0' : 'auto')};
`;

const ButtonControlsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface ButtonWrapperProps {
    view: FilterView;
}
const ButtonWrapper = styled.div`
    cursor: pointer;
    background-color: var(--primary-on-color);
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    height: 2.857rem;
    display: flex;
    align-items: center;
    justify-content: ${({ view }: ButtonWrapperProps) => (view === FilterView.Mobile ? 'center' : 'space-between')};
    gap: 0.571rem;
    width: 100%;
    padding: ${({ view }: ButtonWrapperProps) => (view === FilterView.Mobile ? '0.857rem' : '0 1.143rem;')};

    :focus-visible {
        outline: 2px solid var(--primary-color);
        outline-offset: 2px;
        border-radius: 5px;
    }
`;

const ButtonTextWrapper = styled.div`
    display: flex;
    min-width: 0;
`;

const ButtonText = styled.p`
    font-weight: 700;
    font-size: 1.143rem;
    line-height: 1.714rem;
    color: var(--primary-color);
    user-select: none;
    margin: 0;

    &:first-child {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 0.357rem;
    }
`;

const ArrowButtonIcon = styled.div`
    display: inline-flex;
    margin-left: ${({ isGroupHeadingArrow }: ArrowContainerType) => (isGroupHeadingArrow ? 'auto' : '0')};
    padding-left: ${({ isGroupHeadingArrow }: ArrowContainerType) => (isGroupHeadingArrow ? '0.357rem' : '0')};

    svg {
        width: 0.857rem;
        height: 0.857rem;
        transform: ${({ isOpen }: ArrowContainerType) => (isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};

        path {
            fill: var(--primary-color);
        }
    }
`;

const ResetButtonIcon = styled.div`
    display: inline-flex;
    margin-right: 0.571rem;

    svg {
        width: 0.714rem;
        height: 0.714rem;

        path {
            fill: var(--primary-color);
        }
    }

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
        outline-offset: 3px;
        outline-style: auto;
    }
`;

interface SearchSelectType {
    onChange: (options: OptionType[]) => void;
    isSearchable?: boolean;
    value: OptionType[];
    name: string;
    title: string | string[];
    onResetOptions: () => void;
    options: OptionType[] | GroupedOptionType[];
    isLoading?: boolean;
    placeholder?: string;
    className?: string;
    autoMenuPosition?: boolean;
    noOptionsMessage?: string;
    expanded?: boolean;
    onClose?(): void;
    view?: FilterView;
}

const SearchSelect = ({
    onChange,
    isSearchable = true,
    value,
    name,
    title,
    onResetOptions,
    options,
    isLoading,
    placeholder = 'Søk',
    className,
    autoMenuPosition = false,
    noOptionsMessage = 'Ingen alternativer',
    expanded = false,
    onClose,
    view = FilterView.Desktop,
}: SearchSelectType): ReactElement => {
    const [searchValue, setSearchValue] = useState('');
    const [isSelectVisible, setIsSelectVisible] = useState(expanded);
    const containerRef = useRef<HTMLDivElement>(null);
    const selectWrapperRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<any>(null);
    const fitPosition = useFitPosition(selectWrapperRef, isSelectVisible);
    const resetFilter = () => {
        setIsSelectVisible(false);
        setSearchValue('');
    };

    const isDesktop = view === FilterView.Desktop;
    useOnMouseClickOutsideElement(containerRef, resetFilter, !isDesktop);
    useKeyboardOutsideFocusDetection(containerRef, resetFilter, !isDesktop);

    const onInputChange = (text: string) => {
        setSearchValue(text);
    };

    const handleKeyControl = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Tab' && view !== FilterView.Mobile) setIsSelectVisible((p) => !p);
    };

    useEffect(() => {
        if (isSelectVisible) selectRef?.current?.focus();
    }, [isSelectVisible]);

    const handleButtonClick = () => {
        if (view === 'mobile') {
            onClose && onClose();
            return;
        }
        setIsSelectVisible((p) => !p);
    };

    return (
        <Container ref={containerRef} className={className} view={view}>
            <ButtonWrapper view={view} tabIndex={0} onKeyUp={handleKeyControl} onClick={handleButtonClick}>
                <ButtonTextWrapper>
                    <ButtonText>{title}</ButtonText>
                    <ButtonText>{value.length ? `(${value.length})` : null}</ButtonText>
                </ButtonTextWrapper>
                <ButtonControlsWrapper>
                    {value.length && view === FilterView.Desktop ? (
                        <ResetButtonIcon
                            onClick={(evt) => {
                                evt.stopPropagation();
                                onResetOptions();
                            }}
                            onKeyUp={(event: KeyboardEvent<HTMLElement>) => {
                                if (event.key === 'Enter') onResetOptions();
                            }}
                            tabIndex={0}
                        >
                            <SvgIcon name="CloseIcon" />
                        </ResetButtonIcon>
                    ) : null}
                    {view === 'desktop' && (
                        <ArrowButtonIcon isOpen={isSelectVisible}>
                            <SvgIcon name="ArrowPrimary" alt="arrow" />
                        </ArrowButtonIcon>
                    )}
                </ButtonControlsWrapper>
            </ButtonWrapper>
            {isSelectVisible && (
                <SelectWrapper
                    view={view}
                    ref={selectWrapperRef}
                    position={autoMenuPosition ? fitPosition : Position.Left}
                >
                    <StyledSelect
                        ref={selectRef}
                        isLoading={isLoading}
                        name={name}
                        defaultMenuIsOpen
                        onChange={(options) => onChange(options as OptionType[])}
                        placeholder={placeholder}
                        menuIsOpen
                        closeMenuOnSelect={false}
                        isMulti
                        options={options}
                        hideSelectedOptions={false}
                        isSearchable={isSearchable}
                        searchValue={searchValue}
                        setSearchValue={onInputChange}
                        controlShouldRenderValue={false}
                        backspaceRemovesValue={false}
                        value={value}
                        noOptionsMessage={noOptionsMessage}
                        allSelectable
                        positionMenu={view === FilterView.Mobile ? LayoutPosition.Static : LayoutPosition.Absolute}
                    />
                </SelectWrapper>
            )}
        </Container>
    );
};

export default SearchSelect;
