import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexBox, Typography } from '../../../../../components/styleds';
import { formatDateToOrderDetails } from '../../../helpers';
import OrderCard from '../components/OrderCard';
import OrderSectionItem from '../components/OrderSectionItem';
import { OrderDetailsDeliverySectionProps } from './types';

const DeliverySection: FC<OrderDetailsDeliverySectionProps> = ({ isLoadingOrder, order }) => {
    const { t } = useTranslation('Food', { keyPrefix: 'foodForMeeting' });
    const isDelivery = useMemo(() => Boolean(order?.deliveryLocation), [order]);

    return (
        <OrderCard title={t('foodOrderOrderDeliverySectionTitle')} isSkeleton={isLoadingOrder}>
            <FlexBox dir={'column'} gap={'1.143rem'}>
                <FlexBox dir={'column'} gap={'0.571rem'}>
                    <OrderSectionItem
                        label={t('foodOrderOrderDeliverySectionTimeOfDelivery')}
                        value={formatDateToOrderDetails(order?.deliveryDateTime)}
                    />
                    <OrderSectionItem
                        label={t('foodOrderOrderDeliverySectionDeliveryMethod')}
                        value={t(
                            isDelivery
                                ? 'foodOrderOrderDeliverySectionDeliveryMethodValue'
                                : 'foodOrderOrderDeliverySectionPickUpMethodValue',
                        )}
                    />
                    <OrderSectionItem
                        label={t('foodOrderOrderDeliverySectionDeliveryLocation')}
                        value={order?.deliveryLocation}
                    />
                    <OrderSectionItem
                        label={t('foodOrderOrderDeliverySectionPickUpLocation')}
                        value={order?.pickupLocation}
                    />
                </FlexBox>

                {isDelivery && (
                    <FlexBox dir={'column'} gap={'1.142rem'}>
                        <FlexBox height={'0.071rem'} background={'#D4D6D7'} width={'100%'} />

                        <FlexBox dir={'column'} gap={'0.571rem'}>
                            <OrderSectionItem
                                label={t('foodOrderOrderDeliverySectionTableSetUp')}
                                value={
                                    order?.attendees
                                        ? t('foodOrderOrderDeliverySectionTableSetUpValue', {
                                              amount: order?.attendees,
                                          })
                                        : ''
                                }
                            />
                            <OrderSectionItem
                                label={t('foodOrderOrderDeliverySectionTimeForCleanup')}
                                value={formatDateToOrderDetails(order?.returnDateTime)}
                            />
                        </FlexBox>
                    </FlexBox>
                )}
                {!!order?.comment && (
                    <FlexBox dir={'column'} gap={'1.143rem'}>
                        <FlexBox height={'0.071rem'} background={'#D4D6D7'} width={'100%'} />

                        <FlexBox dir={'column'} gap={'0.286rem'}>
                            <Typography
                                color={'#000'}
                                letterSpacing={'0.071rem'}
                                textTransform={'uppercase'}
                                fontSize={'0.857rem'}
                                lineHeight={'1.429rem'}
                                fontWeight={400}
                            >
                                {t('foodOrderOrderDeliverySectionDeliveryCommentsTitle')}
                            </Typography>
                            <FlexBox pd={'0.857rem 1.143rem'} background={'#E1F6FF'} br={'0.571rem'}>
                                <Typography
                                    color={'#263238'}
                                    fontStyle={'italic'}
                                    lineHeight={'1.714rem'}
                                    fontWeight={400}
                                    fontSize={'1.143rem'}
                                >
                                    {`"${order?.comment}"`}
                                </Typography>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                )}
            </FlexBox>
        </OrderCard>
    );
};

export default DeliverySection;
