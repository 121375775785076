import { PropsWithChildren, ReactElement } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import SvgIcon from '../icons/SvgIcon';

const Container = styled.div`
    background-color: var(--warning-color-light);
    color: var(--warning-on-color-light);
    border-radius: 5px;
`;

const ContentWrapper = styled.div`
    padding: 0.857rem;
    display: flex;
    flex-grow: 1;
    align-items: center;
    align-self: flex-start;
    gap: 0.571rem;
`;

const WarningIcon = styled(Icon)`
    color: #d2b100;
    align-self: flex-start;
`;

const CloseButton = styled.button`
    border: none;
    background-color: unset;
    align-self: start;
    path {
        fill: black;
    }
`;

const Text = styled.p`
    flex: 1;
    margin: 0;
`;
interface WarningMessageProps {
    message?: string;
    onClose?(): void;
}

const WarningMessage = ({ message, children, onClose }: PropsWithChildren<WarningMessageProps>): ReactElement => {
    return (
        <Container>
            <ContentWrapper>
                <WarningIcon name="info circle" />
                {message ? <Text>{message}</Text> : children}
                {onClose && (
                    <CloseButton onClick={onClose}>
                        <SvgIcon name="CloseIcon" />
                    </CloseButton>
                )}
            </ContentWrapper>
        </Container>
    );
};

export default WarningMessage;
