import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SvgIcon } from '../../../../../../../../components';

const Container = styled.div`
    text-align: center;
`;

const SolvedButton = styled.div`
    display: flex;
    align-items: center;
    background-color: var(--surface-color-light);
    width: 100%;
    margin: 1.143rem 0 0.786rem;
    border: 1px solid var(--success-color-dark);
    border-radius: 5px;
    padding: 0.571rem;
`;

const DescriptionContainer = styled.div`
    display: flex;
    padding: 0 0.857rem;
`;

const Description = styled.p`
    font-size: 1rem;
    line-height: 1.714rem;
    font-weight: 300;
    padding-left: 0.786rem;
    margin: 0;
`;

const HorizontalRule = styled.hr`
    flex-grow: 1;
    border: 1px solid #0000001e;
`;

const TicketSolvedInfo = styled.p`
    font-size: 0.857rem;
    line-height: 1.143rem;
`;

const TicketSolvedComment = (): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    return (
        <Container>
            <SolvedButton>
                <HorizontalRule />
                <DescriptionContainer>
                    <SvgIcon name="CheckCircleIcon" />
                    <Description>{t('solvedComponentDescription')}</Description>
                </DescriptionContainer>
                <HorizontalRule />
            </SolvedButton>
            <TicketSolvedInfo>{t('solvedComponentInfo')}</TicketSolvedInfo>
        </Container>
    );
};

export default TicketSolvedComment;
