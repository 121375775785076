import { lazy } from 'react';

export default (resolver, name = 'Placeholder') => {
    return lazy(async () => {
        const resolved = await resolver();
        if (resolved[name]) {
            return { default: resolved[name] };
        } else {
            const defaultSubModule = Object.keys(resolved)[0];
            return { default: resolved[defaultSubModule] };
        }
    });
};
