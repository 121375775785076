import { ReactElement } from 'react';
import styled from 'styled-components';

const Picture = styled.picture`
    min-height: 10rem;
`;

const Header = styled.h4`
    font-size: 1.57rem;
    color: #475156;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 2.5rem;
    text-align: center;
`;

const Description = styled.div`
    text-align: center;
    font-size: 1.17rem;
    margin-bottom: 5rem;
`;

interface FailureDisplayProps {
    header?: string;
    description?: string;
    alt: string;
}
const FailureDisplay = ({ header, description, alt }: FailureDisplayProps): ReactElement => {
    return (
        <>
            <Picture>
                <source srcSet="./empty-icon.webp" type="image/webp" />
                <source srcSet="./empty-icon.png" type="image/png" />
                <img src="empty-icon.png" alt={alt} />
            </Picture>
            {header && <Header>{header}</Header>}
            {description && <Description>{description}</Description>}
        </>
    );
};

export default FailureDisplay;
