import type { Dispatch } from 'redux';
import { Module } from '../app/api/apiTypes/portalApiTypes';
import { getPrivacyPolicyStatus, getAppContext } from '../app/api/portalApi';
import { LanguagesEnum } from '../app/utility/languageUtilities/language.enum';
import { Creators } from './actions';

const handleMessageAction = Creators.appHandleMessage;
const removeMessageAction = Creators.appRemoveMessage;
const requestAppContextAction = Creators.appRequestAppContext;
const receiveAppContextAction = Creators.appReceiveAppContext;
const setSelectedModuleAction = Creators.appSetSelectedModule;
const setSelectedSubModuleAction = Creators.appSetSelectedSubModule;
const setDeviceTypeAction = Creators.appSetDeviceType;
const setSelectedLangAction = Creators.appSetSelectedLang;
const addNavigationPathElementAction = Creators.appAddNavigationPathElement;
const setNavigationPathAction = Creators.appSetNavigationPath;
const setShowReturnButtonAction = Creators.appSetShowReturnButton;

const fetchAppContext = (): ((dispatch: Dispatch) => Promise<void>) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(requestAppContextAction());
        try {
            const result = await getAppContext();
            if (result.status === 200) {
                const appContext = result.data;
                dispatch(receiveAppContextAction({ ...appContext }));
            } else {
                dispatch(handleMessageAction('Failed to fetch app contexts'));
            }
        } catch (e: unknown) {
            dispatch(handleMessageAction((e as Error).message));
        }
    };
};

const setSelectedModule = (module?: Module) => {
    return (dispatch: Dispatch): void => {
        dispatch(setSelectedModuleAction(module));
    };
};

const setSelectedSubModule = (subModule: Module) => {
    return (dispatch: Dispatch): void => {
        dispatch(setSelectedSubModuleAction(subModule));
    };
};

const setDeviceType = (deviceType: string) => {
    return (dispatch: Dispatch): void => {
        dispatch(setDeviceTypeAction(deviceType));
    };
};

const setSelectedLang = (lang: LanguagesEnum) => {
    return (dispatch: Dispatch): void => {
        dispatch(setSelectedLangAction(lang));
    };
};

const removeMessage = (guid: string) => {
    return (dispatch: Dispatch): void => {
        dispatch(removeMessageAction(guid));
    };
};

const addNavigationPathElement = (element: string) => {
    return (dispatch: Dispatch): void => {
        dispatch(addNavigationPathElementAction(element));
    };
};

const setNavigationPath = (path: string) => {
    return (dispatch: Dispatch): void => {
        dispatch(setNavigationPathAction(path));
    };
};

const setShowReturnButton = (show: boolean) => {
    return (dispatch: Dispatch): void => {
        dispatch(setShowReturnButtonAction(show));
    };
};

const getPrivacyPolicy = async (dispatch: Dispatch): Promise<void> => {
    try {
        dispatch({ type: 'FETCH_PRIVACY_POLICY_REQUESTED', payload: null });
        const response = await getPrivacyPolicyStatus();
        dispatch({
            type: 'FETCH_PRIVACY_POLICY_SUCCEEDED',
            payload: {
                ...response.data,
                approvedDate: response.data.statuses.find(({ status }) => status === 'Approved')?.statusDateTime,
            },
        });
    } catch (err) {
        dispatch({
            type: 'FETCH_PRIVACY_POLICY_FAILED',
            payload: err,
        });
    }
};

export default {
    removeMessage,
    fetchAppContext,
    setSelectedModule,
    setSelectedSubModule,
    setDeviceType,
    setSelectedLang,
    addNavigationPathElement,
    setNavigationPath,
    setShowReturnButton,
    getPrivacyPolicy,
};
