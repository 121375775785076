import { ReactElement } from 'react';
import { Button } from 'semantic-ui-react';
import type { ButtonProps } from 'semantic-ui-react';
import styled from 'styled-components';

const primaryColor = 'var(--primary-color)';
const primaryOnColor = 'var(--primary-on-color)';
const primaryColorLight = 'var(--primary-color-light)';
const primaryOnColorLight = 'var(--primary-on-color-light)';
const primaryColorDark = 'var(--primary-color-dark)';
const primaryOnColorDark = 'var(--primary-on-color-dark)';
const disableColor = 'var(--text-disabled-color)';

const errorOnColor = '#fff';
const errorColor = 'var(--error-color-dark)';
const errorOnColorLight = '#263238';
const errorColorLight = '#FF5E5E';
const errorColorDark = '#740000';
const errorOnColorDark = '#fff';

const StyledButton = styled(Button)`
    &&& {
        border: ${({ error }: ButtonProps) => (error ? '2px solid ' + errorColor : '2px solid ' + primaryColor)};
        color: ${({ error }: ButtonProps) => (error ? errorColor : primaryColor)};
        background: none;
        padding: 0.6rem 1.1rem;
        font-size: 1.1rem;
        margin: 0;
        min-height: min-content;
    }

    &&&.disabled {
        border-color: ${disableColor};
        color: ${disableColor};
        opacity: 1 !important;
        & path {
            fill: ${disableColor};
        }
    }

    &&&:hover {
        border: ${({ error }: ButtonProps) =>
            error ? '2px solid ' + errorColorLight : '2px solid ' + primaryColorLight};
        background: ${({ error }: ButtonProps) => (error ? errorColorLight : primaryColorLight)};
        color: ${({ error }: ButtonProps) => (error ? errorOnColorLight : primaryOnColorLight)};

        & path {
            fill: ${({ error }: ButtonProps) => (error ? errorOnColorLight : primaryOnColorLight)};
        }
    }

    &&&:focus {
        border: 2px solid transparent;
        background: ${({ error }: ButtonProps) => (error ? errorColor : primaryColor)};
        color: ${({ error }: ButtonProps) => (error ? errorOnColor : primaryOnColor)};
        outline: ${({ error }: ButtonProps) => (error ? errorColor : primaryColor)};
        outline-offset: 2px;
        outline-style: auto;
        background-clip: padding-box;
        & path {
            fill: ${({ error }: ButtonProps) => (error ? errorOnColor : primaryOnColor)};
        }
    }

    &&&&:active {
        border: ${({ error }: ButtonProps) =>
            error ? '2px solid ' + errorColorDark : '2px solid ' + primaryColorDark};
        background: ${({ error }: ButtonProps) => (error ? errorColorDark : primaryColorDark)};
        color: ${({ error }: ButtonProps) => (error ? errorOnColorDark : primaryOnColorDark)};
        outline: none;
        & path {
            fill: ${({ error }: ButtonProps) => (error ? errorOnColorDark : primaryOnColorDark)};
        }
    }
`;

const GhostPrimaryButton = ({ onClick, error, disabled = false, children, ...props }: ButtonProps): ReactElement => {
    return (
        <StyledButton error={error} onClick={onClick} disabled={disabled} {...props}>
            {children}
        </StyledButton>
    );
};

export default GhostPrimaryButton;
