import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    appHandleMessage: ['messageText', 'messageType', 'messageTitle'],
    appRemoveMessage: ['guid'],
    appRequestAppContext: [],
    appReceiveAppContext: ['appContext'],
    appSetSelectedModule: ['module'],
    appSetSelectedSubModule: ['subModule'],
    appSetDeviceType: ['deviceType'],
    appSetSelectedLang: ['lang'],
    appAddNavigationPathElement: ['element'],
    appSetNavigationPath: ['path'],
    appSetShowReturnButton: ['show'],
});

export { Creators, Types };
