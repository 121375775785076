import { ReactElement, RefObject, useMemo } from 'react';
import { CreateTicketComment, Ticket, TicketSeenEntity } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { AvatarComponentProps } from '../../../../../../components/general/AvatarComponent';
import {
    useCreateTicketInternalNote,
    useGetInfiniteTicketInternalNotes,
} from '../../../../apiQueries/useTicketInternalNotes';
import { TicketListView } from '../../../UserTickets/UserTickets';
import { CommentType } from '../components/Comments';
import { Comments } from '../components/index';
import { useCommentHighlighting } from '../hooks/useCommentHighlighting';

interface InternalNotesTabProps {
    setChangesSaved(isSaved: boolean): void;
    scrollRef: RefObject<HTMLElement>;
    currentUserAvatar: AvatarComponentProps;
    ticket: Ticket;
    isMobile: boolean;
    caseWorkerCanEditTicket: boolean;
    view: TicketListView;
}

const InternalNotesTab = ({
    setChangesSaved,
    scrollRef,
    currentUserAvatar,
    ticket,
    isMobile,
    caseWorkerCanEditTicket,
    view,
}: InternalNotesTabProps): ReactElement => {
    const { data, isLoading, isFetchingMore, isError, canFetchMore, fetchMore } = useGetInfiniteTicketInternalNotes(
        ticket.number,
    );
    const notes = useMemo(() => (data ?? []).flat(), [data]);

    const [
        createTicketInternalNote,
        {
            isLoading: isLoadingInternalNote,
            isSuccess: isSuccessCreatingInternalNote,
            isError: isErrorCreatingInternalNote,
        },
    ] = useCreateTicketInternalNote();

    const handleFetchMore = () => {
        if (!isFetchingMore) fetchMore();
    };

    const onSubmitInternalNoteHandler = (note: CreateTicketComment) => {
        createTicketInternalNote([ticket.number, { ...note }]);
    };

    useCommentHighlighting(notes, isLoading, ticket.number, TicketSeenEntity.InternalNotes);

    return (
        <>
            <Comments
                ticket={ticket}
                comments={notes}
                canFetchMore={canFetchMore}
                fetchMore={handleFetchMore}
                currentUserAvatar={currentUserAvatar}
                disableSubmitMessageButton={isLoadingInternalNote}
                isLoading={isLoading}
                isError={isError}
                key={CommentType.InternalNotes}
                isErrorCreatingComment={isErrorCreatingInternalNote}
                isSuccessCreatingComment={isSuccessCreatingInternalNote}
                onSubmitComment={onSubmitInternalNoteHandler}
                scrollRef={scrollRef}
                setChangesSaved={setChangesSaved}
                isLoadingCreatingComment={isLoadingInternalNote}
                isMobile={isMobile}
                caseWorkerCanEditTicket={caseWorkerCanEditTicket}
                view={view}
                commentType={CommentType.InternalNotes}
            />
        </>
    );
};

export default InternalNotesTab;
