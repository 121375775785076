import { ChangeEvent, forwardRef } from 'react';
import styled from 'styled-components';

const HiddenFileInput = styled.input`
    display: none;
`;

interface GenericUploaderComponentProps extends React.ComponentPropsWithoutRef<'input'> {
    onFilesUploaded: (files: FileList) => void;
    acceptedFileTypes?: string[];
    multiple?: boolean;
}

const GenericUploaderComponent = forwardRef<HTMLInputElement, GenericUploaderComponentProps>(
    function ForwardRefedHiddenFileInput({ onFilesUploaded, acceptedFileTypes, multiple }, ref) {
        const handleFilesUploaded = (e: ChangeEvent<HTMLInputElement>) => {
            const { files: eventFiles } = e.target;
            if (!eventFiles) {
                return;
            }
            onFilesUploaded(eventFiles);
        };

        return (
            <HiddenFileInput
                type="file"
                onChange={handleFilesUploaded}
                accept={acceptedFileTypes && acceptedFileTypes.join(',')}
                multiple={multiple}
                aria-hidden="true"
                ref={ref}
            />
        );
    },
);

export default GenericUploaderComponent;
