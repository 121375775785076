import { PropsWithChildren, SyntheticEvent } from 'react';
import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js';
import styled from 'styled-components';
import { useNavigation } from '../../../../utility';

function findLinkEntities(
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
}

const Url = styled.a`
    &,
    :hover,
    :visited,
    :focus {
        color: var(--text-link-color);
        text-decoration: underline;
        cursor: pointer;
    }
`;

interface LinkProps {
    contentState: ContentState;
    entityKey: string;
    readOnly: boolean;
}
const Link = (props: PropsWithChildren<LinkProps>) => {
    const navigation = useNavigation();
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    const getActualUrl = (url: string) => {
        if (url.startsWith(window.location.origin)) return url;
        if (url.startsWith('http')) return url;
        else {
            return `https://${url}`;
        }
    };
    const handleClick = (e: SyntheticEvent) => {
        e.preventDefault();
        if (url.startsWith(window.location.origin) && props.readOnly) {
            navigation.push(url.replace(window.location.origin, ''));
        } else {
            window.open(getActualUrl(url), '_blank', 'noopener');
        }
    };
    return (
        <Url onClick={handleClick} href={getActualUrl(url)} target="_blank" rel="norefer">
            {props.children}
        </Url>
    );
};

const createDecorator = (isEditing: boolean): CompositeDecorator =>
    new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: Link,
            props: { readOnly: !isEditing },
        },
    ]);

export default createDecorator;
