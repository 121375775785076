import styled from 'styled-components';

export const ContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 1px solid var(--border-color);
    margin-top: -1.2rem;
    margin-bottom: 20rem;
`;

export const SelectorContainer = styled.div`
    width: 80%;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    z-index: 3;
    box-shadow: 0px 4px 6px -3px;
    position: absolute;
    transform: translatey(-20.5rem);
    max-height: 20rem;
    left: 10%;
    background-color: var(--surface-color-light);
    :before {
        width: 0.7rem;
        height: 0.7rem;
        content: '';
        z-index: 2;
        border: 1px solid var(--border-color);
        transform: rotate(45deg);
        margin-top: -0.5rem;
        border-right: none;
        border-bottom: none;
        background-color: #ffff;
        box-shadow: -1px -1px 3px -2px;
        display: inherit;
    }
`;

export const TimeHeader = styled.h3`
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    text-align: center;
    padding: 0.5rem;
    font-size: 1.25rem;
`;

export const OptionsContainer = styled.div`
    display: flex;
    width: 100%;
    overflow: hidden;

    :before {
        width: 7rem;
        height: 2.5rem;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        content: ':';
        border-radius: 5px;
        color: var(--primary-color);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
        background-color: #bee4ff;
    }
`;
