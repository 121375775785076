import { ReactElement } from 'react';
import { Button } from 'semantic-ui-react';
import type { ButtonProps } from 'semantic-ui-react';
import styled from 'styled-components';

const primaryColor = 'var(--primary-color)';
const primaryOnColor = 'var(--primary-on-color)';
const primaryColorLight = 'var(--primary-color-light)';
const primaryOnColorLight = 'var(--primary-on-color-light)';
const primaryColorDark = 'var(--primary-color-dark)';
const primaryOnColorDark = 'var(--primary-on-color-dark)';

const errorOnColor = '#fff';
const errorColor = 'var(--error-color-dark)';
const errorOnColorLight = '#263238';
const errorColorLight = '#FF5E5E';
const errorColorDark = '#740000';
const errorOnColorDark = '#fff';

const StyledButton = styled(Button)`
    &&& {
        color: ${({ error }: ButtonProps) => (error ? errorOnColor : primaryOnColor)};
        background: ${({ error }: ButtonProps) => (error ? errorColor : primaryColor)};
        padding: 0.8rem 1.1rem;
        font-size: 1.1rem;
        min-height: min-content;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & path {
        fill: ${({ error }: ButtonProps) => (error ? errorOnColor : primaryOnColor)};
    }

    &&&:hover {
        color: ${({ error }: ButtonProps) => (error ? errorOnColorLight : primaryOnColorLight)};
        background: ${({ error }: ButtonProps) => (error ? errorColorLight : primaryColorLight)};
    }
    :hover path {
        fill: ${({ error }: ButtonProps) => (error ? errorOnColorLight : primaryOnColorLight)};
    }

    &&&&:active,
    &&&:focus {
        background: ${({ error }: ButtonProps) => (error ? errorColorDark : primaryColorDark)};
        color: ${({ error }: ButtonProps) => (error ? errorOnColorDark : primaryOnColorDark)};
        outline: none;
    }
    :active path,
    :focus path {
        fill: ${({ error }: ButtonProps) => (error ? errorOnColorDark : primaryOnColorDark)};
    }

    &&&:focus {
        outline: ${({ error }: ButtonProps) => (error ? errorColorDark : primaryColorDark)};
        outline-offset: 2px;
        outline-style: auto;
        background-clip: padding-box;
    }

    &&&.disabled {
        background: #d4d6d7;
        color: #a8adaf;
        opacity: 1 !important;
    }
    &:disabled path {
        fill: #a8adaf;
        cursor: auto;
    }
`;

const PrimaryButton = ({ onClick, error, disabled = false, children, ...props }: ButtonProps): ReactElement => {
    return (
        <StyledButton error={error} onClick={onClick} disabled={disabled} {...props}>
            {children}
        </StyledButton>
    );
};

export default PrimaryButton;
