import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PrimaryButton, GhostPrimaryButton, Modal, ModalType } from '../../../../../../../components';
import useTicketingNavigation from '../../../../../useTicketingNavigation';

const Content = styled.div`
    padding: 2rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-left: 1rem;
    }
`;

type AbortModalProps = {
    onRegret(): void;
};
const AbortModal = ({ onRegret }: AbortModalProps): ReactElement => {
    const { goToSettings } = useTicketingNavigation();
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    return (
        <Modal
            title={t('areYouSureYouWantToCancel')}
            type={ModalType.Small}
            onClose={onRegret}
            open
            bottomContent={
                <ButtonContainer>
                    <GhostPrimaryButton onClick={onRegret}>{tCommon('backToEditing')}</GhostPrimaryButton>
                    <StyledPrimaryButton onClick={goToSettings}>{t('ImSure')}</StyledPrimaryButton>
                </ButtonContainer>
            }
        >
            <Content>{t('ifYouCancelChangesWillNotBeSaved')}</Content>
        </Modal>
    );
};
export default AbortModal;
