import { useSelector } from 'react-redux';
import { IRootStateProps } from '../../../reducers';
import { Module, ModuleName } from '../../api/apiTypes/portalApiTypes';

const useModuleIsActive = (moduleName: ModuleName): boolean => {
    const modules = useSelector<IRootStateProps, Module[] | undefined>((state) => state?.app?.modules);

    if (!modules) return false;

    const findModule = (modules: Module[], moduleName: ModuleName): boolean => {
        return modules.some(
            (module) => module.name === moduleName || (module.children && findModule(module.children, moduleName)),
        );
    };

    return findModule(modules, moduleName);
};

export default useModuleIsActive;
