import { GboMenuType, OrderStatusOption, PaymentOption } from '../../../../../api/apiTypes/shopApiTypes';

export const paymentTypeEN = {
    [PaymentOption.Stripe]: 'Card',
    [PaymentOption.CustomerInvoice]: 'Invoice',
};
export const paymentTypeNO = {
    [PaymentOption.Stripe]: 'Kort',
    [PaymentOption.CustomerInvoice]: 'Faktura',
};

export const orderMenuTypeEN = {
    [GboMenuType.FoodForMeeting]: 'Food for meeting',
    [GboMenuType.Kiosk]: 'Kiosk',
    [GboMenuType.Lunch]: 'Lunch',
};

export const orderMenuTypeNO = {
    [GboMenuType.FoodForMeeting]: 'Mat til møtet',
    [GboMenuType.Kiosk]: 'Kiosk',
    [GboMenuType.Lunch]: 'Lunsj',
};

export const CHIP_BG_COLOR = {
    [OrderStatusOption.Canceled]: '#FFD4D4',
    [OrderStatusOption.Ordered]: '#E1F6FF',
    [OrderStatusOption.Deleted]: '#E9EBEB',
    [OrderStatusOption.Delivered]: '#D2F8B6',
    [OrderStatusOption.Approved]: '#D2F8B6',
};
export const CHIP_TEXT_COLOR = {
    [OrderStatusOption.Canceled]: '#B40000',
    [OrderStatusOption.Ordered]: '#001EFF',
    [OrderStatusOption.Deleted]: '#475156',
    [OrderStatusOption.Delivered]: '#006729',
    [OrderStatusOption.Approved]: '#006729',
};
