import React, { useState, useEffect, FC, useCallback, useMemo } from 'react';
import { startOfWeek, add } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { Perm } from '../../../../common';
import { SvgIcon, ErrorMessage } from '../../../../components';
import { Button, FlexBox, Typography } from '../../../../components/styleds';
import Dropdown from '../../../../components/styleds/dropdown';
import { DropdownItem } from '../../../../components/styleds/dropdown/types';
import { useUserHasPerm } from '../../../../utility';
import { getDeviceLanguageDateFormat } from '../../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import { useGetSimpleCanteens } from '../../../FoodForMeetings/apiQueries/useConferenceMeals';
import { usePortalContentConfig } from '../../usePortalContentConfig';
import { MenuImageEditing, MenuForm, EditFoodMenuActionBar } from './components';

const FlexContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    & :last-child {
        max-height: 40px;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Heading = styled.h2`
    font-size: 1.42rem;
    line-height: 2rem;
    color: var(--text-high-emphasis-color);
    font-weight: bold;
    margin-bottom: 2.3rem;
`;

const MenuNavigationButton = styled.button`
    background: none;
    border: none;
    display: flex;
    height: fit-content;
    transform: rotate(${({ invert }: { invert?: boolean }) => (invert ? `0.5turn` : '0')});
    cursor: pointer;
    padding: 0;

    &[disabled] {
        cursor: default;
    }

    &[disabled] svg {
        opacity: 0.5;
    }
`;

const WeekHeader = styled.h3`
    font-size: 1.42rem;
    margin: 0;
`;

const WeekDates = styled.h4`
    font-size: 0.8rem;
    letter-spacing: 0.01rem;
    margin: 0;
    color: var(--text-medium-emphasis-color);
`;

const WeekDetails = styled.div`
    margin: 0 1.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LoaderContainer = styled.div`
    width: 100%;
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const formId = 'EditMenuForm';

export enum RootLocation {
    Dashboard = 'Dashboard',
    SideBar = 'SideBar',
}
interface LocationState {
    page: RootLocation;
}
const EditDailyMenu: FC = () => {
    const { t } = useTranslation('Dashboard', { keyPrefix: 'dailyMenu' });
    const canEditPortalContentImage = useUserHasPerm(Perm.todaysMenuImageUpdate);
    const canEditDailyMenu = useUserHasPerm(Perm.todaysMenuUpdate);
    const [monday, setMonday] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
    const [isSaving, setIsSaving] = useState(false);
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [showNavDisabled, setShowNavDisabled] = useState(false);
    const { data: contentConfig, isLoading: isLoadingConfig } = usePortalContentConfig();

    const [selectedCanteen, setSelectedCanteen] = useState<DropdownItem | null>(null);

    const moveWeek = (forwards: boolean) => {
        setMonday((curr) => add(curr, { weeks: forwards ? 1 : -1 }));
    };
    const goToNextWeek = () => moveWeek(true);
    const goToPreviousWeek = () => moveWeek(false);
    const handleSetIsSaving = (isSaving: boolean) => setIsSaving(isSaving);
    const handleSetFormIsDirty = (isDirty: boolean) => setFormIsDirty(isDirty);
    const handleSetIsSuccess = (isSuccess: boolean) => setIsSuccess(isSuccess);
    const handleSetIsError = (isError: boolean) => setIsError(isError);
    const handleSetShowNavDisabled = () => setShowNavDisabled(true);
    const location = useLocation<LocationState>();
    const { page } = location.state || { page: 'Dashboard' };

    const { data: canteens, isLoading: isLoadingCanteens, isError: isCanteensError } = useGetSimpleCanteens();

    const isDefault = useMemo(
        () => !!canteens?.length && canteens?.find((canteen) => canteen.id === selectedCanteen?.id)?.isDefault === true,
        [canteens, selectedCanteen],
    );
    const isVendor = useMemo(
        () => !!canteens?.length && canteens?.some((canteen) => canteen.id === selectedCanteen?.id),
        [canteens, selectedCanteen],
    );

    useEffect(() => {
        if (canteens?.length && !isLoadingCanteens) {
            const defaultCanteen = canteens[0];
            setSelectedCanteen({ id: defaultCanteen.id, value: defaultCanteen.name });
        }
    }, [canteens, isLoadingCanteens]);

    useEffect(() => {
        const resetErrorStateIfNotNeeded = () => {
            if (!isSaving && !formIsDirty) setShowNavDisabled(false);
        };

        resetErrorStateIfNotNeeded();
    });
    const navDisabled = isSaving || formIsDirty;
    const saveDisabled = isSaving || !formIsDirty || isError;

    const changCategoryCallback = useCallback((val: DropdownItem) => {
        setSelectedCanteen(val);
    }, []);

    const submitBtnText = useMemo(
        () => t(isSaving ? 'storesButton' : !formIsDirty && isSuccess ? 'savedButton' : 'saveButton'),
        [t, isSaving, formIsDirty, isSuccess],
    );

    return (
        <FlexBox dir={'column'} align={'center'} gap={'1.7rem'}>
            {page === RootLocation.Dashboard && <EditFoodMenuActionBar />}
            <FlexBox pd={'0 1.7rem 1.7rem'} justify={'center'}>
                <FlexBox dir={'column'} maxW={'65rem'}>
                    <Heading>{t('editDailyMenuTitle')}</Heading>
                    <FlexBox dir={'column'} gap={'1.714rem'}>
                        {canEditPortalContentImage ? (
                            <FlexBox
                                br={'0.71rem'}
                                pd={'1.71rem'}
                                boxShadow={'0px 4px 10px 0px #00000026'}
                                overflowY={'auto'}
                                background={'var(--surface-color-light)'}
                                height={'min-content'}
                                dir={'column'}
                            >
                                {isLoadingConfig ? (
                                    <LoaderContainer>
                                        <Loader active inline>
                                            {t('loadingMenuImageMessage')}
                                        </Loader>
                                    </LoaderContainer>
                                ) : contentConfig ? (
                                    <MenuImageEditing canteenId={selectedCanteen?.id || ''} />
                                ) : (
                                    <ErrorMessage message={t('errorLoadingImage')} />
                                )}
                            </FlexBox>
                        ) : null}
                        {canEditDailyMenu ? (
                            <FlexBox
                                br={'0.71rem'}
                                pd={'1.71rem'}
                                boxShadow={'0px 4px 10px 0px #00000026'}
                                overflowY={'auto'}
                                background={'var(--surface-color-light)'}
                                height={'min-content'}
                                skeleton={isLoadingConfig}
                                dir={'column'}
                            >
                                <FlexContainer>
                                    <HeaderContainer onClick={navDisabled ? handleSetShowNavDisabled : undefined}>
                                        <MenuNavigationButton
                                            disabled={navDisabled}
                                            invert
                                            onClick={goToPreviousWeek}
                                            aria-label={t('goToLastWeek')}
                                        >
                                            <SvgIcon name="CaretRight" alt="" />
                                        </MenuNavigationButton>
                                        <WeekDetails>
                                            <WeekHeader>
                                                {t('weekNumber')}
                                                {`${getDeviceLanguageDateFormat(monday, 'I')}`}
                                            </WeekHeader>
                                            <WeekDates>
                                                {getDeviceLanguageDateFormat(monday, 'd/M')}-
                                                {getDeviceLanguageDateFormat(add(monday, { days: 4 }), 'd/M')}
                                            </WeekDates>
                                        </WeekDetails>
                                        <MenuNavigationButton
                                            disabled={navDisabled}
                                            onClick={goToNextWeek}
                                            aria-label={t('goToNextWeek')}
                                        >
                                            <SvgIcon name="CaretRight" alt="" />
                                        </MenuNavigationButton>
                                    </HeaderContainer>

                                    <Button
                                        disabled={saveDisabled}
                                        align={'center'}
                                        justify={'center'}
                                        type="submit"
                                        width={'auto'}
                                        pd={'0.8rem 1.1rem'}
                                        br={'0.285rem'}
                                        form={formId}
                                    >
                                        {!formIsDirty && isSuccess && <SvgIcon name="CheckIcon" />}

                                        <Typography
                                            fontSize={'1.1rem'}
                                            color={saveDisabled ? '#a8adaf' : '#fff'}
                                            fontWeight={700}
                                        >
                                            {submitBtnText}
                                        </Typography>
                                    </Button>
                                </FlexContainer>
                                {Array.isArray(canteens) && canteens.length > 1 && (
                                    <FlexBox mrT={'2rem'}>
                                        <Dropdown
                                            value={selectedCanteen}
                                            list={canteens.map((canteen) => ({ id: canteen.id, value: canteen.name }))}
                                            onChange={changCategoryCallback}
                                        />
                                    </FlexBox>
                                )}
                                {!!selectedCanteen && (
                                    <MenuForm
                                        key={monday.toISOString()}
                                        monday={monday}
                                        formId={formId}
                                        setIsSaving={handleSetIsSaving}
                                        setFormIsDirty={handleSetFormIsDirty}
                                        showNavDisabledError={navDisabled && showNavDisabled}
                                        setIsSuccess={handleSetIsSuccess}
                                        setIsError={handleSetIsError}
                                        selectedCanteenIds={selectedCanteen.id}
                                        isCanteensError={isCanteensError}
                                        isLoadingCanteens={isLoadingCanteens}
                                        isDefault={isDefault}
                                        isVendor={isVendor}
                                    />
                                )}
                            </FlexBox>
                        ) : null}
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    );
};

export default EditDailyMenu;
