import { ReactNode } from 'react';
import { MyBooking } from '../../../api/apiTypes/bookingApiTypes';
import { Vendor } from '../../../api/apiTypes/foodForMeetingsApiTypes';
import { CompanyAdmin } from '../../../api/apiTypes/portalApiTypes';
import { OrderOnBehalf, ProductCategoryV2, ProductV2, UserVendors } from '../../../api/apiTypes/shopApiTypes';
import { FoodForMeetingsProduct } from '../types';

export interface OrderPrice {
    total: number;
    totalVAT: number;
    VAT: number;
}

export interface ScheduleItem {
    dayOfWeek: number;
    startTime: string;
    endTime: string;
    showRestriction?: boolean;
}

export interface DeliveryLocationOptions {
    id: string;
    value: string;
}

export interface IsFetchingFoodForMeetingLoader {
    isLoadingProducts: boolean;
    isLoadingCanteens: boolean;
    isFetchingBuildings: boolean;
    isAllergensLoading: boolean;
    isFetchingMore: boolean;
    isBookedMeetingListLoading: boolean;
    isCategoriesListLoader: boolean;
    getOrderListForTimeNowIsLoading: boolean;
}

export interface AllergieItem {
    value: string;
    id: string;
    selected: boolean;
}
export interface FoodForMeetingsOrderListItem {
    product: ProductV2;
    quantity: number;
}

export interface OrderListForTimeNow {
    product: {
        productAvailability: {
            productId: string;
        };
    };
    quantity: number;
}

export interface FoodForMeetingsContextProps {
    isDisablePickerTime: boolean;
    categoriesList: ProductCategoryV2[];
    canteens: Vendor[];
    selectedCanteen: Vendor | null;
    productsList: FoodForMeetingsProduct[];
    orderingList: FoodForMeetingsOrderListItem[];
    updateProductListContent: (counter: number, product: FoodForMeetingsProduct) => void;
    clearOrderingList: () => void;
    isFetchLoading: IsFetchingFoodForMeetingLoader;
    orderPrice: OrderPrice;
    isDrawerOpen: boolean;
    changeDrawerState: (val: boolean) => void;
    updateSelectedCanteen: (val: Vendor | null) => void;
    getScheduleCanteenAvailable: (schedule: ScheduleItem[], time: Date) => ScheduleItem | null;
    changeDeliveryMethod: (val: DeliveryMethod) => void;
    deliveryMethod: DeliveryMethod;
    deliveryLocationOptions: DeliveryLocationOptions[];
    updateSelectedDeliveryDate: (deliveryDate: Date) => void;
    updateSelectedDeliveryLocation: (item: DeliveryLocationOptions) => void;
    selectedDeliveryLocation: null | DeliveryLocationOptions;
    selectedDeliveryDate: Date;
    updateQueryFilters: (val: QueryFilterItem[]) => void;
    allergiesList: AllergieItem[];
    updateSelectedAllergies: (val: AllergieItem) => void;
    updateDebouncedSearchTerm: (searchString: string) => void;
    debouncedSearchTerm: string;
    updateSelectedCategory: (category: ProductCategoryV2) => void;
    selectedCategory: ProductCategoryV2 | null;
    temporarySubmitFoodForMeetingHandler: () => void;
    selectedMeeting: MyBooking | null;
    updateSetSelectedMeeting: (item: MyBooking | null, time: Date) => void;
    bookedMeetingList: MyBooking[];
    isConnectionToMeeting: boolean;
    updateIsConnectionToMeeting: (isChecked: boolean) => void;
    canFetchMore: boolean;
    fetchMore: () => void;
    selectedPreviousDeliveryDateWithoutMeetingConnection: Date;
    orderNumber?: number;
    isEditView: boolean;
    orderOnBehalfOf: OrderOnBehalf | null;
    isPreparedOrderForOrdering: boolean;
    disabledProceedToCheckOut: boolean;
    isAdminWarning: boolean;
    isVendorWarning: boolean;
    vendorsList: UserVendors[] | Error;
    companyAdminsList: CompanyAdmin[] | string;
    isChangesInOrder: boolean;
    orderId?: string;
}

export interface FoodForMeetingsProviderProps {
    children: ReactNode;
}

export enum DeliveryMethod {
    DELIVERY = 'DELIVERY',
    PICKUP = 'PICKUP',
}

export interface QueryFilterItem {
    type: number;
    value: string[];
}

export interface CanteenStorageData {
    canteenId?: string | null;
    deliveryType?: DeliveryMethod;
    meetingId?: string | null;
    date?: Date;
    isConnectionToMeeting?: boolean;
    orderingList?: FoodForMeetingsOrderListItem[];
    deliveryLocation?: DeliveryLocationOptions | null;
    orderId?: string;
}
