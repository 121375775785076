import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { isSameDay } from 'date-fns';
import { useGetMenu } from '../apiQueries/useMenu';
import { ShoppingCartContext } from '../ShoppingCart';
import { FoodLine, FoodMenu } from '../types';

const initialState: FoodMenuContext = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    isFetching: false,
    allergies: [],
    data: undefined,
    fetchAnotherDate: () => null,
};

type FoodMenuContext = {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    isFetching: boolean;
    data: FoodMenu | undefined;
} & {
    allergies: string[];
    fetchAnotherDate: (date: Date) => void;
};

interface FoodMenuProviderProps {
    children: React.ReactNode;
}

const FoodMenuContext = createContext<FoodMenuContext>(initialState);

const getAllAllergenes = (data: FoodMenu): string[] => {
    const all = data.foodGroups.reduce<string[]>((acc, group) => {
        const accGroup = group.foodLines.reduce<string[]>((curr, foodLine) => {
            if (foodLine.allergenList) {
                return [...curr, ...foodLine.allergenList];
            } else {
                return curr;
            }
        }, []);
        return [...acc, ...accGroup];
    }, []);
    const set = new Set(all);
    return Array.from(set);
};

const FoodMenuProvider: React.FC<React.PropsWithChildren<FoodMenuProviderProps>> = ({
    children,
}: FoodMenuProviderProps) => {
    const [date, setDate] = useState<Date | null>(null);
    const { updateFoodLines, state: shoppingCartState } = useContext(ShoppingCartContext);

    const menuQuery = useGetMenu(date);

    const fetchAnotherDate = useCallback(
        async (newDate: Date) => {
            if (date && isSameDay(newDate, date)) return;
            if (!menuQuery.isSuccess || !menuQuery.data) {
                return;
            }

            const dateParam = new Date(newDate);
            if (date) {
                dateParam.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
            }
            setDate(dateParam);
        },
        [date, menuQuery.data, menuQuery.isSuccess],
    );

    useEffect(() => {
        const updateFoodLinesInShoppingCart = () => {
            if (!menuQuery.isSuccess || !menuQuery.data) {
                return;
            }
            if (isSameDay(shoppingCartState.deliveryDateTime, menuQuery.data.menuDateTime)) {
                updateFoodLines(
                    menuQuery.data.foodGroups.reduce<FoodLine[]>((curr, foodGroup) => {
                        return [...curr, ...foodGroup.foodLines];
                    }, [] as FoodLine[]),
                );
            }
        };
        updateFoodLinesInShoppingCart();
    }, [menuQuery.data, menuQuery.isSuccess, shoppingCartState.deliveryDateTime, updateFoodLines]);
    return (
        <FoodMenuContext.Provider
            value={{
                ...menuQuery,
                allergies: menuQuery.isSuccess && menuQuery.data ? getAllAllergenes(menuQuery.data) : [],
                fetchAnotherDate,
            }}
        >
            {children}
        </FoodMenuContext.Provider>
    );
};

export { FoodMenuContext, FoodMenuProvider };
