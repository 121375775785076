import { ReactElement, useEffect, useState } from 'react';
import { Location } from 'history';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { GhostPrimaryButton, PrimaryButton } from '../components';
import Modal, { ModalType } from '../components/general/Modal/index';

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-left: 1rem;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    padding: 1.7rem;
`;

interface RoutePromptModalProps {
    when?: boolean | undefined;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
    modalType?: ModalType;
    modalTitle: string;
    modalContent: JSX.Element;
    modalCancelButtonText: string;
    modalOkButtonText: string;
    modalHideCloseButton?: boolean;
    onOkButtonClick?(): void;
}

const RoutePromptModal = ({
    when,
    navigate,
    shouldBlockNavigation,
    modalType = ModalType.Small,
    modalTitle,
    modalContent,
    modalCancelButtonText,
    modalOkButtonText,
    modalHideCloseButton = true,
    onOkButtonClick,
}: RoutePromptModalProps): ReactElement => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    useEffect(() => {
        setConfirmedNavigation(false);
    }, [when]);

    const closeModalHandler = () => setModalVisible(false);

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setConfirmedNavigation(true);
        setModalVisible(false);
        onOkButtonClick && onOkButtonClick();
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) navigate(lastLocation.pathname);
    }, [navigate, confirmedNavigation, lastLocation]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <Modal
                type={modalType}
                open={modalVisible}
                title={modalTitle}
                bottomContent={
                    <Container>
                        <GhostPrimaryButton onClick={closeModalHandler}>{modalCancelButtonText}</GhostPrimaryButton>
                        <StyledPrimaryButton onClick={handleConfirmNavigationClick}>
                            {modalOkButtonText}
                        </StyledPrimaryButton>
                    </Container>
                }
                onClose={closeModalHandler}
                hideCloseButton={modalHideCloseButton}
            >
                <ContentContainer>{modalContent}</ContentContainer>
            </Modal>
        </>
    );
};
export default RoutePromptModal;
