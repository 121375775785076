import { AxiosResponse } from 'axios';
import {
    MenuDto,
    IPostOrderDetails,
    ICompanyDetails,
    FoodOrderBooking,
    FoodOrderDetails,
    IPatchOrderDetails,
} from './apiTypes/foodApiTypes';
import http from './infrastructure/httpService';
import { getBaseUrlApim, getPortalId } from './utils';

const urlFood = process.env.REACT_APP_FOOD_API_BASEURL;

export function deleteFoodOrderFromApi(orderId: string): Promise<AxiosResponse<never>> {
    const url = `${getBaseUrlApim()}/${urlFood}/portals/${getPortalId()}/orders/${orderId}`;
    return http.delete<never>(url);
}
export function getFoodOrderDetailsFromApi(orderId: string): Promise<AxiosResponse<FoodOrderDetails>> {
    const url = `${getBaseUrlApim()}/${urlFood}/portals/${getPortalId()}/orders/${orderId}`;
    return http.get<FoodOrderDetails>(url);
}
export function getFoodBookingsFromApi(
    deliveryFromDate: Date | null = null,
    deliveryToDate: Date | null = null,
    skip: number | null = null,
    take: number | null = null,
): Promise<AxiosResponse<FoodOrderBooking[]>> {
    const url = `${getBaseUrlApim()}/${urlFood}/portals/${getPortalId()}/orders`;
    const params = { deliveryFromDate, deliveryToDate, skip, take };
    return http.get<Promise<FoodOrderBooking>, AxiosResponse<FoodOrderBooking[]>>(url, { data: params });
}
export function getMenuForDateFromApi(date?: string): Promise<AxiosResponse<MenuDto>> {
    const url = `${getBaseUrlApim()}/${urlFood}/portals/${getPortalId()}/menus`;
    return http.get<MenuDto>(url, { params: { date } });
}
export function CreateFoodBookingFromApi(data: IPostOrderDetails): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${urlFood}/portals/${getPortalId()}/orders`;
    return http.post(url, data);
}
export function sendCompassOnboardingEmailFromApi(companyId: string, data: ICompanyDetails): Promise<AxiosResponse> {
    const url = `${getBaseUrlApim()}/${urlFood}/portals/${getPortalId()}/companies/${companyId}/compassOnboarding`;
    return http.post(url, data);
}
export function updateFoodBooking(data: IPatchOrderDetails): Promise<AxiosResponse<never>> {
    const url = `${getBaseUrlApim()}/${urlFood}/portals/${getPortalId()}/orders/${data.orderGuid}`;
    return http.patch<IPatchOrderDetails, never>(url, data);
}
