import type { RouterState } from 'connected-react-router';
import { connectRouter } from 'connected-react-router';
import type { History } from 'history';
import type { AnyAction, CombinedState } from 'redux';
import { combineReducers } from 'redux';
import { Menu } from './app/common/Sidebar/duck/classes';
import { default as sidebarReducer } from './app/common/Sidebar/duck/reducers';
import { AppContextStateProps, default as appReducer } from './duck/reducers';

export interface IRootStateProps {
    router: {
        menu: any[];
        isSidebarVisible: boolean;
        isLoadingMenu: boolean;
    };
    app: AppContextStateProps;
    sidebar: {
        menu: any[];
        isSidebarVisible: boolean;
        isLoadingMenu: boolean;
    };
}

const rootReducer = (history: History): any => {
    const reducers = combineReducers({
        router: connectRouter(history),
        app: appReducer,
        sidebar: sidebarReducer,
    });
    return (
        state:
            | CombinedState<{
                  router: RouterState<unknown>;
                  app: AppContextStateProps;
                  sidebar:
                      | {
                            menu: Menu;
                            isSidebarVisible: boolean;
                            isLoadingMenu: boolean;
                        }
                      | undefined;
              }>
            | undefined,
        action: AnyAction,
    ) => {
        if (action.type === 'LOGOUT_SUCCESS' || action.type === 'LOGOUT_FAILED') {
            state = undefined;
        }
        return reducers(state, action);
    };
};

export default rootReducer;
