import { ReactElement, forwardRef, useRef, SyntheticEvent, useState, useMemo, useEffect } from 'react';
import { useSelect } from 'downshift';
import type { UseSelectStateChange } from 'downshift';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';
import { OrderStatusOption, VendorOrderStatusOption } from '../../../api/apiTypes/shopApiTypes';
import { getLabel } from '../../../common/Helpers/getRequiredLabel';
import { getOrderStatusBadgeType } from '../../../utility/getBadgeType';
import Badge from '../../dataDisplay/Badge';
import FieldErrorMessage from '../../FieldErrorMessage';
import SvgIcon from '../../icons/SvgIcon';
import Label from '../../Label';
import {
    DropDownHeaderButton,
    Header,
    DownArrow,
    DropDownList,
    DropDownListItem,
    DropdownContainer,
    FieldContainer,
} from './Components/styles';

export type OptionType = {
    value: string | JSX.Element;
    id: string;
};

interface DisabledOptionType extends OptionType {
    disabled: boolean;
}

type StyledDropDownListProps = {
    isScrollable?: boolean;
};

const DropdownSearch = styled(Input)`
    width: calc(100% - calc(0.57rem * 2));

    &&&&& {
        margin: 0.57rem;
    }

    && input {
        padding-top: 0.57rem;
        padding-bottom: 0.57rem;
    }

    && .icon {
        margin-left: 0.57rem;
        font-size: 0.8rem;
    }
`;
const DropdownValue = styled.div`
    color: rgba(0, 0, 0, 0.87);
`;

const BadgedDropdownValue = styled.div`
    font-weight: 600;
`;

const StyledDropDownList = styled(DropDownList)`
    max-height: ${({ isScrollable }: StyledDropDownListProps) => (isScrollable ? '11.5rem' : 'none')};
    overflow-y: ${({ isScrollable }: StyledDropDownListProps) => (isScrollable ? 'auto' : 'visible')};
`;

const NoResultsMessage = styled.div`
    border-top: 1px solid #e9ebeb;
    padding: 0.57rem 1.14rem;
`;

const Placeholder = styled.span`
    color: var(--text-placeholder-color);
`;

const StyledBadge = styled(Badge)`
    margin-right: 2.642rem;
`;

export interface DropDownProps {
    items: OptionType[] | DisabledOptionType[];
    loading?: boolean;
    placeholder?: string;
    onChange: (value: string) => void;
    onChangeSearchTerm?: (value: string) => void;
    onBlur?: () => void;
    label?: string;
    required?: boolean;
    error?: string;
    name?: string;
    initialValue?: string | null;
    value?: string | null;
    readOnly?: boolean;
    className?: string;
    trigger?: (props: any) => ReactElement;
    enableSearch?: boolean;
    noResultsMessage?: string;
    isScrollable?: boolean;
    badgeOptions?: VendorOrderStatusOption[] | OrderStatusOption[];
}

const Dropdown = forwardRef<HTMLButtonElement, DropDownProps>(function DropdownForwarded(
    {
        items,
        placeholder = 'Velg en',
        onChange,
        onChangeSearchTerm,
        label,
        required = false,
        onBlur,
        error,
        name,
        initialValue,
        readOnly,
        className,
        trigger,
        enableSearch = false,
        noResultsMessage,
        value,
        isScrollable = false,
        loading,
        badgeOptions,
        ...rest
    },
    ref,
): ReactElement {
    const initialItem = initialValue ? items.find((item) => item.id === initialValue) : undefined;
    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        getLabelProps,
        reset,
        selectItem,
    } = useSelect({
        items,
        itemToString: (item: OptionType | DisabledOptionType | null) =>
            (typeof item?.value === 'string' ? item?.value : '') ?? '',
        onSelectedItemChange: ({ selectedItem }: UseSelectStateChange<OptionType>) => {
            selectedItem?.value && onChange(selectedItem.id);
            setSearchTerm('');
        },
        selectedItem: initialItem,
    });
    const innerRef = useRef<HTMLDivElement>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const { t } = useTranslation('common');

    useEffect(() => {
        if (!value) reset();
    }, [value, reset]);

    useEffect(() => {
        const newItem = items.find((item) => item.id === value);
        newItem && selectItem(newItem);
    }, [value]);

    useEffect(() => {
        if (isOpen) {
            setSearchTerm('');
        }
    }, [isOpen]);

    const handleButtonBlur = () => {
        if (!isOpen && onBlur) {
            onBlur();
        }
    };

    const handleListBlur = () => {
        if (onBlur && !innerRef?.current?.contains(document.activeElement)) {
            onBlur();
        }
    };

    const handleSearchChange = (e: SyntheticEvent, data: { value: string }) => {
        setSearchTerm(data.value.toLocaleLowerCase().trim());
        onChangeSearchTerm?.(data.value.toLocaleLowerCase().trim());
    };

    const indexedItems: [DisabledOptionType, number][] = useMemo(() => {
        return items.map((item, index) => [item as DisabledOptionType, index]);
    }, [items]);

    const filteredItems = useMemo(() => {
        if (!searchTerm) return indexedItems;
        return indexedItems.filter(
            ([item]) => typeof item.value === 'string' && item.value.toLowerCase().includes(searchTerm),
        );
    }, [indexedItems, searchTerm]);

    const getDropdownValue = () => {
        if (selectedItem?.value) {
            return badgeOptions ? (
                <StyledBadge
                    type={getOrderStatusBadgeType(selectedItem.value as VendorOrderStatusOption | OrderStatusOption)}
                    id="activationStatusBadge"
                >
                    <BadgedDropdownValue>
                        {(selectedItem && value) || <Placeholder>{placeholder}</Placeholder>}
                    </BadgedDropdownValue>
                </StyledBadge>
            ) : (
                <DropdownValue>{selectedItem.value}</DropdownValue>
            );
        }
        return <DropdownValue>{<Placeholder>{placeholder}</Placeholder>}</DropdownValue>;
    };

    return (
        <FieldContainer ref={innerRef} className={className}>
            {label && (
                <Label {...getLabelProps()} isError={error} disable={readOnly}>
                    {getLabel(label, required)}
                </Label>
            )}
            <DropdownContainer>
                {trigger ? (
                    trigger(getToggleButtonProps({ ref }))
                ) : (
                    <DropDownHeaderButton
                        {...getToggleButtonProps({ ref })}
                        aria-required={required}
                        error={error}
                        name={name}
                        type="button"
                        disabled={readOnly}
                        onBlur={handleButtonBlur}
                        loading={loading}
                        isBadged={!!badgeOptions}
                    >
                        <Header>
                            {loading ? <span>{t('load')}</span> : getDropdownValue()}
                            <DownArrow>
                                <SvgIcon name="CaretDown" />
                            </DownArrow>
                        </Header>
                    </DropDownHeaderButton>
                )}
                <StyledDropDownList
                    {...rest}
                    {...getMenuProps()}
                    open={isOpen}
                    onBlur={handleListBlur}
                    isScrollable={isScrollable}
                >
                    {isOpen && (
                        <>
                            {enableSearch && (
                                <DropdownSearch
                                    onKeyDown={(e: KeyboardEvent) => e.stopPropagation()}
                                    icon="search"
                                    iconPosition="left"
                                    placeholder={t('search')}
                                    onChange={handleSearchChange}
                                />
                            )}
                            {filteredItems.length
                                ? filteredItems.map(([item, index]) => (
                                      <DropDownListItem
                                          isHighlighted={highlightedIndex === index}
                                          isDisabled={item.disabled}
                                          key={`${item.id}${index}`}
                                          {...getItemProps({
                                              item,
                                              index,
                                          })}
                                      >
                                          {item.value}
                                      </DropDownListItem>
                                  ))
                                : noResultsMessage && <NoResultsMessage>{noResultsMessage}</NoResultsMessage>}
                        </>
                    )}
                </StyledDropDownList>
            </DropdownContainer>
            {error ? <FieldErrorMessage>{error}</FieldErrorMessage> : null}
        </FieldContainer>
    );
});
export default Dropdown;
