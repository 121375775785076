import nb from 'date-fns/locale/nb';

const getCustomNBLocale = (): Locale => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'];

    const customNBLocale: any = {
        localize: {
            ...nb.localize,
            month: (n: number) => months[n],
        },
    };
    return { ...nb, ...customNBLocale };
};

export { getCustomNBLocale };
