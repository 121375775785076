import React, { ChangeEvent, LegacyRef, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PrimaryButton, SvgIcon } from '../../components';
import { FileObject } from './ImageUploader';
import UploadedImageDisplay from './UploadedImageDisplay';
import { supportedImageFormats } from './utils';

type FileDropZoneWrapperProps = { dragging: boolean; height?: string };
const FileDropZoneWrapper = styled.div`
    margin: 1px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    ${({ dragging }: FileDropZoneWrapperProps) => (dragging ? 'opacity: 0.6;' : 'opacity: 1;')};
    ${({ dragging }: FileDropZoneWrapperProps) => (dragging ? 'background-color: #f2f2f2;' : null)};
    width: 100%;
    background-image: ${`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='0' ry='0' stroke='%23${getComputedStyle(
        document.body,
    )
        .getPropertyValue('--primary-color')
        .slice(
            1,
        )}' stroke-width='2' stroke-dasharray='12%2c12' stroke-dashoffset='' stroke-linecap='square'/%3e%3c/svg%3e")`};
`;

const UploadedFilesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    padding: 0.7rem 0;
`;
const Info = styled.p`
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0.5rem 0 0.857rem 0;
`;

const Instructions = styled.p`
    font-size: 1rem;
    margin-top: 1.7rem;
`;

const SvgIconWrapper = styled.div`
    svg {
        path {
            stroke: var(--primary-color);
        }
    }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        line-height: 1.4rem;
    }
`;

const Container = styled.div`
    width: 100%;
`;

interface FileDropZoneProps {
    fileDropZone: RefObject<HTMLDivElement> | null | undefined;
    dragging: boolean;
    height?: string;
    maxImageSize: number;
    fileInputField: LegacyRef<HTMLInputElement> | undefined;
    imageUploadChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
    onImageUploadButtonClickHandler: () => void;
    uploadedFiles: FileObject[];
    handleRemoveImage?: (selectedFileName: string) => void;
    handleRefetchImage?: (selectedFileName: string) => void;
}

const FileDropZoneComponent: React.FC<React.PropsWithChildren<FileDropZoneProps>> = ({
    fileDropZone,
    dragging,
    height,
    maxImageSize,
    fileInputField,
    imageUploadChangeHandler,
    onImageUploadButtonClickHandler,
    uploadedFiles,
    handleRemoveImage,
    handleRefetchImage,
}: FileDropZoneProps) => {
    const { t } = useTranslation('common');
    const showSelectedFiles = !!uploadedFiles.length && handleRemoveImage && handleRefetchImage;
    return (
        <Container>
            <FileDropZoneWrapper ref={fileDropZone} dragging={dragging} height={height}>
                <SvgIconWrapper>
                    <SvgIcon name="UploadOutlineIcon" alt="Upload file" />
                </SvgIconWrapper>

                <Info>{t('dragAndDropFilesHere')}</Info>

                <form>
                    <input
                        aria-hidden="true"
                        type="file"
                        ref={fileInputField}
                        accept={supportedImageFormats.join(',')}
                        name="file"
                        style={{ display: 'none' }}
                        onChange={imageUploadChangeHandler}
                    />
                </form>
                <StyledPrimaryButton onClick={onImageUploadButtonClickHandler}>
                    {t('uploadFromYourComputer')}
                </StyledPrimaryButton>
                <Instructions>{t('maxAcceptableFileSize', { maxImageSize })}</Instructions>
            </FileDropZoneWrapper>

            {showSelectedFiles && (
                <UploadedFilesContainer>
                    {uploadedFiles.map((fileObject, index) => (
                        <UploadedImageDisplay
                            key={fileObject.file.name + index}
                            file={fileObject.file}
                            fileState={fileObject.fileState}
                            isCompact
                            handleRemoveImage={handleRemoveImage}
                            handleRefetchImage={handleRefetchImage}
                        />
                    ))}
                </UploadedFilesContainer>
            )}
        </Container>
    );
};

export default FileDropZoneComponent;
