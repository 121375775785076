import { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import SvgIcon from '../icons/SvgIcon';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const ErrorHeader = styled.h1`
    color: var(--text-high-emphasis-color);
    text-align: center;
    font-weight: 900;
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin: 0.5rem;
`;

const EmptyIconImageContainer = styled.div`
    min-height: 0;
    display: flex;
    justify-content: center;
    svg {
        height: 13.23rem;
        width: 9.7rem;
    }
    && #path0 {
        fill: var(--primary-color);
        fill-opacity: 1;
    }
    && #path1 {
        fill: var(--primary-color);
        fill-opacity: 0.2;
    }
    && #path2 {
        fill: var(--primary-color);
        fill-opacity: 0.1;
    }
`;

interface ErrorDisplayProps {
    header: string;
}
const ErrorDisplay = ({ header, children }: PropsWithChildren<ErrorDisplayProps>): ReactElement => {
    return (
        <Container>
            <EmptyIconImageContainer>
                <SvgIcon name="EmptyIcon" />
            </EmptyIconImageContainer>
            <ErrorHeader>{header}</ErrorHeader>
            {children}
        </Container>
    );
};

export default ErrorDisplay;
