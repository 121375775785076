import { TicketComment, TicketCommentWithSeen } from '../../../../api/apiTypes/ticketingApiTypes';

const userHasSeen = (comments: TicketComment[], numberOfSeenComments: number): TicketCommentWithSeen[] => {
    return comments.map((comment: TicketComment, index: number) => {
        return {
            ...comment,
            seen: index >= numberOfSeenComments,
        };
    });
};

export default userHasSeen;
