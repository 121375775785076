/**
 * Takes a users name, splits it and returns first name and last name
 * @param name a users name
 * @returns an array of two elements [firstName, lastName]
 */
const splitName = (name: string): [string, string] => {
    const nameArray = name.split(' ');
    return [nameArray[0] ?? '', nameArray[Math.max(0, nameArray.length - 1)] ?? ''];
};

export default splitName;
