import { ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ShimmerAnimation } from '../../../../../components';
import { InventoryColumns } from '../utils';
import { ColumnType } from './InventoryTable';

const greyColorLight = '#e4e4e4';
const greyColor = '#c4c4c4';

const fadeOut = keyframes`
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
`;

type WrapperProps = { show?: boolean };
const Wrapper = styled.div`
    margin-top: 1rem;
    position: relative;
    padding: 0 0.5rem 1rem 0.5rem;
    animation: ${({ show }: WrapperProps) =>
        show
            ? 'none;'
            : css`
                  ${fadeOut} 0.1s linear forwards;
              `};
`;

type ContainerProps = { collapse?: boolean };
const Container = styled.div`
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    box-shadow: inset 0 0 0 1px #d4d6d7;
    padding: 1rem 1rem;
    height: 6rem;

    ${({ collapse }: ContainerProps) =>
        collapse && {
            height: 'auto',
            display: 'grid',
            justifyContent: 'unset',
            gridTemplateAreas: `'${InventoryColumns.Name} ${InventoryColumns.Price}' 
                '${InventoryColumns.Type} ${InventoryColumns.Type}'
                 '${InventoryColumns.Supplier} ${InventoryColumns.Status}'`,
            gridTemplateColumns: 'auto auto',
        }};
`;

const ProductsWrapper = styled.div`
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const ItemContainer = styled.div`
    display: contents;
    align-items: center;
    margin-bottom: 1rem;
`;

const SkeletonItem = styled.div`
    height: 1.285rem;
    background-color: ${greyColorLight};
    border-radius: 0.357rem;
`;

type StatusProps = { collapse: boolean };
const Status = styled.div`
    width: 10%;
    min-width: 4.286rem;
    background-color: ${greyColor};
    height: 1.714rem;
    border-radius: 2.143rem;
    grid-area: ${InventoryColumns.Status};
    justify-self: flex-end;

    ${({ collapse }: StatusProps) =>
        collapse && {
            alignSelf: 'flex-start',
        }}
`;

type NameProps = { collapse: boolean };
const Name = styled(SkeletonItem)`
    width: ${({ collapse }: NameProps) => (collapse ? '40%' : '15%')};
    grid-area: ${InventoryColumns.Name};
`;

const Supplier = styled(Name)`
    grid-area: ${InventoryColumns.Supplier};
`;

type TypeProps = { collapse: boolean };
const Type = styled(SkeletonItem)`
    width: ${({ collapse }: TypeProps) => (collapse ? '40%' : '10%')};
    grid-area: ${InventoryColumns.Type};
`;

type CategoryProps = { collapse: boolean };
const Category = styled(SkeletonItem)`
    width: 10%;
    display: ${({ collapse }: CategoryProps) => (collapse ? 'none' : 'block')};
    grid-area: ${InventoryColumns.Category};
`;

type PriceProps = { collapse: boolean };
const Price = styled(SkeletonItem)`
    width: ${({ collapse }: PriceProps) => (collapse ? '60%' : '10%')};
    grid-area: ${InventoryColumns.Price};

    ${({ collapse }: PriceProps) =>
        collapse && {
            justifySelf: 'flex-end',
        }}
`;

interface PurchasesSkeletonLoaderProps {
    show?: boolean;
    numberOfItems?: number;
    collapse?: boolean;
    columns: ColumnType;
}

const InventorySkeletonLoader = ({
    show,
    collapse = false,
    numberOfItems = 1,
    columns,
}: PurchasesSkeletonLoaderProps): ReactElement => {
    const viewColumns = columns.map((column) => column.key);
    const item = (index: number) => (
        <Wrapper show={show} key={index}>
            <Container collapse={collapse}>
                <ItemContainer>
                    {viewColumns.includes(InventoryColumns.Name) && <Name collapse={collapse} />}
                </ItemContainer>
                <ItemContainer>
                    {viewColumns.includes(InventoryColumns.Status) && <Status collapse={collapse} />}
                </ItemContainer>
                <ItemContainer>
                    {viewColumns.includes(InventoryColumns.Type) && <Type collapse={collapse} />}
                </ItemContainer>
                <ItemContainer>
                    {viewColumns.includes(InventoryColumns.Category) && <Category collapse={collapse} />}
                </ItemContainer>
                <ItemContainer>
                    {viewColumns.includes(InventoryColumns.Price) && <Price collapse={collapse} />}
                </ItemContainer>
                <ItemContainer>
                    {viewColumns.includes(InventoryColumns.Supplier) && <Supplier collapse={collapse} />}
                </ItemContainer>
            </Container>
            <ShimmerAnimation />
        </Wrapper>
    );
    const items = Array.from({ length: numberOfItems }, (_, index) => item(index));
    return <ProductsWrapper>{items}</ProductsWrapper>;
};

export default InventorySkeletonLoader;
