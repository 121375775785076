import { TicketingProvider } from '../Ticketing';
import Worklist from './Worklist';

export default function CompanyTicketsWithProvider() {
    return (
        <TicketingProvider>
            <Worklist />
        </TicketingProvider>
    );
}
