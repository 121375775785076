// @app/common/sidebar/duck/operations.js
import { Creators } from './actions';
const showSidebar = Creators.showSidebar;
const hideSidebar = Creators.hideSidebar;

const handleShowSidebar = () => {
    return (dispatch) => {
        dispatch(showSidebar());
    };
};

const handleHideSidebar = () => {
    return (dispatch) => {
        dispatch(hideSidebar());
    };
};

export default {
    handleShowSidebar,
    handleHideSidebar,
};
