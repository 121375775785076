import { ReactElement } from 'react';
import styled from 'styled-components';

const Picture = styled.picture`
    min-height: 10rem;
`;

const Header = styled.h4`
    font-size: 1.57rem;
    color: var(--success-color-dark);
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 2.5rem;
    text-align: center;
`;

const Description = styled.div`
    text-align: center;
    font-size: 1.17rem;
    margin-bottom: 5rem;
`;

interface SuccessDisplayProps {
    header?: string;
    description?: string;
    alt: string;
}
const SuccessDisplay = ({ header, description, alt }: SuccessDisplayProps): ReactElement => {
    return (
        <>
            <Picture>
                <source srcSet="./SuccessState.webp" type="image/webp" />
                <source srcSet="./SuccessState.jpg" type="image/jpeg" />
                <img src="SuccessState.jpg" alt={alt} />
            </Picture>
            {header && <Header>{header}</Header>}
            {description && <Description>{description}</Description>}
        </>
    );
};

export default SuccessDisplay;
