import React, { createContext, useCallback, useEffect, useReducer } from 'react';
import { shoppingcartState as shoppingcartStateLocalStorageKey } from '../../../../common/LocalStorageKeys/LocalStorageKeys';
import { DeliveryMethod, FoodLine, orderedFoodLine } from '../../types';
import reducer, { ShoppingCartState } from './reducer';

type ShoppingCartContext = {
    state: ShoppingCartState;
    addFoodLine: (foodLine: orderedFoodLine) => void;
    removeFoodLine: (id: number) => void;
    removeOrder: () => void;
    changeFoodLine: (foodLineId: number, quantity: number) => void;
    setDeliveryDateTime: (deliveryDateTime: Date, meetingId: string | null) => void;
    setDeliveryMethod: (deliveryMethod: DeliveryMethod) => void;
    updateFoodLines: (foodLines: FoodLine[]) => void;
};
interface ShoppingCartProviderProps {
    children: React.ReactNode;
}
const getInitialState = (): ShoppingCartState => {
    const minState: ShoppingCartState = {
        status: 'empty',
        foodLines: [],
        deliveryDateTime: new Date(),
        deliveryMethod: 'hente',
        attachedMeeting: null,
    };
    try {
        const localStorage = window.localStorage.getItem(shoppingcartStateLocalStorageKey);
        if (localStorage) {
            const parsed = JSON.parse(localStorage);
            return {
                ...parsed,
                deliveryDateTime: new Date(parsed.deliveryDateTime),
                foodLines: parsed.foodLines.map((line: orderedFoodLine & { orderDeadline: string }) => ({
                    ...line,
                    orderDeadline: new Date(line.orderDeadline),
                })),
            };
        }
        return minState;
    } catch (e) {
        console.error('Error fetching ShoppingCart from localStorage', e);
        return minState;
    }
};

const ShoppingCartContext = createContext<ShoppingCartContext>({
    state: getInitialState(),
    addFoodLine: () => null,
    removeFoodLine: () => null,
    removeOrder: () => null,
    changeFoodLine: () => null,
    setDeliveryDateTime: () => null,
    setDeliveryMethod: () => null,
    updateFoodLines: () => null,
});

const ShoppingCartProvider: React.FC<React.PropsWithChildren<ShoppingCartProviderProps>> = ({
    children,
}: ShoppingCartProviderProps) => {
    const [state, dispatch] = useReducer(reducer, getInitialState());

    const addFoodLine = (foodLine: orderedFoodLine): void => {
        dispatch({
            type: 'ADD_FOOD_LINE',
            payload: { foodLine },
        });
    };
    useEffect(() => {
        if (state.foodLines && state.foodLines.length > 0) {
            window.localStorage.setItem(
                shoppingcartStateLocalStorageKey,
                JSON.stringify({
                    foodLines: state.foodLines,
                    deliveryDateTime: state.deliveryDateTime,
                    deliveryMethod: state.deliveryMethod,
                    attachedMeeting: state.attachedMeeting,
                }),
            );
        } else {
            window.localStorage.removeItem(shoppingcartStateLocalStorageKey);
        }
    }, [state.foodLines, state.deliveryDateTime, state.deliveryMethod, state.attachedMeeting]);

    const removeFoodLine = useCallback((foodLineId: number): void => {
        dispatch({
            type: 'REMOVE_FOOD_LINE',
            payload: { foodLineId },
        });
    }, []);

    const removeOrder = useCallback((): void => {
        dispatch({
            type: 'REMOVE_ORDER',
        });
    }, []);

    const changeFoodLine = useCallback((foodLineId: number, quantity: number): void => {
        dispatch({
            type: 'CHANGE_FOOD_LINE',
            payload: { foodLineId, quantity },
        });
    }, []);

    const setDeliveryDateTime = useCallback((deliveryDateTime: Date, meetingId: string | null): void => {
        dispatch({
            type: 'CHANGE_DELIVERY_DATETIME',
            payload: { deliveryDateTime, meetingId },
        });
    }, []);

    const setDeliveryMethod = useCallback((deliveryMethod: DeliveryMethod): void => {
        dispatch({
            type: 'CHANGE_DELIVERY_METHOD',
            payload: { deliveryMethod },
        });
    }, []);

    const updateFoodLines = useCallback((foodLines: FoodLine[]): void => {
        dispatch({
            type: 'UPDATE_FOOD_LINES',
            payload: { foodLines },
        });
    }, []);

    return (
        <ShoppingCartContext.Provider
            value={{
                state,
                addFoodLine,
                removeFoodLine,
                removeOrder,
                changeFoodLine,
                setDeliveryDateTime,
                setDeliveryMethod,
                updateFoodLines,
            }}
        >
            {children}
        </ShoppingCartContext.Provider>
    );
};

export { ShoppingCartContext, ShoppingCartProvider };
