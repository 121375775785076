import { AxiosError, AxiosResponse } from 'axios';
import type { MutationResultPair, QueryResult } from 'react-query';
import { QueryCache, useMutation, useQuery, useQueryCache } from 'react-query';
import {
    CategoryModel,
    CreateCategoryDto,
    SubcategoryDto,
    GetSubcategoryModel,
    UpdateCategoryDto,
} from '../../../../api/apiTypes/cmsApiTypes';
import {
    createCategory,
    getCategories,
    createSubcategory,
    deleteCategory,
    updateCategory,
    updateSubcategory,
    deleteSubcategory,
    manageCategoryInSideNav,
} from '../../../../api/cmsApi';

export const categoriesKey = 'categories';

export const useGetCategories = (
    count?: boolean,
    isPinToSidebar?: boolean,
): QueryResult<CategoryModel[], Error | AxiosError<string>> => {
    return useQuery(
        [categoriesKey, count, isPinToSidebar],
        async () =>
            (await getCategories(count, isPinToSidebar)).data.map((item) => ({
                ...item,
                children: item.children.sort((a, b) => a.name.localeCompare(b.name)),
            })),
        {
            staleTime: 1000 * 60,
            cacheTime: 1000 * 60 * 60 * 24,
        },
    );
};

export const invalidateCategoryCount = (queryCache: QueryCache): void => {
    queryCache.invalidateQueries([categoriesKey, true]);
};

export const useCreateCategory = (): MutationResultPair<
    AxiosResponse<CategoryModel>,
    Error | AxiosError<string>,
    CreateCategoryDto,
    never
> => {
    const cache = useQueryCache();

    return useMutation<AxiosResponse<CategoryModel>, Error | AxiosError<string>, CreateCategoryDto, never>(
        async (dto) => {
            return await createCategory(dto);
        },
        {
            onSuccess: (response) => {
                cache.invalidateQueries([categoriesKey, true]);
                const queries = cache.getQueries<CategoryModel[], Error | AxiosError<string>>(categoriesKey);
                queries.forEach((query) =>
                    query?.setData((old) => (old ? [response.data, ...old].sort() : [response.data])),
                );
            },
        },
    );
};

export const useCreateSubcategory = (): MutationResultPair<
    AxiosResponse<GetSubcategoryModel>,
    Error | AxiosError<string>,
    [SubcategoryDto, string],
    never
> => {
    const cache = useQueryCache();

    return useMutation<AxiosResponse<GetSubcategoryModel>, Error | AxiosError<string>, [SubcategoryDto, string], never>(
        async ([dto, parentId]) => {
            return await createSubcategory(dto, parentId);
        },
        {
            onSuccess: (response) => {
                cache.invalidateQueries([categoriesKey, true]);
                const queries = cache.getQueries<CategoryModel[], Error | AxiosError<string>>(categoriesKey);
                queries.forEach((query) =>
                    query?.setData((old) =>
                        old
                            ? old.map((item) => {
                                  if (response.data.parent.id === item.id) {
                                      return {
                                          ...item,
                                          children: [
                                              { ...response.data, parent: response.data.parent.id },
                                              ...item.children,
                                          ].sort(),
                                      };
                                  }
                                  return item;
                              })
                            : [],
                    ),
                );
            },
        },
    );
};

export const useUpdateCategory = (): MutationResultPair<
    AxiosResponse<CategoryModel>,
    Error | AxiosError<string>,
    [string, UpdateCategoryDto],
    never
> => {
    const cache = useQueryCache();
    return useMutation<AxiosResponse<CategoryModel>, Error | AxiosError<string>, [string, UpdateCategoryDto], never>(
        ([articleCategoryId, dto]) => updateCategory(articleCategoryId, dto),
        {
            onSuccess: (response) => {
                const queries = cache.getQueries<CategoryModel[], Error | AxiosError<string>>(categoriesKey);
                queries.forEach((query) =>
                    query?.setData((old) =>
                        old
                            ? old
                                  .map((item) =>
                                      item.id === response.data.id
                                          ? {
                                                ...response.data,
                                                countArticles: item.countArticles,
                                                children: item.children,
                                            }
                                          : item,
                                  )
                                  .sort((a, b) => a.name.localeCompare(b.name))
                            : [],
                    ),
                );
            },
        },
    );
};

export const useUpdateSubcategory = (): MutationResultPair<
    AxiosResponse<GetSubcategoryModel>,
    Error | AxiosError<string>,
    [string, SubcategoryDto],
    never
> => {
    const cache = useQueryCache();
    return useMutation<AxiosResponse<GetSubcategoryModel>, Error | AxiosError<string>, [string, SubcategoryDto], never>(
        ([articleCategoryId, dto]) => updateSubcategory(articleCategoryId, dto),
        {
            onSuccess: (response) => {
                const queries = cache.getQueries<CategoryModel[], Error | AxiosError<string>>(categoriesKey);
                queries.forEach((query) =>
                    query?.setData((old) =>
                        old
                            ? old.map((item) =>
                                  item.id === response.data.parent.id
                                      ? {
                                            ...item,
                                            children: item.children
                                                .map((item) =>
                                                    item.id === response.data.id
                                                        ? {
                                                              ...response.data,
                                                              parent: response.data.parent.id,
                                                              countArticles: item.countArticles,
                                                          }
                                                        : item,
                                                )
                                                .sort((a, b) => a.name.localeCompare(b.name)),
                                        }
                                      : item,
                              )
                            : [],
                    ),
                );
            },
        },
    );
};

export const useDeleteCategory = (): MutationResultPair<
    AxiosResponse<CategoryModel>,
    Error | AxiosError<string>,
    string,
    never
> => {
    const cache = useQueryCache();
    return useMutation<AxiosResponse<CategoryModel>, Error | AxiosError<string>, string, never>(
        async (articleCategoryId) => {
            return await deleteCategory(articleCategoryId);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(categoriesKey);
            },
        },
    );
};

export const useDeleteSubcategory = (): MutationResultPair<
    AxiosResponse<CategoryModel>,
    Error | AxiosError<string>,
    string,
    never
> => {
    const cache = useQueryCache();
    return useMutation<AxiosResponse<CategoryModel>, Error | AxiosError<string>, string, never>(
        async (articleSubcategoryId) => {
            return await deleteSubcategory(articleSubcategoryId);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(categoriesKey);
            },
        },
    );
};

export const useManageCategoriesInSideNav = (): MutationResultPair<
    AxiosResponse<CategoryModel>,
    Error | AxiosError<string>,
    [string | number, boolean],
    never
> => {
    const cache = useQueryCache();
    return useMutation<AxiosResponse<CategoryModel>, Error | AxiosError<string>, [string | number, boolean], never>(
        async ([categoryId, pinToSidebar]) => {
            return await manageCategoryInSideNav(categoryId, pinToSidebar);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(categoriesKey);
            },
        },
    );
};
