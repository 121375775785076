import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PlusIconSvg from '../../icons/PlusIconSvg';
import { Button, FlexBox, Typography } from '../index';
import { UploadAnCoverImageBlockProps } from './types';

const UploadAnCoverImageBlock: FC<UploadAnCoverImageBlockProps> = ({ showModalHandler }) => {
    const { t } = useTranslation('events');

    return (
        <FlexBox align={'center'} gap={'0.571rem'}>
            <Button
                width={'auto'}
                br={'50%'}
                background={'var(--primary-color)'}
                align={'center'}
                justify={'center'}
                pd={'0.571rem'}
                onClick={showModalHandler}
            >
                <PlusIconSvg fill={'#fff'} />
            </Button>
            <FlexBox dir={'column'}>
                <Typography
                    color={'var(--primary-color)'}
                    fontWeight={700}
                    lineHeight={'1.571rem'}
                    letterSpacing={'0.014rem'}
                    fontSize={'1rem'}
                >
                    {t('coverImageBlockUploadBtnText')}
                </Typography>
                <Typography color={'#677074'} fontWeight={400} lineHeight={'1.714rem'} fontSize={'1rem'}>
                    {t('coverImageBlockUploadBtnSubText')}
                </Typography>
            </FlexBox>
        </FlexBox>
    );
};

export default UploadAnCoverImageBlock;
