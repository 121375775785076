import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarMenuItem } from '../../components';
import useHouseOfOsloNavigation from './useHouseOfOsloNavigation';

const HouseOfOsloDynamicMenuItem = (): ReactElement => {
    const { houseOfOsloLink, goToHouseOfOsloPage } = useHouseOfOsloNavigation();
    const { pathname } = useLocation();

    const isArticlePathName = pathname?.includes(houseOfOsloLink);

    return (
        <SidebarMenuItem
            icon="CalendarIcon"
            isActive={isArticlePathName}
            onClick={goToHouseOfOsloPage}
            text="House of Oslo"
        />
    );
};

export default HouseOfOsloDynamicMenuItem;
