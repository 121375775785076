import { useEffect, useMemo, useRef } from 'react';
import { addMinutes, differenceInMilliseconds, startOfMinute } from 'date-fns';
import type { QueryResult } from 'react-query';
import { useQuery, useQueryCache } from 'react-query';
import { getMenuForDateFromApi } from '../../../api/foodApi';
import { Service } from '../../../common/lifeAtWorkServices';
import useServiceIsActive from '../../../utility/hooks/useServiceIsActive';
import { FoodMenu } from '../types';

const foodMenuQueryCacheKey = 'foodMenu';

export const useGetMenu = (date: Date | null): QueryResult<FoodMenu, string> => {
    const hasFoodTimeService = useServiceIsActive(Service.FoodTime);

    // Api doesn't care about time so we set it to 12 to have a good query key
    const getConstantTimeOfDay = (date: Date): Date => {
        const newOne = new Date(date);
        newOne.setMilliseconds(0);
        newOne.setSeconds(0);
        newOne.setMinutes(0);
        newOne.setHours(12);
        return newOne;
    };

    const innerDate = useMemo(() => {
        return date ? getConstantTimeOfDay(date) : null;
    }, [date]);

    const roundUp15min = (date: Date) => {
        date.setMinutes(date.getMinutes() === 0 ? 0 : date.getMinutes() + 15 - (date.getMinutes() % 15));
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    };

    const lastKey = useRef<Date | null>(innerDate);

    useEffect(() => {
        lastKey.current = innerDate;
    }, [innerDate]);
    const queryCache = useQueryCache();

    useEffect(() => {
        const setupCacheClearOnEvery15Mins = () => {
            const currentTime = new Date();

            const next15Mins = currentTime.getMinutes() % 15;
            const timeoutInMs = differenceInMilliseconds(
                startOfMinute(addMinutes(currentTime, next15Mins)),
                currentTime,
            );
            const timeout = setTimeout(() => {
                queryCache.invalidateQueries(foodMenuQueryCacheKey);
            }, timeoutInMs);
            return () => clearTimeout(timeout);
        };
        return setupCacheClearOnEvery15Mins();
    }, [queryCache]);

    return useQuery(
        [foodMenuQueryCacheKey, innerDate],
        () =>
            getMenuForDateFromApi(innerDate ? innerDate.toISOString() : undefined)
                .then((result) => result.data)
                .then((result) => ({
                    ...result,
                    departmentBusinessHoursFrom: new Date(result.departmentBusinessHoursFrom),
                    departmentBusinessHoursTo: new Date(result.departmentBusinessHoursTo),
                    menuDateTime: roundUp15min(new Date(result.menuDateTime)),
                    ...result.foodMenus[0],
                    foodGroups: result.foodMenus[0].foodGroups.map((foodGroup) => ({
                        ...foodGroup,
                        foodLines: foodGroup.foodLines.map((foodLine) => ({
                            ...foodLine,
                            orderDeadline: new Date(foodLine.orderDeadline),
                            foodLineId: foodLine.id,
                            allergenList: foodLine.allergenList ? foodLine.allergenList.split(',') : [],
                            taxPriceWithTableService:
                                Math.round(foodLine.taxWithTableService * foodLine.priceNet) / 100,
                            taxPriceWithoutTableService:
                                Math.round(foodLine.taxWithoutTableService * foodLine.priceNet) / 100,
                        })),
                    })),
                    departmentOrderDeadline: new Date(result.departmentOrderDeadline),
                })),
        {
            initialData: () => {
                const data = queryCache.getQueryData<FoodMenu>([foodMenuQueryCacheKey, lastKey.current]);
                if (data && innerDate) {
                    // we only care about time so we change the date
                    const departmentBusinessHoursFrom = new Date(data.departmentBusinessHoursFrom);
                    departmentBusinessHoursFrom.setFullYear(
                        innerDate.getFullYear(),
                        innerDate.getMonth(),
                        innerDate.getDate(),
                    );
                    const departmentBusinessHoursTo = new Date(data.departmentBusinessHoursTo);
                    departmentBusinessHoursTo.setFullYear(
                        innerDate.getFullYear(),
                        innerDate.getMonth(),
                        innerDate.getDate(),
                    );
                    return {
                        ...data,
                        departmentBusinessHoursFrom,
                        departmentBusinessHoursTo,
                    };
                }
                return data;
            },
            initialStale: true,
            onSuccess: (data) => {
                if (!innerDate) {
                    queryCache.setQueryData([foodMenuQueryCacheKey, getConstantTimeOfDay(data.menuDateTime)], data);
                }
            },
            cacheTime: 1000 * 60 * 60,
            refetchOnMount: false,
            staleTime: innerDate ? 1000 * 60 * 60 : 1000 * 60 * 5,
            enabled: hasFoodTimeService,
        },
    );
};

export const useGetMinDate = (): Date | undefined => {
    const date = useGetMenu(null).data?.menuDateTime;
    return useMemo(() => (date ? new Date(date) : undefined), [date]);
};
