import { useState, useEffect, useCallback, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Message, Form, Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useNavigation, useGetNavigationUrl } from '../../utility';
import { getBrowserLanguage } from '../../utility/languageUtilities/getBrowserLanguage';
import ApprovePrivacyPolicy from '../ApprovePrivacyPolicy/components/ApprovePrivacyPolicy';
import ApproveUser from '../ApprovePrivacyPolicy/components/ApproveUser';
import ConfirmButton from '../ApprovePrivacyPolicy/components/ConfirmButton';
import { ApplicationPaths, QueryParameterNames } from '../Authorization';
import useCurrentUserContext from '../Contexts/useCurrentUserContext';
import * as api from './api';

const Wrapper = styled.div`
    background-color: #fbfbfb;
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const Test = styled.div`
    max-width: calc(var(--spacer) * 70);
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
    @media (min-width: 1091px) {
        min-width: calc(var(--spacer) * 65);
    }
    @media (max-width: 1090px) {
        min-width: min(calc(var(--spacer) * 50), 100%);
    }
`;

const ApprovePrivacyPolicyWrapper = () => {
    const { state, dispatch } = useCurrentUserContext();
    const location = useLocation();
    const nav = useNavigation();
    const getNavUrl = useGetNavigationUrl();
    const { t, i18n } = useTranslation('common');
    const usersBrowserLanguage = i18n.resolvedLanguage;
    const isDataLoading = state.isLoadingCurrentUser || state.isLoadingPrivacyPolicy;
    const [phoneInputError, setPhoneInputError] = useState(
        state.currentUser.phoneNumber
            ? { value: 0, errorMessage: '' }
            : {
                  value: 14,
                  errorMessage: t('errorMessagePhoneNumberRequired'),
              },
    );
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const getSchema = (state) => {
        if (!state.currentUser?.confirmedDate && !state.privacyPolicy?.approvedDate) {
            return fullSchema;
        }
        if (!state.currentUser?.confirmedDate) {
            return userSchema;
        }
        if (!state.privacyPolicy?.approvedDate) {
            return privacyPolicySchema;
        }
    };

    const privacyPolicySchema = useMemo(
        () =>
            yup.object({
                approved: yup.boolean().oneOf([true], t('errorMessagePrivacyPolicyMustApproved')),
            }),
        [],
    );

    const userSchema = useMemo(
        () =>
            yup.object({
                firstname: yup.string().required(t('errorMessageFirstNameRequired')),
                lastname: yup.string().required(t('errorMessageLastNameRequired')),
                email: yup.string().email(t('errorMessageEmailNotValid')).required(t('errorMessageEmailRequired')),
                phonenumber: yup
                    .string()
                    .required(t('errorMessagePhoneNumberRequired'))
                    .min(phoneInputError.value, phoneInputError.errorMessage),
            }),
        [phoneInputError],
    );

    const fullSchema = useMemo(() => userSchema.concat(privacyPolicySchema), [userSchema, privacyPolicySchema]);

    const schema = getSchema(state);
    const defaultValues = {
        firstname: state.currentUser.firstName,
        lastname: state.currentUser.lastName,
        email: state.currentUser.email,
        phonenumber: state.currentUser.phoneNumber || '+47',
        language: getBrowserLanguage(usersBrowserLanguage),
    };
    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        setValue,
        getValues,
        register,
        trigger,
        reset,
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [state]);

    useEffect(() => {
        if (touchedFields.phonenumber) {
            trigger('phonenumber');
        }
    }, [phoneInputError, touchedFields.phonenumber, trigger]);
    const redirectToOrigin = useCallback(() => {
        if (location.state) {
            nav.replace(
                `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${location.state.returnUrl}${location.state.search}`,
            );
        } else {
            nav.replace(`${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=/${getNavUrl('/')}`);
        }
    }, [getNavUrl, location.state, nav]);

    useEffect(() => {
        if (state.currentUser?.confirmedDate && state.privacyPolicy?.approvedDate) {
            if (location.state) {
                nav.replace(`${location.state.returnUrl}${location.state.search}`);
            } else {
                nav.replace('/');
            }
        }
    }, [location.state, state.currentUser, state.privacyPolicy, nav]);

    const formIsValid = useCallback((errors) => {
        if (errors) {
            if (Object.keys(errors || {}).length > 0) {
                return false;
            }
        }
        return true;
    }, []);

    const onSubmit = async (data, e) => {
        if (e instanceof Event) e.preventDefault();
        if (formIsValid()) submitForm(data);
    };

    const submitForm = async (data) => {
        try {
            setIsSubmittingForm(true);
            let requests = [];
            if (!state.currentUser?.confirmedDate) requests.push(api.confirmUser(data, dispatch));
            if (!state.privacyPolicy?.approvedDate) {
                requests.push(api.approvePrivacyPolicy(state.privacyPolicy?.version, state.currentUser.id, dispatch));
            }
            await Promise.all(requests);
            redirectToOrigin();
        } catch (ex) {
            console.error(ex);
        } finally {
            setIsSubmittingForm(false);
        }
    };

    const renderLoader = () => (
        <Dimmer active inverted>
            <Loader inverted>{t('load')}</Loader>
        </Dimmer>
    );

    const renderForm = () => (
        <Form onSubmit={handleSubmit((e, data) => onSubmit(e, data))} error={!formIsValid(errors)}>
            {!state.privacyPolicy?.approvedDate && !state.isLoadingPrivacyPolicy && (
                <ApprovePrivacyPolicy
                    control={control}
                    errors={errors}
                    hasApproved={state.privacyPolicy?.approvedDate}
                    privacyPolicyUrl={state.privacyPolicy?.url}
                />
            )}

            {!state.currentUser?.confirmedDate && (
                <ApproveUser
                    control={control}
                    errors={errors}
                    user={state.currentUser}
                    emailIsReadOnly={true}
                    showInfo={!state.privacyPolicy || state.privacyPolicy.approvedDate}
                    onError={(value, errorMessage) => {
                        setPhoneInputError({ value, errorMessage });
                    }}
                    register={register}
                    touchedFields={touchedFields}
                    trigger={trigger}
                    setValue={setValue}
                    getValues={getValues}
                />
            )}
            {errors?.approved && (
                <Message error header={t('errorMessageFormContainsError')} list={[errors.approved.message]} />
            )}
            <ConfirmButton
                isLoading={state.isAwaitingConfirmUser || state.isAwaitingApprovePrivacyPolicy || isSubmittingForm}
                disabled={!formIsValid(errors)}
            />
        </Form>
    );

    return (
        <Wrapper>
            <Test>{isDataLoading ? renderLoader() : renderForm()}</Test>
        </Wrapper>
    );
};

export default ApprovePrivacyPolicyWrapper;
