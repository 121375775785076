import { createContext, PropsWithChildren, ReactElement, useReducer } from 'react';
import { initialState, reducer } from './reducer';
import { SidesheetContextType, SidesheetContextAction, SidesheetState } from './types';

export const SidesheetContext = createContext<SidesheetContextType>({
    state: initialState,
    dispatch: () => {
        return;
    },
});

export const SidesheetProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const [state, dispatch] = useReducer<React.Reducer<SidesheetState, SidesheetContextAction>>(reducer, initialState);

    const value = {
        state,
        dispatch,
    };

    return <SidesheetContext.Provider value={value}>{children}</SidesheetContext.Provider>;
};
