import React, { Suspense, useEffect, useState } from 'react';
import { Loader } from '../..';
import lazy from '../../../utility/lazy';

interface ModuleContainerProps {
    selectedModuleName: string;
}

const ModuleContainerComponent: React.FC<React.PropsWithChildren<ModuleContainerProps>> = ({
    selectedModuleName,
}: ModuleContainerProps): JSX.Element => {
    const [ImportedModule, setImportedModule] = useState<React.ComponentType | null>(null);
    useEffect(() => {
        const loadModule = () => {
            setImportedModule(lazy(() => import(`../../../modules/${selectedModuleName}/index.js`)));
        };
        loadModule();
    }, [selectedModuleName]);

    return <Suspense fallback={<Loader />}>{ImportedModule && <ImportedModule />}</Suspense>;
};

export default ModuleContainerComponent;
