import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const NoAccessOrDontExistWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;

    a {
        display: block;
    }
`;

const Header = styled.h3`
    color: red;
    padding: 1rem;
`;

interface ErrorAuthorizationProps {
    type: 'Login' | 'Logout';
}

const ErrorAuthorization: React.FC<React.PropsWithChildren<ErrorAuthorizationProps>> = ({ type }) => {
    const { t } = useTranslation('authorization');

    return (
        <NoAccessOrDontExistWrapper>
            <Header>{type === 'Login' ? t('errorWhenLogIn') : t('errorWhenLogOut')}</Header>
        </NoAccessOrDontExistWrapper>
    );
};

export default ErrorAuthorization;
