import { ReactElement } from 'react';
import { Icon } from 'semantic-ui-react';
import styled, { css, keyframes } from 'styled-components';
import { MobileViewBreakpoint } from '../../../../../../common/ScreenSizeBreakPoints';
import { ShimmerAnimation } from '../../../common/index';

const greyColor = '#e9ebeb';
const greyColorDark = '#d4d6d7';

const fadeOut = keyframes`
0% {
  opacity: 100%;
}
100% {
  opacity: 0%;
}
`;

type WrapperProps = { show?: boolean };
const Wrapper = styled.div`
    position: relative;
    width: 70%;
    animation: ${({ show }: WrapperProps) =>
        show
            ? 'none;'
            : css`
                  ${fadeOut} 0.1s linear forwards;
              `};
`;

const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

const SkeletonItem = styled.div`
    height: 1.429rem;
    border-radius: 0.357rem;
`;

const WorkList = styled(SkeletonItem)`
    width: 5.857rem;
    background-color: ${greyColorDark};

    @media (max-width: ${MobileViewBreakpoint}px) {
        width: 30%;
    }
`;

const RightCaretIcon = styled(Icon)`
    && {
        margin: 0 0.3rem;
    }
`;

const ReporteeName = styled(SkeletonItem)`
    width: 18rem;
    background-color: ${greyColor};

    @media (max-width: ${MobileViewBreakpoint}px) {
        width: 60%;
    }
`;

interface UserTicketSkeletonLoaderProps {
    show?: boolean;
}

const BreadcrumbTicketSkeletonLoader = ({ show }: UserTicketSkeletonLoaderProps): ReactElement => {
    return (
        <Wrapper show={show}>
            <Container>
                <WorkList />
                <RightCaretIcon size="small" name="angle right" />
                <ReporteeName />
            </Container>
            <ShimmerAnimation />
        </Wrapper>
    );
};

export default BreadcrumbTicketSkeletonLoader;
