import { ComponentProps, ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { SvgIcon } from '../../../components';

const Container = styled.div`
    display: flex;
    align-items: center;
    background: var(--surface-color-light);
    border: 1px solid var(--border-color);
    border-radius: 0.35rem;
    padding-left: 1.14rem;
    gap: 1.14rem;
    :active {
        outline-color: black;
    }
    :focus-within {
        outline-color: var(--primary-color);
        outline-width: 1px;
        outline-style: auto;
    }
    & path {
        fill: var(--text-medium-emphasis-color);
    }
`;

const InputFieldElement = styled.input`
    background: none;
    border: none;
    cursor: auto;
    outline: none;
    flex-grow: 1;
    padding: 0.7rem 1.14rem 0.7rem 0;
`;

interface ControlledInputProps {
    onChange(val: string): void;
    placeholder?: string;
    value: string;
    icon?: ComponentProps<typeof SvgIcon>['name'];
    innerRef: React.RefObject<HTMLInputElement>;
}

const ControlledInput = ({ placeholder, onChange, value, icon, innerRef }: ControlledInputProps): ReactElement => {
    const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value);
    };

    return (
        <Container>
            {icon ? <SvgIcon name={icon} /> : null}
            <InputFieldElement
                placeholder={placeholder}
                autoComplete="false"
                value={value}
                onChange={handleChange}
                ref={innerRef}
            />
        </Container>
    );
};
export default ControlledInput;
