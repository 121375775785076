import React, { Children, cloneElement, FC, isValidElement, PropsWithChildren, ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

type RadioElementProp = { vertical: boolean };

const StyledRadioElement = styled.label`
    display: flex;
    flex-direction: ${({ vertical }: RadioElementProp) => (vertical ? 'row' : 'column')};
    padding: 1.143rem;
    box-shadow: 0 0 0 1px #d4d6d7;
    border-radius: 0.714rem;
    align-items: ${({ vertical }: RadioElementProp) => (vertical ? 'center' : 'flex-start')};
    gap: 0.571rem;
`;

const RadioElementInput = styled.input`
    width: 1.143rem;
    height: 1.143rem;
    cursor: pointer;
    &:checked {
        border: 1px solid var(--primary-color);
    }
`;

interface RadioElementTitleProps {
    $selected: boolean;
}

const RadioElementTitle = styled.span<RadioElementTitleProps>`
    position: relative;
    color: #475156;
    font-size: 1.143rem;
    font-weight: ${({ $selected }) => ($selected ? '700' : '400')};
    line-height: 1.714rem;
    width: 100%;
`;

const StyledRadioGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.857rem;
`;

const StyledRadioInputMainWrapper = styled.div`
    width: ${({ vertical }: RadioElementProp) => (vertical ? 'auto' : '100%')};
    display: flex;
    align-items: center;
    gap: 0.571rem;
`;

interface RadioCircleAndTitleWrapperProps {
    $selected: boolean;
}
const RadioCircleAndTitleWrapper = styled.div<RadioCircleAndTitleWrapperProps>`
    display: inline-flex;
    align-items: center;
    gap: 0.571rem;
    cursor: ${({ $selected }) => ($selected ? 'auto' : 'pointer')};
`;

const StyledRadioInputContent = motion(styled.div`
    width: 100%;
    overflow: hidden;
`);

interface RadioElementProps {
    selected?: boolean;
    title?: string;
    name?: string;
    value: string;
    action?: ReactNode;
    onChange?: (value: string) => void;
    vertical?: boolean;
}

export const RadioElement: FC<PropsWithChildren<RadioElementProps>> = ({
    title,
    selected = false,
    name,
    onChange,
    value,
    children,
    action,
    vertical = false,
}) => {
    return (
        <StyledRadioElement vertical={vertical} htmlFor={name}>
            <StyledRadioInputMainWrapper vertical={vertical}>
                <RadioCircleAndTitleWrapper onClick={() => onChange?.(value)} $selected={selected}>
                    <RadioElementInput
                        type="radio"
                        checked={selected}
                        name={name}
                        onChange={() => onChange?.(value)}
                        value={value}
                    />
                    {!!title && <RadioElementTitle $selected={selected}>{title}</RadioElementTitle>}
                </RadioCircleAndTitleWrapper>
                {action && !vertical && <>{action}</>}
            </StyledRadioInputMainWrapper>
            <AnimatePresence initial={false}>
                {children && (
                    <StyledRadioInputContent
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { height: 'auto' },
                            collapsed: { height: 0, marginTop: 0 },
                        }}
                        transition={{ duration: 0.3 }}
                    >
                        {children}
                    </StyledRadioInputContent>
                )}
            </AnimatePresence>
            {action && vertical && <>{action}</>}
        </StyledRadioElement>
    );
};

export const RadioGroup: FC<PropsWithChildren<{ name: string; value?: any; onChange?: (value: any) => void }>> = ({
    children,
    value,
    onChange,
}) => {
    const handleChange = (value: string) => {
        onChange?.(value);
    };

    return (
        <StyledRadioGroup>
            {Children.map(children, (child) => {
                if (isValidElement<RadioElementProps>(child)) {
                    return cloneElement(child, {
                        selected: child.props.value === value,
                        name: child.props.value,
                        onChange: handleChange,
                    });
                }
                return child;
            })}
        </StyledRadioGroup>
    );
};
