import { ReactElement, useContext } from 'react';
import { UserTicketType } from '../../../../../api/apiTypes/ticketingApiTypes';
import { NewTicketContext } from '../../NewTicketContext';
import { NewTicketList } from './../NewTicketList';

export const CategoryStep = (): ReactElement => {
    const { state: newTicketContext, dispatch } = useContext(NewTicketContext);

    const handleUpdateCategory = (id: string) => {
        dispatch({ type: 'UPDATE_TICKET_CATEGORY', payload: id });
    };

    const currentTicketType =
        newTicketContext.ticketData &&
        newTicketContext.ticketData.find((item: UserTicketType) => item.id === newTicketContext.ticketType)?.categories;

    return <NewTicketList items={currentTicketType || []} onclick={handleUpdateCategory} />;
};
