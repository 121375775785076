import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ImageUploader from '../../../../common/ImageUploader/ImageUploader';
import UploadedImageDisplay from '../../../../common/ImageUploader/UploadedImageDisplay';
import { PrimaryButton, SvgIcon } from '../../../../components';
import { useUploadTicketImage } from '../../apiQueries/useUploadTicketImage';
import { NewTicketContext } from '../NewTicketContext';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
`;
const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        padding: 0;
    }
`;

const UploaderContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledHeader = styled.h4`
    color: var(--primary-color);
    margin-bottom: 0.3rem;
`;

const StyledDescription = styled.p`
    margin-top: 0;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.714rem;
`;

const UploadedFiles = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
`;

const MAX_FILE_SIZE_MB = 50;
export const NewTicketFileUploader = (): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'newTicket' });
    const [selectedFile, setSelectedFile] = useState<File[]>([]);
    const [isDragAndDropOpened, setDragAndDropVisibility] = useState(false);

    const newTicketContext = useContext(NewTicketContext).state;
    const { dispatch } = useContext(NewTicketContext);

    const [uploadImage, { isLoading: isUploadingImage }] = useUploadTicketImage(
        newTicketContext.ticketType,
        newTicketContext.ticketCategory,
        ({ data }) =>
            dispatch({
                type: 'UPDATE_ATTACHMENTS',
                payload: { imageUrl: data.uncompressedUrl, imageFileName: data.fileName },
            }),
    );

    useEffect(() => {
        dispatch({ type: 'UPDATE_ATTACHMENT_IS_LOADING', payload: isUploadingImage });
    }, [dispatch, isUploadingImage]);

    const handleSelect = () => setDragAndDropVisibility(true);

    const removeImageHandler = (name: string) => {
        const selectedFiles = selectedFile?.filter((file) => file.name !== name);
        setSelectedFile(selectedFiles ?? []);
        const attachments = newTicketContext.ticketForm.attachments.filter(
            (attachment) => attachment.imageFileName !== name,
        );
        dispatch({ type: 'REMOVE_ATTACHMENTS', payload: attachments });
    };

    const onPickFile = (file: File) => setSelectedFile((prev) => (prev ? [...prev, file] : [file]));

    return (
        <Container>
            {isDragAndDropOpened && (
                <ImageUploader
                    onClose={() => setDragAndDropVisibility(false)}
                    uploadImage={uploadImage}
                    filePickCallback={onPickFile}
                    maxImageSize={MAX_FILE_SIZE_MB}
                    isUploadingImage={isUploadingImage}
                    handleRemoveImage={removeImageHandler}
                />
            )}
            <UploadedFiles>
                {selectedFile?.map((file) => (
                    <UploadedImageDisplay
                        key={file.name}
                        file={file}
                        handleRemoveImage={removeImageHandler}
                        isCompact
                    />
                ))}
            </UploadedFiles>
            <UploaderContainer>
                <StyledPrimaryButton aria-label="Last opp bilde" type="button" onClick={handleSelect}>
                    <SvgIcon name="Plus" />
                </StyledPrimaryButton>
                <TextContainer>
                    <StyledHeader>{t('fileUploadHeader')}</StyledHeader>
                    <StyledDescription>
                        {t('fileUploadDescription', { maxFileSizeMB: MAX_FILE_SIZE_MB })}
                    </StyledDescription>
                </TextContainer>
            </UploaderContainer>
        </Container>
    );
};
