import { useEffect, ReactElement, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CurrentUserContext } from '../../../../../../../../Providers';
import { CaseworkerModel } from '../../../../../../../api/apiTypes/ticketingApiTypes';
import { useDeleteTicketTypeCaseworker } from '../../../../../apiQueries/useTicketingType';
import CantRemoveCaseworkerModal from './CantRemoveCaseworkerModal';
import InformationModal from './InformationModal';
import NewAssigneeModal from './NewAssigneeModal';
import RemoveCaseworkerModal from './RemoveCaseworkerModal';
import RemoveYourselfModal from './RemoveYourselfModal';

const Paragraph = styled.p`
    font-size: 1.143rem;
    line-height: 1.714rem;
`;

enum ModalStateType {
    'removeLastCaseworker',
    'removeYourself',
    'removeCaseWorker',
    'information',
    'newAssignee',
}

enum ModalStateVariant {
    'noNewAssignee',
    'newAssignee',
}

export type ModalState =
    | { type: ModalStateType.removeLastCaseworker; variant?: '' }
    | { type: ModalStateType.removeYourself; variant?: '' }
    | { type: ModalStateType.removeCaseWorker; variant?: '' }
    | { type: ModalStateType.information; variant: ModalStateVariant.noNewAssignee | ModalStateVariant.newAssignee }
    | { type: ModalStateType.newAssignee; variant?: '' }
    | null;

interface ModalsProps {
    open: boolean;
    onClose(): void;
    typeId: string;
    caseworkerGuid: string;
    caseworkers: CaseworkerModel[] | [];
}

const Modals = ({ open, onClose, typeId, caseworkerGuid, caseworkers }: ModalsProps): ReactElement => {
    const { state } = useContext(CurrentUserContext) ?? { state: null };
    const [modalState, setModalState] = useState<ModalState>(null);
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const filteredCaseworkers = useMemo(() => {
        return caseworkers.filter((caseworker: CaseworkerModel) => caseworker.id !== caseworkerGuid);
    }, [caseworkers, caseworkerGuid]);

    const [deleteCaseworker] = useDeleteTicketTypeCaseworker();

    useEffect(() => {
        if (caseworkers.length === 1 && open) setModalState({ type: ModalStateType.removeLastCaseworker });
        else if (state?.currentUser?.id === caseworkerGuid && open)
            setModalState({ type: ModalStateType.removeYourself });
        else if (open) setModalState({ type: ModalStateType.removeCaseWorker });
    }, [open, state?.currentUser?.id, caseworkerGuid]);

    const onClickNextRemoveCaseworkerHandler = (option: string) => {
        if (option === 'noNewAssignee') {
            setModalState({ type: ModalStateType.information, variant: ModalStateVariant.noNewAssignee });
            deleteCaseworker([typeId, caseworkerGuid, null]);
        } else setModalState({ type: ModalStateType.newAssignee });
    };

    const onClickNextRemoveYourselfHandler = () => {
        setModalState({ type: ModalStateType.removeCaseWorker });
    };

    const onClickNextNewAssigneeHandler = (newAssigneeGuid: string) => {
        setModalState({ type: ModalStateType.information, variant: ModalStateVariant.newAssignee });
        deleteCaseworker([typeId, caseworkerGuid, newAssigneeGuid]);
    };

    const onCloseHandler = () => {
        setModalState(null);
        onClose();
    };

    return (
        <>
            <CantRemoveCaseworkerModal
                open={modalState?.type === ModalStateType.removeLastCaseworker}
                key="removeLastCaseworker"
                onClose={onCloseHandler}
            />
            <RemoveYourselfModal
                open={modalState?.type === ModalStateType.removeYourself}
                key="removeYourselfModal"
                onClose={onCloseHandler}
                onClickNext={onClickNextRemoveYourselfHandler}
            />
            <RemoveCaseworkerModal
                open={modalState?.type === ModalStateType.removeCaseWorker}
                key="removeCaseworkerModal"
                onClose={onCloseHandler}
                onClickNext={onClickNextRemoveCaseworkerHandler}
            />
            <NewAssigneeModal
                key="newAssigneeModal"
                onClose={onCloseHandler}
                open={modalState?.type === ModalStateType.newAssignee}
                onClickNext={onClickNextNewAssigneeHandler}
                caseworkers={filteredCaseworkers}
            />
            <InformationModal
                key="informationModal"
                onClose={onCloseHandler}
                open={modalState?.type === ModalStateType.information}
            >
                {modalState?.variant === ModalStateVariant.noNewAssignee ? (
                    <Paragraph>{t('modalStateVariantNoNewAssignee')}</Paragraph>
                ) : (
                    <Paragraph>{t('modalStateVariantNewAssignee')}</Paragraph>
                )}
            </InformationModal>
        </>
    );
};

export default Modals;
