import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { OrderOnBehalf } from '../../api/apiTypes/shopApiTypes';
import { sidebarOperations } from '../../common/Sidebar/duck';
import { useNavigation } from '../../utility';
import { useIsMobile } from '../../utility/hooks/useIsMobile';

const useFoodForMeetingsNavigation = (): {
    foodForMeetingsLink: string;
    goToFoodForMeetings(orderOnBehalf?: OrderOnBehalf): void;
} => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();

    return useMemo(() => {
        const foodForMeetingsLink = '/FoodForMeetings/';
        const onNavigate = (url: string, shouldCloseSideBar = true, dataToPass?: OrderOnBehalf) => {
            if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
            navigation.push(url, { orderOnBehalf: dataToPass });
        };
        return {
            foodForMeetingsLink,
            goToFoodForMeetings: (orderOnBehalf?: OrderOnBehalf) =>
                onNavigate(foodForMeetingsLink, true, orderOnBehalf),
        };
    }, [navigation, dispatch, isMobile]);
};

export default useFoodForMeetingsNavigation;
