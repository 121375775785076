import { addMinutes, set, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';
import { getDeviceLanguageDateFormat } from './dateUtilities/getDeviceLanguageDateFormat';

export const getCurrentDate = () => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
};

export const parseStringToDate = (dateString) => {
    const parts = dateString.split('.');
    return new Date(parts[2], parts[1] - 1, parts[0]);
};

export const getTimeFromDate = (date) => {
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();

    if (hours.length === 1) {
        hours = hours.padStart(2, '0');
    }

    if (minutes.length === 1) {
        minutes = minutes.padStart(2, '0');
    }

    return `${hours}:${minutes}`;
};

export const getDateString = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${day}.${month}.${year}`;
};

export const isSameYear = (first, second) => first.getFullYear() === second.getFullYear();

export const diffIsMoreThanADay = (lastEvent, firstEvent) => {
    const diffInHours = differenceInHours(lastEvent, firstEvent);
    return diffInHours > 24;
};
export const diffIsMoreThanAnHour = (lastEvent, firstEvent) => {
    const diffInMinutes = differenceInMinutes(lastEvent, firstEvent);
    return diffInMinutes > 60;
};
export const diffIsMoreThanAMinute = (lastEvent, firstEvent) => {
    const diffInSeconds = differenceInSeconds(lastEvent, firstEvent);
    return diffInSeconds > 60;
};
export const diffIsMoreThanASecond = (lastEvent, firstEvent) => {
    const diffInSeconds = differenceInSeconds(lastEvent, firstEvent);
    return diffInSeconds > 1;
};

export const getDiffInHours = (lastEvent, firstEvent) => differenceInHours(lastEvent, firstEvent);
export const getDiffInMinutes = (lastEvent, firstEvent) => differenceInMinutes(lastEvent, firstEvent);
export const getDiffInSeconds = (lastEvent, firstEvent) => differenceInSeconds(lastEvent, firstEvent);

export const getReadableDateString = (date) => {
    return getDeviceLanguageDateFormat(date, 'd. MMMM');
};

export const convertUTCDateToLocalDateString = (date) => {
    if (date) {
        let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        let offset = date.getTimezoneOffset() / 60;
        let hours = date.getHours();
        newDate.setHours(hours - offset);

        return getDeviceLanguageDateFormat(newDate, 'PPPPpp');
    }
};

export const getNextWhole15Minutes = (startTime = new Date()) => {
    const newTime = set(startTime, { seconds: 0, milliseconds: 0 });
    return addMinutes(newTime, 15 - (newTime.getMinutes() % 15));
};

export const getDateWithoutSeconds = (date = new Date()) => {
    return set(date, { seconds: 0, milliseconds: 0 });
};

export const addMinutesFloored = (date, minutes) => {
    const millsPerMinute = 1000 * 60;
    // Remove seconds before adding minutes
    return new Date(Math.floor(date.getTime() / millsPerMinute) * millsPerMinute + millsPerMinute * minutes);
};
