import { ReactElement, useRef, useState, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';
import useCurrentUserContext from '../../../../common/Contexts/useCurrentUserContext';
import { MobileViewBreakpoint, TabletPortraitViewBreakpoint } from '../../../../common/ScreenSizeBreakPoints';
import { FullPageLoader, InformationMessage } from '../../../../components';
import useResizeWindow from '../../../../utility/hooks/useResizeWindow';
import { useGetTicket, useGetTicketPriorities, useGetTicketStatuses } from '../../apiQueries/useTicketingTicket';
import { useTicketingType } from '../../apiQueries/useTicketingType';
import { TicketingContext } from '../../Ticketing';
import useTicketingNavigation, { WorkListTabPath } from '../../useTicketingNavigation';
import { BackButton, MobileNavigationBanner } from '../common';
import { TicketListView } from '../UserTickets/UserTickets';
import { ResolveTicketButton, ResolveTicketModal } from './TicketDetails/components';
import TicketDetails from './TicketDetails/TicketDetails';
import TicketTabs from './TicketTabs/TicketTabs';

type TicketViewProps = { isViewWithoutSidePanel: boolean };
const TicketView = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: ${({ isViewWithoutSidePanel }: TicketViewProps) => (isViewWithoutSidePanel ? 'column' : 'row')};
    overflow-y: hidden;
`;

const SideSheetContainer = styled.div`
    display: flex;
    width: 33.33%;
    min-width: 29.143rem;
`;

type ContentContainerProps = { isViewWithoutSidePanel: boolean };
const ContentContainer = styled.div`
    width: ${({ isViewWithoutSidePanel }: ContentContainerProps) => (isViewWithoutSidePanel ? '100%' : '66.667%')};
    display: flex;
    height: 100%;
`;

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Navigation = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.714rem;
    background-color: var(--surface-color-light);
    border-bottom: 1px solid var(--border-color);
    flex-direction: row;
`;

interface TicketProps {
    view: TicketListView;
}

const Ticket = ({ view }: TicketProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    const { state: TicketingContextState } = useContext(TicketingContext);
    const containerRef = useRef<HTMLDivElement>(null);
    const containerWidth = useResizeWindow(containerRef);
    const { getTicketLink, goBack: goBackTicketing } = useTicketingNavigation();
    const match = useRouteMatch<{ ticketId: string; tab: WorkListTabPath }>(
        `/:portalName${getTicketLink(view, ':ticketId', ':tab')}`,
    );
    const ticketId = match?.params.ticketId ?? '';
    const { data: ticket, isLoading: isLoadingTicket, isError } = useGetTicket(ticketId);
    const { data: types, isLoading: isLoadingTicketType } = useTicketingType();
    const { data: statusOptions, isLoading: isLoadingStatusOptions } = useGetTicketStatuses();
    const { data: priorityOptions, isLoading: isLoadingPriorityOptions } = useGetTicketPriorities();
    const isViewWithoutSidePanel = containerWidth < TabletPortraitViewBreakpoint;
    const isMobileView = containerWidth < MobileViewBreakpoint;
    const { state: currentUserContext } = useCurrentUserContext();
    const userIsReportee = currentUserContext.currentUser?.id === ticket?.registeredBy.id;

    const caseWorkerCanEditTicket = useMemo(() => {
        // TODO: Need to check this condition to relation (canEditTicket & userIsCaseworker)
        if (types)
            return TicketingContextState.caseWorkerCanEditTicket && types.some((type) => type.id === ticket?.type.id);
        return false;
    }, [TicketingContextState.caseWorkerCanEditTicket, types, ticket]);

    const [openModalDesktopView, setOpenModalDesktopView] = useState(false);

    const handleGoBackClick = () => {
        goBackTicketing(view);
    };

    const isLoading = isLoadingTicket || isLoadingTicketType || isLoadingStatusOptions || isLoadingPriorityOptions;

    return (
        <MainContainer>
            {ticket ? (
                <>
                    {isViewWithoutSidePanel ? (
                        <MobileNavigationBanner onClick={handleGoBackClick} />
                    ) : (
                        <Navigation>
                            <BackButton onClick={handleGoBackClick} />
                            <ResolveTicketButton
                                isMobileView={isMobileView}
                                onClick={() => setOpenModalDesktopView(true)}
                                text={t('resolveModalCheckAsSolved')}
                                ticket={ticket}
                            />
                        </Navigation>
                    )}

                    {openModalDesktopView && (
                        <ResolveTicketModal
                            userIsReportee={userIsReportee}
                            caseWorkerCanEditTicket={caseWorkerCanEditTicket}
                            key="resolveTicketModal"
                            onClose={() => setOpenModalDesktopView(false)}
                            open={openModalDesktopView}
                            ticket={ticket}
                        />
                    )}
                </>
            ) : null}
            {isError && <InformationMessage type="error">{t('ticketErrorMessage')}</InformationMessage>}
            <TicketView ref={containerRef} isViewWithoutSidePanel={isViewWithoutSidePanel}>
                {isLoading && <FullPageLoader text="" />}
                {!isError && !isLoading && ticket && (
                    <>
                        {!isViewWithoutSidePanel && (
                            <SideSheetContainer>
                                <TicketDetails
                                    ticket={ticket}
                                    types={types}
                                    statusOptions={statusOptions}
                                    priorityOptions={priorityOptions}
                                    key={`ticketDetails-${ticket.id}`}
                                    isViewWithoutSidePanel={isViewWithoutSidePanel}
                                    isMobileView={isMobileView}
                                    caseWorkerCanEditTicket={caseWorkerCanEditTicket}
                                    userIsReportee={userIsReportee}
                                    view={view}
                                />
                            </SideSheetContainer>
                        )}
                        <ContentContainer isViewWithoutSidePanel={isViewWithoutSidePanel}>
                            {ticket && (
                                <TicketTabs
                                    ticket={ticket}
                                    containerWidth={containerWidth}
                                    key={`ticketTabs-${ticket.id}`}
                                    types={types}
                                    statusOptions={statusOptions}
                                    priorityOptions={priorityOptions}
                                    isViewWithoutSidePanel={isViewWithoutSidePanel}
                                    isMobileView={isMobileView}
                                    caseWorkerCanEditTicket={caseWorkerCanEditTicket}
                                    userIsReportee={userIsReportee}
                                    view={view}
                                />
                            )}
                        </ContentContainer>
                    </>
                )}
            </TicketView>
        </MainContainer>
    );
};

export default Ticket;
