import { ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroller from 'react-infinite-scroller';
import { Divider, Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import LoaderComponent from '../../../Loader/LoaderComponent';
import { useNotification } from '../../context';
import { NotificationsDisplay, NotificationsFetchStatus } from '../common';
import NotificationsTrigger from '../common/NotificationsTrigger';

type ContentDivProps = { isVisible: boolean };
const ContentDiv = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1001;

    transition: transform 0.4s;
    ${({ isVisible }: ContentDivProps) =>
        !isVisible &&
        css`
            display: hidden;
            transform: translateX(100%);
        `}
`;

const Container = styled.div`
    background: var(--surface-color-light);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &:focus {
        outline-offset: 1em;
        outline-style: solid;
        z-index: 2;
    }
`;

const StickyHeader = styled.div`
    top: 0;
    position: sticky;
    background: var(--surface-color-light);
    z-index: 2;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1rem 1rem 0 1rem;
    justify-content: space-between;
    align-items: center;
`;

const Header = styled.h3`
    font-size: 1em;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    margin-bottom: 0;
`;

const CloseButton = styled.button`
    margin-left: auto;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    & path {
        fill: var(--text-high-emphasis-color);
    }
`;

const LoaderWrapper = styled.div`
    height: 5rem;
`;

const NotificationsFullPage = (): ReactElement => {
    const { t } = useTranslation('notification');
    const { notifications, errorLoadingNotifications, canFetchMore, fetchMore } = useNotification();
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [isVisible, setIsVisible] = useState(false);

    const handleTriggerClick = () => {
        setIsVisible(true);
    };

    const hideNotifications = () => setIsVisible(false);

    return (
        <>
            <NotificationsTrigger onClick={handleTriggerClick} spaceRight={2} />
            <ContentDiv
                role={'dialog'}
                aria-hidden={isVisible ? 'false' : 'true'}
                aria-label={'Notifications view'}
                isVisible={isVisible}
            >
                <Container role="dialog" aria-label="overview of notifications" ref={scrollRef}>
                    <StickyHeader>
                        <HeaderContainer>
                            <Header>{t('notifications')}</Header>
                            <CloseButton>
                                <Icon name="close" onClick={hideNotifications} link />
                            </CloseButton>
                        </HeaderContainer>
                        <Divider />
                    </StickyHeader>
                    <InfiniteScroller
                        threshold={200}
                        hasMore={canFetchMore}
                        loadMore={fetchMore}
                        loader={
                            <LoaderWrapper key={0}>
                                <LoaderComponent text="loading more notifications" />
                            </LoaderWrapper>
                        }
                        useWindow={false}
                        getScrollParent={() => scrollRef.current}
                    >
                        <NotificationsFetchStatus
                            errorLoadingNotifications={errorLoadingNotifications}
                            noNotifications={notifications.length === 0}
                        />
                        <NotificationsDisplay />
                    </InfiniteScroller>
                </Container>
            </ContentDiv>
        </>
    );
};

export default NotificationsFullPage;
