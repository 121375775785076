import { ReactElement, RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroller from 'react-infinite-scroller';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
    CreateTicketComment,
    Ticket,
    TicketCommentWithSeen,
    TicketImage,
    TicketStatus,
} from '../../../../../../api/apiTypes/ticketingApiTypes';
import LoaderComponent from '../../../../../../common/Loader/LoaderComponent';
import { InformationMessage } from '../../../../../../components';
import { AvatarComponentProps } from '../../../../../../components/general/AvatarComponent';
import { SwitchToggle } from '../../../../../Food/FoodOrder/components/DetailsSelector/components';
import useTicketingNavigation, { WorkListTabPath } from '../../../../useTicketingNavigation';
import { TicketListView } from '../../../UserTickets/UserTickets';
import { Message, MessageCard, CommentSkeletonLoader } from '../components/index';

const LoaderWrapper = styled.div`
    height: 5rem;
`;

const Description = styled.p`
    padding-bottom: 1.143rem;
    color: #263238e5;
    margin: 0;
`;

type StyledSwitchToggleProps = { isMobile?: boolean };
const StyledSwitchToggle = styled(SwitchToggle)`
    height: 2.86rem;
    margin-bottom: 2rem;
    display: ${({ isMobile }: StyledSwitchToggleProps) => (isMobile ? 'flex' : 'none')};
`;

const InfinityScrollContainer = styled(InfiniteScroller)`
    display: flex;
    flex-direction: column;
    gap: 1.143rem;
`;

const MessagesContainer = styled.div`
    padding-bottom: 2.286rem;
`;

export enum CommentType {
    Comments = 'Comments',
    InternalNotes = 'InternalNotes',
}

interface CommentsProps {
    setChangesSaved(isSaved: boolean): void;
    scrollRef: RefObject<HTMLElement>;
    currentUserAvatar: AvatarComponentProps | null;
    ticket: Ticket;
    comments: TicketCommentWithSeen[];
    isLoading: boolean;
    isError: boolean;
    canFetchMore: boolean | undefined;
    fetchMore(): void;
    onSubmitComment(comment: CreateTicketComment): void;
    disableSubmitMessageButton: boolean;
    isErrorCreatingComment: boolean;
    isSuccessCreatingComment: boolean;
    isLoadingCreatingComment: boolean;
    isMobile: boolean;
    caseWorkerCanEditTicket?: boolean;
    view: TicketListView;
    commentType: CommentType;
}

const Comments = ({
    setChangesSaved,
    scrollRef,
    currentUserAvatar,
    ticket,
    comments,
    isLoading,
    isError,
    canFetchMore,
    fetchMore,
    onSubmitComment,
    disableSubmitMessageButton,
    isErrorCreatingComment,
    isSuccessCreatingComment,
    isLoadingCreatingComment,
    isMobile,
    caseWorkerCanEditTicket = false,
    view,
    commentType,
}: CommentsProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    const { goToTicketLink, getTicketLink } = useTicketingNavigation();
    const match = useRouteMatch<{ reporteeId: string; ticketId: string; tab: WorkListTabPath }>(
        `/:portalName${getTicketLink(view, ':ticketId', ':tab' as WorkListTabPath)}`,
    );
    const tabMatch = match?.params.tab ?? 'comments';
    const [type, setType] = useState(tabMatch === 'comments' ? 1 : 2);

    const onSubmitCommentHandler = (message: { message: string; attachments: TicketImage[] }) => {
        onSubmitComment({ description: message.message, attachments: message.attachments });
    };

    const onToggleCommentTypeHandler = (option: number) => {
        setType(option);
        goToTicketLink(view, ticket.number, option === 1 ? 'comments' : 'internalNotes');
    };

    return (
        <>
            {caseWorkerCanEditTicket ? (
                <Description>
                    {commentType === CommentType.Comments
                        ? t('descriptionDialogueWithApplicant')
                        : t('descriptionInternalDialogue')}
                </Description>
            ) : null}
            {caseWorkerCanEditTicket && (view === TicketListView.Caseworker || TicketListView.Worklist) && (
                <StyledSwitchToggle
                    option1Text={t('commentsOption1Text')}
                    option2Text={t('commentsOption2Text')}
                    onClick={onToggleCommentTypeHandler}
                    selected={type}
                    isMobile={isMobile}
                />
            )}
            {commentType === CommentType.InternalNotes || ticket.status !== TicketStatus.Closed ? (
                <Message
                    key="newCommentMessage"
                    avatar={currentUserAvatar}
                    onSendMessage={onSubmitCommentHandler}
                    placeholder={t('newCommentPlaceholder')}
                    inputFieldPrependIcon="ImageIcon"
                    ticket={ticket}
                    setChangesSaved={setChangesSaved}
                    disable={disableSubmitMessageButton}
                    submitMessageErrorMessage={isErrorCreatingComment ? t('errorCreatingComment') : ''}
                    clearMessage={isSuccessCreatingComment}
                    isLoading={isLoadingCreatingComment}
                />
            ) : null}
            <MessagesContainer>
                {isLoading ? (
                    <CommentSkeletonLoader numberOfItems={20} show={isLoading} showResponseButton={false} />
                ) : isError || !comments ? (
                    <InformationMessage type="error">{t('commentInformationMessage')}</InformationMessage>
                ) : (
                    <InfinityScrollContainer
                        threshold={200}
                        hasMore={canFetchMore}
                        loadMore={fetchMore}
                        loader={
                            <LoaderWrapper key={0}>
                                <LoaderComponent text={t('commentLoaderText')} />
                            </LoaderWrapper>
                        }
                        useWindow={false}
                        getScrollParent={() => scrollRef.current}
                    >
                        {comments.map((comment) => (
                            <MessageCard key={comment.id} comment={comment} highlighted={!comment.seen} />
                        ))}
                    </InfinityScrollContainer>
                )}
            </MessagesContainer>
        </>
    );
};

export default Comments;
