import { PropsWithChildren, ReactElement } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { MobileViewBreakpoint } from '../../../../common/ScreenSizeBreakPoints';

interface SideMenuContainerProps {
    width: string;
}
const SideMenuContainer = styled(motion.div)`
    width: ${({ width }: SideMenuContainerProps) => width};
    background-color: var(--surface-color-light);
    display: grid;
    grid-auto-rows: min-content;
    row-gap: calc(1.71rem - 2 * 0.57rem);
    height: 100%;
    z-index: 2;
    padding: 2.28rem 1.71rem;
    overflow-y: auto;
    flex-shrink: 0;
    border: 1px solid var(--border-color);
    border-left: none;

    @media (max-width: ${MobileViewBreakpoint}px) {
        z-index: 2;
        height: calc(100% - 3.7rem - 4rem);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
        position: absolute;
        right: 0;
        border: none;
    }
`;

interface SlidingSideSectionProps {
    blockInitial?: boolean;
    width?: string;
}
const SlidingSideSection = ({
    children,
    blockInitial = false,
    width = '14rem',
}: PropsWithChildren<SlidingSideSectionProps>): ReactElement => {
    return (
        <SideMenuContainer
            initial={blockInitial ? false : { right: '-100%' }}
            animate={{ right: 0 }}
            exit={{ right: '-100%' }}
            transition={{ type: 'easeOut', duration: 0.1 }}
            width={width}
        >
            {children}
        </SideMenuContainer>
    );
};

export default SlidingSideSection;
