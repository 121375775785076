import { FC, SVGProps } from 'react';

const TickIconSvg: FC<{ fill: string }> = (props: SVGProps<SVGSVGElement>) => (
    <svg width={20} height={20} baseProfile="tiny" viewBox="0 0 24 24" {...props}>
        <path
            fill={props.fill ?? '#000'}
            d="M16.972 6.251a1.999 1.999 0 0 0-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 1 0-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 0 0 1.471-1.009l5-9a2 2 0 0 0-.776-2.72z"
        />
    </svg>
);

export default TickIconSvg;
