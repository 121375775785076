import { ReactElement, useRef } from 'react';
import { Popup } from 'semantic-ui-react';
import NotificationsTrigger from '../common/NotificationsTrigger';
import NotificationsPopupContent from './components/NotificationsPopupContent';

const NotificationsPopUp = (): ReactElement => {
    const contentRef = useRef<null | HTMLAnchorElement>(null);

    return (
        <Popup
            on={['click']}
            style={{
                maxWidth: '40%',
                width: '400px',
                overflow: 'auto',
                padding: '1em',
                borderRadius: '12px',
            }}
            trigger={<NotificationsTrigger />}
            position="bottom right"
        >
            <Popup.Content
                style={{
                    display: 'flex',
                    maxHeight: '400px',
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                }}
            >
                <NotificationsPopupContent ref={contentRef} />
            </Popup.Content>
        </Popup>
    );
};

export default NotificationsPopUp;
