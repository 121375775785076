import { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';

interface StyledLabelProps {
    error?: boolean;
    disable?: boolean;
}

const StyledLabel = styled.label`
    color: ${({ disable, error }: StyledLabelProps) => {
        if (disable) return 'var(--text-disabled-color)';
        else if (error) return 'var(--error-color)';
        else return 'var(--text-placeholder-color)';
    }};
    font-size: 0.857rem;
`;

interface LabelProps {
    isError: boolean;
    htmlFor: string;
    disable?: boolean;
}

const Label = ({ children, isError, htmlFor, disable }: PropsWithChildren<LabelProps>): ReactElement => {
    return (
        <StyledLabel htmlFor={htmlFor} error={isError} disable={disable}>
            {children}
        </StyledLabel>
    );
};

export default Label;
