import { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import InventoryOverview from '../components/InventoryOverview/InventoryOverview';
import { ManageCategoryLayout } from '../components/InventoryOverview/layouts/ManageCategoryLayout/ManageCategoryLayout';
import { ProductFormLayout } from '../components/InventoryOverview/layouts/ProductLayout/ProductFormLayout';
import { ShopOverviewView } from '../ShopAtWork';
import useShopAtWorkNavigation from '../useShopAtWorkNavigation';

const Inventory = (): ReactElement => {
    const { inventoryAddLink, inventoryEditLink, inventoryCategoryLink } = useShopAtWorkNavigation();
    return (
        <Switch>
            <Route path={`/:portalName${inventoryAddLink}`}>
                <ProductFormLayout />
            </Route>
            <Route path={`/:portalName${inventoryEditLink}/:id`}>
                <ProductFormLayout />
            </Route>
            <Route path={`/:portalName${inventoryCategoryLink}`}>
                <ManageCategoryLayout />
            </Route>
            <Route exact>
                <InventoryOverview view={ShopOverviewView.Inventory} />
            </Route>
        </Switch>
    );
};

export default Inventory;
