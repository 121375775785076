import { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Ticket, UserTickets } from '../components';
import { TicketListView } from '../components/UserTickets/UserTickets';
import useTicketingNavigation from '../useTicketingNavigation';

const Container = styled.div`
    display: flex;
    width: 100%;
`;

const Worklist = (): ReactElement => {
    const { getReporteeTicketsLink, getTicketLink } = useTicketingNavigation();

    return (
        <Container>
            <Switch>
                <Route path={`/:portalName${getReporteeTicketsLink(':reporteeId')}`}>
                    <UserTickets view={TicketListView.Caseworker} />
                </Route>
                <Route path={`/:portalName${getTicketLink(TicketListView.Worklist, ':ticketId', ':tab')}`}>
                    <Ticket view={TicketListView.Worklist} />
                </Route>
                <Route>
                    <UserTickets view={TicketListView.Worklist} />
                </Route>
            </Switch>
        </Container>
    );
};

export default Worklist;
