import { ReactElement } from 'react';
import { SidebarMenuItem } from '../../components';
import { useGetCategories } from '../Dashboard/Articles/ApiQueries/useCategories';
import useArticlesNavigation from './useArticlesNavigation';

const PinnedCategoriesDynamicMenuItem = (): ReactElement => {
    const { goToSelectedArticleCategory, pathname, goToSelectedArticleSubCategory } = useArticlesNavigation();
    const { data: pinnedCategories } = useGetCategories(false, true);

    return (
        <>
            {pinnedCategories?.map((category) => (
                <SidebarMenuItem
                    key={category.id}
                    icon="PinnedItemIcon"
                    isActive={pathname?.includes(`/${category.id}`)}
                    onClick={() => goToSelectedArticleCategory(category.id)}
                    text={category.name}
                >
                    {category.children?.map((subCategory) => (
                        <SidebarMenuItem
                            key={subCategory.id}
                            isActive={pathname?.includes(`/${subCategory.id}`)}
                            onClick={() => goToSelectedArticleSubCategory(category.id, subCategory.id)}
                            text={subCategory.name}
                        />
                    ))}
                </SidebarMenuItem>
            ))}
        </>
    );
};

export default PinnedCategoriesDynamicMenuItem;
