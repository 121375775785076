import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SvgIcon } from '../../../../components';

const Banner = styled.div`
    width: 100%;
    padding: 0.643rem 1.214rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background-color);
    border-bottom: 1px solid var(--border-color);
`;

const GoBackButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;

    :focus,
    :focus-visible {
        outline: 1px solid var(--primary-color);
    }
    :focus:not(:focus-visible) {
        outline: 0;
    }

    svg {
        path {
            fill: var(--primary-color);
        }
    }
`;

interface MobileNavigationBannerProps {
    onClick(): void;
}

const MobileNavigationBanner = ({ onClick }: MobileNavigationBannerProps): ReactElement => {
    const { t: tCommon } = useTranslation('common');
    return (
        <Banner>
            <GoBackButton onClick={onClick}>
                <SvgIcon name="CaretLeft" alt={tCommon('return')} />
            </GoBackButton>
        </Banner>
    );
};

export default MobileNavigationBanner;
