import { ReactElement, useMemo, useRef, useContext, useEffect } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import InfiniteScroller from 'react-infinite-scroller';
import { useRouteMatch, useLocation } from 'react-router';
import styled from 'styled-components';
import { TicketSortDirection, TicketSortingType } from '../../../../api/apiTypes/ticketingApiTypes';
import { useCurrentUserContext } from '../../../../common';
import LoaderComponent from '../../../../common/Loader/LoaderComponent';
import { MobileViewBreakpoint } from '../../../../common/ScreenSizeBreakPoints';
import { InformationMessage, PrimaryButton, SvgIcon } from '../../../../components';
import { GroupedOptionType, OptionType } from '../../../../components/select/searchSelect/types';
import { useDebounce } from '../../../../utility';
import { useIsMobile } from '../../../../utility/hooks/useIsMobile';
import useResizeWindow from '../../../../utility/hooks/useResizeWindow';
import { useGetInfiniteReporteeTickets } from '../../apiQueries/useTicketingTicket';
import { useGetUserInfo } from '../../apiQueries/useTicketingUser';
import { Header, HorizontalRule } from '../../common/styles';
import { TicketingContext } from '../../Ticketing';
import useTicketingNavigation from '../../useTicketingNavigation';
import { FilterKeys, getTicketingSettings } from '../../utils';
import { BackButton, MobileNavigationBanner } from '../common';
import { UserDetails, UserDetailsSkeletonLoader } from '../UserDetails/index';
import { UserTicketTable, Breadcrumb, BreadcrumbSkeletonLoader } from './components/index';
import UserTicketsControl from './UserTicketsControl';
import { prepareFilters } from './utils';

const Container = styled.div`
    width: 100%;
    flex-direction: column;
    background: var(--surface-color-light);
    overflow-y: scroll;
`;

const NavigationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`;

type ContentContainerProps = { isMobile: boolean };
const ContentContainer = styled.div`
    padding: ${({ isMobile }: ContentContainerProps) => (isMobile ? '1.143rem' : '1.714rem')};
`;

const Navigation = styled.div`
    display: flex;
    align-items: center;
    gap: 1.143rem;
`;

const StyledHeader = styled(Header)`
    margin-bottom: 0;
`;

const StyledUserDetails = styled(UserDetails)`
    padding-top: 1.714rem;

    @media (max-width: ${MobileViewBreakpoint}px) {
        padding: 0;
        margin-top: 1.5rem;
    }
`;

const LoaderWrapper = styled.div`
    height: 5rem;
`;
const StyledHorizontalRule = styled(HorizontalRule)`
    width: 100%;
    margin: 0;
`;

const AddNewTicketButtonTextContainer = styled.div`
    display: flex;
    gap: 0.571rem;
    align-items: center;
`;

export enum TicketListView {
    Worklist = 'Worklist',
    MyTickets = 'MyTickets',
    Caseworker = 'Caseworker',
    CompanyTickets = 'CompanyTickets',
}

const TICKET_DESKTOP_BREAKPOINT = 953;

interface UserTicketsProps {
    view: TicketListView;
}

export interface TicketFilterProps {
    isSearchable: boolean;
    key: FilterKeys;
    title: string;
    options: OptionType[] | GroupedOptionType[] | [];
    onChange: (options: OptionType[] | []) => void;
    value: OptionType[] | [];
    onResetOptions: () => void;
    autoMenuPosition: boolean;
}

const UserTickets = ({ view }: UserTicketsProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    const location = useLocation();
    const { state: TicketingContextState, dispatch } = useContext(TicketingContext);
    const { state: currentUserContext } = useCurrentUserContext();
    const { sortingType, sortingDirection, ...filters } = getTicketingSettings(TicketingContextState);
    const preparedFilters = prepareFilters(filters);
    const scrollRef = useRef<HTMLDivElement>(null);
    const containerWidth = useResizeWindow(scrollRef);
    const isMobile = useIsMobile();
    const isTabletOrMobileView = containerWidth < TICKET_DESKTOP_BREAKPOINT;

    const { getReporteeTicketsLink, goToNewTicketModal, goBack: goBackTicketing } = useTicketingNavigation();
    const match = useRouteMatch<{ reporteeId: string }>(`/:portalName${getReporteeTicketsLink(':reporteeId')}`);
    const debouncedSearchTerm = useDebounce(TicketingContextState.searchTerm.trim().replace(/\s+/g, ' '), 200);
    const reporteeId =
        view === TicketListView.Caseworker || view === TicketListView.CompanyTickets
            ? match?.params.reporteeId ?? ''
            : currentUserContext.currentUser?.id ?? '';

    const { data, isLoading, isFetchingMore, isError, canFetchMore, fetchMore } = useGetInfiniteReporteeTickets(
        reporteeId,
        sortingType,
        sortingDirection,
        view,
        debouncedSearchTerm,
        preparedFilters,
    );

    const { data: reportee, isLoading: isLoadingReportee } = useGetUserInfo(
        reporteeId,
        TicketingContextState.caseWorkerCanEditTicket,
    );

    const tickets = useMemo(() => (data ? data.map(({ data }) => data) : []).flat(), [data]);
    const handleFetchMore = () => {
        if (!isFetchingMore) fetchMore();
    };

    const handleSort = (sortColumn: { path: TicketSortingType; order: TicketSortDirection }) => {
        dispatch({ type: 'UPDATE_TICKET_SORTING_TYPE', payload: { sortingType: sortColumn.path, view } });
        dispatch({ type: 'UPDATE_TICKET_SORTING_DIRECTION', payload: { sortingDirection: sortColumn.order, view } });
    };

    const handleGoBackClick = () => {
        goBackTicketing(view);
    };

    const filteredAndSortedTickets = useMemo(() => {
        return sortBy(tickets, [sortingType], [sortingDirection]);
    }, [tickets, sortingType, sortingDirection]);

    useEffect(() => {
        dispatch({ type: 'UPDATE_TICKET_LIST_VIEW', payload: view });
    }, [view, dispatch]);

    useEffect(() => {
        dispatch({ type: 'UPDATE_REPORTEE_ID', payload: reporteeId });
    }, [reporteeId, dispatch]);

    const getHeaderTitle = () => {
        switch (view) {
            case TicketListView.Worklist:
                return t('worklist');
            case TicketListView.MyTickets:
                return t('myTickets');
            case TicketListView.CompanyTickets:
                return `${currentUserContext.currentUser?.companyName} ${t('theirAffairs')}`;
            default:
                return '';
        }
    };

    return (
        <Container ref={scrollRef}>
            {isMobile && view === TicketListView.Caseworker && <MobileNavigationBanner onClick={handleGoBackClick} />}
            <ContentContainer isMobile={isTabletOrMobileView}>
                {view === TicketListView.Caseworker && (
                    <>
                        <Navigation>
                            {!isTabletOrMobileView && (
                                <div>
                                    <BackButton onClick={handleGoBackClick} />
                                </div>
                            )}
                            {isLoadingReportee ? (
                                <BreadcrumbSkeletonLoader show={isLoadingReportee} />
                            ) : (
                                reportee && <Breadcrumb reporteeName={`${reportee.firstName} ${reportee.lastName}`} />
                            )}
                        </Navigation>
                        {isLoadingReportee ? (
                            <UserDetailsSkeletonLoader show={isLoadingReportee} />
                        ) : (
                            reportee && <StyledUserDetails user={reportee} avatarSize="big" />
                        )}
                    </>
                )}
                <NavigationContainer>
                    <StyledHeader> {getHeaderTitle()} </StyledHeader>
                    {view === TicketListView.MyTickets && (
                        <PrimaryButton onClick={() => goToNewTicketModal(location.pathname)}>
                            <AddNewTicketButtonTextContainer>
                                <SvgIcon name="Plus" /> {isTabletOrMobileView ? '' : t('addNewTicket')}
                            </AddNewTicketButtonTextContainer>
                        </PrimaryButton>
                    )}
                </NavigationContainer>

                <UserTicketsControl
                    isTabletOrMobileView={isTabletOrMobileView}
                    view={view}
                    itemsCount={tickets.length}
                    isMobile={isMobile}
                    sortingType={sortingType}
                    sortingDirection={sortingDirection}
                    filters={filters}
                />
                {isTabletOrMobileView && <StyledHorizontalRule />}

                {isError || !tickets ? (
                    <InformationMessage type="error">{t('errorMessageLoadingData')}</InformationMessage>
                ) : (
                    <InfiniteScroller
                        threshold={200}
                        hasMore={canFetchMore}
                        loadMore={handleFetchMore}
                        loader={
                            <LoaderWrapper key={0}>
                                <LoaderComponent text={t('loadingMoreInquiries')} />
                            </LoaderWrapper>
                        }
                        useWindow={false}
                        getScrollParent={() => scrollRef.current}
                    >
                        <UserTicketTable
                            isLoading={isLoading}
                            tickets={filteredAndSortedTickets}
                            onSort={handleSort}
                            sortColumn={{
                                path: sortingType,
                                order: sortingDirection,
                            }}
                            key="userTickets"
                            view={view}
                            collapse={isTabletOrMobileView}
                        />
                    </InfiniteScroller>
                )}
            </ContentContainer>
        </Container>
    );
};

export default UserTickets;
