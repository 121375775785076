import styled from 'styled-components';
import { Badge } from '../../../../../../components';

const TicketRecipientBadge = styled(Badge)`
    font-size: 0.857rem;
    font-weight: 600;
    padding: 0.286rem 0.57rem;
`;

export default TicketRecipientBadge;
