import React, { useContext, useMemo } from 'react';
import { eachWeekendOfInterval, differenceInCalendarYears, getYear, addYears, addMinutes, max } from 'date-fns';
import Holidays from 'date-holidays';
import styled from 'styled-components';
import { MobileViewBreakpoint } from '../../../../../../common/ScreenSizeBreakPoints';
import { DateInput } from '../../../../../../components';
import { useGetMinDate } from '../../../../apiQueries/useMenu';
import { TimeDropdown } from '../../../../components';
import { ShoppingCartContext } from '../../../../ShoppingCart';
import { FoodMenuContext } from '../../../foodMenuContext';

const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 1.8rem;

    @media (max-width: ${MobileViewBreakpoint}px) {
        margin: 0;
    }
`;

const FlexContainer = styled.div`
    display: flex;
`;

const DateSelectorContainer = styled.div`
    flex: 1 0;
    display: flex;
    width: min-content;
`;
const TimeSelectorContainer = styled.div`
    flex: 1 0;
    margin-left: 0.5rem;
`;

interface SelectDateAndTimeProps {
    setShouldShowToast: (shouldShow: boolean) => void;
}

const SelectDateAndTime: React.FC<React.PropsWithChildren<SelectDateAndTimeProps>> = ({
    setShouldShowToast,
}: SelectDateAndTimeProps) => {
    const { state: shoppingCartState, setDeliveryDateTime } = useContext(ShoppingCartContext);

    const foodMenuState = useContext(FoodMenuContext);
    const minDate = useGetMinDate();

    const handleDateChange = (date: Date) => {
        if (shoppingCartState.status === 'empty') {
            console.error('unexpected state');
            return;
        }
        const oldDateTime = shoppingCartState.deliveryDateTime;
        oldDateTime.setFullYear(date.getFullYear());
        oldDateTime.setMonth(date.getMonth());
        oldDateTime.setDate(date.getDate());

        setDeliveryDateTime(oldDateTime, null);
    };

    const handleTimeChanged = (newTime: Date) => {
        setDeliveryDateTime(newTime, null);
    };

    const disabledDates = useMemo(() => {
        const holidayInstance = new Holidays();
        holidayInstance.init('NO', {
            types: ['public'],
        });

        const minDate = new Date();
        const maxDate = addYears(minDate, 1);

        const weekendsInInterval = eachWeekendOfInterval({
            start: minDate,
            end: maxDate,
        });
        let holidaysSecondYear = [] as Holidays.Holiday[];

        const holidaysFirstYear = holidayInstance.getHolidays(getYear(minDate));
        if (differenceInCalendarYears(maxDate, minDate) > 0) {
            holidaysSecondYear = holidayInstance.getHolidays(getYear(maxDate));
        }

        const firstYearHolidaysDates = holidaysFirstYear.map((h: Holidays.Holiday) => h.start);
        const secondYearHolidaysDates = holidaysSecondYear.map((h: Holidays.Holiday) => h.start);

        return [...weekendsInInterval, ...firstYearHolidaysDates, ...secondYearHolidaysDates];
    }, []);

    if (shoppingCartState.status === 'empty') {
        console.error('unexpected state');
        return null;
    }

    return (
        <FieldContainer>
            <FlexContainer>
                <DateSelectorContainer>
                    <DateInput
                        name="orderDate"
                        value={shoppingCartState.deliveryDateTime}
                        onChange={handleDateChange}
                        minDate={minDate}
                        dateformat="d / M / yyyy"
                        maxDate={addYears(new Date(), 1)}
                        excludeDates={disabledDates}
                        parentElementId="site-container"
                        icon={{ name: 'calendar alternate' }}
                    />
                </DateSelectorContainer>
                <TimeSelectorContainer>
                    <TimeDropdown
                        value={shoppingCartState.deliveryDateTime}
                        earliestPossibleTime={max([
                            new Date(),
                            minDate || new Date(),
                            foodMenuState?.data?.departmentBusinessHoursFrom || new Date(),
                        ])}
                        latestPossibleTime={
                            shoppingCartState.deliveryMethod === 'hente'
                                ? new Date(foodMenuState?.data?.departmentBusinessHoursTo || 0)
                                : addMinutes(new Date(foodMenuState?.data?.departmentBusinessHoursTo || 0), -15)
                        }
                        onChange={handleTimeChanged}
                        handleSetIsError={setShouldShowToast}
                        clockIcon
                        noRightIcon
                    />
                </TimeSelectorContainer>
            </FlexContainer>
        </FieldContainer>
    );
};

export default SelectDateAndTime;
