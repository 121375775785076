import { convertToRaw } from 'draft-js';
import { SaveArticleDto } from '../ApiQueries/useArticles';
import { SingleArticleState } from '../SingleArticle/SingleArticleContext';
import { removeSelectionStyle } from '.';

const mapFromEditArticleContextToArticleDto = (state: SingleArticleState): SaveArticleDto => {
    // Remove styles only used in the editor
    const strippedContent = removeSelectionStyle(state.content);
    const content = JSON.stringify(convertToRaw(strippedContent));
    const title = convertToRaw(state.header.getCurrentContent()).blocks[0].text;
    return { content, title, ...mapDetails(state.details), roles: state.details.roles };
};

const mapDetails = (
    details: SingleArticleState['details'],
): Pick<
    SaveArticleDto,
    | 'subcategory'
    | 'coverImage'
    | 'showCoverImage'
    | 'isFeatured'
    | 'alertEmail'
    | 'alertSms'
    | 'alertPush'
    | 'forceAlert'
    | 'haveAlerted'
    | 'publishedAt'
    | 'archivedAt'
    | 'positionX'
    | 'positionY'
> => {
    return {
        ...details,
        coverImage: details.coverImage?.id ?? null,
        alertEmail: details.alertPush && details.alertEmail,
        alertSms: details.alertPush && details.alertSms,
    };
};

export default mapFromEditArticleContextToArticleDto;
