import { FC, SyntheticEvent } from 'react';
import InputStyled from './input.styled';
import { IInputProps } from './types';

const Input: FC<IInputProps> = (props) => {
    const stopPropagationOnInputClick = (event: SyntheticEvent) => event.stopPropagation();
    //Ts error with passing array value
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <InputStyled {...props} onClick={props.stopPropagation ? stopPropagationOnInputClick : undefined} />;
};

export default Input;
