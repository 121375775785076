import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Ref, Sidebar, Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { CustomErrorBoundary } from '../../common';
import PromptModal from './components/PromptModal';
import { SidesheetActionNames } from './context/types';
import { SidesheetContext } from './index';

const StyledSideBar = styled(Sidebar)`
    &&&& {
        transition: ease-out 0.1s all;
    }
`;

const CloseButton = styled(Button)`
    &&& {
        position: absolute;
        right: 0;
        background-color: transparent;
        margin: 0;
        z-index: 1;

        &:hover {
            background-color: transparent;
        }
    }
`;

const CloseButtonIcon = styled(Icon)`
    &&& {
        color: #959595;
        margin: 0 !important;
    }
`;
const ErrorContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Sidesheet: React.FC<React.PropsWithChildren<unknown>> = () => {
    const {
        state: { promptOnClose, isOpen, componentState },
        dispatch,
    } = useContext(SidesheetContext);
    const containerRef = useRef<HTMLElement | null>(null);
    const [isPromptModalOpen, setPromptModal] = useState(false);
    const handleErrorClose = () => {
        dispatch({ type: SidesheetActionNames.CloseSidesheet });
    };
    const { t: tCommon } = useTranslation('common');

    const handleOnClose = () => {
        if (promptOnClose) {
            setPromptModal(true);
        } else {
            dispatch({ type: SidesheetActionNames.CloseSidesheet });
        }
    };
    return (
        <Ref innerRef={containerRef}>
            <StyledSideBar
                role="dialog"
                aria-hidden={!isOpen}
                animation="overlay"
                direction="right"
                visible={isOpen}
                width="very wide"
                style={{
                    maxWidth: '100vw',
                    border: '1px solid var(--border-color)',
                    backgroundColor: 'var(--background-color)',
                    zIndex: 9999 /*Higher than semantic loader*/,
                }}
            >
                <CustomErrorBoundary
                    errorComponent={({ reset }: { reset: () => void }) => (
                        <ErrorContainer>
                            <Button onClick={reset}>{tCommon('errorOccurredTryAgain')}</Button>
                            <Button onClick={handleErrorClose}>{tCommon('closeButton')}</Button>
                        </ErrorContainer>
                    )}
                >
                    {!componentState?.hideCloseIcon ? (
                        <CloseButton onClick={handleOnClose} aria-label="Close sidesheet">
                            <CloseButtonIcon name="close" />
                        </CloseButton>
                    ) : null}
                    {componentState?.component}
                    {promptOnClose && isPromptModalOpen ? (
                        <PromptModal
                            onClose={() => setPromptModal(false)}
                            isOpen={isPromptModalOpen}
                            onAcceptCallback={() => {
                                dispatch({ type: SidesheetActionNames.CloseSidesheet });
                                setPromptModal(false);
                            }}
                        />
                    ) : null}
                </CustomErrorBoundary>
            </StyledSideBar>
        </Ref>
    );
};

export default Sidesheet;
