import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Perm } from '../../common';
import { SidebarMenuItem } from '../../components';
import { useUserHasPerm } from '../../utility';
import { useUserHasTicketingAccess, useUserIsCaseWorker, useUserIsCompanyAdmin } from './apiQueries/useTicketingUser';
import useTicketingNavigation from './useTicketingNavigation';

const TicketingDynamicMenuItem = (): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    const {
        settingsLink,
        goToSettings,
        goToWorkList,
        workListLink,
        goToUserTickets,
        userTicketsLink,
        goToCompanyTickets,
        companyTicketsLink,
    } = useTicketingNavigation();
    const { pathname } = useLocation();
    const canEditTypes = useUserHasPerm(Perm.TicketingTypesUpdate);
    const { data: caseworkerCheck } = useUserIsCaseWorker(!canEditTypes);
    const { data: userHasTicketingAccess } = useUserHasTicketingAccess();
    const { data: companyAdminCheck } = useUserIsCompanyAdmin();

    const isTicketingPathName =
        pathname?.includes(settingsLink) ||
        pathname?.includes(workListLink) ||
        pathname?.includes(userTicketsLink) ||
        pathname?.includes(companyTicketsLink);

    return (
        <SidebarMenuItem
            icon="MessageIconWithContent"
            isActive={isTicketingPathName}
            onClick={() => (userHasTicketingAccess ? goToUserTickets() : goToWorkList())}
            text={t('tickets')}
        >
            {userHasTicketingAccess ? (
                <SidebarMenuItem
                    isActive={pathname?.includes(userTicketsLink)}
                    onClick={goToUserTickets}
                    text={t('myTickets')}
                />
            ) : null}

            {userHasTicketingAccess && companyAdminCheck ? (
                <SidebarMenuItem
                    isActive={pathname?.includes(companyTicketsLink)}
                    onClick={goToCompanyTickets}
                    text={t('myTicketsMenuItem')}
                />
            ) : null}
            {caseworkerCheck?.isCaseWorker || canEditTypes ? (
                <>
                    <SidebarMenuItem
                        isActive={pathname?.includes(workListLink)}
                        onClick={goToWorkList}
                        text={t('worklist')}
                    />
                    <SidebarMenuItem
                        isActive={pathname?.includes(settingsLink)}
                        onClick={goToSettings}
                        text={t('settings')}
                    />
                </>
            ) : null}
        </SidebarMenuItem>
    );
};

export default TicketingDynamicMenuItem;
