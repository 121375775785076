import _ from 'lodash';
import type { AnyAction } from 'redux';
import { createReducer } from 'reduxsauce';
import { v4 as uuidv4 } from 'uuid';
import { Module } from '../app/api/apiTypes/portalApiTypes';
import { Service } from '../app/common/lifeAtWorkServices';
import { Types } from './actions';

export interface AppContextStateProps {
    isError: boolean;
    messages: string[];
    errorRedirectUri: string | null;
    portal: any;
    user: any;
    modules: Module[];
    selectedModule: Module | null;
    selectedSubModule: Module | null;
    roles: string[];
    perms: string[];
    isLoading: boolean;
    hasLoadedAppContext: boolean;
    defaultModuleName: string | null;
    deviceType: string | null;
    selectedLang: string;
    fallbackLang: string;
    navigationPath: {
        defaultText: string;
        guid: string;
        link: string;
    }[];
    services: Service[];
    showReturnButton: boolean;
    accessControlApiUrl: string | null;
}

export const INITIAL_STATE: AppContextStateProps = {
    isError: false,
    messages: [],
    errorRedirectUri: null,
    portal: null,
    user: null,
    modules: [],
    selectedModule: null,
    selectedSubModule: null,
    roles: [],
    perms: [],
    isLoading: false,
    hasLoadedAppContext: true,
    defaultModuleName: null,
    deviceType: null,
    selectedLang: 'nb',
    fallbackLang: 'nb',
    navigationPath: [
        {
            defaultText: 'Front page',
            guid: 'caa7f38e-bffa-470d-ae12-187ef70c049a',
            link: '/',
        },
    ],
    services: [],
    showReturnButton: false,
    accessControlApiUrl: null,
};

export const requestAppContext = (state = INITIAL_STATE): unknown => {
    return {
        ...state,
        isLoading: false,
        hasLoadedAppContext: false,
    };
};

export const receiveAppContext = (state = INITIAL_STATE, action: AnyAction): unknown => {
    const { appContext } = action;
    return {
        ...state,
        user: _.get(appContext, 'user'),
        modules: _.get(appContext, 'modules'),
        perms: _.get(appContext, 'perms'),
        roles: _.get(appContext, 'roles'),
        hasLoadedAppContext: true,
        isLoading: false,
        services: _.get(appContext, 'services'),
        accessControlApiUrl: _.get(appContext, 'physicalAccessApiUrl'),
    };
};

export const setSelectedModule = (state = INITIAL_STATE, action: AnyAction): unknown => {
    const { module } = action;
    return {
        ...state,
        selectedModule: module,
    };
};

export const setSelectedSubModule = (state = INITIAL_STATE, action: AnyAction): unknown => {
    const { subModule } = action;
    return {
        ...state,
        selectedSubModule: subModule,
    };
};

export const setDeviceType = (state = INITIAL_STATE, action: AnyAction): unknown => {
    const { deviceType } = action;
    return {
        ...state,
        deviceType,
    };
};

export const setSelectedLang = (state = INITIAL_STATE, action: AnyAction): unknown => {
    const { lang } = action;
    return {
        ...state,
        selectedLang: lang,
    };
};

export const handleMessage = (state = INITIAL_STATE, action: AnyAction): unknown => {
    const { messageText, messageType, messageTitle } = action;
    const message = {
        guid: uuidv4(),
        message: messageText,
        type: messageType,
        title: messageTitle,
    };
    return {
        ...state,
        messages: [...state.messages, message],
    };
};

export const removeMessage = (state = INITIAL_STATE, action: AnyAction): unknown => {
    const { guid } = action;
    return {
        ...state,
        messages: _.filter(state.messages, (event: any) => {
            return event.guid !== guid;
        }),
    };
};

export const addNavigationPathElement = (state = INITIAL_STATE, action: AnyAction): unknown => {
    const { pathElement } = action;
    const newPath = _.uniq([..._.get(state, 'navigationPath', []), pathElement]);

    return { ...state, navigationPath: newPath };
};

export const setNavigationPath = (state = INITIAL_STATE, action: AnyAction): unknown => {
    const { path } = action;
    return {
        ...state,
        navigationPath: [...INITIAL_STATE.navigationPath, ...path],
    };
};

export const setShowReturnButton = (state = INITIAL_STATE, action: AnyAction): unknown => {
    const { show } = action;
    return { ...state, showReturnButton: show };
};

export const HANDLERS: any = {
    [Types.APP_HANDLE_MESSAGE]: handleMessage,
    [Types.APP_REMOVE_MESSAGE]: removeMessage,
    [Types.APP_REQUEST_APP_CONTEXT]: requestAppContext,
    [Types.APP_RECEIVE_APP_CONTEXT]: receiveAppContext,
    [Types.APP_SET_SELECTED_MODULE]: setSelectedModule,
    [Types.APP_SET_SELECTED_SUB_MODULE]: setSelectedSubModule,
    [Types.APP_SET_DEVICE_TYPE]: setDeviceType,
    [Types.APP_SET_SELECTED_LANG]: setSelectedLang,
    [Types.APP_ADD_NAVIGATION_PATH_ELEMENT]: addNavigationPathElement,
    [Types.APP_SET_NAVIGATION_PATH]: setNavigationPath,
    [Types.APP_SET_SHOW_RETURN_BUTTON]: setShowReturnButton,
};

export default createReducer(INITIAL_STATE, HANDLERS);
