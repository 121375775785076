import { PropsWithChildren, ReactElement } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const DimmerLoader = ({ children }: PropsWithChildren<unknown>): ReactElement => (
    <Dimmer active inverted>
        <Loader>{children}</Loader>
    </Dimmer>
);

export default DimmerLoader;
