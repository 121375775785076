import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GhostPrimaryButton, Modal, ModalType, PrimaryButton } from '../../../../components';

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1.142rem;
`;

const ModalDescription = styled.p`
    line-height: 1.714rem;
    padding: 1.714rem;
`;

interface AccessControlMobileKeyModalProps {
    open: boolean;
    onClose(): void;
    accessSystemId: string;
    reassignFunction: (accessSystem: string) => void;
}

const AccessControlMobileKeyModal = ({
    open,
    onClose,
    accessSystemId,
    reassignFunction,
}: AccessControlMobileKeyModalProps): ReactElement => {
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('myProfile');

    const handleCreateNewMobileKey = (): void => {
        reassignFunction(accessSystemId);
    };

    const buttons = () => {
        return (
            <ButtonsWrapper>
                <GhostPrimaryButton onClick={onClose}>{tCommon('cancelButton')}</GhostPrimaryButton>
                <PrimaryButton onClick={handleCreateNewMobileKey}>{t('requestNewButtonText')}</PrimaryButton>
            </ButtonsWrapper>
        );
    };
    return (
        <Modal
            type={ModalType.Small}
            open={open}
            onClose={onClose}
            bottomContent={buttons()}
            title={tCommon('areYouSure')}
        >
            <ModalDescription>{t('requestNewModalText')}</ModalDescription>
        </Modal>
    );
};

export default AccessControlMobileKeyModal;
