import React, { useEffect, useState } from 'react';
import { max, min } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { MyBooking } from '../../../api/apiTypes/bookingApiTypes';
import { TimeInput } from '../../../components';
import ExistingMeetingCard from '../../../components/meeting/ExistingMeetingCard';
import { IBookingsAPI } from '../../Meeting/types';
import { useGetMenu, useGetMinDate } from '../apiQueries/useMenu';
import { getInvalidMeetingMessage } from '../utils';

const StyledModal = styled(Modal)`
    && {
        max-width: 100vw;
        width: 32rem;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
`;

const HeaderContent = styled(Modal.Header)`
    &&&& {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        padding-bottom: 0;
    }
`;

const ModalHeader = styled.h2`
    margin: 0;
    font-size: 1.35rem;
`;

const CloseButton = styled(Button)`
    &&& {
        background: none;
    }
`;

const TimeSelectorContainer = styled.div`
    margin: 1rem;
`;

const CancelButton = styled(Button)`
    &&& {
        background: none;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
    }
`;

const NextButton = styled(Button)`
    &&& {
        background: var(--primary-color);
        border: 1px solid var(--primary-color);
        color: var(--primary-on-color);
        margin-left: 1rem;
    }
`;

const ActionContainer = styled(Modal.Actions)`
    &&&& {
        display: flex;
        justify-content: flex-end;
        border: none;
        background: none;
    }
`;

interface SelectMeetingModalProps {
    meetings: IBookingsAPI[] | MyBooking[];
    defaultMeeting?: IBookingsAPI | null | MyBooking;
    handleClose: () => void;
    selectMeeting: (meetingId: string, deliveryDateTime: Date) => void;
    defaultDeliveryTime?: Date | null;
    earliestDeliveryTime?: Date;
}

const SelectMeetingModal: React.FC<React.PropsWithChildren<SelectMeetingModalProps>> = ({
    meetings,
    handleClose,
    selectMeeting,
    defaultMeeting = null,
    defaultDeliveryTime = null,
}: SelectMeetingModalProps) => {
    const [modalSelectedMeeting, setModalSelectedMeeting] = useState<IBookingsAPI | null | MyBooking>(defaultMeeting);
    const [deliveryTime, setDeliveryTime] = useState<Date | null>(defaultDeliveryTime);
    const { data: foodMenu } = useGetMenu(modalSelectedMeeting?.startTime ?? null);
    const minDate = useGetMinDate();

    useEffect(() => {
        // scrollTo default selectedMeeting
        if (defaultMeeting) {
            const elem = document.querySelector(`[data-meetingid='${defaultMeeting.id}']`);
            if (elem) {
                elem.scrollIntoView();
            }
        }
    }, [defaultMeeting]);

    const handleCancel = (): void => {
        handleClose();
    };

    const handleNextClick = (): void => {
        if (modalSelectedMeeting) {
            setDeliveryTime(
                max([
                    modalSelectedMeeting.startTime,
                    foodMenu?.departmentBusinessHoursFrom ?? new Date(),
                    minDate ?? new Date(),
                ]),
            );
        }
    };
    const handleSelectMeeting = (): void => {
        if (modalSelectedMeeting && deliveryTime) {
            selectMeeting(modalSelectedMeeting.id, deliveryTime);
        }
    };
    const { t } = useTranslation('Food');
    const { t: tCommon } = useTranslation('common');
    return (
        <StyledModal open>
            {!deliveryTime || !modalSelectedMeeting ? (
                <>
                    <HeaderContent>
                        <ModalHeader>{t('commonFood.selectMeeting')}</ModalHeader>
                        <CloseButton onClick={handleCancel} icon="close" />
                    </HeaderContent>
                    <Modal.Content scrolling>
                        {meetings.map((meeting) => {
                            const errorMessage =
                                foodMenu !== undefined
                                    ? getInvalidMeetingMessage(
                                          meeting.startTime,
                                          meeting.endTime,
                                          foodMenu.departmentBusinessHoursFrom,
                                          foodMenu.departmentBusinessHoursTo,
                                          minDate || new Date(),
                                          t,
                                      )
                                    : undefined;
                            return (
                                <ExistingMeetingCard
                                    key={meeting.id}
                                    meeting={meeting}
                                    onClick={() => setModalSelectedMeeting(meeting)}
                                    showOrganizerName
                                    toggleable={!errorMessage}
                                    error={errorMessage}
                                    toggled={modalSelectedMeeting?.id === meeting.id}
                                />
                            );
                        })}
                    </Modal.Content>
                    <ActionContainer>
                        <CancelButton onClick={handleCancel}>{tCommon('cancelButton')}</CancelButton>
                        <NextButton disabled={!modalSelectedMeeting} onClick={handleNextClick}>
                            {tCommon('next')}
                        </NextButton>
                    </ActionContainer>
                </>
            ) : (
                <>
                    <HeaderContent>
                        <ModalHeader>{t('commonFood.selectMeeting')}</ModalHeader>
                        <CloseButton onClick={handleCancel} icon="close" />
                    </HeaderContent>
                    <TimeSelectorContainer>
                        {modalSelectedMeeting && (
                            <ExistingMeetingCard meeting={modalSelectedMeeting} showOrganizerName />
                        )}
                        {foodMenu && minDate ? (
                            <TimeInput
                                selectedTime={deliveryTime}
                                onChangeSelectedTime={setDeliveryTime}
                                earliestTime={max([
                                    modalSelectedMeeting.startTime,
                                    foodMenu.departmentBusinessHoursFrom,
                                    minDate as Date,
                                ])}
                                latestTime={min([modalSelectedMeeting.endTime, foodMenu.departmentBusinessHoursTo])}
                            />
                        ) : null}
                    </TimeSelectorContainer>
                    <ActionContainer>
                        <CancelButton onClick={() => setDeliveryTime(null)}>{tCommon('buttonBack')}</CancelButton>
                        <NextButton onClick={handleSelectMeeting}>{tCommon('next')}</NextButton>
                    </ActionContainer>
                </>
            )}
        </StyledModal>
    );
};

export default SelectMeetingModal;
