import { ReactElement } from 'react';
import styled from 'styled-components';
import { Ticket } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { PrimaryButton, SvgIcon } from '../../../../../../components';
import { useUpdateTicket } from '../../../../apiQueries/useTicketingTicket';
import { isButtonDisabled } from '../utils/utils';

type ButtonProps = { $isMobileView: boolean };

const Button = styled(PrimaryButton)`
    &&& {
        padding: 0.55rem 1.1rem;
        width: ${({ $isMobileView }: ButtonProps) => ($isMobileView ? '100%' : '')};

        svg {
            margin-right: 0.57rem;
        }
    }
`;

interface ResolveTicketButtonProps {
    isMobileView: boolean;
    onClick(): void;
    text: string;
    ticket: Ticket;
}

const ResolveTicketButton = ({ isMobileView, onClick, text, ticket }: ResolveTicketButtonProps): ReactElement => {
    const [updateTicket, { isLoading: isUpdatingTicket }] = useUpdateTicket();

    return (
        <Button
            type="submit"
            onClick={onClick}
            $isMobileView={isMobileView}
            disabled={isButtonDisabled(ticket, isUpdatingTicket)}
        >
            <SvgIcon name="CheckIcon" /> {text}
        </Button>
    );
};

export default ResolveTicketButton;
