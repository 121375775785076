import { ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { SvgIcon, ShimmerAnimation } from '../../../../../components';
import { Columns } from '../../../utils';
import { ColumnType } from './UserTicketTable';

const greyColorLight = '#e4e4e4';
const greyColor = '#c4c4c4';

const fadeOut = keyframes`
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
`;

type WrapperProps = { show?: boolean };
const Wrapper = styled.div`
    position: relative;
    padding: 0 0.5rem 1rem 0.5rem;
    animation: ${({ show }: WrapperProps) =>
        show
            ? 'none;'
            : css`
                  ${fadeOut} 0.1s linear forwards;
              `};
`;

type ContainerProps = { collapse?: boolean };
const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    box-shadow: inset 0 0 0 1px #d4d6d7;
    height: 6.071rem;
    min-height: 6.071rem;
    padding: 0.5rem 1rem;

    ${({ collapse }: ContainerProps) =>
        collapse && {
            marginTop: '1rem',
            height: '14rem',
            display: 'grid',
            justifyContent: 'unset',
            gridTemplateAreas: `'. ${Columns.CaseNumber} ${Columns.Status}'
                '. ${Columns.LastUpdated} ${Columns.Status}'
                '${Columns.Notification} ${Columns.Subject} ${Columns.Subject}'
                '${Columns.Priority} ${Columns.Subject} ${Columns.Subject}'
                '. ${Columns.Requester} ${Columns.Requester}'
                '. ${Columns.Company} ${Columns.Company}'
                '. ${Columns.Location} ${Columns.Location}'
                '. ${Columns.Created} ${Columns.Created}'`,
            gridTemplateColumns: '3.5rem auto 5rem',
        }}
`;

const SkeletonItem = styled.div`
    border-radius: 0.357rem;
`;

const UserTicketWrapper = styled.div`
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const ItemContainer = styled.div`
    display: contents;
    align-items: center;
    margin-bottom: 1rem;
`;

const MessageNotificationContainer = styled.div`
    display: flex;
    align-items: center;
    grid-area: ${Columns.Notification};
    grid-row-start: auto;
`;

const NumbersOfNotifications = styled(SkeletonItem)`
    height: 1rem;
    width: 0.9rem;
    background-color: ${greyColorLight};
`;

type PriorityContainerProps = { collapse: boolean };
const PriorityContainer = styled.div`
    width: ${({ collapse }: PriorityContainerProps) => (collapse ? '100%' : '4%')};
    margin-left: 0.858rem;
    grid-area: ${Columns.Priority};
    grid-row-end: auto;
`;

const Priority = styled.div`
    background-color: ${greyColor};
    height: 1.214rem;
    width: 1.214rem;
    border-radius: 50%;
    flex-shrink: 1;
`;

type SubjectTypeCategoryCaseNumberContainer = { collapse: boolean };
const SubjectTypeCategoryCaseNumberContainer = styled.div`
    width: ${({ collapse }: SubjectTypeCategoryCaseNumberContainer) => (collapse ? '90%' : '38%')};
    grid-area: ${Columns.Subject};
`;

type SubjectLabelContainerProps = { collapse: boolean };
const SubjectLabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    flex-grow: 2;
    width: 90%;
    margin-left: ${({ collapse }: SubjectLabelContainerProps) => (collapse ? '0' : '0.858rem')};
`;

const Subject = styled(SkeletonItem)`
    width: 100%;
    height: 1.2rem;
    background-color: ${greyColorLight};
`;

const TypeCategoryContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

const TypeCategoryLabelText = styled(SkeletonItem)`
    padding-right: 1.143rem;
    height: 1rem;
    width: 20%;
    background-color: ${greyColorLight};
`;

const CaseNumber = styled(SkeletonItem)`
    width: 30%;
    height: 1rem;
    background-color: ${greyColorLight};
`;

const CaseNumberMobile = styled.div`
    width: 50%;
    height: 1rem;
    background-color: ${greyColorLight};
    grid-area: ${Columns.CaseNumber};
`;

type StatusProps = { collapse: boolean };
const Status = styled.div`
    width: 5%;
    min-width: 4.286rem;
    background-color: ${greyColorLight};
    height: 1.5rem;
    border-radius: 2.143rem;
    margin-left: ${({ collapse }: StatusProps) => (collapse ? '0' : '0.858rem')};
    grid-area: ${Columns.Status};
    justify-self: flex-end;
`;

type UpdatedProps = { collapse: boolean };
const Updated = styled(SkeletonItem)`
    height: ${({ collapse }: UpdatedProps) => (collapse ? '1rem' : '1.285rem')};
    width: ${({ collapse }: UpdatedProps) => (collapse ? '90%' : '9%')};
    background-color: ${greyColorLight};
    margin-left: ${({ collapse }: UpdatedProps) => (collapse ? '0' : '0.858rem')};
    grid-area: ${Columns.LastUpdated};
`;

const Assignee = styled(SkeletonItem)`
    width: 9%;
    height: 1.285rem;
    background-color: ${greyColorLight};
    margin-left: 0.858rem;
    grid-area: ${Columns.Caseworker};
`;

type RequesterProps = { collapse: boolean };
const Requester = styled(SkeletonItem)`
    width: ${({ collapse }: RequesterProps) => (collapse ? '40%' : '10%')};
    height: 1.285rem;
    background-color: ${greyColorLight};
    margin-left: ${({ collapse }: RequesterProps) => (collapse ? '0' : '0.858rem')};
    grid-area: ${Columns.Requester};
`;

type CompanyProps = { collapse: boolean };
const Company = styled(SkeletonItem)`
    width: ${({ collapse }: CompanyProps) => (collapse ? '40%' : '10%')};
    height: 1.285rem;
    background-color: ${greyColorLight};
    margin-left: ${({ collapse }: CompanyProps) => (collapse ? '0' : '0.858rem')};
    grid-area: ${Columns.Company};
`;

type LocationProps = { collapse: boolean };
const Location = styled(SkeletonItem)`
    width: ${({ collapse }: LocationProps) => (collapse ? '40%' : '10%')};
    height: 1.285rem;
    background-color: ${greyColorLight};
    margin-left: ${({ collapse }: LocationProps) => (collapse ? '0' : '0.858rem')};
    grid-area: ${Columns.Location};
`;

type CreatedDateProp = { collapse: boolean };
const CreatedDate = styled(Location)`
    grid-area: ${Columns.Created};
    width: ${({ collapse }: CreatedDateProp) => collapse && '60%'};
`;

interface UserTicketSkeletonLoaderProps {
    show?: boolean;
    numberOfItems?: number;
    collapse?: boolean;
    columns: ColumnType;
}

const UserTicketSkeletonLoader = ({
    show,
    collapse = false,
    numberOfItems = 1,
    columns,
}: UserTicketSkeletonLoaderProps): ReactElement => {
    let items = [];
    const viewColumns = columns.map((column) => column.key);
    const item = (index: number) => (
        <Wrapper show={show} key={index}>
            <Container collapse={collapse}>
                <ItemContainer>
                    {viewColumns.includes(Columns.Notification) && (
                        <MessageNotificationContainer>
                            <SvgIcon name="MessageIcon" />
                            <NumbersOfNotifications />
                        </MessageNotificationContainer>
                    )}

                    {viewColumns.includes(Columns.Priority) && (
                        <PriorityContainer collapse={collapse}>
                            <Priority />
                        </PriorityContainer>
                    )}
                </ItemContainer>

                <ItemContainer>{viewColumns.includes(Columns.CaseNumber) && <CaseNumberMobile />}</ItemContainer>

                <ItemContainer>
                    {viewColumns.includes(Columns.Subject) && (
                        <SubjectTypeCategoryCaseNumberContainer collapse={collapse}>
                            <SubjectLabelContainer collapse={collapse}>
                                <Subject />
                                {viewColumns.includes(Columns.Type) && viewColumns.includes(Columns.Category) && (
                                    <>
                                        <TypeCategoryContainer>
                                            <TypeCategoryLabelText />
                                            {'>'}
                                            <TypeCategoryLabelText />
                                        </TypeCategoryContainer>
                                        {!collapse && <CaseNumber />}
                                    </>
                                )}
                            </SubjectLabelContainer>
                        </SubjectTypeCategoryCaseNumberContainer>
                    )}
                </ItemContainer>

                {viewColumns.includes(Columns.Status) && (
                    <ItemContainer>
                        <Status collapse={collapse} />
                    </ItemContainer>
                )}

                {viewColumns.includes(Columns.LastUpdated) && (
                    <ItemContainer>
                        <Updated collapse={collapse} />
                    </ItemContainer>
                )}

                {viewColumns.includes(Columns.Caseworker) && (
                    <ItemContainer>
                        <Assignee />
                    </ItemContainer>
                )}

                {viewColumns.includes(Columns.Requester) && (
                    <ItemContainer>
                        <Requester collapse={collapse} />
                    </ItemContainer>
                )}

                {viewColumns.includes(Columns.Company) && (
                    <ItemContainer>
                        <Company collapse={collapse} />
                    </ItemContainer>
                )}

                {viewColumns.includes(Columns.Location) && (
                    <ItemContainer>
                        <Location collapse={collapse} />
                    </ItemContainer>
                )}
                {viewColumns.includes(Columns.Created) && (
                    <ItemContainer>
                        <CreatedDate collapse={collapse} />
                    </ItemContainer>
                )}
            </Container>
            <ShimmerAnimation />
        </Wrapper>
    );
    items = Array.from({ length: numberOfItems }, (_, index) => item(index));
    return <UserTicketWrapper>{items}</UserTicketWrapper>;
};

export default UserTicketSkeletonLoader;
