export interface UserIsCaseWorker {
    isCaseWorker: boolean;
}

export interface CaseworkerModel {
    portalId?: number;
    id: string;
    name: string | null;
    avatar?: string | null;
    email: string;
    firstName?: string;
    lastName?: string;
}

export type UserIsCompanyAdmin = boolean;

export interface UpdateTicketTypeCaseworkerDto {
    id: string;
    firstName: string;
    lastName: string;
    avatar: string;
}

export interface UpdateTicketTypeDto {
    name?: string;
    internalDescription?: string;
    externalDescription?: string;
    isPublished?: boolean;
    integrationId?: string | null;
}

export interface TicketType {
    id: string;
    categories: Category[];
    name: string;
    isPublished: boolean;
    internalDescription: string;
    externalDescription: string;
    caseWorkers: CaseworkerModel[];
    integrationId: string | null;
}

export interface TicketTypeAndCategoryOption {
    id: string;
    name: string;
    categories: TicketOptionType[];
}

export interface PreparedFiltersType {
    type: TicketFilterType;
    value: string[];
}

export interface UserTicketType {
    id: string;
    categories: UserCategory[];
    name: string;
    externalDescription: string;
    integrationId: string | null;
}

export interface CreateTicketTypeDto {
    name: string;
    internalDescription: string;
    externalDescription: string;
    categories: {
        name: string;
    }[];
}

export interface NotificationSettings {
    caseWorkerId: string;
    emailEnabled: boolean;
    pushEnabled: boolean;
}

export interface Category {
    id: string;
    name: string;
    externalLinkUrl: string;
    isPublished: boolean;
    internalDescription: string;
    externalDescription: string;
    defaultCaseWorker: UpdateTicketTypeCaseworkerDto;
    defaultCaseWorkerId?: string | null;
    ticketCount: number;
    notificationSettings: {
        caseWorkerId: string;
        emailEnabled: boolean;
        pushEnabled: boolean;
    }[];
}

export interface UserCategory {
    id: string;
    name: string;
    externalDescription: string;
    externalLinkUrl: string | null;
}

export interface UpdateTicketingTypeCategory {
    name?: string;
    externalLinkUrl?: string;
    internalDescription?: string;
    externalDescription?: string;
    isPublished?: boolean;
    defaultCaseWorkerId?: string | null;
    notificationSettings?: NotificationSettings[];
}

export interface CreateCategoryDto {
    name: string;
}

export interface PortalUserModel {
    portal_id: number;
    guid: string;
    name: string;
    email: string;
    phone: string | null;
    avatar: string | null;
}

export interface DeleteTypeCollectionItemDto {
    categoryId: string;
    newTypeId: string;
}

export interface TicketReportee {
    id: string;
    firstName: string;
    avatar: string;
    lastName: string;
    phone: string;
    email: string;
    companyName: string;
}

export enum TicketPriority {
    Critical = 'Critical',
    High = 'High',
    Medium = 'Medium',
    Low = 'Low',
}

export enum TicketStatus {
    New = 'New',
    Open = 'Open',
    Pending = 'Pending',
    Solved = 'Solved',
    Closed = 'Closed',
}

export interface TicketImage {
    imageUrl: string;
    imageFileName: string;
}

export enum TicketSortDirection {
    Asc = 0,
    Desc = 1,
}

export enum TicketSortingType {
    Priority = 0,
    Status = 10,
    Subject = 20,
    Category = 30,
    Type = 40,
    LastUpdate = 50,
    Created = 60,
    Location = 70,
    Assignee = 80,
    Company = 90,
    Notification = 100,
    Requester = 110,
    CaseNumber = 120,
}

export enum TicketFilterType {
    Company = 0,
    Assignee = 10,
    Category = 20,
    Status = 30,
    Priority = 40,
}

export interface TicketOptionType {
    id: string;
    name: string;
}

interface TicketAssignee {
    avatar: string;
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    phone: string;
}

export interface Asset {
    id: string;
    name: string;
}

export interface TicketGridResult {
    data: Ticket[];
    count: number;
}

export interface Ticket {
    id: string;
    externalId?: string;
    portalId: number;
    createdDate: string;
    updatedDate: string;
    title: string;
    description: string;
    ticketImage: TicketImage;
    companyName?: string;
    type: Asset;
    category: Asset;
    registeredBy: TicketReportee;
    priority: TicketPriority;
    number: string;
    status: TicketStatus;
    assignee: TicketAssignee | null;
    building: Asset | null;
    floor: Asset | null;
    unreadCommentsAndInternalNotes: number;
    recipient?: string;
}

export interface CreateTicketDto {
    title: string;
    description: string;
    attachments: UploadedTicketAttachment[];
    building: Asset | null;
    floor: Asset | null;
    priority?: string;
    status?: string;
    assigneeId?: string;
    assigneeEmail?: string;
}
export interface UploadedTicketAttachment {
    imageUrl: string;
    imageFileName: string;
}

export interface DeleteCategoryDto {
    newTypeId: string;
    newCategoryId: string;
}

export enum ChangeType {
    CreateTicket = 0,
    AssignToSelf = 10,
    AssignToOther = 15,
    ChangeTicketStatus = 20,
    ChangeInternalTicketStatus = 30,
    ChangeCategory = 40,
    ChangeTypeAndCategory = 50,
    AddInternalNote = 60,
    ChangePriority = 70,
}

type Seen = { seen: boolean };
export type TypeCategoryActivityChange = { CategoryName: string; TypeName: string };

export interface InternalNote {
    createdDate: string;
    description: string;
    id: string;
    registeredBy: TicketRegisteredBy;
    registeredById: string;
    ticketId: string;
    ticketImage: TicketImage;
}

export interface TicketActivity {
    id: string;
    ticketId: string;
    registeredBy: TicketReportee;
    internalNote: InternalNote;
    createdDate: string;
    changeType: ChangeType;
    oldValue: string;
    newValue: string;
}

export interface TicketRegisteredBy {
    id: string;
    firstName: string;
    avatar: string;
    lastName: string;
    phone: string;
    email: string;
    createdBy: string;
    createdDate: string;
    updatedBy: string;
    updatedDate: string;
}

export type TicketComment = {
    ticketImage?: TicketImage;
    ticketImages?: TicketImage[];
    description?: string;
    id: string;
    createdDate: string;
    registeredBy: TicketRegisteredBy;
};

export type TicketReadCount = {
    allCount: number;
    readCount: number;
};
export type TicketReadCountDto = {
    seenEntity: TicketSeenEntity;
    lastSeenDateTime: Date;
};

export enum TicketSeenEntity {
    Comments = 0,
    InternalNotes = 10,
}

export type TicketCommentWithSeen = TicketComment & Seen;

export type CreateTicketComment = { description: string } | { attachments: TicketImage[] };

export interface UpdateTicket {
    newTypeId?: string;
    newCategoryId?: string;
    description?: string;
    priority?: string;
    status?: string;
    assigneeId?: string | null;
    building?: Asset | null;
    floor?: Asset | null;
}

export interface TicketUserCount {
    count: number;
}

export interface UploadedTicketImage {
    id: string;
    uncompressedUrl: string;
    fileName: string;
    extension: string;
    createdBy: string;
    createdDate: string;
    updatedBy: string;
    updatedDate: string;
}

export interface TicketAsset {
    id: string;
    name: string;
    floors: Asset[];
}

export interface DropdownTicketAsset {
    id: string;
    label: string;
    floors: {
        id: string;
        label: string;
    }[];
}

export interface CategoryOrder {
    categoryId: string;
    orderValue: number;
}

export enum FDVIntegrationTypes {
    CuroTech = 'CuroTech',
    Properly = 'Propely',
    Famac = 'Famac',
    None = 'None',
}

export interface TypeIntegrationDto {
    caseworkerId: string | null;
    createdBy: string | null;
    createdDate: string | null;
    description: FDVIntegrationTypes;
    externalId: string;
    id: string;
    portalId: number;
    recipient: number;
    types: TicketType[];
    updatedBy: string | null;
    updatedDate: string | null;
}

export const TICKET_SOLVED_GUID = '2e96e5ab-7c65-456b-8ea8-3f4ebdeea051';
