import { ReactElement, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SingleValue } from 'react-select';
import styled from 'styled-components';
import { MobileViewBreakpoint } from '../../../../common/ScreenSizeBreakPoints';
import { InputSelect } from '../../../../components';
import { OptionTypeWithId } from '../../../../components/general/dropdown/InputSelect';
import { useGetTicketAssets } from '../../apiQueries/useTicketAssets';
import { NewTicketForm } from '../../NewTicket/NewTicketModal';

const LocationContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    gap: 0.571rem;

    @media (max-width: ${MobileViewBreakpoint}px) {
        flex-direction: column;
        gap: 1rem;
    }
`;

const BuildingInputSelect = styled(InputSelect)`
    width: 50%;

    @media (max-width: ${MobileViewBreakpoint}px) {
        width: 100%;
    }
`;

const FloorInputSelect = styled(BuildingInputSelect)``;

export interface AssetForm {
    building: OptionTypeWithId;
    floor: OptionTypeWithId;
}

interface AssetSelectionProps {
    buildingLabel: string;
    buildingValue?: OptionTypeWithId;
    floorLabel: string;
    floorValue?: OptionTypeWithId;
    onBuildingChange(selectedValue: SingleValue<OptionTypeWithId>): void;
    onFloorChange(selectedValue: SingleValue<OptionTypeWithId>): void;
    className?: string;
    disable?: boolean;
}

const AssetSelection = ({
    buildingLabel,
    buildingValue,
    floorLabel,
    floorValue,
    onBuildingChange,
    onFloorChange,
    className,
    disable,
    ...rest
}: AssetSelectionProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    const { data: assets, isLoading } = useGetTicketAssets();
    const {
        register,
        formState: { errors },
    } = useFormContext<NewTicketForm>();

    const floorOptions = useMemo(() => {
        return assets?.find((asset) => asset.id === buildingValue?.id)?.floors;
    }, [assets, buildingValue]);

    return (
        <LocationContainer className={className}>
            <BuildingInputSelect
                label={buildingLabel}
                isClearable
                id="ticketBuilding"
                options={assets ?? []}
                placeholder={t('buildingLabel')}
                showSearchIcon
                error={errors.building?.message}
                isLoading={isLoading}
                loadingMessage={t('loadingBuilding')}
                value={buildingValue}
                disable={disable}
                {...rest}
                {...register('building')}
                onChange={onBuildingChange}
            />
            <FloorInputSelect
                label={floorLabel}
                isClearable
                id="ticketFloor"
                options={floorOptions ?? []}
                placeholder={t('floorLabel')}
                showSearchIcon
                isLoading={isLoading}
                loadingMessage={t('loadingFloors')}
                disable={disable || !buildingValue}
                {...register('floor')}
                value={floorValue}
                onChange={onFloorChange}
                {...rest}
            />
        </LocationContainer>
    );
};

export default AssetSelection;
