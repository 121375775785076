import { useEffect } from 'react';

const useDisableMouseWheel = (condition: boolean): void => {
    useEffect(() => {
        const scrolling = (e: any) => condition && e.preventDefault();
        window.addEventListener('mousewheel', scrolling, { passive: condition ? false : true });

        return () => {
            window.removeEventListener('mousewheel', scrolling);
        };
    }, [condition]);
};

export default useDisableMouseWheel;
