import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SvgIcon from '../icons/SvgIcon';
import GhostPrimaryButton from './GhostPrimaryButton';

const BackButtonStyled = styled(GhostPrimaryButton)`
    svg {
        transform: rotate(-90deg);
        margin-right: 0.571rem;

        path {
            fill: var(--primary-color);
        }
    }
`;

interface BackButtonProps {
    onClick(): void;
    className?: string;
}

const BackButton = ({ onClick, className }: BackButtonProps): ReactElement => {
    const { t } = useTranslation('common');
    return (
        <BackButtonStyled className={className} onClick={onClick}>
            <SvgIcon name="ArrowIcon" />
            {t('buttonBack')}
        </BackButtonStyled>
    );
};

export default BackButton;
