import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import SvgIcon from '../icons/SvgIcon';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Logo = styled.img`
    max-width: 200px;
    margin: 1rem;
`;

const ErrorHeader = styled.h1`
    color: var(--text-high-emphasis-color);
    font-weight: 900;
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin: 0.5rem;
`;
const ErrorDescription = styled.h2`
    color: var(--text-high-emphasis-color);
    font-weight: normal;
    font-size: 1.15rem;
    line-height: 1.4rem;
    margin: 0;
`;

const ActionLink = styled.a`
    color: var(--primary-color);
`;

const EmptyIconImageContainer = styled.div`
    min-height: 0;
    display: flex;
    justify-content: center;
    svg {
        height: 13.23rem;
        width: 9.7rem;
    }
    && #path0 {
        fill: var(--primary-color);
        fill-opacity: 1;
    }
    && #path1 {
        fill: var(--primary-color);
        fill-opacity: 0.2;
    }
    && #path2 {
        fill: var(--primary-color);
        fill-opacity: 0.1;
    }
`;

interface ErrorScreenProps {
    errorMessage: string;
}

const ErrorScreen: React.FC<React.PropsWithChildren<ErrorScreenProps>> = ({ errorMessage }) => {
    return (
        <Container>
            <Logo src="./Life@Work_Logo.png" alt="Life@Work Logo" />
            <EmptyIconImageContainer>
                <SvgIcon name="EmptyIcon" />
            </EmptyIconImageContainer>
            <ErrorHeader>{errorMessage}</ErrorHeader>
            <ErrorDescription>
                <Trans
                    i18nKey="common:errorMessage"
                    components={{
                        actionLink1: <ActionLink href="http://www.lifeatwork.as" />,
                        actionLink2: <ActionLink href="mailto:support@lifeatwork.no" />,
                    }}
                />
            </ErrorDescription>
        </Container>
    );
};

export default ErrorScreen;
