import { ReactElement, SyntheticEvent } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

type FullWindowDivProps = { open: boolean };
const FullWindowDiv = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(71, 81, 86, 0.8);
    z-index: 3;
    display: ${({ open }: FullWindowDivProps) => (open ? 'flex' : 'none')};
    align-items: flex-end;
    justify-content: center;
`;

const Container = styled.div`
    padding: 1.142rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
    border-radius: 0.571rem 0.571rem 0 0;
    background-color: var(--surface-color-light);
`;

interface DrawerProps {
    children: ReactElement;
    open: boolean;
    onClose(): void;
}

const Drawer = ({ children, open, onClose }: DrawerProps): ReactElement => {
    return (
        <AnimatePresence>
            {open && (
                <FullWindowDiv open={open} onClick={onClose}>
                    <Container
                        as={motion.div}
                        initial={{ y: 380, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 380, opacity: 0 }}
                        transition={{ duration: 0.3, ease: 'easeIn' }}
                        onClick={(e: SyntheticEvent) => {
                            e.stopPropagation();
                        }}
                    >
                        {children}
                    </Container>
                </FullWindowDiv>
            )}
        </AnimatePresence>
    );
};

export default Drawer;
