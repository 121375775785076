import { ReactElement } from 'react';
import { Route, Switch } from 'react-router';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { Login } from './Login';
import Logout from './Logout';

const ApiAuthorizationRoutes = (): ReactElement => {
    return (
        <Switch>
            <Route path={ApplicationPaths.Login} render={() => <Login action={'login'} />} />
            <Route path={ApplicationPaths.LoginCallback} render={() => <Login action={'login-callback'} />} />
            <Route path={ApplicationPaths.SilentCallback} render={() => <Login action={'silent-callback'} />} />
            <Route path={ApplicationPaths.LogOut} render={() => <Logout action={'logout'} />} />
            <Route path={ApplicationPaths.LogOutCallback} render={() => <Logout action={'logout-callback'} />} />
        </Switch>
    );
};

export default ApiAuthorizationRoutes;
