import { createContext, useReducer, PropsWithChildren, ReactElement, Dispatch } from 'react';
import { UploadedTicketAttachment, UserTicketType } from '../../../api/apiTypes/ticketingApiTypes';
import { NewTicketFormStep } from './UseNewTicketSteps';

type UpdateTicketTypes = { type: 'UPDATE_TYPES'; payload: UserTicketType[] };
type UpdateTicketType = { type: 'UPDATE_TICKET_TYPE'; payload: string };
type UpdateTicketCategory = { type: 'UPDATE_TICKET_CATEGORY'; payload: string };
type NextStepAction = { type: 'NEXT_STEP' };
type PreviousStepAction = { type: 'PREVIOUS_STEP' };
type SetStepAction = { type: 'SET_STEP'; payload: number };
type UpdateBuildingAction = { type: 'UPDATE_BUILDING'; payload: { id: string; label: string } | null };
type UpdateFloorAction = { type: 'UPDATE_FLOOR'; payload: { id: string; label: string } | null };
type UpdateTitleAction = { type: 'UPDATE_TITLE'; payload: string };
type UpdateDescriptionAction = { type: 'UPDATE_DESCRIPTION'; payload: string };
type UpdateImage = { type: 'UPDATE_ATTACHMENTS'; payload: UploadedTicketAttachment | null };
type UpdateImageIsLoading = { type: 'UPDATE_ATTACHMENT_IS_LOADING'; payload: boolean };
type UpdateFormIsValid = { type: 'UPDATE_FORM_IS_VALID'; payload: boolean };
type RemoveAttachment = { type: 'REMOVE_ATTACHMENTS'; payload: UploadedTicketAttachment[] };

type NewTicketAction =
    | NextStepAction
    | PreviousStepAction
    | SetStepAction
    | UpdateBuildingAction
    | UpdateFloorAction
    | UpdateTitleAction
    | UpdateDescriptionAction
    | UpdateTicketTypes
    | UpdateTicketType
    | UpdateTicketCategory
    | UpdateImage
    | UpdateImageIsLoading
    | UpdateFormIsValid
    | RemoveAttachment;
export interface NewTicketForm {
    title: string;
    description: string;
    attachments: UploadedTicketAttachment[];
    uploadedImages: boolean;
    building: { id: string; label: string } | null;
    floor: { id: string; label: string } | null;
    formIsValid: boolean;
}

export interface NewTicketState {
    ticketData: UserTicketType[];
    currentStep: NewTicketFormStep;
    ticketType: string;
    ticketCategory: string;
    ticketForm: NewTicketForm;
}

const reducer = (state: NewTicketState, action: NewTicketAction): NewTicketState => {
    switch (action.type) {
        case 'UPDATE_TYPES':
            return {
                ...state,
                ticketData: action.payload,
            };
        case 'UPDATE_TICKET_TYPE':
            return {
                ...state,
                ticketType: action.payload,
            };
        case 'UPDATE_TICKET_CATEGORY':
            return {
                ...state,
                ticketCategory: action.payload,
            };

        case 'NEXT_STEP':
            return {
                ...state,
                currentStep:
                    state.currentStep < NewTicketFormStep.Result ? (state.currentStep += 1) : state.currentStep,
            };
        case 'PREVIOUS_STEP':
            return {
                ...state,
                currentStep:
                    state.currentStep > NewTicketFormStep.SelectCategory ? (state.currentStep -= 1) : state.currentStep,
            };
        case 'SET_STEP':
            return {
                ...state,
                currentStep: action.payload,
            };

        case 'UPDATE_BUILDING':
            return {
                ...state,
                ticketForm: {
                    ...state.ticketForm,
                    building: action.payload,
                    floor: null,
                },
            };
        case 'UPDATE_FLOOR':
            return {
                ...state,
                ticketForm: {
                    ...state.ticketForm,
                    floor: action.payload,
                },
            };
        case 'UPDATE_TITLE':
            return {
                ...state,
                ticketForm: {
                    ...state.ticketForm,
                    title: action.payload,
                },
            };
        case 'UPDATE_DESCRIPTION':
            return {
                ...state,
                ticketForm: {
                    ...state.ticketForm,
                    description: action.payload,
                },
            };
        case 'UPDATE_ATTACHMENTS':
            return {
                ...state,
                ticketForm: {
                    ...state.ticketForm,
                    attachments: action.payload
                        ? [...state.ticketForm.attachments, action.payload]
                        : state.ticketForm.attachments,
                },
            };
        case 'REMOVE_ATTACHMENTS':
            return {
                ...state,
                ticketForm: {
                    ...state.ticketForm,
                    attachments: action.payload,
                },
            };
        case 'UPDATE_FORM_IS_VALID':
            return {
                ...state,
                ticketForm: {
                    ...state.ticketForm,
                    formIsValid: action.payload,
                },
            };
        case 'UPDATE_ATTACHMENT_IS_LOADING':
            return {
                ...state,
                ticketForm: {
                    ...state.ticketForm,
                    attachments: [...state.ticketForm.attachments],
                },
            };
    }
};

const initialState: NewTicketState = {
    ticketData: [],
    currentStep: 1,
    ticketType: '',
    ticketCategory: '',
    ticketForm: {
        building: null,
        floor: null,
        title: '',
        description: '',
        attachments: [],
        uploadedImages: false,
        formIsValid: false,
    },
};

type NewTicketContext = {
    state: NewTicketState;
    dispatch: Dispatch<NewTicketAction>;
};

export const NewTicketContext = createContext<NewTicketContext>({
    state: initialState,
    dispatch: () => {
        return;
    },
});

export const NewTicketProvider = ({ children }: PropsWithChildren<Record<string, unknown>>): ReactElement => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <NewTicketContext.Provider value={{ state, dispatch }}>{children}</NewTicketContext.Provider>;
};
