import { useLayoutEffect, useCallback, useState, RefObject, useEffect } from 'react';

export type RectResult = {
    bottom: number;
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
};

const useRect = (ref: RefObject<HTMLElement>, visible?: boolean): DOMRect | RectResult => {
    const [rect, setRect] = useState(getRect(ref ? ref.current : null));

    useEffect(() => {
        if (visible) setRect(getRect(ref ? ref.current : null));
    }, [visible, ref]);

    const handleResize = useCallback(() => {
        if (!ref.current) return;

        setRect(getRect(ref.current));
    }, [ref]);

    useLayoutEffect(() => {
        const element = ref.current;
        if (!element) return;

        handleResize();

        if (typeof ResizeObserver === 'function') {
            const resizeObserver = new ResizeObserver(() => handleResize());
            resizeObserver.observe(element);

            return () => {
                if (!resizeObserver) return;

                resizeObserver.disconnect();
            };
        } else {
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [ref, handleResize]);

    return rect;
};

const getRect = (element: HTMLElement | null) => {
    if (!element) {
        return {
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            width: 0,
        };
    }

    return element.getBoundingClientRect();
};

export default useRect;
