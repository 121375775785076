import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { MEDIA_BREAKPOINTS, MEDIA_INDEX } from '../constants';
import {
    BORDER_STYLES,
    FLEX_STYLES,
    INCLUDE_ARRAY_VALUE,
    MARGIN_PADDING_STYLES,
    OVERFLOW_STYLES,
    POSITION_STYLES,
    SIZE_STYLES,
} from '../stylesHelpers';
import { IMotionStyles } from './types';

const MotionBoxStyled = styled(motion.div)<IMotionStyles>`
    background: ${({ background }) => background ?? 'transparent'};
    opacity: ${({ opacity }) => opacity || 1};
    box-shadow: ${({ boxShadow }) => boxShadow ?? 'none'};
    ${(props) => SIZE_STYLES(props)};
    ${(props) => FLEX_STYLES(props)};
    ${(props) => MARGIN_PADDING_STYLES(props)};
    ${(props) => BORDER_STYLES(props)};
    ${(props) => OVERFLOW_STYLES(props)};
    ${(props) => POSITION_STYLES(props)};

    ${(props) =>
        INCLUDE_ARRAY_VALUE(props) &&
        css`
            @media (max-width: ${MEDIA_BREAKPOINTS.TABLET}px) {
                ${SIZE_STYLES(props, MEDIA_INDEX.TABLET)}
                ${FLEX_STYLES(props, MEDIA_INDEX.TABLET)}
                    ${MARGIN_PADDING_STYLES(props, MEDIA_INDEX.TABLET)}
                    ${BORDER_STYLES(props, MEDIA_INDEX.TABLET)}
                    ${OVERFLOW_STYLES(props, MEDIA_INDEX.TABLET)}
                    ${POSITION_STYLES(props, MEDIA_INDEX.TABLET)}
            }
            @media (max-width: ${MEDIA_BREAKPOINTS.MOBILE}px) {
                ${SIZE_STYLES(props, MEDIA_INDEX.MOBILE)}
                ${FLEX_STYLES(props, MEDIA_INDEX.MOBILE)}
                ${MARGIN_PADDING_STYLES(props, MEDIA_INDEX.MOBILE)}
                ${BORDER_STYLES(props, MEDIA_INDEX.MOBILE)}
                ${OVERFLOW_STYLES(props, MEDIA_INDEX.MOBILE)}
                ${POSITION_STYLES(props, MEDIA_INDEX.MOBILE)}
            }
        `}
`;

export default MotionBoxStyled;
