import { FC, useMemo } from 'react';
import { FlexBox, Typography } from '../../../../../../components/styleds';
import { OrderDetailsSectionItemProps } from './types';

const OrderSectionItem: FC<OrderDetailsSectionItemProps> = ({ label, value, isChip }) => {
    const valueIsStringOrNumber = typeof value === 'string' || typeof value === 'number';
    const valueRender = useMemo(
        () =>
            isChip ? (
                <FlexBox
                    width={'auto'}
                    align={'center'}
                    justify={'center'}
                    pd={'0.286rem 0.571rem'}
                    br={'2.143rem'}
                    background={'#E1F6FF'}
                >
                    <Typography
                        fontSize={'0.857rem'}
                        fontWeight={600}
                        lineHeight={'1.143rem'}
                        color={'var(--primary-color)'}
                        lineClamp={'1'}
                        width={'auto'}
                    >
                        {value}
                    </Typography>
                </FlexBox>
            ) : valueIsStringOrNumber ? (
                <Typography
                    color={'#475156'}
                    fontSize={'1rem'}
                    lineHeight={'1.643rem'}
                    textAlign={'right'}
                    fontWeight={400}
                    lineClamp={'1'}
                    width={'auto'}
                >
                    {value}
                </Typography>
            ) : (
                <FlexBox width={'auto'}>{value}</FlexBox>
            ),
        [value, isChip],
    );
    if (!value || !label) return null;
    return (
        <FlexBox align={'center'} justify={'space-between'} gap={'1rem'}>
            <Typography
                color={'#475156'}
                lineHeight={'1.571rem'}
                fontWeight={700}
                fontSize={'1rem'}
                letterSpacing={'0.2px'}
                lineClamp={'1'}
            >
                {label}
            </Typography>
            {valueRender}
        </FlexBox>
    );
};

export default OrderSectionItem;
