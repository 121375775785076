import { ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { TicketType } from '../../../../../api/apiTypes/ticketingApiTypes';
import { Perm } from '../../../../../common';
import { GhostPrimaryButton, InformationMessage, PrimaryButton, SvgIcon } from '../../../../../components';
import { useUserHasPerm } from '../../../../../utility';
import useResizeWindow from '../../../../../utility/hooks/useResizeWindow';
import { useGetListOfRestrictedSubmitters } from '../../../apiQueries/useTicketingSubmitters';
import useTicketingNavigation from '../../../useTicketingNavigation';
import useCanCreateType from '../../useCanCreateAndDeleteType';
import { TypeCard } from './components';
import RestrictSubmittersModal from './components/RestrictSubmittersModal/RestrictSubmittersModal';

const ScrollContainer = styled.div`
    overflow-y: auto;
    width: 100%;
`;

const Container = styled.div`
    padding: 2rem;
    max-width: 89.714rem;
    overflow-wrap: break-word;
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
`;

const Header = styled.h2`
    margin: 0;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 1.142rem;
`;

const NewTypeButton = styled(PrimaryButton)`
    & svg {
        margin-right: 0.57rem;
    }
`;

const RestrictSubmittersButton = styled(GhostPrimaryButton)`
    && {
        display: flex;
    }
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.714rem;

    svg {
        g {
            rect {
                fill: var(--primary-color);
            }
        }
    }

    :hover,
    :focus {
        svg {
            g {
                rect {
                    fill: #f7ffff;
                }
            }
        }
    }
`;

interface TicketOverviewProps {
    types: TicketType[];
}

const DESKTOP_BREAKPOINT = 450;
const TicketOverview = ({ types }: TicketOverviewProps): ReactElement => {
    const { t } = useTranslation('Ticketing');
    const { goToCreateNewType } = useTicketingNavigation();
    const canCreateType = useCanCreateType();
    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    const [restrictSubmittersModal, showRestrictSubmittersModal] = useState(false);
    const { data: submitters, isLoading, isError } = useGetListOfRestrictedSubmitters();
    const containerRef = useRef<HTMLDivElement>(null);

    const containerWidth = useResizeWindow(containerRef);
    const isTabletOrMobileView = containerWidth < DESKTOP_BREAKPOINT;
    return (
        <ScrollContainer>
            <Container>
                <FlexContainer ref={containerRef}>
                    <Header>{t('settings.overview')}</Header>
                    <ButtonsWrapper>
                        {userIsPortalAdmin ? (
                            <RestrictSubmittersButton
                                disabled={isLoading || isError}
                                onClick={() => showRestrictSubmittersModal(true)}
                            >
                                {isLoading ? <Loader active inline size="mini"></Loader> : <SvgIcon name="GearsIcon" />}
                                {`${!isTabletOrMobileView ? t('settings.restrictSubmittersButton') : ''} (${
                                    submitters?.length
                                        ? t('settings.restrictSubmittersActive')
                                        : t('settings.restrictSubmittersIncative')
                                })`}
                            </RestrictSubmittersButton>
                        ) : null}
                        {canCreateType ? (
                            <NewTypeButton onClick={goToCreateNewType}>
                                <SvgIcon name="Plus" />
                                {t('settings.newType')}
                            </NewTypeButton>
                        ) : null}
                    </ButtonsWrapper>
                </FlexContainer>
                {types.length == 0 ? (
                    <InformationMessage type="information">{t('settings.noTypesCreatedOne')}</InformationMessage>
                ) : (
                    types?.map((type) => <TypeCard key={type.id} type={type} />)
                )}
                {restrictSubmittersModal ? (
                    <RestrictSubmittersModal
                        choosenRoles={submitters || []}
                        open={restrictSubmittersModal}
                        onClose={() => showRestrictSubmittersModal(false)}
                    />
                ) : null}
            </Container>
        </ScrollContainer>
    );
};
export default TicketOverview;
