import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { DropdownProps } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { Loader } from '../../../../../../../common';
import {
    ErrorDisplay,
    GhostPrimaryButton,
    Modal,
    ModalType,
    PrimaryButton,
    SuccessDisplay,
} from '../../../../../../../components';
import {
    useGetAllPortalRoles,
    useUpdateListOfRestrictedSubmitters,
} from '../../../../../apiQueries/useTicketingSubmitters';

const ContentContainer = styled.div`
    padding: 1.428rem;
    padding-bottom: 20rem;
`;

const ButtonsContainer = styled.div`
    display: flex;
    gap: 1.142rem;
    justify-content: flex-end;
`;
const StyledDropdown = styled(Dropdown)`
    width: 100%;
`;
const StatusContainer = styled.div`
    text-align: center;
    padding: 3.571rem;
`;

const LoaderContainer = styled.div`
    padding: 10.285rem;
`;

const ModalTitle = styled.h3``;
const ModalText = styled.p``;

type RestrictSubmittersModalProps = {
    open: boolean;
    choosenRoles: string[];
    onClose(): void;
};
const RestrictSubmittersModal = ({ open, choosenRoles, onClose }: RestrictSubmittersModalProps): ReactElement => {
    const [roles, setRoles] = useState<string[]>([]);

    const { data: allRoles, isLoading: isRolesLoading } = useGetAllPortalRoles();

    const memoizedSubmitters = useMemo(() => JSON.stringify(choosenRoles), [choosenRoles]);

    const [updateSubmitters, { isLoading: isSavingSubmittersLoading, isError, isSuccess, reset }] =
        useUpdateListOfRestrictedSubmitters();

    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });

    useEffect(() => {
        choosenRoles && setRoles(choosenRoles);
    }, [choosenRoles]);

    const memoizedRoles = useMemo(() => {
        return allRoles?.map((item) => ({ text: item.phrase || item.name, value: item.name }));
    }, [allRoles]);

    const getModalContent = (): ReactElement => {
        if (isSuccess) {
            return (
                <StatusContainer>
                    <SuccessDisplay header={tCommon('changesSaved')} alt="success" />
                </StatusContainer>
            );
        } else if (isError) {
            return (
                <StatusContainer>
                    <ErrorDisplay header={tCommon('somethingWentWrong')} />
                </StatusContainer>
            );
        }

        return (
            <ContentContainer>
                <ModalTitle>{t('restrictSubmittersTitle')}</ModalTitle>
                <ModalText>{t('restrictSubmittersDescription')}</ModalText>

                <StyledDropdown
                    value={roles}
                    options={memoizedRoles}
                    multiple
                    selection
                    onChange={(e: React.SyntheticEvent, { value }: DropdownProps) => setRoles(value as string[])}
                />
            </ContentContainer>
        );
    };

    const getBottomContent = (): ReactElement => {
        if (isSuccess) {
            return (
                <ButtonsContainer>
                    <GhostPrimaryButton onClick={onClose}>{tCommon('closeButton')}</GhostPrimaryButton>
                </ButtonsContainer>
            );
        } else if (isError) {
            return (
                <ButtonsContainer>
                    <GhostPrimaryButton onClick={onClose}>{tCommon('closeButton')}</GhostPrimaryButton>
                    <PrimaryButton onClick={() => reset()}>{tCommon('tryAgain')}</PrimaryButton>
                </ButtonsContainer>
            );
        }
        return (
            <ButtonsContainer>
                <GhostPrimaryButton onClick={onClose}>{tCommon('cancelButton')}</GhostPrimaryButton>
                <PrimaryButton
                    onClick={() => updateSubmitters(roles)}
                    disabled={memoizedSubmitters === JSON.stringify(roles)}
                >
                    {tCommon('saveButton')}
                </PrimaryButton>
            </ButtonsContainer>
        );
    };

    return (
        <Modal
            type={ModalType.Small}
            title={t('restrictSubmittersModalHeader')}
            open={open}
            onClose={onClose}
            bottomContent={getBottomContent()}
        >
            {isRolesLoading || isSavingSubmittersLoading ? (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            ) : (
                getModalContent()
            )}
        </Modal>
    );
};

export default RestrictSubmittersModal;
