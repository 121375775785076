import React, { FC } from 'react';
import FlexBoxStyled from './flexBox.styled';
import { IBoxProps } from './types';

const FlexBox: FC<IBoxProps> = ({ children, forwardedRef, ...props }) => {
    return (
        //Ts error with passing array value
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <FlexBoxStyled ref={forwardedRef} {...props}>
            {children}
        </FlexBoxStyled>
    );
};

export default FlexBox;
