import { Key, ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import type { CSSProperties } from 'styled-components';
import {
    CompanyOrder,
    OrderSortDirection,
    OrderSortingType,
    PortalOrder,
    UserOrder,
} from '../../../../../api/apiTypes/shopApiTypes';
import { Badge } from '../../../../../components';
import { Table, OrderEnum } from '../../../../../components/table';
import { getFormattedCurrency } from '../../../../../utility';
import { getDeviceLanguageDateFormat } from '../../../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import { getOrderStatusBadgeType } from '../../../../../utility/getBadgeType';
import { ShopOverviewView, ShopAtWorkContext } from '../../../ShopAtWork';
import useShopAtWorkNavigation from '../../../useShopAtWorkNavigation';
import {
    getShopArWorkFormOfPaymentTranslation,
    getShopAtWorkStatusTranslation,
    getViewColumns,
    PurchasesColumns,
} from '../utils';
import PurchasesSkeletonLoader from './PurchasesSkeletonLoader';

const TableContainer = styled.div``;

const StyledBadge = styled(Badge)`
    height: 1.714rem;
`;

type TableProps = { collapse?: boolean };
const StyledTable = styled(Table)`
    tr {
        ${({ collapse }: TableProps) =>
            collapse && {
                display: 'grid',
                gridTemplateAreas: `'${PurchasesColumns.OrderNumber} ${PurchasesColumns.Status}'
                '${PurchasesColumns.Created} ${PurchasesColumns.Status}'
                '${PurchasesColumns.Items} ${PurchasesColumns.Total}'
                '${PurchasesColumns.Payment} ${PurchasesColumns.Payment}'
                '${PurchasesColumns.Employee} ${PurchasesColumns.Employee}'
                '${PurchasesColumns.Company} ${PurchasesColumns.Company}'`,
                gridTemplateColumns: 'auto 8rem',
                gridAutoRows: 'auto 3rem 7.5rem auto auto',
            }}
    }
    td {
        ${({ collapse }: TableProps) =>
            collapse && {
                padding: '0.4rem',
                marginBottom: 0,
                ':before': {
                    content: 'none',
                },
            }};
    }
`;

const TableCell = styled.p`
    font-size: 1.143rem;
    margin: 0;
`;

const TableHeaderLabel = styled.p``;

const Status = styled(TableCell)`
    display: flex;
    justify-content: flex-start;
    min-width: max-content;
`;

type OrderNumberProps = { collapse?: boolean };
const OrderNumber = styled(TableCell)`
    ${({ collapse }: OrderNumberProps) =>
        collapse && {
            fontWeight: 900,
        }}
`;

type CreatedProps = { collapse?: boolean };
const Created = styled(TableCell)`
    ${({ collapse }: CreatedProps) =>
        collapse && {
            fontSize: '0.857rem',
        }}
`;

const CreatedSpanText = styled.span`
    color: #26323899;
    font-size: 0.857rem;
    font-weight: 600;
`;

const Payment = styled(TableCell)``;
const Employee = styled(TableCell)``;
const Company = styled(TableCell)``;
const Items = styled(TableCell)``;

const ItemsList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const ItemsItem = styled.li``;

type TotalProps = { collapse?: boolean };
const Total = styled(TableCell)`
    ${({ collapse }: TotalProps) =>
        collapse && {
            fontSize: '1.429rem',
            lineHeight: '2.286rem',
            fontWeight: 400,
        }}
`;

type Order = UserOrder & CompanyOrder & PortalOrder;

export type ColumnType = {
    name: string;
    path?: OrderSortingType;
    key: Key;
    label: string | ReactElement;
    width: string;
    content?(order: Order): JSX.Element | null;
    headerOnly?: boolean;
    cellColSpan?: number;
    style?: CSSProperties;
}[];

interface PurchasesTableProps {
    orders: Order[];
    onSort(sortColumn: { path: OrderSortingType; order: OrderSortDirection }): void;
    sortColumn: { path: OrderSortingType; order: OrderSortDirection };
    includeColumns?: string[];
    view: ShopOverviewView;
    isLoading: boolean;
    collapse?: boolean;
}

const PurchasesTable = ({
    orders,
    onSort,
    sortColumn,
    view,
    isLoading,
    collapse,
}: PurchasesTableProps): ReactElement => {
    const mappedOrders = useMemo(() => orders.map((order) => ({ ...order, id: order.id })), [orders]);
    const { goToOrderLink } = useShopAtWorkNavigation();
    const { dispatch } = useContext(ShopAtWorkContext);
    const { t } = useTranslation('ShopAtWork');

    const onRowClickHandler = (itemId: string) => {
        dispatch({ type: 'UPDATE_FROM_ORDER_ID', payload: { orderId: itemId, previousView: view } });
        goToOrderLink(view, itemId);
    };

    const onSortHandler = (sortColumn: { path: OrderSortingType; order: OrderEnum }) => {
        const modifiedOrder = sortColumn.order === OrderEnum.asc ? OrderSortDirection.Asc : OrderSortDirection.Desc;
        onSort({ path: sortColumn.path, order: modifiedOrder });
    };

    const modifiedSortColumn = useMemo(() => {
        const modifiedOrder = sortColumn.order === OrderSortDirection.Asc ? 'asc' : 'desc';
        return { path: sortColumn.path, order: modifiedOrder };
    }, [sortColumn]);

    const allColumns = useMemo((): ColumnType => {
        const allColumns: ColumnType = [
            {
                name: 'status',
                path: OrderSortingType.Status,
                key: PurchasesColumns.Status,
                label: <TableHeaderLabel>{t('portalPurchases.tableLabelStatus')}</TableHeaderLabel>,
                width: '15%',
                style: collapse
                    ? {
                          gridArea: PurchasesColumns.Status,
                          alignSelf: 'flex-start',
                          display: 'flex',
                          justifyContent: 'flex-end',
                      }
                    : undefined,
                content: function renderContent(order) {
                    return (
                        <Status>
                            <StyledBadge type={getOrderStatusBadgeType(order.status)}>
                                {getShopAtWorkStatusTranslation(order.status)}
                            </StyledBadge>
                        </Status>
                    );
                },
            },
            {
                name: 'orderNumber',
                path: OrderSortingType.OrderNumber,
                key: PurchasesColumns.OrderNumber,
                label: <TableHeaderLabel>{t('portalPurchases.tableLabelOrderNumber')}</TableHeaderLabel>,
                width: '10%',
                style: collapse
                    ? {
                          gridArea: PurchasesColumns.OrderNumber,
                          paddingBottom: 0,
                      }
                    : undefined,
                content: function renderContent(order) {
                    return <OrderNumber collapse={collapse}>{order.orderNumber}</OrderNumber>;
                },
            },
            {
                name: 'created date',
                path: OrderSortingType.Date,
                key: PurchasesColumns.Created,
                label: t('portalPurchases.tableLabelCreated'),
                width: '15%',
                style: collapse
                    ? {
                          gridArea: PurchasesColumns.Created,
                          paddingTop: 0,
                      }
                    : undefined,
                content: function renderContent(order) {
                    return (
                        <Created collapse={collapse}>
                            {collapse ? (
                                <CreatedSpanText>{t('portalPurchases.tableLabelCreated')}: </CreatedSpanText>
                            ) : null}
                            {getDeviceLanguageDateFormat(new Date(order.createdDate))}
                        </Created>
                    );
                },
            },
            {
                name: 'payment',
                path: OrderSortingType.Payment,
                key: PurchasesColumns.Payment,
                label: t('portalPurchases.tableLabelPayment'),
                width: '20%',
                style: { gridArea: PurchasesColumns.Payment },
                content: function renderContent(order) {
                    return <Payment>{getShopArWorkFormOfPaymentTranslation(order.paymentType)}</Payment>;
                },
            },
            {
                name: 'employee',
                path: OrderSortingType.Employee,
                key: PurchasesColumns.Employee,
                label: t('portalPurchases.tableLabelEmployee'),
                width: '20%',
                style: { gridArea: PurchasesColumns.Employee },
                content: function renderContent(order) {
                    return <Employee>{`${order.employee?.firstName} ${order.employee?.lastName}`}</Employee>;
                },
            },
            {
                name: 'company',
                path: OrderSortingType.Company,
                key: PurchasesColumns.Company,
                label: t('portalPurchases.companies'),
                width: '20%',
                style: { gridArea: PurchasesColumns.Company },
                content: function renderContent(order) {
                    return <Company>{order.company?.name}</Company>;
                },
            },
            {
                name: 'items',
                path: OrderSortingType.Items,
                key: PurchasesColumns.Items,
                label: t('portalPurchases.tableLabelItems'),
                width: '30%',
                style: { gridArea: PurchasesColumns.Items },
                content: function renderContent(order) {
                    const countOfDisplayedAmounts = 3;
                    return (
                        <Items>
                            <ItemsList>
                                {order.orderLines.slice(0, countOfDisplayedAmounts).map((order) => {
                                    return (
                                        <ItemsItem key={order.id}>
                                            {`${order.title} ${order.amount} ${t(
                                                'commonShopAtWork.itemsColumnAmountText',
                                            )}`}
                                        </ItemsItem>
                                    );
                                })}
                            </ItemsList>
                            {order.orderLines.length > countOfDisplayedAmounts && (
                                <p>{`+${order.orderLines.length - countOfDisplayedAmounts} ${t(
                                    'commonShopAtWork.itemsColumnAdditionalText',
                                )}`}</p>
                            )}
                        </Items>
                    );
                },
            },
            {
                name: 'total',
                path: OrderSortingType.Total,
                key: PurchasesColumns.Total,
                label: t('portalPurchases.tableLabelTotal'),
                width: '10%',
                style: collapse
                    ? {
                          gridArea: PurchasesColumns.Total,
                          alignSelf: 'center',
                          display: 'flex',
                          justifyContent: 'flex-end',
                      }
                    : undefined,
                content: function renderContent(order: Order) {
                    return <Total collapse={collapse}>{getFormattedCurrency(order.total)}</Total>;
                },
            },
        ];

        return getViewColumns(view, allColumns);
    }, [view, collapse]);

    return (
        <TableContainer>
            <StyledTable
                isLoading={isLoading}
                columns={allColumns}
                sortColumn={modifiedSortColumn}
                onSort={onSortHandler}
                data={mappedOrders}
                onRowClick={onRowClickHandler}
                collapse={collapse}
                noResultMessage={t('portalPurchases.noResultsMessage')}
            />
            {isLoading && (
                <PurchasesSkeletonLoader numberOfItems={20} show={isLoading} collapse={collapse} columns={allColumns} />
            )}
        </TableContainer>
    );
};

export default PurchasesTable;
