import React from 'react';
import PropTypes from 'prop-types';
import { Sidebar, Segment, Responsive } from 'semantic-ui-react';
import { MobileViewBreakpoint } from '../../common/ScreenSizeBreakPoints';

class SideSheet extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        const { visible, direction, children } = this.props;

        return (
            <React.Fragment>
                <Responsive minWidth={MobileViewBreakpoint} fireOnMount>
                    <Sidebar
                        as={Segment}
                        animation="overlay"
                        direction={direction}
                        visible={visible}
                        width={direction === 'right' ? 'very wide' : ''}
                        className="sidebar-wrapper"
                    >
                        {children && this.renderChildren()}
                    </Sidebar>
                </Responsive>

                <Responsive maxWidth={Responsive.onlyMobile.maxWidth} fireOnMount>
                    <Sidebar
                        as={Segment}
                        animation="overlay"
                        direction={direction}
                        visible={visible}
                        className="sidebar-wrapper mobile"
                    >
                        {children && this.renderChildren()}
                    </Sidebar>
                </Responsive>
            </React.Fragment>
        );
    };

    renderChildren = () => {
        const { children } = this.props;
        return children;
    };
}

SideSheet.propTypes = {
    children: PropTypes.element.isRequired,
    direction: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
};

export default SideSheet;
