import { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Invoice from '../components/Invoice/Invoice';
import Purchase from '../components/Purchase/Purchase';
import { ShopOverviewView } from '../ShopAtWork';
import useShopAtWorkNavigation from '../useShopAtWorkNavigation';

interface PurchaseInformationProps {
    view: ShopOverviewView;
}

const PurchaseInformation = ({ view }: PurchaseInformationProps): ReactElement => {
    const { getOrderLink } = useShopAtWorkNavigation();
    const purchaseInfoDefaultRoute = `/:portalName${getOrderLink(view, ':orderId')}`;
    const match = useRouteMatch<{ orderId: string }>(purchaseInfoDefaultRoute);
    const orderId = match?.params.orderId ?? '';

    return (
        <Switch>
            <Route exact path={purchaseInfoDefaultRoute}>
                <Purchase orderId={orderId} view={view} />
            </Route>
            <Route path={`${purchaseInfoDefaultRoute}/invoice`}>
                <Invoice orderId={orderId} view={view} />
            </Route>
        </Switch>
    );
};

export default PurchaseInformation;
