import { ReactElement } from 'react';
import { Icon, Responsive } from 'semantic-ui-react';
import CustomErrorBoundary from '../Error/CustomErrorBoundary';
import { MobileViewBreakpoint } from '../ScreenSizeBreakPoints';
import { NotificationsPopUp, NotificationsFullPage } from './components';
import { NotificationContextProvider } from './context';

const Notifications = (): ReactElement => {
    return (
        <NotificationContextProvider>
            <Responsive minWidth={MobileViewBreakpoint}>
                <CustomErrorBoundary
                    errorComponent={({ reset }) => (
                        <Icon
                            onClick={reset}
                            name="close"
                            inverted
                            circular
                            size="small"
                            color="red"
                            aria-label="Error in Notifications"
                            style={{ marginRight: 'calc(var(--spacer) * 5)' }}
                        />
                    )}
                >
                    <NotificationsPopUp />
                </CustomErrorBoundary>
            </Responsive>
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                <CustomErrorBoundary
                    errorComponent={({ reset }) => (
                        <Icon
                            onClick={reset}
                            name="close"
                            inverted
                            circular
                            size="small"
                            color="red"
                            aria-label="Error in Notifications"
                            style={{ marginRight: 'calc(var(--spacer) * 2)' }}
                        />
                    )}
                >
                    <NotificationsFullPage />
                </CustomErrorBoundary>
            </Responsive>
        </NotificationContextProvider>
    );
};

export default Notifications;
