export enum Perm {
    MeetingSetOrganizer = 'meeting-set-organizer',
    MeetingBookingAllEdit = 'meeting-booking-all-edit',
    MeetingRoomsUpdate = 'meeting-rooms-update',
    FoodOrdersOwnCompanyCreate = 'foodorders-owncompany-create',
    ArticlesUpdate = 'articles-update',
    todaysMenuImageUpdate = 'todays-menu-image-update',
    todaysMenuUpdate = 'todays-menu-update',
    TicketingTypesUpdate = 'ticketing-types-update',
    PortalAdmin = 'portal-administrator',
    CompanyAdmin = 'company-administrator',
    SystemAdmin = 'Systemadmin',
    User = 'User',
    FoodRequirent = 'food-requirent',
    MeetingPortalSettingsWrite = 'meeting-portalsettings-write',
    FoodOrdersOwnCompanyRead = 'foodorders-owncompany-read',
    IntegrationSettingUpdate = 'integration-setting-update',
}

export default Perm;
