import { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Ticket, UserTickets } from '../components';
import { TicketListView } from '../components/UserTickets/UserTickets';
import useTicketingNavigation from '../useTicketingNavigation';

const CompanyTickets = (): ReactElement => {
    const { getTicketLink } = useTicketingNavigation();

    return (
        <Switch>
            <Route path={`/:portalName${getTicketLink(TicketListView.CompanyTickets, ':ticketId', ':tab')}`}>
                <Ticket view={TicketListView.CompanyTickets} />
            </Route>
            <Route>
                <UserTickets view={TicketListView.CompanyTickets} />
            </Route>
        </Switch>
    );
};

export default CompanyTickets;
