import { PropsWithChildren, ReactElement, ReactNode, useEffect, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { ConditionalWrapper } from '../../../common';
import { MobileViewBreakpoint } from '../../../common/ScreenSizeBreakPoints';
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import { useModalContext } from './ModalProvider';

export enum ModalType {
    'Small',
    'Medium',
    'Large',
    'Sidesheet',
    'Image',
}

type FullWindowDivProps = { open: boolean };
const FullWindowDiv = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(71, 81, 86, 0.8);
    z-index: 1002;
    display: ${({ open }: FullWindowDivProps) => (open ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
`;

const NonSideSheetCss = css`
    max-height: 95%;
    height: auto;
    top: auto;
    right: auto;
    border-radius: 0.7rem;
    transform: none;
    max-width: 95%;
`;

const SmallModalCss = css`
    width: 28.571rem;
`;

const MediumModalCss = css`
    width: 42.85rem;
`;

const LargeModalCss = css`
    width: 57rem;
`;

const ImageModalCss = css`
    &&&& {
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    img,
    image {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
`;

const SideSheetCss = css`
    top: 0;
    right: -28.571rem;
    width: 28.571rem;
    height: 100%;
    border-radius: 0;
    transform: ${({ open }: ModalContainerProps) => (open ? 'translateX(-28.571rem)' : 'translateX(28.571rem)')};
    transition: 0.1s ease-out all;
`;

type ModalContainerProps = { error: boolean; type: ModalType; open: boolean };
const ModalContainer = styled.div`
    position: fixed;
    z-index: 1;
    overflow: visible;
    background-color: var(--surface-color-light);
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.15));
    display: flex;
    flex-direction: column;

    ${({ type }: ModalContainerProps) => (type !== ModalType.Sidesheet ? NonSideSheetCss : undefined)}
    ${({ type }: ModalContainerProps) => {
        switch (type) {
            case ModalType.Small:
                return SmallModalCss;
            case ModalType.Medium:
                return MediumModalCss;
            case ModalType.Large:
                return LargeModalCss;
            case ModalType.Sidesheet:
                return SideSheetCss;
            case ModalType.Image:
                return ImageModalCss;
        }
    }}
        /* mobile in landscape  */ @media (max-height: ${MobileViewBreakpoint ?? 768}px) {
        max-height: 100%;
    }

    @media (max-width: ${MobileViewBreakpoint ?? 768}px) {
        max-width: 90%;
        max-height: 95%;
    }
`;

type TopBarContainerProps = { error: boolean; bordered?: boolean };
const TopBarContainer = styled.div`
    padding: 1.9rem 1.14rem 1.9rem 1.7rem;
    display: flex;
    align-items: center;
    border-radius: 0.7rem 0.7rem 0 0;
    ${({ error }: TopBarContainerProps) =>
        error ? 'background-color:  var(--error-color)' : 'background-color: #fff'};
    ${({ bordered }: TopBarContainerProps) =>
        bordered &&
        css`
            border-bottom: 1px solid #d4d6d7;
        `};
    /* mobile in landscape  */
    @media (max-height: ${MobileViewBreakpoint}px) {
        padding-top: 1.7rem;
        padding-bottom: 1.7rem;
    }
`;

type ModalHeaderProps = { error: boolean };
const ModalHeader = styled.h1`
    font-weight: 900;
    font-size: 1.42rem;
    line-height: 32px;
    color: var(--text-high-emphasis-color);
    margin: 0;
    width: 100%;
    overflow-wrap: break-word;
    ${({ error }: ModalHeaderProps) => (error ? 'color:  #fff' : 'color: var(--text-high-emphasis-color)')};
`;

const CloseButton = styled.button`
    margin-left: auto;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    & path {
        fill: var(--text-high-emphasis-color);
    }
`;

const ImageCloseButton = styled.button`
    position: absolute;
    z-index: 4;
    top: 1rem;
    right: 1rem;
    border: none;
    background: none;
    cursor: pointer;

    svg {
        width: 2.143rem;
        height: 2.143rem;

        path {
            fill: var(--text-high-emphasis-color);
        }
    }

    :focus,
    :focus-visible {
        outline: 1px solid var(--primary-color);
    }
    :focus:not(:focus-visible) {
        outline: 0;
    }
`;

type ContentContainerProps = { type: ModalType };
const ContentContainer = styled.div`
    flex-grow: 1;
    z-index: 3;
    overflow: ${({ type }: ContentContainerProps) => (type === ModalType.Image ? 'hidden' : 'auto')};
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
`;

type BottomBarContainerProp = { bordered?: boolean; zIndex?: number };
const BottomBarContainer = styled.div`
    padding: 1.7rem 1.7rem;

    ${({ bordered }: BottomBarContainerProp) =>
        bordered &&
        css`
            border-top: 1px solid #d4d6d7;
        `};

    @media (max-height: ${MobileViewBreakpoint}px) {
        padding: 1rem 1.7rem;
    }
    z-index: ${({ zIndex }: BottomBarContainerProp) => zIndex ?? 2};
`;

const ForbiddenIcon = styled(Icon)`
    color: #fff;
    transform: rotate(45deg);

    && {
        margin: 0 0.571rem;
    }
`;

interface ModalProps {
    title?: string;
    type: ModalType;
    error?: boolean;
    onClose(): void;
    bottomContent?: ReactNode;
    hideCloseButton?: boolean;
    open: boolean;
    className?: string;
    bordered?: boolean;
    bottomBarZindex?: number;
}
const Modal = ({
    title,
    onClose,
    children,
    bottomContent,
    error = false,
    hideCloseButton = false,
    type,
    open = false,
    className,
    bordered,
    bottomBarZindex,
}: PropsWithChildren<ModalProps>): ReactElement | null => {
    const containerRef = useRef<HTMLDivElement>(null);
    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') onClose();
        },
        [onClose],
    );

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, [onKeyDown]);

    useEffect(() => {
        if (open && !containerRef.current?.contains(document.activeElement)) {
            containerRef.current?.focus();
        }
    }, [open]);

    // Block all clicks that are not on the modal
    // UPD: commented because this feature brokes datepickers inside of the modals
    // const handleDimmerClick = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

    const mountNode = useModalContext();
    return createPortal(
        <ConditionalWrapper
            condition={type !== ModalType.Sidesheet}
            wrapper={(children) => (
                <FullWindowDiv tabIndex={0} ref={containerRef} open={open} id="fullWindowDiv">
                    {children}
                </FullWindowDiv>
            )}
        >
            <ModalContainer
                error={error}
                aria-modal="true"
                role="dialog"
                type={type}
                open={open}
                aria-hidden={!open}
                className={className}
            >
                {type === ModalType.Image ? (
                    <ImageCloseButton onClick={onClose}>
                        <CloseIcon />
                    </ImageCloseButton>
                ) : (
                    <TopBarContainer error={error} bordered={bordered}>
                        {error && <ForbiddenIcon name="minus circle" size="big" />}
                        {title && (
                            <ModalHeader error={error} id="modalTitle">
                                {title}
                            </ModalHeader>
                        )}
                        {!hideCloseButton && (
                            <CloseButton>
                                <CloseIcon onClick={onClose} />
                            </CloseButton>
                        )}
                    </TopBarContainer>
                )}
                <ContentContainer id="modalContentContainer" type={type}>
                    {children}
                </ContentContainer>
                {bottomContent && (
                    <BottomBarContainer zIndex={bottomBarZindex} bordered={bordered}>
                        {bottomContent}
                    </BottomBarContainer>
                )}
            </ModalContainer>
        </ConditionalWrapper>,
        mountNode,
    );
};

export default Modal;
