import { AxiosResponse, AxiosError } from 'axios';
import { type MutationResultPair, useMutation } from 'react-query';
import { setDefaultPortal, setSelectedPortal } from '../../api/portalApi';

export const useSetDefaultPortal = (): MutationResultPair<
    AxiosResponse<void>,
    string | AxiosError<string> | Error,
    number,
    never
> => {
    return useMutation((portalId) => {
        return setDefaultPortal(portalId);
    });
};

export const useSetSelectedPortal = (): MutationResultPair<
    AxiosResponse<void>,
    string | AxiosError<string> | Error,
    number,
    never
> => {
    return useMutation((portalId) => {
        return setSelectedPortal(portalId);
    });
};
