const routeConstants: {
    adminTenants: string;
    adminUsers: string;
    adminKitchen: string;
    articles: string;
    editFoodMenus: string;
    food: string;
    instillinger: string;
    meeting: string;
    ticketing: string;
    shopAtWork: string;
    wardrobe: string;
    vendorSettings: string;
    events: string;
} = {
    adminTenants: '/admin/tenants',
    adminUsers: '/admin/users',
    adminKitchen: '/admin/vendors',
    articles: '/dashboard/articles',
    editFoodMenus: '/dashboard/dailyMenu',
    food: '/food',
    instillinger: '/instillinger',
    meeting: '/meeting',
    ticketing: '/ticketing',
    shopAtWork: '/shopAtWork',
    wardrobe: '/wardrobe',
    vendorSettings: '/admin/vendors',
    events: '/events',
};

export default routeConstants;
