import { ReactElement, useEffect, useMemo, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Switch, Route, useHistory, useLocation, useRouteMatch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Category, TicketType } from '../../../../../api/apiTypes/ticketingApiTypes';
import RoutePromptModal from '../../../../../common/RoutePromptModal';
import { useIsMobile } from '../../../../../utility/hooks/useIsMobile';
import { useUpdateTicketingTypeCategory } from '../../../apiQueries/useTicketingTypeCategory';
import useTicketingNavigation, { CategoryTabPath } from '../../../useTicketingNavigation';
import TitleBar from '../TitleBar';
import DescriptionTab from './DescriptionTab/DescriptionTab';
import GeneralTab from './GeneralTab/GeneralTab';
import NotificationTab from './NotificationTab/NotificationTab';
import ResponsibleCaseworkerTab from './ResponsibleCaseworkerTab/ResponsibleCaseworkerTab';

const Information = styled.p`
    font-size: 1.143rem;
    line-height: 1.714rem;
`;

type CardProps = { isMobile: boolean };
const Card = styled.div`
    border-radius: ${({ isMobile }: CardProps) => (isMobile ? 'none' : '0.71rem')};
    margin: ${({ isMobile }: CardProps) => (isMobile ? '0' : '2rem')};
    padding: 1.714rem 0;
    box-shadow: ${({ isMobile }: CardProps) => (isMobile ? 'none' : '0px 4px 10px rgba(0, 0, 0, 0.15)')};
    overflow-y: auto;
    background: var(--surface-color-light);
    width: ${({ isMobile }: CardProps) => !isMobile && '85.714rem'};

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

type TabsWrapperProps = { isMobile: boolean };
const TabsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ isMobile }: TabsWrapperProps) => (isMobile ? '2.179rem 0' : '2.179rem 1.714rem;')};
`;

const TabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: 0;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    ::-webkit-scrollbar {
        display: none; /* chrome and safari */
    }
`;

const HorizontalRule = styled.hr`
    border: 1px solid #d4d6d7;
    margin: 0;
`;

type StyledTabProps = { disabled: boolean };
const StyledTab = styled.button`
    font-weight: ${({ disabled }: StyledTabProps) => (disabled ? 'bold;' : 'normal;')};
    font-size: 1rem;
    color: ${({ disabled }: StyledTabProps) => (disabled ? '#263238;' : '#677074;')};
    && {
        border-bottom: ${({ disabled }: StyledTabProps) =>
            disabled
                ? `
             2px solid blue ;
           `
                : 'gray;'};
    }

    padding: 1.143rem;
    border: none;
    margin: 0 0.1rem;
    background-color: var(--surface-color-light);
    cursor: pointer;

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

const Content = styled.div`
    padding: 0 1.714rem;
`;

interface CategoryViewProps {
    types: TicketType[];
}

const CategoryView = ({ types }: CategoryViewProps): ReactElement | null => {
    const history = useHistory();
    const currentLocation = useLocation();
    const isMobile = useIsMobile();
    const { getCategoryTabLink, goToCategoryTab } = useTicketingNavigation();
    const { typeId } = useParams<{ typeId: string }>();
    const { categoryId } = useParams<{ categoryId: string }>();
    const [changesSaved, setChangesSaved] = useState(true);
    const match = useRouteMatch<{ typeId: string; categoryId: string; tab: CategoryTabPath }>(
        `/:portalName${getCategoryTabLink(':typeId', ':categoryId', ':tab' as CategoryTabPath)}`,
    );
    const tabMatch = match?.params.tab ?? 'general';
    const [toggleState, setToggleState] = useState<CategoryTabPath>(tabMatch);
    const [goToTab, setGoToTab] = useState<CategoryTabPath>(tabMatch);
    const [activeTab, setActiveTab] = useState(document.getElementById('#categoryGeneral'));
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing');
    const selectedType = useMemo(() => {
        return types.find(({ id }) => id === typeId);
    }, [typeId, types]);

    const selectedCategory = useMemo(() => {
        return selectedType && selectedType.categories.find((categories: Category) => categories.id === categoryId);
    }, [categoryId, selectedType]);

    useEffect(() => {
        setToggleState(tabMatch);
    }, [tabMatch]);

    useEffect(() => {
        activeTab?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }, [activeTab]);

    const changesSavedHandler = (isSaved: boolean) => {
        setChangesSaved(isSaved);
    };

    const clickTabHandler = (event: MouseEvent<HTMLButtonElement>, tab: CategoryTabPath) => {
        setGoToTab(tab);
        setActiveTab(event.currentTarget);

        switch (tab) {
            case 'general':
                goToCategoryTab(typeId, categoryId, 'general');
                changesSaved && setToggleState('general');
                break;
            case 'description':
                goToCategoryTab(typeId, categoryId, 'description');
                changesSaved && setToggleState('description');
                break;
            case 'notifications':
                goToCategoryTab(typeId, categoryId, 'notifications');
                changesSaved && setToggleState('notifications');
                break;
            case 'responsibleCaseworker':
                goToCategoryTab(typeId, categoryId, 'responsibleCaseworker');
                changesSaved && setToggleState('responsibleCaseworker');
                break;
        }
    };

    const [updateCategory] = useUpdateTicketingTypeCategory();

    const handleOnClickPublish = (isPublished: boolean) => {
        updateCategory([typeId, categoryId, { isPublished }]);
    };

    return selectedType && selectedCategory ? (
        <>
            <RoutePromptModal
                when={!changesSaved}
                navigate={history.push}
                shouldBlockNavigation={(location) => {
                    if (location.pathname === currentLocation.pathname) return false;
                    else return !changesSaved;
                }}
                modalTitle={tCommon('warning')}
                modalContent={<Information>{t('commonTicketing.ticketDetailsModalContent')}</Information>}
                modalCancelButtonText={tCommon('backToEditing')}
                modalOkButtonText={tCommon('rejectChanges')}
                onOkButtonClick={() => {
                    setChangesSaved(true);
                    setToggleState(goToTab);
                }}
            />
            <Card isMobile={isMobile} key={typeId + categoryId}>
                <TitleBar
                    name={`${selectedType.name} - ${selectedCategory.name}`}
                    isPublished={selectedCategory.isPublished}
                    handleOnClickPublish={handleOnClickPublish}
                />
                <TabsWrapper isMobile={isMobile}>
                    <TabsContainer>
                        <StyledTab
                            disabled={toggleState === 'general'}
                            onClick={(e) => clickTabHandler(e, 'general')}
                            id="categoryGeneral"
                        >
                            {t('settings.typeViewTabGeneral')}
                        </StyledTab>
                        <StyledTab
                            disabled={toggleState === 'description'}
                            onClick={(e) => clickTabHandler(e, 'description')}
                            id="categoryDescription"
                        >
                            {t('commonTicketing.description')}
                        </StyledTab>
                        <StyledTab
                            disabled={toggleState === 'responsibleCaseworker'}
                            onClick={(e) => clickTabHandler(e, 'responsibleCaseworker')}
                            id="categoryResponsibleCaseworker"
                        >
                            {t('commonTicketing.selectCaseworkerInputSelectLabel')}
                        </StyledTab>
                        <StyledTab
                            disabled={toggleState === 'notifications'}
                            onClick={(e) => clickTabHandler(e, 'notifications')}
                            id="categoryNotifications"
                        >
                            {tCommon('alerts')}
                        </StyledTab>
                    </TabsContainer>
                    <HorizontalRule />
                </TabsWrapper>
                <Content>
                    <Switch>
                        <Route path={`/:portalName${getCategoryTabLink(':typeId', categoryId, 'general')}`}>
                            <GeneralTab
                                category={selectedCategory}
                                typeId={typeId}
                                key={selectedType.id + selectedCategory.id}
                                changesSaved={changesSavedHandler}
                                existingTicketTypeCategoryNames={selectedType.categories.map(
                                    (category: Category) => category.name,
                                )}
                                allTypes={types}
                            />
                        </Route>
                        <Route path={`/:portalName${getCategoryTabLink(selectedType.id, categoryId, 'description')}`}>
                            <DescriptionTab
                                category={selectedCategory}
                                key={selectedType.id + selectedCategory.id}
                                changesSaved={changesSavedHandler}
                                ticketTypeId={selectedType.id}
                            />
                        </Route>
                        <Route
                            path={`/:portalName${getCategoryTabLink(
                                selectedType.id,
                                categoryId,
                                'responsibleCaseworker',
                            )}`}
                        >
                            <ResponsibleCaseworkerTab
                                category={selectedCategory}
                                ticketType={selectedType}
                                key={selectedType.id + selectedCategory.id}
                            />
                        </Route>
                        <Route path={`/:portalName${getCategoryTabLink(selectedType.id, categoryId, 'notifications')}`}>
                            <NotificationTab
                                category={selectedCategory}
                                ticketType={selectedType}
                                key={selectedType.id + selectedCategory.id}
                            />
                        </Route>
                        <Redirect to={`/:portalName${getCategoryTabLink(':typeId', categoryId, 'general')}`} />
                    </Switch>
                </Content>
            </Card>
        </>
    ) : null;
};
export default CategoryView;
