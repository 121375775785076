import type { AxiosPromise, AxiosResponse } from 'axios';
import {
    AccessEntitiesType,
    ApproveBookings,
    Approver,
    Booking,
    BookingForManagingFoodOrder,
    Building,
    BuildingsWithAvailableRoomsParams,
    CreateMeetingRoomBooking,
    MyBooking,
    SearchOrganizersModel,
    UpdateMeetingRoomBooking,
    UpdateRoom,
} from './apiTypes/bookingApiTypes';
import http from './infrastructure/httpService';
import { getBaseUrlApim, getPortalId } from './utils';

const urlBooking = process.env.REACT_APP_APIM_BOOKING_URL;

export const createMeetingRoomBooking = (booking: CreateMeetingRoomBooking): Promise<AxiosResponse<string>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/bookings/meetingrooms`;
    return http.post<CreateMeetingRoomBooking, AxiosResponse<string>>(url, booking);
};

export const updateMeetingRoomBooking = ({
    id,
    ...booking
}: UpdateMeetingRoomBooking): Promise<AxiosResponse<never>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/bookings/meetingrooms/${id}`;
    return http.put<UpdateMeetingRoomBooking, AxiosResponse<never>>(url, booking);
};

export const getBuildings = (): Promise<AxiosResponse<Building[]>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/locations/buildings`;
    return http.get<Building[]>(url);
};

export const getBookings = (start?: Date, end?: Date): Promise<AxiosResponse<Booking[]>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/bookings/meetingrooms`;
    return http.get<Booking[]>(url, { params: { start, end } });
};

export const getMyBookings = (fromDate?: Date, toDate?: Date): Promise<AxiosResponse<Booking[]>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/bookings/meetingrooms/user`;
    return http.get<MyBooking[]>(url, { params: { start: fromDate, end: toDate } });
};

export const getDeclinedBookingForManagingFoodOrder = (
    bookingId: string,
): Promise<AxiosResponse<BookingForManagingFoodOrder>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/bookings/${bookingId}/manageFoodOrder`;
    return http.get<BookingForManagingFoodOrder>(url);
};

export const updateRoom = (id: string, data: UpdateRoom): Promise<AxiosResponse<never>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/meetingrooms/${id}`;
    return http.put<UpdateRoom, AxiosResponse<never>>(url, data);
};

export const updateApproversRoom = (
    meetingRoomId: string,
    approversList: Approver[],
    needsApproval: boolean,
): Promise<AxiosResponse<never>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/meetingrooms/${meetingRoomId}/approvers`;
    return http.put<Approver[], AxiosResponse<never>>(url, { approvers: approversList, needsApproval });
};

export const updateAccessibilityRoom = (
    meetingRoomId: string,
    data: AccessEntitiesType,
): Promise<AxiosResponse<never>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/meetingrooms/${meetingRoomId}/accessibility`;
    return http.put<Approver[], AxiosResponse<never>>(url, { access: data });
};

export const updateAccessGroupRoom = (meetingRoomId: string, data: string[]): Promise<AxiosResponse<never>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/meetingrooms/${meetingRoomId}/physicalAccessGroups`;
    return http.put<Approver[], AxiosResponse<never>>(url, { accessGroupIds: data });
};

export const updatePriceRoom = (meetingRoomId: string, pricePerHour: number): Promise<AxiosResponse<never>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/meetingrooms/${meetingRoomId}/price`;
    return http.put<Approver[], AxiosResponse<never>>(url, { pricePerHour });
};

export const deleteMeetingRoomBooking = (bookingId: string): Promise<AxiosResponse<never>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/bookings/meetingrooms/${bookingId}`;
    return http.delete<never>(url);
};

export const getConfigurationUrl = (): Promise<AxiosResponse<string>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/administration/loop/url`;
    return http.get<string>(url);
};

export const approveMeeting = (bookingId: string): Promise<AxiosResponse<never>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/bookings/meetingrooms/${bookingId}/approve`;
    return http.put<any, AxiosResponse<never>>(url);
};

export const declineMeeting = (bookingId: string, declineReason: string): Promise<AxiosResponse<never>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/bookings/meetingrooms/${bookingId}/decline`;
    return http.put<any, AxiosResponse<never>>(url, null, { params: { declineReason } });
};

export function getUserIsApprover(): Promise<AxiosResponse<boolean>> {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/meetingrooms/isUserApprover`;
    return http.get<boolean>(url);
}

export const getApprovalBookings = (start?: Date, end?: Date): Promise<AxiosResponse<ApproveBookings[]>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/bookings/meetingrooms/approvalRequest`;
    return http.get<ApproveBookings[]>(url, { params: { start, end } });
};

export const getBuildingsWithAvailableMeetingRooms = async (
    filters?: BuildingsWithAvailableRoomsParams,
): Promise<AxiosResponse<Building[] | string>> => {
    const portalId = getPortalId();
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${portalId}/locations/buildings/available`;
    return http.get<Building[]>(url, {
        params: { ...filters },
    });
};

export const getBookingDetailsByPeriodExcelFile = (
    dateFrom: string,
    dateTo: string,
    ianaLocaleString: string,
): AxiosPromise<Blob> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/bookings/meetingrooms/report/excel`;
    return http.put<Blob>(
        url,
        { dateFrom, dateTo, ianaLocaleString },
        {
            responseType: 'blob',
        },
    );
};

export const getOrganizersForPortal = (
    term: string,
    page: number,
    pageSize: number,
): Promise<AxiosResponse<SearchOrganizersModel[]>> => {
    const url = `${getBaseUrlApim()}/${urlBooking}/portals/${getPortalId()}/events/organizers/search`;
    return http.get(url, { params: { term, page, pageSize } });
};
