import { useState, useEffect, useRef, PropsWithChildren, ReactElement, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Avatar, Checkbox, RadioButton, SvgIcon } from '..';
import { MobileViewBreakpoint } from '../../common/ScreenSizeBreakPoints';
import { splitName } from '../../utility';
import { FlexBox, Typography } from '../styleds';

interface ContentCardProps {
    isDisabled?: boolean;
}

const ContentCard = styled.button`
    display: flex;
    align-items: center;
    height: auto;
    border: 1px solid #d4d6d7;
    padding: 0.57rem 1rem;
    cursor: pointer;
    background-color: white;
    width: 100%;
    justify-content: space-between;
    pointer-events: ${({ isDisabled }: ContentCardProps) => (isDisabled ? 'none' : 'auto')};

    &:hover {
        background-color: #f6fcff;
    }

    :focus-visible,
    :focus {
        outline: none;
        background-color: #f6fcff;
    }

    :focus:not(:focus-visible) {
        outline: none;
        background-color: #f6fcff;
    }
`;

const ContentCardHeader = styled.h4`
    text-transform: uppercase;
    font-size: 0.857rem;
    letter-spacing: 1px;
    margin: 0;
    font-weight: bold;
    color: #263238;
    text-align: left;
`;

const RemoveButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0.5rem;
    margin-left: auto;

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

type NotificationCheckboxWrapperProps = { isMobile: boolean };
const NotificationCheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    ${({ isMobile }: NotificationCheckboxWrapperProps) => isMobile && 'width: 100%; justify-content: center;'};
`;

const VerticalLine = styled.div`
    border-left: 2px solid #d4d6d7;
    height: 3.429rem;
    margin: 0rem 1.143rem;
`;

interface BaseCaseworkerProps {
    id: string;
    name?: string;
    email: string;
    avatar?: string | null;
}

interface BaseNotificationSettingsProps {
    pushEnabled: boolean;
    emailEnabled: boolean;
}
export enum CaseWorkerType {
    Remove = 'Remove',
    Select = 'Select',
    Search = 'Search',
    Notification = 'Notification',
}
type CaseworkerCardProps<T extends BaseCaseworkerProps, N> = {
    onClick?: (guid: string) => void;
    onClickRemoveUser?: (guid: string) => void;
    onClickNotification?: (caseWorkerId: string, pushEnabled: boolean, emailEnabled: boolean) => void;
    notificationsSettings?: N;
    type: CaseWorkerType;
    className?: string;
    selectCaseworker?: boolean;
    hideAvatarImage?: boolean;
    isError?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    radioName?: string;
} & T;

const CaseworkerCard = <T extends BaseCaseworkerProps, N extends BaseNotificationSettingsProps>({
    id,
    name,
    radioName,
    email,
    avatar,
    children,
    onClick,
    onClickRemoveUser,
    onClickNotification,
    notificationsSettings,
    type,
    className,
    selectCaseworker,
    hideAvatarImage,
    isError,
    isLoading,
    isDisabled,
}: PropsWithChildren<CaseworkerCardProps<T, N>>): ReactElement => {
    const { t: tCommon } = useTranslation('common');
    const [firstName, lastName] = splitName(name ?? email);
    const childrenContainer = useRef<HTMLDivElement>(null);
    const isMobile = window.matchMedia(`(max-width:${MobileViewBreakpoint ?? 768}px)`)?.matches;
    const [pushChecked, setPushChecked] = useState(notificationsSettings?.pushEnabled ?? true);
    const [emailChecked, setEmailChecked] = useState(notificationsSettings?.emailEnabled ?? true);

    const handleClickRemoveUser = (e: MouseEvent<HTMLButtonElement>, guid: string) => {
        e.stopPropagation();
        onClickRemoveUser && onClickRemoveUser(guid);
    };

    useEffect(() => {
        setPushChecked(notificationsSettings?.pushEnabled ?? true);
        setEmailChecked(notificationsSettings?.emailEnabled ?? true);
    }, [isError, notificationsSettings]);

    const handleClickNotification = (isChecked: boolean, type: 'push' | 'email') => {
        type === 'push' && setPushChecked(isChecked);
        type === 'email' && setEmailChecked(isChecked);
        onClickNotification &&
            notificationsSettings &&
            onClickNotification(
                id,
                type === 'push' ? isChecked : notificationsSettings.pushEnabled,
                type === 'email' ? isChecked : notificationsSettings.emailEnabled,
            );
    };

    return (
        <ContentCard
            onClick={() => onClick && onClick(id)}
            tabIndex={0}
            className={className}
            key={id}
            isDisabled={isDisabled}
        >
            <FlexBox align={'center'} mrR={'0.3rem'} overflow={'hidden'}>
                <FlexBox mrR={'0.5rem'} width={'auto'}>
                    <Avatar firstName={firstName} lastName={lastName} image={avatar} hideImage={hideAvatarImage} />
                </FlexBox>
                <FlexBox dir={'column'}>
                    <ContentCardHeader>{name}</ContentCardHeader>
                    <Typography textAlign="left" color="#677074">
                        {email}
                    </Typography>
                </FlexBox>
                <div ref={childrenContainer}>{children}</div>
            </FlexBox>
            {onClickRemoveUser && type === CaseWorkerType.Search && (
                <RemoveButton onClick={(e) => handleClickRemoveUser(e, id)}>
                    <SvgIcon name="SubtractButtonIcon" />
                </RemoveButton>
            )}
            {type === CaseWorkerType.Select && radioName && (
                <RadioButton disabled={isDisabled} name={radioName} value={id} checked={selectCaseworker} />
            )}
            {type === CaseWorkerType.Notification && onClickNotification && notificationsSettings && (
                <NotificationCheckboxWrapper isMobile={isMobile}>
                    <Checkbox
                        id={`${id}push`}
                        key={`${id}push`}
                        checked={pushChecked}
                        onChange={(_, isChecked) => handleClickNotification(isChecked, 'push')}
                        label={tCommon('notification')}
                        disable={isLoading}
                    />
                    <VerticalLine />

                    <Checkbox
                        id={`${id}email`}
                        key={`${id}email`}
                        checked={emailChecked}
                        onChange={(_, isChecked) => handleClickNotification(isChecked, 'email')}
                        label={tCommon('email')}
                        disable={isLoading}
                    />
                </NotificationCheckboxWrapper>
            )}
        </ContentCard>
    );
};

export default CaseworkerCard;
