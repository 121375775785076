import { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { MobileViewBreakpoint } from '../../../../common/ScreenSizeBreakPoints';

type positionType = 'top' | 'bottom';

type BannerProps = { position: positionType };
const Banner = styled.div`
    width: 100%;
    padding: 1.14rem 1.75rem;
    display: grid;
    grid-template-columns: fit-content(100%) auto fit-content(100%);
    grid-column-gap: 0.5rem;
    background-color: var(--surface-color-light);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    z-index: 1;
    position: ${({ position }: BannerProps) => (position === 'bottom' ? 'absolute' : 'relative')};
    bottom: ${({ position }: BannerProps) => (position === 'bottom' ? '0' : 'none')};

    @media (max-width: ${MobileViewBreakpoint}px) {
        width: 100%;
        padding: 1.14rem;
        box-shadow: none;
        border-bottom: 1px solid var(--border-color);
    }
`;

interface ActionBannerProps {
    position?: positionType;
}

const VisualActionBanner = ({ position = 'top', children }: PropsWithChildren<ActionBannerProps>): ReactElement => {
    return <Banner position={position}>{children}</Banner>;
};

export default VisualActionBanner;
