import { PropsWithChildren, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SvgIcon } from '../../../../../../components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

type ExpandCollapseButtonProps = { isExpanded: boolean };
const ExpandCollapseButton = styled.button`
    margin: auto;
    padding: 0.5rem;
    transform: ${({ isExpanded }: ExpandCollapseButtonProps) => (isExpanded ? 'rotate(-180deg)' : 'none')};
    transition: ease transform 0.2s;
    background: transparent;
    border: none;
    cursor: pointer;

    :focus,
    :focus-visible {
        outline: 1px solid var(--primary-color);
    }
    :focus:not(:focus-visible) {
        outline: 0;
    }

    svg {
        path {
            fill: var(--primary-color);
        }
    }
`;

interface AccordionProps {
    disableAndShowContent?: boolean;
}

const Accordion = ({ disableAndShowContent, children }: PropsWithChildren<AccordionProps>): ReactElement => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    return (
        <Container>
            {!disableAndShowContent && (
                <ExpandCollapseButton onClick={() => setIsExpanded(!isExpanded)} isExpanded={isExpanded} type="button">
                    <SvgIcon
                        name="CaretDown"
                        alt={isExpanded ? t('extendedInquiryInformation') : t('collapsedInquiryInformation')}
                    />
                </ExpandCollapseButton>
            )}
            {(isExpanded || disableAndShowContent) && children}
        </Container>
    );
};

export default Accordion;
