import { ReactElement, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { appOperations } from '../../../../duck';
import { Service } from '../../../common/lifeAtWorkServices';
import Perm from '../../../common/perms';
import { InformationMessage, InputField, PhoneInputField, PrimaryButton } from '../../../components';
import { useServiceIsActive, useUserHasPerm } from '../../../utility';
import { useIsMobile } from '../../../utility/hooks/useIsMobile';
import {
    useActivateShop,
    useGetShopIsActivatedFromPortalAdmin,
    useGetShopSettings,
} from '../apiQueries/useShopActivation';
import { ActivationStatusBadge } from '../components';
import { Header } from '../styles/ShopAtWorkStyles';
import { FormSchema, schema } from './utils';

const TopContainer = styled.div`
    display: flex;
    align-items: baseline;
    padding-bottom: 0.5rem;
    gap: 1rem;
    flex-wrap: wrap;
`;

const Description = styled.p`
    font-size: 1rem;
    margin: 0;
`;

const InformationMessageText = styled.p``;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 1.714rem 0;
    gap: 1.143rem;
`;

const SubHeader = styled.h3`
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.714rem;
    margin-bottom: 0;
`;

type FormGroupProps = { isMobile: boolean };
const FormGroup = styled.div`
    display: flex;
    flex-direction: ${({ isMobile }: FormGroupProps) => (isMobile ? 'column' : 'row')};
    width: 100%;
    gap: 1.714rem;
`;

type FormColumnProps = { isMobile: boolean };
const FormColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({ isMobile }: FormColumnProps) => (isMobile ? '100%' : '28.714rem')};
    gap: 1.714rem;
`;

const StyledPhoneInputField = styled(PhoneInputField)`
    #phone-input {
        width: 100%;
        background: var(--surface-color-light);
        border: 1px solid var(--border-color);
        border-radius: 0.35rem;
    }
`;

const SubmitButton = styled(PrimaryButton)`
    &&& {
        margin-top: 1rem;
    }

    width: fit-content;
`;

const ShopAtWorkSettings = (): ReactElement => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    const { data: shopSettings, isLoading: isLoadingShopSettings } = useGetShopSettings();
    const { data: shopIsActivatedByPortalAdmin = false } = useGetShopIsActivatedFromPortalAdmin();
    const [showFormValidationWarningMessage, setShowFormValidationWarningMessage] = useState(false);
    const [activateShop, { isLoading: isLoadingActivateShop }] = useActivateShop();
    const shopIsActivated = userIsPortalAdmin ? shopIsActivatedByPortalAdmin : useServiceIsActive(Service.ShopAtWork);
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'settings' });

    const defaultValues = {
        companyName: shopSettings?.company?.name ?? '',
        address: shopSettings?.company?.street ?? '',
        location: shopSettings?.company?.city ?? '',
        organizationNumber: shopSettings?.company?.organizationNumber ?? '',
        postalCode: shopSettings?.company?.zipCode ?? '',
        country: shopSettings?.company?.country ?? '',
        contactFirstName: shopSettings?.contactPerson?.firstName ?? '',
        contactSurName: shopSettings?.contactPerson?.lastName ?? '',
        contactEmail: shopSettings?.contactPerson?.email ?? '',
        contactPhoneNumber: shopSettings?.contactPerson?.phone ?? '',
    };

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { errors },
    } = useForm<FormSchema>({
        mode: 'onTouched',
        resolver: yupResolver(schema(t)),
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [isLoadingShopSettings]);

    const formHasErrors = !!Object.keys(errors).length;
    useEffect(() => setShowFormValidationWarningMessage(formHasErrors), [formHasErrors]);

    const onSubmitHandler = (form: FormSchema) => {
        activateShop(
            [
                {
                    company: {
                        name: form.companyName,
                        city: form.location,
                        country: form.country,
                        organizationNumber: form.organizationNumber,
                        street: form.address,
                        zipCode: form.postalCode,
                    },
                    contactPerson: {
                        email: form.contactEmail,
                        firstName: form.contactFirstName,
                        lastName: form.contactSurName,
                        phone: form.contactPhoneNumber,
                    },
                },
            ],
            {
                onSuccess: () => {
                    dispatch(appOperations.fetchAppContext());
                },
            },
        );
    };

    return (
        <>
            <TopContainer>
                <Header>{t('companyInfoTitle')}</Header>
                <ActivationStatusBadge eShopEnabled={shopIsActivated} />
            </TopContainer>
            <Description>{t('subtitle')}</Description>
            {showFormValidationWarningMessage ? (
                <InformationMessage type="warning" onClose={() => setShowFormValidationWarningMessage(false)}>
                    <InformationMessageText id="informationMessageText">{t('formError')}</InformationMessageText>
                </InformationMessage>
            ) : null}
            <Form>
                <SubHeader>{t('companyDetailsTitle')}</SubHeader>
                <FormGroup isMobile={isMobile}>
                    <FormColumn isMobile={isMobile}>
                        <InputField
                            label={t('companyNameInputLabel')}
                            type="text"
                            key="companyName"
                            required
                            {...register('companyName')}
                            error={errors.companyName?.message}
                            isLoading={isLoadingShopSettings}
                            absolutePositionErrorMessage
                        />
                        <InputField
                            label={t('companyAdressInputLabel')}
                            type="text"
                            required
                            {...register('address')}
                            error={errors.address?.message}
                            isLoading={isLoadingShopSettings}
                            absolutePositionErrorMessage
                        />
                        <InputField
                            label={t('companyLocationInputLabel')}
                            type="text"
                            required
                            {...register('location')}
                            error={errors.location?.message}
                            isLoading={isLoadingShopSettings}
                            absolutePositionErrorMessage
                        />
                    </FormColumn>
                    <FormColumn isMobile={isMobile}>
                        <InputField
                            label={t('companyNumberInputLabel')}
                            type="number"
                            required
                            {...register('organizationNumber')}
                            error={errors.organizationNumber?.message}
                            isLoading={isLoadingShopSettings}
                            absolutePositionErrorMessage
                        />
                        <InputField
                            label={t('companyPostNumberInputLabel')}
                            type="number"
                            required
                            {...register('postalCode')}
                            error={errors.postalCode?.message}
                            isLoading={isLoadingShopSettings}
                            absolutePositionErrorMessage
                        />
                        <InputField
                            label={t('companyCountryInputLabel')}
                            type="text"
                            required
                            {...register('country')}
                            error={errors.country?.message}
                            absolutePositionErrorMessage
                        />
                    </FormColumn>
                </FormGroup>

                <SubHeader>{t('contactPersonTitle')}</SubHeader>
                <Description>{t('contactPersonSubtitle')}</Description>
                <FormColumn isMobile={isMobile}>
                    <InputField
                        label={t('contactPersonFirstName')}
                        type="text"
                        required
                        {...register('contactFirstName')}
                        error={errors.contactFirstName?.message}
                        absolutePositionErrorMessage
                    />
                    <InputField
                        label={t('contactPersonLastName')}
                        type="text"
                        required
                        {...register('contactSurName')}
                        error={errors.contactSurName?.message}
                        absolutePositionErrorMessage
                    />
                    <InputField
                        label={t('contactPersonEmail')}
                        type="text"
                        required
                        {...register('contactEmail')}
                        error={errors.contactEmail?.message}
                        absolutePositionErrorMessage
                    />
                    <Controller
                        control={control}
                        name="contactPhoneNumber"
                        defaultValue={''}
                        render={({ field: { onChange, value, onBlur } }) => (
                            <StyledPhoneInputField
                                label={t('contactPersonPhoneNumber')}
                                value={value}
                                onChange={(phoneNumber) => onChange(phoneNumber ? `+${phoneNumber}` : '')}
                                error={
                                    errors.contactPhoneNumber?.message
                                        ? errors.contactPhoneNumber.message.length > 0
                                        : false
                                }
                                onBlur={onBlur}
                                helpText={errors.contactPhoneNumber?.message}
                            />
                        )}
                    />
                </FormColumn>
                <SubmitButton
                    onClick={handleSubmit(onSubmitHandler)}
                    disabled={isLoadingActivateShop || isLoadingShopSettings}
                    id="submitShopSettingsButton"
                >
                    {t('confirmButton')}
                </SubmitButton>
            </Form>
        </>
    );
};

export default ShopAtWorkSettings;
