import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PrimaryButton } from '../../../../components';

const CardFormWrapper = styled.div`
    background-color: white;
    border-radius: 0.714rem;
    border: 1px solid #d4d6d7;
    box-shadow: 0 0.285rem 0.714rem 0 #00000026;
    max-width: min(51.429rem, 100%);
    width: 100%;
`;

const CardPageContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem;
`;

const CardFormHeader = styled.header`
    padding: 1.643rem 1.143rem;
`;

const CardFormHeaderTitle = styled.span`
    font-size: 1.429rem;
    font-weight: 900;
    line-height: 2.286rem;
`;

const CardFormFooter = styled.footer`
    display: flex;
    justify-content: flex-end;
    padding: 1.143rem;
    border-top: 1px solid #d4d6d7;
`;

interface CardBoxProps {
    title?: string;
    saveButtonProps: {
        loading: boolean;
        disabled: boolean;
        onClick: () => void;
    };
}

export const CardBox: FC<PropsWithChildren<CardBoxProps>> = ({ title, children, saveButtonProps }) => {
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'portalPurchases' });

    return (
        <CardPageContent>
            <CardFormWrapper>
                {!!title && (
                    <CardFormHeader>
                        <CardFormHeaderTitle>{title}</CardFormHeaderTitle>
                    </CardFormHeader>
                )}
                {children}
                <CardFormFooter>
                    <PrimaryButton
                        onClick={saveButtonProps.onClick}
                        loading={saveButtonProps.loading}
                        disabled={saveButtonProps.disabled}
                    >
                        {t('saveCardButtonText')}
                    </PrimaryButton>
                </CardFormFooter>
            </CardFormWrapper>
        </CardPageContent>
    );
};
