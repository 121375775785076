import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Header, Paragraph } from '../../../../../common/styles';

const Container = styled.div`
    width: 100%;
    margin-bottom: 1.714rem;
`;

const CaseworkerDescription = (): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    return (
        <Container>
            <Header>{t('selectCaseManagers')}</Header>
            <Paragraph>{t('caseWorkerDescription')}</Paragraph>
        </Container>
    );
};

export default CaseworkerDescription;
