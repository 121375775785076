import styled from 'styled-components';

export const DropdownContainer = styled.div`
    position: relative;
`;

interface InputFieldElementProps {
    error: boolean;
    isBadged?: boolean;
}
export const DropDownHeaderButton = styled.button`
    background: var(--surface-color-light);
    border-radius: 0.35rem;
    padding: 0.67rem 1.14rem;
    padding: ${({ isBadged }: InputFieldElementProps) => (isBadged ? '0.471rem 1.04rem' : '0.67rem 1.14rem')};
    width: 100%;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 1rem;

    border: 1px solid;
    border-color: ${({ error }: InputFieldElementProps) => (error ? `var(--error-color)` : 'var(--border-color)')};
    :focus {
        background: #fff;
        outline-color: var(--primary-color);
        outline-style: auto;
    }
    :hover {
        background: var(--background-color);
        cursor: pointer;
    }
    :active {
        color: var(--primary-on-color-light);
        background: #fff;
        outline: none;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const DownArrow = styled.div`
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
`;

interface DropDownListProps {
    open: boolean;
}
export const DropDownList = styled.div`
    overflow-y: auto;
    border-top: 0;
    position: absolute;
    z-index: 2;
    background: var(--primary-on-color);
    width: 100%;
    outline: none;
    max-height: 30rem;
    display: ${({ open }: DropDownListProps) => (open ? 'inherit' : 'none')};
    filter: drop-shadow(0px -1px 10px rgba(0, 0, 0, 0.15));
    border: 1px solid var(--border-color);
    overflow-wrap: break-word;
`;
type DropDownListItemType = {
    isHighlighted?: boolean;
    isDisabled?: boolean;
};
export const DropDownListItem = styled.div<DropDownListItemType>`
    border: 1px solid var(--border-color);
    padding: 0.67rem 1.14rem;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    outline: none;
    background: ${({ isHighlighted, isDisabled }) =>
        isHighlighted && !isDisabled ? 'var(--primary-color-light)' : '#fff'};
    color: ${({ isHighlighted, isDisabled }) => (isHighlighted && !isDisabled ? 'var(--primary-on-color-light)' : '')};
    opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
    border-top: 0;

    :last-child {
        border-bottom: 0;
    }
`;

export const FieldContainer = styled.div`
    position: relative;
`;
