import { ReactElement, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import type { AllergenType } from '../../api/apiTypes/shopApiTypes';
import type { OptionType } from '../select/searchSelect/types';
import StyledSelect from '../select/StyledSelect';

interface AllergenSelectControllerProps<T extends FieldValues> extends UseControllerProps<T, any> {
    label: string;
    placeholder: string;
    allergens: AllergenType[];
}

const parseSelectOptionToAllergen = ({ label: name, value: id }: OptionType): AllergenType => ({ name, id });
const parseAllergenToSelectOption = ({ id: value, name: label }: AllergenType): OptionType => ({ label, value });
export const AllergenSelectController = <T extends FieldValues>({
    control,
    name,
    label,
    placeholder,
    allergens,
}: AllergenSelectControllerProps<T>): ReactElement => {
    const preparedAllergens = useMemo(() => {
        return allergens.map(parseAllergenToSelectOption);
    }, [allergens]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, field }) => {
                const parsedValue = value?.map(parseAllergenToSelectOption) ?? [];
                const handleSetValue = (v: OptionType | OptionType[]) => {
                    onChange(Array.isArray(v) ? v.map(parseSelectOptionToAllergen) : parseSelectOptionToAllergen(v));
                };

                return (
                    <StyledSelect
                        placeholder={placeholder}
                        label={label}
                        closeMenuOnSelect={false}
                        isMulti
                        options={preparedAllergens}
                        {...field}
                        onChange={handleSetValue}
                        value={parsedValue}
                        isCommonSelect
                        hideSelectedOptions={false}
                        controlShouldRenderValue
                        backspaceRemovesValue={false}
                        disabled={false}
                    />
                );
            }}
        />
    );
};
