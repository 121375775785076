import { ReactElement, PropsWithChildren, useState, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import SvgIcon, { SvgIconName } from './icons/SvgIcon';

interface SideBarElementProps {
    isActive: boolean;
}
const SideBarElement = styled.a`
    outline: none;

    :focus-visible {
        outline: 1px solid var(--primary-color);
    }
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-placeholder-color) !important;
    cursor: pointer;
    ${({ isActive }: SideBarElementProps) =>
        isActive
            ? css`
                  font-weight: bold;
                  color: var(--primary-color-dark) !important;
              `
            : css`
                  color: var(--text-placeholder-color);
              `}
`;

type IconContainerProps = { isActive: boolean };
const IconContainer = styled.div`
    display: flex;
    width: 3.5rem;
    svg {
        height: 1.857rem;
        width: 1.857rem;

        g {
            rect {
                fill: var(--primary-color);
                fill-opacity: ${({ isActive }: IconContainerProps) => (isActive ? 1 : 0.8)};
            }
        }
    }
`;

const FlexElementGrow = styled.div`
    flex: 1;
`;

const FlexElementNoGrow = styled.div`
    flex: 0;
`;

interface MenuItemProps {
    icon?: SvgIconName;
    isActive?: boolean;
    onClick(): void;
    text: string;
    isReversed?: boolean;
}
const SidebarMenuItem = ({
    icon,
    children,
    isActive = false,
    onClick,
    text,
    isReversed = false,
}: PropsWithChildren<MenuItemProps>): ReactElement => {
    const hasChildren = Boolean(children);
    const [isExpanded, setIsExpanded] = useState(isActive);

    useEffect(() => {
        !isActive && setIsExpanded(false);
    }, [isActive]);

    const onSidebarMenuClick = () => {
        setIsExpanded(!isExpanded);
        onClick();
    };

    return (
        <>
            {isReversed && isExpanded && isActive && hasChildren && children}
            <SideBarElement isActive={isActive} onClick={onSidebarMenuClick} id={`sideBarMenuItem-${text}`}>
                <IconContainer isActive={isActive}>{icon ? <SvgIcon name={icon} /> : null}</IconContainer>
                <FlexElementGrow>{text}</FlexElementGrow>
                <FlexElementNoGrow>
                    {hasChildren ? (
                        <Icon name={isActive && isExpanded ? 'chevron up' : 'chevron down'} size="small" />
                    ) : null}
                </FlexElementNoGrow>
            </SideBarElement>
            {!isReversed && isExpanded && isActive && hasChildren && children}
        </>
    );
};

export default SidebarMenuItem;
