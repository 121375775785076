import { RefObject, TouchList } from 'react';
import { addMinutes, isSameDay } from 'date-fns';
import { getDeviceLanguageDateFormat } from '../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import { RectResult } from '../../../utility/hooks/useRect';

const getHourString = (date: Date): string => getDeviceLanguageDateFormat(date, 'HH');

const getMinuteString = (date: Date): string => getDeviceLanguageDateFormat(date, 'mm');

const getTimeOptions = (earliestTime: Date, latestTime: Date): Date[] => {
    const startTime = new Date(earliestTime);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);
    const endTime = new Date(latestTime);
    if (!isSameDay(startTime, endTime)) {
        console.error('Unexpected, opening and closing is not same day');
    }

    // If startTime is not whole 15 minutes
    if (startTime.getMinutes() % 15 !== 0) {
        startTime.setMinutes(startTime.getMinutes() + 15 - (startTime.getMinutes() % 15));
    }

    const dateOptions: Date[] = [];
    let current = startTime;
    while (current.valueOf() <= endTime.valueOf()) {
        dateOptions.push(current);

        current = addMinutes(current, 15);
    }
    return dateOptions;
};

const getSortAndMapMinutes = (
    selectedTime: Date,
    timeOptions: Date[],
    minutes: Set<string>,
): { value: string; invalid: boolean }[] => {
    return Array.from(minutes)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .map((value: string): { value: string; invalid: boolean } => {
            const dateRepresentation = new Date(selectedTime);
            dateRepresentation.setMinutes(parseInt(value));
            const invalid: boolean =
                dateRepresentation < timeOptions[0] || dateRepresentation > timeOptions[timeOptions.length - 1];
            return { value, invalid };
        });
};
const getMapHours = (hours: Set<string>): { value: string; invalid: boolean }[] => {
    return Array.from(hours).map((value) => ({ value, invalid: false }));
};
const scrollToOption = (
    containerRef: RefObject<HTMLElement>,
    rect: RectResult | DOMRect,
    containerName: string,
    dataValue: string,
): void => {
    if (containerRef.current) {
        const container = containerRef.current.querySelector(`[data-containerName="${containerName}"]`);
        const item = containerRef.current.querySelector<HTMLElement>(`[data-value="${containerName}${dataValue}"]`);
        if (container && item) {
            container.scrollTo({
                top: item.offsetTop - rect.height / 2 - item.getBoundingClientRect().height / 3,
                behavior: 'smooth',
            });
        }
    }
};

const getNewScrollValue = (
    options: { value: string; invalid: boolean }[],
    event: React.WheelEvent,
    selectedValue: string,
): string => {
    const delta = event.deltaY;
    const indexOfCurrent = options.findIndex(({ value }) => value === selectedValue);
    const numberOfSnaps = delta > 0 ? Math.max(Math.round(delta / 100), 1) : Math.min(Math.round(delta / 100), -1);

    return options[Math.max(Math.min(options.length - 1, indexOfCurrent + numberOfSnaps), 0)]?.value;
};

const getNewKeyValue = (
    options: { value: string; invalid: boolean }[],
    selectedValue: string,
    numberOfSnaps: number,
): string => {
    const indexOfCurrent = options.findIndex(({ value }) => value === selectedValue);
    return options[Math.max(Math.min(options.length - 1, indexOfCurrent + numberOfSnaps), 0)].value;
};

const getNewTouchValue = (
    touchRef: RefObject<any>,
    touches: TouchList,
    options: { value: string; invalid: boolean }[],
): string => {
    const movedDistance = touchRef?.current?.pos - touches[0].clientY;
    const numberOfSnaps = Math.round(movedDistance / 30);

    return options[Math.max(Math.min(options.length - 1, touchRef.current.origIndex + numberOfSnaps), 0)]?.value;
};

const getClosestValidMinuteValue = (date: Date, options: { value: string; invalid: boolean }[]): number => {
    const currentMinute = parseInt(getMinuteString(date));

    const closestValue = options.reduce(
        (prev: { value: string; invalid: boolean }, current: { value: string; invalid: boolean }) => {
            return (!current.invalid && parseInt(current.value) - currentMinute) < !current.invalid &&
                parseInt(prev.value) - currentMinute
                ? current
                : prev;
        },
    );

    return parseInt(closestValue.value);
};

const getValidMinuteOption = (date: Date, options: { value: string; invalid: boolean }[]): Date | undefined => {
    const checkedMinuteValue = date;
    if (options.length === 0) return;
    if (!options.some((option: { value: string; invalid: boolean }) => option.value === getMinuteString(date)))
        checkedMinuteValue.setMinutes(getClosestValidMinuteValue(date, options));

    return checkedMinuteValue;
};

const isValidScrollableValue = (value: string, options: { value: string; invalid: boolean }[]): boolean => {
    return options.some((option: { value: string; invalid: boolean }) => {
        return option.value === value && option.invalid === false;
    });
};

export {
    getTimeOptions,
    getSortAndMapMinutes,
    getMapHours,
    getHourString,
    getMinuteString,
    scrollToOption,
    getNewScrollValue,
    getNewKeyValue,
    getNewTouchValue,
    getValidMinuteOption,
    isValidScrollableValue,
};
