import { MutableRefObject, ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { MenuDropdown, SvgIcon } from '../index';
import { AccordionContextMenuProps } from './types';

type TriggerButtonProps = { show?: boolean };
const TriggerButton = styled.button`
    background-color: ${({ show }: TriggerButtonProps) =>
        show ? 'var(--primary-color-light)' : 'var(--surface-color)'};

    border-radius: 50%;
    cursor: pointer;
    width: 2.28rem;
    height: 2.28rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    :focus {
        border: 0.0625rem solid var(--primary-color);
        outline: none;
    }

    & path {
        fill: ${({ show }: TriggerButtonProps) =>
            show ? 'var(--primary-on-color-light)' : 'var(--text-medium-emphasis-color)'};
    }
`;

export const AccordionContextMenu = ({ actions, id, ...rest }: AccordionContextMenuProps): ReactElement => {
    const onClickHandler = (event: SyntheticEvent, onClick: (e: SyntheticEvent, id: string) => void) => {
        onClick(event, id);
    };
    const actionsWithData = actions.map((action) => ({ ...action, id, ...rest }));
    return (
        <MenuDropdown
            Trigger={({ onClick, isOpen, buttonRef }) => (
                <TriggerButton
                    onClick={(event: SyntheticEvent) => onClickHandler(event, onClick)}
                    show={isOpen}
                    ref={buttonRef as MutableRefObject<HTMLButtonElement>}
                >
                    <SvgIcon name="EllipsisIcon"></SvgIcon>
                </TriggerButton>
            )}
            items={actionsWithData}
        />
    );
};
