import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    diffIsMoreThanADay,
    diffIsMoreThanAnHour,
    diffIsMoreThanAMinute,
    diffIsMoreThanASecond,
    isSameYear,
    getDiffInHours,
    getDiffInMinutes,
    getDiffInSeconds,
} from '../../../utility/dateTimeHelper';

const useUpdatedTimeDifference = (dateTime) => {
    const [now, setNow] = useState(new Date());
    const { t } = useTranslation('notification');
    const [timeString, timeOut] = useMemo(() => {
        if (diffIsMoreThanADay(now, dateTime))
            return [
                `${dateTime.getDate()}. ${dateTime.toLocaleString('nb', {
                    month: 'long',
                })} ${isSameYear(dateTime, now) ? '' : dateTime.getFullYear()}`,
            ];

        if (diffIsMoreThanAnHour(now, dateTime)) {
            const hourDiff = getDiffInHours(now, dateTime);
            if (hourDiff === 1) {
                return [t('oneHourAgo'), 1000 * 60 * 60];
            }
            return [t('hourDiffHourAgo', { hourDiff }), 1000 * 60 * 60];
        }
        if (diffIsMoreThanAMinute(now, dateTime)) {
            const minuteDiff = getDiffInMinutes(now, dateTime);
            if (minuteDiff === 1) {
                return [t('oneMinuteAgo'), 1000 * 60];
            }
            return [t('minuteDiffMinAgo', { minuteDiff }), 1000 * 60];
        }

        if (diffIsMoreThanASecond(now, dateTime)) {
            const secondDiff = getDiffInSeconds(now, dateTime);
            if (secondDiff === 1) {
                return [t('oneSecondAgo'), 1000];
            }
            return [t('secondDiffSecondAgo', { secondDiff }), 1000];
        }

        return [t('now'), 1000];
    }, [dateTime, now]);

    const isValidDateTime = Number.isFinite(dateTime.valueOf());
    useEffect(() => {
        if (Number.isFinite(timeOut) && isValidDateTime) {
            //Update display bases on when the notification was received
            const interval = setInterval(() => setNow(new Date()), timeOut);
            return () => clearInterval(interval);
        }
    }, [isValidDateTime, timeOut]);

    return timeString;
};

export default useUpdatedTimeDifference;
