import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SidebarMenuItem } from '../../components';
import useFoodNavigation from './useFoodNavigation';

const FoodDynamicMenuItem = (): ReactElement => {
    const { t } = useTranslation('common');
    const { goToFoodOrder, goToMyBookings, foodOrderLink, myBookingsLink, isUserFoodOrdersOwnCompanyRead } =
        useFoodNavigation();
    const { pathname } = useLocation();
    const isFoodPathName = pathname?.includes(foodOrderLink) || pathname?.includes(myBookingsLink);
    return (
        <SidebarMenuItem icon="CutleryIcon" isActive={isFoodPathName} onClick={goToFoodOrder} text={t('foodMenuLabel')}>
            <SidebarMenuItem
                isActive={pathname?.includes(foodOrderLink)}
                onClick={goToFoodOrder}
                text={t('foodOrderMenuLabel')}
            />
            {isUserFoodOrdersOwnCompanyRead ? (
                <SidebarMenuItem
                    isActive={pathname?.includes(myBookingsLink)}
                    onClick={goToMyBookings}
                    text={t('myFoodOrdersMenuLabel')}
                />
            ) : null}
        </SidebarMenuItem>
    );
};

export default FoodDynamicMenuItem;
