import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Order } from '../../../api/apiTypes/shopApiTypes';
import { getFormattedCurrency } from '../../../utility';

type isMobileViewProp = { isMobileView: boolean };

const TotalAmountContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
`;

const TotalAmount = styled.div`
    margin-top: ${({ isMobileView }: isMobileViewProp) => (isMobileView ? '0.571rem' : '2.857rem')};
    min-width: ${({ isMobileView }: isMobileViewProp) => (isMobileView ? '100%' : '31.428rem')};
    padding: 0.571rem 0;
    border-top: ${({ isMobileView }: isMobileViewProp) => (isMobileView ? 'none' : '2px solid #aaaaaa')};
`;

const Divider = styled.hr`
    width: 4.857rem;
    text-align: end;
    margin-right: 0;
`;

const TotalAmountList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 0;
    list-style: none;
`;

const TotalAmountItem = styled.li`
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1.142rem;
    line-height: 1.714rem;
    gap: 1rem;
`;

const ItemSubtitle = styled.h4`
    font-weight: 700;
    font-size: 1.142rem;
    line-height: 1.714rem;
    margin: 0;
`;

const CardTitle = styled.h3`
    font-weight: 900;
    font-size: 1.428rem;
    line-height: 2.285rem;
`;

interface TotalProps {
    order: Order;
    isMobileView: boolean;
}

const TotalSum = ({ order, isMobileView }: TotalProps): ReactElement => {
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'orderOverview' });

    return (
        <TotalAmountContainer>
            <TotalAmount isMobileView={isMobileView}>
                <CardTitle>{t('totalTitle')}</CardTitle>
                <TotalAmountList>
                    <TotalAmountItem>
                        <ItemSubtitle>{t('totalDiscount')}</ItemSubtitle>
                        {getFormattedCurrency(order.discount)}
                    </TotalAmountItem>
                    <TotalAmountItem>
                        <ItemSubtitle>{t('totalVAT')}</ItemSubtitle>
                        {getFormattedCurrency(order.tax)}
                    </TotalAmountItem>
                </TotalAmountList>
                <Divider />
                <TotalAmountList>
                    <TotalAmountItem>
                        <ItemSubtitle>{t('totalAmount')}</ItemSubtitle>
                        <ItemSubtitle>{getFormattedCurrency(order.totalExcludingTax)}</ItemSubtitle>
                    </TotalAmountItem>
                    <TotalAmountItem>
                        <ItemSubtitle>{t('totalAmountWithMVA')}</ItemSubtitle>
                        <ItemSubtitle>{getFormattedCurrency(order.total)}</ItemSubtitle>
                    </TotalAmountItem>
                </TotalAmountList>
            </TotalAmount>
        </TotalAmountContainer>
    );
};

export default TotalSum;
