import { ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { MobileViewBreakpoint } from '../../../../common/ScreenSizeBreakPoints';
import { ShimmerAnimation } from '../common/index';

const greyColor = '#e9ebeb';
const greyColorDark = '#d4d6d7';

const fadeOut = keyframes`
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
`;

type WrapperProps = { show?: boolean };
const Wrapper = styled.div`
    position: relative;
    animation: ${({ show }: WrapperProps) =>
        show
            ? 'none;'
            : css`
                  ${fadeOut} 0.1s linear forwards;
              `};
`;

const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 1.714rem;

    @media (max-width: ${MobileViewBreakpoint}px) {
        padding-top: 0;
    }
`;

const UserDetailsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: ${MobileViewBreakpoint}px) {
        width: 100%;
    }
`;

const Avatar = styled.div`
    height: 4.571rem;
    width: 4.571rem;
    border-radius: 50%;
    background-color: ${greyColor};
`;

const SkeletonItem = styled.div`
    height: 1.143rem;
    background-color: ${greyColor};
    border-radius: 0.357rem;
`;

const UserDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 1.143rem;
    gap: 0.357rem;
`;

const Name = styled(SkeletonItem)`
    width: 8.571rem;
    height: 1.429rem;
    background-color: ${greyColorDark};
`;

const Email = styled(SkeletonItem)`
    width: 4.857rem;
`;

const Company = styled(SkeletonItem)`
    width: 8.571rem;
`;

const PhoneNumber = styled(SkeletonItem)`
    width: 6.429rem;
`;

interface UserDetailsSkeletonLoaderProps {
    show?: boolean;
    numberOfItems?: number;
}

const UserDetailsSkeletonLoader = ({ show, numberOfItems = 1 }: UserDetailsSkeletonLoaderProps): ReactElement => {
    let items = [];
    const item = (index: number) => (
        <Wrapper show={show} key={index}>
            <Container>
                <Avatar />
                <UserDetailsContainer>
                    <Name />
                    <Email />
                    <Company />
                    <PhoneNumber />
                </UserDetailsContainer>
            </Container>
            <ShimmerAnimation />
        </Wrapper>
    );
    items = Array.from({ length: numberOfItems }, (_, index) => item(index));
    return <UserDetailsWrapper>{items}</UserDetailsWrapper>;
};

export default UserDetailsSkeletonLoader;
