import { OwnUser } from '../../app/api/apiTypes/portalApiTypes';
import { CurrentUserContextAction, CurrentUserState } from './types';

export const initialState: CurrentUserState = {
    isLoadingCurrentUser: false,
    currentUser: null,
    userError: null,
    isLoadingPrivacyPolicy: true,
    privacyPolicy: null,
    privacyPolicyError: null,
    isAwaitingConfirmUser: false,
    confirmUserError: null,
    isAwaitingApprovePrivacyPolicy: false,
};

export const reducer = (state: CurrentUserState, action: CurrentUserContextAction): CurrentUserState => {
    switch (action.type) {
        case 'REQUESTED_CURRENT_USER':
            return {
                ...state,
                isLoadingCurrentUser: true,
                currentUser: null,
            };
        case 'RECEIVED_CURRENT_USER':
            return {
                ...state,
                isLoadingCurrentUser: false,
                currentUser: {
                    ...action.payload,
                } as OwnUser | null,
            };
        case 'UPDATE_CURRENT_USER_AVATAR':
            if (!state.currentUser) throw new Error('Received avatar before userObject initialized, unexpected');
            return {
                ...state,
                currentUser: { ...state.currentUser, avatar: action.payload },
            };
        case 'UPDATE_CURRENT_USER_PROFILE_DATA':
            if (!state.currentUser) throw new Error('Received data before userObject initialized, unexpected');
            return {
                ...state,
                currentUser: { ...state.currentUser, ...action.payload },
            };
        case 'FETCH_PRIVACY_POLICY_REQUESTED':
            return {
                ...state,
                isLoadingPrivacyPolicy: true,
            };
        case 'FETCH_PRIVACY_POLICY_SUCCEEDED':
            return {
                ...state,
                isLoadingPrivacyPolicy: false,
                privacyPolicy: action.payload,
            };
        case 'FETCH_PRIVACY_POLICY_FAILED':
            return {
                ...state,
                isLoadingPrivacyPolicy: false,
                privacyPolicyError: action.payload,
            };
        case 'CONFIRM_USER_REQUESTED':
            return {
                ...state,
                isAwaitingConfirmUser: true,
            };
        case 'CONFIRM_USER_SUCCEEDED':
            return {
                ...state,
                isAwaitingConfirmUser: false,
            };
        case 'CONFIRM_USER_FAILED':
            return {
                ...state,
                isAwaitingConfirmUser: false,
                confirmUserError: action.payload,
            };
        case 'APPROVE_PRIVACY_POLICY_REQUESTED':
            return {
                ...state,
                isAwaitingApprovePrivacyPolicy: true,
            };
        case 'APPROVE_PRIVACY_POLICY_SUCCEEDED':
            return {
                ...state,
                privacyPolicy: {
                    approvedDate: new Date().toISOString(),
                    versionDateTime: state.privacyPolicy?.versionDateTime || '',
                },
                isAwaitingApprovePrivacyPolicy: false,
            };
        case 'APPROVE_PRIVACY_POLICY_FAILED':
            return {
                ...state,
                isAwaitingApprovePrivacyPolicy: false,
            };

        default:
            return {
                ...state,
            };
    }
};
