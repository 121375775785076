import styled from 'styled-components';

export const AccordionCount = styled.p`
    font-size: 0.85rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
    text-align: left;
    /* Width is based on what there is space for, not a specific breakpoint */
    @media (max-width: 370px) {
        white-space: pre-wrap;
        font-size: 0.71rem;
    }
`;
