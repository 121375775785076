import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useAutoSave = (isLoading: boolean, isSuccess: boolean, isError: boolean): string => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const [status, setStatus] = useState(t('savingStatus.noChanges'));
    useEffect(() => {
        isLoading && setStatus(t('savingStatus.isSaving'));
        isSuccess && setStatus(t('savingStatus.changesSaved'));
        isError && setStatus(t('savingStatus.error'));
    }, [isLoading, isSuccess, isError]);

    return status;
};

export default useAutoSave;
