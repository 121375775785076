import { useEffect, useState } from 'react';

const useLocalStorageState = <stateType>(
    localStorageKey: string,
    initialValue: stateType,
): [stateType, React.Dispatch<React.SetStateAction<stateType>>] => {
    const useStateResult = useState<stateType>(() => {
        const selectedLocalStorageData = localStorage.getItem(localStorageKey) || '';
        if (selectedLocalStorageData) return JSON.parse(selectedLocalStorageData);
        return initialValue;
    });

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(useStateResult[0]));
    }, [localStorageKey, useStateResult]);

    return useStateResult;
};

export default useLocalStorageState;
