import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalType } from '../../../../../components';
import { Button, FlexBox, Typography } from '../../../../../components/styleds';
import Confirmation from '../../../../../components/styleds/confirmation';
import useKitchenAdminNavigation from '../../../../Admin/KitchenAdmin/useKitchenAdminNavigation';
import { useCancelFoodOrder } from '../../../../ShopAtWork/apiQueries/useShopOrders';
import { ShopOverviewView } from '../../../../ShopAtWork/ShopAtWork';
import useShopAtWorkNavigation from '../../../../ShopAtWork/useShopAtWorkNavigation';
import OrderCanceledPopup from './components/OrderCanceledPopup';
import UnableToCancelOrderPopup from './components/UnableToCancelOrderPopup';
import { HeaderStripProps } from './types';

const HeaderStrip: FC<HeaderStripProps> = ({ order, isLoadingOrder, view }) => {
    const { t } = useTranslation('Food', { keyPrefix: 'foodForMeeting' });
    const { goToMyPurchases, goToOrderEdit } = useShopAtWorkNavigation();
    const { goToOrdersLink } = useKitchenAdminNavigation();

    const [deleteFoodOrder, { isLoading: isDeleteFoodOrderLoading }] = useCancelFoodOrder();

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isSuccessConfirmationModal, setIsSuccessConfirmationModal] = useState(false);
    const [isUnableToCancelModal, setIsUnableToCancelModal] = useState(false);

    const expiredProducts = useMemo(() => {
        const today = new Date();

        return (
            order?.orderLines.filter(({ product }) => {
                const { orderingDeadlineInDays, orderingDeadlineTimeOfDay } = product.productAvailability;

                if (!orderingDeadlineInDays || !orderingDeadlineTimeOfDay) {
                    return false;
                } else {
                    // Calculate the deadline date by subtracting the number of days from today
                    const deadlineDate = new Date();
                    deadlineDate.setDate(deadlineDate.getDate() - orderingDeadlineInDays);

                    // Set the deadline time using orderingDeadlineTimeOfDay values
                    deadlineDate.setHours(
                        orderingDeadlineTimeOfDay.hours,
                        orderingDeadlineTimeOfDay.minutes,
                        orderingDeadlineTimeOfDay.seconds,
                        orderingDeadlineTimeOfDay.milliseconds,
                    );

                    // Return only products where today's date is greater than the deadline (expired)
                    return today > deadlineDate;
                }
            }) || []
        );
    }, [order?.orderLines]);

    const cancelOrderHandler = useCallback(async () => {
        if (expiredProducts.length) {
            setIsUnableToCancelModal(true);
        } else {
            setIsConfirmationModalOpen(true);
        }
    }, [expiredProducts]);

    const handleGoBack = useCallback(() => {
        if (view === ShopOverviewView.MyPurchases) {
            goToMyPurchases();
        } else {
            goToOrdersLink();
        }
    }, [view, goToMyPurchases, goToOrdersLink]);

    const removeOrderHandler = useCallback(() => {
        deleteFoodOrder(order?.id, {
            onSuccess: () => {
                setIsConfirmationModalOpen(false);
                setIsSuccessConfirmationModal(true);
            },
        });
    }, [deleteFoodOrder, order?.id]);

    const goToEditHandler = useCallback(() => {
        if (order) {
            goToOrderEdit(order.id, view === ShopOverviewView.MyPurchases ? '' : order.employee.email);
        }
    }, [goToOrderEdit, order?.id]);

    const backToOrderHandler = useCallback(() => {
        setIsSuccessConfirmationModal(false);
        handleGoBack();
    }, [goToMyPurchases, handleGoBack]);

    return (
        <FlexBox
            background={'#fff'}
            pd={'1.143rem 1.714rem'}
            align={'center'}
            justify={'space-between'}
            boxShadow={'0px 4px 10px 0px rgba(0, 0, 0, 0.15)'}
            skeleton={isLoadingOrder}
            position={'sticky'}
            left={'0px'}
            top={'0px'}
            zIndex={1}
        >
            <Button
                background={'transparent'}
                borderWidth={'2px'}
                borderColor={'var(--primary-color)'}
                br={'5px'}
                align={'center'}
                justify={'center'}
                pd={'0.571rem 1.143rem'}
                width={'auto'}
                onClick={handleGoBack}
            >
                <Typography
                    fontSize={'1.143rem'}
                    lineHeight={'1.714rem'}
                    fontWeight={700}
                    color={'var(--primary-color)'}
                >
                    {t('foodOrderDetailHeaderBackBtnText')}
                </Typography>
            </Button>
            <FlexBox align={'center'} gap={'0.571rem'} width={'auto'}>
                {order?.canCancel && (
                    <Button
                        background={'#B40000'}
                        borderWidth={'2px'}
                        borderColor={'#B40000'}
                        br={'5px'}
                        align={'center'}
                        justify={'center'}
                        pd={'0.571rem 1.143rem'}
                        width={'auto'}
                        onClick={cancelOrderHandler}
                    >
                        <Typography fontSize={'1.143rem'} lineHeight={'1.714rem'} fontWeight={700} color={'#fff'}>
                            {t('foodOrderDetailHeaderCancelOrderBtnText')}
                        </Typography>
                    </Button>
                )}
                {order?.canEdit && (
                    <Button
                        background={'transparent'}
                        borderWidth={'2px'}
                        borderColor={'var(--primary-color)'}
                        br={'0.357rem'}
                        align={'center'}
                        justify={'center'}
                        pd={'0.571rem 1.143rem'}
                        width={'auto'}
                        onClick={goToEditHandler}
                    >
                        <Typography
                            fontSize={'1.143rem'}
                            lineHeight={'1.714rem'}
                            fontWeight={700}
                            color={'var(--primary-color)'}
                        >
                            {t('foodOrderDetailHeaderEditOrderBtnText')}
                        </Typography>
                    </Button>
                )}
            </FlexBox>
            <Confirmation
                title={t('foodOrderDetailHeaderEditOrderConfirmationTitle')}
                isOpened={isConfirmationModalOpen}
                setIsOpened={setIsConfirmationModalOpen}
                saveDataCallback={removeOrderHandler}
                cancelBtnText={t('foodOrderDetailHeaderEditOrderConfirmationCancelText')}
                saveBtnText={t('foodOrderDetailHeaderEditOrderConfirmationSubmitText')}
                submitBg={'#B40000'}
                type={ModalType.Small}
                isDisabledSaveBtn={isDeleteFoodOrderLoading}
            >
                <Typography color={'#000'} fontSize={'1.143rem'} lineHeight={'1.714rem'} fontWeight={400}>
                    {t('foodOrderDetailHeaderEditOrderConfirmationDescription')}
                </Typography>
            </Confirmation>
            <OrderCanceledPopup
                isOpened={isSuccessConfirmationModal}
                setIsOpened={setIsSuccessConfirmationModal}
                backToOrderHandler={backToOrderHandler}
            />
            <UnableToCancelOrderPopup
                isOpened={isUnableToCancelModal}
                setIsOpened={setIsUnableToCancelModal}
                orderLines={expiredProducts}
                email={order?.employee?.email || ''}
            />
        </FlexBox>
    );
};
export default HeaderStrip;
