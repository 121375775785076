import { ReactElement, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import type { SingleValue } from 'react-select';
import styled from 'styled-components';
import { Category, TicketType } from '../../../../../../../api/apiTypes/ticketingApiTypes';
import {
    Dropdown,
    FullPageLoader,
    GhostPrimaryButton,
    InputSelect,
    Modal,
    ModalType,
    PrimaryButton,
} from '../../../../../../../components';
import { useNavigation } from '../../../../../../../utility';
import { TicketTypeQueryCacheKey } from '../../../../../apiQueries/useTicketingType';
import { useDeleteTypeCategory } from '../../../../../apiQueries/useTicketingTypeCategory';
import { Paragraph } from '../../../../../common/styles';
import useTicketingNavigation from '../../../../../useTicketingNavigation';

const StyledModal = styled(Modal)`
    #modalContentContainer {
        overflow: visible;
    }
`;

const FooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Container = styled.div`
    width: 100%;
`;

const StyledGhostPrimaryButton = styled(GhostPrimaryButton)`
    &&& {
        margin-right: 1rem;
    }
`;

const StyledInputSelect = styled(InputSelect)`
    &&& {
        margin-bottom: 1rem;
    }
`;

const StyledDropdown = styled(Dropdown)`
    height: 14.286rem;
`;

const ModalContentWrapper = styled.div`
    padding: 2rem;
`;

const LoaderContainer = styled.div`
    min-height: min(80vh, 15rem);
    display: flex;
    align-items: center;
`;

interface DeleteCategoryModalProps {
    category: Category;
    typeId: string;
    disableDeleteCategory: boolean;
    allTypes: TicketType[];
    ticketCountOpen: number;
    ticketCountClosed: number;
}

const DeleteCategoryModal = ({
    category,
    typeId,
    disableDeleteCategory,
    allTypes,
    ticketCountOpen,
    ticketCountClosed,
}: DeleteCategoryModalProps): ReactElement => {
    const { t } = useTranslation('Ticketing');
    const { t: tCommon } = useTranslation('common');
    const [deleteCategory, { isSuccess, isLoading, isError }] = useDeleteTypeCategory();
    const { getTypeLink } = useTicketingNavigation();
    const navigation = useNavigation();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedType, setSelectedType] = useState({ id: '', name: '' });
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const cache = useQueryCache();

    useEffect(() => setSelectedCategory(''), [selectedType.id]);

    const typeOptions = useMemo(
        () =>
            allTypes.map((type) => ({
                label: type.name,
                id: type.id,
            })),
        [allTypes],
    );

    const categoryOptions = useMemo(() => {
        const type = allTypes.find((type) => type.id === selectedType.id);
        if (type)
            return type.categories
                .filter((filteredCategory) => {
                    return filteredCategory.id !== category.id;
                })
                .map((category) => ({
                    value: category.name,
                    id: category.id,
                }));
        else return [];
    }, [allTypes, category.id, selectedType.id]);

    const handleDeleteClick = () => {
        setOpenDeleteModal(true);
        if (!disableDeleteCategory && !ticketCountOpen && !ticketCountClosed)
            deleteCategory([category.id, typeId, undefined]);
    };

    const handleCloseClick = () => {
        setSelectedType({ id: '', name: '' });
        setSelectedCategory(null);
        setOpenDeleteModal(false);

        if (isSuccess) {
            cache.invalidateQueries(TicketTypeQueryCacheKey);
            navigation.replace(getTypeLink(typeId));
        }
    };

    const handleTypeOnChange = (selectedValue: SingleValue<{ id: string; label: string } | null>) => {
        if (selectedValue) setSelectedType({ id: selectedValue?.id, name: selectedValue?.label });
    };

    const handleCategoryOnChange = (categoryId: string) => {
        setSelectedCategory(categoryId);
    };

    return (
        <Container>
            <Paragraph>{t('settings.deleteCategoryHeader')}</Paragraph>
            <PrimaryButton error onClick={handleDeleteClick}>
                {t('settings.deleteCategoryDeleteButton')}
            </PrimaryButton>
            {openDeleteModal && (
                <StyledModal
                    key={`deleteTypeModal${category.id}`}
                    type={ModalType.Small}
                    title={
                        disableDeleteCategory
                            ? t('settings.deleteCategoryModalTitleCantDelete')
                            : ticketCountOpen + ticketCountClosed && !isSuccess
                            ? t('settings.deleteCategoryModalTitleCategoryName', { categoryName: category.name })
                            : t('settings.deleteCategoryModalDeleted', { categoryName: category.name })
                    }
                    onClose={handleCloseClick}
                    open={openDeleteModal}
                    hideCloseButton={false}
                    bottomContent={
                        !(ticketCountOpen + ticketCountClosed) || isSuccess ? (
                            <FooterWrapper>
                                <PrimaryButton onClick={handleCloseClick}>{tCommon('closeButton')}</PrimaryButton>
                            </FooterWrapper>
                        ) : (
                            <FooterWrapper>
                                <StyledGhostPrimaryButton onClick={() => setOpenDeleteModal(false)}>
                                    {tCommon('cancelButton')}
                                </StyledGhostPrimaryButton>
                                <PrimaryButton
                                    onClick={() => {
                                        deleteCategory([
                                            category.id,
                                            typeId,
                                            { newTypeId: selectedType.id, newCategoryId: selectedCategory as string },
                                        ]);
                                    }}
                                    disabled={!selectedType || !selectedCategory}
                                >
                                    {tCommon('next')}
                                </PrimaryButton>
                            </FooterWrapper>
                        )
                    }
                >
                    <ModalContentWrapper>
                        {isLoading ? (
                            <LoaderContainer>
                                <FullPageLoader text="" />
                            </LoaderContainer>
                        ) : ticketCountOpen + ticketCountClosed && !isSuccess ? (
                            <>
                                <Paragraph>
                                    {t('settings.countOpenAndClosedInquiries', { ticketCountOpen, ticketCountClosed })}
                                </Paragraph>
                                <Paragraph>{t('settings.chooseWhichCategoryShouldBeMovedTo')}</Paragraph>
                                <StyledInputSelect
                                    options={typeOptions}
                                    isClearable={true}
                                    onChange={handleTypeOnChange}
                                    label=""
                                    placeholder={t('commonTicketing.changeTypeModalSearchByTypePlaceholder')}
                                    id="newTypeDelete"
                                    name="newTypeDelete"
                                    showSearchIcon
                                />
                                <StyledDropdown
                                    items={categoryOptions}
                                    onChange={handleCategoryOnChange}
                                    label=""
                                    required={true}
                                    placeholder={t('commonTicketing.categoryLabel')}
                                    readOnly={!selectedType.id}
                                />
                            </>
                        ) : (
                            <Paragraph>
                                {disableDeleteCategory && t('settings.disableDeleteCategoryMessage')}
                                {isSuccess && t('settings.successDeletedCategory')}
                                {isError && t('settings.errorDeletedCategory')}
                            </Paragraph>
                        )}
                    </ModalContentWrapper>
                </StyledModal>
            )}
        </Container>
    );
};

export default DeleteCategoryModal;
