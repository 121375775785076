import React from 'react';
import type { ButtonProps } from 'semantic-ui-react';
import { Button, Icon, Radio } from 'semantic-ui-react';
import styled from 'styled-components';
import { MyBooking } from '../../api/apiTypes/bookingApiTypes';
import { usePortalConfig } from '../../common/apiQueries/useAppConfiguration';
import { IBookingsAPI } from '../../modules/Meeting/types';
import { getTimeFromDate } from '../../utility/dateTimeHelper';
import ErrorMessage from '../error/ErrorMessage';

interface ContainerProps extends ButtonProps {
    isError: boolean;
}
const Container = styled(Button)`
    &&& {
        background: none;
        border: 1px solid
            ${({ isError }: ContainerProps) => (isError ? 'var(--error-color-light)' : 'var(--border-color)')};
        border-radius: 5px;

        padding: calc(var(--spacer) * 2);
        margin: calc(var(--spacer) * 2) 0;
        width: 100%;
        opacity: 1 !important;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`;

const SectionDate = styled.h4`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--primary-color);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    text-transform: uppercase;
    color: var(--primary-on-color);
    padding: calc(var(--spacer) * 1.5) 0;
    width: 5.3rem;
    margin: 0;
`;

const SectionContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: calc(var(--spacer) * 2);
    margin-top: var(--spacer);
    width: 100%;
    align-items: flex-start;
`;

const DateDetail = styled.span`
    font-size: 0.85rem;
    font-weight: 900;
`;

const Date = styled.span`
    font-size: 2.2rem;
    margin-top: var(--spacer);
    margin-bottom: var(--spacer);
`;

const Time = styled.h4`
    font-size: 0.85rem;
    font-weight: 500;
    color: rgba(38, 50, 56, 0.6);
    letter-spacing: 1px;
    margin: 0;
`;

const Title = styled.h3`
    font-size: 1.7rem;
    line-height: 1.8rem;
    color: rgba(38, 50, 56, 0.9);
    margin-top: var(--spacer);
    margin-bottom: calc(var(--spacer) * 2);
    text-align: left;
`;

const Info = styled.p<{ addPxWith: boolean }>`
    color: rgba(38, 50, 56, 0.6);
    font-size: 0.75rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    max-width: 25rem;
    justify-content: space-between;
    text-align: left;

    /* 17px is radio width*/
    width: calc(100% + ${({ addPxWith }) => (addPxWith ? 17 : 0)}px);
`;

interface ExistingMeetingCardProps {
    onClick?: () => void;
    meeting: IBookingsAPI | MyBooking;
    showOrganizerName?: boolean;
    toggleable?: boolean;
    toggled?: boolean;
    error?: string;
}

const ExistingMeetingCard: React.FC<React.PropsWithChildren<ExistingMeetingCardProps>> = ({
    onClick,
    meeting,
    showOrganizerName = false,
    toggleable = false,
    toggled = false,
    error = '',
}: ExistingMeetingCardProps) => {
    const { name: portalName } = usePortalConfig();
    const dayString = meeting.startTime.toLocaleDateString('no-NB', {
        weekday: 'short',
    });
    const monthString = meeting.startTime.toLocaleDateString('no-NB', {
        month: 'short',
    });

    const day = dayString.charAt(0).toUpperCase() + dayString.substring(1).replace('.', '');
    const month = monthString.charAt(0).toUpperCase() + monthString.substring(1);
    const date = meeting.startTime.getDate();
    const from = getTimeFromDate(meeting.startTime);
    const to = getTimeFromDate(meeting.endTime);

    return (
        <Container
            type="button"
            data-meetingid={meeting.id}
            onClick={onClick}
            as={onClick ? '' : 'div'}
            isError={error !== ''}
            disabled={onClick && error}
        >
            <InfoContainer>
                <SectionDate>
                    <DateDetail>{day}</DateDetail>
                    <Date>{date}</Date>
                    <DateDetail>{month}</DateDetail>
                </SectionDate>
                <SectionContent>
                    <Time>{`${from}-${to} ${showOrganizerName ? ' - ' + meeting.organizer?.name : ''}`}</Time>
                    <Title>{meeting.title}</Title>
                    <Info addPxWith={toggleable}>
                        <span>
                            <Icon name="target" />
                            {meeting.room.name}
                        </span>
                        <span>
                            <Icon name="building" />
                            {meeting.room.buildingName}
                        </span>
                        <span>
                            <Icon name="map marker alternate" />
                            {portalName}
                        </span>
                    </Info>
                </SectionContent>
                {toggleable ? <Radio checked={toggled}></Radio> : null}
            </InfoContainer>
            {error ? <ErrorMessage message={error} /> : null}
        </Container>
    );
};

export default ExistingMeetingCard;
