import { useEffect, useState } from 'react';

/** Use debounce hook
 * @param {ValueType} value - the value to debounce
 * @param {number} delay - in ms for debouncing
 * @returns {ValueType} either the value or the previous value before debounce
 * */
const useDebounce = <ValueType>(value: ValueType, delay: number): ValueType => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

export default useDebounce;
