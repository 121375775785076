import axios, { AxiosError } from 'axios';
import AuthorizeService from '../../common/Authorization/AuthorizeService';
import logger from './logService';

const cancelToken = axios.CancelToken;
let source = cancelToken.source();
const setSource = () => (source = cancelToken.source());

const cancel = (): void => {
    source.cancel('Request cancelled by user');
    setSource();
};

axios.interceptors.response.use(undefined, (error: AxiosError) => {
    const expectedError =
        (error.response && error.response.status >= 400 && error.response.status < 500) || axios.isCancel(error);

    if (!expectedError) logger.log(error);

    return Promise.reject(error);
});

axios.interceptors.request.use(async (config) => {
    // We default to use credentials but it can be disabled
    if (config.withCredentials === false) return config;
    const token = await AuthorizeService.getAccessToken();
    if (config.headers) config.headers['Authorization'] = `Bearer ${token}`;
    return { ...config, cancelToken: source.token, withCredentials: undefined };
});

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
    cancel,
};
