import { ReactElement, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Responsive, Icon, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { CurrentUserContext } from '../../../Providers';
import { IRootStateProps } from '../../../reducers';
import { ModuleName } from '../../api/apiTypes/portalApiTypes';
import { Avatar } from '../../components';
import { isWifiAtWork } from '../../helpers/isService';
import { ShoppingCart } from '../../modules/Food/ShoppingCart';
import { SetWifiPasswordNotification } from '../../modules/WifiAtWork/index';
import { useModuleIsActive, SidesheetContext } from '../../utility';
import { SidesheetActionNames } from '../../utility/Sidesheet/context/types';
import { usePortalConfig } from '../apiQueries/useAppConfiguration';
import { Help } from '../Help';
import MyProfile from '../MyProfile';
import Notifications from '../Notifications/Notifications';
import { MobileViewBreakpoint } from '../ScreenSizeBreakPoints';
import { sidebarOperations } from '../Sidebar/duck';
import { getNavBarHeader } from './utils';
import './navbar.css';

const ToggleContainer = styled.div`
    display: flex;
    margin-left: 1.5em;
`;

const NavHeaderContainer = styled.div`
    flex: 1;
    margin-left: 1.5em;
`;

const NavItemsContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 1.75em;

    & button {
        &:focus-visible {
            outline: 1px solid var(--primary-color);
            border-radius: 0.5rem;
        }
    }
`;

const NavBarHeader = styled.h1`
    color: #263238e5;
    font-size: 1.143rem;
    line-height: 1.143rem;
    letter-spacing: 0.014rem;
`;

const StyledAvatar = styled(Avatar)`
    :hover {
        filter: brightness(60%);
    }
`;

const NavBar = (): ReactElement => {
    const {
        app: { services },
        sidebar: { isSidebarVisible },
    } = useSelector((state: IRootStateProps) => state);
    const dispatch = useDispatch();
    const state = useContext(CurrentUserContext)?.state;
    const location = useLocation();
    const { name: portalName } = usePortalConfig();
    const { dispatch: sideSheetDispatch } = useContext(SidesheetContext);
    const wifiAtWorkModuleIsActivated = useModuleIsActive(ModuleName.WifiAtWork);
    const wifiAtWorkIsActivated = !!(wifiAtWorkModuleIsActivated && services.find(isWifiAtWork));
    const { t } = useTranslation('common');

    useEffect(() => {
        const setDocumentTitle = () => {
            const title = getNavBarHeader(location.pathname, t);
            document.title = `${title} - ${portalName ? portalName : 'Life@Work'}`;
        };
        setDocumentTitle();
    }, [location]);

    const handleToggleSidebar = () => {
        if (isSidebarVisible) {
            dispatch(sidebarOperations.handleHideSidebar());
        } else {
            dispatch(sidebarOperations.handleShowSidebar());
        }
    };

    const handleOnAvatarClick = () => {
        sideSheetDispatch({
            type: SidesheetActionNames.OpenSidesheet,
            payload: {
                hideCloseIcon: true,
                component: <MyProfile />,
            },
        });
    };

    const renderAvatar = () => {
        return (
            <Button
                style={{
                    backgroundColor: 'transparent',
                    padding: 0,
                    margin: 0,
                }}
                onClick={handleOnAvatarClick}
            >
                <StyledAvatar
                    image={state?.currentUser?.avatar}
                    firstName={state?.currentUser?.firstName ?? ''}
                    lastName={state?.currentUser?.lastName ?? ''}
                />
            </Button>
        );
    };

    const navbarHeader = useMemo(() => getNavBarHeader(location.pathname, t), [location.pathname]);
    const renderDesktop = () => {
        return (
            <>
                <ToggleContainer>
                    <Icon name="bars" link onClick={handleToggleSidebar} />
                </ToggleContainer>
                <NavHeaderContainer id="navBarHeader">
                    {navbarHeader ? <NavBarHeader>{navbarHeader}</NavBarHeader> : null}
                </NavHeaderContainer>
                <div className="navbar-icons-container">
                    <NavItemsContainer>
                        <ShoppingCart />
                        <Help />
                        <Notifications />
                        {renderAvatar()}
                    </NavItemsContainer>
                </div>
            </>
        );
    };

    return (
        <>
            <Responsive minWidth={MobileViewBreakpoint} className="navbar-container" fireOnMount>
                {renderDesktop()}
            </Responsive>
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="navbar-container mobile" fireOnMount>
                {renderDesktop()}
            </Responsive>
            {wifiAtWorkIsActivated ? <SetWifiPasswordNotification /> : null}
        </>
    );
};

export default NavBar;
