import { format, parseISO } from 'date-fns';
import i18n from 'i18next';

export const getOrderTimeInStringFormat = (date: Date): string => {
    // Get the current date and time
    const now = date || new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    // Set the order time
    const orderTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);

    // Format the date and time
    const formattedDate = `${String(orderTime.getDate()).padStart(2, '0')}/${String(orderTime.getMonth() + 1).padStart(
        2,
        '0',
    )}/${String(orderTime.getFullYear()).slice(-2)}`;
    const formattedTime = `${String(orderTime.getHours()).padStart(2, '0')}:${String(orderTime.getMinutes()).padStart(
        2,
        '0',
    )}`;

    // Return the order time and date in the specified format
    return `${formattedDate}, ${formattedTime}`;
};

export const getShortDayName = (dateString: Date): string => {
    const daysEN = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const daysNO = ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'];
    const currentLanguage = i18n.language;
    const days = currentLanguage === 'en' ? daysEN : daysNO;

    const date = new Date(dateString);
    const dayIndex = date.getUTCDay();

    return days[dayIndex];
};

export const getShortMonthName = (dateString: Date): string => {
    const monthsEN = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthsNO = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'];

    const currentLanguage = i18n.language;

    const months = currentLanguage === 'en' ? monthsEN : monthsNO;

    const date = new Date(dateString);
    const monthIndex = date.getUTCMonth();

    return months[monthIndex];
};

export const getDayOfMonth = (dateString: Date): number => {
    const date = new Date(dateString);
    return date.getUTCDate();
};

export const getTimeInHour = (dateString: Date): string => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
};

export const formatDateToOrderDetails = (dateString?: string): string | undefined => {
    if (!dateString) return;
    const date = parseISO(dateString);
    return format(date, 'dd MMM yyyy HH:mm');
};
