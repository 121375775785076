import { forwardRef, ReactElement } from 'react';
import NumericFormat from 'react-number-format';
import styled from 'styled-components';
import FieldErrorMessage from '../../FieldErrorMessage';
import Label from '../../Label';

interface InputFieldElementProps {
    error?: boolean;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const LabelContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 0.5rem;
    justify-content: space-between;
`;

const StatusField = styled.p`
    font-size: 1.167rem;
    line-height: 1.833rem;
    margin: 0;
    color: var(--text-placeholder-color);
`;

const InputFieldElement = styled.input`
    background: var(--surface-color-light);
    border: 1px solid var(--border-color);
    border-radius: 0.35rem;
    padding: 0.7rem 1.14rem;
    resize: vertical;

    :active {
        outline-color: black;
    }

    :focus {
        outline-color: var(--primary-color);
    }

    border-color: ${({ error }: InputFieldElementProps) => (error ? 'var(--error-color)' : '')};
    cursor: auto;
`;

interface NumberInputInterface {
    placeholder: string;
    label: string;
    required: boolean;
    name: string;
    id?: string;
    value?: number;
    thousandSeparator?: string;
    onChange: (value: number) => void;
    readOnly?: boolean;
    isError?: boolean;
    allowNegative?: boolean;
    suffix?: string;
    prefix?: string;
    min?: string | number;
    max?: string | number;
    statusText?: string;
    error?: string;
}

export const NumberInput = forwardRef(
    (
        {
            placeholder,
            label,
            required,
            name,
            error,
            statusText,
            onChange,
            min = -Infinity,
            max = Infinity,
            value,
            id,
            suffix = '',
            prefix = '',
            thousandSeparator,
            allowNegative = false,
            isError = false,
            readOnly = false,
        }: NumberInputInterface,
        ref,
    ): ReactElement => {
        return (
            <Container>
                <LabelContainer>
                    <Label htmlFor={name} isError={isError} disable={readOnly}>{`${label}`}</Label>
                    {statusText && <StatusField>{statusText}</StatusField>}
                </LabelContainer>
                <NumericFormat
                    required={required}
                    customInput={InputFieldElement}
                    prefix={prefix}
                    suffix={suffix}
                    thousandSeparator={thousandSeparator}
                    getInputRef={ref}
                    min={0}
                    max={100}
                    placeholder={placeholder}
                    inputMode="numeric"
                    displayType="input"
                    autoComplete="false"
                    value={value}
                    isAllowed={(values) => {
                        const { value: targetValue } = values;
                        return targetValue <= max && targetValue >= min;
                    }}
                    allowNegative={allowNegative}
                    onValueChange={({ floatValue }) => {
                        if (typeof floatValue !== 'undefined') {
                            onChange(floatValue);
                        } else {
                            onChange(0);
                        }
                    }}
                />
                {isError ? <FieldErrorMessage id={`${id ?? name}-errorMessage`}>{error}</FieldErrorMessage> : null}
            </Container>
        );
    },
);
