import { AxiosError, AxiosResponse } from 'axios';
import type { InfiniteQueryResult, MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQueryCache, useInfiniteQuery, useQuery } from 'react-query';
import { NotificationDto, NotificationStatus } from '../../../api/apiTypes/msgApiTypes';
import logService from '../../../api/infrastructure/logService';
import {
    getAllNotifications,
    getUnseenNotificationsCount,
    markAllSentNotificationsAsSeen,
    markNotifications,
} from '../../../api/msgApi';

export const NotificationsCacheKey = 'NotificationsCacheKey';
export const NotificationsUnseenCountCacheKey = 'NotificationsUnseenCountCacheKey';

export const useGetInfiniteNotifications = (
    languageId: string,
): InfiniteQueryResult<NotificationDto[], AxiosError<string> | Error> => {
    const numberOfNotifications = 20;

    return useInfiniteQuery<NotificationDto[], AxiosError<string> | Error>(
        [NotificationsCacheKey, languageId],
        async (_, languageId, page): Promise<NotificationDto[]> => {
            const result = await getAllNotifications(languageId, { skip: page ?? 0, take: numberOfNotifications });
            return result.data;
        },
        {
            getFetchMore: (lastPage, allPages) => {
                if (lastPage.length !== numberOfNotifications) {
                    return false;
                }
                return allPages.length;
            },
            refetchInterval: 1000 * 60,
            onError: (error) => logService.log(new Error('Failed to fetch notifications', { cause: error })),
        },
    );
};

export const useUpdateNotificationStatuses = (
    languageId: string,
): MutationResultPair<
    AxiosResponse<NotificationDto[]>,
    Error | AxiosError<string>,
    [string[], NotificationStatus],
    never
> => {
    const queryClient = useQueryCache();
    return useMutation<
        AxiosResponse<NotificationDto[]>,
        Error | AxiosError<string>,
        [string[], NotificationStatus],
        never
    >(
        async ([notificationIds, status]) => {
            return markNotifications(notificationIds, status);
        },
        {
            onSuccess: ({ data: updatedNotifications }) => {
                queryClient.setQueryData<NotificationDto[] | undefined>(
                    [NotificationsCacheKey, languageId],
                    (oldNotifications) =>
                        (
                            oldNotifications?.map((notification) => {
                                if (
                                    updatedNotifications?.some(
                                        (updatedNotification) => updatedNotification.id === notification.id,
                                    )
                                ) {
                                    return updatedNotifications.find(
                                        (updatedNotification) => updatedNotification.id === notification.id,
                                    );
                                }
                                return notification;
                            }) ?? []
                        ).filter((notification) => notification !== undefined) as NotificationDto[],
                );
                queryClient.invalidateQueries([NotificationsCacheKey, languageId]);
            },
            onError: (error) => logService.log(new Error('Failed to update notification statuses', { cause: error })),
        },
    );
};

export const useGetUnseenNotificationsCount = (): QueryResult<number> => {
    return useQuery(
        [NotificationsUnseenCountCacheKey],
        async () => {
            const result = await getUnseenNotificationsCount();
            return result.data;
        },
        {
            onError: (error) =>
                logService.log(new Error('Failed to fetch unseen notifications count', { cause: error })),
            refetchInterval: 1000 * 60,
        },
    );
};

export const useMarkAllSentNotificationsAsSeen = (): MutationResultPair<
    AxiosResponse<undefined>,
    AxiosError<string>,
    never,
    never
> => {
    const queryClient = useQueryCache();
    return useMutation(markAllSentNotificationsAsSeen, {
        onSuccess: () => {
            queryClient.invalidateQueries([NotificationsUnseenCountCacheKey]);
        },
        onError: (error) => logService.log(new Error('Failed to mark notifications as seen', { cause: error })),
    });
};
