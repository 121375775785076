import { differenceInMonths, differenceInDays, differenceInWeeks, differenceInYears } from 'date-fns/esm';
import { useTranslation } from 'react-i18next';

export const getUpdatedSinceString = (compareDate: Date): string => {
    const { t } = useTranslation('Dashboard');
    const differenceDays = differenceInDays(new Date(), new Date(compareDate));
    const differenceWeeks = differenceInWeeks(new Date(), new Date(compareDate));
    const differenceMonths = differenceInMonths(new Date(), new Date(compareDate));
    const differenceYears = differenceInYears(new Date(), new Date(compareDate));

    const durationSincePublishedInDays = {
        value: differenceDays,
        string: differenceDays > 1 ? `${differenceDays} ${t('articles.days')}` : t('articles.oneDay'),
    };
    const durationSincePublishedInWeeks = {
        value: differenceWeeks,
        string: differenceWeeks > 1 ? t('articles.overDifferenceWeeks', { differenceWeeks }) : t('articles.overWeek'),
    };
    const durationSincePublishedInMonths = {
        value: differenceMonths,
        string:
            differenceMonths > 1 ? t('articles.overDifferenceMonth', { differenceMonths }) : t('articles.overMonth'),
    };
    const durationSincePublishedInYears = {
        value: differenceYears,
        string: t('articles.overOneYear'),
    };

    const durationArray = [
        durationSincePublishedInYears,
        durationSincePublishedInMonths,
        durationSincePublishedInWeeks,
        durationSincePublishedInDays,
    ];

    let first = ` ${t('articles.updatedToday')}`;
    for (let index = 0; index < durationArray.length; index++) {
        if (durationArray[index].value > 0) {
            first = ` ${t('articles.updatedForSince', { durationInfo: durationArray[index].string })}`;
            break;
        }
    }

    return first;
};
