import { AxiosPromise, AxiosResponse } from 'axios';
import qs from 'qs';
import {
    IHelpArticle,
    ArticleDto,
    ArticleModel,
    GetArticlesStatusOptions,
    CategoryModel,
    CreateCategoryDto,
    SubcategoryDto,
    GetSubcategoryModel,
    UpdateCategoryDto,
    ContentConfigModel,
    SortingDateType,
    ImageModel,
    PortalContentConfigCoverImage,
} from './apiTypes/cmsApiTypes';
import { RolePhrase } from './apiTypes/portalApiTypes';
import http from './infrastructure/httpService';
import { getBaseUrlApim, getPortalId } from './utils';

const urlCms = process.env.REACT_APP_APIM_CMS_URL;
const portalApiUrl = process.env.REACT_APP_APIM_PORTAL_URL;
const time_format = 'yyyy-MM-dd';

export function getAllHelpArticles(): Promise<AxiosResponse<IHelpArticle[]>> {
    const url = `${getBaseUrlApim()}/${urlCms}/help-pages`;
    return http.get<IHelpArticle[]>(url);
}

export function getArticle(articleId: string, drafts?: boolean): Promise<AxiosResponse<ArticleModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/articles`;
    const articleIdParam = articleId ? `/${articleId}` : '';

    return http.get<ArticleDto, AxiosResponse<ArticleModel>>(`${url}${articleIdParam}`, {
        params: {
            portalId: getPortalId(),
            draft: drafts,
        },
    });
}

export function getArticles(
    drafts: boolean,
    prioritizeFeatured = false,
    numberOfArticles = 100,
    roles: string[],
    start?: number,
    searchTerm?: string,
    sortBy?: SortingDateType.New | SortingDateType.Old,
    dateSearch?: { fromDate: Date | undefined; toDate: Date | undefined },
    status?: GetArticlesStatusOptions,
    category?: number,
    subcategory?: number,
): Promise<AxiosResponse<ArticleModel[]>> {
    const url = `${getBaseUrlApim()}/${urlCms}/articles`;
    const searchQuery = qs.stringify({
        _where: drafts
            ? [{ updated_gte: dateSearch?.fromDate }, { updated_lte: dateSearch?.toDate }]
            : [{ published_at_gte: dateSearch?.fromDate }, { published_at_lte: dateSearch?.toDate }],
    });
    const sortDirection = sortBy === SortingDateType.New ? 'DESC' : 'ASC';

    const sortByQuery = [
        prioritizeFeatured ? 'isFeatured:DESC' : '',
        status === 'published' ? `publishedAt:${sortDirection}` : '',
        `updated:${sortDirection}`,
    ]
        .filter(Boolean)
        .join(',');

    return http.get<ArticleDto, AxiosResponse<ArticleModel[]>>(`${url}?${searchQuery}`, {
        params: {
            portalId: getPortalId(),
            draft: drafts,
            _sort: sortByQuery,
            _limit: numberOfArticles,
            _start: start,
            status: status,
            search: searchTerm,
            subcategory: subcategory,
            'subcategory.parent': category,
            roles,
        },
    });
}

export function createArticle(article: ArticleDto, draft: boolean): Promise<AxiosResponse<ArticleModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/articles`;
    return http.post<ArticleDto, AxiosResponse<ArticleModel>>(url, article, {
        params: { portalId: getPortalId(), draft },
    });
}

export function updateArticle(
    articleId: string,
    article: ArticleDto,
    oldUpdatedAt: Date,
    draft: boolean,
): Promise<AxiosResponse<ArticleModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/articles/${articleId}`;
    return http.put<ArticleDto, AxiosResponse<ArticleModel>>(url, article, {
        params: { portalId: getPortalId(), oldUpdatedAt, draft },
    });
}

export function deleteArticle(
    articleId: string,
    draft: boolean,
    oldUpdatedAt: Date,
): Promise<AxiosResponse<ArticleModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/articles/${articleId}`;
    return http.delete<ArticleDto, AxiosResponse<ArticleModel>>(url, {
        params: { portalId: getPortalId(), draft, oldUpdatedAt },
    });
}

export function getCategories(count?: boolean, isPinToSidebar?: boolean): Promise<AxiosResponse<CategoryModel[]>> {
    const url = `${getBaseUrlApim()}/${urlCms}/article-categories`;
    return http.get<never, AxiosResponse<CategoryModel[]>>(url, {
        params: { portalId: getPortalId(), count, _limit: 1000, _sort: 'name:ASC', isPinToSidebar },
    });
}

export function createCategory(data: CreateCategoryDto): Promise<AxiosResponse<CategoryModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/article-categories`;
    return http.post<CreateCategoryDto, AxiosResponse<CategoryModel>>(url, data, {
        params: { portalId: getPortalId() },
    });
}

export function updateCategory(id: string, data: UpdateCategoryDto): Promise<AxiosResponse<CategoryModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/article-categories/${id}`;
    return http.put<UpdateCategoryDto, AxiosResponse<CategoryModel>>(url, data, {
        params: { portalId: getPortalId() },
    });
}

export function manageCategoryInSideNav(
    categoryId: string | number,
    isPinToSidebar: boolean,
): Promise<AxiosResponse<CategoryModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/article-categories/${categoryId}`;
    return http.patch<UpdateCategoryDto, AxiosResponse<CategoryModel>>(
        url,
        {
            isPinToSidebar,
        },
        {
            params: { portalId: getPortalId() },
        },
    );
}

export function createSubcategory(data: SubcategoryDto, parentId: string): Promise<AxiosResponse<GetSubcategoryModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/article-subcategories`;
    return http.post<SubcategoryDto, AxiosResponse<GetSubcategoryModel>>(url, data, {
        params: { portalId: getPortalId(), articleCategoryId: parentId },
    });
}

export function updateSubcategory(id: string, data: SubcategoryDto): Promise<AxiosResponse<GetSubcategoryModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/article-subcategories/${id}`;
    return http.put<SubcategoryDto, AxiosResponse<GetSubcategoryModel>>(url, data, {
        params: { portalId: getPortalId() },
    });
}

export function deleteCategory(categoryId: string): Promise<AxiosResponse<CategoryModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/article-categories/${categoryId}`;
    return http.delete<CategoryModel, AxiosResponse<CategoryModel>>(url, {
        params: { portalId: getPortalId(), articleCategoryId: categoryId },
    });
}

export function deleteSubcategory(subcategoryId: string): Promise<AxiosResponse<CategoryModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/article-subcategories/${subcategoryId}`;
    return http.delete<CategoryModel, AxiosResponse<CategoryModel>>(url, {
        params: { portalId: getPortalId(), articleSubcategoryId: subcategoryId },
    });
}

export function getPortalContentConfig(): Promise<AxiosResponse<ContentConfigModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/portal/${getPortalId()}/portal-content-config`;
    return http.get(url, { params: { portalId: getPortalId() } });
}

export const getAllRoles = (): AxiosPromise<RolePhrase[]> => {
    const url = `${getBaseUrlApim()}/${portalApiUrl}/adm/portals/${getPortalId()}/roles/all`;
    return http.get(url);
};

export function uploadImage(image: FormData): Promise<AxiosResponse<ImageModel[]>> {
    const url = `${getBaseUrlApim()}/${urlCms}/upload`;
    return http.post<FormData, AxiosResponse<ImageModel[]>>(url, image, {
        params: { portalId: getPortalId() },
    });
}

export function updatePortalContentMenuCoverImage(
    image: null | ImageModel,
): Promise<AxiosResponse<ContentConfigModel>> {
    const url = `${getBaseUrlApim()}/${urlCms}/portal/${getPortalId()}/portal-content-config/foodMenuCoverImage`;
    return http.put<PortalContentConfigCoverImage, AxiosResponse<ContentConfigModel>>(
        url,
        { foodMenuCoverImage: image },
        {
            params: { portalId: getPortalId() },
        },
    );
}
