import { Dispatch } from 'react';
import { Profile } from '../../api/apiTypes/portalApiTypes';
import {
    deleteSelf,
    updateProfile as updateProfileApi,
    updatePinCode as updatePinCodeApi,
    uploadAvatar as uploadAvatarApi,
} from '../../api/portalApi';

export const updateProfile = async (data: Profile, dispatch: Dispatch<any>): Promise<any> => {
    try {
        dispatch({ type: 'UPDATE_PROFILE_REQUESTED' });

        await updateProfileApi({ ...data });
        dispatch({ type: 'UPDATE_PROFILE_SUCCEEDED' });
    } catch (err) {
        dispatch({ type: 'UPDATE_PROFILE_FAILED', payload: err });
        // TODO: Throw error so that ErrorBoundary can catch it, and redirect to login ex. if it's a 401-error.
    }
};

export const updatePinCode = async (pinCode: string): Promise<any> => {
    await updatePinCodeApi({ pincode: pinCode });
};

export const deleteSelfPost = async (): Promise<any> => {
    const response = await deleteSelf();
    return response.data;
};

export const uploadAvatar = async (image: string | Blob): Promise<any> => {
    const formData = new FormData();
    formData.append('file', image);

    return await uploadAvatarApi(formData);
};
