import { createContext, Dispatch, ReactElement, useEffect, useReducer } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { TicketSortDirection, TicketSortingType, TicketStatus } from '../../api/apiTypes/ticketingApiTypes';
import { Loader, Perm } from '../../common';
import { OptionType } from '../../components/select/searchSelect/types';
import { useUserHasPerm } from '../../utility';
import { useUserHasTicketingAccess, useUserIsCaseWorker, useUserIsCompanyAdmin } from './apiQueries/useTicketingUser';
import CompanyTickets from './CompanyTickets/CompanyTickets';
import { TicketListView } from './components/UserTickets/UserTickets';
import MyTickets from './MyTickets/MyTickets';
import NewTicket from './NewTicket';
import Settings from './Settings/SettingsComponents';
import useTicketingNavigation from './useTicketingNavigation';
import WorkList from './Worklist/Worklist';

type FromTicketNumber = string | null;

type TicketViewHistoryEntry = string;

type AddTicketViewHistory = {
    type: 'ADD_TICKET_VIEW_HISTORY';
    payload: TicketViewHistoryEntry;
};
type PopTicketViewHistory = { type: 'POP_TICKET_VIEW_HISTORY' };
type DeleteTicketViewHistory = { type: 'DELETE_TICKET_VIEW_HISTORY' };

type UpdateUserCanEditTicket = { type: 'UPDATE_USER_CAN_EDIT_TICKET'; payload: boolean };
type UpdateFromTicketNumber = { type: 'UPDATE_FROM_TICKET_NUMBER'; payload: FromTicketNumber };
type UpdateTicketSortingType = {
    type: 'UPDATE_TICKET_SORTING_TYPE';
    payload: { sortingType: TicketSortingType; view: TicketListView };
};
type UpdateTicketSortingDirection = {
    type: 'UPDATE_TICKET_SORTING_DIRECTION';
    payload: { sortingDirection: TicketSortDirection; view: TicketListView };
};
type UpdateTicketView = {
    type: 'UPDATE_TICKET_LIST_VIEW';
    payload: TicketListView;
};
type UpdateReporteeId = {
    type: 'UPDATE_REPORTEE_ID';
    payload: string;
};

type UpdateTicketSearchTerm = {
    type: 'UPDATE_TICKET_SEARCH_TERM';
    payload: string;
};

type UpdateTicketFilters = {
    type: 'UPDATE_TICKET_FILTERS';
    payload: { [key: string]: OptionType[] | [] | TicketListView };
};

type TicketingAction =
    | UpdateUserCanEditTicket
    | UpdateFromTicketNumber
    | UpdateTicketSortingType
    | UpdateTicketSortingDirection
    | UpdateTicketView
    | UpdateReporteeId
    | UpdateTicketSearchTerm
    | UpdateTicketFilters
    | AddTicketViewHistory
    | PopTicketViewHistory
    | DeleteTicketViewHistory;

export interface FiltersType {
    companies: OptionType[] | [];
    assignees: OptionType[] | [];
    categories: OptionType[] | [];
    statuses: OptionType[] | [];
    priorities: OptionType[] | [];
}
export interface TicketingFilters extends FiltersType {
    sortingType: TicketSortingType;
    sortingDirection: TicketSortDirection;
}

export interface TicketingState {
    caseWorkerCanEditTicket: boolean;
    fromTicketNumber: FromTicketNumber;
    myTicket: TicketingFilters;
    worklist: TicketingFilters;
    caseworker: TicketingFilters;
    companyTickets: TicketingFilters;
    ticketListView: TicketListView | null;
    reporteeId: string;
    searchTerm: string;
    ticketViewHistory: TicketViewHistoryEntry[];
}

const reducer = (state: TicketingState, action: TicketingAction): TicketingState => {
    const setFilter = (action: UpdateTicketSortingDirection | UpdateTicketSortingType | UpdateTicketFilters) => {
        const { view, ...filters } = action.payload;
        switch (view) {
            case TicketListView.MyTickets:
                return { myTicket: { ...state.myTicket, ...filters } };
            case TicketListView.Worklist:
                return { worklist: { ...state.worklist, ...filters } };
            case TicketListView.Caseworker:
                return { caseworker: { ...state.caseworker, ...filters } };
            case TicketListView.CompanyTickets:
                return { companyTickets: { ...state.companyTickets, ...filters } };
        }
    };

    switch (action.type) {
        case 'ADD_TICKET_VIEW_HISTORY':
            const previous = state.ticketViewHistory[0];
            return {
                ...state,
                ticketViewHistory: previous ? [action.payload, previous] : [action.payload],
            };
        case 'POP_TICKET_VIEW_HISTORY': {
            const history = state.ticketViewHistory.slice(1);
            return {
                ...state,
                ticketViewHistory: [...history],
            };
        }
        case 'DELETE_TICKET_VIEW_HISTORY':
            return {
                ...state,
                ticketViewHistory: [],
            };
        case 'UPDATE_USER_CAN_EDIT_TICKET':
            return {
                ...state,
                caseWorkerCanEditTicket: action.payload,
            };
        case 'UPDATE_FROM_TICKET_NUMBER':
            return {
                ...state,
                fromTicketNumber: action.payload,
            };
        case 'UPDATE_TICKET_SORTING_TYPE':
            return {
                ...state,
                ...setFilter(action),
            };
        case 'UPDATE_TICKET_SORTING_DIRECTION':
            return {
                ...state,
                ...setFilter(action),
            };
        case 'UPDATE_TICKET_LIST_VIEW':
            const ticketListView = action.payload;
            return {
                ...state,
                ticketListView,
                searchTerm: ticketListView !== state.ticketListView ? '' : state.searchTerm,
            };
        case 'UPDATE_REPORTEE_ID':
            return {
                ...state,
                reporteeId: action.payload,
            };
        case 'UPDATE_TICKET_SEARCH_TERM':
            return {
                ...state,
                searchTerm: action.payload,
            };
        case 'UPDATE_TICKET_FILTERS':
            return {
                ...state,
                ...setFilter(action),
            };
    }
};

const defaultFilters = {
    sortingType: TicketSortingType.LastUpdate,
    sortingDirection: TicketSortDirection.Desc,
    companies: [],
    assignees: [],
    categories: [],
    statuses: [
        { value: TicketStatus.New, label: TicketStatus.New },
        { value: TicketStatus.Open, label: TicketStatus.Open },
        { value: TicketStatus.Pending, label: TicketStatus.Pending },
        { value: TicketStatus.Solved, label: TicketStatus.Solved },
    ],
    priorities: [],
};

const initialState: TicketingState = {
    caseWorkerCanEditTicket: false,
    fromTicketNumber: null,
    myTicket: defaultFilters,
    worklist: defaultFilters,
    caseworker: defaultFilters,
    companyTickets: defaultFilters,
    ticketListView: null,
    reporteeId: '',
    searchTerm: '',
    ticketViewHistory: [],
};

type TicketingContext = {
    state: TicketingState;
    dispatch: Dispatch<TicketingAction>;
};

const TicketingContext = createContext<TicketingContext>({
    state: initialState,
    dispatch: () => {
        return;
    },
});

const TicketingProvider = (): ReactElement => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        settingsLink,
        workListLink,
        newTicketModalLink,
        userTicketsLink,
        companyTicketsLink,
        getModuleName,
        noAccessRedirectionLink,
    } = useTicketingNavigation();
    const canEditTypes = useUserHasPerm(Perm.TicketingTypesUpdate);
    const { data: caseworkerCheck } = useUserIsCaseWorker(!canEditTypes);
    const { data: companyAdminCheck } = useUserIsCompanyAdmin();
    const { moduleName } = useParams<{ moduleName: string }>();
    const isUserCaseworkerOrCanEditTypes = (canEditTypes || caseworkerCheck?.isCaseWorker) ?? false;
    const { data: hasAccess, isLoading: isHasAccessLoading } = useUserHasTicketingAccess();

    useEffect(() => {
        dispatch({
            type: 'UPDATE_USER_CAN_EDIT_TICKET',
            payload: isUserCaseworkerOrCanEditTypes,
        });
    }, [caseworkerCheck, canEditTypes]);

    return (
        <TicketingContext.Provider value={{ state, dispatch }}>
            {isHasAccessLoading ? (
                <Loader />
            ) : (
                <Switch>
                    {!hasAccess && !caseworkerCheck && <Redirect to={`/:portalName${noAccessRedirectionLink}`} />}
                    {hasAccess ? (
                        <Route path={`/:portalName${newTicketModalLink}`}>
                            <NewTicket />
                        </Route>
                    ) : null}
                    {hasAccess ? (
                        <Route path={`/:portalName${userTicketsLink}`}>
                            <MyTickets />
                        </Route>
                    ) : null}
                    {hasAccess && companyAdminCheck ? (
                        <Route path={`/:portalName${companyTicketsLink}`}>
                            <CompanyTickets />
                        </Route>
                    ) : null}
                    {isUserCaseworkerOrCanEditTypes ? (
                        <>
                            <Route path={`/:portalName${settingsLink}`}>
                                <Settings />
                            </Route>
                            <Route path={`/:portalName${workListLink}`}>
                                <WorkList />
                            </Route>
                        </>
                    ) : null}
                    {moduleName === getModuleName() && <Redirect to={`/:portalName${userTicketsLink}`} />}
                </Switch>
            )}
        </TicketingContext.Provider>
    );
};

export { TicketingProvider, TicketingContext };
