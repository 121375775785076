import { useQuery } from 'react-query';
import type { QueryResult } from 'react-query';
import { SimpleCanteen, Vendor } from '../../../api/apiTypes/foodForMeetingsApiTypes';
import { CanteenWithAvailabilityParams, DeliveryLocationOptionFilters } from '../../../api/apiTypes/shopApiTypes';
import { getBuildingsWithAvailableMeetingRooms } from '../../../api/bookingApi';
import {
    getAllAvailableCanteens,
    getCanteensByVendorId,
    getDailyMenuCanteens,
    getSimpleCanteensByDefault,
} from '../../../api/foodForMeetingsApi';
import { DeliveryLocationOptions } from '../contexts/types';

export const CanteensCacheKey = 'CanteensCacheKey';
const DeliveryLocationOptionsCacheKey = 'DeliveryLocationOptionsCacheKey';
const CanteenByVendorId = 'CanteenByVendorId';
export const SimpleCanteensCacheKey = 'SimpleCanteensCacheKey';
export const SimpleCanteensWithDefaultCanteenCacheKey = 'SimpleCanteensWithDefaultCanteenCacheKey';

export const useGetAllAvailableCanteens = (
    { suggestedCanteenIds, isDelivery, orderDateTime, menuType }: CanteenWithAvailabilityParams,
    enabled?: boolean,
): QueryResult<Vendor[]> => {
    return useQuery(
        [CanteensCacheKey, suggestedCanteenIds, isDelivery, orderDateTime, menuType],
        async () => (await getAllAvailableCanteens({ suggestedCanteenIds, isDelivery, orderDateTime, menuType }))?.data,
        {
            enabled,
        },
    );
};
export const useGetCanteensByVendorId = (vendorId: string): QueryResult<Vendor[]> => {
    return useQuery([CanteenByVendorId, vendorId], async () => (await getCanteensByVendorId(vendorId))?.data, {
        enabled: !!vendorId,
    });
};

export const useGetSimpleCanteens = (): QueryResult<SimpleCanteen[]> =>
    useQuery([SimpleCanteensCacheKey], async () => (await getDailyMenuCanteens())?.data);

export const useGetSimpleCanteensByDefault = (): QueryResult<SimpleCanteen[]> =>
    useQuery([SimpleCanteensWithDefaultCanteenCacheKey], async () => (await getSimpleCanteensByDefault())?.data);

export const useGetDeliveryLocationOptions = (
    filters: DeliveryLocationOptionFilters,
    enabled?: boolean,
): QueryResult<DeliveryLocationOptions[] | [], string> => {
    return useQuery(
        [DeliveryLocationOptionsCacheKey, filters],
        async () => {
            const response = await getBuildingsWithAvailableMeetingRooms(filters);
            const buildings = response.data;
            if (typeof buildings === 'string' || !buildings) return [];

            return (
                buildings
                    ?.map((building) => {
                        return building.rooms.map((room) => {
                            return {
                                id: room.id,
                                value: room.name,
                            };
                        });
                    })
                    .flat() ?? []
            );
        },
        { staleTime: 1000 * 60, enabled },
    );
};
