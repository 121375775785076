import React, { useState, useEffect, useMemo, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import type { DropdownProps } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { selectedDropdownStatus } from '../../LocalStorageKeys/LocalStorageKeys';

const FilterContainer = styled.div`
    margin-left: auto;
`;

const StatusLabel = styled.div`
    margin: 1rem;
`;

const StatusDropdown = styled(Dropdown)`
    &&& {
        display: block;
        width: 100%;
    }
`;

export const statusOptions = [
    { key: '1', text: 'active', value: 'active' },
    { key: '2', text: 'Draft', value: 'Draft' },
    { key: '3', text: 'Aktivert', value: 'Aktivert' },
];

const StatusFilterComponent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
    const { t: tCommon } = useTranslation('common');
    useEffect(() => {
        const selectedStatusData = localStorage.getItem(selectedDropdownStatus) || '';
        if (selectedStatusData) setSelectedStatus(JSON.parse(selectedStatusData));
    }, []);

    const handleSetSelectedStatus = (status: string[]): void => {
        localStorage.setItem(selectedDropdownStatus, JSON.stringify(status));
        setSelectedStatus(status);
    };
    const handleOnChange = (e: SyntheticEvent, { value }: DropdownProps) => {
        const casted = value as string[];
        handleSetSelectedStatus(casted);
    };
    const mappedStatuses = useMemo(() => {
        if (statusOptions) {
            return statusOptions
                .sort((a, b) => (a.text.toLocaleLowerCase() > b.text.toLocaleLowerCase() ? 1 : -1))
                .map((status) => {
                    return { text: status.text, value: status.value, key: status.key };
                });
        }
    }, []);
    return (
        <FilterContainer>
            <StatusLabel>{tCommon('status')}</StatusLabel>
            <StatusDropdown
                multiple
                selection
                search
                loading={false}
                value={selectedStatus}
                options={mappedStatuses}
                placeholder={tCommon('status')}
                onChange={handleOnChange}
                aria-label={tCommon('selectStatus')}
                noResultsMessage={tCommon('noMoreStatus')}
            />
        </FilterContainer>
    );
};

export default StatusFilterComponent;
