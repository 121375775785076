export interface ProductType {
    id: string;
    name: string;
    predefinedType: number;
    imageUrl: string | null;
}

export interface ProductCategory {
    id: string;
    name: string;
    imageUrl: string | null;
    type: ProductType;
}
export enum ShopType {
    General = 'Generelt',
    Services = 'Tjenester',
    Food = 'Mat',
    Premises = 'Lokaler',
}
