import { forwardRef, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroller from 'react-infinite-scroller';
import { Divider } from 'semantic-ui-react';
import styled from 'styled-components';
import LoaderComponent from '../../../../Loader/LoaderComponent';
import { NotificationContext } from '../../../context';
import { NotificationsDisplay, NotificationsFetchStatus } from '../../common';

const Container = styled.div`
    background: var(--surface-color-light);
    display: flex;
    flex-direction: column;
    width: 100%;

    &:focus {
        outline-offset: 1em;
        outline-style: solid;
        z-index: 2;
    }
`;

const MainHeader = styled.h3`
    font-size: 1em;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    margin-bottom: 0;
`;

const NotificationsContainerDiv = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
`;

const LoaderWrapper = styled.div`
    height: 5rem;
`;

const NotificationsPopupContent = forwardRef(function ForwardRefedNotificationContainer(_props, ref) {
    const { t } = useTranslation('common');
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const { notifications, errorLoadingNotifications, canFetchMore, fetchMore } = useContext(NotificationContext);

    return (
        <Container role="dialog" aria-label="overview of notifications">
            <MainHeader>{t('alerts')}</MainHeader>
            <Divider />
            <NotificationsContainerDiv ref={scrollRef}>
                <InfiniteScroller
                    threshold={200}
                    hasMore={canFetchMore}
                    loadMore={fetchMore}
                    loader={
                        <LoaderWrapper key={0}>
                            <LoaderComponent text="loading more notifications" />
                        </LoaderWrapper>
                    }
                    useWindow={false}
                    getScrollParent={() => scrollRef.current}
                >
                    <NotificationsFetchStatus
                        errorLoadingNotifications={errorLoadingNotifications}
                        noNotifications={notifications.length === 0}
                    />
                    <NotificationsDisplay ref={ref} />
                </InfiniteScroller>
            </NotificationsContainerDiv>
        </Container>
    );
});

export default NotificationsPopupContent;
