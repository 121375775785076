import { Key, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import type { CSSProperties } from 'styled-components';
import styled from 'styled-components';
import { OrderLine } from '../../../../../api/apiTypes/shopApiTypes';
import { getFormattedCurrency } from '../../../../../utility';

type IsMobileViewProps = { isMobileView: boolean };
const Table = styled.table`
    position: relative;
    border-spacing: 0 1rem;
    border-collapse: collapse;
    padding: 0 0.5rem;
    width: 100%;
    border-bottom: ${({ isMobileView }: IsMobileViewProps) => (isMobileView ? '2px solid #aaaaaa' : 'none')};
`;

const TableHeader = styled.thead``;
const Header = styled.th`
    white-space: nowrap;
    font-size: 1.143rem;
    line-height: 1.714rem;
    text-align: left;
    padding: 0.429rem;
    padding-bottom: 0.714rem;
    font-weight: 400;

    &:first-child {
        padding-left: 1rem;
    }

    :focus-visible,
    :focus {
        outline: none;
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

const Row = styled.tr``;

const TableBody = styled.tbody``;

const TableCell = styled.td`
    font-size: 1.143rem;
    line-height: 1.714rem;
    padding: 0.714rem 0.429rem;
    word-break: break-word;
    max-width: 0;

    &:first-child {
        padding-left: 1rem;
        min-width: 3.7rem;
    }
`;

const OrderLineCell = styled.p`
    font-size: 1.143rem;
    margin: 0;
`;

export enum InvoiceColumns {
    serialNumber = 'serialNumber',
    ProductDescription = 'productDescription',
    Amount = 'amount',
    PriceOneItem = 'priceOneItem',
    MVA = 'mva',
    Total = 'total',
}

export type ColumnType = {
    name: string;
    key: Key;
    label: string | ReactElement;
    width: string;
    content?(orderLine: OrderLine, index: number): JSX.Element | null;
    headerOnly?: boolean;
    cellColSpan?: number;
    style?: CSSProperties;
}[];

type ProductsTableProps = {
    orderLines: OrderLine[];
    isMobileView: boolean;
};
const InvoiceTable = ({ orderLines, isMobileView }: ProductsTableProps): ReactElement => {
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'invoice' });

    const allColumns: ColumnType = [
        {
            name: 'products',
            key: InvoiceColumns.serialNumber,
            label: t('invoiceTableProductNumber'),
            width: '10%',
            content: function renderContent(orderLine: OrderLine, index) {
                const serialNumber = index + 1;
                return <OrderLineCell>{serialNumber}</OrderLineCell>;
            },
        },
        {
            name: 'productDescription',
            key: InvoiceColumns.ProductDescription,
            label: t('invoiceTableProductDescription'),
            width: '30%',
            content: function renderContent(orderLine: OrderLine) {
                return <OrderLineCell>{orderLine.description}</OrderLineCell>;
            },
        },
        {
            name: 'amount',
            key: InvoiceColumns.Amount,
            label: t('invoiceTableAmount'),
            width: '10%',
            content: function renderContent(orderLine: OrderLine) {
                return <OrderLineCell>{orderLine.amount}</OrderLineCell>;
            },
        },
        {
            name: 'priceOneItem',
            key: InvoiceColumns.PriceOneItem,
            label: t('invoiceTablePriceOneItem'),
            width: '10%',
            content: function renderContent(orderLine: OrderLine) {
                return <OrderLineCell>{getFormattedCurrency(orderLine.priceOneItem)}</OrderLineCell>;
            },
        },
        {
            name: 'mva',
            key: InvoiceColumns.MVA,
            label: t('invoiceTableVAT'),
            width: '10%',
            content: function renderContent(orderLine: OrderLine) {
                return <OrderLineCell>{`${getFormattedCurrency(orderLine.taxOneItemPercent)}%`}</OrderLineCell>;
            },
        },
        {
            name: 'total',
            key: InvoiceColumns.Total,
            label: t('invoiceTableTotal'),
            width: '10%',
            content: function renderContent(orderLine: OrderLine) {
                return <OrderLineCell>{getFormattedCurrency(orderLine.totalPrice)}</OrderLineCell>;
            },
        },
    ];

    const mobileViewColumns: Key[] = [InvoiceColumns.ProductDescription, InvoiceColumns.Amount, InvoiceColumns.Total];

    const filteredColumns = isMobileView
        ? allColumns.filter((column) => mobileViewColumns.includes(column.key))
        : allColumns;

    return (
        <Table isMobileView={isMobileView}>
            <TableHeader>
                <Row>
                    {filteredColumns.map((column) => (
                        <Header key={column.key} style={{ width: column.width, ...column.style }} tabIndex={0}>
                            {column.label}
                        </Header>
                    ))}
                </Row>
            </TableHeader>
            <TableBody>
                {orderLines.map((order, index) => (
                    <Row key={order.id}>
                        {filteredColumns.map((column) => (
                            <TableCell key={order.id + column.key} data-label={`${column.key}:`}>
                                {column.content && column.content(order, index)}
                            </TableCell>
                        ))}
                    </Row>
                ))}
            </TableBody>
        </Table>
    );
};

export default InvoiceTable;
