import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Perm } from '../../common';
import { sidebarOperations } from '../../common/Sidebar/duck';
import { useNavigation, useUserHasPerm } from '../../utility';
import { useIsMobile } from '../../utility/hooks/useIsMobile';

const useFoodNavigation = (): {
    foodOrderLink: string;
    myBookingsLink: string;
    goToFoodOrder(): void;
    goToMyBookings(): void;
    isUserFoodOrdersOwnCompanyRead: boolean;
} => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const isUserFoodOrdersOwnCompanyRead = useUserHasPerm(Perm.FoodOrdersOwnCompanyRead);

    return useMemo(() => {
        const foodOrderLink = '/Food/FoodOrder';
        const myBookingsLink = `/Food/MyBookings`;
        const onNavigate = (url: string, shouldCloseSideBar = true) => {
            if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
            navigation.push(url);
        };
        return {
            foodOrderLink,
            myBookingsLink,
            goToFoodOrder: () => onNavigate(foodOrderLink),
            goToMyBookings: () => onNavigate(myBookingsLink),
            isUserFoodOrdersOwnCompanyRead,
        };
    }, [navigation, dispatch, isMobile, isUserFoodOrdersOwnCompanyRead]);
};

export default useFoodNavigation;
