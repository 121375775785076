import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Category } from '../../../../../../../api/apiTypes/ticketingApiTypes';
import { MobileViewBreakpoint } from '../../../../../../../common/ScreenSizeBreakPoints';
import { SvgIcon } from '../../../../../../../components';
import { useGetNavigationUrl, useNavigation } from '../../../../../../../utility';
import useTicketingNavigation from '../../../../../useTicketingNavigation';

type CardProps = { ismobile: boolean };
const Card = styled.div`
    min-width: ${({ ismobile }: CardProps) => (ismobile ? 'calc(100% - 50px)' : '25.88rem')};
    width: ${({ ismobile }: CardProps) => (ismobile ? 'calc(100% - 50px)' : '25.88rem')};
    border-radius: 0.4rem;
    margin: 0.571rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 5.714rem;
    justify-content: space-between;

    :focus,
    :focus-visible {
        outline: 1px solid var(--primary-color);
    }
    :focus:not(:focus-visible) {
        outline: 0;
    }
`;

const Content = styled.div`
    width: 100%;
    padding: 1rem;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Header = styled.h6`
    font-size: 1.143rem;
    line-height: 1.714rem;
    margin: 0;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1rem;
    max-width: 60%;
`;

const PublishedContainer = styled.div`
    display: flex;
    align-items: center;
    flex-basis: 100px;
    flex-grow: 0;
`;

const PublishedText = styled.span`
    padding-left: 0.75rem;
    font-size: 1.143rem;
    font-weight: bold;
`;

interface CaseCategoryCardProps {
    category: Category;
    typeId: string;
}

const CaseCategoryCard = ({ category, typeId }: CaseCategoryCardProps): ReactElement => {
    const isMobile = window.matchMedia(`(max-width:${MobileViewBreakpoint ?? 768}px)`)?.matches;
    const { getCategoryTabLink } = useTicketingNavigation();
    const categoryLink = getCategoryTabLink(typeId, category.id, 'general');
    const getUrl = useGetNavigationUrl();
    const actualCategoryLink = getUrl(categoryLink);
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const { push } = useNavigation();
    return (
        <Card key={category.id} tabIndex={0} ismobile={isMobile} onClick={() => push(actualCategoryLink)}>
            <Content>
                <HeaderContainer>
                    <Header>{category.name}</Header>
                    <PublishedContainer>
                        {category.isPublished ? (
                            <>
                                <SvgIcon name="VisibilityOn" />
                                <PublishedText>{t('published')}</PublishedText>
                            </>
                        ) : (
                            <>
                                <SvgIcon name="VisibilityOff" />
                                <PublishedText>{t('hidden')}</PublishedText>
                            </>
                        )}
                    </PublishedContainer>
                </HeaderContainer>
            </Content>
        </Card>
    );
};

export default CaseCategoryCard;
