import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroller from 'react-infinite-scroller';
import styled from 'styled-components';
import { InventorySortDirection, InventorySortingType } from '../../../../api/apiTypes/shopApiTypes';
import LoaderComponent from '../../../../common/Loader/LoaderComponent';
import {
    FilteringDrawer,
    GhostPrimaryButton,
    InformationMessage,
    PrimaryButton,
    SearchInput,
    SearchSelect,
    SortingDrawer,
    SvgIcon,
} from '../../../../components';
import { GroupedOptionType, OptionType } from '../../../../components/select/searchSelect/types';
import { filterOptionsHelper } from '../../../../helpers/filterOptionsHelper';
import { useDebounce } from '../../../../utility';
import useResizeWindow from '../../../../utility/hooks/useResizeWindow';
import useScrollToElement from '../../../../utility/hooks/useScrollToElement';
import {
    useGetShopFilterProductStatusOptions,
    useGetShopFilterProductTypeOptions,
} from '../../apiQueries/useShopFilters';
import { useGetInfiniteCompanyProducts } from '../../apiQueries/useShopProducts';
import { ShopAtWorkContext, ShopOverviewView } from '../../ShopAtWork';
import { HorizontalRule } from '../../styles/ShopAtWorkStyles';
import { InventoryHeader } from './components/InventoryHeader';
import InventoryTable from './components/InventoryTable';
import {
    FilterKeys,
    ProductColumns,
    allFilterOptions,
    prepareInventoryFilters,
    renderStatusOptions,
    renderTypesOptions,
} from './utils';

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

type SearchFieldProps = { isTabletOrMobileView: boolean };
const SearchField = styled(SearchInput)`
    min-width: ${({ isTabletOrMobileView }: SearchFieldProps) => (isTabletOrMobileView ? 'auto' : '21.071rem')};
    width: 100%;
    height: 2.857rem;
    margin: 0;
    flex: 1;

    input {
        text-overflow: ellipsis;
    }
`;

const PurchasesFilter = styled(SearchSelect)`
    min-width: 12rem;
`;

type ControlsWrapperProps = { isTabletOrMobileView: boolean };
const ControlsWrapper = styled.div`
    display: flex;
    flex-direction: ${({ isTabletOrMobileView }: ControlsWrapperProps) => (isTabletOrMobileView ? 'column' : 'row')};
    justify-content: space-between;
    align-items: ${({ isTabletOrMobileView }: ControlsWrapperProps) =>
        isTabletOrMobileView ? 'flex-start' : 'center'};
    flex-wrap: wrap;
    gap: 0.714rem;
`;

const SearchAndSortContainer = styled.div`
    min-width: auto;
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.571rem;
`;

const SortingDirectionButton = styled(PrimaryButton)`
    height: 2.857rem;
    svg {
        rect {
            fill: var(--background-color);
        }
    }
`;

const StyledHorizontalRule = styled(HorizontalRule)`
    width: 100%;
    margin: 0;
`;

type PurchasesFiltersWrapperProps = { isTabletOrMobileView: boolean };
const PurchasesFiltersWrapper = styled.div`
    display: flex;
    flex-direction: ${({ isTabletOrMobileView }: PurchasesFiltersWrapperProps) =>
        isTabletOrMobileView ? 'column' : 'row'};
    gap: 0.571rem;
    justify-content: ${({ isTabletOrMobileView }: PurchasesFiltersWrapperProps) =>
        isTabletOrMobileView ? 'start' : 'end'};
`;

const LoaderWrapper = styled.div`
    height: 5rem;
`;

const FilterDrawerButtonContainer = styled.div`
    gap: 0.571rem;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
`;

const OpenFilterDrawerButton = styled(GhostPrimaryButton)`
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    &&& {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        gap: 0.285rem;
    }
`;

const ButtonTextWrapper = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const ButtonCount = styled.p`
    display: inline;
`;

const ButtonText = styled.p`
    display: inline;
`;

export interface InventoryFilterProps {
    isSearchable: boolean;
    key: FilterKeys;
    title: string;
    options: OptionType[] | GroupedOptionType[] | [];
    onChange: (options: OptionType[] | []) => void;
    value: OptionType[] | [];
    onResetOptions: () => void;
    autoMenuPosition: boolean;
}

//TODO move it
const DESKTOP_BREAKPOINT = 870;

interface InventoryOverviewProps {
    view: ShopOverviewView;
}

const InventoryOverview = ({ view }: InventoryOverviewProps): ReactElement => {
    const { state: ShopContextState, dispatch } = useContext(ShopAtWorkContext);
    const { sortingType, sortingDirection, ...filters } = ShopContextState.inventoryItems;
    const { data: statusFilterOptions } = useGetShopFilterProductStatusOptions();
    const { data: typesFilterOptions } = useGetShopFilterProductTypeOptions();
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'inventory' });

    const containerWidth = useResizeWindow(ShopContextState.scrollRef);
    const isTabletOrMobileView = containerWidth < DESKTOP_BREAKPOINT;
    const debouncedSearchTerm = useDebounce(ShopContextState.searchTerm.trim().replace(/\s+/g, ' '), 200);

    const preparedFilters = prepareInventoryFilters(filters);
    const { statuses, types } = filters;
    const [showMobileFilters, setShowMobileFilters] = useState(false);

    useScrollToElement(ShopContextState.fromOrderId.orderId?.toString());

    const { data, isLoading, isFetchingMore, isError, canFetchMore, fetchMore } = useGetInfiniteCompanyProducts(
        {
            sortingType,
            sortDirection: sortingDirection,
            searchTerm: debouncedSearchTerm,
            filters: preparedFilters,
        },
        true,
    );

    const products = useMemo(() => (data ? data.map((data) => data) : []).flat(), [data]);
    const options = allFilterOptions();
    const filterDropdownOptions = filterOptionsHelper(options, ProductColumns);

    const handleSort = (sortColumn: { path: InventorySortingType; order: InventorySortDirection }) => {
        dispatch({ type: 'UPDATE_INVENTORY_OVERVIEW_SORTING_TYPE', payload: { sortingType: sortColumn.path, view } });
        dispatch({
            type: 'UPDATE_INVENTORY_OVERVIEW_SORTING_DIRECTION',
            payload: { sortingDirection: sortColumn.order, view },
        });
    };

    const onChangeSearchTermHandler = (searchTerm: string) => {
        dispatch({
            type: 'UPDATE_SHOP_OVERVIEW_SEARCH_TERM',
            payload: searchTerm,
        });
    };

    const onChangeFiltersHandler = (filterType: string, selectedOptions: OptionType[]) => {
        dispatch({
            type: 'UPDATE_INVENTORY_OVERVIEW_FILTERS',
            payload: { [filterType]: selectedOptions, view },
        });
    };

    const onSortDirectionHandler = () => {
        sortingDirection === InventorySortDirection.Desc
            ? dispatch({
                  type: 'UPDATE_INVENTORY_OVERVIEW_SORTING_DIRECTION',
                  payload: { sortingDirection: InventorySortDirection.Asc, view },
              })
            : dispatch({
                  type: 'UPDATE_INVENTORY_OVERVIEW_SORTING_DIRECTION',
                  payload: { sortingDirection: InventorySortDirection.Desc, view },
              });
    };

    useEffect(() => {
        dispatch({ type: 'UPDATE_SHOP_OVERVIEW_VIEW', payload: view });
    }, [view, dispatch]);

    const handleFetchMore = () => {
        if (!isFetchingMore) fetchMore();
    };

    const [visibleDrawer, setDrawerVisibility] = useState<string | null>(null);
    const inventoryFilters = [
        {
            onChange: (options: OptionType[] | []) => onChangeFiltersHandler(FilterKeys.Statuses, options),
            value: statuses,
            key: FilterKeys.Statuses,
            title: t('statusFilterLabel'),
            onResetOptions: () => onChangeFiltersHandler(FilterKeys.Statuses, []),
            options: renderStatusOptions(statusFilterOptions),
            isSearchable: false,
            autoMenuPosition: true,
        },
        {
            onChange: (options: OptionType[] | []) => onChangeFiltersHandler(FilterKeys.Types, options),
            value: types,
            key: FilterKeys.Types,
            title: t('productTypeFilterLabel'),
            onResetOptions: () => onChangeFiltersHandler(FilterKeys.Types, []),
            options: renderTypesOptions(typesFilterOptions),
            isSearchable: false,
            autoMenuPosition: true,
        },
    ];

    return (
        <MainContainer>
            <InventoryHeader />
            <ControlsWrapper isTabletOrMobileView={isTabletOrMobileView}>
                <SearchAndSortContainer>
                    <SearchField
                        isTabletOrMobileView={isTabletOrMobileView}
                        onChange={onChangeSearchTermHandler}
                        value={ShopContextState.searchTerm}
                        placeholder={t('searchPlaceholder')}
                    />
                    {isTabletOrMobileView && (
                        <SortingDirectionButton onClick={() => setDrawerVisibility('sorting')}>
                            <SvgIcon name="SortIcon" />
                        </SortingDirectionButton>
                    )}
                    {isTabletOrMobileView && (
                        <SortingDirectionButton onClick={() => setShowMobileFilters(!showMobileFilters)}>
                            <SvgIcon name="FilterIcon" />
                        </SortingDirectionButton>
                    )}
                </SearchAndSortContainer>

                {isTabletOrMobileView ? (
                    showMobileFilters ? (
                        <FilterDrawerButtonContainer>
                            {inventoryFilters.map((filter) => {
                                const isVisible = visibleDrawer === filter.title;
                                return (
                                    <div key={filter.key}>
                                        <OpenFilterDrawerButton onClick={() => setDrawerVisibility(filter.title)}>
                                            <ButtonTextWrapper>
                                                <ButtonText>{filter.title} </ButtonText>
                                            </ButtonTextWrapper>

                                            {!!filter.value.length && (
                                                <ButtonCount> {`(${filter.value.length})`}</ButtonCount>
                                            )}
                                        </OpenFilterDrawerButton>
                                        <FilteringDrawer
                                            isVisible={isVisible}
                                            onClose={() => setDrawerVisibility(null)}
                                            onReset={filter.onResetOptions}
                                            filterName={filter.key}
                                            filterTitle={filter.title}
                                            filterOptions={filter.options}
                                            filterValue={filter.value}
                                            isSearchable={filter.isSearchable}
                                            onFilterChange={filter.onChange}
                                            itemsCount={products.length}
                                        ></FilteringDrawer>
                                    </div>
                                );
                            })}
                        </FilterDrawerButtonContainer>
                    ) : null
                ) : (
                    <PurchasesFiltersWrapper isTabletOrMobileView={isTabletOrMobileView}>
                        {inventoryFilters.map(
                            ({
                                isSearchable,
                                value,
                                onChange,
                                onResetOptions,
                                key,
                                title,
                                options,
                                autoMenuPosition,
                            }) => (
                                <PurchasesFilter
                                    key={key}
                                    onChange={onChange}
                                    value={value}
                                    name={key}
                                    title={title}
                                    onResetOptions={onResetOptions}
                                    options={options}
                                    isSearchable={isSearchable}
                                    autoMenuPosition={autoMenuPosition}
                                />
                            ),
                        )}
                    </PurchasesFiltersWrapper>
                )}
                {isTabletOrMobileView && (
                    <>
                        <SortingDrawer
                            isVisible={visibleDrawer === 'sorting'}
                            onClose={() => setDrawerVisibility(null)}
                            onChange={(value: unknown) =>
                                dispatch({
                                    type: 'UPDATE_INVENTORY_OVERVIEW_SORTING_TYPE',
                                    payload: { sortingType: value as InventorySortingType, view },
                                })
                            }
                            filterOptions={filterDropdownOptions}
                            filterValue={sortingType}
                            sortingDirection={sortingDirection}
                            onChangeDirection={onSortDirectionHandler}
                        />
                        <StyledHorizontalRule />
                    </>
                )}
            </ControlsWrapper>
            {isError || !products ? (
                <InformationMessage type="error">{t('productsFetchingError')}</InformationMessage>
            ) : (
                <InfiniteScroller
                    threshold={200}
                    hasMore={canFetchMore}
                    loadMore={handleFetchMore}
                    loader={
                        <LoaderWrapper key={0}>
                            <LoaderComponent text={t('loaderText')} />
                        </LoaderWrapper>
                    }
                    useWindow={false}
                    getScrollParent={() => ShopContextState.scrollRef?.current ?? null}
                >
                    <InventoryTable
                        products={products}
                        view={view}
                        isLoading={isLoading}
                        onSort={handleSort}
                        sortColumn={{
                            path: sortingType,
                            product: sortingDirection,
                        }}
                        collapse={isTabletOrMobileView}
                    />
                </InfiniteScroller>
            )}
        </MainContainer>
    );
};

export default InventoryOverview;
