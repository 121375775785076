import React, { useRef, KeyboardEvent, useMemo } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useClientRect } from '../../../../utility';
import { getNewKeyValue, getNewScrollValue, getNewTouchValue } from '../utils';

const ListContainer = styled.div`
    flex-grow: 1;
    height: 100%;
    width: 100%;
    z-index: 5;
    overflow: hidden;

    :focus,
    :focus-visible {
        outline: 1px solid var(--primary-color);
        outline-offset: -5px;
        outline-style: auto;
    }
    :focus:not(:focus-visible) {
        outline: none;
    }
`;

const List = styled.div`
    list-style: none;
    padding: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    outline: none;
`;

type OptionItemProps = {
    selected: boolean;
    invalid?: boolean;
    animationDuration: number;
    alignRight: boolean;
};

const OptionItem = styled.button`
    padding: 0.68rem 2rem;
    color: ${({ selected, invalid = false }: OptionItemProps) =>
        selected
            ? 'var(--primary-color)'
            : invalid
            ? 'var(--text-medium-emphasis-color)'
            : 'var(--text-high-emphasis)'};
    font-size: 1.15rem;
    border: none;
    background: none;
    text-align: inherit;
    outline: none;
    cursor: pointer;
    transition: ${({ animationDuration }: OptionItemProps) => `ease color ${animationDuration}s`};

    :focus,
    :focus-visible {
        outline: 1px solid var(--primary-color);
        outline-offset: -5px;
        outline-style: auto;
    }
    :focus:not(:focus-visible) {
        outline: none;
    }
`;

type OptionContainerProps = {
    alignRight: boolean;
};
const OptionContainer = styled.div`
    display: flex;
    justify-content: ${({ alignRight }: OptionContainerProps) => (alignRight ? 'flex-start' : 'flex-end')};
`;

interface OptionsListProps {
    containerName: string;
    options: { value: string; invalid: boolean }[];
    selectedValue: string;
    handleValueChange: (newValue: string) => void;
    alignRight?: boolean;
    yContainerCenter: number;
}

const OptionsList: React.FC<React.PropsWithChildren<OptionsListProps>> = ({
    containerName,
    options,
    selectedValue,
    handleValueChange,
    alignRight = false,
    yContainerCenter,
}: OptionsListProps) => {
    const touchRef = useRef({ pos: 0, origIndex: 0 });
    const [OptionItemRect, { height: optionItemHeight }] = useClientRect();
    const animationDuration = 0.2;

    const onScrollHandler = (event: React.WheelEvent): void => {
        const newValue = getNewScrollValue(options, event, selectedValue);
        handleValueChange(newValue);
    };

    const onKeyDownHandler = (e: KeyboardEvent<HTMLElement>): void => {
        if (e.code === 'ArrowDown') {
            e.preventDefault();
            const newValue = getNewKeyValue(options, selectedValue, 1);
            handleValueChange(newValue);
        }
        if (e.code === 'ArrowUp') {
            e.preventDefault();
            const newValue = getNewKeyValue(options, selectedValue, -1);
            handleValueChange(newValue);
        }
    };

    const onTouchStartHandler = ({ touches }: React.TouchEvent): void => {
        touchRef.current = {
            pos: touches[0].clientY,
            origIndex: options.findIndex(({ value }) => value === selectedValue),
        };
    };

    const onTouchMoveHandler = ({ touches }: React.TouchEvent): void => {
        const newValue = getNewTouchValue(touchRef, touches, options);
        handleValueChange(newValue);
    };

    const selectedIndex = options.findIndex(({ value }) => value === selectedValue);
    const yPos = useMemo(() => {
        if (yContainerCenter && optionItemHeight) {
            return -selectedIndex * optionItemHeight + yContainerCenter - optionItemHeight / 2;
        }
    }, [optionItemHeight, selectedIndex, yContainerCenter]);

    return (
        <ListContainer
            data-containername={containerName}
            onWheel={onScrollHandler}
            onKeyDown={onKeyDownHandler}
            onTouchMove={onTouchMoveHandler}
            onTouchStart={onTouchStartHandler}
            role="listbox"
            aria-activedescendant={`${containerName}-option-${selectedIndex}`}
            tabIndex={0}
        >
            <List>
                {options.map(({ value, invalid }, i) => {
                    const selected = value === selectedValue;
                    return (
                        <OptionContainer key={value} alignRight={alignRight}>
                            <OptionItem
                                id={`${containerName}-option-${i}`}
                                role="option"
                                aria-selected={selected}
                                as={motion.button}
                                animate={{ y: yPos }}
                                animationDuration={animationDuration}
                                initial={{ y: yPos }}
                                transition={{ type: 'spring', duration: animationDuration, bounce: 0.5 }}
                                style={{ fontWeight: selected ? 'bold' : 'normal' }}
                                onClick={() => handleValueChange(value)}
                                key={value}
                                data-value={`${containerName}${value}`}
                                selected={selected}
                                invalid={invalid}
                                tabIndex={-1}
                                alignRight={alignRight}
                                ref={OptionItemRect}
                            >
                                {value}
                            </OptionItem>
                        </OptionContainer>
                    );
                })}
            </List>
        </ListContainer>
    );
};

export default OptionsList;
