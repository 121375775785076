import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorStrip from '../../errorStrip';
import ChargeIconSvg from '../../icons/ChargeIconSvg';
import ReloadIconSvg from '../../icons/ReloadIconSvg';
import { Modal, ModalType } from '../../index';
import { Button, FlexBox, Image, Typography } from '../index';
import { MAX_IMAGE_SIZE, VALID_FORMATS } from './constants';
import { UploadMediaModalProps } from './types';

const UploadMediaModal: FC<UploadMediaModalProps> = ({
    isOpened,
    hideModalCallback,
    setFile,
    file,
    isEditView,
    uploadImageToStorage,
}) => {
    const { t } = useTranslation('events');
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const [error, setError] = useState('');

    const saveCallback = useCallback(async () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            uploadImageToStorage(formData);
        }
        hideModalCallback();
    }, [hideModalCallback, file, uploadImageToStorage]);

    const cancelCallback = useCallback(() => {
        if (!isEditView) setFile(null);
        hideModalCallback();
    }, [hideModalCallback, setFile, isEditView]);

    const resetHandler = useCallback(() => {
        setFile(null);
    }, [setFile]);

    const bottomContent = (
        <FlexBox gap={'1.143rem'} justify={'flex-end'}>
            {!!file && (
                <Button
                    width={'auto'}
                    borderColor={'var(--primary-color)'}
                    pd={'0.571rem 1.143rem'}
                    br={'0.357rem'}
                    justify={'center'}
                    align={'center'}
                    background={'transparent'}
                    onClick={resetHandler}
                    gap={'0.571rem'}
                >
                    <ReloadIconSvg fill={'var(--primary-color)'} />
                    <Typography fontSize={'1.143rem'} fontWeight={700} color={'var(--primary-color)'}>
                        {t('coverImageBlockResetBtnText')}
                    </Typography>
                </Button>
            )}
            <Button
                width={'auto'}
                borderWidth={'0.143rem'}
                borderColor={'var(--primary-color)'}
                pd={'0.571rem 1.143rem'}
                br={'0.357rem'}
                justify={'center'}
                align={'center'}
                background={'#fff'}
                onClick={cancelCallback}
            >
                <Typography fontSize={'1.143rem'} fontWeight={700} color={'var(--primary-color)'}>
                    {t('coverImageBlockCancelBtnText')}
                </Typography>
            </Button>
            <Button
                width={'auto'}
                pd={'0.571rem 1.143rem'}
                background={'var(--primary-color)'}
                br={'0.357rem'}
                justify={'center'}
                align={'center'}
                onClick={saveCallback}
            >
                <Typography fontSize={'1.143rem'} fontWeight={700} color={'#fff'}>
                    {t('coverImageBlockSubmitBtnText')}
                </Typography>
            </Button>
        </FlexBox>
    );

    const handleFileChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.files?.length) {
                const file = event.target.files[0];

                if (!VALID_FORMATS.includes(file.type)) {
                    setError(t('coverImageBlockUnsupportedFileFormat'));
                    return;
                }

                if (file.size > MAX_IMAGE_SIZE) {
                    setError(t('coverImageBlockFileSizeExceedsLimit'));
                    return;
                }
                setError('');
                setFile(file);
            }
        },
        [setFile, t],
    );

    const handleButtonClick = useCallback(() => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    }, []);

    const handleDragOver = useCallback((event: React.DragEvent) => {
        event.preventDefault();
    }, []);

    const handleDrop = useCallback(
        (event: React.DragEvent) => {
            event.preventDefault();
            const file = event.dataTransfer.files[0];

            if (!VALID_FORMATS.includes(file.type)) {
                setError(t('coverImageBlockUnsupportedFileFormat'));
                return;
            }

            if (file.size > MAX_IMAGE_SIZE) {
                setError(t('coverImageBlockFileSizeExceedsLimit'));
                return;
            }
            setError('');
            setFile(file);
        },
        [setFile, t],
    );

    return (
        <Modal
            type={ModalType.Medium}
            open={isOpened}
            title={t('coverImageBlockModalTitle')}
            onClose={cancelCallback}
            bottomContent={bottomContent}
        >
            {file ? (
                <FlexBox align={'center'} justify={'center'} background={'#f2f2f2'} height={'100%'}>
                    <Image src={URL.createObjectURL(file)} objectFit={'contain'} maxH={'23.429rem'} />
                </FlexBox>
            ) : (
                <FlexBox align={'center'} justify={'center'} pd={'2.857rem'} dir={'column'}>
                    <FlexBox
                        dir={'column'}
                        gap={'1.714rem'}
                        pd={'1.714rem'}
                        br={'0.714rem'}
                        borderColor={'var(--primary-color)'}
                        borderWidth={'0.071rem'}
                        borderStyle={'dashed'}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <FlexBox dir={'column'} gap={'0.571rem'} align={'center'}>
                            <ChargeIconSvg stroke={'var(--primary-color)'} />
                            <Typography
                                color={'#000'}
                                textAlign={'center'}
                                fontWeight={700}
                                lineHeight={'1.714rem'}
                                fontSize={'1.143rem'}
                            >
                                {t('coverImageBlockModalSectionTitle')}
                            </Typography>
                            <Button
                                br={'0.357rem'}
                                align={'center'}
                                width={'auto'}
                                justify={'center'}
                                background={'var(--primary-color)'}
                                pd={'0.571rem 1.143rem'}
                                onClick={handleButtonClick}
                            >
                                <input
                                    type="file"
                                    ref={inputFileRef}
                                    style={{ display: 'none' }}
                                    accept=".jpeg,.jpg,.png,.tiff,.pdf"
                                    onChange={handleFileChange}
                                />
                                <Typography
                                    color={'#fff'}
                                    fontWeight={700}
                                    fontSize={'1.143rem'}
                                    lineHeight={'1.286rem'}
                                >
                                    {t('coverImageBlockModalUploadFromMyComputerButtonText')}
                                </Typography>
                            </Button>
                        </FlexBox>
                        <Typography
                            textAlign={'center'}
                            color={'#677074'}
                            fontWeight={400}
                            lineHeight={'1.143rem'}
                            fontSize={'0.857rem'}
                        >
                            {t('coverImageBlockModalFormatText')}
                        </Typography>
                        <ErrorStrip textContent={error} />
                    </FlexBox>
                </FlexBox>
            )}
        </Modal>
    );
};

export default UploadMediaModal;
