import { t } from 'i18next';
import {
    TicketOptionType,
    PreparedFiltersType,
    TicketFilterType,
    TicketTypeAndCategoryOption,
    TicketType,
    TicketStatus,
    TicketPriority,
} from '../../../../api/apiTypes/ticketingApiTypes';
import { GroupedOptionType, OptionType } from '../../../../components/select/searchSelect/types';
import { FilterKeys, getPriorityTranslation, getStatusTicketTranslation } from '../../utils';

interface FiltersType {
    companies: OptionType[] | [];
    assignees: OptionType[] | [];
    categories: OptionType[] | [];
    statuses: OptionType[] | [];
    priorities: OptionType[] | [];
}

export const renderTicketStatusOptions = (options: TicketStatus[] | undefined): OptionType[] => {
    return options ? options.map((option) => ({ value: option, label: getStatusTicketTranslation(option) })) : [];
};

export const renderPriorityOptions = (options: TicketPriority[] | undefined): OptionType[] => {
    return options
        ? options.map((option: TicketPriority) => ({ value: option, label: getPriorityTranslation(option) }))
        : [];
};

type DefaultAssigneesOptions = 'Me' | 'Unassigned';

export const renderAssigneesOptions = (options: TicketOptionType[] | undefined): OptionType[] | [] => {
    const getAssigneesOptionLabel = (label: string) => {
        const labels = {
            Unassigned: t('Ticketing:commonTicketing.unassignedLabel'),
            Me: t('Ticketing:commonTicketing.assignedToMeLabel'),
        };
        return labels[label as DefaultAssigneesOptions] || label;
    };

    return options ? options.map((option) => ({ value: option.id, label: getAssigneesOptionLabel(option.name) })) : [];
};

export const renderCompaniesOptions = (options: TicketOptionType[] | undefined): OptionType[] => {
    return options ? options.map((option) => ({ value: option.id, label: option.name })) : [];
};

export const renderCategoriesOptions = (types: TicketTypeAndCategoryOption[] | undefined): GroupedOptionType[] => {
    if (types) {
        return types
            .map(({ categories, name }) => ({
                label: name,
                options: categories.map(({ id, name }) => ({
                    value: id,
                    label: name,
                })),
            }))
            .sort((firstCategory, secondCategory) => firstCategory.label.localeCompare(secondCategory.label));
    }
    return [];
};

export const renderCategoriesAdminOptions = (types: TicketType[] | undefined): GroupedOptionType[] => {
    if (types) {
        return types
            .filter(({ isPublished }) => isPublished)
            .map(({ categories, name }) => ({
                label: name,
                options: categories
                    .filter(({ isPublished }) => isPublished)
                    .map(({ id, name }) => ({
                        value: id,
                        label: name,
                    })),
            }))
            .sort((firstCategory, secondCategory) => firstCategory.label.localeCompare(secondCategory.label));
    }
    return [];
};

export const prepareFilters = (filters: FiltersType): PreparedFiltersType[] => {
    return Object.entries(filters).map(([type, options]) => {
        let enumFilterType: TicketFilterType;
        switch (type) {
            case FilterKeys.Companies:
                enumFilterType = TicketFilterType.Company;
                break;
            case FilterKeys.Assignees:
                enumFilterType = TicketFilterType.Assignee;
                break;
            case FilterKeys.Categories:
                enumFilterType = TicketFilterType.Category;
                break;
            case FilterKeys.Statuses:
                enumFilterType = TicketFilterType.Status;
                break;
            case FilterKeys.Priorities:
                enumFilterType = TicketFilterType.Priority;
                break;
            default:
                enumFilterType = TicketFilterType.Priority;
                break;
        }
        return { type: enumFilterType, value: options.map((option: OptionType) => option.value) };
    });
};
