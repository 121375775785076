import { FC } from 'react';
import styled from 'styled-components';

const StyledCommonHeader = styled.header`
    display: flex;
    align-items: center;
    padding: 1.107rem 1.714rem;
    background-color: white;
    width: 100%;
    border-bottom: 1px solid #e9ebeb;
`;

const CommonHeaderBackButtonWrapper = styled.button`
    display: flex;
    align-items: center;
    padding: 0.857rem 0;
    cursor: pointer;
    border: none;
    background-color: transparent;
    gap: 1.429rem;
`;

const CommonHeaderBackButtonLabel = styled.span`
    color: #263238e5;
    font-weight: 600;
    font-size: 1.143rem;
    line-height: 1.143rem;
`;

const BackButton = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export interface CommonHeaderProps {
    backButtonLabel: string;
    handleGoBack: () => void;
}
export const CommonHeader: FC<CommonHeaderProps> = ({ backButtonLabel, handleGoBack }) => {
    return (
        <StyledCommonHeader>
            <CommonHeaderBackButtonWrapper onClick={handleGoBack}>
                <BackButton>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_373_32385" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
                            <path
                                d="M14.1925 20.1751L13.3007 21.0669C12.923 21.4446 12.3124 21.4446 11.9388 21.0669L4.12893 13.2611C3.75129 12.8835 3.75129 12.2728 4.12893 11.8992L11.9388 4.08938C12.3164 3.71174 12.9271 3.71174 13.3007 4.08938L14.1925 4.98124C14.5742 5.3629 14.5662 5.9856 14.1765 6.35922L9.33549 10.9712H20.8815C21.4158 10.9712 21.8457 11.4011 21.8457 11.9354V13.221C21.8457 13.7553 21.4158 14.1851 20.8815 14.1851H9.33549L14.1765 18.7971C14.5702 19.1707 14.5782 19.7934 14.1925 20.1751Z"
                                fill="#657074"
                            />
                        </mask>
                        <g mask="url(#mask0_373_32385)">
                            <rect x="0.845703" y="0.578125" width="24" height="24" fill="#677074" />
                        </g>
                    </svg>
                </BackButton>
                <CommonHeaderBackButtonLabel>{backButtonLabel}</CommonHeaderBackButtonLabel>
            </CommonHeaderBackButtonWrapper>
        </StyledCommonHeader>
    );
};
