import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import {
    Control,
    UseFormGetValues,
    UseFormHandleSubmit,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
    ComponentType,
    CreateFoodProductDto,
    CreateProductDto,
    PredefinedCategoriesEnum,
} from '../../../../../../api/apiTypes/shopApiTypes';
import { OptionType } from '../../../../../../components/select/searchSelect/types';
import {
    useCreateProduct,
    useGetShopCategoriesByProductTypeId,
    useGetShopFilterProductTypeOptions,
    useUpdateProduct,
} from '../../../../apiQueries/useShopFilters';
import {
    useDeleteProductById,
    useGetProductById,
    useGetProductsAllergens,
} from '../../../../apiQueries/useShopProducts';
import useShopAtWorkNavigation from '../../../../useShopAtWorkNavigation';
import { getProductTypeOptionTranslation } from '../../utils';

type DropdownElement = {
    value: string;
    id: string;
};

export enum ProductTypeTranslation {
    FOOD = 'Mat',
    GENERAL = 'Generelle produkter',
    SERVICES = 'Tjenester',
    ITEM = 'Punkt',
    MEMBERSHIP = 'Medlemskap',
    SPACES = 'Mellomrom',
}

export type CreateProductForm = {
    productId: string;
    categoryId: string;
    title: string;
    price: number;
    taxPercent: number;
    productStatus: string;
    canteenId: string;
    description: string;
    components?: OptionType | OptionType[];
};

type ErrorModalContext = {
    title: string;
    body: string | null;
};

interface NewProductFormLogicMethodsInterface {
    onSubmit: (data: CreateProductForm) => void;
    onAbortForm: () => void;
    onAbortModal: () => void;
    onRemove: () => void;
    setIsModalVisible: (isVisible: boolean) => void;
}

interface NewProductFormLogicValuesInterface {
    prepareProductTypes: DropdownElement[];
    preparedCategories: DropdownElement[];
    preparedAllergens: OptionType[];
    errorModalContext: ErrorModalContext | null;
    isActionLoading: boolean;
    isAllergensLoading: boolean;
    isAllergensLoadingSuccess: boolean;
    isCategoriesFetching: boolean;
    isEditableProductLoading: boolean;
    isEditForm: boolean;
    isFormVisible: boolean;
    isModalVisible: boolean;
    isSubmitButtonDisabled: boolean;
    isCategoriesDropdownLoading: boolean;
    showProductInputs: boolean;
}

interface NewProductFormLogicInterface {
    values: NewProductFormLogicValuesInterface;
    methods: NewProductFormLogicMethodsInterface;
    form: {
        handleSubmit: UseFormHandleSubmit<CreateProductForm>;
        control: Control<CreateProductForm>;
        register: UseFormRegister<CreateProductForm>;
        setValue: UseFormSetValue<CreateProductForm>;
        watch: UseFormWatch<CreateProductForm>;
        getValues: UseFormGetValues<CreateProductForm>;
        errors: FieldErrors<CreateProductForm>;
        isValid: boolean;
        isDirty: boolean;
        isSubmitted: boolean;
    };
}

const prepareProductDto = (
    formData: CreateProductForm,
    productTypeIsFood: boolean,
): CreateFoodProductDto | CreateProductDto => {
    const { components: productAllergens, ...productData } = formData;
    const mappedComponent = (productAllergens as OptionType[])?.map((i) => i.value);
    const resultData = {
        ...productData,
        externalID: null,
        description: formData.description || null,
        predefinedCategory: PredefinedCategoriesEnum.Custom,
        stock: null,
        productStatus: +formData.productStatus,
    };

    // TODO after deploy to production frontend and backend parts we need to get rid of this variable since we'll use components only
    if (productTypeIsFood) return { ...resultData, components: mappedComponent, allergens: mappedComponent };
    return resultData;
};

export const useProductFormLogic = (): NewProductFormLogicInterface => {
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'productForm' });

    const schema = yup.object().shape({
        productId: yup.string().required(t('inputErrorProductId')),
        title: yup.string().required(t('inputErrorTitle')),
        categoryId: yup.string().required(t('inputErrorCategoryId')),
        price: yup.number().required(t('inputErrorPrice')),
        taxPercent: yup.number().required(t('inputErrorTaxPercent')),
        productStatus: yup.string().required(t('inputErrorStatus')),
    });

    const { id: editableProductId } = useParams<{ id: string }>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [errorModalContext, setErrorModalContext] = useState<ErrorModalContext | null>(null);
    const {
        data: editableProduct,
        isLoading: isGetEditableProductLoading,
        isError: isGetEditableProductError,
        isFetched,
    } = useGetProductById(editableProductId);
    const { data: typesOptions } = useGetShopFilterProductTypeOptions();
    const [createProduct, { isLoading: isCreateProductLoading }] = useCreateProduct();
    const [updateProduct, { isLoading: isUpdateProductLoading }] = useUpdateProduct();
    const [deleteProductById] = useDeleteProductById();
    const { goToInventory } = useShopAtWorkNavigation();
    const {
        data: allAllergens,
        isLoading: isAllergensLoading,
        isSuccess: isAllergensLoadingSuccess,
    } = useGetProductsAllergens();

    const mapComponentsToOptions = (options: ComponentType[]) => {
        return options.map(({ name, id }) => ({ label: name, value: id }));
    };

    const editableProductComponent = editableProduct?.components;
    const isComponentsEntitiesFull = !!editableProductComponent;

    // TODO after deploy to production frontend and backend parts we need to get rid of this variable since we'll use components only
    const editableProductAllergens = useMemo(() => {
        return editableProduct?.allergens?.map((allergenId) => {
            const matchedAllergen = allAllergens?.find((allergen) => {
                return allergen.id === allergenId;
            });
            if (matchedAllergen?.name && matchedAllergen?.id)
                return { label: matchedAllergen?.name, value: matchedAllergen?.id };
        });
    }, [editableProduct?.allergens, allAllergens]);

    const defaultValues = {
        categoryId: '',
        price: editableProduct?.price ?? 0,
        productId: editableProduct?.category.type.id ?? '',
        productStatus: editableProduct?.status.toString() ?? '0',
        // TODO: clarify default tax percent
        taxPercent: editableProduct?.taxPercent ?? 25,
        title: editableProduct?.title ?? '',
        canteen: editableProduct?.canteenName ?? '',
        description: editableProduct?.description ?? '',
        // TODO after deploy to production frontend and backend parts need to get rid of this condition and use component key only
        components: isComponentsEntitiesFull
            ? mapComponentsToOptions(editableProductComponent)
            : editableProductAllergens,
    };

    const {
        handleSubmit,
        control,
        register,
        setValue,
        watch,
        getValues,
        reset,
        formState: { errors, isValid, isDirty, isSubmitted },
    } = useForm<CreateProductForm>({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues,
    });

    const onSubmit = (data: CreateProductForm) => {
        const preparedData = prepareProductDto(data, showProductInputs);
        if (editableProductId) {
            updateProduct(
                { ...preparedData, id: editableProductId },
                {
                    onSuccess: goToInventory,
                    onError: (error) => {
                        setErrorModalContext({
                            title: t('updateProductErrorText'),
                            body: error.toString(),
                        });
                    },
                },
            );
        } else {
            createProduct(
                { ...preparedData },
                {
                    onSuccess: goToInventory,
                    onError: (error) => {
                        setErrorModalContext({
                            title: t('createProductErrorText'),
                            body: error.toString(),
                        });
                    },
                },
            );
        }
    };

    const onAbortForm = () => {
        goToInventory();
    };

    const onAbortModal = () => {
        setErrorModalContext(null);
        setIsModalVisible(false);
    };

    const onRemove = () => {
        reset(defaultValues);
        deleteProductById(editableProductId, {
            onSuccess: goToInventory,
            onError: (error) => {
                setErrorModalContext({ title: t('onRemoveErrorText'), body: error.toString() });
            },
        });
    };

    const checkIsPreparedCategoriesExists = (): boolean => !!preparedCategories.length;

    const productTypeIdFormValue = watch('productId');

    const { data: fetchedCategories, isFetching: isCategoriesFetching } =
        useGetShopCategoriesByProductTypeId(productTypeIdFormValue);

    const preparedCategories = useMemo(() => {
        if (!fetchedCategories || !Array.isArray(fetchedCategories)) return [];
        return fetchedCategories.map((category) => ({ id: category.id, value: category.name }));
    }, [fetchedCategories]);

    const prepareProductTypes = useMemo((): DropdownElement[] => {
        if (!typesOptions) return [];
        return typesOptions.map((type) => ({ id: type.id, value: getProductTypeOptionTranslation(type.name) }), []);
    }, [typesOptions]);

    const preparedAllergens = useMemo(() => {
        if (!allAllergens || !Array.isArray(allAllergens)) return [];
        return allAllergens.map((allAllergens) => ({ label: allAllergens.name, value: allAllergens.id })) || [];
    }, [allAllergens]);

    const showProductInputs = useMemo(() => {
        const currentProductTypeItem = prepareProductTypes.find((item) => {
            return item.id === productTypeIdFormValue;
        });
        return currentProductTypeItem?.value === ProductTypeTranslation.FOOD;
    }, [prepareProductTypes, productTypeIdFormValue]);

    const isFormVisible = !editableProductId || (!!editableProductId && !isGetEditableProductLoading);
    const isActionLoading = editableProductId ? isUpdateProductLoading : isCreateProductLoading;
    const isSubmitButtonDisabled = !isDirty || !isValid || isActionLoading;
    const isCategoriesDropdownLoading = isCategoriesFetching || !preparedCategories.length;

    // TODO: Remove once backend migration is fixed
    useEffect(() => {
        if (isGetEditableProductError) {
            goToInventory();
        }
    }, [isGetEditableProductError]);

    useEffect(() => {
        reset(defaultValues);
    }, [isFetched]);

    useEffect(() => {
        if (checkIsPreparedCategoriesExists()) {
            const [{ id: firstCategoryId }] = preparedCategories;
            setValue('categoryId', firstCategoryId, { shouldDirty: true });
        }
    }, [productTypeIdFormValue && preparedCategories]);

    useEffect(() => {
        const isFirstInitCategoryWithEditableProduct =
            checkIsPreparedCategoriesExists() &&
            editableProduct &&
            getValues('productId') === editableProduct.productId;

        if (isFirstInitCategoryWithEditableProduct) {
            reset({ ...getValues(), categoryId: editableProduct.category.id });
        }
    }, [preparedCategories]);

    return {
        values: {
            prepareProductTypes,
            preparedCategories,
            preparedAllergens,
            isActionLoading,
            isAllergensLoading,
            isAllergensLoadingSuccess,
            isCategoriesDropdownLoading,
            isSubmitButtonDisabled,
            isCategoriesFetching,
            isEditableProductLoading: isGetEditableProductLoading,
            isFormVisible,
            isEditForm: !!editableProductId,
            isModalVisible,
            errorModalContext,
            showProductInputs,
        },
        methods: {
            onSubmit,
            onAbortForm,
            onAbortModal,
            onRemove,
            setIsModalVisible,
        },
        form: {
            handleSubmit,
            control,
            register,
            setValue,
            watch,
            getValues,
            errors,
            isValid,
            isDirty,
            isSubmitted,
        },
    };
};
