import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { GhostPrimaryButton, PrimaryButton, SvgIcon } from '../../components';
import { useNavigation } from '../../utility';
import useTicketingNavigation from '../Ticketing/useTicketingNavigation';

const NoAccessOrDontExistWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: calc(var(--spacer) * 5);
    overflow-y: auto;
`;

const Header = styled.h3`
    color: red;
    padding-bottom: 1rem;
`;

const Description = styled.p``;

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

const BackButton = styled(GhostPrimaryButton)`
    display: flex;

    svg {
        transform: rotate(180deg);
        margin-right: 0.5rem;
        path {
            fill: var(--primary-color);
        }
    }
`;

interface NoAccessOrDontExistProps {
    onGoBackHandler?: () => void;
}

const NoAccessOrDontExist: React.FC<React.PropsWithChildren<NoAccessOrDontExistProps>> = ({
    onGoBackHandler = () => null,
}: NoAccessOrDontExistProps): JSX.Element => {
    const nav = useNavigation();
    const location = useLocation();
    const errorRef = useRef<unknown>();

    const { goToNewTicketModal } = useTicketingNavigation();

    useEffect(() => {
        if (errorRef.current) {
            onGoBackHandler();
        }
        errorRef.current = location;
    }, [location, onGoBackHandler]);

    const handleBackClick = () => {
        onGoBackHandler();
        nav.goBack(() => nav.push('/'));
    };
    const { t: tCommon } = useTranslation('common');
    return (
        <NoAccessOrDontExistWrapper>
            <Header>{tCommon('accessMissing')}</Header>
            <Description>{tCommon('accessMissingDescription')}</Description>
            <ButtonContainer>
                <BackButton onClick={handleBackClick}>
                    <SvgIcon name="ArrowSecondary" />
                    {tCommon('buttonBack')}
                </BackButton>
                <PrimaryButton onClick={() => goToNewTicketModal()}>{tCommon('createInquiry')}</PrimaryButton>
            </ButtonContainer>
        </NoAccessOrDontExistWrapper>
    );
};

export default NoAccessOrDontExist;
