import { useEffect, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Category } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { HorizontalRule } from '../../../../common/styles';
import CategoryDescription from './components/CategoryDescription';

interface DescriptionTabProps {
    category: Category;
    ticketTypeId: string;
    changesSaved(isSaved: boolean): void;
}

const DescriptionTab = ({ category, ticketTypeId, changesSaved }: DescriptionTabProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const currentLocation = useLocation();
    const [formsSaved, setFormsSaved] = useState<{
        externalDescription: boolean;
        internalDescription: boolean;
    }>({
        externalDescription: true,
        internalDescription: true,
    });

    useEffect(() => {
        setFormsSaved({ externalDescription: true, internalDescription: true });
    }, [currentLocation]);

    useEffect(() => {
        changesSaved(formsSaved.externalDescription && formsSaved.internalDescription);
    }, [changesSaved, formsSaved]);

    return (
        <>
            <CategoryDescription
                header={t('descriptionTabHeaderFieldForUser')}
                description={t('descriptionTabDescriptionFieldForUser')}
                inputValue={category.externalDescription}
                name="externalDescription"
                label={t('descriptionTabLabelFieldForUser')}
                isSaved={(isSaved: boolean) => setFormsSaved({ ...formsSaved, externalDescription: isSaved })}
                category={category}
                ticketTypeId={ticketTypeId}
                isExternalDescription={true}
            />
            <HorizontalRule />
            <CategoryDescription
                header={t('descriptionTabHeaderFieldForManager')}
                description={t('descriptionTabDescriptionFieldForManager')}
                inputValue={category.internalDescription}
                name="internalDescription"
                label={t('descriptionTabLabelFieldForManager')}
                isSaved={(isSaved: boolean) => setFormsSaved({ ...formsSaved, internalDescription: isSaved })}
                category={category}
                ticketTypeId={ticketTypeId}
                isExternalDescription={false}
            />
        </>
    );
};

export default DescriptionTab;
