import { forwardRef, ReactElement, FocusEvent, useRef, SyntheticEvent, ChangeEvent } from 'react';
import styled from 'styled-components';
import { SvgIcon } from '../..';
import FieldErrorMessage from '../../FieldErrorMessage';

const disabledColor = '#D4D6D7';
const textColor = 'var(--text-high-emphasis-color)';
const primaryColor = 'var(--primary-color)';
const backgroundColor = 'var(--background-color)';
const primaryColorLight = 'var(--primary-color-light)';
const primaryColorDark = 'var(--primary-color-dark)';

type CheckboxSizes = 'default' | 'small';

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 0;
`;

type StyledCheckboxProps = { checked: boolean; disable: boolean; size: CheckboxSizes; partlyChecked: boolean };
const StyledCheckbox = styled.div`
    min-height: ${({ size }: StyledCheckboxProps) => (size === 'small' ? '1.143rem' : '1.714rem')};
    min-width: ${({ size }: StyledCheckboxProps) => (size === 'small' ? '1.143rem' : '1.714rem')};
    background: ${({ checked, disable }: StyledCheckboxProps) =>
        disable && checked ? disabledColor : checked ? primaryColor : backgroundColor};
    border: ${({ checked, disable }: StyledCheckboxProps) =>
        disable ? `2px solid ${disabledColor}` : checked ? 'none' : `2px solid ${primaryColor}`};
    color: white;
    border-radius: ${({ size }: StyledCheckboxProps) => (size === 'small' ? '3px' : '5px')};
    transition: all 0.1s ease-in;
    user-select: none;
    position: relative;
    display: flex;

    :hover {
        background: ${({ checked, disable }: StyledCheckboxProps) => (!disable && checked ? primaryColorLight : '')};
        border: ${({ checked, disable }: StyledCheckboxProps) =>
            !disable && !checked ? `2px solid ${primaryColorLight}` : ''};
    }

    :active {
        background: ${({ checked, disable }: StyledCheckboxProps) => (!disable && checked ? primaryColorDark : '')};
        border: ${({ checked, disable }: StyledCheckboxProps) =>
            !disable && !checked ? `2px solid ${primaryColorDark}` : ''};
    }

    ${HiddenCheckbox}:focus-visible + & {
        outline: 1px solid var(--primary-color) !important;
        outline-style: auto;
        outline-offset: 2px;
    }
    ${HiddenCheckbox}:focus:not(:focus-visible) + & {
        outline: none;
    }
`;

//Using text-shadow instead of font-weight to persist positioning.
type TextProps = { checked: boolean; disable: boolean; hasError: boolean; size: CheckboxSizes };
const Label = styled.label`
    display: flex;
    align-items: center;
    font-size: ${({ size }: TextProps) => (size === 'small' ? '0.857rem' : '1.143rem')};
    color: ${textColor};
    text-shadow: ${({ checked }: TextProps) => (checked ? '0.25px 0px 0.1px, -0.25px 0px 0.1px' : 'none')};
    cursor: pointer;
    text-align: left;
`;

type LabelTextProps = { size: CheckboxSizes; disable: boolean };
const LabelText = styled.p`
    padding-left: ${({ size }: LabelTextProps) => (size === 'small' ? '0.571rem' : '1.143rem')};
    opacity: ${({ disable }: LabelTextProps) => (disable ? 0.5 : 1)};
`;

type IconWrapperProps = { size: CheckboxSizes };
const IconWrapper = styled.div`
    display: flex;
    width: 100%;

    svg {
        margin: auto;
        height: ${({ size }: IconWrapperProps) => (size === 'small' ? '1rem' : '1.5rem')};
        width: ${({ size }: IconWrapperProps) => (size === 'small' ? '1rem' : '1.5rem')};
    }

    svg > path {
        fill: ${backgroundColor};
    }
`;

type SquareIconProps = { size: CheckboxSizes; disabled: boolean };
const SquareIcon = styled.div`
    margin: auto;
    height: ${({ size }: SquareIconProps) => (size === 'small' ? '0.565rem' : '0.857rem')};
    width: ${({ size }: SquareIconProps) => (size === 'small' ? '0.565rem' : '0.857rem')};
    background-color: ${({ disabled }: SquareIconProps) => (disabled ? disabledColor : primaryColor)};
`;

type VerticalLineProps = { disabled: boolean };
const VerticalLine = styled.div`
    border-left: 2px solid ${({ disabled }: VerticalLineProps) => (disabled ? disabledColor : primaryColor)};
    height: 3.429rem;
    margin-left: 0.571rem;
`;

type DashProps = { disabled: boolean };
const Dash = styled.div`
    display: block;
    text-align: center;
    margin-left: 1.143rem;
    border: 2px solid ${({ disabled }: DashProps) => (disabled ? disabledColor : primaryColor)};
    min-width: 1rem;
`;

interface CheckboxProps {
    id: string;
    label?: string | ReactElement;
    checked: boolean;
    partlyChecked?: boolean;
    onChange(event: SyntheticEvent, isChecked: boolean): void;
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    disable?: boolean;
    error?: string;
    size?: CheckboxSizes;
    dividerIcon?: 'verticalLine' | 'dash';
    tabIndex?: number;
    className?: string;
    propagated?: boolean;
}

const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>(function CheckboxForwarded(
    {
        id,
        label = '',
        checked = false,
        partlyChecked = false,
        onChange,
        disable = false,
        error = '',
        size = 'default',
        dividerIcon,
        tabIndex = 0,
        onFocus,
        className = '',
        propagated = false,
    },
    ref,
): ReactElement {
    const hasError = error !== '';

    const hiddenCheckboxRef = useRef<HTMLInputElement>(null);

    const onHiddenCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e, e.target.checked);
    };

    return (
        <div className={className}>
            <Label hasError={hasError} htmlFor={id} size={size} checked={checked} disable={disable}>
                <HiddenCheckbox
                    checked={checked}
                    id={id}
                    disabled={disable}
                    onChange={onHiddenCheckboxChange}
                    tabIndex={tabIndex}
                    onFocus={onFocus}
                    ref={hiddenCheckboxRef}
                    {...(propagated ? { onClick: (e) => e.stopPropagation() } : null)}
                />
                <StyledCheckbox
                    checked={checked}
                    partlyChecked={partlyChecked}
                    disable={disable}
                    size={size}
                    ref={ref}
                    {...(propagated
                        ? {
                              onClick: (e: SyntheticEvent<HTMLDivElement>) => {
                                  e.stopPropagation();
                                  !disable && onChange(e, (e.target as HTMLInputElement).checked);
                              },
                          }
                        : null)}
                >
                    {partlyChecked && !checked && <SquareIcon size={size} disabled={disable}></SquareIcon>}
                    {checked && (
                        <IconWrapper size={size}>
                            <SvgIcon name="CheckIcon" />
                        </IconWrapper>
                    )}
                </StyledCheckbox>
                {dividerIcon === 'verticalLine' ? (
                    <VerticalLine disabled={disable} />
                ) : dividerIcon === 'dash' ? (
                    <Dash disabled={disable} />
                ) : null}
                {label && (
                    <LabelText size={size} disable={disable}>
                        {label}
                    </LabelText>
                )}
            </Label>
            {hasError ? <FieldErrorMessage>{error}</FieldErrorMessage> : null}
        </div>
    );
});

export default Checkbox;
