import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { ShopOverviewView } from '../../../ShopAtWork';
import useShopAtWorkNavigation from '../../../useShopAtWorkNavigation';
import { purchasesHeader } from '../../PurchasesOverview/utils';

const BreadCrumbContainer = styled.div`
    padding: 1.857rem 0;
    font-size: 0.857rem;
    line-height: 1.429rem;
    text-transform: uppercase;

    button {
        color: var(--primary-color);
        cursor: pointer;
    }

    .inactive > button {
        color: #475156;
        cursor: default;
    }

    button:focus-visible {
        outline: 1px solid var(--primary-color);
        outline-offset: 3px;
        outline-style: auto;
    }
`;

const BreadCrumbSection = styled.div`
    display: inline-block;
`;

const BreadCrumbButton = styled.button`
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 0.857rem;
    text-transform: uppercase;
    line-height: 1.429rem;
    border: none;
    background-color: inherit;
    padding: 0;
`;

const RightCaretIcon = styled(Icon)`
    && {
        margin: 0 0.3rem;
    }
`;

interface BreadcrumbProps {
    view: ShopOverviewView;
    orderNumber?: number;
    className?: string;
}

const Breadcrumb = ({ view, orderNumber, className }: BreadcrumbProps): ReactElement | null => {
    const { goToMyPurchases, goToCompanyTransactions, goToPortalTransactions } = useShopAtWorkNavigation();
    const { t: tCommon } = useTranslation('common');
    const goBackHandler = () => {
        switch (view) {
            case ShopOverviewView.MyPurchases:
                return goToMyPurchases();
            case ShopOverviewView.CompanyPurchases:
                return goToCompanyTransactions();
            case ShopOverviewView.PortalPurchases:
                return goToPortalTransactions();
            default:
                goToMyPurchases();
        }
    };

    return (
        <BreadCrumbContainer className={className}>
            <span key={1}>
                <BreadCrumbSection key="ordersTable">
                    <BreadCrumbButton onClick={goBackHandler}>{purchasesHeader(view)}</BreadCrumbButton>
                </BreadCrumbSection>
                <RightCaretIcon size="small" name="angle right" />
                {orderNumber ? (
                    <BreadCrumbSection key="orderNumber" className="inactive">
                        <BreadCrumbButton>{`${tCommon('order')} ${orderNumber}`}</BreadCrumbButton>
                    </BreadCrumbSection>
                ) : null}
            </span>
        </BreadCrumbContainer>
    );
};

export default Breadcrumb;
