import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
    padding-top: 1.14rem;
`;

interface ConfirmButtonProps {
    isLoading?: boolean;
    disabled?: boolean;
}

const ConfirmButton = ({ isLoading = false, disabled }: ConfirmButtonProps): ReactElement => {
    const { t } = useTranslation('common');
    return (
        <ButtonWrapper>
            <Button
                fluid
                loading={isLoading}
                type="submit"
                style={{
                    backgroundColor: 'var(--primary-color)',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#fff',
                }}
                disabled={disabled}
            >
                {t('confirm')}
            </Button>
        </ButtonWrapper>
    );
};

export default ConfirmButton;
