import { ChangeEvent, forwardRef, ReactElement, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getLabel } from '../../../common/Helpers/getRequiredLabel';
import FieldErrorMessage from '../../FieldErrorMessage';
import SvgIcon from '../../icons/SvgIcon';
import Label from '../../Label';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: ${({ width }: { width?: string }) => width || 'auto'};
`;

interface InputFieldElementProps {
    error?: boolean;
    as?: 'textarea';
}

const InputFieldElement = styled.input`
    background: var(--surface-color-light);
    border: 1px solid var(--border-color);
    border-radius: 0.35rem;
    padding: 0.7rem 1.14rem;
    resize: vertical;
    :active {
        outline-color: black;
    }

    :focus {
        outline-color: var(--primary-color);
    }
    height: ${({ height }) => height || 'auto'};
    min-height: ${({ as }: InputFieldElementProps) => (as === 'textarea' ? `5rem` : 'auto')};
    border-color: ${({ error }: InputFieldElementProps) => (error ? 'var(--error-color)' : '')};
    cursor: auto;
`;

const LabelContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const StatusField = styled.p`
    font-size: 1.167rem;
    line-height: 1.833rem;
    margin: 0;
    color: var(--text-placeholder-color);
`;

const InputContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
`;

const ToggleVisibilityButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: var(--surface-color-light);
    margin: 0;
    border: none;
    cursor: pointer;
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    right: 0.642rem;

    :focus {
        outline-color: var(--primary-color);
    }

    > svg {
        height: 1.142rem;

        width: 1.142rem;
    }
`;

export interface InputFieldProps {
    onBlur?: (event: React.SyntheticEvent) => void;
    onFocus?: (event: React.SyntheticEvent) => void;
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    label?: string;
    error?: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    readOnly?: boolean;
    statusText?: string;
    className?: string;
    isLoading?: boolean;
    absolutePositionErrorMessage?: boolean;
    id?: string;
    value?: string | number;
    width?: string;
    height?: string;
}

interface TextInputFieldProps extends InputFieldProps {
    type?: 'text' | undefined;
    min?: number | string;
    max?: number | string;
    rows?: undefined;
}

interface NumberInputFieldProps extends InputFieldProps {
    type: 'number';
    min?: number | string;
    max?: number | string;
    rows?: undefined;
}

interface TextAreaFieldProps extends InputFieldProps {
    type: 'textarea' | 'text';
    min?: number | string;
    max?: number | string;
    rows?: number;
}

interface PasswordInputFieldProps extends InputFieldProps {
    type: 'password' | 'text';
    min?: number | string;
    max?: number | string;
    rows?: undefined;
}

export type InputFieldPropType =
    | TextInputFieldProps
    | NumberInputFieldProps
    | TextAreaFieldProps
    | PasswordInputFieldProps;

enum PasswordInputMaskingType {
    Password = 'password',
    Text = 'text',
}

const InputField = forwardRef<HTMLInputElement & HTMLTextAreaElement, InputFieldPropType>(function InputFieldForwarded(
    {
        label,
        error = '',
        name,
        placeholder,
        type = 'text',
        min,
        max,
        required = false,
        rows = 3,
        readOnly,
        onBlur,
        onChange,
        statusText,
        className,
        isLoading = false,
        absolutePositionErrorMessage,
        id,
        width,
        onFocus,
        height,
        ...rest
    },
    ref,
): ReactElement {
    const [changePasswordType, setChangePasswordType] = useState<PasswordInputMaskingType>(
        PasswordInputMaskingType.Password,
    );
    const { t } = useTranslation('common');
    const handleInputFieldMasking = (e: SyntheticEvent) => {
        e.preventDefault();
        const toggleMasking =
            changePasswordType === PasswordInputMaskingType.Text
                ? PasswordInputMaskingType.Password
                : PasswordInputMaskingType.Text;
        setChangePasswordType(toggleMasking);
    };
    const isError = error !== '';

    const getInputElement = (): ReactElement => {
        switch (type) {
            case 'textarea':
                return (
                    <InputFieldElement
                        as="textarea"
                        error={isError}
                        name={name}
                        ref={ref}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={isLoading ? t('loadingContent') : placeholder}
                        required={required}
                        minLength={min ? Number(min) : undefined}
                        maxLength={max ? Number(max) : undefined}
                        rows={rows}
                        readOnly={readOnly}
                        id={id ?? name}
                        {...rest}
                    />
                );
            case 'password':
                return (
                    <InputContainer>
                        <InputFieldElement
                            error={isError}
                            name={name}
                            ref={ref}
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder={isLoading ? t('loadingContent') : placeholder}
                            type={changePasswordType}
                            min={min}
                            max={max}
                            required={required}
                            autoComplete="false"
                            readOnly={readOnly || isLoading}
                            id={id ?? name}
                            height={height}
                            {...rest}
                        />

                        <ToggleVisibilityButton onClick={handleInputFieldMasking} type="button">
                            <SvgIcon
                                name={
                                    changePasswordType === PasswordInputMaskingType.Text
                                        ? 'VisibilityOn'
                                        : 'VisibilityOff'
                                }
                            />
                        </ToggleVisibilityButton>
                    </InputContainer>
                );
            default:
                return (
                    <InputFieldElement
                        error={isError}
                        name={name}
                        ref={ref}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={isLoading ? t('loadingContent') : placeholder}
                        type={type}
                        min={min}
                        max={max}
                        required={required}
                        autoComplete="false"
                        readOnly={readOnly || isLoading}
                        id={id ?? name}
                        onFocus={onFocus}
                        height={height}
                        {...rest}
                    />
                );
        }
    };

    return (
        <Container className={className} width={width}>
            {!!label && (
                <LabelContainer>
                    <Label htmlFor={name} isError={isError} disable={readOnly}>
                        {getLabel(label, required)}
                    </Label>
                    {statusText && <StatusField>{statusText}</StatusField>}
                </LabelContainer>
            )}
            {getInputElement()}
            {isError ? (
                <FieldErrorMessage absolutePosition={absolutePositionErrorMessage} id={`${id ?? name}-errorMessage`}>
                    {error}
                </FieldErrorMessage>
            ) : null}
        </Container>
    );
});
export default InputField;
