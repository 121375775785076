import styled from 'styled-components';

const FormLabel = styled.label`
    &&&& {
        font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-weight: 600;
        font-size: 12px;
        color: var(--text-medium-emphasis-color);
    }
`;

export default FormLabel;
