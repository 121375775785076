import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { VehicleDto } from '../../../../api/apiTypes/portalApiTypes';
import { PrimaryButton, SvgIcon } from '../../../../components';
import { VehicleControlActions } from '../Profile';
import VehicleCard from './VehicleCard';
import VehiclesModal from './VehiclesModal';

const Wrapper = styled.div`
    margin-top: 2.85rem;
`;
const VehiclesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const VehiclesHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.14rem;
    align-items: center;
`;

const VehiclesTitle = styled.p`
    line-height: 2rem;
    font-size: 1.14rem;
    margin-bottom: 0;
    font-weight: bolder;
`;

export type VehicleProps = {
    manageVehicleItem: (
        event: React.MouseEvent<HTMLButtonElement>,
        actionType: VehicleControlActions,
        vehicle?: VehicleDto,
    ) => void;
    vehicleModalType: false | VehicleControlActions;
    setVehicleModalType: (actionType: VehicleControlActions | false) => void;
    selectedVehicle: VehicleDto | undefined;
    vehicles: VehicleDto[];
};

const Vehicle = ({
    manageVehicleItem,
    vehicleModalType,
    setVehicleModalType,
    selectedVehicle,
    vehicles,
}: VehicleProps): ReactElement => {
    const { t } = useTranslation('myProfile');

    return (
        <>
            <Wrapper>
                <VehiclesHeader>
                    <VehiclesTitle>{t('vehiclesText')}</VehiclesTitle>
                    {vehicles && vehicles.length < 2 && (
                        <PrimaryButton onClick={(event) => manageVehicleItem(event, VehicleControlActions.Create)}>
                            {t('addVehicleButtonText')}
                            <SvgIcon name="Plus" />
                        </PrimaryButton>
                    )}
                </VehiclesHeader>
                {!vehicles.length && <div>{t('noRegisteredVehiclesText')}</div>}
                <VehiclesWrapper>
                    {vehicles?.map((vehicle) => (
                        <VehicleCard key={vehicle.id} manageVehicle={manageVehicleItem} data={vehicle} />
                    ))}
                </VehiclesWrapper>
            </Wrapper>
            {vehicleModalType && (
                <VehiclesModal
                    open={vehicleModalType}
                    onClose={() => setVehicleModalType(false)}
                    modalType={vehicleModalType}
                    vehicleData={selectedVehicle}
                />
            )}
        </>
    );
};

export default Vehicle;
