import { FC } from 'react';
import { FlexBox, Typography } from '../../../../../../components/styleds';
import { OrderCardProps } from './types';

const OrderCard: FC<OrderCardProps> = ({ title, children, isSkeleton, pd, titlePd }) => {
    return (
        <FlexBox
            background={'#fff'}
            boxShadow={'0px 4px 10px 0px rgba(0, 0, 0, 0.15)'}
            br={'0.714rem'}
            pd={pd || '1.714rem'}
            dir={'column'}
            gap={'0.571rem'}
            skeleton={isSkeleton}
            overflow={'hidden'}
        >
            <FlexBox pd={titlePd || '0px'}>
                <Typography fontSize={'1.714rem'} lineHeight={'2.571rem'} fontWeight={900} color={'#475156'}>
                    {title}
                </Typography>
            </FlexBox>
            {children}
        </FlexBox>
    );
};

export default OrderCard;
