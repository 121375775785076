import type { AxiosResponse } from 'axios';
import { AxiosError } from 'axios';
import type { MutationResultPair } from 'react-query';
import { useMutation, useQueryCache } from 'react-query';
import { VehicleModel } from '../../api/apiTypes/portalApiTypes';
import { createVehicle, deleteVehicle, updateVehicle } from '../../api/portalApi';
import { useGetUserByIdInfoCacheKey } from '../../common/MyProfile/apiQueries/useGetUserByIdInfo';

export const useCreateVehicle = (): MutationResultPair<
    AxiosResponse<void>,
    string | AxiosError<string> | Error,
    VehicleModel,
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        (vehicleDto) => {
            return createVehicle(vehicleDto);
        },
        { onSuccess: () => cache.invalidateQueries(useGetUserByIdInfoCacheKey) },
    );
};

export const useUpdateVehicle = (): MutationResultPair<
    AxiosResponse<void>,
    string | AxiosError<string> | Error,
    [VehicleModel, string],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([vehicleDto, vehicleId]) => {
            return updateVehicle(vehicleDto, vehicleId);
        },
        { onSuccess: () => cache.invalidateQueries(useGetUserByIdInfoCacheKey) },
    );
};

export const useDeleteVehicle = (): MutationResultPair<
    AxiosResponse<void>,
    string | AxiosError<string> | Error,
    string,
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        (vehicleId) => {
            return deleteVehicle(vehicleId);
        },
        { onSuccess: () => cache.invalidateQueries(useGetUserByIdInfoCacheKey) },
    );
};
