import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { sidebarOperations } from '../../common/Sidebar/duck';
import { useNavigation } from '../../utility';
import { useIsMobile } from '../../utility/hooks/useIsMobile';
import { TicketListView } from './components/UserTickets/UserTickets';
import { TicketingContext } from './Ticketing';

export type TypeTabPath = 'general' | 'description' | 'caseworkers' | 'FDVIntegration';
export type WorkListTabPath = 'comments' | 'internalNotes' | 'activity' | 'ticketDetails';
export type CategoryTabPath =
    | 'general'
    | 'description'
    | 'responsibleCaseworker'
    | 'statuses'
    | 'notifications'
    | 'registrationForm';

export interface FromNewTicketPath extends RouteComponentProps {
    fromPath?: string;
}

const useTicketingNavigation = (): {
    goToTickets(): void;
    goToUserTickets(): void;
    userTicketsLink: string;
    goToSettings(): void;
    settingsLink: string;
    goToWorkList(): void;
    workListLink: string;
    goToCompanyTickets(): void;
    companyTicketsLink: string;
    goToCreateNewType(): void;
    getTypeLink(id: string): string;
    goToType(id: string): void;
    createNewTypeLink: string;
    goToTypeTab(typeId: string, tab: TypeTabPath): void;
    getTypeTabLink(typeId: string, tab: TypeTabPath): string;
    getTicketLink(view: TicketListView, ticketId: string, tab?: string): string;
    goToTicketLink(view: TicketListView, ticketId: string, tab: WorkListTabPath, withHistory?: boolean): void;
    goToCategoryTab(typeId: string, categoryId: string, tab: CategoryTabPath): void;
    getCategoryTabLink(typeId: string, categoryId: string, tab?: string): string;
    getReporteeTicketsLink(reporteeId: string): string;
    goToReporteeTicketsLink(reporteeId: string, fromPath: string): void;
    goToNewTicketModal(fromPath?: string): void;
    newTicketModalLink: string;
    getModuleName(): string;
    goBack(currentView?: TicketListView): void;
    noAccessRedirectionLink: string;
} => {
    const moduleName = 'ticketing';
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const {
        state: { ticketViewHistory },
        dispatch: dispatchTicketing,
    } = useContext(TicketingContext);

    return useMemo(() => {
        const userTicketsLink = '/ticketing/myTickets';
        const settingsLink = `/ticketing/settings`;
        const workListLink = '/ticketing/worklist';
        const companyTicketsLink = '/ticketing/companyTickets';
        const getTypeLink = (typeId: string) => `${settingsLink}/${typeId}`;
        const createNewTypeLink = `${settingsLink}/createNewType`;
        const getTypeTabLink = (typeId: string, tab: TypeTabPath) => `${settingsLink}/${typeId}/${tab}`;
        const newTicketModalLink = '/ticketing/newTicket';
        const getCategoryTabLink = (typeId: string, categoryId: string, categoryTab: CategoryTabPath) =>
            `${settingsLink}/${typeId}/${categoryId}/${categoryTab}`;
        const getTicketLink = (view: TicketListView, ticketId: string, tab: WorkListTabPath) => {
            let link = userTicketsLink;
            if (view === TicketListView.Worklist || view === TicketListView.Caseworker) {
                link = workListLink;
            } else if (view === TicketListView.CompanyTickets) {
                link = companyTicketsLink;
            }
            return link + `/${ticketId}/${tab}`;
        };

        const getReporteeTicketsLink = (reporteeId: string) => `${workListLink}/user/${reporteeId}`;
        const onNavigate = (url: string, shouldCloseSideBar = true) => {
            if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
            navigation.push(url);
        };

        const onNavigateWithHistory = (url: string, shouldCloseSideBar = true) => {
            dispatchTicketing({ type: 'ADD_TICKET_VIEW_HISTORY', payload: location.pathname });
            onNavigate(url, shouldCloseSideBar);
        };

        const goToTickets = () => onNavigate(userTicketsLink, false);
        const goToUserTickets = () => onNavigateWithHistory(userTicketsLink);
        const goToSettings = () => onNavigate(settingsLink);
        const goToWorkList = () => onNavigate(workListLink);
        const goToCompanyTickets = () => onNavigate(companyTicketsLink);
        const noAccessRedirectionLink = '/Dashboard';
        return {
            userTicketsLink,
            settingsLink,
            workListLink,
            companyTicketsLink,
            goToTickets,
            goToUserTickets,
            goToSettings,
            goToWorkList,
            goToCompanyTickets,
            getReporteeTicketsLink,
            goToReporteeTicketsLink: (reporteeId: string, fromPath: string) => {
                const url = `${workListLink}/user/${reporteeId}`;
                dispatchTicketing({ type: 'ADD_TICKET_VIEW_HISTORY', payload: location.pathname });
                navigation.push(url, { fromPath });
            },
            getTypeLink,
            goToType: (id: string) => navigation.push(getTypeLink(id)),
            createNewTypeLink,
            goToCreateNewType: () => navigation.push(createNewTypeLink),
            goToTypeTab: (typeId: string, tab: TypeTabPath) => navigation.push(getTypeTabLink(typeId, tab)),
            getTypeTabLink,
            getTicketLink,
            goToTicketLink: (view: TicketListView, ticketId: string, tab: WorkListTabPath, withHistory = false) =>
                withHistory
                    ? onNavigateWithHistory(getTicketLink(view, ticketId, tab))
                    : onNavigate(getTicketLink(view, ticketId, tab)),
            goToCategoryTab: (typeId: string, categoryId: string, categoryTab: CategoryTabPath) =>
                navigation.push(getCategoryTabLink(typeId, categoryId, categoryTab)),
            getCategoryTabLink,
            newTicketModalLink,
            goToNewTicketModal: (fromPath?: string) => navigation.push(newTicketModalLink, { fromPath }),
            getModuleName: () => moduleName,
            goBack: (currentView?: TicketListView) => {
                if (ticketViewHistory.length) {
                    navigation.push(ticketViewHistory[0]);
                    dispatchTicketing({ type: 'POP_TICKET_VIEW_HISTORY' });
                } else {
                    if (currentView === TicketListView.MyTickets) goToUserTickets();
                    else if (currentView === TicketListView.CompanyTickets) goToCompanyTickets();
                    else goToWorkList();
                }
            },
            noAccessRedirectionLink: noAccessRedirectionLink,
        };
    }, [navigation, isMobile, dispatch, dispatchTicketing, ticketViewHistory]);
};
export default useTicketingNavigation;
