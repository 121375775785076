import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CaseworkerModel } from '../../../../../../../api/apiTypes/ticketingApiTypes';
import { GhostPrimaryButton, Modal, ModalType, PrimaryButton } from '../../../../../../../components';
import { ExistingCaseworkerSearch } from '../components/index';

const StyledModal = styled(Modal)`
    height: 560px;

    #modalContentContainer {
        overflow-y: auto;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    padding: 1.7rem;
`;

const Paragraph = styled.p`
    font-size: 1.143rem;
    line-height: 1.714rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-left: 1rem;
    }
`;

interface NewAssigneeModalProps {
    open: boolean;
    onClickNext(selectedCaseworkerGuid: string): void;
    onClose(): void;
    caseworkers: CaseworkerModel[] | [];
}

const NewAssigneeModal = ({ open, onClickNext, onClose, caseworkers }: NewAssigneeModalProps): ReactElement => {
    const [selectedCaseworker, setSelectedCaseworker] = useState<string | null>(
        caseworkers.length === 1 ? caseworkers[0].id : null,
    );
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const { t: tCommon } = useTranslation('common');
    useEffect(() => {
        caseworkers.length === 1 && setSelectedCaseworker(caseworkers[0].id);
    }, [caseworkers]);

    return (
        <StyledModal
            title={t('removeCaseManager')}
            onClose={() => null}
            open={open}
            type={ModalType.Small}
            hideCloseButton={true}
            bottomContent={
                <ButtonContainer>
                    <GhostPrimaryButton onClick={onClose}>{tCommon('cancelButton')}</GhostPrimaryButton>
                    <StyledPrimaryButton
                        onClick={() => onClickNext(selectedCaseworker as string)}
                        disabled={!selectedCaseworker}
                    >
                        {tCommon('next')}
                    </StyledPrimaryButton>
                </ButtonContainer>
            }
        >
            <ContentContainer>
                <Paragraph>{t('chooseWhoShouldBeAssigned')}</Paragraph>
                <ExistingCaseworkerSearch
                    caseworkers={caseworkers}
                    selectedCaseworkerId={selectedCaseworker}
                    onSelectNewAssignee={(guid) => setSelectedCaseworker(guid)}
                />
            </ContentContainer>
        </StyledModal>
    );
};

export default NewAssigneeModal;
