export const isQAEnvironment = (): boolean => {
    return process.env.REACT_APP_IDENTITY_APP_NAME?.toString() === 'PortalWeb-QA';
};

export const isTestEnvironment = (): boolean => {
    return process.env.REACT_APP_IDENTITY_APP_NAME?.toString() === 'portal-app-lifeatwork-Test';
};

export const isProductionEnvironment = (): boolean => {
    return process.env.REACT_APP_IDENTITY_APP_NAME?.toString() === 'PortalWeb';
};

export const isDevelopmentMode = (): boolean => {
    return process.env.NODE_ENV === 'development';
};

export const isHiddenFromProduction = (): boolean => {
    return isTestEnvironment() || isQAEnvironment() || isDevelopmentMode();
};
