import React from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import styled, { css } from 'styled-components';
import FieldErrorMessage from '../FieldErrorMessage';
import Label from '../Label';

import 'react-phone-input-2/lib/style.css';

type PhoneInputFieldProps = {
    value: string;
    label?: string;
    onChange: (value: string) => void;
    placeholder?: string;
    error?: boolean;
    onError?(value: number, errorMessage: string): void;
    onFocus?: (event: React.SyntheticEvent) => void;
    disabled?: boolean;
    helpText?: string;
    onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
    country?: string;
    className?: string;
};

type PhoneInputFieldWrapperProps = { hasError: boolean };
const PhoneInputFieldWrapper = styled.div`
    width: 100%;
    margin-top: 0;

    .form-control {
        padding-left: 48px !important;
    }

    ${({ hasError }: PhoneInputFieldWrapperProps) =>
        hasError &&
        css`
            .form-control {
                border: 1px solid red !important;
            }
            .flag-dropdown {
                border: 1px solid red;
            }
        `}
`;

type Country = { name: string; dialCode: string; countryCode: string; format: string };

const PhoneInputField: React.FC<React.PropsWithChildren<PhoneInputFieldProps>> = ({
    value,
    label,
    onChange,
    placeholder = '',
    error = false,
    onError,
    disabled = false,
    helpText = '',
    onFocus,
    onBlur,
    country = 'no',
    className,
}: PhoneInputFieldProps) => {
    const { t } = useTranslation('common');
    const validate = (value: string, country: Country | Record<string, never>): void => {
        if (value?.length > 1 && value?.length !== country?.format?.length) {
            const errorMessage = country?.format
                ? t('phoneNumberFormatNotCorrect', { country })
                : t('invalidPhoneNumber');
            onError?.(country?.format?.length, errorMessage);
        } else onError?.(0, '');
    };

    return (
        <div>
            <Label isError={error} htmlFor="phone-input">
                {label}
            </Label>
            <PhoneInputFieldWrapper hasError={error}>
                <PhoneInput
                    value={value || ''}
                    onChange={(phoneNumber, country, event) => {
                        validate(event.target.value, country);
                        onChange(phoneNumber);
                    }}
                    placeholder={placeholder}
                    preferredCountries={['no']}
                    onBlur={(event) => {
                        onBlur?.(event);
                    }}
                    inputStyle={{
                        height: '2.71rem',
                    }}
                    inputProps={{ id: 'phone-input', autoComplete: 'off' }}
                    country={country}
                    disabled={disabled}
                    onFocus={onFocus}
                    containerClass={className}
                />
            </PhoneInputFieldWrapper>
            {helpText && <FieldErrorMessage>{helpText}</FieldErrorMessage>}
        </div>
    );
};

export default PhoneInputField;
