import { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';

const StyledEditButton = styled.button`
    border: none;
    background-color: transparent;
    font-size: 1.143rem;
    line-height: 1.714rem;
    cursor: pointer;
    text-decoration: underline;
    color: #263238;
    margin-left: auto;
`;

interface EditButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
    text: string;
}

export const EditButton: FC<EditButtonProps> = ({ text, ...props }) => (
    <StyledEditButton {...props}>{text}</StyledEditButton>
);
