import { ChangeEvent, ReactElement, useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import RoutePromptModal from '../../../../../common/RoutePromptModal';
import { InformationMessage, InputField } from '../../../../../components';
import { OptionTypeWithId } from '../../../../../components/general/dropdown/InputSelect';
import { useNavigation } from '../../../../../utility';
import { AssetSelection } from '../../../components/common';
import { NewTicketContext } from '../../NewTicketContext';
import { NewTicketForm } from '../../NewTicketModal';
import { NewTicketFileUploader } from '../FileUploader';

const StepTitle = styled.h2`
    font-weight: 900;
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-bottom: 1rem;
`;

const StepSubtitle = styled.h3`
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 1.7rem;
    color: #111111;
    margin-top: 0;
`;

const InputContainer = styled.div`
    margin-bottom: 1rem;
`;

const FileUploaderContainer = styled.div`
    padding-bottom: 1.071rem;
`;

interface FormStepProps {
    submitError: boolean;
}

export const FormStep = ({ submitError }: FormStepProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'newTicket' });
    const { t: tCommon } = useTranslation('common');
    const newTicketContext = useContext(NewTicketContext).state;
    const { dispatch } = useContext(NewTicketContext);
    const nav = useNavigation();

    const {
        control,
        setValue,
        trigger,
        formState: { errors, isValid, isDirty },
    } = useFormContext<NewTicketForm>();
    const onBuildingChange = async (selectedValue: OptionTypeWithId) => {
        dispatch({ type: 'UPDATE_BUILDING', payload: selectedValue });
        setValue('building', selectedValue?.id ?? '');
        await trigger('building');
    };

    const onFloorChange = async (selectedValue: OptionTypeWithId) => {
        dispatch({ type: 'UPDATE_FLOOR', payload: selectedValue });
        setValue('floor', selectedValue?.id ?? '');
        await trigger('floor');
    };

    useEffect(() => {
        dispatch({ type: 'UPDATE_FORM_IS_VALID', payload: isValid });
    }, [dispatch, isValid]);

    return (
        <>
            <StepTitle>{t('formStepTitle')}</StepTitle>
            <StepSubtitle>{t('formStepSubTitle')} </StepSubtitle>
            <form key="newTicketForm">
                <AssetSelection
                    buildingLabel={t('buildingLabel')}
                    buildingValue={newTicketContext.ticketForm.building}
                    floorLabel={t('floorLabel')}
                    floorValue={newTicketContext.ticketForm.floor}
                    key="newTicketAsset"
                    onBuildingChange={onBuildingChange}
                    onFloorChange={onFloorChange}
                />
                <InputContainer>
                    <Controller
                        control={control}
                        name="title"
                        render={({ field: { onChange }, field }) => {
                            const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                onChange(event);
                                dispatch({ type: 'UPDATE_TITLE', payload: event.currentTarget.value });
                            };
                            return (
                                <InputField
                                    placeholder={t('formStemTitleFieldPlaceholder')}
                                    label={t('formStemTitleFieldLabel')}
                                    required
                                    type="text"
                                    error={errors?.title?.message}
                                    {...field}
                                    onChange={handleChange}
                                />
                            );
                        }}
                    />
                </InputContainer>
                <InputContainer>
                    <Controller
                        control={control}
                        name="description"
                        render={({ field: { onChange }, field }) => {
                            const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                onChange(event);
                                dispatch({ type: 'UPDATE_DESCRIPTION', payload: event.currentTarget.value });
                            };
                            return (
                                <InputField
                                    placeholder={t('formStemDescriptionPlaceholder')}
                                    label={t('formStemDescriptionFieldLabel')}
                                    required
                                    type="textarea"
                                    rows={5}
                                    error={errors?.description?.message}
                                    {...field}
                                    onChange={handleChange}
                                />
                            );
                        }}
                    />
                </InputContainer>
                <FileUploaderContainer>
                    <NewTicketFileUploader />
                </FileUploaderContainer>
            </form>

            {submitError && <InformationMessage type={'error'}>{t('formStepErrorMessage')}</InformationMessage>}

            <RoutePromptModal
                navigate={nav.push}
                shouldBlockNavigation={() => isDirty}
                modalTitle={t('modalWindowTitle')}
                modalContent={<p>{t('formStepModalContent')}</p>}
                modalCancelButtonText={tCommon('backToEditing')}
                modalOkButtonText={tCommon('discardChanges')}
            />
        </>
    );
};
