import type { QueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { IHelpArticle } from '../../../api/apiTypes/cmsApiTypes';
import { getAllHelpArticles } from '../../../api/cmsApi';

const helpArticlesQueryCacheKey = 'helpArticles';

export const useGetHelpArticles = (): QueryResult<IHelpArticle[]> => {
    return useQuery([helpArticlesQueryCacheKey], async () => {
        const { data } = await getAllHelpArticles();
        return data;
    });
};
