import styled from 'styled-components';

const Header = styled.h5`
    font-size: 1.286rem;
    line-height: 1.714rem;
    letter-spacing: 0.2px;
`;

const Paragraph = styled.p`
    font-size: 1.143rem;
    line-height: 1.714rem;
`;

const ChangesSaved = styled.p`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-size: 1.167rem;
    line-height: 1.833rem;
    margin: 0;
    color: var(--text-placeholder-color);
`;

const HorizontalRule = styled.hr`
    border: 1px solid #d4d6d7;
    margin: 1.714rem 0;
`;

export { Header, Paragraph, ChangesSaved, HorizontalRule };
