import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'semantic-ui-react';

interface DisplayErrorProps {
    reset(): void;
    error: string;
}

const DisplayError: React.FC<React.PropsWithChildren<DisplayErrorProps>> = ({ reset, error }) => {
    const { t } = useTranslation('common');
    return (
        <Card>
            <Card.Content>
                <Card.Header>{t('somethingWentWrong')}</Card.Header>
                <Card.Description>
                    {t('displayErrorErrorMessage')} {error}
                </Card.Description>
            </Card.Content>
            <Card.Content extra style={{ display: 'flex', justifyContent: 'center' }}>
                <Button primary onClick={reset}>
                    {t('tryAgain')}
                </Button>
            </Card.Content>
        </Card>
    );
};

export default DisplayError;
