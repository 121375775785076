import { ReactElement } from 'react';
import { Card } from 'semantic-ui-react';
import styled, { css, keyframes } from 'styled-components';
import { MobileViewBreakpoint } from '../../../../../common/ScreenSizeBreakPoints';

const greyColorLight = '#fbfbfb';
const greyColor = '#e9ebeb';
const greyColorDark = '#d4d6d7';

const keyframesShimmer = keyframes`
  0% {
    background-position:100% 100%;
  }

  75% {
    background-position:0 0;
  }

  100% {
    background-position:0 0;
  }
`;

const fadeOut = keyframes`
0% {
    opacity: 100%;
}
100% {
    opacity: 0%;
}
`;

const shimmerAnimation = css`
    display: inline-block;
    background: linear-gradient(
        to bottom right,
        transparent 45%,
        rgba(255, 255, 255, 0.7) 45% 55%,
        transparent 55% 80%
    );
    background-size: 250% 250%;
    background-repeat: no-repeat;
    animation: ${keyframesShimmer} 1s infinite linear;
`;

type WrapperProps = { show?: boolean };
const Wrapper = styled.div`
    animation: ${({ show }: WrapperProps) =>
        show
            ? 'none;'
            : css`
                  ${fadeOut} 0.1s linear forwards;
              `};
`;

const StyledCard = styled(Card)`
    cursor: pointer;
    height: 30.071rem;
    border-radius: 5px;

    &&& {
        width: 31.65rem;
        margin: 0.85rem 0;

        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        :hover {
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
        }

        :active {
            border: 1px solid var(--primary-color-dark);
            box-shadow: none;
            border-radius: 5px;
        }

        :focus {
            box-shadow: 0px 0px 0px 2px var(--primary-color);
            border-radius: 5px;
        }

        @media (max-width: ${MobileViewBreakpoint ?? 768}px) {
            width: 100%;
        }
    }
`;

const Shimmer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${shimmerAnimation}
`;

const ContextMenuButton = styled.div`
    position: absolute;
    right: 25px;
    top: 25px;
    background-color: ${greyColor};
    height: 32px;
    width: 32px;
    border-radius: 50%;
`;

const Image = styled.div`
    height: 213px;
    background-color: ${greyColorLight};
`;

const Badge = styled.div`
    position: absolute;
    border-radius: 30px;
    top: 165px;
    background-color: ${greyColor};
    width: 62px;
    height: 24px;
    margin-left: 24px;
`;

const ContentWrapper = styled.div`
    margin: 24px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
`;

const Header = styled.div`
    background-color: ${greyColorDark};
    width: 379px;
    height: 24px;
    border-radius: 5px;
    margin-bottom: 20px;
`;

const Ingress1 = styled.div`
    background-color: ${greyColor};
    width: 402px;
    height: 18px;
    border-radius: 5px;
    margin-bottom: 8px;
`;

const Ingress2 = styled.div`
    background-color: ${greyColor};
    width: 298px;
    height: 18px;
    border-radius: 5px;
    margin-bottom: 32px;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 8px;
`;

const AuthorDisplay = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
`;

const Avatar = styled.div`
    background-color: ${greyColorDark};
    height: 40px;
    width: 40px;
    border-radius: 50%;
    min-width: 40px;
`;

const UserName = styled.div`
    background: ${greyColorDark};
    width: 153px;
    height: 12px;
    border-radius: 5px;
`;

const EditInfo = styled.div`
    background: ${greyColor};
    width: 209px;
    height: 12px;
    border-radius: 5px;
`;

const Icon = styled.div`
    background-color: ${greyColor};
    height: 32px;
    width: 32px;
    border-radius: 50%;
    min-width: 32px;
    margin-left: 8px;
`;

const SkeletonWrapper = styled.div`
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: ${MobileViewBreakpoint}px) {
        width: 100%;
    }
`;

interface ImageCardSkeletonLoaderProps {
    show?: boolean;
    numberOfCards?: number;
}

const ArticleCardSkeletonLoader = ({ show, numberOfCards = 1 }: ImageCardSkeletonLoaderProps): ReactElement | null => {
    let cards = [];
    const card = (index: number) => (
        <StyledCard key={index}>
            <Wrapper show={show}>
                <ContextMenuButton />
                <Image />
                <Badge />
                <ContentWrapper>
                    <Header />
                    <Ingress1 />
                    <Ingress2 />
                    <Footer>
                        <AuthorDisplay>
                            <Avatar />
                            <Container>
                                <UserName />
                                <EditInfo />
                            </Container>
                        </AuthorDisplay>
                        <Icon />
                    </Footer>
                </ContentWrapper>
                <Shimmer />
            </Wrapper>
        </StyledCard>
    );

    cards = Array.from({ length: numberOfCards }, (_, index) => card(index));
    return <SkeletonWrapper>{cards}</SkeletonWrapper>;
};

export default ArticleCardSkeletonLoader;
