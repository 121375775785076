import { ReactElement, ReactNode, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { ModuleName } from '../../../../../api/apiTypes/portalApiTypes';
import {
    OrderStatusOption,
    VendorOrderStatusOption,
    PaymentOption,
    CompanyOrder,
    UserOrderForKitchenAdmin,
    GboMenuType,
} from '../../../../../api/apiTypes/shopApiTypes';
import { Loader, useCurrentUserContext } from '../../../../../common';
import { Badge, Dropdown } from '../../../../../components';
import { DropDownProps } from '../../../../../components/general/dropdown/DropDown';
import { isHiddenFromProduction, useModuleIsActive } from '../../../../../utility';
import { getOrderStatusBadgeType } from '../../../../../utility/getBadgeType';
import { useUpdateOrderStatus } from '../../../../Admin/KitchenAdmin/apiQueries/useUpdateOrders';
import useTicketingNavigation from '../../../../Ticketing/useTicketingNavigation';
import Customer from '../../Customer';
import { getShopAtWorkStatusTranslation } from '../../PurchasesOverview/utils';
import TotalSum from '../../TotalSum';
import SellerInformation from '../SellerInformation';
import ProductsTable from './ProductsTable';
import { getFormattedDate, getMenuType } from './utils';

const CardContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 0.714rem;

    margin-bottom: 1.714rem;

    ${({ isMobileView }: { isMobileView: boolean }) =>
        isMobileView &&
        css`
            flex-direction: column;
        `}
`;

const Card = styled.div`
    width: 100%;
    padding: 1.714rem;
    border: 1px solid #000000;
    border-radius: 0.357rem;
    overflow: auto;
    height: 100%;
`;

const CardTitle = styled.h3`
    font-weight: 900;
    font-size: 1.428rem;
    line-height: 2.285rem;
`;

const CardText = styled.p`
    font-weight: 400;
    font-size: 1.142rem;
    line-height: 1.714rem;
`;

const OrderDetailsList = styled.ul`
    padding: 0;
    list-style: none;
`;

interface OrderDetailsItemProps {
    isMobileView?: boolean;
}
const OrderDetailsItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.571rem;
    flex-direction: ${({ isMobileView }: OrderDetailsItemProps) => (isMobileView ? 'column' : 'row')};
`;

const ItemSubtitle = styled.h4`
    font-weight: 700;
    font-size: 1.142rem;
    line-height: 1.714rem;
    margin: 0;
`;

const StyledBadge = styled(Badge)`
    height: 1.714rem;
`;

const OrderDetailsField = styled.p``;

const StyledDropdown = styled(({ ...props }: DropDownProps) => <Dropdown {...props} />)``;

type OrderDetailsItemsType = {
    title: string;
    content: string | ReactNode;
    hide?: boolean;
};

interface DetailedPurchaseProps {
    order: CompanyOrder | UserOrderForKitchenAdmin;
    isMobileView: boolean;
    canChangeOrderStatus?: boolean;
    isVendor: boolean;
}

const IPARK_PORTAL_ID = '1';

const PurchaseDetails = ({
    order,
    isMobileView,
    canChangeOrderStatus,
    isVendor,
}: DetailedPurchaseProps): ReactElement => {
    const { t, i18n } = useTranslation('ShopAtWork', { keyPrefix: 'orderOverview' });
    const { t: tCommon } = useTranslation('common');
    const { language } = i18n;
    const [updateOrder, { isLoading: isUpdateOrderLoading, isError: isUpdateOrderError }] = useUpdateOrderStatus();
    const updateOrderErrorMessage = isUpdateOrderError ? tCommon('somethingWentWrong') : undefined;
    const { state: userInfo } = useCurrentUserContext();
    const showOrderStatusDropdown = canChangeOrderStatus && order?.status === OrderStatusOption.Ordered;
    const orderStatusOptions = useMemo(() => {
        return (Object.keys(VendorOrderStatusOption) as Array<keyof typeof VendorOrderStatusOption>).map((key) => ({
            value: key,
            id: key,
        }));
    }, []);

    const isFoodForMeetingOrder = 'menuType' in order && order.menuType === GboMenuType.FoodForMeeting;
    const isLunchOrder = 'menuType' in order && order.menuType === GboMenuType.Lunch;

    const orderDetailsItems: OrderDetailsItemsType[] = [
        {
            title: tCommon('status'),
            content: showOrderStatusDropdown ? (
                <>
                    {isUpdateOrderLoading ? (
                        <div>
                            <Loader />
                        </div>
                    ) : (
                        <StyledDropdown
                            error={updateOrderErrorMessage}
                            initialValue={order.status}
                            badgeOptions={Object.values(VendorOrderStatusOption)}
                            items={orderStatusOptions}
                            value={order.status}
                            onChange={(val: string) => {
                                updateOrder([[order.id], val]);
                            }}
                        />
                    )}
                </>
            ) : (
                <OrderDetailsField>
                    <StyledBadge type={getOrderStatusBadgeType(order.status)}>
                        {getShopAtWorkStatusTranslation(order.status)}
                    </StyledBadge>
                </OrderDetailsField>
            ),
        },
        {
            title: 'Type',
            content: getMenuType((order as UserOrderForKitchenAdmin).menuType),
        },

        {
            title: t('orderDetailsDate'),
            content: getFormattedDate(order.createdDate, language),
        },
        {
            title: t('orderDetailsUpdated'),
            content: getFormattedDate(order.updatedDate, language),
        },
        {
            title: t('orderDetailsDeliveryTime'),
            content: getFormattedDate(order.deliveryDateTime, language),
            hide: !order?.deliveryDateTime || !isFoodForMeetingOrder,
        },
        {
            title: t('orderDetailsDeliveryLocation'),
            content: order.deliveryLocation,
            hide: !order.deliveryLocation || !isFoodForMeetingOrder,
        },
        {
            title: t('orderDetailsCanteen'),
            content: (order as UserOrderForKitchenAdmin).canteenName,
            hide: !(order as UserOrderForKitchenAdmin).canteenName,
        },
        {
            title: t('orderDetailsPaymentForm'),
            content: (PaymentOption[order.paymentType] as keyof typeof PaymentOption)
                ? t(PaymentOption[order.paymentType] as keyof typeof PaymentOption)
                : '-',
        },
    ];

    const isIparkPortal = userInfo.currentUser?.portalId === IPARK_PORTAL_ID;
    const isGboModuleActive = useModuleIsActive(ModuleName.GoldByOpen);
    const userInformation =
        isIparkPortal || isGboModuleActive ? (
            <SellerInformation seller={(order as CompanyOrder)?.vendor} />
        ) : (
            <Information />
        );

    return (
        <>
            <CardContainer isMobileView={isMobileView}>
                <Card>
                    <CardTitle>{t('orderDetailsTitle')}</CardTitle>
                    <OrderDetailsList>
                        {orderDetailsItems.map((item: OrderDetailsItemsType) => {
                            return (
                                !item.hide && (
                                    <OrderDetailsItem isMobileView={isMobileView}>
                                        <ItemSubtitle>{item.title}</ItemSubtitle>
                                        <OrderDetailsField>{item.content}</OrderDetailsField>
                                    </OrderDetailsItem>
                                )
                            );
                        })}
                    </OrderDetailsList>
                </Card>
                {isVendor ? (
                    <Customer
                        customer={{
                            title: t('customer'),
                            areaTitle: t('comments'),
                            areaValue: order?.comment || t('noComments'),
                            userInfo: `${order.employee.firstName} ${order.employee.lastName}`,
                            companyName: order.employee.companyName || '',
                            email: order.employee.email,
                            hideComment: isLunchOrder,
                        }}
                    />
                ) : (
                    userInformation
                )}
            </CardContainer>

            <ProductsTable orderLines={order.orderLines} isMobileView={isMobileView} />

            <TotalSum isMobileView={isMobileView} order={order} />
        </>
    );
};

export default PurchaseDetails;

const InformationParagraph = styled.p``;

const AnchorLink = styled.a`
    &,
    :hover,
    :visited,
    :focus {
        color: var(--text-link-color);
        text-decoration: underline;
        cursor: pointer;
    }
`;

function Information(): ReactElement {
    const { goToNewTicketModal } = useTicketingNavigation();
    const { state: userState } = useCurrentUserContext();
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'orderOverview' });

    // TODO: Temporary solution for test feature
    const isFeatureVisible = isHiddenFromProduction();

    return (
        <Card>
            <CardTitle>{t('orderInfoTitle')}</CardTitle>
            <CardText>
                <InformationParagraph>{t('orderInfoTextP1')}</InformationParagraph>
                {isFeatureVisible && (
                    <>
                        <InformationParagraph>
                            <Trans
                                i18nKey="ShopAtWork:orderOverview.orderInfoTextP2"
                                components={{
                                    privacyLink: <AnchorLink href={userState.privacyPolicy?.url} target="_blank" />,
                                }}
                            />
                        </InformationParagraph>
                        <InformationParagraph>
                            <Trans
                                i18nKey="ShopAtWork:orderOverview.orderInfoTextP3"
                                components={{
                                    newTicketLink: <AnchorLink onClick={() => goToNewTicketModal()} tabIndex={0} />,
                                }}
                            />
                        </InformationParagraph>
                    </>
                )}
            </CardText>
        </Card>
    );
}
