import { ReactElement, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { NewProductCategory } from '../../../api/apiTypes/shopApiTypes';
import { Dropdown, GhostPrimaryButton, InputField, PrimaryButton } from '../../../components';
import { DropDownProps } from '../../../components/general/dropdown/DropDown';
import Modal, { ModalType } from '../../../components/general/Modal';
import { useCreateProductCategory } from '../apiQueries/useShopFilters';
import { ManageCategoryLayoutLogic } from './InventoryOverview/layouts/ManageCategoryLayout/ManageCategoryLayoutLogic';

const ModalBody = styled.div`
    padding: 1.9rem 1.14rem 1.9rem 1.7rem;
`;

const ModalBottom = styled.div`
    display: flex;
    justify-content: end;
    gap: 1.143rem;
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    gap: 1.143rem;
`;

const FormElementContainer = styled.div`
    width: 100%;
`;

const StyledFormAdditionalInformation = styled.span`
    color: var(--text-placeholder-color);
    font-size: 0.857rem;
    line-height: 1.143rem;
`;

const StyledDropdown = styled(({ ...props }: DropDownProps) => <Dropdown {...props} />)`
    width: 100%;

    button {
        height: 2.857rem;
        padding: 0.571rem 1.143rem;
    }
`;

interface NewCategoryModalProps {
    isCreateNewCategoryModalShown: boolean;
    hideCreateNewCategoryModal: () => void;
}

const CreateNewCategoryModal = ({
    isCreateNewCategoryModalShown,
    hideCreateNewCategoryModal,
}: NewCategoryModalProps): ReactElement => {
    const { selectedCategoryId, preparedProductTypesForModalDropdown } = ManageCategoryLayoutLogic();

    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'productCategoryAdministration' });
    const { t: tCommon } = useTranslation('common');

    const schema = yup.object().shape({
        typeId: yup
            .string()
            .required(t('newCategoryModalCategoryTypeError'))
            .test('productType', (formProductType) => {
                return !!preparedProductTypesForModalDropdown.find((productType) => productType.id === formProductType);
            }),
        name: yup.string().required(t('newCategoryModalCategoryNameError')),
    });

    const {
        formState: { errors, isDirty, isValid },
        handleSubmit,
        control,
        register,
        setValue,
        reset,
        watch,
    } = useForm<NewProductCategory>({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            typeId: '',
        },
    });

    const selectedProductTypeId: string = watch('typeId');

    const [createProductCategory, { isLoading: isCreateProductCategoryLoading }] =
        useCreateProductCategory(selectedProductTypeId);

    const onSubmit = async (formData: NewProductCategory) => {
        await createProductCategory(formData, {
            onSuccess: () => {
                reset();
                hideCreateNewCategoryModal();
            },
        });
    };

    useEffect(() => {
        reset({
            name: '',
            typeId: selectedCategoryId ?? '',
        });
    }, [selectedCategoryId]);

    return (
        <Modal
            open={isCreateNewCategoryModalShown}
            onClose={hideCreateNewCategoryModal}
            type={ModalType.Small}
            title={t('newCategoryModalTitle')}
            hideCloseButton
            bottomContent={
                <ModalBottom>
                    <GhostPrimaryButton onClick={hideCreateNewCategoryModal} disabled={isCreateProductCategoryLoading}>
                        {tCommon('cancelButton')}
                    </GhostPrimaryButton>
                    <PrimaryButton
                        onClick={handleSubmit(onSubmit)}
                        loading={isCreateProductCategoryLoading}
                        disabled={!isValid || !isDirty || isCreateProductCategoryLoading}
                    >
                        {tCommon('saveButton')}
                    </PrimaryButton>
                </ModalBottom>
            }
        >
            <ModalBody>
                <FormWrapper>
                    <FormElementContainer>
                        <InputField
                            label={t('newCategoryModalCategoryNameLabel')}
                            placeholder={t('newCategoryModalCategoryNamePlaceholder')}
                            isLoading={isCreateProductCategoryLoading}
                            error={errors.name?.message}
                            {...register('name')}
                        />
                    </FormElementContainer>
                    <StyledFormAdditionalInformation>{t('newCategoryModalPrompt')}</StyledFormAdditionalInformation>
                    <FormElementContainer>
                        <Controller
                            name="typeId"
                            control={control}
                            render={({ field: { onChange }, field }) => {
                                const handleOnChange = (value: string) => {
                                    setValue('typeId', value);
                                    onChange(value);
                                };
                                return (
                                    <StyledDropdown
                                        items={preparedProductTypesForModalDropdown}
                                        label={t('newCategoryModalCategoryTypeLabel')}
                                        required
                                        placeholder={t('newCategoryModalCategoryTypeLabel')}
                                        readOnly={isCreateProductCategoryLoading}
                                        {...field}
                                        onChange={handleOnChange}
                                        error={errors.typeId?.message}
                                    />
                                );
                            }}
                        />
                    </FormElementContainer>
                </FormWrapper>
            </ModalBody>
        </Modal>
    );
};

export default CreateNewCategoryModal;
