import { FC, PropsWithChildren } from 'react';
import { FlexBox } from '../../../../components/styleds';
import { CommonHeader, CommonHeaderProps } from '../CommonHeader';

type CommonLayoutProps = CommonHeaderProps;

export const CommonLayout: FC<PropsWithChildren<CommonLayoutProps>> = ({ children, ...props }) => {
    return (
        <FlexBox dir={'column'} background={'#fff'} height={'100%'}>
            <CommonHeader {...props} />
            {children}
        </FlexBox>
    );
};
