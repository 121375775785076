import { AxiosError, AxiosResponse } from 'axios';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { RolePhrase } from '../../../api/apiTypes/portalApiTypes';
import { getPortalRoles } from '../../../api/portalApi';
import { getListOfRestrictedSubmitters, updateListOfRestrictedSubmitters } from '../../../api/ticketingApi';
import { Perm } from '../../../common';
import { useUserHasPerm } from '../../../utility';
import { UserHasTicketingAccessCacheKey } from './useTicketingUser';

const TicketingSubmittersCacheKey = 'TicketingSubmittersCacheKey';
const PortalRolesCacheKey = 'PortalRolesCacheKey';

export const useGetListOfRestrictedSubmitters = (): QueryResult<string[], string | AxiosError<string> | Error> => {
    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    return useQuery(
        [TicketingSubmittersCacheKey],
        async () => {
            const result = await getListOfRestrictedSubmitters();
            return result.data;
        },
        {
            enabled: userIsPortalAdmin,
        },
    );
};

export const useUpdateListOfRestrictedSubmitters = (): MutationResultPair<
    AxiosResponse<string>,
    string | AxiosError<string> | Error,
    string[],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        (roles) => {
            return updateListOfRestrictedSubmitters(roles);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(TicketingSubmittersCacheKey);
                cache.invalidateQueries(UserHasTicketingAccessCacheKey);
            },
        },
    );
};

export const useGetAllPortalRoles = (): QueryResult<RolePhrase[], string | AxiosError<string> | Error> => {
    const userIsPortalAdmin = useUserHasPerm(Perm.PortalAdmin);
    return useQuery(
        [PortalRolesCacheKey],
        async () => {
            const result = await getPortalRoles();
            return result.data;
        },
        {
            enabled: userIsPortalAdmin,
        },
    );
};
